<div fxLayout="column" *ngIf="!!internalValueCtr">
    <mat-label >{{ labelText }}</mat-label>
    <mat-hint *ngIf="!!comment">({{ comment }})</mat-hint>

    <div fxLayout="row" fxLayoutGap="15" fxLayoutAlign="space-between">
        <mat-radio-group fxFlex="40" fxLayout="row" *ngIf="!!internalIsPercentCtr"  [formControl]="internalIsPercentCtr">
            <mat-radio-button [value]="false" color="primary" class="cxda-radio">€</mat-radio-button>
            <mat-radio-button [value]="true" color="primary" >%</mat-radio-button>
        </mat-radio-group>

        <mat-form-field fxFlex="45" appearance="outline">
            <input [options]="options" currencyMask matInput [formControl]="internalValueCtr" />
            <mat-error cxad-form-field-error *ngIf="internalValueCtr.invalid" [control]="internalValueCtr"></mat-error>
        </mat-form-field>
    </div>
</div>
