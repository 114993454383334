<mat-form-field *ngIf="!!control" [attr.name]="controlName" [hideRequiredMarker]="!isRequired">
    <mat-label>{{ labelText }}</mat-label>

    <ng-container [ngSwitch]="type">
        <!-- bug with type number https://stackoverflow.com/a/59027629/4758334 -->
        <input *ngSwitchCase="'number'" [min]="min" matInput type="number" [readonly]="readonly"
            [formControl]="formControl" [required]="!!isRequired" (keydown)="onlyNumbers($event)" (blur)="onBlur()" (keydown.enter)="onEnter()" #inputElement>

        <input *ngSwitchCase="'year'" matInput type="number" [readonly]="readonly" [formControl]="formControl"
            [required]="!!isRequired" (blur)="onBlur()" (keydown.enter)="onEnter()" #inputElement>

        <input *ngSwitchCase="'password'" matInput [type]="showPassword ? 'text' : 'password'" [readonly]="readonly"
            [formControl]="formControl" [required]="!!isRequired" class="password-input" (blur)="onBlur()" (keydown.enter)="onEnter()" #inputElement>

        <input *ngSwitchDefault matInput [type]="type" [readonly]="readonly" [formControl]="formControl"
            [required]="!!isRequired" (blur)="onBlur()" (keydown.enter)="onEnter()" #inputElement>
    </ng-container>

    <span *ngIf="type === 'password' && showPassword" class="material-icons password-toggle"
        (click)="toggleShowPassword()">visibility</span>
    <span *ngIf="type === 'password' && !showPassword" class="material-icons password-toggle"
        (click)="toggleShowPassword()">visibility_off</span>

    <mat-error ucba-form-field-error *ngIf="control.invalid" [control]="control"></mat-error>
    <mat-hint *ngIf="!!comment">{{ comment }}</mat-hint>
</mat-form-field>