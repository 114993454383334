import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotFound, Unauthorized } from '@curveball/http-errors';
import { Observable, ObservableInput, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IHttpOptions } from '../../interfaces';
import { GlobalSettings } from '../../utils';


/**
 * Basis Controller, welcher den eigentlichen Request absetzt
 */
@Injectable()
export abstract class BaseController {

    /** wird im AppModul Konstruktor gesetzt */
    public static backendUrl: string | undefined;

    /**
     * Standard Konstruktor
     * 
     * @param {HttpClient} httpClient HttpClient injector
     */
    public constructor(
        protected httpClient: HttpClient,
    ) { }

    /**
     * generiert ein httpOptions mit authentifikation
     * 
     * @param {IHttpOptions} options optionale Parameter für den request
     * @returns {IHttpOptions} httpOptions
     */
    private static createOptions(options?: IHttpOptions): IHttpOptions {

        const httpOptions = Object.assign({
            headers: new HttpHeaders({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'content-type': 'application/json',
            }),
        }, options);

        if (!!GlobalSettings.token) {
            httpOptions.headers = (httpOptions.headers as HttpHeaders).append('authorization', `Bearer ${GlobalSettings.token}`);
        }

        if (!!GlobalSettings.connectionId) {
            httpOptions.headers = (httpOptions.headers as HttpHeaders).append('connectionId', GlobalSettings.connectionId);
        }

        return httpOptions;
    }

    /**
     * handeld einen HttpErrorResponse
     * 
     * @param {HttpErrorResponse} e der zu handlende Fehler
     * @returns {Observable} fehler Observable
     */
    private static handleError<T>(e: HttpErrorResponse): Observable<T> {
        if (e.status === 0 || e.status === 504) {
            GlobalSettings.isOnline.next(false);
            return of();
        }
        else {
            return throwError(() => {

                const serverHeader = e.headers.get('server');
                // wenn Antwort nicht von unserem BackEnd
                if (serverHeader && !serverHeader.startsWith('Microsoft-IIS/')) {
                    return new Error(`Die Kommunikation mit dem Server wurde blockiert.<br/>Die blockierende Software identifiziert sich als "${serverHeader}".<br/>Bitte wenden Sie sich an die technische Administration Ihrer Institution.`);
                }

                switch (e.status) {

                    case 401: // Unauthorized
                        {
                            let message = e.message;

                            if (e.error) {
                                message = e.error.message ?? e.error.errorMessage;
                            }

                            return new Unauthorized(message);
                        }

                    case 404: // entität nicht mehr da (z.b. nach löschen)
                        return new NotFound('Die angeforderte Entität ist nicht verfügbar');

                    case 412: // PreconditionFailedError
                        return e;

                    case 500: // PreconditionFailedError
                        return new Error(`${e.error}<br/><br/>${e.message}`);


                    default:
                        return new Error(e.message);
                }
            });
        }
    }

    /**
     * Für einen GET Request aus
     * 
     * @param  {string} action Action welche ausgeführt wird
     * @param  {IHttpOptions} options optionale Parameter für den request
     * @returns {Observable} Observable mit response
     */
    protected get<T>(action: string, options?: IHttpOptions): Observable<T> {
        return this.httpClient
            .get<T>(`${BaseController.backendUrl}/api${action}`, BaseController.createOptions(options))
            .pipe<T>(catchError<T, ObservableInput<T>>(BaseController.handleError));
    }

    /**
     * Für einen POST Request aus
     * 
     * @param  {string} action Action welche ausgeführt wird
     * @param  {any} body optionaler Body
     * @param  {IHttpOptions} options optionale Parameter für den request
     * @returns {Observable} Observable mit response
     */
    protected post<T>(action: string, body?: unknown, options?: IHttpOptions): Observable<T> {
        return this.httpClient
            .post<T>(`${BaseController.backendUrl}/api${action}`, body, BaseController.createOptions(options))
            .pipe<T>(catchError<T, ObservableInput<T>>(BaseController.handleError));
    }

    /**
     * Für einen PUT Request aus
     * 
     * @param  {string} action Action welche ausgeführt wird
     * @param  {any} body optionaler Body
     * @param  {IHttpOptions} options optionale Parameter für den request
     * @returns {Observable} Observable mit response
     */
    protected put<T>(action: string, body?: unknown, options?: IHttpOptions): Observable<T> {
        return this.httpClient
            .put<T>(`${BaseController.backendUrl}/api${action}`, body, BaseController.createOptions(options))
            .pipe(catchError<T, ObservableInput<T>>(BaseController.handleError));
    }

    /**
     * Für einen PATCH Request aus
     * 
     * @param  {string} action Action welche ausgeführt wird
     * @param  {any} body optionaler Body
     * @param  {IHttpOptions} options optionale Parameter für den request
     * @returns {Observable} Observable mit response
     */
    protected patch<T>(action: string, body?: unknown, options?: IHttpOptions): Observable<T> {
        return this.httpClient
            .patch<T>(`${BaseController.backendUrl}/api${action}`, body, BaseController.createOptions(options))
            .pipe(catchError<T, ObservableInput<T>>(BaseController.handleError));
    }

    /**
     * Für einen DELETE Request aus
     * 
     * @param  {string} action Action welche ausgeführt wird
     * @param  {IHttpOptions} options optionale Parameter für den request
     * @returns {Observable} Observable mit response
     */
    protected delete<T>(action: string, options?: IHttpOptions): Observable<T> {
        return this.httpClient
            .delete<T>(`${BaseController.backendUrl}/api${action}`, BaseController.createOptions(options))
            .pipe(catchError<T, ObservableInput<T>>(BaseController.handleError));
    }

    /**
     * Für einen HEAD Request aus
     * 
     * @param  {string} action Action welche ausgeführt wird
     * @param  {IHttpOptions} options optionale Parameter für den request
     * @returns {Observable} Observable mit response
     */
    protected head<T>(action: string, options?: IHttpOptions): Observable<T> {
        return this.httpClient
            .head<T>(`${BaseController.backendUrl}/api${action}`, BaseController.createOptions(options))
            .pipe(catchError<T, ObservableInput<T>>(BaseController.handleError));
    }

    /**
     * Für einen generischen Request aus
     * 
     * @param  {string} action Action welche ausgeführt wird
     * @returns {Observable} Observable mit response
     */
    protected download(action: string): Observable<Blob | null> {
        return this.httpClient.get(
            `${BaseController.backendUrl}/api${action}`,
            {
                headers: new HttpHeaders({
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'Authorization': `Bearer ${GlobalSettings.token}`,
                }),
                responseType: 'blob',
            },
        ).pipe(
            catchError<Blob | null, Observable<Blob>>(BaseController.handleError),
        );
    }
}
