import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { IListTuple } from '@ucba/sdk/interfaces';

/**
 * Komponente, welche eine Radio Button darstellt
 */
@Component({
  selector: 'cxad-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class RadioComponent<T> implements OnInit {

    @Input() public control!: AbstractControl;
    @Input() public labelText!: string;
    @Input() public comment: string | undefined;
    @Input() public enum: T | undefined;
    @Input() public array: IListTuple<string>[] | null | undefined; //import from ucba?
    @Input() public translationKey!: string;
    public isRequired: boolean | undefined;

    /**
     * gibt den aktuellen AbstractControl als FormControl zurück
     *
     * @returns {FormControl} control
     */
    public get formControl(): FormControl {
        return this.control as FormControl;
    }

    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {
        // @see https://stackoverflow.com/a/43904237
        const validators = !!this.control && !!this.control.validator ? this.control.validator(new FormControl()) : {};
        this.isRequired = !!validators && ('required' in validators);
    }
}
