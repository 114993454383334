import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { DocumentType, EmployeeStatus, OrganisationalUnitResponsibilityType } from '@ntag-ef/finprocess-enums/finadvisory';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { NGXLogger } from 'ngx-logger';
import { PDFDocument } from 'pdf-lib';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

import { TagListDialogComponent } from '../../components/tag-list-dialog/tag-list-dialog.component';
import { DocumentArea, UploadFileResult } from '../../enums';
import { IAssignDocumentData, IDirectUploadData, IDocumentSectionArea, IFileUploadSection, IFileUploadSectionItem, IGlobalState, IListGroup, IListResult, IPdfResult, IReferenceTuple, ISectionUploadData, IUploadFileResult } from '../../interfaces';
import { IDocumentModel, IFileModel } from '../../models';
import { IPostDocumentFileRequest } from '../../requests';
import { SdkClearSelectedDocuments, SdkDeleteSignature } from '../../statemanagement/actions';
import { DebtorState, FileState, FinancingMapState, HouseholdState, LiabilityState, RealEstateState, SignatureState } from '../../statemanagement/states';
import { GlobalSettings } from '../../utils';
import { DOCUMENT_CARD_VALIDATION_MAP, IDocumentValidation } from '../../validations';
import { DataService } from '../data/data.service';
import { HelperService } from '../helper/helper.service';

import { FileHelper } from './file.helper';

/**
 * class for file and photo handling. All functions with 'pick' open a nativ view to chose file from device
 */
@Injectable()

export class FileService {
    public static documentName: string | undefined;

    /** 
     * Standard Konstruktor
     *
     * @param { Router } router Router injector
     * @param { Store } store Store injector
     * @param { MatDialog } matDialog MatDialog injector
     * @param { DataService } dataService DataService injector
     * @param { WaiterService } waiter WaiterService injector
     * @param { NotificationService } notificationService NotificationService injector
     * @param { TranslateService } translate TranslateService injector
     * @param { NGXLogger } logger NGXLogger injector
     */
    public constructor(
        private router: Router,
        private store: Store,
        private matDialog: MatDialog,
        private dataService: DataService,
        private waiter: WaiterService,
        private notificationService: NotificationService,
        private translate: TranslateService,
        private logger: NGXLogger,
    ) { }

    /**
     * erstellt eine liste für den Dialog, anhand der übergebenen Bereiche
     * 
     * @param  {IFileUploadSection[]} sectionList benötigte Bereiche
     * @returns {IListGroup} Liste an Groups
     */
    private static generateListGroupsFromSections(sectionList: IFileUploadSection[]): IListGroup[] {
        return sectionList.map<IListGroup>(section => ({
            name: section.name,
            items: section.items.map(item => ({
                area: section.area,
                name: item.name,
                documentType: item.documentType,
                referenceProperty: item.referenceProperty,
                references: item.references,
                hasRequired: item.references.some(({ required }) => required),
            })),
        }));
    }

    /**
     * wandelt ein document section area in eine file upload section um
     * 
     * @param  {IDocumentSectionArea} sectionArea zu wandelnde Area
     * @param  {IDocumentValidation[]} validations validations für den bereich
     * @returns {IFileUploadSection} gewandelte Section
     */
    public toFileUploadSection(sectionArea: IDocumentSectionArea, validations: IDocumentValidation[]): IFileUploadSection {
        return {
            area: sectionArea.area,
            name: sectionArea.name,
            items: validations.reduce<IFileUploadSectionItem[]>((items, val) => {
                const reference = sectionArea.referenceObject[val.referenceProperty];

                if (!!reference && (!Array.isArray(reference) || reference.length > 0)) {
                    const refTuples = this.checkValidation(sectionArea.area, reference, val);

                    if (refTuples.length > 0) {
                        const newItem: IFileUploadSectionItem = {
                            name: DocumentType.translate(val.type) ?? '',
                            documentType: val.type,
                            referenceProperty: val.referenceProperty,
                            references: refTuples,
                        };

                        return [...items, newItem]
                    }
                }

                return items;

            }, []),
        }
    }

    /**
     * öffnet zum übergebenen Bereich ein Dialog zur Auswahl des Ziel Dokuments
     * 
     * @param { ISectionUploadData } data Daten um den Upload zu handlen
     * @returns { Promise } void Promise
     */
    public async handleSectionUpload(data: ISectionUploadData): Promise<void> {

        if (!!data.fileList && data.fileList.length > 0) {
            const result = await this.validateFiles(data.fileList);

            if (result.type === UploadFileResult.ALL_VALID) {
                const groups = FileService.generateListGroupsFromSections(data.sections);
                const selectedItem = await this.showList(groups);
                return !!selectedItem ? this.handleNewFiles(data.fileList, selectedItem) : Promise.resolve();
            }
            else {
                this.showUploadError(result);
                return Promise.resolve();
            }
        }
        else {
            return Promise.resolve();
        }
    }

    /**
     * Läd ein oder mehrere Dokumente direkt zu einem Typ hoch
     * 
     * @param { IDirectUploadData } data Daten um den Upload zu handlen
     * @returns { Promise } void Promise
     */
    public async handleDirectUpload(data: IDirectUploadData): Promise<void> {

        if (!!data.fileList && data.fileList.length > 0) {
            const result = await this.validateFiles(data.fileList);

            if (result.type === UploadFileResult.ALL_VALID) {

                const dummyItem: Partial<IListResult> = {
                    area: data.area,
                    documentType: data.documentType,
                    metaType: data.metaType,
                    referenceId: data.referenceId,
                };

                if (data.area === DocumentArea.Unsorted) {
                    dummyItem.name = '';
                    dummyItem.referenceProperty = 'financingMapId';
                }
                else {
                    const validation = DOCUMENT_CARD_VALIDATION_MAP[data.area].find(({ type }) => type === data.documentType);

                    if (!!validation && HelperService.hasValue(data.documentType)) {
                        dummyItem.name = DocumentType.translate(data.documentType) ?? '';
                        dummyItem.referenceProperty = validation.referenceProperty;
                    }
                    else {
                        return Promise.resolve();
                    }
                }

                return !!dummyItem ? this.handleNewFiles(data.fileList, dummyItem as IListResult) : Promise.resolve();
            }
            else {
                this.showUploadError(result);
                return Promise.resolve();
            }
        }
        else {
            return Promise.resolve();
        }
    }

    /**
     * öffnet zum übergebenen Bereich ein Dialog zur Auswahl des Ziel Dokuments
     * 
     * @param { IAssignDocumentData } data Daten um das umsortieren zu handlen
     * @returns { Promise } void Promise
     */
    public async handleAssignDocument(data: IAssignDocumentData): Promise<void> {

        const groups = FileService.generateListGroupsFromSections(data.sections);
        const selectedItem = await this.showList(groups);

        if (!!selectedItem) {

            for (const documentId of data.sourceDocumentIds) {

                const changeDocument: Partial<IDocumentModel> = {
                    id: documentId,
                    debtorId: undefined,
                    financingMapId: undefined,
                    realEstateId: undefined,
                    householdId: undefined,
                    type: selectedItem.documentType,
                    name: selectedItem.name,
                };

                const local = this.store.selectSnapshot((state: IGlobalState) => state.documentState.data).find(({ id }) => id === changeDocument.id);
                if (!!local) {
                    const files = this.store.selectSnapshot(FileState.filesByDocumentIds)([local.id])
                    const signed = [DocumentArea.Signatures, DocumentArea.RequiredDocuments, DocumentArea.HouseholdDocuments].includes(selectedItem.area);

                    for (const f of files) {
                        if (f.isSigned !== signed) {
                            await this.dataService.updateFile(f.id, signed);
                        }
                    }
                }

                (changeDocument as Record<string, unknown>)[selectedItem.referenceProperty] = selectedItem.referenceId;

                const toSave = Object.assign({}, local, changeDocument);
                await this.dataService.updateDocument(toSave);
            }
        }
    }

    /**
     * die Tag-Liste anzeigen und die angegebene Datei mit dem ausgewählten Dokumenttyp speichern
     * 
     * @param {IListGroup[]} groups IListGroup welche IListResult beinhaltet
     * @returns {Promise<IListResult | null>} IListResult
     */
    private showList(groups: IListGroup[]): Promise<IListResult | undefined> {
        return new Promise<IListResult | undefined>(resolve => {
            const listModal = this.matDialog.open<TagListDialogComponent, IListGroup[], IListResult>
                (TagListDialogComponent, { data: groups });

            listModal.afterClosed().pipe(take(1)).subscribe(result => {
                if (!!result) {
                    this.store.dispatch(new SdkClearSelectedDocuments());
                    resolve(result);
                }
            });
        });
    }

    /**
     * Öffnen eines Document Viewers entsprechend dem Dateityp mimeType
     * 
     * @param {string} fileId ID der Datei
     * @param {string} fileName Name der Datei
     * @param {string} mimeType Mime Type der Datei
     * @param {string} title Title der zugeordneten Kategorie
     */
    public async showFile(fileId: string, fileName: string, mimeType: string, title?: string): Promise<void> {
        if (!!GlobalSettings.validShowMimeTypes.includes(mimeType)) {
            await this.router.navigate(['/document-viewer'], {
                queryParams: {
                    title,
                    fileName,
                    mimeType,
                    fileId,
                },
            });
        }
    }

    /**
     * läd den Finanzierungsexport als PDF und öffnet diesen im PDF Viewer
     * 
     * @param {string} financingMapId ID der Finanzierung
     * @param {string} title angezeigter Titel
     */
    public async showFinancingMapFile(financingMapId: string, title: string): Promise<void> {
        await this.router.navigate(['/document-viewer'], {
            queryParams: {
                financingMapId,
                title,
            },
        });

    }

    /**
     * Dateien im Application State speichern
     * 
     * @param {FileList} fileList Liste an zu speichernden Dateien
     * @param {IListResult} item item
     * @param { boolean } showWaiter soll wärend des Uploads ein Waiter angezeigt werden (default: true)
     */
    private handleNewFiles(fileList: FileList, item: IListResult, showWaiter = true) {

        if (showWaiter) {
            this.waiter.show();
        }

        return new Promise<void>((resolve, reject) => {
            const docFileRequests: Array<IPostDocumentFileRequest> = [];
            const financingMap = this.store.selectSnapshot(FinancingMapState.current);

            for (let i = 0; i < fileList.length; i++) {
                const file = fileList.item(i);

                // Wenn File nicht gesetzt ist nächster schleifen durchlauf
                if (!file) {
                    continue;
                }

                const newDocument: Partial<IDocumentModel> = {
                    type: item.documentType,
                    metaType: item.metaType,
                    name: item.name,
                };

                (newDocument as Record<string, unknown>)[item.referenceProperty] = item.referenceId;

                const parts = FileHelper.getNameParts(file.name);

                const newFile: Partial<IFileModel> = {
                    name: parts.name,
                    mimeType: file.type,
                    length: file.size,
                    extension: parts.extension,
                };

                docFileRequests.push({
                    document: newDocument,
                    file: newFile,
                    content: file,
                });
            }

            if (!!financingMap) {
                const fileRequests: Promise<void>[] = [];

                const setIsSigned = [DocumentArea.Signatures, DocumentArea.RequiredDocuments, DocumentArea.HouseholdDocuments].includes(item.area);

                for (const request of docFileRequests) {
                    request.file.isSigned = setIsSigned;
                    fileRequests.push(this.dataService.createFile(request));
                }

                Promise.all(fileRequests)
                    .then(() => {
                        this.waiter.hide();
                        if (item.area === DocumentArea.Signatures) {
                            this.cleanUpSignatures(item).then(resolve).catch(reject);
                        }
                        else {
                            resolve();
                        }
                    })
                    .catch(error => {
                        this.waiter.hide();
                        reject(error);
                    });
            }
        });
    }

    /**
     * prüft ob ein document angezeigt wird, es ein Pflichtdokument ist
     * oder für mehrere Entitäten zählt
     * 
     * @param {DocumentArea} area der Bereich in dem sich das Dokument befindet
     * @param {string | string[]} referenceIds ids für mehrere Entäten
     * @param {IDocumentValidation} val die validierung des zu prüfenden Dokuments
     * @returns {IReferenceTuple[]} Array aus id/required tupels für jede Entität
     */
    // eslint-disable-next-line complexity
    private checkValidation(area: DocumentArea, referenceIds: string | string[], val: IDocumentValidation): IReferenceTuple[] {
        const financingMap = this.store.selectSnapshot(FinancingMapState.current);
        const realEstates = this.store.selectSnapshot(RealEstateState.current);
        const households = this.store.selectSnapshot(HouseholdState.current);
        const debtors = this.store.selectSnapshot(DebtorState.current);
        const liabilities = this.store.selectSnapshot(LiabilityState.current);

        // Sollt es keine Selbstständigen geben werden die Dokumente für selbstständige rausgenommen
        if (val.responsibility === OrganisationalUnitResponsibilityType.SelfEmployed &&
            !debtors.some(({ employeeStatus }) => employeeStatus === EmployeeStatus.SelfEmployed)) {
            return [];
        }

        switch (area) {
            case DocumentArea.Debtor: {
                if (Array.isArray(referenceIds)) {
                    return referenceIds.reduce<IReferenceTuple[]>((references, debtorId) => {
                        const debtor = debtors.find(({ id }) => id === debtorId);
                        return val.visible(debtor) ? [
                            ...references, {
                                referenceId: debtorId,
                                required: val.required(debtor),
                            }] : references;
                    }, []);
                }
                else {
                    const debtor = debtors.find(({ id }) => id === referenceIds);
                    return !!debtor && val.visible(debtor) ? [{
                        referenceId: referenceIds,
                        required: val.required(debtor),
                    }] : [];
                }
            }

            case DocumentArea.HouseHold: {
                if (val.useForAnyDebtor) {
                    const asArray = Array.isArray(referenceIds) ? referenceIds : [referenceIds];
                    return asArray.reduce<IReferenceTuple[]>((references, debtorId) => {
                        const debtor = debtors.find(({ id }) => id === debtorId);
                        return val.visible(debtor) ? [
                            ...references, {
                                referenceId: debtorId,
                                required: val.required(debtor),
                            }] : references;
                    }, []);
                }
                else {
                    // Sollte hier immer nur eine Id reinkommen
                    const asSingle = Array.isArray(referenceIds) ? referenceIds[0] : referenceIds;
                    const household = households.find(({ id }) => id === asSingle);

                    return !!household && val.visible(household) ? [{
                        referenceId: asSingle,
                        required: val.required(household),
                    }] : [];
                }
            }

            case DocumentArea.FinancingPlan: {

                if (val.useForAnyDebtor) {
                    const asArray = Array.isArray(referenceIds) ? referenceIds : [referenceIds];
                    return asArray.reduce<IReferenceTuple[]>((references, debtorId) => {
                        const debtor = debtors.find(({ id }) => id === debtorId);
                        return val.visible(debtor) ? [
                            ...references, {
                                referenceId: debtorId,
                                required: val.required(debtor),
                            }] : references;
                    }, []);
                }
                else {
                    // Sollte hier immer nur eine Id reinkommen
                    const asSingle = Array.isArray(referenceIds) ? referenceIds[0] : referenceIds;

                    return !!financingMap && val.visible(liabilities) ? [{
                        referenceId: asSingle,
                        required: val.required(liabilities),
                    }] : [];
                }
            }

            case DocumentArea.RealEstate: {
                // Sollte hier immer nur eine Id reinkommen
                const asSingle = Array.isArray(referenceIds) ? referenceIds[0] : referenceIds;

                const realestate = realEstates.find(({ id }) => id === asSingle);
                const visibilityMap = RealEstateState.visibleMap(realestate);

                return !!realestate && val.visible(visibilityMap) ? [{
                    referenceId: asSingle,
                    required: val.required(visibilityMap),
                }] : [];
            }

            case DocumentArea.Other: {
                // Sollte hier immer nur eine Id reinkommen
                const asSingle = Array.isArray(referenceIds) ? referenceIds[0] : referenceIds;

                return [{
                    referenceId: asSingle,
                    required: false,
                }];
            }

            case DocumentArea.Signatures: {
                // Sollte hier immer nur eine Id reinkommen
                const asSingle = Array.isArray(referenceIds) ? referenceIds[0] : referenceIds;

                return !!financingMap && val.visible(financingMap) ? [{
                    referenceId: asSingle,
                    required: val.required(financingMap),
                }] : [];
            }

            case DocumentArea.RequiredDocuments: {
                // Sollte hier immer nur eine Id reinkommen
                const asSingle = Array.isArray(referenceIds) ? referenceIds[0] : referenceIds;

                return !!financingMap && val.visible(financingMap) ? [{
                    referenceId: asSingle,
                    required: val.required(financingMap),
                }] : [];
            }

            default: return [];
        }
    }

    /**
     * Löschen von Unterschriften
     * 
     * @param {IListResult} item Dokument was hochgeladen werden soll
     */
    private async cleanUpSignatures(item: IListResult) {
        const finacingMapId = item.referenceProperty === 'financingMapId' ? item.referenceId : '';
        if (!!finacingMapId && !!item.documentType) {
            if (item.area === DocumentArea.Signatures && item.documentType !== DocumentType.StandardInformationVVISignature) {
                const signatures = this.store.selectSnapshot(SignatureState.financingSignaturesByType)(
                    finacingMapId, [item.documentType],
                );
                for (const signature of signatures) {
                    await this.dataService.deleteSignature(signature.id);
                    this.store.dispatch(new SdkDeleteSignature(signature.id));
                }
            }
        }
    }

    /**
     * prüft die ausgewählten dateien auf typ und größe
     * 
     * @param {FileList} fileList die liste der hochzuladenden Dateien
     * @returns {UploadFileResult} ergebnis der validierung
     */
    private async validateFiles(fileList: FileList): Promise<IUploadFileResult> {
        let sizeSum = this.store.selectSnapshot(FileState.filesSizeOfAll);

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList.item(i);

            if (!!file) {

                if (!GlobalSettings.validUploadMimeTypes.includes(file.type)) {
                    return { type: UploadFileResult.WRONG_FORMAT, file };
                }

                if (file.size === 0) {
                    // zusätzliches Logging in der Datenbank zum nachvollziehen
                    this.logger.warn(new Error(`NO_SIZE Error "${file.name}"`));

                    return { type: UploadFileResult.NO_SIZE, file };
                }

                if (file.size > GlobalSettings.maxSize) {
                    return { type: UploadFileResult.SIZE_QUOTA, file };
                }

                sizeSum += file.size;

                if (sizeSum > GlobalSettings.maxSizeForAll) {
                    return { type: UploadFileResult.SIZE_QUOTA_ALL, file };
                }

                const canBeSave = await this.checkPDF(FileHelper.arrayBufferToBlob(await file.arrayBuffer(), file.type), file.type);

                if (canBeSave.result && canBeSave.type === UploadFileResult.ENCRYPTED_PDF) {
                    const role = await firstValueFrom(this.notificationService.confirmYesNo(
                        this.translate.instant('global.UploadFileResult.ENCRYPTED_PDF_Title'),
                        this.translate.instant('global.UploadFileResult.ENCRYPTED_PDF_Text', { document: file.name }),
                    ));

                    if (role === 'submit') {
                        return { type: UploadFileResult.ALL_VALID, file };
                    }
                    else {
                        return { type: UploadFileResult.UPLOAD_CANCLED, file };
                    }
                }

                if (canBeSave.type === UploadFileResult.CORRUPTED_PDF) {

                    return { type: UploadFileResult.CORRUPTED_PDF, file };
                }
            }
        }

        return { type: UploadFileResult.ALL_VALID };
    }


    /**
     * zeigt die Fehler an, welche beim Upload auftreten können
     * 
     * @param {UploadFileResult} result aufgetretener Fehler
     */
    private showUploadError(result: IUploadFileResult) {
        if (!!result && result.type !== UploadFileResult.ALL_VALID) {
            this.notificationService.alert(
                this.translate.instant(`global.UploadFileResult.${result.type}_Title`),
                this.translate.instant(`global.UploadFileResult.${result.type}_Text`, {
                    maxSize: GlobalSettings.maxSizeInMB,
                    maxSizeAll: GlobalSettings.maxSizeForAllInMB,
                    document: result.file ? result.file.name : '',
                }),
            )
        }
    }


    /**
     * 
     * Überprüft PDF-Dateien ob diese beschädigt oder verschlüsselt sind
     * 
     * @param {Blob} fileBlob Blob des Files
     * @param {string} mimeType mimeType des Files
     * @returns { Promise } boolean Promise: true -> Datei ist nicht beschädigt/verschlüsselt false -> Datei ist beschädigt/verschlüsselt
     */
    // eslint-disable-next-line class-methods-use-this
    private checkPDF(fileBlob: Blob, mimeType: string): Promise<IPdfResult> {
        return new Promise<IPdfResult>((resolve, reject) => {
            if (mimeType === GlobalSettings.MIME_PDF) {
                const reader = new FileReader();
                reader.readAsArrayBuffer(fileBlob);
                reader.onerror = error => {
                    reject(error);
                };

                reader.onload = async () => {
                    const readerResult = reader.result;
                    if (!!readerResult) {
                        const pdf = await PDFDocument.load(readerResult, { ignoreEncryption: true });
                        if (!pdf.catalog) {
                            resolve({ result: false, type: UploadFileResult.CORRUPTED_PDF });
                        }
                        else if (pdf.isEncrypted) {
                            resolve({ result: true, type: UploadFileResult.ENCRYPTED_PDF });
                        }
                        else {
                            resolve({ result: true });
                        }
                    }
                    else {
                        resolve({ result: false });
                    }
                };
            }
            else {
                resolve({ result: true });
            }
        });
    }
}
