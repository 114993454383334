import { IBackendBaseModel } from './backend-base.model';
import { IDocumentModel } from './document.model';

/**
 * A class respresenting a product
 */
export interface IProductModel extends IBackendBaseModel {
    name: string;
    position: number;
    productPackageId: string;
}

export interface IProductVM extends IProductModel {
    documents: IDocumentModel[];
}
