import { IBackendBaseModel } from './backend-base.model';
import { IProductVM } from './product.model';

/**
 * A class respresenting a product package
 */
export interface IProductPackageModel extends IBackendBaseModel {
    name: string;
    position: number;
    financingMapId: string;
}

export interface IProductPackageVM extends IProductPackageModel {
    products: IProductVM[];
}
