import { CalculationVersionHelper, ComfortProductCalculationService, DebitorCalculationService, FinancingMapCalculationService, HouseholdCalculationService, LiabilityCalculationService, NewLiabilityCalculationService, ProductCalculationService, RealEstateCalculationService } from '@ntag-ef/finprocess-calculations';

/**
 * erstellt und verwaltet instanzen der VPC Calculation Services
 */
export class CalculationFactoryService {
    private static instanceMap: Record<string, Record<number, unknown>> = {};

    private static readonly financingSrvKey = 'financingMapCalculationService';
    private static readonly realEstateSrvKey = 'realEstateCalculationService';
    private static readonly housholdSrvKey = 'householdCalculationService';
    private static readonly liabilitySrvKey = 'liabilityCalculationService';
    private static readonly newLiabilitySrvKey = 'newLiabilityCalculationService';
    private static readonly debtorSrvKey = 'debitorCalculationService';
    private static readonly productSrvKey = 'productCalculationService';
    private static readonly comfortProductSrvKey = 'comfortProductCalculationService';

    /**
     * gibt eine Instanz FinancingMapCalculationService zur übergebenen/aktuellen Version zurück
     * 
     * @param {number} version version des Services
     * @returns {FinancingMapCalculationService} instanz des services
     */
    public static financingMapCalculationService(version?: number): FinancingMapCalculationService {

        version = version !== undefined ? version : CalculationVersionHelper.currentCalculationVersion;

        if (!CalculationFactoryService.instanceMap[CalculationFactoryService.financingSrvKey]) {
            CalculationFactoryService.instanceMap[CalculationFactoryService.financingSrvKey] = {};
        }

        if (!CalculationFactoryService.instanceMap[CalculationFactoryService.financingSrvKey][version]) {
            CalculationFactoryService.instanceMap[CalculationFactoryService.financingSrvKey][version] = new FinancingMapCalculationService(version);
        }

        return CalculationFactoryService.instanceMap[CalculationFactoryService.financingSrvKey][version] as FinancingMapCalculationService;
    }

    /**
     * gibt eine Instanz RealEstateCalculationService zur übergebenen/aktuellen Version zurück
     * 
     * @param {number} version version des Services
     * @returns {RealEstateCalculationService} instanz des services
     */
    public static realEstateCalculationService(version?: number): RealEstateCalculationService {

        version = version !== undefined ? version : CalculationVersionHelper.currentCalculationVersion;

        if (!CalculationFactoryService.instanceMap[CalculationFactoryService.realEstateSrvKey]) {
            CalculationFactoryService.instanceMap[CalculationFactoryService.realEstateSrvKey] = {};
        }

        if (!CalculationFactoryService.instanceMap[CalculationFactoryService.realEstateSrvKey][version]) {
            CalculationFactoryService.instanceMap[CalculationFactoryService.realEstateSrvKey][version] = new RealEstateCalculationService(version);
        }

        return CalculationFactoryService.instanceMap[CalculationFactoryService.realEstateSrvKey][version] as RealEstateCalculationService;
    }

    /**
     * gibt eine Instanz HouseholdCalculationService zur übergebenen/aktuellen Version zurück
     * 
     * @param {number} version version des Services
     * @returns {HouseholdCalculationService} instanz des services
     */
    public static householdCalculationService(version?: number): HouseholdCalculationService {

        version = version !== undefined ? version : CalculationVersionHelper.currentCalculationVersion;

        if (!CalculationFactoryService.instanceMap[CalculationFactoryService.housholdSrvKey]) {
            CalculationFactoryService.instanceMap[CalculationFactoryService.housholdSrvKey] = {};
        }

        if (!CalculationFactoryService.instanceMap[CalculationFactoryService.housholdSrvKey][version]) {
            CalculationFactoryService.instanceMap[CalculationFactoryService.housholdSrvKey][version] = new HouseholdCalculationService(version);
        }

        return CalculationFactoryService.instanceMap[CalculationFactoryService.housholdSrvKey][version] as HouseholdCalculationService;
    }

    /**
     * gibt eine Instanz LiabilityCalculationService zur übergebenen/aktuellen Version zurück
     * 
     * @param {number} version version des Services
     * @returns {LiabilityCalculationService} instanz des services
     */
    public static liabilityCalculationService(version?: number): LiabilityCalculationService {

        version = version !== undefined ? version : CalculationVersionHelper.currentCalculationVersion;

        if (!CalculationFactoryService.instanceMap[CalculationFactoryService.liabilitySrvKey]) {
            CalculationFactoryService.instanceMap[CalculationFactoryService.liabilitySrvKey] = {};
        }

        if (!CalculationFactoryService.instanceMap[CalculationFactoryService.liabilitySrvKey][version]) {
            CalculationFactoryService.instanceMap[CalculationFactoryService.liabilitySrvKey][version] = new LiabilityCalculationService(version);
        }

        return CalculationFactoryService.instanceMap[CalculationFactoryService.liabilitySrvKey][version] as LiabilityCalculationService;
    }

    /**
     * gibt eine Instanz NewLiabilityCalculationService zur übergebenen/aktuellen Version zurück
     * 
     * @param {number} version version des Services
     * @returns {NewLiabilityCalculationService} instanz des services
     */
    public static newLiabilityCalculationService(version?: number): NewLiabilityCalculationService {

        version = version !== undefined ? version : CalculationVersionHelper.currentCalculationVersion;

        if (!CalculationFactoryService.instanceMap[CalculationFactoryService.newLiabilitySrvKey]) {
            CalculationFactoryService.instanceMap[CalculationFactoryService.newLiabilitySrvKey] = {};
        }

        if (!CalculationFactoryService.instanceMap[CalculationFactoryService.newLiabilitySrvKey][version]) {
            CalculationFactoryService.instanceMap[CalculationFactoryService.newLiabilitySrvKey][version] = new NewLiabilityCalculationService(version);
        }

        return CalculationFactoryService.instanceMap[CalculationFactoryService.newLiabilitySrvKey][version] as NewLiabilityCalculationService;
    }

    /**
     * gibt eine Instanz DebitorCalculationService zur übergebenen/aktuellen Version zurück
     * 
     * @param {number} version version des Services
     * @returns {DebitorCalculationService} instanz des services
     */
    public static debtorCalculationService(version?: number): DebitorCalculationService {

        version = version !== undefined ? version : CalculationVersionHelper.currentCalculationVersion;

        if (!CalculationFactoryService.instanceMap[CalculationFactoryService.debtorSrvKey]) {
            CalculationFactoryService.instanceMap[CalculationFactoryService.debtorSrvKey] = {};
        }

        if (!CalculationFactoryService.instanceMap[CalculationFactoryService.debtorSrvKey][version]) {
            CalculationFactoryService.instanceMap[CalculationFactoryService.debtorSrvKey][version] = new DebitorCalculationService(version);
        }

        return CalculationFactoryService.instanceMap[CalculationFactoryService.debtorSrvKey][version] as DebitorCalculationService;
    }

    /**
     * gibt eine Instanz ProductCalculationService zur übergebenen/aktuellen Version zurück
     * 
     * @param {number} version version des Services
     * @returns {ProductCalculationService} instanz des services
     */
     public static productCalculationService(version?: number): ProductCalculationService {

        version = version !== undefined ? version : CalculationVersionHelper.currentCalculationVersion;

        if (!CalculationFactoryService.instanceMap[CalculationFactoryService.productSrvKey]) {
            CalculationFactoryService.instanceMap[CalculationFactoryService.productSrvKey] = {};
        }

        if (!CalculationFactoryService.instanceMap[CalculationFactoryService.productSrvKey][version]) {
            CalculationFactoryService.instanceMap[CalculationFactoryService.productSrvKey][version] = new ProductCalculationService(version);
        }

        return CalculationFactoryService.instanceMap[CalculationFactoryService.productSrvKey][version] as ProductCalculationService;
    }

    /**
     * gibt eine Instanz ComfortProductCalculationService zur übergebenen/aktuellen Version zurück
     * 
     * @param {number} version version des Services
     * @returns {ComfortProductCalculationService} instanz des services
     */
     public static comfortProductCalculationService(version?: number): ComfortProductCalculationService {

        version = version !== undefined ? version : CalculationVersionHelper.currentCalculationVersion;

        if (!CalculationFactoryService.instanceMap[CalculationFactoryService.comfortProductSrvKey]) {
            CalculationFactoryService.instanceMap[CalculationFactoryService.comfortProductSrvKey] = {};
        }

        if (!CalculationFactoryService.instanceMap[CalculationFactoryService.comfortProductSrvKey][version]) {
            CalculationFactoryService.instanceMap[CalculationFactoryService.comfortProductSrvKey][version] = new ComfortProductCalculationService(version);
        }

        return CalculationFactoryService.instanceMap[CalculationFactoryService.comfortProductSrvKey][version] as ComfortProductCalculationService;
    }
}
