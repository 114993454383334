<div fxLayout="column">
    <mat-label>{{ labelText }}</mat-label>
    <mat-form-field *ngIf="!!control" [hideRequiredMarker]="!isRequired" appearance="outline">
        <input *ngIf="isRequired" matInput [matDatepicker]="picker" [formControl]="formControl" required>
        <input *ngIf="!isRequired" matInput [matDatepicker]="picker" [formControl]="formControl">
        <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-hint *ngIf="!!comment">{{ comment }}</mat-hint>
        <mat-error cxad-form-field-error *ngIf="control.invalid" [control]="control"></mat-error>
    </mat-form-field>
</div>