import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { HelperService } from '../services';

/**
 * validator class for numbers validation
 */
export class NumberValidation {
    /**
     * Validierung ob die Anzahl an stellen stimmt
     * 
     * @param {number} amount zu prüfender Wert
     * @returns {ValidatorFn} ValidatorFn
     */
    public static preciseAmountOfNumbers(amount: number): ValidatorFn {
        return (ac: AbstractControl) => {
            if (!isNaN(amount) && amount !== null && !HelperService.isNullOrEmpty(ac.value)) {
                const regEx = new RegExp(`^[0-9]{${amount}}$`);
                if (!regEx.test(ac.value) || (typeof ac.value === 'string' && ac.value.length !== amount)) {
                    return {
                        preciseAmountOfNumbers: {
                            requiredNumbers: amount,
                        },
                    };
                }
            }
            return null;
        };
    }

    /**
     * Validierung ab die zahl durch 12 teilbar ist
     * 
     * @param {AbstractControl} ac zu prüfendendes Control
     * @returns {ValidatorFn} ValidatorFn
     */
    public static isDivisibleByTwelve(ac: AbstractControl): ValidationErrors | null {

        if (ac.value !== null) {
            // Parse input value to number
            const inputValueAsNumber = parseInt(ac.value, 10);

            // Input is not divisible by 12
            if (inputValueAsNumber % 12 !== 0) {
                ac.setErrors({ divisible: false }, { emitEvent: false });
                return { divisible: false };
            }
        }

        return null;
    }

    /**
     * Validierung ob der Wert größer als value ist
     * 
     * @param {number} value zu prüfender Wert
     * @returns {ValidatorFn} ValidatorFn
     */
    public static greaterThan(value: number): ValidatorFn {
        return (ac: AbstractControl) => {
            if (!isNaN(value) && value !== null && !HelperService.isNullOrEmpty(ac.value)) {
                const v = typeof ac.value === 'number' ? ac.value : parseFloat(ac.value);
                if (value >= v) {
                    return {
                        greaterThan: {
                            value,
                        },
                    };
                }
            }
            return null;
        };
    }

    /**
     * Validierung ob der Wert größer als value ist
     * 
     * @param {number} value zu prüfender Wert
     * @returns {ValidatorFn} ValidatorFn
     */
    public static lowerThan(value: number): ValidatorFn {
        return (ac: AbstractControl) => {
            if (!isNaN(value) && value !== null && !HelperService.isNullOrEmpty(ac.value)) {
                const v = typeof ac.value === 'number' ? ac.value : parseFloat(ac.value);
                if (value <= v) {
                    return {
                        lowerThan: {
                            value,
                        },
                    };
                }
            }
            return null;
        };
    }
}
