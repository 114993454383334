/** repräsentiert den key in DOCUMENT_CARD_VALIDATION_MAP */
export enum DocumentArea {
    Debtor = 'debtor',
    HouseHold = 'household',
    FinancingPlan = 'financingplan',
    RealEstate = 'realestate',
    Other = 'other',
    Signatures = 'signature',
    RequiredDocuments = 'requiredDocuments',
    HouseholdDocuments = 'householdDocuments',
    Unsorted = 'unsorted',
}
