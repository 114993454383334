import { IHouseholdModel } from '../../models';

/**
 * Action welche alle Household updated
 */
export class SdkPatchHousehold {
    public static readonly type = '[SDK Household] PATCH';

    /**
     * Standard Konstruktor
     * 
     * @param {IHouseholdModel} payload zu patchende Household
     */
    public constructor(public payload: IHouseholdModel[]) { }
}

/**
 * Action welche ein neues Household hinzufügt, sollte es bereits existieren wird add ignoriert
 */
 export class SdkAddHousehold {
    public static readonly type = '[SDK Household] ADD';

    /**
     * Standard Konstruktor
     * 
     * @param {IHouseholdModel} payload neues Household
     */
    public constructor(public payload: IHouseholdModel) { }
}

/**
 * Action welche ein spezifisches Household updated
 */
export class SdkUpdateHousehold {
    public static readonly type = '[SDK Household] UPDATE';

    /**
     * Standard Konstruktor
     * 
     * @param {IHouseholdModel} payload zu patchendes Household
     */
    public constructor(public payload: IHouseholdModel) { }
}

/**
 * Action welche ein Household entfernt, sollte es nicht existieren wird delete ignoriert
 */
export class SdkDeleteHousehold {
    public static readonly type = '[SDK Household] DELETE';

    /**
     * Standard Konstruktor
     * 
     * @param {string} id id des zu löschenden Households
     */
    public constructor(public id: string) { }
}
