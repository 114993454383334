import { ElementRef, Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { EnumTranslationService, Gender } from '@ntag-ef/finprocess-enums';
import emojiRegex from 'emoji-regex';
import { sort } from 'fast-sort';
import * as moment from 'moment';
import { Observable, Observer, fromEvent, map, merge } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { YesNo } from '../../enums';
import { IListTuple, IModifiedBackend } from '../../interfaces';
import { ICustomerLightModel, IDebtorModel, ILiabilityModel, INewLiabilityModel, IRealEstateModel } from '../../models'
import { ISODate } from '../../types';
import { GlobalSettings } from '../../utils/global-settings';


/**
 * class for global helper methods
 */
@Injectable()
export class HelperService {

    /**
     * Observable getter welcher den Online status des Browsers überwacht
     *
     * @returns {Observable} den online status
     * @see https://stackoverflow.com/a/57069101
     */
    public static get isBrowserOnlineObservable$(): Observable<boolean> {
        return merge(
            fromEvent(window, 'offline').pipe(map(() => false)),
            fromEvent(window, 'online').pipe(map(() => true)),
            new Observable((sub: Observer<boolean>) => {
                sub.next(navigator.onLine);
                sub.complete();
            }));
    }

    /**
     * ersetzt unsichere Zeichen in einem HTML string
     *
     * @see https://stackoverflow.com/questions/6234773/can-i-escape-html-special-chars-in-javascript
     * @param {string} unsafe das "unsichere" HTML
     * @returns {string} sicheres HTML
     */
    public static escapeHtml(unsafe: string): string {
        return unsafe
            .replace(emojiRegex(), '')
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;');
    }

    /**
     * ersetzt alle string line brakes in HTML brakes
     *
     * @param {string} text originaler string
     * @returns {string} string mit ersetzten line brakes
     */
    public static toHTMLBreakes(text: string): string {
        // https://stackoverflow.com/questions/10805125/how-to-remove-all-line-breaks-from-a-string
        return !!text ? text.replace(/(\r\n|\n|\r)/gm, '<br>') : '';
    }

    /**
     * Ersetzt alle Umlaute mit durch den ensprechenden Vokal. Haupsächlich genutzt für sortierung
     *
     * @param {string} text zu untersuchender Text
     * @returns {string} ergebnistext
     */
    public static replaceUmlauts(text: string): string {
        return text
            .replace('Ä', 'A')
            .replace('ä', 'a')
            .replace('Ö', 'O')
            .replace('ö', 'o')
            .replace('Ü', 'U')
            .replace('ü', 'u')
            .replace('ß', 's')
    }

    /**
     * prüft ob der übergebene Wert eine GUID ist
     * 
     * @param {string} value zu prüfender Wert
     * @see https://stackoverflow.com/a/13653180
     * @returns {boolean} ist GUID
     */
    public static isValidGUID(value: string): boolean {
        return (value.length > 0) ?
            (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i).test(value) :
            false;
    }

    /**
     * gibt den aktuellen Tag um 0:00 Uhr zurück
     *
     * @returns {Date} aktueller Tag
     */
    public static today(): Date {
        const now = new Date();
        return HelperService.toUTCDate(now.getFullYear(), now.getMonth() + 1, now.getDate());
    }

    /**
     * prüft ob der übergebene string dem ISO8601 Datumsformat entspricht
     *
     * @param {any} date zu prüfender String
     * @returns {boolean} entspricht dem Format
     */
    public static isISODate(date: unknown): boolean {
        return typeof date === 'string' && (GlobalSettings.iso8601Regex.test(date) || GlobalSettings.dateTimeOffsetRegex.test(date));
    }

    /**
     * prüft ob der übergebene string einem Deutschen Datumsformat entspricht (TT.MM.JJJJ)
     *
     * @param {any} date zu prüfender String
     * @returns {boolean} entspricht dem Format
     */
    public static isGermanDate(date: unknown): boolean {
        return typeof date === 'string' && GlobalSettings.germanDateRegex.test(date);
    }

    /**
     * Parsed Jahr, Monat und Tag in ein UTC Date
     *
     * @see https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Date/UTC
     * @param {number} year das Jahr
     * @param {number} month der Monat
     * @param {number} day der Tag
     * @returns {Date} UTC Datum
     */
    public static toUTCDate(year: number, month: number, day: number): Date {
        // year = integer, month = 0-11, day = 1-31
        return new Date(Date.UTC(year, month - 1, day));
    }

    /**
     * gibt den übergebenen wert in Mikrosekunden zurück
     * 
     * @param {ISODate} date umzuwandelndes Datum
     * @returns {number} Datum als Mikrosekunden
     */
    public static toMicroSeconds(date: ISODate): number {
        if (!HelperService.isISODate(date)) {
            throw new Error('date is not a valid ISO Date');
        }

        let offset = 0;

        // Wenn es mehr als 24Zeichen sind, wird beim umwandeln der mikro teil abgeschnitten
        // new Date('2022-03-23T10:33:36.94403Z').getTime() = 1648031616944
        // new Date('2022-03-23T10:33:36.9440395Z').getTime() = 1648031616944

        // Es werden 4 weitere Stellen mit einbezogen
        const extra = 4;

        if (date.length > 24) {

            const start = date.lastIndexOf('.') + 4; // Anfang mikro teil
            const length = date.lastIndexOf('Z') - start;
            const microPart = date.substr(start, Math.min(extra, length)).padEnd(extra, '0');
            offset = parseInt(microPart, 10);
        }

        return new Date(date).getTime() * Math.pow(10, extra) + offset;
    }

    /**
     * addiert übergebenen Datum x Tage hinzu
     *
     * @see https://stackoverflow.com/questions/1296358/subtract-days-from-a-date-in-javascript?page=1&tab=votes#tab-top
     * @param {Date} date start Datum
     * @param {number} days zu addierende Tage
     * @returns {Date} neues Datum
     */
    public static addDays(date: Date, days: number): Date {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + days);
        return newDate;
    }

    /**
     * addiert übergebenen Datum x Minuten hinzu
     *
     * @param {Date} date start Datum
     * @param {number} minutes zu addierende Miuten
     * @returns {Date} neues Datum
     */
    public static addMinutes(date: Date, minutes: number): Date {
        const newDate = new Date(date);
        newDate.setMinutes(newDate.getMinutes() + minutes);
        return newDate;
    }


    /**
     * addiert übergebenen Datum x Jahre hinzu
     *
     * @see https://stackoverflow.com/questions/8609261/how-to-determine-one-year-from-now-in-javascript
     * @param {Date} date start Datum
     * @param {number} years zu addierende Jahre
     * @returns {Date} neues Datum
     */
    public static addYears(date: Date, years: number): Date {
        const newDate = new Date(date);
        newDate.setFullYear(newDate.getFullYear() + years);
        return newDate;
    }

    /**
     * ermittelt den Abstand zwischen zwei datums in monaten
     *
     * @param {Date} date1 erstes Datum
     * @param {Date} date2 zweites Datum
     * @returns {number} Abstand in Monaten
     */
    public static dateDiffInMonth(date1: Date, date2: Date): number {
        let months = (date2.getFullYear() - date1.getFullYear()) * 12;
        months -= date1.getMonth();
        months += date2.getMonth();
        return Math.abs(months);
    }

    /**
     * ermittelt den Abstand zwischen zwei datums in tagen
     *
     * @param {Date} date1 erstes Datum
     * @param {Date} date2 zweites Datum
     * @returns {number} Abstand in Tagen
     */
    public static dateDiffInDays(date1: Date, date2: Date): number {
        const timeDiff = Math.abs(date2.getTime() - date1.getTime());
        return Math.ceil(timeDiff / (1000 * 3600 * 24));
    }

    /**
     * Gibt das kleinere von zwei Datums zurück
     *
     * @param {Date} date1 erstes Datum
     * @param {Date} date2 zweites Datum
     * @returns {Date} kleineres Datum
     */
    public static dateCompareMin(date1: Date, date2: Date): Date {
        return date1.getTime() < date2.getTime() ? date1 : date2;
    }

    /**
     * Gibt das größere von zwei Datums zurück
     *
     * @param {Date} date1 erstes Datum
     * @param {Date} date2 zweites Datum
     * @returns {Date} größere Datum
     */
    public static dateCompareMax(date1: Date, date2: Date): Date {
        return date1.getTime() > date2.getTime() ? date1 : date2;
    }

    /**
     * parset ein ISODate in das Format "DD.MM.YYYY"
     *
     * @param {string} isodate zu parsendes Datum
     * @returns {string} geparstes Datum
     */
    public static parseISODateToGermanDateString(isodate: ISODate): string | null | undefined {

        if (isodate === null) {
            return null;
        }

        if (isodate === undefined || isodate.length === 0) {
            return undefined;
        }

        const date = new Date(isodate);

        // see https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Date/UTC
        // year = integer, month = 0-11, day = 1-31

        const day = date.getUTCDate().toString().padStart(2, '0');
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = date.getUTCFullYear().toString();

        return `${day}.${month}.${year}`;
    }

    /**
     * erkennt selbstständig welches datumsformat vorliegt und parsed es in eine Datumsobjekt
     *
     * @param {string} date zu parsendes Datum
     * @returns {Date} geparstes Datum
     */
    public static parseAnyDateStringToDate(date: ISODate | string | moment.Moment): Date | undefined {

        if (date !== undefined && date !== null) {
            if (typeof date === 'string') {
                if (HelperService.isGermanDate(date)) {
                    const iso = HelperService.parseGermanDateToISODate(date);

                    if (!!iso) {
                        date = iso;
                    }
                    else {
                        return undefined;
                    }
                }
            }
            else {
                date = (date as moment.Moment).toISOString();
            }

            return new Date(date as string);
        }
        else {
            return undefined;
        }
    }

    /**
     * Gibt das übergebene Datum und Zeit im übergebenen Format zurück
     * 
     * @param {string} format moment formatierungs string
     * @param {Date} date optionales datum welches formatiert werden soll. Leer für aktuelles datum.
     * @returns {string} formatierter datumsstring
     */
    public static formatDate(format: string, date?: Date | string): string {
        return moment(date).format(format);
    }

    /**
     * parset das Format "DD.MM.YYYY" in ein ISODate
     *
     * @param {string} date date in format "DD.MM.YYYY"
     * @returns {ISODate} geparstes Datum
     */
    public static parseGermanDateToISODate(date: string): ISODate | null | undefined {

        if (date === null) {
            return null;
        }

        if (date === undefined || date.length === 0) {
            return undefined;
        }

        const parts = date.match(/(\d+)/g);
        const asDate = !!parts && parts.length === 3 ?
            HelperService.toUTCDate(parseInt(parts[2], 10), parseInt(parts[1], 10), parseInt(parts[0], 10)) : null;

        return !!asDate ? asDate.toISOString() : null;
    }

    /**
     * gibt das aktuellste modified datum aus dem Array zurück
     *
     * @param {any[]} array zu prüfende Elemente
     * @returns {ISODate} aktuellstes modified Datum
     */
    public static getLatestModified<T extends IModifiedBackend>(array: T[]): ISODate | undefined {
        return (Array.isArray(array) && array.length > 0) ? sort(array).desc(it => it.modified)[0].modified : undefined;
    }

    /**
     * kürzt einen string und fügt ... am ende an
     *
     * @param {string} value zu prüfender String
     * @param {number} maxLength max Anzahl erlaubter Zeichen
     * @returns {string} gekürzter String
     */
    public static abbreviateString(value: string, maxLength: number): string {
        return !!value ? (value.length <= maxLength ? value : `${value.substr(0, maxLength - 3)}...`) : '';
    }

    /**
     * erstellt das Namens Label eines Kreditnehmers
     *
     * @param {IDebtorModel} debtor Kreditnehmer
     * @param {number} index Stelle des Kreditnehmers
     * @param {string} defaultDebitorLabel label wenn kein Name vorhanden ist
     * @param {boolean} trim soll der Name ab 50 Zeichen gekürzt werden (default: false)
     * @returns {string} Namens Label
     */
    public static calcDebitorLabel(debtor: IDebtorModel, index: number, defaultDebitorLabel: string, trim = false): string {
        if (!!debtor.firstName && !!debtor.lastName) {
            let displayName = `${debtor.firstName} ${debtor.lastName}`;

            if (trim && displayName.length > 50) {
                displayName = `${displayName.substring(0, 50)}...`;
            }

            return displayName;
        }
        else {
            return `${index + 1}. ${defaultDebitorLabel}`;
        }
    }

    /**
     * erstellt das Label eines Customers
     * 
     * @param {ICustomerLightModel} customer Kreditnehmer
     * @returns {string} Namens Label
     */
    public static calcCustomerLabel(customer: ICustomerLightModel): string {
        let returnCustomerLabel = '';
        if (this.hasValue(customer.gender) && this.hasValue(customer.title)) {
            returnCustomerLabel += `${Gender.translate(customer.gender)} ${customer.title} `;
        }

        if (this.hasValue(customer.gender) && !this.hasValue(customer.title)) {
            returnCustomerLabel += `${Gender.translate(customer.gender)} `;
        }

        if (!this.hasValue(customer.gender) && this.hasValue(customer.title)) {
            returnCustomerLabel += `${customer.title} `;
        }

        returnCustomerLabel += `${customer.firstName} ${customer.lastName}`;
        return returnCustomerLabel;
    }

    /**
     * erstellt das Namens Label eines Kreditnehmers
     *
     * @param {IDebtorModel} debtor Kreditnehmer
     * @param {number} householdIdx Stelle des Haushaltes
     * @param {number} debtorIdx Stelle des Kreditnehmers
     * @param {string} defaultDebitorLabel label wenn kein Name vorhanden ist
     * @param {boolean} trim soll der Name ab 50 Zeichen gekürzt werden (default: false)
     * @returns {string} Namens Label
     */
    public static calcHouseholdDebitorLabel(debtor: IDebtorModel, householdIdx: number, debtorIdx: number, defaultDebitorLabel: string, trim = false): string {
        if (!!debtor.firstName && !!debtor.lastName) {
            let displayName = `${debtor.firstName} ${debtor.lastName}`;

            if (trim && displayName.length > 50) {
                displayName = `${displayName.substring(0, 50)}...`;
            }

            return `${householdIdx + 1}. Haushalt, ${displayName}`;
        }
        else {
            return `${householdIdx + 1}. Haushalt, ${debtorIdx + 1}. ${defaultDebitorLabel}`;
        }
    }


    /**
     * Gibt einen formatierten Adress String zurück
     *
     * @param {string} street Straße
     * @param {string} streetNumber Hausnummer
     * @param {string} zip PLZ
     * @param {string} city Ort
     * @returns {string} formatierte Adresse
     */
    public static formatAddress(street?: string | null, streetNumber?: string | null, zip?: string | null, city?: string | null): string {
        let result = '';

        result += HelperService.hasValue(street) ? `${street} ` : '';
        result += HelperService.getValue(streetNumber, '');

        result = result.trim(); // if no streetNumber

        if ((!!street || !!streetNumber) && (!!zip || !!city)) {
            result += ', ';
        }

        result += HelperService.hasValue(zip) ? `${zip} ` : '';
        result += HelperService.getValue(city, '');

        result = result.trim(); // if no city

        return result;
    }

    /**
     * entfernt aus einem string alle XML Tags
     *
     * @see https://stackoverflow.com/questions/822452/strip-html-from-text-javascript?page=1&tab=votes#tab-top
     * @param {string} html zu prüfender string
     * @returns {string} string ohne tags
     */
    public static removeHtml(html: string): string {
        return html.replace(/<[^>]*>+/gm, '');
    }

    /**
     * Entfernt Datei Endung
     *
     * @param {string} filename Dateiname
     * @returns {string} Dateiname ohne endung
     */
    public static trimExtension(filename: string): string {
        return filename.includes('.') ? filename.substr(0, filename.lastIndexOf('.')) : filename;
    }

    /**
     * Gibt einen Wert oder undefiend zurück
     *
     * @param {any} value zu prüfender wert
     * @returns {any} der Wert oder undefiend
     */
    public static getValueOrUndefiend<T extends number | string | boolean | Date>(value?: T | null): T | undefined {
        return (value !== null) ? value : undefined;
    }

    /**
     * Gibt einen Wert oder null zurück
     *
     * @param {any} value zu prüfender wert
     * @returns {any} der Wert oder null
     */
    public static getValueOrNull<T extends number | string | boolean | Date>(value?: T | null): T | null {
        return (value !== undefined) ? value : null;
    }

    /**
     * Prüft ob der übergebene Wert gesetzt ist
     *
     * @param {any} value zu prüfender Wert
     * @returns {boolean} ist gesetzt
     */
    public static hasValue<T>(value: T | undefined | null): value is T {
        return value !== null && value !== undefined;
    }

    /**
     * Prüft ob mindestens ein Wert gesetzt ist
     *
     * @param {any} values zu prüfende Werte
     * @returns {boolean} wurden gesetzt
     */
    public static hasAtLeastOneValue<T>(values: Array<T | undefined | null>): values is Array<T> {
        return values.some(value => this.hasValue(value));
    }

    /**
     * gibt einen wert zurück wenn dieser gesetzt ist, sonst den default Wert
     *
     * @param {any} value zu prüfender wert
     * @param {any} defaultValue alternativer wert
     * @returns {any} wert oder alternativer wert
     */
    public static getValue<T extends number | string | boolean | Date>(value: T | null | undefined, defaultValue: T): T {
        return HelperService.hasValue(value) ? (value as T) : defaultValue;
    }

    /**
     * Prüft ob ein string nicht gesetzt oder leer ist
     *
     * @param  {string} value zu prüfender string
     * @returns {boolean} ist nicht gesetzt oder leer
     */
    public static isNullOrEmpty(value?: string | null): boolean {
        return !HelperService.hasValue(value) || value.length === 0;
    }

    /**
     * Prüft ob ein string nicht gesetzt oder nur aus whitespaces besteht
     *
     * @param  {string} value zu prüfender string
     * @returns {boolean} ist nicht gesetzt oder nur whitespaces
     */
    public static isNullOrWhitespaces(value?: string | null): boolean {
        return !HelperService.hasValue(value) || value.trim().length === 0;
    }

    /**
     * Prüft ob übergebenes Objekt leer ist
     *
     * @param {Record<string, any>} obj zu prüfendes Objekt
     * @returns {boolean} ist objekt leer
     */
    public static isEmptyObject(obj: Record<string, unknown>): boolean {
        return Object.entries(obj).length === 0;
    }

    /**
     * Prüft ob Zahl gesetzt und größer als 0 ist
     *
     * @param {number} value zu prüfende Zahl
     * @returns {boolean} ist gesetzt und größer 0
     */
    public static isGreaterThenZero(value?: number | null): boolean {
        return HelperService.hasValue(value) && (value as number) > 0;
    }

    /**
     * Gibt den ersten Fehler aus einem ValidationErrors Objekt zurück
     *
     * @param {ValidationErrors} errors das zu prüfenden Objekt
     * @returns {string} erster Fehler
     */
    public static getFirstValidationError(errors: ValidationErrors): string {
        return Object.keys(errors)[0];
    }

    /**
     * Ermittelt den Namen eines FormControls
     *
     * @param {FormControl} control zu prüfendes Control
     * @returns {string} name des controls
     */
    public static getControlName(control: FormControl): string | undefined {
        return (!!control && !!control.parent) ? Object.keys(control.parent.controls)
            .find(name => (control.parent?.controls as { [key: string]: AbstractControl })[name] === control) : undefined;
    }

    /**
     * Prüft ob der mimetype ein valides Bildformat ist
     *
     * @param {string} mimetype zu prüfender MimeType
     * @returns {boolean} ist valides Bild
     */
    public static isValidImage(mimetype: string): boolean {
        return GlobalSettings.validImageFormates.includes(mimetype);
    }

    /**
     * Prüft ob der mimetype ein PDF ist
     *
     * @param {string} mimetype zu prüfender MimeType
     * @returns {boolean} ist PDF
     */
    public static isPDF(mimetype: string): boolean {
        return mimetype === GlobalSettings.MIME_PDF;
    }
    /**
     * Macht einen Deep Clone von einem Objekt oder Array
     *
     * @param {any} value zu clonendes Objekt
     * @returns {any} geklontes objekt
     */
    public static clone<T>(value: T | Record<string, unknown> | ReadonlyArray<T> | T[]): T | T[] | Date {
        if (Array.isArray(value)) {
            if (value.length === 0) {
                return [];
            }
            else if (typeof value[0] === 'object') { // Array of objects
                return (value as readonly T[]).map(x => HelperService.clone<T>(x) as T);
            }
            else { // Array of primitives
                return Array.from(value);
            }
        }
        else if (HelperService.hasValue(value) && typeof value === 'object') { // object

            if (value instanceof Date) {
                return new Date(value.getTime());
            }
            else {
                const cp: Record<string, unknown> = {};

                for (const k in (value as Record<string, unknown>)) {
                    if (value !== null && k in value) {
                        cp[k] = HelperService.clone((value as Record<string, unknown>)[k]);
                    }
                }

                return cp as T;
            }
        }
        else { // primitive
            return value as T | T[];
        }
    }


    /**
     * Prüft ob zwei Objekte die selben Properties und Werte hat
     *
     * @param {any} x erstes Objekt
     * @param {any} y zweites Objekt
     * @param {boolean} ignoreNull null und undefined werden als gleich gewertet
     * @returns {boolean} haben selbe Properties und Werte
     */
    public static compareObjects<T extends Record<string, unknown>>(x: T, y: T, ignoreNull = false): boolean {
        if (Object.keys(x).length !== Object.keys(y).length) {
            return false;
        }

        for (const prop in x) {
            if (
                !(prop in x) || !(prop in y) ||
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (ignoreNull ? HelperService.getValueOrNull(x[prop] as any) !== HelperService.getValueOrNull(y[prop] as any) : x[prop] !== y[prop])) {
                return false;
            }
        }

        return true;
    }

    /**
     * Prüft ob zwei Arrays die selben Werte hat
     *
     * @param {any} x erstes Array
     * @param {any} y zweites Array
     * @returns {boolean} haben selbe Werte
     */
    public static compareArray(x: unknown[], y: unknown[]): boolean {
        if (x.length !== y.length) {
            return false;
        }

        for (let i = 0; i < x.length; i++) {
            if (x[i] !== y[i]) {
                return false;
            }
        }

        return true;
    }

    /**
     * gibt die Elemente des Arrays als unique zurück
     * 
     * @param {any[]} array zu durchsuchendes Array
     * @returns {any[]} array mit unique Entitäten
     * @see https://stackoverflow.com/a/34199330
     */
    public static distinct<T>(array: T[]): T[] {
        return array.filter((value, index, self) => self.indexOf(value) === index)
    }

    /**
     * gibt eine definierte Property des Arrays als unique zurück
     * 
     * @param {any[]} array zu durchsuchendes Array
     * @param {any} key key der Property
     * @returns {any[]} array mit unique Entitäten
     */
    public static distinctSubValue<T extends object, R>(array: T[], key: keyof T): R[] {

        const preArray = array.map(it => it[key]) as R[];
        return preArray.filter((value, index, self) => self.indexOf(value) === index)
    }

    /**
     * convertiert einen YesNo Wert zu einem boolean
     *
     * @param  {YesNo} value zu convertierender Wert
     * @returns {boolean} convertierter Wert
     */
    public static yesNoToBoolean(value?: YesNo | null): boolean | undefined | null {
        return value === YesNo.Yes ? true : value === YesNo.No ? false : value;
    }

    /**
     * convertiert einen boolean Wert zu ein YesNo
     *
     * @param  {YesNo} value zu convertierender Wert
     * @returns {boolean} convertierter Wert
     */
    public static booleanToYesNo(value?: boolean | null): YesNo | undefined | null {
        return value === true ? YesNo.Yes : value === false ? YesNo.No : value;
    }

    /**
     * prüft ob bei einem flagEnum ein Wert gesetzt ist
     *
     * @param {any} flag zu prüfender Wert
     * @param {any} value aktueller Enum Wert
     * @returns {boolean} Wert ist gesetzt
     */
    public static hasFlag<T>(flag: T, value?: T): boolean {
        if (value === undefined || value === null) {
            return false;
        }
        else {
            return ((value as unknown as number) & (flag as unknown as number)) !== 0;
        }
    }

    /**
     * setzt einen Wert in ein Flag enum
     *
     * @param {any} flag aktuelles Flag enum
     * @param {any} value zu setzender Wert
     * @returns {any} neues Flag enum
     */
    public static setFlag<T>(flag: T, value?: T): T {
        if (value === undefined || value === null) {
            return flag;
        }
        else {
            return (((value as unknown as number) | (flag as unknown as number))) as unknown as T;
        }
    }

    /**
     * entfernt einen Wert in einem Flag enum
     *
     * @param {any} flag aktuelles Flag enum
     * @param {any} value zu entfernender Wert
     * @returns {any} neues Flag enum
     */
    public static unsetFlag<T>(flag: T, value?: T): T {
        if (value === undefined || value === null) {
            return 0 as unknown as T;
        }
        else {
            return (((flag as unknown as number) & ~(value as unknown as number))) as unknown as T;
        }
    }

    /**
     * setzt oder entfernt einen Wert in ein Flag enum
     *
     * @param {any} flag aktuelles Flag enum
     * @param {any} value zu setzender Wert
     * @returns {any} neues Flag enum
     */
    public static toggleFlag<T>(flag: T, value?: T): T {
        if (value === undefined || value === null) {
            return flag;
        }
        else {
            return (((value as unknown as number) ^ (flag as unknown as number))) as unknown as T;
        }
    }

    /**
     * convertiert ein Flag enum in ein Array aus Enum Werten
     *
     * @param {any} flag aktuelles Flag enum
     * @param {any} enumType Typ des Enums
     * @returns {any} Enum Array
     */
    public static convertFlagToArray<T>(flag: T, enumType: unknown): T[] {
        if (flag !== undefined && flag !== null) {
            const enumArray = HelperService.getEnumArray((enumType as Record<string, string | number>), true) as unknown as T[];
            return enumArray.filter(it => HelperService.hasFlag(flag, it)) as T[];
        }
        return [];
    }

    /**
     * convertiert ein Enum Array in ein Flag enum
     *
     * @param {any} array Array aus Enum Werten
     * @returns {any} Flag Enum
     */
    public static convertArrayToFlag<T>(array?: T[] | null): T | undefined | null {
        if (Array.isArray(array) && array.length > 0) {
            return array.reduce((pv: T, cv: T) => {
                if (pv === undefined) {
                    return cv;
                }
                else {
                    return ((pv as unknown as number) | (cv as unknown as number)) as unknown as T;
                }
            });
        }

        return !!array ? null : array;
    }

    /**
     * Gibt ein Enum als Array zurück
     *
     * @param {any} enumObject der Enum Typ
     * @param {boolean} returnNumbers sollen die Werte als Zahlen anstelle von Strings zurückgegeben werden (default: true)
     * @returns {string[] | number[]} array aus Werten
     */
    public static getEnumArray(enumObject: Record<string, string | number>, returnNumbers = true): string[] | number[] {
        return returnNumbers ?
            Object.keys(enumObject).reduce<number[]>((acc, curr) => (typeof enumObject[curr] === 'number' ? [...acc, (enumObject[curr] as number)] : acc), []) :
            Object.keys(enumObject).reduce<string[]>((acc, curr) => (typeof enumObject[curr] === 'string' ? [...acc, (enumObject[curr] as string)] : acc), []);
    }

    /**
     * Wandelt ein Enum in ein ListTuple Array mit übersetzung um
     * 
     * @param {any} enumObject der Enum Typ
     * @param {string} enumName der Enum Name
     * @param {EnumTranslationService} enumTranslate EnumTranslationService
     * @returns {IListTuple<string>[]} ListTuple Array mit übersetzten Labels
     */
    public static enumToListTuple<T extends number | string>(enumObject: Record<string, string | number>, enumName: string, enumTranslate: EnumTranslationService): IListTuple<T>[] {
        const asArray = <T[]>HelperService.getEnumArray(enumObject);

        return asArray.map<IListTuple<T>>(t => ({
            value: t,
            label: enumTranslate.instant({ type: enumName, value: t }) as string,
        }));
    }

    /**
     * Entfernt Enumwerte aus einem Enum
     *
     * @param {any} enumObject original Enum
     * @param {number[]} toRemove zu entfernende Werte
     * @returns {any} verkleinertes Enum
     */
    public static removeValuesFromEnum<T>(enumObject: T, toRemove: number[]): T {

        if (toRemove.length === 0) {
            return enumObject;
        }

        const asRecord = { ...enumObject } as unknown as Record<string, number | string>

        for (const key of Object.keys(asRecord)) {
            const value = asRecord[key];
            if (typeof value === 'number' && toRemove.includes(value)) {
                delete asRecord[asRecord[key]];
                delete asRecord[key];
            }
        }

        return asRecord as unknown as T;
    }

    /**
     * Prüft ob der Wert gesetzt ist und parst ihn ggf. in in int
     *
     * @param  {string | number} value zu prüfender Wert
     * @returns {number} Wert als Int
     */
    public static stringToInt(value: string | number): number {
        return HelperService.hasValue(value) && (typeof value === 'string') ?
            parseInt(value, 10) : value as number;
    }

    /**
     * parset einen string in ein boolean
     *
     * @param  {string | boolean} value zu prüfender Wert
     * @returns {boolean} Wert als Boolean
     */
    public static parseStringToBoolean(value: string | boolean): boolean | undefined {
        if (typeof value === 'boolean') { return value; }
        if (typeof value === 'string' && value === 'true') { return true; }
        if (typeof value === 'string' && value === 'false') { return false; }

        return undefined;
    }

    /**
     * Rückgabe einer gültigen HTML ID, wenn Key nicht gesetzt ist, wird eine GUID verwendet
     *
     * @param { string } key Key
     * @returns { string } id als HTML Id
     */
    public static toHTMLid(key?: string): string {
        return `a${!!key ? key : uuid()}`;
    }

    /**
     * ist ein beliebiges Feld in Haftung nicht null oder undefiniert
     *
     * @param {ILiabilityModel} liability Haftung
     * @returns {boolean} Haftung
     */
    public static isLiabilityDirty(liability: ILiabilityModel): boolean {
        return (
            HelperService.hasValue(liability.ibanCreditor) ||
            HelperService.hasValue(liability.initialAmount) ||
            HelperService.hasValue(liability.currentAmount) ||
            HelperService.hasValue(liability.loanPeriodInMonths) ||
            HelperService.hasValue(liability.started) ||
            HelperService.hasValue(liability.monthlyRate) ||
            !!liability.covered ||
            !!liability.securedByLandRegister ||
            HelperService.hasValue(liability.securedRealEstateId) ||
            !!liability.isCorporateCredit
        );
    }


    /**
     * is any field in newLiability not null or undefined
     *
     * @param {INewLiabilityModel} newLiability zu prüfendes Objekt
     * @returns {boolean} Wert als Boolean
     */
    public static isNewLiabilityDirty(newLiability: INewLiabilityModel): boolean {
        return (
            HelperService.hasValue(newLiability.creditorName) ||
            HelperService.hasValue(newLiability.amount) ||
            HelperService.hasValue(newLiability.loanPeriodInMonths) ||
            HelperService.hasValue(newLiability.starts) ||
            HelperService.hasValue(newLiability.monthlyRate) ||
            HelperService.hasValue(newLiability.securedRealEstateId) ||
            !!newLiability.securedByLandRegister
        );
    }

    /**
     * is any field in IRealEstateModel Trustee Address not null or undefined
     *
     * @param {IRealEstateModel} realEstate zu prüfendes Objekt
     * @returns {boolean} Wert als Boolean
     */
    public static isTrusteeAddressDirty(realEstate: IRealEstateModel): boolean {
        return (
            HelperService.hasValue(realEstate.trusteeStreet) ||
            HelperService.hasValue(realEstate.trusteeStreetNumber) ||
            HelperService.hasValue(realEstate.trusteeZip) ||
            HelperService.hasValue(realEstate.trusteeCity)
        );
    }

    /**
     * is any field in IRealEstateModel Trustee not null or undefined
     *
     * @param {IRealEstateModel} realEstate zu prüfendes Objekt
     * @returns {boolean} Wert als Boolean
     */
    public static isTrusteeDirty(realEstate: IRealEstateModel): boolean {
        return (
            HelperService.isTrusteeAddressDirty(realEstate) ||
            HelperService.hasValue(realEstate.trusteeName) ||
            HelperService.hasValue(realEstate.trusteePhoneNumber) ||
            HelperService.hasValue(realEstate.trusteeFaxNumber)
        )
    }


    /**
     * zum Anfang der Seite scrollen
     *
     * @param {ElementRef} elementRef Element
     */
    public static scrollToStart(elementRef: ElementRef<HTMLElement>): void {

        setTimeout(() => {
            const element = elementRef.nativeElement.parentElement;
            if (!!element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
            }
        }, 100)
    }

    /**
     * zum HTML Element scrollen
     *
     * @param {string} controlName feld Name
     * @param {ElementRef} elementRef Element
     */
    public static scrollToElement(controlName: string, elementRef: ElementRef<HTMLElement>): void {

        if (!!controlName) {
            setTimeout(() => {
                const element = elementRef.nativeElement.querySelector(`mat-form-field[name=${controlName}]`);
                if (!!element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
                }
            }, 100)
        }
    }

    /**
     * Formatiert die Messages in HTML Listenelemente
     * 
     * @param {Record<string, string[]>} messages zu formatierende Messages
     * @returns {string} formatierte HTML Listenelemente
     */
    public static formatPasscodeMessages(messages: Record<string, string[]>): string {
        let message = '';

        for (const key in messages) {
            if (!!messages[key]) {
                for (const msg of messages[key]) {
                    const formattedMsg = HelperService.toHTMLBreakes(msg);
                    message += `<li>${formattedMsg}</li>`;
                }
            }
        }

        return message;
    }

        /**
         * erstellt das Namens Label einer Liegenschaft
         *
         * @param {IRealEstateModel} realEstate Liegenschaft
         * @param {string} defaultRealEstateLabel Label wenn kein Name vorhanden ist
         * @returns {string} Namens Label
         */
        public static calcRealEstateLabel(realEstate: IRealEstateModel, defaultRealEstateLabel: string): string {
            if (!!realEstate.name) {
                return `${`${defaultRealEstateLabel} (${realEstate.name})`}`;
            }
            else {
                return `${defaultRealEstateLabel}`;
            }
        }
}
