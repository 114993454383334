import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ITimeLineItem } from '@ucba/sdk';

/**
 * Komponente welche einen Zweitstrahl der Status anzeigt
 */
@Component({
    selector: 'cxad-status-timeline',
    templateUrl: './status-timeline.component.html',
    styleUrls: ['./status-timeline.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class StatusTimelineComponent implements OnInit {
    @Input()
    public items?: ITimeLineItem[];

    public activeElement = 0;

    /**
     * Angular Hook
     */
    public ngOnInit() {
        this.activeElement = this.items?.findIndex(e => e.itemStatus === 'edit' || e.itemStatus === 'error') ?? 0;
    }
}
