import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { EnumTranslationService } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { FileController } from '@ucba/sdk/controller';
import { SystemType } from '@ucba/sdk/enums';
import { IMessageModel } from '@ucba/sdk/models';
import { DataService, FileHelper, HelperService } from '@ucba/sdk/services';
import { CustomerState, FinancingMapState, UserState } from '@ucba/sdk/statemanagement/states';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';

/**
 * Komponente, welche die Nachrichten anzeigen und versenden
 *
 */
@Component({
    selector: 'cxad-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('scrollContrainer') private scrollContrainer: ElementRef | undefined;
    public chatForm: FormGroup | undefined;
    public userId: string | undefined;
    public systemType = SystemType;
    public chatMessagesGroup$: Observable<Partial<IMessageModel>[]> | undefined;

    public isSendLoading = false;

    public isDownloadBtnActive = true;
    public isDownloadLoading = false;

    private receiver: null | undefined | SystemType;
    private receiverGroupChat = HelperService.convertArrayToFlag([SystemType.Bank, SystemType.Finadvisory]);

    /** wird verwendet um das Formular zur Eingabe der Nachricht ein-/auszublenden*/
    public isChatOnlyReadable: boolean | undefined;

    public isFinancingMapOpen: boolean | undefined;

    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();

    /**
     *Standard Konstuktor
     *
     * @param {Store} store Store injector
     * @param {DataService} dataService DataService injector
     * @param {FileController} fileController FileController injector
     * @param {TranslateService} translate TranslateService injector
     * @param {EnumTranslationService} enumTranslate EnumTranslationService injector
     * @param {NotificationService} notificationService NotificationService injector
     */
    public constructor(
        private store: Store,
        private dataService: DataService,
        private fileController: FileController,
        private translate: TranslateService,
        private enumTranslate: EnumTranslationService,
        private notificationService: NotificationService,
    ) { }

    /**
     * Angular Hook um Werte zu initialisieren
     */
    public ngOnInit(): void {
        this.chatForm = new FormGroup({ txArea: new FormControl() });
        this.userId = this.store.selectSnapshot(UserState.current)?.id;
        this.chatMessagesGroup$ = this.dataService.messageObservable$.pipe(
            filter(messages => Array.isArray(messages)),
            map(messages => messages as IMessageModel[]),
            map(messages => messages
                .filter(msg =>
                    HelperService.hasFlag(msg.to, SystemType.Cxadvisory) ||
                    HelperService.hasFlag(msg.from, SystemType.Cxadvisory))
                .map<IMessageModel>(msg => ({
                    ...msg,
                    text: HelperService.toHTMLBreakes(msg.text),
                    displayName: !HelperService.isNullOrWhitespaces(msg.displayName) ?
                        `${msg.displayName}, ${this.enumTranslate.instant({ type: 'SystemType', value: msg.from })}` :
                        this.enumTranslate.instant({ type: 'SystemType', value: msg.from }) as string,
                }))),
            tap(async messages => {
                if (messages.length > 0) {
                    // filtert die Nachrichten, welche Cxadvisory noch nicht gelesen hat und welche nicht von Cxadvisory kommen
                    const messageIds = messages
                        .filter(msg => !HelperService.hasFlag(msg.readFrom, SystemType.Cxadvisory) &&
                            !HelperService.hasFlag(msg.from, SystemType.Cxadvisory))
                        .map(msg => msg.id);

                    if (messageIds.length > 0) {
                        await this.dataService.setReaded(messageIds).catch(e => { throw e; });
                    }
                }
            }),
            tap(() => {
                this.scrollContainerWithTimeout();
            }),
        );

        this.store.select(FinancingMapState.current)
            .pipe(takeUntil(this.viewLeft$))
            .subscribe(financing => {
                this.isChatOnlyReadable = !!financing && FinancingMapState.staticIsChatForFinancingMapOnlyReadable(false, financing.isAutoExit === null ? false : financing.isAutoExit, financing.status);
                this.isFinancingMapOpen = !!financing && FinancingMapState.staticIsFinancingMapEditable(financing.status);
            });
    }

    /**
     * A lifecycle hook that is called after Angular has fully initialized a component's view.
     */
    public ngAfterViewInit(): void {
        if (!!this.scrollContrainer) {
            this.scrollContrainer.nativeElement.scrollTop = 99999;
        }
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
    }

    /**
     * um die letzte Nachricht zu zeigen, wir müssen warten bis container gerendert ist
     */
    private scrollContainerWithTimeout(): void {
        setTimeout(() => {
            if (!!this.scrollContrainer) {
                this.scrollContrainer.nativeElement.scrollTop = 99999;
            }
        }, 200)
    }

    /**
     * Nachricht speichern
     */
    public async onSend(): Promise<void> {
        this.isSendLoading = true;

        if (!!this.chatForm) {

            const text = this.chatForm.get('txArea')?.value

            this.receiver = this.receiverGroupChat;

            const msg: Partial<IMessageModel> = {
                financingMapId: this.store.selectSnapshot(FinancingMapState.currentId),
                text,
                to: HelperService.hasValue(this.receiver) ? this.receiver : undefined,
            };

            await this.dataService.createMessage(msg)
                .then(() => {
                    this.chatForm?.reset();
                    this.isSendLoading = false;

                })
                .catch(e => {
                    this.isSendLoading = false;

                    throw e
                })
        }
    }

    /**
     * Nachricht als gelöscht markieren
     *
     * @param {string} id id der Nachricht
     */
    public markDeleted(id: string): void {
        this.notificationService.confirmYesNo(
            this.translate.instant('components.chat.deleteMsg'),
            this.translate.instant('components.chat.deleteMsgConfirm'),
        )
            .pipe(take(1))
            .subscribe(role => {
                if (role === 'submit') {
                    this.dataService.markMessageDeleted(id).catch(e => { throw e; });
                }
            });
    }

    /**
     * download Chat
     */
    public downloadChatHistory(): void {
        this.isDownloadBtnActive = false;
        this.isDownloadLoading = true;

        const id = this.store.selectSnapshot(FinancingMapState.currentId);
        if (id) {
            this.fileController.createCommunicationExport(id, true)
                .pipe(take(1))
                .subscribe(blob => {
                    if (!!blob) {
                        const customer = this.store.selectSnapshot(CustomerState.current);
                        const dateTimeString = HelperService.formatDate('YYYY-MM-DD_HH_mm');
                        const name = `${this.translate.instant('components.chat.filename')}_${customer?.firstName}_${customer?.lastName}_${dateTimeString}`;
                        FileHelper.downloadFileFromBlob(blob, name)
                            .then(() => {
                                this.isDownloadBtnActive = true;
                                this.isDownloadLoading = false;
                            })
                            .catch(e => {
                                this.isDownloadBtnActive = true;
                                this.isDownloadLoading = false;
                                throw e;
                            });
                    }
                    else {
                        throw new Error(this.translate.instant('components.chat.downloadError'));
                    }
                });
        }
    }
}
