import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IBackendBaseModel } from '../../models/backend-base.model';
import { BaseController } from '../base/base.controller';

/**
 * Controller welche die einfachen GET, POST, PUT, DELETE Funkionalitäten bereithält
 */
@Injectable()
export class BaseApiController<T extends IBackendBaseModel> extends BaseController {

    protected baseUrl = '';

    /**
     * Gibt die Entität mit übergebener Id zurück
     * 
     * @param {string} id id der Entität
     * @returns {any} angeforderte Entität
     */
    public getEntity(id: string): Observable<T> {
        return this.get(`${this.baseUrl}/${id}`);
    }

    /**
     * erstellt eine Entität im Backend
     * 
     * @param {any} model zu speichernde Entität
     * @returns {any} im backend gespeicherte Entität
     */
    public createEntity(model: Partial<T>): Observable<T> {
        return this.post(this.baseUrl, model);
    }

    /**
     * updated eine Entität im Backend
     * 
     * @param {any} model zu speichernde Entität
     * @returns {any} im backend gespeicherte Entität
     */
    public updateEntity(model: Partial<T>): Observable<T> {
        return this.put(`${this.baseUrl}/${model.id}`, model)
    }

    /**
     * Löscht die Entität mit übergebener Id
     * 
     * @param {string} id id der Entität
     * @returns {any} angeforderte Entität
     */
    public deleteEntity(id: string): Observable<void> {
        return this.delete(`${this.baseUrl}/${id}`);
    }
}
