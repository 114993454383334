import { IDebtorModel } from '../../models';

/**
 * Action welche alle Debtor updated
 */
export class SdkPatchDebtor {
    public static readonly type = '[SDK Debtor] PATCH';

    /**
     * Standard Konstruktor
     * 
     * @param {IDebtorModel} payload zu patchende Debtor
     */
    public constructor(public payload: IDebtorModel[]) { }
}

/**
 * Action welche ein neues Debtor hinzufügt, sollte es bereits existieren wird add ignoriert
 */
 export class SdkAddDebtor {
    public static readonly type = '[SDK Debtor] ADD';

    /**
     * Standard Konstruktor
     * 
     * @param {IDebtorModel} payload neues Debtor
     */
    public constructor(public payload: IDebtorModel) { }
}

/**
 * Action welche ein spezifisches Debtor updated
 */
export class SdkUpdateDebtor {
    public static readonly type = '[SDK Debtor] UPDATE';

    /**
     * Standard Konstruktor
     * 
     * @param {IDebtorModel} payload zu patchendes Debtor
     */
    public constructor(public payload: IDebtorModel) { }
}

/**
 * Action welche ein Debtor entfernt, sollte es nicht existieren wird delete ignoriert
 */
export class SdkDeleteDebtor {
    public static readonly type = '[SDK Debtor] DELETE';

    /**
     * Standard Konstruktor
     * 
     * @param {string} id id des zu löschenden Debtors
     */
    public constructor(public id: string) { }
}
