import { ActionType, SystemType } from '../enums';

import { IBackendBaseModel } from './backend-base.model';

/**
 * Historieneintrag model
 */
export interface IHistoryModel extends IBackendBaseModel {

    /** History action type */
    action: ActionType,

    /**ConsultantId */
    consultantId: string,

    /** Customer Id  */
    customerId: string,

    /** System type  */
    executingSystem: SystemType,

    /** Financing map id  */
    financingMapId: string,

    /** Visibility  */
    isVisibleIn: SystemType,

    /** New Value  */
    newEntity?: string,

    /** Old Value  */
    oldEntity?: string,

    /** Parameter  */
    parameter?: string,

    /** Consultan name  */
    consultantFirstname: string,

    /** Consultan last name  */
    consultantLastname: string,

    /** Debtor last name  */
    customerFirstname: string,

    /** Debtor last name  */
    customerLastname: string
}
