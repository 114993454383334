import { Validators } from '@angular/forms';

import { IValidationMap } from '../interfaces';

import { NumberValidation } from './number.validation';

export const CALCULATOR_PLAN_FORM_VALIDATION_MAP: IValidationMap = {
    financingMapModel: {
        id: {
            required: () => true,
            baseValidator: () => [],
        },
        customerId: {
            required: () => true,
            baseValidator: () => [],
        },
        assumedDuration: {
            required: () => true,
            baseValidator: () => [Validators.min(120), Validators.max(420), NumberValidation.isDivisibleByTwelve],
        },
        gracePeriod: {
            required: () => false,
            baseValidator: () => [Validators.min(0), Validators.max(36)],
        },
        interestMethod: {
            required: () => true,
            baseValidator: () => [],
        },
        requestedDebitRate: {
            required: () => false,
            baseValidator: () => [NumberValidation.greaterThan(0), Validators.max(9.999)],
        },
        requestedDebitRateNotes: {
            required: () => false,
            baseValidator: () => [Validators.maxLength(2048)],
        },
        // plannedFinancingStart: {
        //     required: () => false,
        //     baseValidator: (financing: FinancingMapModel) => [
        //         DateValidation.format,
        //         DateValidation.minDate(!!financing.plannedFinancingStart ?
        //             HelperService.dateCompareMin(new Date(financing.plannedFinancingStart), HelperService.today()) :
        //             HelperService.today()),
        //         DateValidation.maxDate(HelperService.toUTCDate(2099, 12, 31)),
        //     ],
        // },
    },
}
