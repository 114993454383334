import { DocumentType } from '@ntag-ef/finprocess-enums/finadvisory';

import { IBackendBaseModel } from './backend-base.model';

/**
 * Unterschirften Model
 */
export interface ISignatureModel extends IBackendBaseModel {

    /** Der Finanzierungsmappen Identifikator. */
    financingMapId: string;

    /** Der User Identifikator. */
    userId?: string;

    /** Der Debtor Identifikator. */
    debtorId?: string;

    /** Der Typ des MIME. */
    mimeType?: string;

    /** Die Länge. */
    length?: number;

    /** Der Type. */
    type?: DocumentType;

    /** die Unterschrift als base64, muss explizit mit api/Signature/Download/{id} geladen werden */
    content?: Blob | string | null;
}
