import { FormGroup } from '@angular/forms';
import { MonoTypeOperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

export const clearInvalidFormValues = <T extends Record<string, unknown>>(group: FormGroup): MonoTypeOperatorFunction<T> => input => input.pipe(map(data => {
    for (const key of Object.keys(data)) {
        const control = group.get(key);
        if (!control || control.invalid || (typeof control.value === 'string' && control.value.trim() === '')) {
            (data as unknown as Record<string, unknown>)[key] = null;
        }
    }
    return data;
}));
