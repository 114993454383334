import { AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 * Custom ErrorStateMatcher um Fehler der äußeren AbstractControl innerhalb der Phone Komponente anzuzeigen
 */
export class PhoneErrorStateMatcher extends ErrorStateMatcher {

    /**
     * Standard Konstruktor
     *
     * @param {AbstractControl} control AbstractControl
     */
    public constructor(private control: AbstractControl) {
        super();
    }

    /**
     * isErrorState entscheidet wann mat-error angezeigt werden soll
     *
     * @returns {boolean} Ob mat-error angezeigt werden soll
     */
    public override isErrorState(): boolean {
        return this.control.dirty && this.control.invalid;
    }
}
