/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable class-methods-use-this */

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ITaskModel } from '../../models';
import { Dispose } from '../actions';

import { SdkAddTask, SdkDeleteTask, SdkPatchTask, SdkUpdateTask } from './task.actions';

export interface ITaskStateModel {
    data: ITaskModel[];
}

/**
 * Klasse des Task States
 */
@State<ITaskStateModel>({
    name: TaskState.stateName,
    defaults: TaskState.defaultData,
})

@Injectable()
export class TaskState {
    public static readonly stateName = 'taskState';
    private static readonly defaultData: ITaskStateModel = {
        data: [],
    };

    /**
     * Aktualisiert die aktuelle Task
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchTask} action SdkPatchTask Action
     */
    @Action(SdkPatchTask)
    public patchTask(
        { patchState }: StateContext<ITaskStateModel>,
        { payload }: SdkPatchTask,
    ): void {
        patchState({
            data: payload,
        });
    }

    /**
     * Aktualisiert eine spezifische Task
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkUpdateTask} action sdk Action
     */
    @Action(SdkUpdateTask)
    public updateTask(
        { patchState, getState }: StateContext<ITaskStateModel>,
        { payload }: SdkUpdateTask,
    ): void {
        const current = getState().data;

        if (current.some(({ id }) => id === payload.id)) {
            patchState({
                data: current.filter(({ id }) => id !== payload.id).concat(payload),
            });
        }
    }

    /**
     * fügt eine neue Task hinzu
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkAddTask} action SdkAddTask Action
     */
    @Action(SdkAddTask)
    public addTask(
        { patchState, getState }: StateContext<ITaskStateModel>,
        { payload }: SdkAddTask,
    ): void {
        const current = getState().data;

        if (!current.some(({ id }) => id === payload.id)) {
            patchState({
                data: current.concat(payload),
            });
        }
    }

    /**
     * entfernt eine spezifische Task
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkDeleteTask} action SdkDeleteTask Action
     */
    @Action(SdkDeleteTask)
    public deleteTask(
        { patchState, getState }: StateContext<ITaskStateModel>,
        { id: taskId }: SdkDeleteTask,
    ): void {
        const current = getState().data;

        if (current.some(({ id }) => id === taskId)) {
            patchState({
                data: current.filter(({ id }) => id !== taskId),
            });
        }
    }

    /**
     * zurücksetzen des States
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(Dispose)
    public dispose({ setState }: StateContext<ITaskStateModel>): void {
        setState({ ...TaskState.defaultData });
    }

    /**
     * gibt alle Tasks zurück, welche einem Kunden zugeordnet sind
     *
     * @param {ITaskStateModel} state aktueller State
     * @returns {ITaskModel} der aktuell selektierte RealEstate
     */
    @Selector()
    public static current(state: ITaskStateModel): ITaskModel[] {
        return state?.data ?? [];
    }


    /**
     * gibt einen spezifischen Task zurück
     *
     * @param {ITaskStateModel} state aktueller State
     * @returns {(taskId: string) => ITaskModel} der spezifischer Task
     */
    @Selector()
    public static currentById(state: ITaskStateModel): (taskId: string) => ITaskModel | undefined {
        /**
         * callback funktion zum filtern
         *
         * @param {string} taskId Id des Task
         * @returns {ITaskModel} gefilterter Task
         */
        return (taskId: string): ITaskModel | undefined => TaskState.current(state).find(({ id }) => id === taskId);
    }

}
