/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable class-methods-use-this */

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { INewLiabilityInput } from '@ntag-ef/finprocess-validation';

import { INewLiabilityModel } from '../../models';
import { CalculationFactoryService, HelperService } from '../../services';
import { Dispose, UnLoadCustomer, UnLoadFinancing } from '../actions';

import { SdkAddNewLiability, SdkDeleteNewLiability, SdkDeleteNewLiabilityByHousehold, SdkPatchNewLiability, SdkUpdateNewLiability } from './new-liability.actions';

export interface INewLiabilityStateModel {
    data: INewLiabilityModel[];
}

/**
 * Klasse des NewLiability States
 */
@State<INewLiabilityStateModel>({
    name: NewLiabilityState.stateName,
    defaults: NewLiabilityState.defaultData,
})
@Injectable()
export class NewLiabilityState {

    public static readonly stateName = 'newLiabilityState';

    private static readonly defaultData: INewLiabilityStateModel = {
        data: [],
    };

    /* #region  Actions */

    /**
     * Aktualisiert die aktuellen Bestandskredite
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchNewLiability} action SdkPatchNewLiability Action
     */
    @Action(SdkPatchNewLiability)
    public patchNewLiability(
        { patchState }: StateContext<INewLiabilityStateModel>,
        { payload }: SdkPatchNewLiability,
    ): void {
        patchState({
            data: payload,
        });
    }

    /**
     * Aktualisiert ein spezifischen Bestandskredit
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkUpdateNewLiability} action SdkUpdateNewLiability Action
     */
    @Action(SdkUpdateNewLiability)
    public updateNewLiability(
        { patchState, getState }: StateContext<INewLiabilityStateModel>,
        { payload }: SdkUpdateNewLiability,
    ): void {
        const current = getState().data;

        if (current.some(({ id }) => id === payload.id)) {
            patchState({
                data: current.filter(({ id }) => id !== payload.id).concat(payload),
            });
        }
    }

    /**
     * fügt ein neuen Bestandskredit hinzu
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkAddNewLiability} action SdkAddNewLiability Action
     */
    @Action(SdkAddNewLiability)
    public addNewLiability(
        { patchState, getState }: StateContext<INewLiabilityStateModel>,
        { payload }: SdkAddNewLiability,
    ): void {
        const current = getState().data;

        if (!current.some(({ id }) => id === payload.id)) {
            patchState({
                data: current.concat(payload),
            });
        }
    }

    /**
     * entfernt ein spezifischen Bestandskredit
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkDeleteNewLiability} action SdkDeleteNewLiability Action
     */
    @Action(SdkDeleteNewLiability)
    public deleteNewLiability(
        { patchState, getState }: StateContext<INewLiabilityStateModel>,
        { id: newLiabilityId }: SdkDeleteNewLiability,
    ): void {
        const current = getState().data;

        if (current.some(({ id }) => id === newLiabilityId)) {
            patchState({
                data: current.filter(({ id }) => id !== newLiabilityId),
            });
        }
    }

    /**
     * entfernt ein alle neuen Verpflichtungen eines Haushaltes
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkDeleteNewLiabilityByHousehold} action SdkDeleteNewLiabilityByHousehold Action
     */
    @Action(SdkDeleteNewLiabilityByHousehold)
    public deleteNewLiabilityByHousehold(
        { patchState, getState }: StateContext<INewLiabilityStateModel>,
        { id }: SdkDeleteNewLiabilityByHousehold,
    ): void {
        const current = getState().data;

        if (current.some(({ householdId }) => householdId === id)) {
            patchState({
                data: current.filter(({ householdId }) => householdId !== id),
            });
        }
    }

    /**
     * zurücksetzen des States
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action([Dispose, UnLoadFinancing, UnLoadCustomer])
    public dispose({ setState }: StateContext<INewLiabilityStateModel>): void {
        setState({ ...NewLiabilityState.defaultData });
    }

    /* #endregion */

    /* #region  Selectors */

    /**
     * gibt alle Bestandskredite zurück, welche der aktuellen Finanzierung zugeordnet sind
     *
     * @param {INewLiabilityStateModel} state aktueller State
     * @returns {INewLiabilityModel} die aktuell selektierten Bestandskredite
     */
    @Selector()
    public static current(state: INewLiabilityStateModel): INewLiabilityModel[] {
        return state?.data ?? [];
    }

    /**
     * gibt den Bestandskredit mit übergebener Id zurück
     *
     * @param {INewLiabilityStateModel} state aktueller State
     * @returns {(newLiabilityId: string) => INewLiabilityStateModel} funktion zum filtern
     */
    @Selector()
    public static currentById(state: INewLiabilityStateModel):
        (newLiabilityId: string) => INewLiabilityModel | undefined {

        /**
         * callback funktion zum herraussuchen eines Bestandskredites
         *
         * @param {string} newLiabilityId id des Bestandskredites
         * @returns {INewLiabilityModel} gesuchter Bestandskredit
         */
        return (newLiabilityId: string) => state.data.find(({ id }) => id === newLiabilityId);
    }

    /**
     * gibt alle aktuellen Bestandskredite eines Haushaltes zurück
     *
     * @param {INewLiabilityStateModel} state aktueller State
     * @returns {(householdIds: string[]) => INewLiabilityModel[]} funktion zum filtern
     */
    @Selector()
    public static currentByHousholdIds(state: INewLiabilityStateModel):
        (householdIds: string[]) => INewLiabilityModel[] {
        /**
         * callback funktion zum filtern
         *
         * @param {string[]} householdIds ids der Haushalte
         * @returns {INewLiabilityModel[]} gefilterte Bestandskredite
         */
        return (householdIds: string[]): INewLiabilityModel[] => state.data.filter(({ householdId }) => householdIds.includes(householdId));
    }

    /**
     * gibt alle neuen Kredite im Format für den Precheck zurück
     *
     * @param {INewLiabilityInput} state aktueller State
     * @returns {INewLiabilityInput[]} Neue Kredite
     */
    @Selector()
    public static asInputModel(state: INewLiabilityStateModel): INewLiabilityInput[] {
        return NewLiabilityState.current(state).filter(nl => HelperService.isNewLiabilityDirty(nl)) as INewLiabilityInput[];
    }

    /* #endregion */

    /* #region calculations */

    /**
     * berechnet monatliche Rate
     *
     * @param {INewLiabilityStateModel} state aktueller State
     * @returns {(householdId: string, calculationVersion?: number) => number} funktion zum berechnen
     */
    @Selector()
    public static newLiabilitiesSecuredSumByHouseHold(state: INewLiabilityStateModel):
        (householdId: string, calculationVersion?: number) => number {

        /**
         * Callback funktion zum berechnen der montl. Rate
         *
         * @param {string} householdId Id des Haushaltes
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {number} monatliche Rate
         */
        return (householdId: string, calculationVersion?: number): number => {
            const newLiabilityCalculationService = CalculationFactoryService.newLiabilityCalculationService(calculationVersion);
            const newLiabilities = NewLiabilityState.currentByHousholdIds(state)([householdId]);

            return newLiabilities.reduce<number>((acc, { securedByLandRegister, monthlyRate }) =>
                acc + newLiabilityCalculationService.monthlyRateSecuredByLandRegister({
                    securedByLandRegister,
                    monthlyRate,
                }), 0);
        };
    }


    /**
     * berechnet Kreditbetrag, wenn grundbücherlich besichert
     *
     * @param {INewLiabilityStateModel} state aktueller State
     * @returns {(calculationVersion?: number) => number} funktion zum berechnen
     */
    @Selector()
    public static newLiabilityAmountOfLandRegister(state: INewLiabilityStateModel):
        (calculationVersion?: number) => number {

        /**
         * Callback funktion zum berechnen des Kreditbetrags
         *
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {number} Kreditbetrag
         */
        return (calculationVersion?: number): number => {
            const newLiabilityCalculationService = CalculationFactoryService.newLiabilityCalculationService(calculationVersion);
            const newLiabilities = NewLiabilityState.current(state);

            return newLiabilities.reduce<number>((acc, { securedByLandRegister, amount }) =>
                acc + newLiabilityCalculationService.amountSecuredByLandRegister({
                    amount,
                    securedByLandRegister,
                }), 0);
        };
    }

    /* #endregion */
}
