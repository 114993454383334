import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Komponete, welche einen Floating-Button mit Icon darstellt, der bei Hover zusätzlich mit Text erweitert wird.
 */
@Component({
    selector: 'ucba-floating-button',
    templateUrl: './floating-button.component.html',
    styleUrls: ['./floating-button.component.scss'],
})
export class FloatingButtonComponent {
    @Output() public clicked: EventEmitter<unknown> = new EventEmitter();
    @Input() public icon!: string;
    @Input() public labelText!: string;
    @Input() public tooltip!: string;
    @Input() public fixed = false;

    public isHovered = false;
    public hoveredStyles: object = {};

    /**
     * passt die Breite des Buttons beim Hover an, basierend auf dem darin enthaltenen Text an 
     * 
     * @param {boolean} isHovered true wenn mouseover
     * @param {string} textLength Anzahl der Buttontextzeichen
     */
    // eslint-disable-next-line class-methods-use-this
    public changeWidth(isHovered: boolean, textLength = 0): void {
        if (!this.fixed) {
            const mappedWidth = (textLength * 6) + 80;
            this.hoveredStyles = {
                'width': isHovered ? `${mappedWidth}px` : '50px',
            };
        }
    }
}
