import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IListTuple } from '@ucba/sdk/interfaces';
import { Subject, combineLatest, takeUntil } from 'rxjs';

import { IDeleteHouseholdData } from './../../interfaces/delete-household-data.interface';

/**
 * Dialog zum generieren eines neuen Kreditnehmers
 */
@Component({
    selector: 'cxad-delete-household',
    templateUrl: './delete-household.component.html',
    styleUrls: ['./delete-household.component.scss'],
})
export class DeleteHouseholdComponent implements OnDestroy, OnInit {

    public form?: FormGroup;
    public selectOptions?: IListTuple<string>[];

    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();

    /**
     * standard Konstruktor
     *
     * @param {MatDialogRef} dialogRef MatDialogRef injector
     * @param {IDeleteHouseholdData} data Observables für Households und Debtors
     * @param {UntypedFormBuilder} fb UntypedFormBuilder injector
     */
    public constructor(
        public dialogRef: MatDialogRef<DeleteHouseholdComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDeleteHouseholdData,
        private fb: UntypedFormBuilder,
    ) {
    }

    /**
     * Angular Hook zum initialisieren
     *
     */
    public ngOnInit(): void {
        if (!this.data) {
            return;
        }

        combineLatest([this.data.debtors, this.data.households])
            .pipe(takeUntil(this.viewLeft$))
            .subscribe(([debtors, households]) => {
                this.form = this.fb.group(
                    {
                        debtors: this.fb.array(debtors.filter(debtor => debtor.householdId === this.data.deletedHouseholdId)
                            .map(debtor => this.fb.group({
                                name: `${debtor.firstName} ${debtor.lastName}`,
                                id: debtor.id,
                                household: [
                                    debtor.householdId !== this.data.deletedHouseholdId ? debtor.householdId : null,
                                    Validators.required,
                                ],
                        }))),
                    },
                );

                this.selectOptions = [];

                households.forEach((household, index) => {
                    if (household.id === this.data.deletedHouseholdId) {
                        return;
                    }

                    this.selectOptions?.push({
                        value: household.id,
                        label: `${index + 1}. Haushalt`,
                    });
                });

                if (debtors.some(debtor => debtor.householdId !== this.data.deletedHouseholdId)) {
                    this.selectOptions.push({ value: 'delete', label: 'Löschen'});
                }
            });
    }

    /**
     * Angular Hook zum initialisieren
     *
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
    }

    /**
     * Gibt sicher ein FormArray zurück
     *
     * @param {FormGroup} form Form
     * @param {string} key Schlüssel des FormArrays
     * @returns {FormArray} FormArray
     */
    public asFormArray(form: FormGroup, key: string): FormArray {
        const control = form.get(key);
        return !!control ? control as FormArray : this.fb.array([]);
    }

    /**
     * Dialog abbrechen
     */
    public dismiss(): void {
        this.dialogRef.close();
    }

    /**
     * Formular speichern
     */
    public save(): void {
        if (!!this.form && this.form.valid) {
            this.dialogRef.close(this.form.value);
        }
    }
}
