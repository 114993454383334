<div fxLayout="column" *ngIf="!!control">
    <mat-label>{{ labelText }}</mat-label>
    <mat-hint *ngIf="!!comment">{{ comment }}</mat-hint>

    <div fxLayout="row" fxLayoutGap="15" fxLayoutAlign="space-between">

        <mat-checkbox fxFlex="30" *ngIf="!!checkBoxControl" [formControl]="checkBoxFormControl">
            {{ checkBoxText }}
        </mat-checkbox>

        <mat-form-field fxFlex="55" *ngIf="!!control" [hideRequiredMarker]="!isRequired" appearance="outline">
            <input [options]="options" currencyMask matInput [formControl]="formControl" [required]="!!isRequired" [readonly]="!!readonly" [min]="0"/>
            <mat-error cxad-form-field-error *ngIf="control.invalid" [control]="control"></mat-error>
        </mat-form-field>
    </div>
</div>
