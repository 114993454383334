
/**
 * Action den Hash setzt
 */
export class SetHash {
    public static readonly type = '[Application] SET_HASH';

    /**
     * Standard Konstruktor
     * 
     * @param {string} hash der authentifizierungs hash
     */
    public constructor(public hash: string) { }

}

/**
 * Action die Customer Id setzt
 */
 export class SetCustomerId {
    public static readonly type = '[Application] SET_CUSTOMER_ID';

    /**
     * Standard Konstruktor
     * 
     * @param {string} customerId id des Kunden
     */
    public constructor(public customerId: string) { }

}

/**
 * Action den aktuellen Confirmed status
 */
 export class SetConfirmed {
    public static readonly type = '[Application] SET_CONFIRMED';

    /**
     * Standard Konstruktor
     * 
     * @param {boolean} confirmed der aktuelle confirmed status
     */
    public constructor(public confirmed: boolean) { }

}
