/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable class-methods-use-this */


import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { IUserModel } from '../../models';
import { Dispose } from '../actions';

import { SdkPatchUser } from './user.actions';

export interface IUserStateModel {
    data: IUserModel | null;
}

/**
 * Klasse des Kunden States
 */
@State<IUserStateModel>({
    name: UserState.stateName,
    defaults: UserState.defaultData,
})
@Injectable()
export class UserState {
    public static readonly stateName = 'userState';

    private static readonly defaultData: IUserStateModel = {
        data: null,
    };

    /**
     * Aktualisiert die aktuelle Kunden Liste
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchUser} action PatchUser Action
     */
    @Action(SdkPatchUser)
    public patchUser(
        { patchState }: StateContext<IUserStateModel>,
        { payload }: SdkPatchUser,
    ): void {
        patchState({
            data: payload,
        });
    }

    /**
     * zurücksetzen des States
     * 
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(Dispose)
    public dispose({ setState }: StateContext<IUserStateModel>): void {
        setState({ ...UserState.defaultData });
    }

    /**
     * gibt den aktuellen Nutzer zurück
     * 
     * @param {IUserStateModel} state aktueller State
     * @returns {IUserModel} aktueller Nutzer oder null
     */
    @Selector()
    public static current(state: IUserStateModel): IUserModel | null {
        return state?.data ?? null;
    }

    /**
     * prüft ob ein Nutzer gesetzt ist
     * 
     * @param {IUserStateModel} state aktueller State
     * @returns {boolean} boolean ob gesetzt
     */
    @Selector()
    public static existsUser(state: IUserStateModel): boolean | null {
        return !!state?.data;
    }

    /**
     * gibt den Display Name des aktuellen Nutzers zurück
     * 
     * @param {IUserStateModel} state aktueller State
     * @returns {string | undefined} displayname des Nutzers
     */
    @Selector()
    public static displayName(state: IUserStateModel): string | undefined {
        return UserState.current(state)?.displayName;
    }

    /**
     * gibt alle BranchId des aktuellen Nutzers zurück die ihm zugewiesen sind
     * 
     * @param {IUserStateModel} state aktueller State
     * @returns {string[]} zugeweisene branchIds des Nutzers
     */
    @Selector()
    public static branchIds(state: IUserStateModel): string[] {
        const user = UserState.current(state);

        const branchIds = user?.branches ? [...user.branches] : [];

        if (user?.branchId) {
            branchIds.push(user.branchId);
        }

        return branchIds;
    }
}
