import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IListTuple } from '@ucba/sdk';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

/**
 * Komponente zur Anzeige einer Karte für: 
 * ein Produktpakete, 
 * für alternative Rechenbeispiel beantragen (im Fall Produktpakete),
 * oder für eine Liste von Rechenbeispielen im Falle von lediglich Rechenbeispielen.
 */
@Component({
    selector: 'ucba-sample-calculation-card',
    templateUrl: './sample-calculation-card.component.html',
    styleUrls: ['./sample-calculation-card.component.scss'],
})
export class SampleCalculationCardComponent implements OnInit, OnDestroy {

    @Input() public cardTitle?: string;
    @Input() public cardSubTitle?: string;
    @Input() public dataSource!: Array<IListTuple<string> & { iconName?: string }>;
    @Input() public userAcceptedSampleCalculationFileId = new BehaviorSubject<string | null | undefined>('');
    @Input() public sampleCalculationResponse: string | null | undefined;
    @Input() public isProposeRequest!: boolean;
    @Input() public isProductPackage = false;
    @Input() public isCxadvisory = false;

    @Output() public selectedValueChanged = new EventEmitter<string | null>();
    @Output() public openFileClicked = new EventEmitter<string>()
    @Output() public sendCreditOfferDecisionClicked = new EventEmitter<{ sampleCalculationResponse: string, accepted: boolean }>();

    private viewLeft$ = new Subject<void>();

    public form!: FormGroup;
    public cardTitleLabel!: string; // Wenn es kein Produktpakete gibt, ansonstens cardTitle wird genutzt
    public cardSubtitleLabel?: string;
    public responseLabel!: string;
    public sendAcceptedBtnLabel!: string;
    public sendProposeRequestBtnLabel!: string;

    /**
     * Standard Konstruktor
     *
     * @param {UntypedFormBuilder} fb UntypedFormBuilder injector
     * @param {TranslateService} translate TranslateService injector
     */
    public constructor(private fb: UntypedFormBuilder, private translate: TranslateService) { }
    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
    }


    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {

        this.initLabels(this.isCxadvisory);

        this.form = this.fb.group({
            acceptedSampleCalculationFileId: [],
            sampleCalculationResponse: [this.sampleCalculationResponse, { updateOn: 'change' }],
        }, { updateOn: 'blur' });

        this.userAcceptedSampleCalculationFileId?.pipe(takeUntil(this.viewLeft$)).subscribe(newId => {

            if ((!this.hasSelectedId(newId))) {

                this.form.controls['sampleCalculationResponse'].disable();
            }
            else {
                this.form.controls['sampleCalculationResponse'].enable();
            }
        })
    }

    /**
     * Gibt true zurück, wenn die ausgewählte ID zum Produktpaket gehört 
     *
     * @param {string | null | undefined} id id of the file
     * @returns {boolean} true wenn die ausgewählte ID zum Produktpaket gehört
     */
    public hasSelectedId(id: string | null | undefined): boolean {
        return this.dataSource?.some(item => item.value === id);
    }

    /**
     * Initialisieren die Labels basierend darauf, ob sie in KundenInfo oder FinAdvisory verwendet werden
     *
     * @param {isCxadvisory} isCxadvisory true wenn in KundenInfo verwendet werden
     */
    public initLabels(isCxadvisory: boolean) {
        this.cardTitleLabel = isCxadvisory ? this.translate.instant('components.financingTab.bankdocuments.titleCalculationExample') : this.translate.instant('modules.customer.components.paperTab.bank.titleCalculationExample');
        this.cardSubtitleLabel = this.translate.instant('components.financingTab.bankdocuments.subTitleCalculationExample');
        this.responseLabel = isCxadvisory ? this.translate.instant('components.financingTab.bankdocuments.sampleCalculationResponse') : this.translate.instant('modules.customer.components.paperTab.bank.sampleCalculationResponse');
        this.sendAcceptedBtnLabel = isCxadvisory ? this.translate.instant('components.financingTab.bankdocuments.submitCalculation') : this.translate.instant('modules.customer.components.paperTab.bank.submitCalculation');
        this.sendProposeRequestBtnLabel = isCxadvisory ? this.translate.instant('components.financingTab.bankdocuments.proposeRequest') : this.translate.instant('modules.customer.components.paperTab.bank.proposeRequest');

    }
}
