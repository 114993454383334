import { ICustomerModel } from '../../models';

/**
 * Action welche alle Kunden updated
 */
export class SdkPatchCustomer {
    public static readonly type = '[SDK Customer] PATCH';

    /**
     * Standard Konstruktor
     * 
     * @param {ICustomerModel} payload zu patchender Kunde
     */
    public constructor(public payload: ICustomerModel | null) { }
}
