<div class="tabcontent">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start" class="titles">
        <span>
            <h2 class="title">{{ 'components.welcome.myFinancing' | translate }}</h2>
            <p class="subtitle">
                {{ 'components.welcome.selfDisclosureMsg' | translate }}
            </p>
        </span>

        <button mat-raised-button class="btn" color="primary" (click)="openCalculator()">
            <span class="material-icons">calculate</span>
            {{ 'components.navigation.ratesCalculator' | translate}}
        </button>
    </div>
    <waiter-component [isLoading]="(financings$ | async) === null" class="area-wrapper financings-height">
        <div fxLayout="row wrap" fxLayoutGap="15px grid" fxLayout.lt-sm="column" class="scroll-wrapper">
            <ucba-financing-card fxFlex="33" fxFlex.lt-md="50" *ngFor="let fm of (financings$ | async)"
                [financingModel]="fm.financing" [forceExpand]="true" (clicked)="openFinancing(fm.financing.id)"
                [buttons]="fm.buttons">
            </ucba-financing-card>
        </div>
    </waiter-component>
</div>