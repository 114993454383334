/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import { Loader, LoaderOptions, google } from 'google-maps';

import { AddressComponentTypes } from './address-component-types.enum';
import { IFindPlaceResult } from './find-place-result.interface';
import { ILatLngZoomPosition } from './latlngzoom-position.interface';

export type mapMarkerPosition = google.maps.LatLng & { zoom: number };

/**
 * Google Service welche alle Google Funktionen Kapselt
 * 
 * @see https://www.npmjs.com/package/google-maps
 */
@Injectable()
export class GoogleService {

    public static readonly defaultLat = 47.557627;
    public static readonly defaultLng = 14.293236;
    /** to prevent double initialization of google service */
    public isLoading = false;

    private _google: google | undefined;

    private _geocoder: google.maps.Geocoder | undefined;
    private _autoCompleteService: google.maps.places.AutocompleteService | undefined;
    private _placesService: google.maps.places.PlacesService | undefined;
    private _map: google.maps.Map | undefined;

    private _marker: google.maps.Marker | undefined;
    private _infoWindow: google.maps.InfoWindow | undefined;
    private _currentAddressData: google.maps.GeocoderResult | undefined;

    private _posCenter: google.maps.LatLng | undefined;
    private _zoom: number | undefined;

    /**
     * Getter welcher prüft ob der google service geladen ist
     * 
     * @returns {boolean} ist der Service geladen
     */
    public get isLoaded(): boolean {
        return !!this._google;
    }

    /**
     * Getter welcher prüft ob die aktuelle Adresse valide ist
     * 
     * @returns {boolean} ist die Adresse valide ist
     */
    public get isAddressValid(): boolean {
        if (!!this._currentAddressData && !!this._currentAddressData.address_components) {
            const zipcode = this._currentAddressData.address_components.find(ac => ac.types.includes('postal_code'));
            const country = this._currentAddressData.address_components.find(ac => ac.types.includes('country'));

            return country?.short_name.toLowerCase() === 'at' && zipcode?.long_name.length === 4;
        }
        else {
            return false;
        }
    }

    /**
     * Gibt den AutocompleteService als Singleton zurück
     * 
     * @returns {google.maps.places.AutocompleteService} der AutocompleteService
     */
    private get autoCompleteService(): google.maps.places.AutocompleteService {
        if (!this._autoCompleteService && !!this._google) {
            this._autoCompleteService = new this._google.maps.places.AutocompleteService();
        }

        if (!this._autoCompleteService) {
            throw new Error('fail to initialize AutocompleteService');
        }

        return this._autoCompleteService;
    }

    /**
     * Gibt den Geocoder als Singleton zurück
     * 
     * @returns {google.maps.Geocoder} der Geocoder
     */
    private get geocoder(): google.maps.Geocoder {
        if (!this._geocoder && this._google) {
            this._geocoder = new this._google.maps.Geocoder();
        }

        if (!this._geocoder) {
            throw new Error('fail to initialize Geocoder');
        }

        return this._geocoder;
    }

    /**
     * Getter des PlacesService
     * 
     * @throws {Error} wenn PlacesService noch nicht gesetzt
     * @returns {google.maps.places.PlacesService} der PlacesService
     */
    private get placesService(): google.maps.places.PlacesService {
        if (!this._placesService) {
            throw new Error('You must first init the placesService! call GoogleMapsService.initPlacecService');
        }

        return this._placesService;
    }

    /**
     * Getter für die aktuellen Adress daten
     * 
     * @returns {google.maps.GeocoderResult} Adressdaten
     */
    public get currentAddressData(): google.maps.GeocoderResult | undefined {
        return this.isAddressValid ? this._currentAddressData : undefined;
    }

    /**
     * wandelt eine google geometry in eine Lat Lng Zoom Position
     * 
     * @param {google.maps.places.PlaceGeometry} geometry die google geometry
     * @returns {ILatLngZoomPosition} die ermittelte Position
     * @see https://stackoverflow.com/questions/6048975/google-maps-v3-how-to-calculate-the-zoom-level-for-a-given-bounds
     */
    public static geometryToLatLngZoom(geometry: google.maps.places.PlaceGeometry): ILatLngZoomPosition {
        const GLOBE_WIDTH = 256; // a constant in Google's map projection
        const west = geometry.viewport.getSouthWest().lng();
        const east = geometry.viewport.getNorthEast().lng();
        let angle = east - west;
        if (angle < 0) {
            angle += 360;
        }

        const pixelWidth = 600;
        const zoom = Math.round(Math.log(pixelWidth * 360 / angle / GLOBE_WIDTH) / Math.LN2);

        return {
            lat: geometry.location.lat(),
            lng: geometry.location.lng(),
            zoom,
        };
    }

    /**
     * check if the google api is loaded and start it
     */
    private static checkGoogleApi(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (typeof google === 'undefined') {
                // wait 1s until google api was loaded
                window.setTimeout(() => {
                    if (typeof google === 'undefined') {
                        reject(new Error('cant load google Api'));
                    }
                    else {
                        resolve();
                    }
                }, 1000);
            }
            else {
                resolve();
            }
        });
    }

    /**
     * prüft ob es die Adressekomponenten alle notwendigen Adressteile enthält.
     * 
     * @param {google.maps.GeocoderAddressComponent[]} addressComponents die zu prüfenden Adresskomponenten
     * @returns {boolean} existiert alle Adressteile
     */
    private static allValuesAreAvailable(addressComponents: google.maps.GeocoderAddressComponent[]): boolean {
        return (
            addressComponents.some(({ types }) => types.includes(AddressComponentTypes.route)) &&
            addressComponents.some(({ types }) => types.includes(AddressComponentTypes.street_number)) &&
            addressComponents.some(({ types }) => types.includes(AddressComponentTypes.postal_code)) &&
            (
                addressComponents.some(({ types }) => types.includes(AddressComponentTypes.locality)) ||
                addressComponents.some(({ types }) => types.includes(AddressComponentTypes.administrative_area_level_2)) ||
                addressComponents.some(({ types }) => types.includes(AddressComponentTypes.administrative_area_level_1))

            )
        );
    }

    /**
     * versucht eine prediction in adresscomponents zu wandeln
     * 
     * @param {google.maps.places.AutocompletePrediction} prediction die unsprüngliche AutocompletePrediction
     * @returns {google.maps.GeocoderAddressComponent[]} die gefundenen Adress Components
     */
    private static predictionToAddressComponents(prediction: google.maps.places.AutocompletePrediction): google.maps.GeocoderAddressComponent[] {
        const result: google.maps.GeocoderAddressComponent[] = [];

        if (prediction.terms.length > 0) {
            const term = prediction.terms[0];
            result.push({
                long_name: term.value,
                short_name: term.value,
                types: [AddressComponentTypes.route],
            });
        }

        if (prediction.terms.length > 1) {
            const term = prediction.terms[1];

            const rx = new RegExp(/\d+\w{0,3}/); // match number with max 3 letters
            if (rx.test(term.value)) {
                result.push({
                    long_name: term.value,
                    short_name: term.value,
                    types: [AddressComponentTypes.street_number],
                });
            }
        }

        if (prediction.terms.length > 2) {
            const term = prediction.terms[2];

            const rx = new RegExp(/\d+/); // match only numbers
            if (rx.test(term.value)) {
                result.push({
                    long_name: term.value,
                    short_name: term.value,
                    types: [AddressComponentTypes.postal_code],
                });
            }
        }

        if (prediction.terms.length > 3) {
            const term = prediction.terms[3];
            result.push({
                long_name: term.value,
                short_name: term.value,
                types: [AddressComponentTypes.locality],
            });
        }

        return result;
    }

    /**
     * füllt fehlende GeocoderAddressComponent Werte mit den AutocompletePrediction auf
     * 
     * @param {google.maps.GeocoderAddressComponent[]} current Bisherige GeocoderAddressComponent
     * @param {google.maps.places.AutocompletePrediction} prediction aktuelle AutocompletePrediction
     * @returns {google.maps.GeocoderAddressComponent[]} neue GeocoderAddressComponent
     */
    private static fillUpWithPredictions(current: google.maps.GeocoderAddressComponent[], prediction: google.maps.places.AutocompletePrediction): google.maps.GeocoderAddressComponent[] {
        const predictedComponents = GoogleService.predictionToAddressComponents(prediction);
        const result = current;

        for (const component of predictedComponents) {
            if (!result.some(c => c.types.includes(component.types[0]))) {
                result.push(component);
            }
        }

        return result;
    }

    /**
     * initialisiert die Google Api
     * 
     * @param {string} googleApiKey Key um google Api zu initialisieren
     * @returns {Promise} google objekt
     */
    public async initGoogleLoader(googleApiKey: string): Promise<google> {
        if (this._google === undefined) {
            this.isLoading = true;
            const options: LoaderOptions = {
                libraries: ['places'],
                language: 'de',
                region: 'AT',
            };

            const loader = new Loader(googleApiKey, options);

            try {
                this._google = await loader.load();
            }
            finally {
                this.isLoading = false;
            }
        }

        return this._google;
    }

    /**
     * setzt den marker auf der Map
     * 
     * @param {ILatLngZoomPosition} geometry initialwerte zum initialisieren
     */
    public initializeMarker(geometry?: ILatLngZoomPosition): void {

        if (!this._google) {
            throw new Error('google is not defiend! call GoogleMapsService.initGoogleLoader()')
        }

        if (!this._map) {
            throw new Error('map is not defiend! call GoogleMapsService.setMap()')
        }

        this._posCenter = !!geometry ?
            new this._google.maps.LatLng(geometry.lat, geometry.lng) :
            new this._google.maps.LatLng(GoogleService.defaultLat, GoogleService.defaultLng); // Östereich

        this._zoom = !!geometry ? geometry.zoom : 8;

        this._map.setOptions({
            center: this._posCenter,
            gestureHandling: 'greedy',
            mapTypeControl: false,
            rotateControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            clickableIcons: false,
            zoom: this._zoom,
        });

        const markerOpt = { // google.maps.ReadonlyMarkerOptions
            draggable: true,
            animation: this._google.maps.Animation.BOUNCE,
            position: this._posCenter,
            map: this._map,
        };

        if (!!this._marker) {
            this._marker.setOptions(markerOpt);
        }
        else {
            this._marker = new this._google.maps.Marker(markerOpt);

            this._map.addListener('click', ev => {

                if (this._marker) {
                    this._marker.setAnimation(null);
                }

                this.placeMarkerAndPanTo(ev.latLng);
                this._posCenter = ev.latLng;

                this.geolocate(ev.latLng).then(result => {
                    this._currentAddressData = result;
                    if (!!this._infoWindow) {
                        this._infoWindow.setContent(!!this._currentAddressData ? this._currentAddressData.formatted_address : '');
                    }
                }).catch(e => { throw e; });
            });

            this._marker.addListener('dragend', ev => {
                if (!!this._infoWindow && this.infoWindowIsOpen()) {
                    this._infoWindow.close();
                }

                this.placeMarkerAndPanTo(ev.latLng);
                this._posCenter = ev.latLng;

                this.geolocate(ev.latLng).then(result => {
                    this._currentAddressData = result;
                    if (!!this._infoWindow) {
                        this._infoWindow.setContent(!!this._currentAddressData ? this._currentAddressData.formatted_address : '');

                        if (!this.infoWindowIsOpen() && !!this._posCenter) {
                            this._infoWindow.open(this._map, this._marker);
                        }
                    }
                }).catch(e => { throw e; });
            });

            this._marker.addListener('dragstart', () => {
                if (!!this._marker) {
                    this._marker.setAnimation(null);
                }

                if (this._infoWindow) {
                    this._infoWindow.close();
                }
            });

            this._marker.addListener('click', () => {
                if (!!this._marker) {
                    this._marker.setAnimation(null);
                }

                if (!!this._infoWindow && !this.infoWindowIsOpen()) {
                    this._infoWindow.open(this._map, this._marker);
                }
            });
        }

        if (!this._infoWindow) {
            this._infoWindow = new this._google.maps.InfoWindow({
                content: !!this._currentAddressData ? this._currentAddressData.formatted_address : '',
            });
        }

        // tslint:disable-next-line:no-identical-functions
        this.geolocate(this._posCenter).then(result => {
            this._currentAddressData = result;
            if (!!this._infoWindow) {
                this._infoWindow.setContent(!!this._currentAddressData ? this._currentAddressData.formatted_address : '');
            }
        }).catch(e => { throw e; });

        if (!!this._infoWindow && !this.infoWindowIsOpen()) {
            this._infoWindow.open(this._map, this._marker);
        }

        // this.placeMarkerAndPanTo(this._posCenter);
    }

    /**
     * initialisiert den Places Service an das übergebene HTML Element
     * 
     * @param {HTMLElement} mapElement element über den der Place Service initialisiert wird
     * @returns {google.maps.places.PlacesService} der PlacesService
     */
    public initPlacesService(mapElement: HTMLElement): google.maps.places.PlacesService {
        if (!this._google) {
            throw new Error('google is not defiend! call GoogleMapsService.initGoogleLoader()')
        }

        const map = new this._google.maps.Map(mapElement);
        this._placesService = new this._google.maps.places.PlacesService(map);
        return this._placesService;
    }

    /**
     * initialisieze the Map Element with given HTML Element
     * 
     * @param {HTMLElement} mapElement element an welches die Map gehängt werden soll
     * @param {google.maps.MapOptions} options MapOptions welche gesetzt werden können
     * @returns {google.maps.Map} das Mapsobjekt
     * @see https://developers.google.com/maps/documentation/javascript/overview#maps_map_simple-typescript
     */
    public setMap(mapElement: HTMLElement, options?: google.maps.MapOptions): google.maps.Map {
        if (!this._google) {
            throw new Error('google is not defiend! call GoogleMapsService.initGoogleLoader()')
        }

        this._map = new this._google.maps.Map(mapElement, options);
        return this._map;
    }

    /**
     * gibt das Map Element frei und entfernt alle Bezüge darauf
     */
    public unsetMap(): void {
        if (!!this._map) {
            this._map.unbindAll();
            this._map = undefined;
        }

        if (!!this._marker) {
            // this._marker.setMap(null);
            this._marker.unbindAll();
            this._marker = undefined;
        }

        if (!!this._infoWindow) {
            this._infoWindow.unbindAll();
            this._infoWindow = undefined;
        }
    }

    /**
     * Sucht nach der übergebenen Adresse und gibt ein Array aus AutocompletePrediction zurück
     * 
     * @param {string} addressString die zu suchende Adresse
     * @returns {google.maps.places.AutocompletePrediction[]} Ergebnis AutocompletePredictions
     */
    public async findLocations(addressString: string): Promise<google.maps.places.AutocompletePrediction[]> {
        await GoogleService.checkGoogleApi();
        return new Promise<google.maps.places.AutocompletePrediction[]>((resolve, reject) => {

            const request: google.maps.places.AutocompletionRequest = {
                input: addressString,
                types: ['geocode'],
                componentRestrictions: {
                    country: 'AT',
                },
            };

            this.autoCompleteService.getPlacePredictions(request, (result, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    result = result.filter(r => r.terms.length >= 4); // only with all terms
                    resolve(result);
                }
                else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                    resolve([]);
                }
                else if (status === google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR) {
                    reject(new Error('LocationService'));
                }
                else {
                    reject(new Error(status));
                }
            });
        });
    }

    /**
     * Sucht nach einer Position anhand der übergebenen Werte. Wenn nichts gefunden wird,
     * wird immer der letzte Wert weggenommen und erneut gesucht.
     * Optimale Reihenfolge: [zip, city, street]
     * 
     * @param {string[]} queryParts die Adress teile
     * @returns {google.maps.places.PlaceGeometry} die gefundene Position
     */
    public async getNearPosition(queryParts: string[]): Promise<google.maps.places.PlaceGeometry | undefined> {
        await GoogleService.checkGoogleApi();

        for (let i = queryParts.length; i > 0; i--) {
            const request: google.maps.places.FindPlaceFromQueryRequest = {
                query: queryParts.join('+'),
                fields: ['geometry', 'formatted_address'],
                locationBias: new google.maps.LatLng(GoogleService.defaultLat, GoogleService.defaultLng),
            };

            const placeResult = await this.findPlaceFromQueryPromise(request);

            if (placeResult.status === google.maps.places.PlacesServiceStatus.OK) {
                if (!!placeResult.results[0] && placeResult.results[0].geometry) {
                    return placeResult.results[0].geometry;
                }
                else {
                    queryParts.splice(queryParts.length - 1);
                    continue;
                }
            }
            else if (placeResult.status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                queryParts.splice(queryParts.length - 1);
                continue;
            }
            else if (placeResult.status === google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR) {
                throw new Error('PlacesService');
            }
            else {
                throw placeResult.status;
            }
        }

        return undefined;
    }

    /**
     * gibt die detailierten Adressinformationen zur übergebenen placeId zurück.
     * Sollten die Informationen unvollständig sein, werden die predictions mit verwendet
     * 
     * @param {string} placeId die id der adressauswahl
     * @param {google.maps.places.AutocompletePrediction} prediction Predictions welche bei unvollständigem ergebnis mit verwendet werden
     * @returns {Promise<google.maps.places.PlaceResult>} detailierte Adresse
     */
    public async getDetailAddress(placeId: string, prediction?: google.maps.places.AutocompletePrediction): Promise<google.maps.places.PlaceResult | undefined> {
        // Predictions welche bei unvollständigem ergebnis mit verwendet werden, z.B. Grazer Straße, 296, 8240, Friedberg -> in details fehlt Hausnummer
        await GoogleService.checkGoogleApi();
        return new Promise<google.maps.places.PlaceResult | undefined>((resolve, reject) => {

            const request: google.maps.places.PlaceDetailsRequest = {
                placeId,
                fields: ['geometry', 'formatted_address', 'address_component'],
            };

            // tslint:disable-next-line:no-identical-functions
            this.placesService.getDetails(request, (result, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK ||
                    status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {

                    if (status === google.maps.places.PlacesServiceStatus.OK ||
                        status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {

                        if (!!result && Array.isArray(result.address_components)) {
                            // check if all values a available
                            if (!GoogleService.allValuesAreAvailable(result.address_components)) {

                                if (!!prediction) {
                                    result.address_components = GoogleService.fillUpWithPredictions(result.address_components, prediction);
                                    if (!GoogleService.allValuesAreAvailable(result.address_components)) {
                                        resolve(undefined);
                                    }
                                    else {
                                        resolve(result);
                                    }
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(result);
                            }
                        }
                        else {
                            resolve(result);
                        }
                    }
                    else if (status === google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR) {
                        reject(new Error('DetailService'));
                    }
                    else {
                        resolve(result);
                    }
                }
                else {
                    reject(status);
                }
            });

        });
    }

    /**
     * gibt die Adresse für die übergebene Position zurück
     * 
     * @param {google.maps.LatLng} latlng die Position
     * @returns {google.maps.GeocoderResult} die Adresse als GeocoderResult
     */
    public async geolocate(latlng: google.maps.LatLng): Promise<google.maps.GeocoderResult | undefined> {
        await GoogleService.checkGoogleApi();
        return new Promise<google.maps.GeocoderResult | undefined>((resolve, reject) => {

            const request: google.maps.GeocoderRequest = {
                location: latlng,
            };

            this.geocoder.geocode(request, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    const validAddress = results.find(res => GoogleService.allValuesAreAvailable(res.address_components));
                    resolve(validAddress);
                }
                else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
                    resolve(undefined);
                }
                else if (status === google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
                    resolve(undefined);
                }
                else if (status === google.maps.GeocoderStatus.UNKNOWN_ERROR) {
                    reject(new Error('GeocoderService'));
                }
                else {
                    reject(status);
                }
            });

        });
    }

    /**
     * Setzt den Marker an die übergebene Position
     * 
     * @param {google.maps.LatLng} latLng neue Position des Markers
     */
    private placeMarkerAndPanTo(latLng: google.maps.LatLng) {
        if (latLng === null) {
            if (!!this._marker) {
                this._marker.setMap(null);
            }
        }
        else {
            if (!!this._marker && this._map) {
                if (this._marker.getMap() === null) {
                    this._marker.setMap(this._map);
                }

                this._marker.setPosition(latLng);
                this._map.panTo(latLng);
            }
        }
    }

    /**
     * prüft ob das Info Fenster über dem Marker offen ist
     * 
     * @returns {boolean} Info ist offen
     * @see https://groups.google.com/forum/#!msg/google-maps-js-api-v3/_L0RdmgNCbo/IB2MlW5iwpgJ
     */
    private infoWindowIsOpen(): boolean {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return !!this._infoWindow && !!(this._infoWindow as any)['getMap']();
    }

    /**
     * ruft die findPlaceFromQuery auf und gibt das Ergebnis als Promise zurück
     * 
     * @param {google.maps.places.FindPlaceFromQueryRequest} request das Anfrage Objekt
     * @returns {Promise<IFindPlaceResult>} das Ergebnis als Promise
     */
    private findPlaceFromQueryPromise(request: google.maps.places.FindPlaceFromQueryRequest): Promise<IFindPlaceResult> {
        return new Promise<IFindPlaceResult>(resolve => {
            this.placesService.findPlaceFromQuery(request, (results, status) => {
                resolve({ results, status });
            });
        });
    }
}
