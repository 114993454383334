import { IUserModel } from '../../models';

/**
 * Action welche alle Kunden updated
 */
export class SdkPatchUser {
    public static readonly type = '[SDK User] PATCH';

    /**
     * Standard Konstruktor
     * 
     * @param {IUserModel} payload zu patchender Kunde
     */
    public constructor(public payload: IUserModel | null) { }
}
