import { extension } from 'mime-types';

/**
 * static helper for file handling
 */
export class FileHelper {

    /**
     * Private Konstruktor
     * 
     */
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() { } // dont be instancable, only static methods

    /**
     * Erstellen eines Blobs aus einer Base64-Zeichenkette
     * 
     * @see https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
     * @param {string} dataURI Data-URL
     * @returns {Blob} erstelltes Blob
     */
    public static dataURItoBlob(dataURI: string): Blob {
        const arr = dataURI.split(',');
        const sliceSize = 512;
        const matchArray = arr[0].match(/:(.*?);/);
        const mimeType = !!arr[0] && !!matchArray ? matchArray[1] : '';
        const byteCharacters = atob(arr[1]);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: mimeType });
    }

    /**
     * Blob in base64 dataURL konvertieren
     * 
     * @see https://stackoverflow.com/questions/23150333/html5-javascript-dataurl-to-blob-blob-to-dataurl
     * @param {Blob} blob Blob was konvertiert werden soll
     * @returns {Promise<string>} abschluss Promise
     */
    public static blobToDataURL(blob: Blob): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if (!blob) { reject(new Error('blobToDataURL failed: blob is not defined')); }

            const fileReader = new FileReader();

            fileReader.onload = () => {
                resolve(fileReader.result as string);
            };

            fileReader.onerror = ev => {
                // eslint-disable-next-line no-console
                console.error(ev);
                reject(new Error('blobToDataURL failed'));
            };

            fileReader.readAsDataURL(blob);
        });
    }

    /**
     * Blob in HTMLImageElement umwandeln
     * 
     * @see https://stackoverflow.com/a/12570870
     * @param {Blob} blob Blob was konvertiert werden soll
     * @returns {Promise<HTMLImageElement>} abschluss Promise
     */
    public static blobToHTMLImage(blob: Blob): Promise<HTMLImageElement> {
        return new Promise<HTMLImageElement>((resolve, reject) => {
            if (!blob) { reject(new Error('blobToHTMLImage failed: blob is not defined')); }

            const fileReader = new FileReader();

            fileReader.onload = () => {
                const img = new Image();
                img.onload = () => {
                    resolve(img);
                };
                img.onerror = err => {
                    // eslint-disable-next-line no-console
                    console.error(err);
                    reject(new Error('blobToHTMLImage Image onload failed'));
                };

                img.src = (fileReader.result as string);
            };

            fileReader.onerror = err => {
                // eslint-disable-next-line no-console
                console.error(err);
                reject(new Error('blobToHTMLImage failed'));
            };

            fileReader.readAsDataURL(blob);
        });
    }

    /**
     * Blob in einen Array-Puffer umwandeln
     * 
     * @param {Blob} blob Blob was konvertiert werden soll
     * @returns {Promise<ArrayBuffer>} abschluss Promise
     */
    public static blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
        return new Promise<ArrayBuffer>((resolve, reject) => {
            if (!blob) { reject(new Error('blobToArrayBuffer failed: blob is not defined')); }

            const fileReader = new FileReader();

            fileReader.onload = () => {
                resolve(fileReader.result as ArrayBuffer);
            };

            fileReader.onerror = ev => {
                // eslint-disable-next-line no-console
                console.error(ev);
                reject(new Error('blobToArrayBuffer failed'));
            };
            fileReader.readAsArrayBuffer(blob);
        });
    }

    /**
     * einen ArrayBuffer in einen Blob umwandeln
     * 
     * @param {ArrayBuffer} buffer ArrayBuffer
     * @param {string} mimeType mimetype der Datei
     * @returns {Blob} erstelltes Blob
     */
    public static arrayBufferToBlob(buffer: ArrayBuffer, mimeType: string): Blob {
        return new Blob([buffer], { type: mimeType });
    }

    /**
     *  Konvertierung eines Array-Puffers in base64-Bilddaten
     * 
     * @see https://stackoverflow.com/questions/9267899/arraybuffer-to-base64-encoded-string/42334410
     * @param {ArrayBuffer} buffer ArrayBuffer
     * @returns {string} base64-kodierte Zeichenkette
     */
    public static arrayBufferToBase64(buffer: ArrayBuffer): string {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    /**
     * einen ArrayBuffer in Image URI umwandeln
     * 
     * @param {ArrayBuffer} buffer ArrayBuffer
     * @param {string} mimeType mimetype der Datei
     * @returns {Promise<string>} abschluss Promise
     */
    public static arrayBufferToDataUri(buffer: ArrayBuffer, mimeType: string): Promise<string> {
        const blob = FileHelper.arrayBufferToBlob(buffer, mimeType);
        return FileHelper.blobToDataURL(blob);
    }

    /**
     * Konvertierung von base64-Bilddaten in ArrayBuffer
     * 
     * @see https://stackoverflow.com/questions/21797299/convert-base64-string-to-arraybuffer
     * @param {string} base64 base64 string
     * @returns {ArrayBuffer} buffer ArrayBuffer
     */
    public static base64ToArrayBuffer(base64: string): ArrayBuffer {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer as ArrayBuffer;
    }

    /**
     * data:image/jpeg;base64, wird weg getrimmt
     * 
     * @param {string} dataUri dataUri
     * @returns {string} getrimmte dataUri
     */
    public static dataUriToBase64(dataUri: string): string {
        return dataUri.startsWith('data:') ? dataUri.split(',', 2)[1] : dataUri;
    }

    /**
     * prüfen, ob die Datei-URL eine Online-Url ist (web:[fileId])
     * 
     * @param {string} content URL
     * @returns {boolean} true = Online-Url, false = keine Online-Url
     */
    public static isWebURL(content: string): boolean {
        return !!content && content.startsWith('web:');
    }

    /**
     * Rückgabe der fileId als webURL (web:[fileId])
     * 
     * @param {string} fileId URL
     * @returns {string} webURL
     */
    public static idToWebURL(fileId: string): string {
        return `web:${fileId}`;
    }
    /**
     * Rückgabe der fileId aus webURL (web:[fileId])
     * 
     * @param {string} content URL
     * @returns {string} fileId
     */
    public static getIdFromWebURL(content: string): string {
        return content.substr(4);
    }

    /**
     * Konvertier aus dem Feld den Namen und die Extension
     * 
     * @param {string} fileName Name des Feldes
     * @returns {{ name: string, extension: string}} gibt den Namen und die Extension zurück
     */
    public static getNameParts(fileName: string): { name: string, extension: string } {
        if (fileName.includes('.')) {
            const idx = fileName.lastIndexOf('.');
            return {
                name: fileName.substring(0, idx),
                extension: fileName.substring(idx),
            }
        } else {
            return { name: fileName, extension: '' };
        }
    }

    /**
     * Datei mit angegebener Uri herunterladen
     * 
     * @param {string} data Base64 String
     * @param {string} filename Name der Datei
     * @returns {Promise<void>} Void Promise
     */
    public static downloadFileFromDataUri(data: string, filename: string): Promise<void> {
        const blob = FileHelper.dataURItoBlob(data);
        return this.downloadFileFromBlob(blob, filename);
    }

    /**
     * Datei aus gegebenem ArrayBuffer herunterladen
     * 
     * @param {ArrayBuffer} buffer ArrayBuffer
     * @param {string} mimeType Mime-Type der Datei
     * @param {string} filename Name der Datei
     * @returns {Promise<void>} Void Promise
     */
    public static downloadFileFromArrayBuffer(buffer: ArrayBuffer, mimeType: string, filename: string): Promise<void> {
        const blob = FileHelper.arrayBufferToBlob(buffer, mimeType);
        return this.downloadFileFromBlob(blob, filename);
    }

    /**
     * Datei aus gegebenem Blob herunterladen
     * 
     * @param {Blob} blob Blob
     * @param {string} filename Name der Datei
     * @returns {Promise<void>} Void Promise
     */
    public static downloadFileFromBlob(blob: Blob, filename: string): Promise<void> {
        return new Promise<void>(resolve => {
            const ext = `.${extension(blob.type)}`;
            const a = document.createElement('a');
            a.style.display = 'none';
            a.download = filename + ext;

            const blobUrl = (window.URL ? window.URL : window['webkitURL']).createObjectURL(blob);
            a.href = blobUrl;

            document.body.appendChild(a);
            a.click();

            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(blobUrl);
                resolve();
            }, 200);
        });
    }
}
