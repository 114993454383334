import { Component, Input } from '@angular/core';

/**
 * Custom Spinner-Komponente
 */
@Component(
    {
        selector: 'cxad-spinner',
        templateUrl: './spinner.component.html',
        styleUrls: ['./spinner.component.scss'],
    })
export class SpinnerComponent {
    @Input() public scaleValue = '1';
}
