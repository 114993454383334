<mat-tab-group *ngIf="items" [selectedIndex]="activeElement" class="time-line-group">
    <mat-tab *ngFor="let item of items; let i = index" disabled>
        <ng-template mat-tab-label>
            <div class="time-line-item" [matTooltip]="item.statusNotes ? item.statusNotes : item.statusName">
                <ng-container [ngSwitch]="item.itemStatus">
                    <div *ngSwitchCase="'number'" class="time-line-item-bubble number-bubble">
                        <span class="time-line-item-bubble-span">{{item.position}}</span>
                    </div>
                    <div *ngSwitchCase="'edit'" class="time-line-item-bubble edit-bubble">
                        <span class="time-line-item-bubble-span">{{item.position}}</span>
                    </div>
                    <div *ngSwitchCase="'done'" class="time-line-item-bubble done-bubble">
                        <mat-icon>check</mat-icon>
                    </div>
                    <div *ngSwitchCase="'error'" class="time-line-item-bubble error-bubble">
                        <mat-icon>warning</mat-icon>
                    </div>
                </ng-container>
                <div class="time-line-item-label">{{item.statusName}}</div>
            </div>
        </ng-template>
    </mat-tab>
</mat-tab-group>