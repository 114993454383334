<mat-toolbar class="header mat-elevation-z3 header-toolbar">
    <div fxFlex="33%" fxLayout="row" fxLayoutAlign="start center">
        <button mat-button type="button" (click)="back()" matTooltip="Zurück zum Objektbereich">
            <span class="material-icons md-36">arrow_back</span>
        </button>
    </div>
    <div fxFlex="33%" fxLayout="row" fxLayoutAlign="center center">
        <span>{{ 'components.googleMaps.mapTitle' | translate }}</span>
    </div>
</mat-toolbar>

<div class="label" fxLayout="row">
    <div fxLayout="column">
        {{'modules.customer.components.financingTab.realestate.noAddressFound' | translate}}
    </div>
</div>
<ucba-map class="google" [geometry]="geometry"></ucba-map>
<div class="button" fxLayout="row">
    <div fxLayout="column">
        <button class="button-submite" [disabled]="!isAddressValid" (click)="onSubmitMapClicked()" mat-raised-button
            color="primary">
            {{ 'components.googleMaps.mapSubmit' | translate }}
        </button>
    </div>
</div>