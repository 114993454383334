<mat-form-field *ngIf="!!internalValueCtr" [attr.name]="controlName" [hideRequiredMarker]="!isRequired">
    <mat-label>{{ labelText }}</mat-label>
    <input [options]="options" currencyMask matInput [formControl]="internalValueCtr" [required]="!!isRequired" />

    <mat-error ucba-form-field-error *ngIf="internalValueCtr.invalid" [control]="internalValueCtr"></mat-error>
    <mat-hint *ngIf="!!comment">{{ comment }}</mat-hint>
</mat-form-field>

<div class="percent-toggle" *ngIf="!!internalIsPercentCtr" matTooltip="Eingabeart in € / % wechseln">
    <span>€</span>
    <mat-slide-toggle [formControl]="internalIsPercentCtr">%</mat-slide-toggle>
</div>