import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { DocumentType, RealEstateType } from '@ntag-ef/finprocess-enums/finadvisory';
import { WaiterService } from '@ntag-ef/waiter';
import { Observable, Subject, combineLatest, forkJoin, map, mergeMap, of, take, takeUntil } from 'rxjs';

import { FileController, SignatureController } from '../../controller';
import { IImageOptions, ISignatureDialogResult } from '../../interfaces';
import { IDebtorModel, IRealEstateModel, ISignatureModel } from '../../models';
import { ICreateFileRequest } from '../../requests';
import { DataService, FileHelper, HelperService } from '../../services';
import { CustomerState, DebtorState, FinancingMapState, HouseholdState, RealEstateState, SignatureState } from '../../statemanagement/states';
import { SignatureDialogComponent } from '../signatur/signatur.component';

interface IDebtorSignatureMap {
    debtor: IDebtorModel;
    signature?: ISignatureModel;
}

/**
 * Komponente um die Datenschutzerklärung anzuzeigen
 */
@Component({
    selector: 'ucba-privacy-statement',
    templateUrl: './privacy-statement.component.html',
    styleUrls: ['./privacy-statement.component.scss'],
})
export class PrivacyStatementComponent implements OnInit {

    private downloadInProgress: boolean | undefined;
    public debSignMap: IDebtorSignatureMap[] = [];
    public customerName: string | undefined | null;
    public realEstate: IRealEstateModel | undefined | null;
    public width?: string;
    public imgOptions: IImageOptions | undefined;

    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();

    public helperService = HelperService;
    public realEstateType = RealEstateType;
    public isLoading = true;

    /**
     * Standard konstruktor
     * 
     * @param { Store } store Store injector
     * @param { Location } location Location injector
     * @param { MatDialog } matDialog MatDialog injector
     * @param { DataService } dataService DataService injector
     * @param { SignatureController } signatureController SignatureController injector
     * @param { FileController } fileController FileController injector
     * @param { WaiterService } waiter WaiterService injector
     */
    public constructor(
        private store: Store,
        private location: Location,
        private matDialog: MatDialog,
        private dataService: DataService,
        private signatureController: SignatureController,
        private fileController: FileController,
        private waiter: WaiterService,
    ) { }

    /**
     * Angular Hook um Werte zu initialisieren
     */
    public ngOnInit(): void {
        combineLatest([
            this.store.select(CustomerState.currentName),
            this.store.select(RealEstateState.currentFinancingObject),
        ])
            .pipe(
                takeUntil(this.viewLeft$),
                map(([customerName, realEstate]) => ({
                    customerName,
                    realEstate,
                })))
            .subscribe(({ customerName, realEstate }) => {
                this.customerName = customerName;
                this.realEstate = realEstate;
            });

        combineLatest([
            this.store.select(DebtorState.current),
            this.store.select(SignatureState.current),
        ])
            .pipe(
                takeUntil(this.viewLeft$),
                map(([debtors, signatures]) => debtors.map<IDebtorSignatureMap>(deb => (
                    {
                        debtor: deb,
                        signature: signatures.find(({ debtorId }) => debtorId === deb.id),
                    }),
                )),
                mergeMap(signMaps => {
                    const downloadObservables = signMaps.reduce<Observable<{ content: Blob | null, id: string }>[]>((acc, curr) => {

                        if (!!curr.signature) {
                            const obs = this.signatureController.downloadSignature(curr.signature.id)
                                .pipe(map(content => ({ content, id: (curr.signature as ISignatureModel).id })));

                            return [...acc, obs];
                        }
                        else {
                            return acc;
                        }
                    }, []);

                    if (downloadObservables.length > 0) {
                        return forkJoin(downloadObservables)
                            .pipe(map(contents => signMaps.map<IDebtorSignatureMap>(m => ({
                                ...m,
                                signature: !!m.signature ? ({
                                    ...m.signature,
                                    content: contents.find(c => c.id === m.signature?.id)?.content,
                                }) : undefined,
                            }))));
                    }
                    else {
                        return of(signMaps);
                    }
                }),
            )
            .subscribe(async result => {
                for (const m of result) {
                    if (!!m.signature && m.signature.content instanceof Blob) {
                        m.signature.content = await FileHelper.blobToDataURL(m.signature.content);
                    }
                }

                this.debSignMap = result;
                if (!!result) {
                    this.isLoading = false;
                }
            });
    }

    /**
     * navigiert zur vorherigen Seite
     */
    public back(): void {
        this.location.back();
    }

    /**
     * Herunterladen der geöffneten Datei
     */
    public download(): void {
        if (!this.downloadInProgress) {
            this.downloadInProgress = true;
            this.waiter.show();
            const financingMapId = this.store.selectSnapshot(FinancingMapState.currentId);
            if (!!financingMapId) {
                const request: ICreateFileRequest = {
                    financingMapId: financingMapId,
                    householdId: this.store.selectSnapshot(HouseholdState.currentIds)[0],
                    type: DocumentType.PrivacyStatementSignature,
                };

                this.fileController.createServerFile(request).pipe(take(1)).subscribe(async result => {
                    if (!!result) {
                        if (HelperService.isPDF(result.type)) {
                            const buffer = await FileHelper.blobToArrayBuffer(result);
                            FileHelper.downloadFileFromArrayBuffer(buffer, result.type, 'Datenschutzerklärung').then(() => {
                                this.downloadInProgress = false;
                                this.waiter.hide();
                            }).catch((e: unknown) => {
                                this.downloadInProgress = false;
                                this.waiter.hide();
                                throw e;
                            });
                        }
                    }
                });
            }
        }
    }

    /**
     * Dialog öffnen zum setzten einer Unterschrift und speichern bzw. löschen dieser
     * 
     * @param {Event} event event des Buttons
     * @param {IDebtorSignatureMap} signMap beinhaltet den Debtoren und die Unterschrift
     */
    public openSignatureModal(event: Event, signMap: IDebtorSignatureMap): void {
        event.stopImmediatePropagation();
        const dialogRef = this.matDialog.open(SignatureDialogComponent, {
            data: {
                debtor: signMap.debtor,
                signature: signMap.signature,
            },
        });
        dialogRef.afterClosed().pipe(take(1)).subscribe(async (result: ISignatureDialogResult) => {
            if (!!result) {
                if (result.wasCleared && result.isEmpty) {
                    if (!!signMap.signature) {
                        await this.dataService.deleteSignature(signMap.signature?.id);
                    }
                }

                if (!result.isEmpty) {
                    if (!!signMap.signature) {
                        await this.dataService.deleteSignature(signMap.signature?.id);
                    }
                    const financingMapId = this.store.selectSnapshot(FinancingMapState.currentId);
                    if (!!financingMapId) {
                        const newSignature: Partial<ISignatureModel> = {
                            financingMapId,
                            content: result.base64Data,
                            debtorId: signMap.debtor.id,
                            type: DocumentType.PrivacyStatementSignature,
                        };

                        await this.dataService.createSignature(newSignature);
                    }
                }
            }
        });
    }
}
