import { IBranchModel, ICountryModel, IDebitRateAdaptionModel, IDebitRateModel, IFinancingConfigurationModel, ILegalisationFeeBaseModel, IMorixRatingAssignmentModel } from '../../models';

/**
 * Action welche alle Filialen updated
 */
export class SdkPatchBranches {
    public static readonly type = '[MasterData] SdkPatchBranches';

    /**
     * Standard Konstruktor
     * 
     * @param {IBranchModel[]} payload zu patchende Filialen
     */
    public constructor(public payload: IBranchModel[]) { }
}

/**
 * Action welche alle DebitRate updated
 */
export class SdkPatchDebitRate {
    public static readonly type = '[MasterData] SdkPatchDebitRate';

    /**
     * Standard Konstruktor
     * 
     * @param {IDebitRateModel[]} payload zu patchende DebitRate
     */
    public constructor(public payload: IDebitRateModel[]) { }
}

/**
 * Action welche alle DebitRateAdaption updated
 */
export class SdkPatchDebitRateAdaption {
    public static readonly type = '[MasterData] SdkPatchDebitRateAdaption';

    /**
     * Standard Konstruktor
     * 
     * @param {IDebitRateAdaptionModel[]} payload zu patchende DebitRateAdaption
     */
    public constructor(public payload: IDebitRateAdaptionModel[]) { }
}

/**
 * Action welche alle IFinancingConfigurationModel updated
 */
export class SdkPatchFinancingConfigurations {
    public static readonly type = '[MasterData] SdkPatchFinancingConfigurations';

    /**
     * Standard Konstruktor
     * 
     * @param {IFinancingConfigurationModel[]} payload zu patchende IFinancingConfigurationModel
     */
    public constructor(public payload: IFinancingConfigurationModel[]) { }
}

/**
 * Action welche alle IMorixRatingAssignmentModel updated
 */
export class SdkPatchMorixRatingAssignments {
    public static readonly type = '[MasterData] SdkPatchMorixRatingAssignments';

    /**
     * Standard Konstruktor
     * 
     * @param {IMorixRatingAssignmentModel[]} payload zu patchende IMorixRatingAssignmentModel
     */
    public constructor(public payload: IMorixRatingAssignmentModel[]) { }
}

/**
 * Action welche alle Countries updated
 */
export class SdkPatchCountries {
    public static readonly type = '[MasterData] SdkPatchCountries';

    /**
     * Standard Konstruktor
     * 
     * @param {ICountryModel[]} payload zu patchende Countries
     */
    public constructor(public payload: ICountryModel[]) { }
}

/**
 * Action welche alle LegalisationFee updated
 */
export class SdkPatchLegalisationFee {
    public static readonly type = '[MasterData] SdkPatchLegalisationFee';

    /**
     * Standard Konstruktor
     * 
     * @param {ILegalisationFeeBaseModel[]} payload zu patchende LegalisationFee
     */
    public constructor(public payload: ILegalisationFeeBaseModel[]) { }
}

/**
 * Action welche alle bankAccounts updated
 */
export class SdkPatchBankAccounts {
    public static readonly type = '[MasterData] SdkPatchBankAccounts';

    /**
     * Standard Konstruktor
     * 
     * @param {string[]} payload zu patchende BankAccounts
     */
    public constructor(public payload: string[]) { }
}
