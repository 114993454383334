import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { GlobalSettings } from '@ucba/sdk';
import { RoutingParams } from '@ucba/sdk/enums';
import { ICustomerModel } from '@ucba/sdk/models';
import { Dispose } from '@ucba/sdk/statemanagement/actions';
import { CustomerState } from '@ucba/sdk/statemanagement/states';
import { Observable, filter, map } from 'rxjs';

import { ApplicationState } from '../../statemanagement/states';

/**
 * Komponente welche die Tabbar enthält für die Navigation
 */
@Component({
    selector: 'cxad-tab-navigation',
    templateUrl: './tab-navigation.component.html',
    styleUrls: ['./tab-navigation.component.scss'],
})

export class TabNavigationComponent implements OnInit {
    @Select(ApplicationState.isLoggedIn)
    public isLoggedIn$: Observable<boolean> | undefined;

    @Select(CustomerState.current)
    public customer$: Observable<ICustomerModel | null> | undefined;

    public hideHeader = false;

    public globalSettings = GlobalSettings;

    /**
     * Standard Konstruktor
     *
     * @param { Router } router Router injector
     * @param { Store } store Store injector
     * @param {NotificationService} notification NotificationService injector
     * @param {TranslateService} translate TranslateService injector
     */
    public constructor(
        private router: Router,
        private store: Store,
        private notification: NotificationService,
        private translate: TranslateService,
    ) { }

    @ViewChild('drawer', { static: false })
    public drawer?: MatDrawer;
    // public navLinks = [
    //     { location: '/welcome', icon: '', label: '', description: '' },
    //     { location: '/financing/status', icon: 'laptop', label: 'components.welcome.status' },
    //     { location: '/news', icon: 'mail', label: 'components.welcome.messages' },
    //     { location: '/financing', icon: 'description', label: 'components.welcome.selfDisclosure' },
    //     { location: '/myData', icon: 'person', label: 'components.welcome.myData' },
    //   ];
    public navLinks = [
        { location: '/welcome', label: '', description: '' },
        { location: '/financing', label: 'components.navigation.myFinancing' },
        { location: '/financing/status', label: 'components.navigation.status' },
    ];

    /**
     * Angular Hook zum initialisieren
     *
     */
    public ngOnInit(): void {
        this.hideHeader = this.router.url.endsWith(RoutingParams.PRIVACY_ROUTE);
        this.router.events.pipe(
            filter(ev => ev instanceof NavigationEnd),
            map(ev => (ev as NavigationEnd).url),
        ).subscribe(url => {
            this.hideHeader = url.endsWith(RoutingParams.PRIVACY_ROUTE);
        });
    }

    /**
     * Öffnet/Schließt das Sidenav
     *
     * @returns {Promise<void>} Void Promise
     */
    public async toggleDrawer(): Promise<void> {
        if (this.drawer) {
            await this.drawer.toggle();
        }
    }

    /**
     * wenn Logoutbutton gedrückt
     * 
     * @returns {Promise} Navigations Promise
     */
    public onLogout(): Promise<boolean> {
        this.store.dispatch(Dispose);
        GlobalSettings.token = null;
        return this.router.navigateByUrl('logout');
    }

    /**
     * Aktion wenn auf das Icon gedrückt wird, welches die fehlende Hubverbindung signalisiert
     */
    public onHubWarningClicked(): void {
        this.notification.alert(
            this.translate.instant('applicationTranslations.noHubHeader'),
            this.translate.instant('applicationTranslations.noHubNote'),
        );
    }
}
