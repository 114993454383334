<div class="card-container">
    <div>
        <mat-card-header>
            <mat-card-title *ngIf="(isProductPackage && !isProposeRequest) || !isProductPackage">
                {{ isProductPackage ? cardTitle : cardTitleLabel}}
            </mat-card-title>
            <mat-card-subtitle
                *ngIf="isCxadvisory && ((!isProposeRequest && isProductPackage) || (!isProductPackage) )">
                {{cardSubTitle}}
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <ucba-select-document [control]="form.controls['acceptedSampleCalculationFileId']" 
                [selectedId]="userAcceptedSampleCalculationFileId | async" [array]="dataSource"
                (selectedChanged)="selectedValueChanged.emit($event)" (buttonClicked)="openFileClicked.emit($event)">
            </ucba-select-document>
            <ucba-textarea [dynamicRows]="true" [rows]="3" [control]="form.controls['sampleCalculationResponse']"
                [labelText]="responseLabel">
            </ucba-textarea>
        </mat-card-content>
    </div>

    <button
        *ngIf="hasSelectedId(userAcceptedSampleCalculationFileId | async)"
        class="submit-button" [class.advisory-color]="!isCxadvisory" mat-button [color]="isCxadvisory ? 'primary' : ''"
        (click)="sendCreditOfferDecisionClicked.emit({sampleCalculationResponse: form.controls['sampleCalculationResponse'].value, accepted: !isProposeRequest})">
        {{ isProposeRequest ? sendProposeRequestBtnLabel : sendAcceptedBtnLabel }}
    </button>
</div>