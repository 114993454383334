import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Komponente, welche Textarea darstellt
 */
@Component({
    selector: 'cxad-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent implements OnInit, OnDestroy {

    @Input() public control!: AbstractControl;
    @Input() public labelText!: string;
    @Input() public comment: string | undefined;
    @Input() public dynamicRows = false;
    @Input() public rows = 1;
    @Input() public isReadOnly = false;
    public isRequired: boolean | undefined;

    private destroyed$ = new Subject<void>();

    /**
     * gibt den aktuellen AbstractControl als FormControl zurück
     *
     * @returns {FormControl} control
     */
    public get formControl(): FormControl {
        return this.control as FormControl;
    }

    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {
        // @see https://stackoverflow.com/a/43904237
        const validators = !!this.control && !!this.control.validator ? this.control.validator(new FormControl()) : {};
        this.isRequired = !!validators && ('required' in validators);

        //  beim dynamicRows=true, updateOn sollte 'change' sein!
        if (this.dynamicRows) {
            const minRows = this.rows;
            this.control.valueChanges
                .pipe(takeUntil(this.destroyed$))
                .subscribe(value => {
                    const addedRows = value ? (value.match(/\n/g) || []).length + 1 : 0;
                    this.rows = Math.max(addedRows, minRows);
                });
        }

    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.destroyed$.next();
    }

}

