<form *ngIf="!!phoneForm && !!control" [formGroup]="phoneForm" class="usba-phone-wrapper">
  <mat-label>{{ labelText }}</mat-label>
  <div fxLayout="row">
    <div fxFlex="30">
        <mat-form-field class="areacode" appearance="outline">
            <mat-select formControlName="areaCode">
                <mat-option *ngFor="let entity of areaCodeList" [value]="entity.value">
                    {{entity.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxFlex="70">
        <mat-form-field [hideRequiredMarker]="!isRequired" appearance="outline" class="phone-number">
            <input matInput type="phone" formControlName="number" [required]="!!isRequired" [errorStateMatcher]="phoneErrorStateMatcher">
            <mat-hint *ngIf="!!comment">{{ comment }}</mat-hint>
            <mat-error cxad-form-field-error [control]="control"></mat-error>
        </mat-form-field>
    </div>
  </div>
</form>