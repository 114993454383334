import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnumTranslateLoader } from '@ntag-ef/finprocess-enums';
import { translations } from '@ntag-ef/finprocess-enums/core';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Custom Loader um die Enum Translation und die App Enums zu laden
 */
@Injectable({
    providedIn: 'root',
})
export class CustomEnumTranslationLoaderService extends EnumTranslateLoader {

    /**
     * Cached translations
     */
    private static translations: { [index: string]: Record<string, unknown> } = {};

    /**
     * Unterstützte Sprachen
     */
    public languages = ['de'];

    /**
     * Standard Konstruktor mit aufruf des Eltern Konstruktors
     * 
     * @param {HttpClient} http HttpClient injector
     */
    public constructor(private http: HttpClient) {
        super();
    }

    /**
     * Cachen aller Sprachen
     * 
     * @returns {Promise<void>} abschluss Promise
     */
    public async cache(): Promise<void> {
        for (const language of this.languages) {
            try {
                CustomEnumTranslationLoaderService.translations[language] = (await this.http.get(`./assets/i18n/enums/${language}.json`).toPromise()) as Record<string, unknown>;
            }
            catch (error) {
                // eslint-disable-next-line no-console
                console.error(`Failed to fetch language ${language} with error ${error}`);
                CustomEnumTranslationLoaderService.translations[language] = {};
            }
        }
    }

    /**
     * läd Lokalisierung
     * 
     * @param {string} lang Zu ladende Lokalisierung
     * @returns {Observable} Observable des jeweiligen Sprachpakets
     */
    // eslint-disable-next-line class-methods-use-this
    public getTranslation(lang: string): Observable<Record<string, unknown>> {
        if (lang in CustomEnumTranslationLoaderService.translations) {
            return combineLatest([
                of((translations as Record<string, unknown>)[lang]),
                of(CustomEnumTranslationLoaderService.translations[lang]),
            ]).pipe(map(([vpcEnums, appEnums]) => Object.assign({}, vpcEnums, appEnums)));
        }
        else if (lang.includes('-') && (lang.substr(0, 2) in CustomEnumTranslationLoaderService.translations)) {
            return combineLatest([
                of((translations as Record<string, unknown>)[lang.substr(0, 2)]),
                of(CustomEnumTranslationLoaderService.translations[lang.substr(0, 2)]),
            ]).pipe(map(([vpcEnums, appEnums]) => Object.assign({}, vpcEnums, appEnums)));
        }
        else {
            return of((translations as Record<string, unknown>)[lang]) as Observable<Record<string, unknown>>;
        }
    }
}
