import { InterestMethod } from '@ntag-ef/finprocess-enums/finadvisory';

import { IModifiedBackend } from '../interfaces';

/**
 * Handles bank debit rates.
 */
export interface IDebitRateModel extends IModifiedBackend {

    /** The interest method. */
    interestMethod: InterestMethod;

    /** The rating 4-. */
    rating4: number;

    /** The worst debit rate rating 3. */
    worstDebitRate3: number;

    /** The worst debit rate rating 5. */
    worstDebitRate5: number;
}
