import { Gender } from '@ntag-ef/finprocess-enums/finadvisory';

import { FinancingMapStatus } from '../enums';

import { IBackendBaseModel } from './backend-base.model';

/**
 * Abgespeckte Kunden Daten für Kundenliste
 */
export interface ICustomerLightModel extends IBackendBaseModel {

    /** Id der zugehörigen Filiale */
    branchId: string | null;

    /** Id des Filialmitarbeiters */
    consultantId: string;

    /** Name des Filialmitarbeiters */
    consultant: string;

    /** Geschlecht */
    gender?: Gender | null;

    /** Titel */
    title?: string | null;

    /** Vorname */
    firstName: string | null;

    /** Nachname */
    lastName: string | null;

    /** Mobiltelefonnummer */
    mobileNumber: string | null;

    /** E-Mail */
    email?: string | null;

    /** Postleitzahl */
    zip?: string | null;

    /** Stadt */
    city?: string | null;

    /** Straße */
    street?: string | null;

    /** Hausnummer */
    streetNumber?: string | null;

    /** Status der zuletzt bearbeiteten Finanzierung */
    status?: FinancingMapStatus | null;

    /** Alternativer Translation Key für Status */
    alternativeStatusText?: string | null;
}

export interface ICustomerLightResultModel {
    data: ICustomerLightModel[];
    totalResultCount: number,
    consultants: Record<string, string>,
}
