/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable class-methods-use-this */

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DocumentType } from '@ntag-ef/finprocess-enums/finadvisory';
import { IHouseholdInput } from '@ntag-ef/finprocess-validation';
import { sort } from 'fast-sort';

import { ICalculationHouseHold, IHouseHoldDocumentFile, IHouseHoldDocumentMap } from '../../interfaces';
import { IHouseholdModel } from '../../models';
import { CalculationFactoryService, HelperService, ModelFactoryService } from '../../services';
import { Dispose, UnLoadCustomer, UnLoadFinancing } from '../actions';
import { DebtorState, IDebtorStateModel } from '../debtor/debtor.state';
import { DocumentState, IDocumentStateModel } from '../document/document.state';
import { FileState, IFileStateModel } from '../file/file.state';
import { ILiabilityStateModel, LiabilityState } from '../liability/liability.state';
import { INewLiabilityStateModel, NewLiabilityState } from '../new-liability/new-liability.state';

import { SdkAddHousehold, SdkDeleteHousehold, SdkPatchHousehold, SdkUpdateHousehold } from './household.actions';

export interface IHouseholdStateModel {
    data: IHouseholdModel[];
}

/**
 * Klasse des Household States
 */
@State<IHouseholdStateModel>({
    name: HouseholdState.stateName,
    defaults: HouseholdState.defaultData,
})
@Injectable()
export class HouseholdState {
    public static readonly stateName = 'householdState';
    private static readonly defaultData: IHouseholdStateModel = {
        data: [],
    };

    /**
     * Aktualisiert den aktuellen Haushalt
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchHousehold} action SdkPatchHousehold Action
     */
    @Action(SdkPatchHousehold)
    public patchHousehold(
        { patchState }: StateContext<IHouseholdStateModel>,
        { payload }: SdkPatchHousehold,
    ): void {
        patchState({
            data: payload,
        });
    }

    /**
     * Aktualisiert ein spezifischen Haushalt
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchHousehold} action SdkUpdateHousehold Action
     */
    @Action(SdkUpdateHousehold)
    public updateHousehold(
        { patchState, getState }: StateContext<IHouseholdStateModel>,
        { payload }: SdkUpdateHousehold,
    ): void {
        const current = getState().data;

        if (current.some(({ id }) => id === payload.id)) {
            patchState({
                data: current.filter(({ id }) => id !== payload.id).concat(payload),
            });
        }
    }

    /**
     * fügt ein neuen Haushalt hinzu
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkAddHousehold} action SdkAddHousehold Action
     */
    @Action(SdkAddHousehold)
    public addHousehold(
        { patchState, getState }: StateContext<IHouseholdStateModel>,
        { payload }: SdkAddHousehold,
    ): void {
        const current = getState().data;

        if (!current.some(({ id }) => id === payload.id)) {
            patchState({
                data: current.concat(payload),
            });
        }
    }

    /**
     * entfernt ein spezifischen Haushalt
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkDeleteHousehold} action SdkDeleteHousehold Action
     */
    @Action(SdkDeleteHousehold)
    public deleteHousehold(
        { patchState, getState }: StateContext<IHouseholdStateModel>,
        { id: householdId }: SdkDeleteHousehold,
    ): void {
        const current = getState().data;

        if (current.some(({ id }) => id === householdId)) {
            patchState({
                data: current.filter(({ id }) => id !== householdId),
            });
        }
    }

    /**
     * zurücksetzen des States
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action([Dispose, UnLoadFinancing, UnLoadCustomer])
    public dispose({ setState }: StateContext<IHouseholdStateModel>): void {
        setState({ ...HouseholdState.defaultData });
    }

    /**
     * gibt die Haushalte zurück, welche der aktuellen Finanzierung zugeordnet sind
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @returns {IHouseholdModel} die aktuell selektierten Haushalte
     */
    @Selector()
    public static current(state: IHouseholdStateModel): IHouseholdModel[] {
        // umwandlung in date, da es sonst im ms bereich zu fehlern kommt (Problem beim Kopieren)
        // 2022-03-23T10:33:36.94403Z > 2022-03-23T10:33:36.9440395Z was aber falsch ist
        return sort(state.data).asc(household => HelperService.toMicroSeconds(household.created)); 
    }

    /**
     * gibt die Ids aller Haushalte zurück, welche der aktuellen Finanzierung zugeordnet sind
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @returns {string[]} Ids aller selektierten Haushalte
     */
    @Selector()
    public static currentIds(state: IHouseholdStateModel): string[] {
        return HouseholdState.current(state).map(({ id }) => id);
    }

    /**
     * gibt den Haushalt mit übergebener Id zurück
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @returns {(householdId: string) => IHouseholdModel} funktion zum filtern
     */
    @Selector()
    public static currentById(state: IHouseholdStateModel): (householdId: string) => IHouseholdModel | undefined {
        /**
         * callback funktion zum herraussuchen eines Haushaltes
         *
         * @param {string} householdId id des Haushaltes
         * @returns {IHouseholdModel} gesuchtes Haushalt
         */
        return (householdId: string): IHouseholdModel | undefined => state.data.find(({ id }) => id === householdId);
    }

    /**
     * gibt die aktuellen Haushalte im Format für den Precheck zurück
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @returns {IHouseholdInput} Haushalte
     */
    @Selector()
    public static asInputModel(state: IHouseholdStateModel): IHouseholdInput[] {
        const households = HouseholdState.current(state);

        return households.map(household => ({
            broadcastCosts: HelperService.getValue(household.broadcastCosts, ModelFactoryService.globalCurrencyDefault),
            operationalCosts: HelperService.getValue(household.operationalCosts, ModelFactoryService.globalCurrencyDefault),
            electricityCosts: HelperService.getValue(household.electricityCosts, ModelFactoryService.globalCurrencyDefault),
            phoneCosts: HelperService.getValue(household.phoneCosts, ModelFactoryService.globalCurrencyDefault),
            insuranceCosts: HelperService.getValue(household.insuranceCosts, ModelFactoryService.globalCurrencyDefault),
            livingCosts: HelperService.getValue(household.livingCosts, ModelFactoryService.globalCurrencyDefault),
            carCosts: HelperService.getValue(household.carCosts, ModelFactoryService.globalCurrencyDefault),
            otherCosts: HelperService.getValue(household.otherCosts, ModelFactoryService.globalCurrencyDefault),
        }));

    }

    //#region Berechnungen

    /**
     * gibt alle Ergebnisse der aktuellen Haushalte zurück
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @param {ILiabilityStateModel} liabilityState aktueller liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller new liability State
     * @returns {(calculationVersion?: number) => ICalculationHouseHold[]} callback zum berechnen
     */
    @Selector([DebtorState, LiabilityState, NewLiabilityState])
    public static calculations(
        state: IHouseholdStateModel,
        debtorState: IDebtorStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
    ): (calculationVersion?: number) => ICalculationHouseHold[] {

        /**
         * callback funktion zum bereitstellen aller Berechnungen
         *
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {ICalculationHouseHold[]} Berechnungsobjekte mit Ergebnissen
         */
        return (calculationVersion?: number) => state.data.map(({ id: householdId }) => ({
            householdId,
            commonIncomeSum: DebtorState.commonIncomeSumByHouseHoldId(debtorState)(householdId, calculationVersion),
            householdCostsSum: HouseholdState.householdCostsSumById(state)(householdId, calculationVersion),
            budgetAccountSubtotal: HouseholdState.budgetAccountSubtotalById(state, debtorState)(householdId, calculationVersion),
            safetyMargin: HouseholdState.safetyMarginById(state, debtorState)(householdId, calculationVersion),
            disposableIncome: HouseholdState.disposableIncomeById(state, debtorState)(householdId, calculationVersion),
            liabilitiesSum: LiabilityState.liabilitiesSumByHouseHold(liabilityState)(householdId, calculationVersion),
            newLiabilitiesSecuredSum: NewLiabilityState.newLiabilitiesSecuredSumByHouseHold(newLiabilityState)(householdId, calculationVersion),
            reasonableLoanRate: HouseholdState.reasonableLoanRateById(state, debtorState, liabilityState, newLiabilityState)(householdId, calculationVersion),
        } as ICalculationHouseHold))
    }

    /**
     * calculate the HouseholdCostsSum of household
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @returns {(householdId: string, calculationVersion?: number) => number} callback für berechnung
     */
    @Selector()
    public static householdCostsSumById(state: IHouseholdStateModel):
        (householdId: string, calculationVersion?: number) => number {

        /**
         * Callback funktion zum berechnen der montl. Haushaltsausgaben
         *
         * @param {string} householdId Id des Haushaltes
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {number} summe der ausgaben
         */
        return (householdId: string, calculationVersion?: number): number => {
            const hh = HouseholdState.currentById(state)(householdId);

            const householdCalculationService = CalculationFactoryService.householdCalculationService(calculationVersion);
            return !!hh ? householdCalculationService.sumCostsPerMonth({
                operationalCosts: HelperService.getValue(hh.operationalCosts, 0),
                electricityCosts: HelperService.getValue(hh.electricityCosts, 0),
                phoneCosts: HelperService.getValue(hh.phoneCosts, 0),
                broadcastCosts: HelperService.getValue(hh.broadcastCosts, 0),
                insuranceCosts: HelperService.getValue(hh.insuranceCosts, 0),
                livingCosts: HelperService.getValue(hh.livingCosts, 0),
                carCosts: HelperService.getValue(hh.carCosts, 0),
            }) : 0;
        };
    }

    /**
     * berechnet das verfügbare Einkommen
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IDebtorStateModel} debtorState aktueller debtor State
     * @returns {(householdId: string, calculationVersion?: number) => number} callback für berechnung
     */
    @Selector([DebtorState])
    public static budgetAccountSubtotalById(
        state: IHouseholdStateModel,
        debtorState: IDebtorStateModel,
    ): (householdId: string, calculationVersion?: number) => number {
        /**
         * Callback funktion zum berechnen des verfügbaren Einkommes
         *
         * @param {string} householdId Id des Haushaltes
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {number} summe des Einkommens
         */
        return (householdId: string, calculationVersion?: number): number => {
            const currentCommonIncomeSum = DebtorState.commonIncomeSumByHouseHoldId(debtorState)(householdId, calculationVersion);
            const currentHouseholdCostsSum = HouseholdState.householdCostsSumById(state)(householdId, calculationVersion);

            const householdCalculationService = CalculationFactoryService.householdCalculationService(calculationVersion);
            return householdCalculationService.remainingPerMonth({
                netIncomeTotal: currentCommonIncomeSum,
                sumCostsPerMonth: currentHouseholdCostsSum,
            });
        };
    }

    /**
     * berechnet die Sicherheitsreseven
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IDebtorStateModel} debtorState aktueller debtor State
     * @returns {(householdId: string, calculationVersion?: number) => number} callback für berechnung
     */
    @Selector([DebtorState])
    public static safetyMarginById(
        state: IHouseholdStateModel,
        debtorState: IDebtorStateModel,
    ): (householdId: string, calculationVersion?: number) => number {
        /**
         * Callback funktion zum berechnen der sicherheitsreseven
         *
         * @param {string} householdId Id des Haushaltes
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {number} summe der reserven
         */
        return (householdId: string, calculationVersion?: number): number => {
            const budgetAccountSubtotalById = HouseholdState.budgetAccountSubtotalById(state, debtorState)(householdId, calculationVersion);

            const householdCalculationService = CalculationFactoryService.householdCalculationService(calculationVersion);
            return householdCalculationService.reserve({
                remainingPerMonth: budgetAccountSubtotalById,
            });
        };
    }

    /**
     * berechnung des frei verfügbaren einkommens
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IDebtorStateModel} debtorState aktueller debtor State
     * @returns {(householdId: string, calculationVersion?: number) => number} callback für berechnung
     */
    @Selector([DebtorState])
    public static disposableIncomeById(
        state: IHouseholdStateModel,
        debtorState: IDebtorStateModel,
    ): (householdId: string, calculationVersion?: number) => number {
        /**
         * Callback funktion zum berechnen des frei verfügbaren einkommens
         *
         * @param {string} householdId Id des Haushaltes
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {number} summe des einkommens
         */
        return (householdId: string, calculationVersion?: number): number => {
            const budgetAccountSubtotal = HouseholdState.budgetAccountSubtotalById(state, debtorState)(householdId, calculationVersion);

            const householdCalculationService = CalculationFactoryService.householdCalculationService(calculationVersion);
            return householdCalculationService.freeAccessibleIncome({
                remainingPerMonth: budgetAccountSubtotal,
            });
        };
    }

    /**
     * berechnet die zumutbare Kreditrate
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IDebtorStateModel} debtorState aktueller debtor State
     * @param {ILiabilityStateModel} liabilityState aktueller liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller new liability State
     * @returns {(householdId: string, calculationVersion?: number) => number} callback für berechnung
     */
    @Selector([DebtorState, LiabilityState, NewLiabilityState])
    public static reasonableLoanRateById(
        state: IHouseholdStateModel,
        debtorState: IDebtorStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
    ): (householdId: string, calculationVersion?: number) => number {
        /**
         * Callback funktion zum berechnen der zumutbaren Kreditrate
         *
         * @param {string} householdId Id des Haushaltes
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {number} summe der rate
         */
        return (householdId: string, calculationVersion?: number): number => {
            const household = HouseholdState.currentById(state)(householdId);
            const disposableIncome = HouseholdState.disposableIncomeById(state, debtorState)(householdId, calculationVersion);
            const liabilitiesSum = LiabilityState.liabilitiesSumByHouseHold(liabilityState)(householdId, calculationVersion);
            const newMonthlyRates = NewLiabilityState.newLiabilitiesSecuredSumByHouseHold(newLiabilityState)(householdId, calculationVersion);
            return Math.max(0, disposableIncome - liabilitiesSum - newMonthlyRates - HelperService.getValue(household?.otherCosts, 0));
        };
    }

    /**
     * berechnet die zumutbaren Rate pro Haushalt
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IDebtorStateModel} debtorState aktueller debtor State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @returns {(calculationVersion?: number) => number[]} callback für berechnung
     */
    @Selector([DebtorState, LiabilityState, NewLiabilityState])
    public static reasonableLoanRateArray(
        state: IHouseholdStateModel,
        debtorState: IDebtorStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
    ): (calculationVersion?: number) => number[] {

        /**
         * Callback funktion zum berechnen der zumutbaren Rate pro Haushalt
         *
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {number[]} zumutbaren Rate pro Haushalt
         */
        return (calculationVersion?: number): number[] => {
            const households = HouseholdState.current(state);

            return households.map(({ id, otherCosts }) => {
                const disposableIncome = HouseholdState.disposableIncomeById(state, debtorState)(id, calculationVersion);
                const newMonthlyRates = NewLiabilityState.newLiabilitiesSecuredSumByHouseHold(newLiabilityState)(id, calculationVersion);
                const liabilitiesSum = LiabilityState.liabilitiesSumByHouseHold(liabilityState)(id, calculationVersion);
                return Math.max(0, disposableIncome - newMonthlyRates - (otherCosts ?? 0) - liabilitiesSum);
            });
        };
    }


    /**
     * berechnet die gemeinsamen Einkünfte der Kreditnehmer pro Haushalt
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IDebtorStateModel} debtorState aktueller debtor State
     * @returns {(calculationVersion?: number) => number[]} callback für berechnung
     */
    @Selector([DebtorState])
    public static commonIncomeSumArray(
        state: IHouseholdStateModel,
        debtorState: IDebtorStateModel,
    ): (calculationVersion?: number) => number[] {
        /**
         * Callback funktion zum berechnen der gemeinsamen Einkünfte pro Haushalt
         *
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {number[]} gemeinsamen Einkünfte pro Haushalt
         */
        return (calculationVersion?: number): number[] => {
            const householdIds = HouseholdState.currentIds(state);
            return householdIds.map(id => DebtorState.commonIncomeSumByHouseHoldId(debtorState)(id, calculationVersion));
        };
    }

    /**
     * berechnet die Summe der gemeinsamen Einkünfte der Kreditnehmer pro Haushalt
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IDebtorStateModel} debtorState aktueller debtor State
     * @returns {(calculationVersion?: number) => number} callback für berechnung
     */
    @Selector([DebtorState])
    public static commonIncomeSum(
        state: IHouseholdStateModel,
        debtorState: IDebtorStateModel,
    ): (calculationVersion?: number) => number {

        /**
         * Callback funktion zum berechnen Summer der gemeinsamen Einkünfte pro Haushalt
         *
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {number} Summe der gemeinsamen Einkünfte pro Haushalt
         */
        return (calculationVersion?: number): number =>
            HouseholdState.commonIncomeSumArray(state, debtorState)(calculationVersion)
                .reduce((acc, curr) => acc + curr, 0);
    }

    //#endregion

    /**
     * gibt die Haushaltsdokumente als fertiges Map Array zurück
     * 
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IDebtorStateModel} debtorState aktueller debtor State
     * @param {IDocumentStateModel} documentState aktueller debtor State 
     * @param {IFileStateModel} fileState aktueller debtor State 
     * @returns {IHouseHoldDocumentMap} householddocuments groupiert nach haushalt
     */
    @Selector([DebtorState, DocumentState, FileState])
    public static housholdDocumentMaps(
        state: IHouseholdStateModel,
        debtorState: IDebtorStateModel,
        documentState: IDocumentStateModel,
        fileState: IFileStateModel,
    ): IHouseHoldDocumentMap[] {
        const documents = DocumentState.allHousholdDocuments(documentState, fileState);
        const files = FileState.current(fileState);

        const maps = documents.reduce<IHouseHoldDocumentMap[]>((result, document) => {
            const filesOfDokument = files.filter(({ documentId }) => documentId === document.id);
            const fileItems = filesOfDokument.reduce<IHouseHoldDocumentFile[]>((items, file) => (file.isSigned ? items : [...items, {
                type: document.type,
                name: `${file.name}${file.extension}`,
                fileId: file.id,
            }]), []);

            let existingMap: IHouseHoldDocumentMap | undefined;
            let householdKey: string | undefined;

            if (document.type === DocumentType.SelfDisclosure && !!document.debtorId) {
                const debtor = DebtorState.currentById(debtorState)(document.debtorId);

                if (!!debtor) {
                    existingMap = result.find(({ householdId }) => householdId === debtor.householdId);
                    householdKey = debtor.householdId;
                }
            }
            else {
                existingMap = result.find(({ householdId }) => householdId === document.householdId);
                householdKey = document.householdId;
            }

            if (!!existingMap) {
                existingMap.files = existingMap.files.concat(fileItems);
            }
            else if (!!householdKey) {
                result.push({
                    householdId: householdKey,
                    files: fileItems,
                });
            }

            return result;

        }, []);

        // sortierung der Files nach Typ und Name
        for (const documentMap of maps) {
            documentMap.files = sort(documentMap.files).by([
                { asc: it => it.type },
                { asc: it => it.name },
            ]);
        }

        if (maps.length > 1) {
            // sortierung der Haushalte wie in der Finanzierung
            const householdIds = HouseholdState.currentIds(state);
            const result: IHouseHoldDocumentMap[] = [];

            for (const hhId of householdIds) {
                const item = maps.find(({ householdId }) => householdId === hhId);

                if (!!item) {
                    result.push(item);
                }
                else {
                    // sollte es aus irgendeinem Grund keine Files zu einem Haushalt geben, wird
                    // eine leere Karte für diesen Haushalt angezeigt
                    result.push({
                        householdId: hhId,
                        files: [],
                    });
                }
            }

            return result;
        }
        else {
            return maps;
        }
    }
}
