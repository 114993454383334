import { Component } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Komponente, welches einen generischen footer bereitstellt
 */
@Component({
  selector: 'cxad-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {

    public year: string;
    /**
     * Standard Konstruktor
     *
     * @param {Router} router Router injector
     */
    public constructor(
        private router: Router,
    ) {
        this.year = new Date().getFullYear().toString();
    }

    /**
     * navigate to imprint
     */
    public goToImprint(): void {
        this.router.navigateByUrl('/imprint').catch(e => {throw e});
    }
}
