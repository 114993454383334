<waiter-component [isLoading]="!form" class="area-wrapper area-height">
    <h2 class="page-title">{{ 'components.financingTab.household.pageTitle' | translate }}</h2>
    <cxad-read-only [isVisible]="validation.isReadOnly"></cxad-read-only>
    <ng-container *ngIf="householdCollections">
        <mat-chip-listbox>
            <mat-chip-option *ngFor="let household of householdCollections.controls; let chipIdx = index"
                (removed)="deleteHousehold(chipIdx)" (click)="selectHousehold(chipIdx)"
                [selected]="selectedHouseholdIdx === chipIdx">
                {{ 'components.financingTab.household.labelHouseHold' | translate:{i:chipIdx + 1 } }}
                <mat-icon *ngIf="!validation.isReadOnly" matChipRemove>cancel</mat-icon>
            </mat-chip-option>
            <button *ngIf="householdCollections.controls.length < maxHouseholds" mat-stroked-button type="button"
                (click)="addHousehold()" [disabled]="validation.isReadOnly" color="primary">
                {{ 'components.financingTab.household.addNewHouseholdButton' | translate }}
            </button>
        </mat-chip-listbox>
        <form *ngIf="form" [formGroup]="form" class="scroll-wrapper" #overflowWrapper>
            <ng-container *ngIf="asFormGroup(householdCollections.controls[selectedHouseholdIdx]) as collection">
                <div *ngIf="asFormGroup(collection.get(housholdKey)) as household">
                    <div fxLayout="column wrap"
                        *ngIf="houseHoldCalculations[household.controls['id'].value] as calculation">

                        <!-- Debtor -->
                        <div fxLayout="column"
                            *ngFor="let debtor of asFormGroups(collection.get(debtorKey)); let debIdx=index; let lst = last;">
                            <h2>{{ 'components.financingTab.household.titleDebtor' | translate }}</h2>
                            <p class="comment">{{ debtorLabels[selectedHouseholdIdx][debIdx] }}</p>
                            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end"
                                fxLayoutGap="10px">
                                <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px"
                                    [control]="debtor.controls['netIncome']"
                                    [labelText]="'components.financingTab.debtor.netIncome' | translate"
                                    [comment]="'components.financingTab.debtor.netIncomeComment' | translate">
                                </cxad-input-currency>

                                <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px"
                                    [control]="debtor.controls['otherIncome']"
                                    [labelText]="'components.financingTab.debtor.otherIncome' | translate"
                                    [comment]="'components.financingTab.debtor.otherIncomeComment' | translate">
                                </cxad-input-currency>

                                <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                            </div>
                        </div>

                        <!-- Household -->
                        <div fxLayout="column">
                            <h2>{{ 'components.financingTab.household.titleHaushold' | translate }}</h2>
                            <p class="comment">{{ 'components.financingTab.household.commentHaushold' | translate }}
                            </p>

                            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end"
                                fxLayoutGap="10px">
                                <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px"
                                    [control]="household.controls['operationalCosts']"
                                    [labelText]="'components.financingTab.household.operationalCosts' | translate"
                                    [comment]="'components.financingTab.household.operationalCostsComment' | translate">
                                </cxad-input-currency>

                                <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px"
                                    [control]="household.controls['phoneCosts']"
                                    [labelText]="'components.financingTab.household.phoneCosts' | translate"
                                    [comment]="'components.financingTab.household.phoneCostsComment' | translate">
                                </cxad-input-currency>
                                <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px"
                                    [control]="household.controls['electricityCosts']"
                                    [labelText]="'components.financingTab.household.electricityCosts' | translate"
                                    [comment]="'components.financingTab.household.electricityCostsComment' | translate">
                                </cxad-input-currency>
                            </div>
                            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end"
                                fxLayoutGap="10px">
                                <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px" [commentHeight]="true"
                                    [control]="household.controls['broadcastCosts']"
                                    [labelText]="'components.financingTab.household.broadcastCosts' | translate">
                                </cxad-input-currency>
                                <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                                <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                            </div>
                        </div>

                        <!-- Belastungen -->
                        <div fxLayout="column">
                            <h2>{{ 'components.financingTab.household.titleHaushold2' | translate }}</h2>
                            <ng-container *ngIf="asFormGroups(collection.get(liabilityKey)) as liabilities">
                                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center"
                                    fxLayoutGap="10px" *ngFor="let liab of liabilities; let i = index">
                                    <cxad-input-currency fxFlex fxFlex.gt-sm="0.4 1 0px"
                                        [labelText]="'components.financingTab.household.liabilitiesSum' | translate"
                                        [control]="liab.controls['monthlyRate']">
                                    </cxad-input-currency>
                                    <div fxFlex>
                                        <button *ngIf="liabilities.length > 1" mat-icon-button type="button"
                                            [disabled]="validation.isReadOnly"
                                            [matTooltip]="'components.financingTab.household.deleteLiability' | translate"
                                            (click)="deleteLiability(i)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                        <button
                                            *ngIf="i === (liabilities.length-1) && liabilities.length < maxLiabilities"
                                            mat-icon-button type="button" [disabled]="validation.isReadOnly"
                                            [matTooltip]="'components.financingTab.household.addLiability' | translate"
                                            (click)="addLiability()">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="asFormGroups(collection.get(newliabilityKey)) as newLiabilities">
                                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center"
                                    fxLayoutGap="10px" *ngFor="let newLiab of newLiabilities; let i = index">
                                    <cxad-input-currency fxFlex fxFlex.gt-sm="0.4 1 0px"
                                        [labelText]="'components.financingTab.household.newMonthlyRates' | translate"
                                        [comment]="'components.financingTab.household.newMonthlyRatesComment' | translate"
                                        [control]="newLiab.controls['monthlyRate']">
                                    </cxad-input-currency>
                                    <div class="margin-top" fxFlex>
                                        <button *ngIf="newLiabilities.length > 1" mat-icon-button type="button"
                                            [disabled]="validation.isReadOnly"
                                            [matTooltip]="'components.financingTab.household.deleteNewLiability' | translate"
                                            (click)="deleteNewLiability(i)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                        <button
                                            *ngIf="i === (newLiabilities.length-1) && newLiabilities.length < maxNewLiabilities"
                                            mat-icon-button type="button" [disabled]="validation.isReadOnly"
                                            [matTooltip]="'components.financingTab.household.addNewLiability' | translate"
                                            (click)="addNewLiability()">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>

                            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end"
                                fxLayoutGap="10px">
                                <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px" [commentHeight]="true"
                                    [labelText]="'components.financingTab.household.otherCosts' | translate"
                                    [control]="household.controls['otherCosts']">
                                </cxad-input-currency>
                                <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                                <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                            </div>
                        </div>
                        <cxad-textarea
                            [labelText]="'components.financingTab.household.titleNotesHouseholdCard' | translate"
                            [control]="household.controls['notesHousehold']" [rows]='5'>
                        </cxad-textarea>
                        {{ 'components.financingTab.household.creditHint' | translate }}
                    </div>
                </div>
            </ng-container>
        </form>
    </ng-container>
    <div fxLayout="row" fxLayoutAlign="end center" class="next-btn">
        <button mat-raised-button (click)="onNext()" type="button" color="primary">
            {{ 'global.buttons.next' | translate }}
        </button>
    </div>
</waiter-component>