import { FinancingMapStatus } from '../enums';

import { IBackendBaseModel } from './backend-base.model';

export interface IStatusEntry extends IBackendBaseModel {
    /** The status. */
    status: FinancingMapStatus;

    /** The information. */
    info: string;

    /** The financing map identifier. */
    financingMapId: string;

    /** The user information. */
    userInfo: string;
}
