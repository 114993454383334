import { IActivityCluster } from '../../models';

/**
 * Action welche alle Aktivitäten updated
 */
export class SdkPatchActivity {
    public static readonly type = '[SDK Activity] PATCH';

    /**
     * Standard Konstruktor
     *
     * @param {IActivityCluster[]} payload alle zu patchenden Aktivitäten
     */
    public constructor(public payload: IActivityCluster[]) { }
}
