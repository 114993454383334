import { CurrencyPipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { IExitValidationOutput } from '@ntag-ef/finprocess-validation';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { Observable, catchError, forkJoin, iif, map, mergeMap, of, switchMap, take, throwError } from 'rxjs';

import { IFileModel, IFinancingMapLightModel, IFinancingMapModel, IHistoryModel, IProductModel, IProductPackageModel } from '../../models';
import { IBackendBaseModel } from '../../models/backend-base.model';
import { ICopyFinancingRequest, IEsisDecisionRequest, ISampleCalculationRequest } from '../../requests';
import { IPreCheckResponse, IStatusResponse } from '../../responses';
import { HelperService } from '../../services';
import { UserState } from '../../statemanagement/states';
import { BaseApiController } from '../base/base-api.controller';

/**
 * Controller welche alle Funkionalitäten der Financing Controller API bereithält
 */
@Injectable()
export class FinancingController extends BaseApiController<IFinancingMapModel> {

    protected override baseUrl = '/financing';

    /**
     * Standard Konstruktor
     *
     * @param {HttpClient} httpClient HttpClient injector
     * @param {Store} store Store injector
     * @param {NotificationService} notification NotificationService injector
     * @param {TranslateService} translate TranslateService injector
     * @param {CurrencyPipe} currencyPipe CurrencyPipe injector
     * @param {WaiterService} waiter WaiterService injector
     */
    public constructor(
        protected override httpClient: HttpClient,
        private store: Store,
        private notification: NotificationService,
        private translate: TranslateService,
        private currencyPipe: CurrencyPipe,
        private waiter: WaiterService,
    ) {
        super(httpClient);
    }

    //#region --- Get ---

    /**
     * Gibt die Finanzierungsmappe mit übergebener Id zurück
     * 
     * @param {string} id id der Finanzierung
     * @param {boolean} light anfordern als LightModel (default: false)
     * @returns {IFinancingMapModel | IFinancingMapLightModel} angeforderte Finanzierung
     */
    public override getEntity<T extends IBackendBaseModel>(id: string, light = false): Observable<T> {
        return this.get<T>(`${this.baseUrl}/${id}?light=${light}`);
    }

    /**
     * Gibt alle Finanzierungen als LightModel für den Kunden mit der übergebenen Id zurück.
     * 
     * @param {string} id id des Kunden
     * @returns {IFinancingMapLightModel[]} alle Finanzierungen des Kunden
     */
    public getFinancingMapsByCustomer(id: string): Observable<IFinancingMapLightModel[]> {
        return this.get<IFinancingMapLightModel[]>(`${this.baseUrl}/GetFinancingMapsByCustomer/${id}`);
    }

    /**
     * Gibt den Status der Finanzierungsmappe mit übergebener Id zurück
     * 
     * @param {string} id id der Finanzierung
     * @returns {IStatusResponse} Status der Finanzierung
     */
    public getFinancingMapStatus(id: string): Observable<IStatusResponse> {
        return this.get<IStatusResponse>(`${this.baseUrl}/GetFinancingStatus/${id}`);
    }

    /**
     * Gibt die Historie der übergebenen Finanzierung zurück
     * 
     * @param {string} id id der Finanzierung
     * @returns {IHistoryModel[]} Historieeinträge der Finanzierung
     */
    public getFinancingHistory(id: string): Observable<IHistoryModel[]> {
        return this.get<IHistoryModel[]>(`${this.baseUrl}/GetFinancingHistory/${id}`);
    }

    /**
     * Gibt alle Produktpakete zurück die zur Finanzierung gehören
     * 
     * @param {string} id id der Finanzierung
     * @returns {IProductPackageModel[]} Produktpakete der Finanzierung
     */
    public getProductPackages(id: string): Observable<IProductPackageModel[]> {
        return this.get<IProductPackageModel[]>(`${this.baseUrl}/GetProductPackages/${id}`);
    }

    /**
     * Gibt alle Produktpakete zurück die zur Finanzierung gehören
     * 
     * @param {string} id id der Finanzierung
     * @returns {IProductPackageModel[]} Produktpakete der Finanzierung
     */
    public getProductAndPackages(id: string): Observable<{ productPackages: IProductPackageModel[], products: IProductModel[] }> {
        return this.getProductPackages(id)
            .pipe(
                switchMap<IProductPackageModel[], Observable<{ productPackages: IProductPackageModel[], products: IProductModel[] }>>(productPackages =>
                    iif(
                        () => Array.isArray(productPackages) && productPackages.length > 0,
                        forkJoin(productPackages.map(productPackage => this.getProducts(productPackage.id)))
                            .pipe(
                                map(products => products.reduce((acc, val) => acc.concat(val), [])),
                                map(products => ({ productPackages, products })),
                            ),
                        of(({ productPackages: [], products: [] })),
                    )),
            )
    }

    /**
     * Gibt alle Produkte zurück die zum Produktpaket gehören
     * 
     * @param {string} id id der Finanzierung
     * @returns {IProductModel[]} Produkte der Finanzierung
     */
    public getProducts(id: string): Observable<IProductModel[]> {
        return this.get<IProductModel[]>(`${this.baseUrl}/GetProducts/${id}`);
    }

    //#endregion

    //#region --- CRUD ---


    /**
     * Stößt ein Kopiervorgang mit allen Daten im Backend an
     *
     * @param {string} id id der Finanzierung
     * @returns {Observable} request observable
     */
    public copyFinancingMapFull(id: string): Observable<IFinancingMapLightModel> {
        return this.copyFinancingMap({
            financingMapId: id,
            copyPerson: true,
            copyHousehold: true,
            copyMap: true,
            copyObject: true,
            copyGuarantees: true,
            copyDocuments: true,
        });
    }

    /**
     * Stößt ein Kopiervorgang mit ausgewählten Daten im Backend an
     *
     * @param {ICopyFinancingRequest} requst auswahl objekt für kopiervorgang
     * @returns {Observable} request observable
     */
    public copyFinancingMap(requst: ICopyFinancingRequest): Observable<IFinancingMapLightModel> {
        return this.post<IFinancingMapLightModel>(`${this.baseUrl}/CopyFinancingMap`, requst);
    }

    //#endregion

    //#region --- FinProcess---

    /**
     * Stößt das Backend an eine Finanzierung an FinProcess zu übermitteln
     *
     * @param {string} id id der Finanzierung
     * @returns {Observable} request observable
     */
    public submitFinancingMap(id: string): Observable<IStatusResponse> {
        return this.post<IStatusResponse>(`${this.baseUrl}/SubmitFinancingMap/${id}`)
            .pipe(catchError(error => this.handleVPCErrors(error)));
    }

    /**
     * sendet die entscheidung über das Rechenbeispiel ans Backend
     *
     * @param {string} id id der Finanzierung
     * @param {string} message kommentar zur entscheidung
     * @param {string} acceptedFileId gewälte id des RB Files oder null wenn neu beantragt
     * @returns {Observable} request observable
     */
    public sendCreditOfferDecision(id: string, message?: string, acceptedFileId?: string): Observable<IStatusResponse> {

        const request: ISampleCalculationRequest = {
            id,
            accepted: !!acceptedFileId,
            acceptedFileId,
            message,
            consultingService: false,
        };

        return this.post<IStatusResponse>(`${this.baseUrl}/SendCreditOfferDecision`, request)
            .pipe(catchError(error => this.handleVPCErrors(error)));
    }

    /**
     * sendet die entscheidung über das Esis ans Backend
     *
     * @param {string} id id der Finanzierung
     * @param {boolean} accepted entscheidung über das Esis
     * @param {string} message kommentar zur entscheidung
     * @returns {Observable} request observable
     */
    public sendEsisDecision(id: string, accepted: boolean, message?: string): Observable<IStatusResponse> {
        const request: IEsisDecisionRequest = { id, accepted, message };
        return this.post<IStatusResponse>(`${this.baseUrl}/SendEsisDecision`, request)
            .pipe(catchError(error => this.handleVPCErrors(error)));
    }

    /**
     * Führt die Precheck validierung im Backend aus
     * 
     * @param {string} id id der Finanzierung
     */
    public validateFinancingMap(id: string): void {

        this.waiter.show();

        this.get<IPreCheckResponse>(`${this.baseUrl}/ValidateFinancingMap/${id}`)
            .pipe(
                take(1),
            ).subscribe(
                {
                    next: result => {
                        this.waiter.hide();

                        const asExitOutput: IExitValidationOutput = {
                            valid: result.exit,
                            validationMessages: result.exclusionMessages,
                            maxComfortCreditTotalAmount: result.maxComfortCreditTotalAmount,
                            maxComfortCreditFictionalTotalAmount: result.maxComfortCreditFictionalTotalAmount,
                        };

                        this.notification.alert(
                            this.translate.instant('modules.customer.components.financingTab.precheck.title'),
                            this.generatePreCheckMsg(asExitOutput),
                        );
                    },
                    error: e => {
                        this.waiter.hide();
                        throw e;
                    },
                });
    }

    /**
     * Triggert das Backend noch nicht übermittelte Files an FinProcess zu übertragen
     *
     * @param {string} id id der Finanzierung
     * @returns {Observable} aktualisierte Files
     */
    public sendDocumentsToFinProcess(id: string): Observable<IFileModel[]> {
        return this.post(`${this.baseUrl}/SendDocumentsToFinProcess/${id}`);
    }

    /**
     * Triggert das Backend die Haushaltsdokumente an FinProcess zu übertragen
     * 
     * @param {string} id id der Finanzierung
     * @returns {Observable} request observable
     */
    public sendHouseholdDocuments(id: string): Observable<IStatusResponse> {
        return this.post<IStatusResponse>(`${this.baseUrl}/SendHouseholdDocuments/${id}`)
            .pipe(catchError(error => this.handleVPCErrors(error)));
    }

    //#endregion

    /**
     * handelt alle Fehler welche von VPC kommen
     *
     * @param {HttpErrorResponse} error geworfener Fehler
     * @returns {Observable} Fehler Observable
     */
    private handleVPCErrors(error: HttpErrorResponse): Observable<IStatusResponse> {
        if (error.status === 412) { // PreconditionFailedError

            const e: Record<string, { errors: Array<{ errorMessage: string }> }> = error.error;

            const messages = Object.keys(e).reduce<Record<string, string[]>>((result, key) => {
                result[key] = e[key].errors.map(it => it.errorMessage);
                return result;
            }, {})

            if (Object.keys(messages).length > 0) {
                const message = this.generateExitMsg(messages);

                return this.notification.alert(
                    'Zurückstellung Ihrer Kreditanfrage', // TODO
                    message,
                ).pipe(
                    mergeMap(() => throwError(() => error)),
                );
            }
        }

        return throwError(() => error);
    }

    /**
     * Übersetzt und formatiert die Auto Exit Nachrichten
     *
     * @param {Record<string, string[]>} messages Die Validierungsnachrichten
     * @returns {string} Formatierte Nachricht
     */
    private generateExitMsg(messages: Record<string, string[]> = {}): string {
        const name = this.store.selectSnapshot(UserState.displayName);

        let message = `Sehr geehrte/r ${name}<br>`; // TODO
        message += 'nachfolgend aufgeführte Gründe haben dazu geführt, dass Ihre Kreditanfrage zurückgestellt wird';

        message += '<ul>';
        for (const key in messages) {
            if (!!messages[key]) {
                for (const msg of messages[key]) {
                    const formattedMsg = HelperService.toHTMLBreakes(msg);
                    message += `<li>${formattedMsg}</li>`;
                }
            }
        }
        message += '</ul><br><br>';

        message += 'Sie haben die Möglichkeit die Angaben der Kreditanfrage zu überarbeiten und neuerlich einzureichen.'; // TODO

        return message;

    }

    /**
     * Übersetzt und formatiert die Precheck Nachrichten
     *
     * @param {IExitValidationOutput} output ergebnisobjekt des prechecks
     * @returns {string} Formatierte Nachricht
     */
    private generatePreCheckMsg(output: IExitValidationOutput): string {
        const name = this.store.selectSnapshot(UserState.displayName);

        if (output.valid) {
            return this.translate.instant('modules.customer.components.financingTab.precheck.textValid', { name });
        }
        else {
            let message: string = this.translate.instant('modules.customer.components.financingTab.precheck.text', { name });
            message += '<ul>';
            message += HelperService.formatPasscodeMessages(output.validationMessages);

            const onlyDSTI = Object.keys(output.validationMessages).every(key => key.toLocaleLowerCase() === 'dsti');

            if (onlyDSTI) {
                const maxTotalAmountMsg = this.translate.instant('modules.customer.components.financingTab.precheck.maxTotalAmount', {
                    value: this.currencyPipe.transform(output.maxComfortCreditTotalAmount ?? 0, 'EUR', 'symbol', '1.2-2'),
                });

                const maxFictionalRateMsg = this.translate.instant('modules.customer.components.financingTab.precheck.maxFictionalRate', {
                    value: this.currencyPipe.transform(output.maxComfortCreditFictionalTotalAmount ?? 0, 'EUR', 'symbol', '1.2-2'),
                });

                message += `<li>${maxTotalAmountMsg}</li>`;
                message += `<li>${maxFictionalRateMsg}</li>`;
            }

            message += '</ul><br><br>';

            message += this.translate.instant('modules.customer.components.financingTab.precheck.text2');

            return message;
        }
    }
}
