import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IHouseholdModel } from '../../models';
import { BaseApiController } from '../base/base-api.controller';

/**
 * Controller welche alle Funkionalitäten der Household Controller API bereithält
 */
@Injectable()
export class HouseholdController extends BaseApiController<IHouseholdModel> {

    protected override baseUrl = '/household';

    //#region --- Get ---

    /**
     * Gibt alle Haushalte für die Finanzierung mit der übergebenen Id zurück.
     * 
     * @param {string} id id der Finanzierung
     * @returns {IHouseholdModel[]} alle Haushalte der Finanzierung
     */
    public getHouseholdsByFinancing(id: string): Observable<IHouseholdModel[]> {
        return this.get<IHouseholdModel[]>(`${this.baseUrl}/GetHouseholdsByFinancing/${id}`);
    }

    //#endregion

    //#region --- CRUD ---

    //#endregion
}
