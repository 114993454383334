<div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between stretch" *ngIf="!!control">
    <div fxLayout="column" fxLayoutAlign="start start">
        <mat-label>{{ labelText }}</mat-label>
        <mat-hint *ngIf="!!comment">{{ comment }}</mat-hint>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between">

        <mat-checkbox fxLayout="row" fxLayoutAlign="space-between start" *ngIf="!!checkBoxControl"
            [formControl]="checkBoxFormControl">
            {{ checkBoxText }}
        </mat-checkbox>

        <mat-form-field *ngIf="!!control" [hideRequiredMarker]="!isRequired" appearance="outline">
            <input [options]="options" currencyMask matInput [formControl]="formControl" [required]="!!isRequired"
                [readonly]="!!readonly" [min]="0" />
            <mat-error cxad-form-field-error *ngIf="control.invalid" [control]="control"></mat-error>
        </mat-form-field>
    </div>
</div>
<hr class="hr-set" [class.valid]="control.valid" fxHide fxShow.gt-sm>