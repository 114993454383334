import { AbstractControl } from '@angular/forms';

import { HelperService } from '../services';

/**
 * validator class for not equal validation
 */
export class NotEqualValidation {

    /**
     * check two values of not equal
     * 
     * @param {string} displayName Anezige Name in der Error Message
     * @param {string} other string mit dem verglichen werden soll
     * @returns {AbstractControl} AbstractControl
     */
    public static notEqual(displayName: string, other?: string) {
        return (field: AbstractControl) => {
            const valueSelf = field.value?.toLowerCase();
            const valueOther = other?.toLowerCase();

            if ((!HelperService.isNullOrEmpty(valueSelf) || !HelperService.isNullOrEmpty(valueSelf)) && valueSelf === valueOther) {
                return { equalValue: { displayName } };
            }
            else {
                return null;
            }
        };
    }
}
