import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { IListTuple, ValidationService } from '@ucba/sdk';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

/**
 * Komponente, welche eine Radio Button darstellt
 */
@Component({
    selector: 'ucba-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
})
export class RadioComponent<T> implements OnInit, OnDestroy {

    @Input() public control!: AbstractControl;
    @Input() public labelText!: string;
    @Input() public comment: string | undefined;
    @Input() public enum: T | undefined;
    @Input() public array: IListTuple<string>[] | null | undefined;
    @Input() public translationKey!: string;
    public isRequired: boolean | undefined;
    private destroyed$ = new Subject<void>();

    /**
     * gibt den aktuellen AbstractControl als FormControl zurück
     * 
     * @returns {FormControl} control
     */
    public get formControl(): FormControl {
        return this.control as FormControl;
    }

    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {
        ValidationService.validatorsChangedObservable$.pipe(
            filter(it => it === this.control),
            takeUntil(this.destroyed$),
        ).subscribe(() => {
            this.updateRequired();
        });

        this.updateRequired();
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.destroyed$.next();
    }

    /**
     * update das isRequired feld anhand der im control rinterlegten validatoren
     */
    private updateRequired() {
        // @see https://stackoverflow.com/a/43904237
        const validators = !!this.control && !!this.control.validator ? this.control.validator(new FormControl()) : {};
        this.isRequired = !!validators && ('required' in validators);
    }

}
