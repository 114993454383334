import { Component, Input } from '@angular/core';

/**
 * Komponente, welche eine Output Currency Feld darstellt
 */
@Component({
  selector: 'cxad-output-currency-h',
  templateUrl: './output-currency-horizontal.component.html',
  styleUrls: ['./output-currency-horizontal.component.scss'],
})
export class OutputCurrencyHorizontalComponent {
    @Input() public labelText!: string;
    @Input() public comment: string | undefined;
    @Input() public value: number | undefined;

}
