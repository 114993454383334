export enum DocumentViewerMode {
    /** Datei aus lokalem Zustand mit fileId laden */
    LOCAL_FILE,

    /** Generierte pdf-Datei vom Backend laden mit financingmapId, householdId, documenttype */
    SIGNATURE_FILE,

    /** Laden von pdf-Finanzierungskarten aus dem Backend mit financingmapId */
    ARCHIVE_MAP_FILE,

    /** Laden der Offenlegungsdaten pdf aus dem Backend mit activityId */
    DISCLOSURE_FILE,

    /** Unternehmensdatei mit fileId aus dem Internet laden */
    COMPANY_FILE,

    /** Finanzierungsmappe mit fileId aus dem Internet laden */
    FINANCING_MAP_FILE,

    /** ein Handbuch, das vom Server geladen wird */
    MANUAL_FILE,

}
