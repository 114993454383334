import { CalendarEvent } from 'angular-calendar';

import { ISODate } from '../types';

import { IBackendBaseModel } from './backend-base.model';

export interface ITaskModel extends ITaskMeta {
    description: string;
    date: ISODate;
}

export interface ITaskMeta extends IBackendBaseModel {

    consultantId?: string;
    customerId?: string;
    finaningMapId?: string;
    reminder: boolean;
    completed: boolean;
    notes?: string;
}


export const toCalendarEvent = (task: ITaskModel): CalendarEvent<ITaskMeta> => ({
    id: task.id,
    start: new Date(task.date),
    title: task.description,
    draggable: true,
    meta: {
        consultantId: task.consultantId,
        customerId: task.customerId,
        finaningMapId: task.finaningMapId,
        reminder: task.reminder,
        completed: task.completed,
        notes: task.notes,
        created: task.created,
        modified: task.modified,
        id: task.id,
    },
});

export const toTaskModel = (event: CalendarEvent<ITaskMeta>): ITaskModel => ({
    id: event.id as string,
    created: event.meta?.created as ISODate,
    modified: event.meta?.modified,

    description: event.title,
    date: event.start.toISOString(),

    customerId: event.meta?.customerId as string,
    consultantId: event.meta?.consultantId as string,
    finaningMapId: event.meta?.finaningMapId as string,
    reminder: !!event.meta?.reminder,
    completed: !!event.meta?.completed,
    notes: event.meta?.notes as string,
});
