import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { EmployeeStatus, Gender, HousingType, LevelOfTraining, MaritalStatus } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { GlobalSettings, clearInvalidFormValues } from '@ucba/sdk';
import { FinancingMapStatus, LineType } from '@ucba/sdk/enums';
import { IFieldCardVisibilityDebtor, IListTuple } from '@ucba/sdk/interfaces';
import { ICountryModel, IDebtorFormModel, IDebtorModel, IHouseholdModel } from '@ucba/sdk/models';
import { DataService, HelperService, ModelFactoryService, ValidationService } from '@ucba/sdk/services';
import { DebtorState, FinancingMapState, HouseholdState, MasterDataState } from '@ucba/sdk/statemanagement/states';
import { DEBTOR_FORM_VALIDATION_MAP } from '@ucba/sdk/validations';
import { Observable, Subject, UnaryFunction, combineLatest, pipe } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';

import { AddDebtorComponent } from '../add-debtor/add-debtor.component';

type VisibilityMap = { [debitorId: string]: IFieldCardVisibilityDebtor };

/**
 * Komponente zum darstellen des Debtor Formulares
 */
@Component({
    selector: 'cxad-debtor',
    templateUrl: './debtor.component.html',
    styleUrls: ['./debtor.component.scss'],
})

export class DebtorComponent implements OnInit, OnDestroy, AfterViewInit {

    public households?: IListTuple<string>[];

    public gender = Gender;
    public housingType = HousingType;
    public maritalStatus = MaritalStatus;
    public employeeStatus = EmployeeStatus;
    public levelOfTraining = LevelOfTraining;
    public lineType = LineType;

    public form: FormGroup | undefined;

    public visibilityMap: VisibilityMap = {};
    public debtorLabels: string[] = [];

    public readonly maxDebtor = GlobalSettings.maxDebtor;

    /** index der selektierten Entität */
    public selectedEntityIdx = 0;
    public debtors: IDebtorModel[] = [];

    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();

    /* Sonstiges Labelvalue */
    public otherLabel: string;

    /** eine Map von allen Listen welche die Other logik beinhaltet */
    public listsWithOther: Record<string, { list: string[], listObservable$: Observable<IListTuple<string>[]> }>;

    private readonly otherPostfix = 'Other';

    @ViewChild('overflowWrapper') public overflowWrapper!: ElementRef;


    /**
     * Standard Konstruktor
     *
     * @param {UntypedFormBuilder} fb FormBulder injector
     * @param {Store} store Store injector
     * @param {DataService} dataService DataService injector
     * @param {NotificationService} notificationService NotificationService injector
     * @param {TranslateService} translate TranslateService injector
     * @param {MatDialog} dialog MatDialog injector
     * @param {ValidationService} validation ValidationService injector
     * @param { Router } router Router injector
     * @param { ActivatedRoute } route ActivatedRoute injector
     * @param { ElementRef } elementRef ElementRef injector
     */
    public constructor(
        private fb: UntypedFormBuilder,
        private store: Store,
        private dataService: DataService,
        private notificationService: NotificationService,
        private translate: TranslateService,
        private dialog: MatDialog,
        public validation: ValidationService,
        private router: Router,
        private route: ActivatedRoute,
        private elementRef: ElementRef<HTMLElement>,
    ) {
        // Sonstiges Dropdown Label Name
        this.otherLabel = this.translate.instant('global.other');

        // Wichtig, der Property Name muss dem Feldname im Model entsprechen!
        this.listsWithOther = {
            'birthcountry': {
                list: [],
                listObservable$: this.store.select(MasterDataState.birthCountries)
                    .pipe(
                        this.addOtherToList(),
                        tap(countries => { this.listsWithOther['birthcountry'].list = countries.map(({ label }) => label) }),
                    ),
            },
            'homeCountry': {
                list: [],
                listObservable$: this.store.select(MasterDataState.birthCountries)
                    .pipe(
                        // durch this.addOtherToList() ersetzen, falls 'Sonstiges' in Zukunft angefordert wird
                        map((countries: ICountryModel[]) => countries.map(({ name }) => ({
                            label: name,
                            value: name,
                        }))),
                        tap(countries => { this.listsWithOther['homeCountry'].list = countries.map(({ label }) => label) }),
                    ),
            },
            'citizenship': {
                list: [],
                listObservable$: this.store.select(MasterDataState.citizenshipCountries)
                    .pipe(
                        this.addOtherToList(),
                        tap(countries => { this.listsWithOther['citizenship'].list = countries.map(({ label }) => label) }),
                    ),
            },
            'additionalCitizenship': {
                list: [],
                listObservable$: this.store.select(MasterDataState.citizenshipCountries)
                    .pipe(
                        this.addOtherToList(),
                        tap(countries => { this.listsWithOther['additionalCitizenship'].list = countries.map(({ label }) => label) }),
                    ),
            },
            'taxResidence': {
                list: [],
                listObservable$: this.store.select(MasterDataState.taxResidenceCountries)
                    .pipe(
                        this.addOtherToList(),
                        tap(countries => { this.listsWithOther['taxResidence'].list = countries.map(({ label }) => label) }),
                    ),
            },
        };
    }

    /**
     * castet ein Abstract Control in ein Formgroup um es im Template besser verwenden zu können
     *
     * @param {AbstractControl} ac das zu castende AbstractControl
     * @returns { FormGroup } gecastede Formgroup
     */
    // eslint-disable-next-line class-methods-use-this
    public asFormGroup(ac: AbstractControl): FormGroup {
        return ac as FormGroup;
    }

    /**
     * Angular Hook zum initialisieren
     *
     */
    public ngOnInit(): void {

        // Selbstständige und Probezeit werden z.Z. nicht supportet
        this.employeeStatus = HelperService.removeValuesFromEnum(EmployeeStatus, [EmployeeStatus.SelfEmployed, EmployeeStatus.Probation]);

        combineLatest([
            this.store.select(DebtorState.current),
            ...Object.keys(this.listsWithOther).map(key => this.listsWithOther[key].listObservable$),
        ])
            .pipe(takeUntil(this.viewLeft$))
            .subscribe(([debtors]) => {
                if (Array.isArray(debtors) && debtors.length > 0) {

                    this.debtors = debtors;

                    this.visibilityMap = debtors.reduce<VisibilityMap>((acc, curr) => {
                        acc[curr.id] = DebtorState.visibleMap(curr);
                        return acc;
                    }, {} as VisibilityMap);

                    if (!!this.form) {
                        //prüft ob ausgewählter Debtor noch existiert, es kann sein dass aus der Filiale entfernt ist
                        const debtor = this.debtors[this.selectedEntityIdx];
                        if (!!debtor) {
                            this.updateFormular(this.debtors[this.selectedEntityIdx]);
                        } else {
                            this.onSelectEntity(0);
                        }
                    }
                    else {
                        this.initFormular(this.debtors[this.selectedEntityIdx]);
                    }

                    this.debtorLabels = debtors.map((deb, idx) => HelperService.calcDebitorLabel(deb, idx, 'Kreditnehmer'));
                }
            });

        this.store.select(HouseholdState.current)
            .pipe(takeUntil(this.viewLeft$))
            .subscribe(households => {
                this.updateHouseholdSelection(households);
            });
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
    }

    /**
     * Angular Hook nachdem die View geladen wurde
     */
    public ngAfterViewInit(): void {
        const p = this.elementRef.nativeElement.parentElement?.parentElement;

        if (!!p?.scrollTo) {
            p.scrollTo(0, 0);
        }
    }

    /**
     * wenn auf den Weiter Button gedrückt wird, wird zum nächsten Tab gewechselt
     * 
     * @returns {Promise} navigations Promise
     */
    public onNext(): Promise<boolean> {
        return this.router.navigate(['..', 'household'], { relativeTo: this.route });
    }

    /**
     * Fügt eine Entität hinzu
     */
    public addEntity(): void {
        const dialogRef = this.dialog.open(AddDebtorComponent, {
            disableClose: true,
            data: this.store.selectOnce(HouseholdState.current),
        });

        dialogRef.afterClosed()
            .pipe(take(1))
            .subscribe(async result => {
                if (!!result) {

                    if (result.household === 'createhousehold') {
                        const financingId = this.store.selectSnapshot(FinancingMapState.currentId);

                        if (!!financingId) {
                            const newHousehold = ModelFactoryService.createHouseholdModel(financingId);
                            result.household = await this.dataService.createHousehold(newHousehold);
                        }
                    }

                    const newDebtor = ModelFactoryService.createDebtorModel(result.household);
                    newDebtor.firstName = result.firstName;
                    newDebtor.lastName = result.lastName;

                    if (result.copyAddress) {
                        newDebtor.homeStreet = this.debtors[0].homeStreet;
                        newDebtor.homeStreetNumber = this.debtors[0].homeStreetNumber;
                        newDebtor.homeStairway = this.debtors[0].homeStairway;
                        newDebtor.homeTop = this.debtors[0].homeTop;
                        newDebtor.homeZip = this.debtors[0].homeZip;
                        newDebtor.homeCity = this.debtors[0].homeCity;
                        newDebtor.homeCountry = this.debtors[0].homeCountry;
                    }

                    this.dataService.createDebtor(newDebtor).catch(e => { throw e; });
                }
            });
    }

    /**
     * Entfernt einer Entität
     *
     * @param {string} id id der zu entfernenden Entität
     */
    public removeEntity(id: string): void {
        const financingMapStatus = this.store.selectSnapshot(FinancingMapState.current)?.status;

        if (financingMapStatus === FinancingMapStatus.Open) {
            if (this.debtors.length === 1) {
                this.notificationService.alert(
                    this.translate.instant('components.financingTab.debtor.lastDebtorTitle'),
                    this.translate.instant('components.financingTab.debtor.lastDebtorText'),
                );
            }
            else {
                this.notificationService.confirmYesNo(
                    this.translate.instant('components.financingTab.debtor.removeDebtorTitle'),
                    this.translate.instant('components.financingTab.debtor.removeDebtorText'),
                )
                    .pipe(take(1))
                    .subscribe(role => {
                        if (role === 'submit') {
                            const currentEntität = this.selectedEntityIdx !== undefined ? this.debtors[this.selectedEntityIdx] : undefined;
                            if (!!currentEntität && currentEntität.id === id) {
                                this.selectedEntityIdx = 0;
                                this.initFormular(this.debtors[0]);
                            }

                            this.dataService.deleteDebtor(id).catch(e => { throw e; });
                        }
                    });
            }
        }
    }

    /**
     * Handler wenn ein Chip angeklickt wird um eine Entität auszuwählen
     *
     * @param {number} index Index der Entität
     */
    public onSelectEntity(index: number): void {
        this.selectedEntityIdx = index;
        this.initFormular(this.debtors[index]);
        this.overflowWrapper.nativeElement.scrollTo({ top: 0 });
    }

    /**
     * initialisiert das Formular
     *
     * @param {IDebtorModel} debtor der Kreditnehmer zum initialisieren
     */
    private initFormular(debtor: IDebtorModel): void {
        const debValidation = DEBTOR_FORM_VALIDATION_MAP['debtorModel'];

        const controlCfg: Record<string, unknown> = {
            id: [debtor.id], // only for identification
            householdId: [debtor.householdId, Validators.required],
        };

        const formModel = this.modelToFormObject(debtor);

        for (const fieldname in debValidation) {
            if (fieldname in debValidation) {
                let value = (formModel as unknown as Record<string, unknown>)[fieldname] as number | string | boolean | unknown[];

                if (fieldname === 'lineType' && this.visibilityMap[debtor.id].fieldLineTypeMulti) {
                    value = HelperService.convertFlagToArray(debtor.lineType, LineType);
                    controlCfg[fieldname] = [value];
                }
                else {
                    controlCfg[fieldname] = [HelperService.getValueOrNull(value as number | string | boolean)];
                }
            }
        }

        const group = this.fb.group(controlCfg, { updateOn: 'blur' });

        this.validation.setValidatorsForForm(debValidation, group, undefined, debtor);

        group.valueChanges
            .pipe(
                takeUntil(this.viewLeft$),
                clearInvalidFormValues(group),
            )
            .subscribe(async (change: IDebtorFormModel) => {
                change = this.unsetHiddenFields(change);

                if (this.visibilityMap[change.id].fieldLineTypeMulti) {
                    change.lineType = HelperService.convertArrayToFlag(change.lineType as unknown as LineType[])
                }


                const currentDebtor = this.debtors[this.selectedEntityIdx];
                if (change.householdId === 'createhousehold') {
                    const newHouseholdId = await this.dataService.createHousehold({
                        financingMapId: this.store.selectSnapshot(FinancingMapState.currentId),
                    });

                    change.householdId = newHouseholdId ?? currentDebtor?.householdId;
                }

                for (const fieldName of Object.keys(this.listsWithOther)) {
                    const list = this.listsWithOther[fieldName].list;

                    const oldValue = (currentDebtor as unknown as Record<string, unknown>)[fieldName] as string | null | undefined;
                    const newValue = (change as unknown as Record<string, unknown>)[fieldName] as string | null | undefined;

                    // wechsel von einem gültigen wert aus der Liste auf Sonstiges
                    if (!!oldValue && list.includes(oldValue) && oldValue !== newValue && newValue === this.otherLabel) {
                        // Zurücksetzen des Sonstigen Feld
                        (change as unknown as Record<string, unknown>)[`${fieldName}${this.otherPostfix}`] = null;
                    }
                }

                change.customerNumber = !!change.customerNumber ? change.customerNumber.padStart(GlobalSettings.maxCustomerNumberLength, '0') : change.customerNumber;

                const toUpdate = this.formToModelObject(change);
                this.dataService.updateDebtor(toUpdate).catch(e => { throw e; });
            });

        this.updateHouseholdSelection();

        this.form = group;

    }

    /**
     * aktualisiert das Formular
     *
     * @param {IDebtorModel} debtor der Kreditnehmer zum updaten
     */
    private updateFormular(debtor: IDebtorModel): void {
        if (!!this.form) {
            if (!!debtor) {
                const debValidation = DEBTOR_FORM_VALIDATION_MAP['debtorModel'];

                const formModel = this.modelToFormObject(debtor);

                for (const fieldname in debValidation) {
                    if (fieldname in debValidation) {
                        const field = this.form.get(fieldname);
                        const value = (formModel as unknown as Record<string, unknown>)[fieldname] as number | string | boolean;

                        if (!!field && HelperService.getValueOrNull(field.value) !== HelperService.getValueOrNull(value) && (field.valid || HelperService.hasValue(value) || fieldname === 'lineType')) {

                            if (fieldname === 'lineType' && this.visibilityMap[debtor.id].fieldLineTypeMulti) {
                                field.patchValue(HelperService.convertFlagToArray(value, LineType), { onlySelf: true });
                            }
                            else {
                                field.patchValue(value, { onlySelf: true });
                            }
                        }

                    }
                }

                this.validation.setValidatorsForForm(debValidation, this.form, undefined, debtor);
            }
        }
    }

    /**
     * setzt alle Werte zurück, bei denen die Felder nicht mehr angezeigt werden
     *
     * @param  {IDebtorModel} debtor zu prüfender Kreditnehmer
     * @returns {IDebtorModel} überarbeitetes debtor model
     */
    private unsetHiddenFields(debtor: IDebtorModel): IDebtorModel {
        const newVisibility = DebtorState.visibleMap(debtor);
        const oldVisibility = this.visibilityMap[debtor.id];

        if (
            newVisibility.fieldLineType !== oldVisibility.fieldLineType ||
            newVisibility.fieldLineTypeMulti !== oldVisibility.fieldLineTypeMulti) {
            // wenn sich die anzahl der KFZ so ändert das ein wechsel des LineTypes erfolgt, wird dieses zurückgesetzt
            debtor.lineType = null;
        }

        return {
            ...debtor,
            tinNumber: !!debtor.additionalCitizenship ? debtor.tinNumber : null,
            lineType: newVisibility.fieldLineType || newVisibility.fieldLineTypeMulti ? debtor.lineType : null,
        };
    }

    /**
     * Aktualisiert die Haushalte für die Karte 'Zugehörigkeit'
     *
     * @param {IHouseholdModel[]} households Haushalte (optional)
     */
    private updateHouseholdSelection(households?: IHouseholdModel[]): void {
        const currentHouseholds = households ?? this.store.selectSnapshot(HouseholdState.current);

        this.households = currentHouseholds.map((household, index) => ({
            value: household.id,
            label: `${index + 1}. Haushalt`,
        }));

        if (this.households.length < GlobalSettings.maxHouseholds) {
            this.households?.push({
                value: 'createhousehold',
                label: this.translate.instant('components.financingTab.household.addNewHouseholdButton'),
            })
        }
    }

    /**
     * convertiert ein Data model objekt in ein Formobjekt
     *
     * @param  {IDebtorFormModel} model data objekt
     * @returns {IDebtorModel} daten objekt
     */
    private modelToFormObject(model: IDebtorModel): Partial<IDebtorFormModel> {
        const result: Record<string, unknown> = {
            ...model,
        }

        for (const fieldName of Object.keys(this.listsWithOther)) {
            const value = (model as unknown as Record<string, unknown>)[fieldName] as string | null | undefined;
            result[fieldName] = this.getValueForList(this.listsWithOther[fieldName].list, value);
            result[`${fieldName}${this.otherPostfix}`] = this.getValueForOther(this.listsWithOther[fieldName].list, value);
        }

        return result;
    }

    /**
     * convertiert ein Formobjekt zu einem Data model objekt
     *
     * @param  {IDebtorFormModel} model form objekt
     * @returns {IDebtorModel} daten objekt
     */
    private formToModelObject(model: IDebtorFormModel): Partial<IDebtorModel> {
        const result: Record<string, unknown> = {
            ...model,
        }

        for (const fieldName of Object.keys(this.listsWithOther)) {
            const asRecord = (model as unknown as Record<string, string | null | undefined>);
            result[fieldName] = this.listFieldModelMapping(asRecord[fieldName], asRecord[`${fieldName}${this.otherPostfix}`])
        }

        return result;
    }

    /**
     * ermittelt den Wert für das Sonstiges Feld. Wenn field in der Auswahlliste enthalten ist (nicht case sensitiv),
     * wird der Listenwert zurück gegeben. Wenn er nicht enthalten ist, wird der Wert selbst zurück gegeben. Wenn field nicht
     * gesetzt ist, wird null zurück gegeben.
     *
     * @param  {string[]} elementList zu prüfende liste
     * @param  {string|null} field Wert des Models
     * @returns {string} Feld wert für Sonstiges oder null
     */
    private getValueForOther(elementList: string[], field?: string | null): string | null | undefined {

        // wenn noch nichts eingegeben wurde oder nur die Auswahl auf Sonstiges steht, wird nichts zurückgegeben
        if (!HelperService.hasValue(field) || field === this.otherLabel) {
            return null;
        }

        const listValue = elementList.find(element => element.toLowerCase() === field?.toLowerCase())
        return listValue ?? field;
    }

    /**
     * ermittelt den Wert für die Auswahlliste. Wenn field in der Auswahlliste enthalten ist (nicht case sensitiv),
     * wird der Listenwert zurück gegeben. Wenn er nicht enthalten ist, wird "Sonstiges" zurück gegeben. Wenn field nicht
     * gesetzt ist, wird null zurück gegeben.
     *
     * @param  {string[]} elementList zu prüfende liste
     * @param  {string|null} field Wert des Models
     * @returns {string} Listen Wert oder null
     */
    private getValueForList(elementList: string[], field?: string | null): string | null {

        // wenn noch nichts eingegeben wurde, wird auch nichts zurückgegeben
        if (!HelperService.hasValue(field)) {
            return null;
        }

        const listValue = elementList.find(element => element.toLowerCase() === field?.toLowerCase())
        return listValue ?? this.otherLabel;
    }

    /**
     * wenn countries sontiges ist und befüllt dann gib sonstiges zurück sonst das normale land
     *
     * @param  {string} modelField model normal countries field
     * @param  {string} modelOtherField model other countries field
     * @returns {string} countriesValue
     */
    private listFieldModelMapping(modelField?: string | null, modelOtherField?: string | null): string | null | undefined {
        return (modelField === this.otherLabel && !!modelOtherField) ? modelOtherField : modelField;
    }

    /**
     * callback function für countries pipes
     *
     * @returns {UnaryFunction} pipe, welche ICountryModel in IListTuple mapped und Sonstiges hinzufügt
     */
    private addOtherToList(): UnaryFunction<Observable<ICountryModel[]>, Observable<IListTuple<string>[]>> {
        return pipe(
            map((countries: ICountryModel[]) => countries.map(({ name }) => ({
                label: name,
                value: name,
            }))),
            map(countries => [
                ...countries,
                {
                    label: this.otherLabel,
                    value: this.otherLabel,
                },
            ]));
    }
}
