import { AbstractControl, ValidationErrors } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';

/**
 * validator class for phone number validation
 */
export class PhoneNumberValidation {
    /**
     * Prüft ob die Telefonnummer im gültigen Format ist
     * 
     * @param {AbstractControl} control zu prüfendes Control
     * @returns {ValidationErrors} ValidationErrors mit Fehlern oder null
     */
    public static validPhoneFormat(control: AbstractControl): ValidationErrors | null {
        const phoneUtils = PhoneNumberUtil.getInstance();

        if (control.value && control.value.length > 0) {
            try {
                const phoneNumber = phoneUtils.parse(control.value);
                const countryCode = phoneNumber.getCountryCode();
                const nationalNumber = phoneNumber.getNationalNumber();

                if (countryCode === undefined || nationalNumber === undefined || (`+${countryCode.toString()}${nationalNumber.toString()}`).length < control.value.length) {
                    // In this case glibphone removed leading zeros which should be disallowed
                    return { phoneLength: true};
                }

                const isValidPhoneNumber = phoneUtils.isValidNumber(phoneNumber);

                if (!isValidPhoneNumber) {
                    return { invalidPhoneNumber: true};
                }

            }
            catch (_) {
                return { pattern: true};
            }
        }

        return null;
    }
}
