// exportiert alle unter states

import { CustomerState, DebtorState, DocumentState, FileState, FinancingMapState, HouseholdState, LiabilityState, MasterDataState, NewLiabilityState, RealEstateState, SignatureState } from '@ucba/sdk/statemanagement/states';

import { ApplicationState } from './application/application.state';

export * from './application/application.state';

/**
 * Alle States welche über NGXS Verwaltet werden
 */
 export const allStates = [
    ApplicationState,
    MasterDataState,
    CustomerState,
    FinancingMapState,
    RealEstateState,
    HouseholdState,
    DebtorState,
    LiabilityState,
    NewLiabilityState,
    DocumentState,
    FileState,
    SignatureState,
]

// Alle States welche im sessionStorage persisitiert werden
export const persistStates = [
    ApplicationState.stateName,
    MasterDataState.stateName,
]

