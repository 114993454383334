export interface IHistoryEntry {
    /** Action  */
    title: string; //action

    /** Datum  */
    date?: Date;

    /** Short editor name  */
    editor? : string;

    /** Additional information  */
    info?: string;
}
