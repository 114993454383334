import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WaiterService } from '@ntag-ef/waiter';
import { Subject } from 'rxjs';

import { IRealEstateModel } from '../../models';
import { DataService } from '../../services/data/data.service';
import { AddressComponentTypes } from '../../services/google/address-component-types.enum';
import { GoogleService } from '../../services/google/google.service';

/**
 * Komponente, mit welcher die Map angezeigt wird
 */
@Component({
    selector: 'ucba-google-maps',
    templateUrl: './google-maps.component.html',
    styleUrls: ['./google-maps.component.scss'],
})
export class GoogleMapsComponent implements OnInit, OnDestroy {

    /**
     * Gibt ein boolean zurück, wenn die adresse valide ist
     * 
     * @returns {boolean} is address valid
     */
    public get isAddressValid(): boolean {
        return this.googleMapService.isAddressValid;
    }

    public geometry: { lat: number; lng: number; zoom: number; } | undefined;
    public currentRealestateId: string | undefined;

    /** marker for destroying subscriptions */
    private viewLeft$ = new Subject<void>();


    /**
     * Konstruktor für die google map
     * 
     * @param {GoogleService} googleMapService GoogleService incjector
     * @param {DataService} dataService DataService incjector
     * @param {Location} location Location incjector
     * @param {Router} router Router injector
     * @param {ActivatedRoute} activateRoute ActivatedRoute incjector
     * @param {WaiterService} waiter WaiterService incjector
     */
    public constructor(
        private googleMapService: GoogleService,
        private dataService: DataService,
        private location: Location,
        private router: Router,
        private activateRoute: ActivatedRoute,
        private waiter: WaiterService,
    ) {
        const navigationState = this.router.getCurrentNavigation()?.extras?.state;
        this.geometry = !!navigationState ? navigationState['geometry'] : undefined;
    }

    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {
        this.currentRealestateId = this.activateRoute.snapshot.queryParams['id'];
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
    }

    /**
     * wenn adresse übernehmen geklickt wird
     */
    public async onSubmitMapClicked(): Promise<void> {
        const address = this.googleMapService.currentAddressData;
        if (!!address) {
            await this.updateRealEstateFromGeocodeResult(address);
        }

        this.location.back();
    }

    /**
     * navigiert zur vorherigen Seite
     */
    public back(): void {
        this.location.back();
    }

    /**
     * aktualisiert die Grundstücksadresse
     * 
     * @param {object} data geocoder result
     */
    private updateRealEstateFromGeocodeResult(data: google.maps.GeocoderResult): Promise<void> {
        return new Promise<void>((resolve, reject) => {

            this.waiter.show();
            const realEstate: Partial<IRealEstateModel> = {};
            realEstate.validatedStreet = data.address_components.find(c => c.types.includes(AddressComponentTypes.route))?.long_name;
            realEstate.validatedStreetNumber = data.address_components.find(c => c.types.includes(AddressComponentTypes.street_number))?.long_name;
            realEstate.validatedZip = data.address_components.find(c => c.types.includes(AddressComponentTypes.postal_code))?.long_name;

            if (data.address_components.some(c => c.types.includes(AddressComponentTypes.locality))) {
                realEstate.validatedCity = data.address_components.find(c => c.types.includes(AddressComponentTypes.locality))?.long_name;
            }
            else if (data.address_components.some(c => c.types.includes(AddressComponentTypes.administrative_area_level_2))) {
                realEstate.validatedCity = data.address_components.find(c => c.types.includes(AddressComponentTypes.administrative_area_level_2))?.long_name;
            }
            else if (data.address_components.some(c => c.types.includes(AddressComponentTypes.administrative_area_level_1))) {
                realEstate.validatedCity = data.address_components.find(c => c.types.includes(AddressComponentTypes.administrative_area_level_1))?.long_name;
            }

            realEstate.latitude = data.geometry.location.lat();
            realEstate.longitude = data.geometry.location.lng();

            realEstate.id = this.currentRealestateId;

            this.dataService.updateRealEstate(realEstate)
                .then(() => {
                    this.waiter.hide();
                    resolve();
                })
                .catch(e => {
                    this.waiter.hide();
                    reject(e);
                });
        });
    }
}
