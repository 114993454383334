
import { DocumentType } from '@ntag-ef/finprocess-enums/finadvisory';
import { BehaviorSubject } from 'rxjs';

import { ActivitySortingType, ClaimType, CustomerSortingType, FilterType, FinancingMapStatus } from '../enums';
import { IPGToken } from '../interfaces';
import { IUserSettings } from '../interfaces/user-settings.interface';

/**
 * exportiert Konstanten, welche in der Anwendung verwendet werden
 */
export class GlobalSettings {


    /** flag ob die Anwendung online ist */
    public static isOnline = new BehaviorSubject<boolean>(true);
    
    /** flag ob der Browser online ist */
    public static isBrowserOnline = true;

    public static connectionErrorId = 'OFFLINE';
    
    /** aktiviert debug funktionen */
    public static debugMode = false;

    public static readonly googleApiKey = 'AIzaSyCsF3Nctx5WKV0-m4vhFdPJmG-R_GIPeQI';

    /** Bekannte Mimetypes */
    public static readonly MIME_JPG = 'image/jpeg';
    public static readonly MIME_PNG = 'image/png';
    public static readonly MIME_PDF = 'application/pdf';
    public static readonly MIME_XLS = 'application/vnd.ms-excel';
    public static readonly MIME_MP4 = 'video/mp4';

    /** erlaubte Mimetypes für Bilder */
    public static readonly validImageFormates = [GlobalSettings.MIME_JPG, GlobalSettings.MIME_PNG];

    /** erlaubte Mimetypes für den Fileupload */
    public static readonly validUploadMimeTypes = [GlobalSettings.MIME_JPG, GlobalSettings.MIME_PNG, GlobalSettings.MIME_PDF];
    /** erlaubte Mimetypes für den document viewer */
    public static readonly validShowMimeTypes = [GlobalSettings.MIME_JPG, GlobalSettings.MIME_PNG, GlobalSettings.MIME_PDF, GlobalSettings.MIME_MP4];

    /**
     * Rückgabe eines Array mit allen DokumentTypen die vom VPC zugesendet werden
     */
    public static readonly documentTypesComeFromVpc: DocumentType[] = [
        DocumentType.SampleCalculation,
        DocumentType.SampleCalculationVariable,
        DocumentType.HouseholdBalanceSignature,
        DocumentType.SelfDisclosure,
        DocumentType.ESIS,
        DocumentType.BankAccountPrices,
        DocumentType.RejectionLetter,
        DocumentType.EmployerApproval,
        DocumentType.CompositionOfOtherIncomeSignature,
        DocumentType.PensionHouseholdBalanceSignature,
        DocumentType.AdditionalSheetSignature,
    ];

    /**
     * Rückgabe eines Array mit allen DokumentTypen welche nach dem Rechenbeispiel unterschrieben werden müssen
     */
    public static readonly documentTypesHousehold: DocumentType[] = [
        DocumentType.HouseholdBalanceSignature,
        DocumentType.CompositionOfOtherIncomeSignature,
        DocumentType.SelfDisclosure,
        DocumentType.PensionHouseholdBalanceSignature,
        DocumentType.AdditionalSheetSignature,
    ];

    public static readonly minDuration = 120;
    public static readonly maxDuration = 420;

    public static readonly maxCommunityCustomerNumberLength = 9;
    public static readonly maxCustomerNumberLength = 8;

    public static readonly retirementAge = 65;

    public static readonly maxLiabilities = 5;
    public static readonly maxNewLiabilities = 2;
    public static readonly maxDebtor = 5;
    public static readonly maxHouseholds = 5;
    public static readonly maxRealEstates = 5;

    /** the max value of all currency inputs 10.000.000.000 € */
    public static readonly currencyMax = 10000000000;

    /** the minimum available Date */
    public static readonly minDate = new Date(0);

    /** the maximum available Date */
    public static readonly maxDate = new Date(8640000000000000);

    /** min Jahr für den Datepicker */
    public static minDateForDatepicker = new Date(1900, 0, 1);

    /** max Jahr für den Datepicker */
    public static maxDateForDatepicker = new Date(2099, 0, 1);

    /** a regular expression for strictly checking ISO 8601 dates */
    public static iso8601Regex = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]+)?(Z)?$/;
    public static dateTimeOffsetRegex = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]+)?((\+|-)(2[0-3]|[01][0-9]):([0-5][0-9]))?$/;
    public static germanDateRegex = /^\d{2}\.\d{2}.\d{4}$/;

    /** regular expression for not allowed symbols in filename, it will replace with _ */
    public static repaceRegex = /[^a-zA-Z0-9_\-.]/g;


    /** max size of an uploaded file */
    public static readonly maxSize = 15 * 1024 * 1024; // 50 MB

    /** max size of a financing map includes documents */
    public static readonly maxSizeForAll = 2000 * 1024 * 1024; // 2000 MB (48MB puffer für evt erzeugte Dokumente und Daten)

    /** FormGroup Key*/
    public static readonly housholdKey = 'houshold';
    public static readonly debtorKey = 'debtors';
    public static readonly liabilityKey = 'liabilitys';
    public static readonly newliabilityKey = 'newLiabilitys';

    public static readonly defaultActivitySort = ActivitySortingType.CreatedDescending; // Datum absteigend
    public static readonly defaultActivityFilter = FilterType.ALL; // Alle Aktivitäten der Filiale
    public static readonly defaultActivitySearch = '';
    public static readonly defaultCustomerSort = CustomerSortingType.NameAscending; // Nachname aufsteigend
    public static readonly defaultCustomerSearch = '';

    /** Waiter delay period */
    public static readonly waiterDelayLong = 1000;
    public static readonly waiterDelayShort = 300;

    /**
     * max size in MB
     *
     * @returns {number}  max size
     */
    public static get maxSizeInMB(): number {
        return Math.floor(this.maxSize / 1024 / 1024);
    }

    /**
     * max size for all in MB
     *
     * @returns {number}  max size
     */
    public static get maxSizeForAllInMB(): number {
        return Math.floor(this.maxSizeForAll / 1024 / 1024);
    }

    /** Systemempfehlungen für Browser */
    public static browser = {
        microsoft: 'Microsoft Edge Version 118.x',
        firefox: 'Firefox Version 118.x',
        chrome: 'Google Chrome Version 118.x',
        safari: 'Safari 16.5.x unter MacOS',
    };

    /** Authentifizierungstoken, wird in HubService.handleNavigationChange verwaltet */
    public static token: string | null | undefined;

    /** die Hub Connection Id */
    public static connectionId: string | null | undefined;

    /** flag welches repräsentiert, dass beim Aufbau der Hubverbindung es zu einem Fehler kam */
    public static hubError = false;

    public static readonly pageSizeOptions = [10, 20, 50, 100];

    /** Einstellung/Hilfsvariablen vom Nutzer, welche temporär in der Anwendungen gesetzt werden */
    public static userSettings: IUserSettings = {
        activitySort: GlobalSettings.defaultActivitySort,
        activityFilterConsult: GlobalSettings.defaultActivityFilter,
        activityFilterBranch: [],
        activitySearch: GlobalSettings.defaultActivitySearch,
        activityPaging: {
            currentPage: 1,
            entityCountPerPage: GlobalSettings.pageSizeOptions[0],
            totalResultCount: 0,
        },

        customerSort: GlobalSettings.defaultCustomerSort,
        customerFilterConsultant: null,
        customerFilterBranch: [],
        customerFilterStatus: [],
        customerSearch: GlobalSettings.defaultCustomerSearch,
        customerPaging: {
            currentPage: 1,
            entityCountPerPage: GlobalSettings.pageSizeOptions[0],
            totalResultCount: 0,
        },
    };

    /** 
     * Die Version des Betriebssystems, nur Windows, nicht in Firefox. Ab 13.0.0 = Windows 11
     * 
     * @see https://learn.microsoft.com/de-de/microsoft-edge/web-platform/how-to-detect-win11
     * @see https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData
     */
    public static platformVersion?: string;

    // Regulärer Ablauf einer Finanzierung
    // Reihenfolge beachten!
    public static defaultFinancingStatusInfos = [
        { status: FinancingMapStatus.Open, discription: 'Geben Sie alle Daten an und übermitteln Sie diese. Ihr Filialberater wird sich anschließend bei Ihnen melden.' },
        { status: FinancingMapStatus.HouseholdCalculationRequested, discription: 'Sie erhalten zeitnah eine Mitteilung der Bank mit Ihrer Haushaltsrechnung.' },
        { status: FinancingMapStatus.HouseholdCalculationExist, discription: 'Bitte unterschreiben Sie die Haushaltsrechnungen und übermitteln diese anschließend.' },
        { status: FinancingMapStatus.SampleCalculationRequested, discription: 'Sie erhalten zeitnah eine Mitteilung der Bank mit Rechenbeispielen.' },
        { status: FinancingMapStatus.SampleCalculationExists, discription: 'Bitte wählen Sie ein Rechenbeispiel aus oder fordern ein neues an.' },
        { status: FinancingMapStatus.EsisRequested, discription: 'Sie erhalten zeitnah eine Mitteilung der Bank mit dem ESIS für Ihre Kreditanfrage.' },
        { status: FinancingMapStatus.EsisExists, discription: 'Bitte bestätigen Sie das ESIS oder fordern ein neues an.' },
        { status: FinancingMapStatus.VpcCompleted, discription: 'Ihr Vertrag liegt zur Unterschrift in der Filiale bereit.' },
    ];

     /** angezeigte Statustext für den Nutzer beim SampleCalculationRequested, wenn ein alternatives RB schon angefordert wurde */
    public static alternativeSampleCalculationText = 'Alternatives Rechenbeispiel angefordert';

    /** angezeigte Statustext für den Nutzer beim EsisRequested, wenn ein alternatives ESIS schon angefordert wurde */
    public static alternativeEsisText = 'Alternatives ESIS angefordert';


    /**
     * gibt letzte Enum-wert von FinancingMapStatus + 1 zurück, zur Nutzung von "Alternatives Rechenbeispiel angeforder" im Status filter 
     * , damit sich dies nicht auf FinancingMapStatus auswirkt, wenn die Enums in Zukunft erweitert/geändert wird.
     * 
     *  @returns {FinancingMapStatus} letzte Enum-wert + 1
     */
    public static get alternativeSampleCalculationValue(): FinancingMapStatus {
        const keys = Object.keys(FinancingMapStatus);
        return FinancingMapStatus[keys[keys.length - 1] as keyof typeof FinancingMapStatus] + 1;
    }

    /**
     * gibt letzte Enum-wert von FinancingMapStatus + 2 zurück, zur Nutzung von "Alternatives ESIS angeforder" im Status filter 
     * , damit sich dies nicht auf FinancingMapStatus auswirkt, wenn die Enums in Zukunft erweitert/geändert wird.
     * 
     *  @returns {FinancingMapStatus} letzte Enum-wert + 2
     */
    public static get alternativeEsisValue(): FinancingMapStatus {
        const keys = Object.keys(FinancingMapStatus);
        return FinancingMapStatus[keys[keys.length - 1] as keyof typeof FinancingMapStatus] + 2;
    }

    /**
     * prüft ob der token noch gültig ist.
     *
     * @returns {boolean} ist Token noch gültig
     */
    public static assertAccessToken(): boolean {
        if (!!GlobalSettings.token) {
            const token = GlobalSettings.parseTokenData(GlobalSettings.token);
            if (token === undefined || token === null) {
                // eslint-disable-next-line no-console
                console.warn('no action token');
                return false;
            }
            else if (token.exp < new Date()) {
                // eslint-disable-next-line no-console
                console.warn('expired: ', token.exp.toISOString());
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    }

    /**
     * Parst ein JWT für den externen Login
     *
     * @param {string} token JWT Token in Base64
     * @returns {IPGToken} Geparstes Token
     */
    public static parseTokenData(token: string): IPGToken {
        const parsed = JSON.parse(window.atob(token.split('.')[1]));

        const pgToken: IPGToken = {
            id: parsed[ClaimType.NameIdentifier],
            branchId: parsed['branchId'],
            email: parsed[ClaimType.Email],
            firstname: parsed[ClaimType.Name],
            lastname: parsed[ClaimType.Surname],
            role: parsed[ClaimType.Role],
            exp: new Date(parsed['exp'] * 1000),
        };

        return pgToken;
    }



}
