import { Component, Input, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { EnumTranslationService } from '@ntag-ef/finprocess-enums';
import { IListTuple } from '@ucba/sdk/interfaces';
import { Subject, map, takeUntil } from 'rxjs';

/**
 * Komponente, welche eine Selekt auswahl darstellt
 */
@Component({
    selector: 'cxad-select-flat',
    templateUrl: './select-flat.component.html',
    styleUrls: ['./select-flat.component.scss'],
})
export class SelectFlatComponent<T> implements OnDestroy {

    @Input() public control!: AbstractControl;
    @Input() public comment: string | undefined;

    /**
     * Setter um Enum bei jeder Änderung zu aktualisieren
     *
     * @param  {object | undefined} enumValue Enum
     */
    @Input() public set enum(enumValue: T | undefined) {
        if (!!enumValue && !!this.control) { // Achtung, Reihenfolge der Parameter beachten, als erstes das control und dann das enum!
            this.setEnum(enumValue);
        }
    }

    @Input() public translationKey: string | undefined;

    /** es wird immer ein eigenes Label definiert */
    @Input() public customLabel: string | undefined;

    /**
     * gibt den aktuellen AbstractControl als FormControl zurück
     *
     * @returns {FormControl} control
     */
    public get formControl(): FormControl {
        return this.control as FormControl;
    }

    public selectList: IListTuple<number>[] = [];

    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();

    public form: FormGroup;

    /**
     * Standard Konstruktor
     *
     * @param  {EnumTranslationService} enumTranslate EnumTranslationService injector
     * @param  {UntypedFormBuilder} fb UntypedFormBuilder injector
     */
    public constructor(
        private enumTranslate: EnumTranslationService,
        private fb: UntypedFormBuilder,
    ) {
        this.form = this.fb.group({});
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
    }

    /**
     * Initialisert oder aktualisiert den Enum
     *
     * @param {object} enumValue Enum
     */
    private setEnum(enumValue: Record<string | number, T>): void {
        const currentValues = this.control.value as number[];

        this.selectList = Object.values(enumValue).reduce<IListTuple<number>[]>((acc, curr: T) => {
            if (typeof curr === 'number') {
                // "Keine" wird immer ausgeschlossen
                const label = this.enumTranslate.instant({ type: this.translationKey || '', value: curr.toString() }) as string;
                return (!label.toLowerCase().endsWith('keine') && !label.toLowerCase().endsWith('keine/r')) ? [...acc, {
                    value: curr,
                    label,
                }] : acc;
            }
            else {
                return acc;
            }
        }, []);

        const controlCfg: Record<string, FormControl> = {};

        for (const item of this.selectList) {
            if (!!item.value) {
                controlCfg[item.value.toString()] = this.fb.control(currentValues.includes(item.value), { updateOn: 'change' });
            }
        }

        const form = this.fb.group(controlCfg);

        // Änderungen vom interenen nach außen
        form.valueChanges
            .pipe(
                takeUntil(this.viewLeft$),
                map<Record<string, boolean>, number[]>(changes => {
                    const result: number[] = [];

                    for (const prop in changes) {
                        if (prop in changes && changes[prop] === true) {
                            result.push(parseInt(prop, 10));
                        }
                    }

                    return result;
                }),
            )
            .subscribe(data => {
                this.control.patchValue(data);
                this.control.markAsDirty();
            });

        // Änderungen von ausen an das interne
        this.control.valueChanges
            .pipe(takeUntil(this.viewLeft$))
            .subscribe((values: number[]) => {
                const data: Record<string, boolean> = this.form.value;

                for (const item of this.selectList) {
                    if (!!item.value) {
                        data[item.value.toString()] = values.includes(item.value);
                    }
                }

                this.form.patchValue(data, { onlySelf: true, emitEvent: false })
            });


        this.form = form;

        if (this.control.disabled && this.form.enabled) {
            this.form.disable({ emitEvent: false });
        }
    }
}
