<ng-container>
    <h1 mat-dialog-title> {{data.debtor.firstName}} {{data.debtor.lastName}} - Unterschrift</h1>
    <div mat-dialog-content class="content">
        <canvas #signatureCanvas [width]="signaturePadOptions.width" [height]="signaturePadOptions.height"> </canvas>
    </div>
    <div mat-dialog-actions fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around none">
        <button fxFlex="100" fxFlex.gt-sm="30" class="dialog-button" mat-raised-button color="primary" cdkFocusInitial
            (click)="drawComplete()">
            {{ 'global.buttons.save' | translate }}
        </button>
        <button fxFlex="100" fxFlex.gt-sm="30" class="dialog-button" mat-raised-button (click)="drawClear()">
            {{ 'global.buttons.clean' | translate }}
        </button>
        <button fxFlex="100" fxFlex.gt-sm="30" class="dialog-button" mat-raised-button (click)="drawCancel()">
            {{ 'global.buttons.close' | translate }}
        </button>
    </div>
</ng-container>