import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { HelperService, ValidationService } from '@ucba/sdk';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

/**
 * Komponente, welche eine Input Feld darstellt
 */
@Component({
    selector: 'ucba-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() public control!: AbstractControl;
    @Input() public labelText!: string;
    @Input() public comment: string | undefined;
    @Input() public type = 'text';
    @Input() public readonly = false;
    @Input() public isFocused = false;

    @Input() public min = 0;

    @Output() public blurred = new EventEmitter();

    @ViewChild('inputElement') private inputElement: ElementRef | undefined;

    public controlName: string | undefined;
    public isRequired: boolean | undefined;
    private destroyed$ = new Subject<void>();
    public showPassword = false;

    /**
     * gibt den aktuellen AbstractControl als FormControl zurück
     *
     * @returns {FormControl} control
     */
    public get formControl(): FormControl {
        return this.control as FormControl;
    }

    /**
     * standard Konstruktor
     *
     * @param {ChangeDetectorRef} cRef ChangeDetectorRef injector
     */
    public constructor(private cRef: ChangeDetectorRef) { }

    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {

        this.controlName = HelperService.getControlName(this.formControl);

        ValidationService.validatorsChangedObservable$.pipe(
            filter(it => it === this.control),
            takeUntil(this.destroyed$),
        ).subscribe(() => {
            this.updateRequired();
        });

        this.updateRequired();
    }

    /**
     * Angular Hook nachdem die View geladen wurde
     */
    public ngAfterViewInit () {
        if (this.isFocused) {
            this.inputElement?.nativeElement.focus();
            this.cRef.detectChanges();
        }
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.destroyed$.next();
    }

    /**
     * update das isRequired feld anhand der im control rinterlegten validatoren
     */
    private updateRequired() {
        // @see https://stackoverflow.com/a/43904237
        const validators = !!this.control && !!this.control.validator ? this.control.validator(new FormControl()) : {};
        this.isRequired = !!validators && ('required' in validators);
    }

    /**
     * Schaltet um, ob das Passwort in Klartext angezeigt werden soll
     */
    public toggleShowPassword(): void {
        this.showPassword = !this.showPassword;
    }

    /**
     * Verhindert die Eingabe von Nicht Zahlen
     * 
     * @param {KeyboardEvent} event Tastatur Event
     */
    // eslint-disable-next-line class-methods-use-this
    public onlyNumbers(event: KeyboardEvent): void {
        const specialKeys: string[] = ['Backspace', 'Delete', 'Control', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];
        const allowdWithCtr = ['x', 'c', 'v']

        if (isNaN(parseInt(event.key, 10)) && !specialKeys.includes(event.key) && (!allowdWithCtr.includes(event.key) && !event.ctrlKey)) {
            event.preventDefault();
        }
    }

    /**
     * wenn die Eingabe focus verliert
     */
    public onBlur() {
        this.blurred.emit();
    }

    /**
     * Enter wird gedrückt
     */
    public onEnter() {
        //this.isFocused = false;
        this.inputElement?.nativeElement.blur();
    }
}
