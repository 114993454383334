<div fxLayout="row wrap" fxLayoutGap="15px grid" fxLayout.lt-sm="column">
    <mat-card class="cardStyle mat-elevation-z2 skeleton-card" [fxFlex]="cardFxFlex" [fxFlex.lt-md]="cardFxFlexMd"
        *ngFor="let item of [].constructor(items); let cardIndex = index;"
        [ngStyle]="{'margin-right': (marginRightPx  +  'px')}">
        <div>
            <mat-card-header>
                <mat-card-title
                    [ngClass]="{'skeleton-card-header-title-inputs': inputs,'skeleton-card-header-title': rows }"
                    class="skeleton">
                </mat-card-title>

                <div *ngIf="rows" class="skeleton-container-buttons">
                    <div *ngFor="let item of [].constructor(buttons);"
                        class="skeleton content-buttons skeleton-content-buttons">
                    </div>
                </div>
                <div *ngIf="inputs" class="skeleton-container-buttons">
                    <div class="skeleton content-buttons skeleton-content-customer-title-inputs">
                    </div>
                </div>
            </mat-card-header>
            <hr *ngIf="!inputs" class="financing-card-underline" />
            <mat-card-content class="cursor-pointer skeleton-card-content" matRipple>
                <div fxLayout="column">
                    <table *ngIf="rows" class="fn-table">
                        <div *ngFor="let item of [].constructor(rows); let i = index;">
                            <div fxLayout="row wrap">
                                <div fxFlex="50">
                                    <div class="skeleton skeleton-card-content-rows"
                                        [ngStyle]="{'width': (randomNumbersArray[(cardIndex +1) * (i+1) === randomNumbersArray.length ?  randomNumbersArray.length-1 : (cardIndex +1) * (i+1)] +  '%')}">
                                    </div>
                                </div>
                                <div *ngIf="column" fxFlex="50">
                                    <div class="skeleton skeleton-card-content-rows"
                                        [ngStyle]="{'width': (randomNumbersArray[rows - i] +  '%')}"></div>
                                </div>
                            </div>
                        </div>
                    </table>
                    <div *ngIf="inputs">
                        <form class="example-form">
                            <div *ngFor="let item of [].constructor(inputs); let i = index;">
                                <div class="input-card-container">
                                    <div class="skeleton skeleton-card-content-inputs"
                                        [ngStyle]="{'width': (randomNumbersArray[(cardIndex +1) * (i+1) === randomNumbersArray.length ?  randomNumbersArray.length-1 : (cardIndex +1) * (i+1)] +  '%')}">
                                    </div>
                                    <hr class="input-card-underline">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </mat-card-content>
        </div>
    </mat-card>
</div>