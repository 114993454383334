import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ICustomerLightModel, ICustomerLightResultModel, ICustomerModel } from '../../models';
import { IBackendBaseModel } from '../../models/backend-base.model';
import { ICustomerFilterRequest } from '../../requests';
import { ISendPasscodeResponse, IStartPasscodeResponse, IValidatePasscodeResponse } from '../../responses';
import { GlobalSettings } from '../../utils/global-settings';
import { BaseApiController } from '../base/base-api.controller';

/**
 * Controller welche alle Funkionalitäten der Customer Controller API bereithält
 */
@Injectable()
export class CustomerController extends BaseApiController<ICustomerModel> {
    protected override baseUrl = '/customer';

    /**
     * Gibt den Kunden mit übergebener Id zurück
     * 
     * @param {string} id id des Kunden
     * @param {boolean} light anfordern als LightModel (default: false)
     * @returns {ICustomerModel | ICustomerLightModel} angeforderter Kunde
     */
    public override getEntity<T extends IBackendBaseModel>(id: string, light = false): Observable<T> {
        return this.get<T>(`${this.baseUrl}/${id}?light=${light}`);
    }

    /**
     * Gibt ein Array aus allen für den Nutzer sichbaren Entitäten
     * 
     * @returns {any} angeforderte Entität
     */
    public getEntities(): Observable<ICustomerLightModel[]> {
        return this.get(this.baseUrl);
    }

    /**
     * Gibt ein Array aus allen für den Nutzer sichbaren Entitäten gefiltert
     * 
     * @returns {any} angeforderte Entität
     */
    public filter(): Observable<ICustomerLightResultModel> {
        const request: ICustomerFilterRequest = {
            currentPage: GlobalSettings.userSettings.customerPaging.currentPage,
            entityCountPerPage: GlobalSettings.userSettings.customerPaging.entityCountPerPage,
            textSearch: GlobalSettings.userSettings.customerSearch,
            sorting: GlobalSettings.userSettings.customerSort,
            branchIds: GlobalSettings.userSettings.customerFilterBranch,
            userIds: GlobalSettings.userSettings.customerFilterConsultant ?? [],
            status: GlobalSettings.userSettings.customerFilterStatus.filter(s => s !== GlobalSettings.alternativeSampleCalculationValue && s !== GlobalSettings.alternativeEsisValue),
            alternativeSampleCalculationSelected: GlobalSettings.userSettings.customerFilterStatus.includes(GlobalSettings.alternativeSampleCalculationValue),
            alternativeEsisSelected: GlobalSettings.userSettings.customerFilterStatus.includes(GlobalSettings.alternativeEsisValue),
        };

        const asRecord = request as unknown as Record<string, number | string | boolean | Array<number | string | boolean>>;
        return this.get(`${this.baseUrl}/filter`, { params: asRecord });
    }

    /**
     * abrufen der letzten 4 Ziffern der Mobilnummer
     *
     * @param {string} customerId Id des Kunden
     * @returns {Observable} http request observable
     */
    public getCustomersNumber(customerId: string): Observable<IStartPasscodeResponse> {
        return this.get<IStartPasscodeResponse>(`${this.baseUrl}/startpasscode/${customerId}`);
    }

    /**
     * Sendet den Passcode an die mobilenummer des Kunden
     *
     * @param {string} customerId Id des Kunden
     * @param {boolean} skipPasscode Id des Kunden
     * @returns {Observable} http request observable
     */
    public sendpasscode(customerId: string, skipPasscode?: boolean): Observable<ISendPasscodeResponse> {
        const params = {
            'skipPasscode': !!skipPasscode,
        };
        return this.post<ISendPasscodeResponse>(`${this.baseUrl}/sendpasscode/${customerId}`, undefined, { params });
    }

    /**
     * Prüft ob der eingegebene Passcode valide ist
     *
     * @param {string} customerId Id des Kunden
     * @param {string} passcode eingegebener passcode
     * @returns {Observable} http request observable
     */
    public validatepasscode(customerId: string, passcode: string): Observable<IValidatePasscodeResponse> {
        return this.post<IValidatePasscodeResponse>(`${this.baseUrl}/validatepasscode/${customerId}?passcode=${passcode}`);
    }

    /**
     * Datenschutzerklärung bestätigen
     *
     * @param {string} customerId Id des Kunden
     * @returns {Observable} http request observable
     */
    public confirmprivacystatement(customerId: string): Observable<void> {
        return this.post<void>(`${this.baseUrl}/confirmprivacystatement/${customerId}`);
    }
}
