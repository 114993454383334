<mat-dialog-content>
    <waiter-component [isLoading]="isLoading">
        <div fxLayout="column" fxLayoutAlign="space-between end">
            <mat-accordion class="dialog-accordion" multi>
                <mat-expansion-panel *ngFor="let group of listgroups; let i=index" [expanded]="listgroups.length === 1">

                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span>{{ group.name }}</span>
                            <span *ngIf="group.required" item-content class="label-required"> *</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <mat-accordion multi>
                        <mat-list>
                            <ng-container *ngFor="let item of group.items">
                                <mat-expansion-panel class="custom-shadow"
                                    *ngIf="item.references.length > 1; else oneItem">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <span>{{ item.name }}</span>
                                            <span *ngIf="item.hasRequired" item-content class="label-required"> *</span>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <mat-panel-description class="panel-description-arrow">
                                        <mat-list>
                                            <mat-list-item *ngFor="let reference of item.references"
                                                (click)="onItemClicked(item, reference)">
                                                <span fxFlex="80%" fxLayout="row" fxLayoutAlign="start center">
                                                    <span>
                                                        {{
                                                        referenceLabels[item.referenceProperty][reference.referenceId]
                                                        }}
                                                    </span>
                                                    <span *ngIf="reference.required" item-content
                                                        class="label-required">
                                                        *</span>
                                                </span>
                                                <span class="arrow-color" fxFlex="20%" fxLayout="row"
                                                    fxLayoutAlign="end center">
                                                    <mat-icon>navigate_next</mat-icon>
                                                </span>
                                            </mat-list-item>
                                        </mat-list>
                                    </mat-panel-description>
                                </mat-expansion-panel>

                                <ng-template #oneItem>
                                    <mat-list-item class="custom-shadow"
                                        (click)="onItemClicked(item, item.references[0])">
                                        <span fxFlex="80%" fxLayout="row" fxLayoutAlign="start center">
                                            <span> {{ item.name }}</span>
                                            <span *ngIf="item.hasRequired" item-content class="label-required"> *</span>
                                        </span>
                                        <span class="arrow arrow-color" fxFlex="20%" fxLayout="row"
                                            fxLayoutAlign="end center">
                                            <mat-icon>navigate_next</mat-icon>
                                        </span>
                                    </mat-list-item>
                                </ng-template>
                            </ng-container>
                        </mat-list>
                    </mat-accordion>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </waiter-component>
    <div>
        <button class="dialog-button" mat-raised-button (click)="cancel()">
            Abbrechen
        </button>
    </div>
</mat-dialog-content>