import { ISODate } from '../types';

import { IBackendBaseModel } from './backend-base.model';

/**
 * Handles liabilities of a household.
 */
export interface ILiabilityModel extends IBackendBaseModel {

    /** The household identifier. */
    householdId: string;

    /** The iban of the creditor. */
    ibanCreditor?: string;

    /** The initial amount. */
    initialAmount: number;

    /** The current amount. */
    currentAmount: number;

    /** The loan period in months. */
    loanPeriodInMonths?: number;

    /** The started date. */
    started?: ISODate;

    /** The monthly rate. */
    monthlyRate?: number;

    /** Whether it is covered. */
    covered?: boolean;

    /** Whether the liability is secured by land register. */
    securedByLandRegister?: boolean;

    /** Id of the RealEstate wich secured this liability */
    securedRealEstateId?: string;

    /** Whether the liability is a corporate credit. */
    isCorporateCredit?: boolean;
}
