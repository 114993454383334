import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IMessageModel } from '../../models';
import { BaseController } from '../base/base.controller';

/**
 * Controller welche das Mailtemplate abruft und Mails sendet
 */
@Injectable()
export class MessageController extends BaseController {

    private baseUrl = '/message';

    //#region --- Get ---

    /**
     * Gibt die Entität mit übergebener Id zurück
     * 
     * @param {string} id id der Entität
     * @returns {any} angeforderte Entität
     */
    public getEntity(id: string): Observable<IMessageModel> {
        return this.get(`${this.baseUrl}/${id}`);
    }

    /**
     * Gibt alle Nachrichten für die Finanzierung mit der übergebenen Id zurück.
     * 
     * @param {string} id id der Finanzierung
     * @returns {IMessageModel[]} alle Nachrichten der Finanzierung
     */
    public getMessagesByFinancing(id: string): Observable<IMessageModel[]> {
        return this.get(`${this.baseUrl}/GetMessagesByFinancing/${id}`);
    }

    //#endregion

    //#region --- CRUD ---

    /**
     * erstellt eine Entität im Backend
     * 
     * @param {any} model zu speichernde Entität
     * @returns {any} im backend gespeicherte Entität
     */
    public createEntity(model: Partial<IMessageModel>): Observable<IMessageModel> {
        return this.post(this.baseUrl, model);
    }

    /**
     * setzt mehrere Nachrichten auf gelesen
     * 
     * @param  {string[]} ids ids der Nachrichten
     * @returns {Observable} aktualisierte MessageModel ids
     */
    public setReaded(ids: string[]): Observable<IMessageModel[]> {
        const url = `${this.baseUrl}/SetReaded`;
        return this.patch(url, { ids });
    }

    /**
     * markiert eine Nachricht als gelöscht
     * 
     * @param {string} id id der Nachricht
     * @returns {Observable} Abschluss Observable
     */
    public markDeleted(id: string): Observable<IMessageModel> {
        return this.patch(`${this.baseUrl}/MarkDeleted/${id}`);
    }

    //#endregion
}
