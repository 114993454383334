/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable class-methods-use-this */

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DocumentType } from '@ntag-ef/finprocess-enums/finadvisory';

import { IDocumentModel, IFileModel } from '../../models';
import { HelperService } from '../../services';
import { GlobalSettings } from '../../utils';
import { Dispose, UnLoadCustomer, UnLoadFinancing } from '../actions';

import { DOCUMENT_CARD_VALIDATION_MAP } from './../../validations/document-card.validation-map';
import { FileState, IFileStateModel } from './../file/file.state';
import { SdkAddAndDeleteSelectedDocument, SdkAddDocument, SdkClearSelectedDocuments, SdkDeleteDocument, SdkDeleteDocuments, SdkDeleteSelectedDocument, SdkPatchDocument, SdkUpdateDocument } from './document.actions';

export interface IDocumentStateModel {
    data: IDocumentModel[];
    selectedDocuments: string[],
}

/**
 * Klasse des Dokument States
 */
@State<IDocumentStateModel>({
    name: DocumentState.stateName,
    defaults: DocumentState.defaultData,
})
@Injectable()
export class DocumentState {
    public static readonly stateName = 'documentState';
    private static readonly defaultData: IDocumentStateModel = {
        data: [],
        selectedDocuments: [],
    };

    /**
     * Aktualisiert das aktuelle Dokument
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchDocument} action SdkPatchDocument Action
     */
    @Action(SdkPatchDocument)
    public patchDocument(
        { patchState }: StateContext<IDocumentStateModel>,
        { payload }: SdkPatchDocument,
    ): void {
        patchState({
            data: payload,
        });
    }

    /**
     * Aktualisiert ein spezifisches Dokument
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkUpdateDocument} action SdkUpdateDocument Action
     */
    @Action(SdkUpdateDocument)
    public updateDocument(
        { patchState, getState }: StateContext<IDocumentStateModel>,
        { payload }: SdkUpdateDocument,
    ): void {
        const current = getState().data;

        if (current.some(({ id }) => id === payload.id)) {
            patchState({
                data: current.filter(({ id }) => id !== payload.id).concat(payload),
            });
        }
    }

    /**
     * fügt ein neues Dokument hinzu
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkAddDocument} action SdkAddDocument Action
     */
    @Action(SdkAddDocument)
    public addDocument(
        { patchState, getState }: StateContext<IDocumentStateModel>,
        { payload }: SdkAddDocument,
    ): void {
        const current = getState().data;

        if (!current.some(({ id }) => id === payload.id)) {
            patchState({
                data: current.concat(payload),
            });
        }
    }

    /**
     * entfernt ein spezifisches Dokument aus der LisAuswahllistete
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkDeleteSelectedDocument} action SdkDeleteSelectedDocument Action
     */
    @Action(SdkDeleteSelectedDocument)
    public deleteSelectedDocument(
        { getState, patchState }: StateContext<IDocumentStateModel>,
        { id }: SdkDeleteSelectedDocument,
    ): void {
        const current = getState().selectedDocuments;

        if (Array.isArray(current) && current.includes(id)) {
            patchState({
                selectedDocuments: current.filter((i => id !== i)),
            });
        }
    }

    /**
     * Fügt ein neues Element zur Auswahlliste hinzu oder entfernt es
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkAddAndDeleteSelectedDocument} action SdkDeleteSelectedDocument Action
     */
    @Action(SdkAddAndDeleteSelectedDocument)
    public addAndDeleteSelectedDocument(
        { getState, patchState }: StateContext<IDocumentStateModel>,
        { id }: SdkAddAndDeleteSelectedDocument,
    ): void {
        const current = getState().selectedDocuments;

        if (Array.isArray(current)) {
            if (!current.includes(id)) {
                patchState({
                    selectedDocuments: current.concat(id),
                });
            }
            if (current.includes(id)) {
                patchState({
                    selectedDocuments: current.filter((i => id !== i)),
                });
            }
        }
    }

    /**
     * Leert die Liste mit ausgewählten Dokumenten
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(SdkClearSelectedDocuments)
    public clearSelectedDocuments(
        { getState, patchState }: StateContext<IDocumentStateModel>): void {
        const current = getState().selectedDocuments;
        if (Array.isArray(current)) {
            patchState({
                selectedDocuments: [],
            });
        }
    }

    /**
     * entfernt ein spezifisches Dokument
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkDeleteDocument} action SdkDeleteDocument Action
     */
    @Action(SdkDeleteDocument)
    public deleteDocument(
        { dispatch }: StateContext<IDocumentStateModel>,
        { id }: SdkDeleteDocument,
    ): void {
        dispatch(new SdkDeleteDocuments([id]));
    }


    /**
     * entfernt mehrere spezifisches Dokument
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkDeleteDocuments} action SdkDeleteDocuments Action
     */
    @Action(SdkDeleteDocuments)
    public deleteDocuments(
        { patchState, getState }: StateContext<IDocumentStateModel>,
        { ids }: SdkDeleteDocuments,
    ): void {
        const current = getState().data;

        if (current.some(({ id }) => ids.includes(id))) {
            patchState({
                data: current.filter(({ id }) => !ids.includes(id)),
            });
        }
    }

    /**
     * zurücksetzen des States
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action([Dispose, UnLoadFinancing, UnLoadCustomer])
    public dispose({ setState }: StateContext<IDocumentStateModel>): void {
        setState({
            ...DocumentState.defaultData,
        });
    }

    /**
     * gibt alle aktuell selectierten Dokumente zurück
     *
     * @param {IDocumentStateModel} state aktueller State
     * @returns {IDocumentModel} der aktuell selektierte Kunde
     */
    @Selector()
    public static current(state: IDocumentStateModel): IDocumentModel[] {
        return state?.data ?? [];
    }

    /**
     * gibt alle aktuell ausgewählten Dokumente zurück
     *
     * @param {IDocumentStateModel} state aktueller State
     * @returns {IDocumentModel} der aktuell selektierte Kunde
     */
    @Selector()
    public static currentSelected(state: IDocumentStateModel): string[] {
        return state.selectedDocuments ?? [];
    }

    /**
     * gibt das Dokument mit übergebener Id zurück
     * 
     * @param {IDocumentStateModel} state aktueller State
     * @returns {(documentId: string) => IDocumentModel} funktion zum filtern
     */
    @Selector()
    public static currentById(state: IDocumentStateModel): (documentId: string) => IDocumentModel | undefined {

        /**
         * callback funktion zum herraussuchen eines Dokuments
         *
         * @param {string} documentId id des Dokuments
         * @returns {IDocumentModel} gesuchtes Dokuments
         */
        return (documentId: string): IDocumentModel | undefined => state.data.find(({ id }) => id === documentId);
    }

    /**
     * Ermittlung der Dokument-IDs, die mit einer bestimmten ID übereinstimmen
     *
     * @param {IDocumentStateModel} state aktueller State
     * @returns {IDocumentStateModel} callback
     */
    @Selector()
    public static documentIdsByAnyKindOfIds(state: IDocumentStateModel): (financingMapIds?: string[] | undefined, realEstateIds?: string[] | undefined, householdIds?: string[] | undefined, debtorIds?: string[] | undefined, types?: DocumentType[] | undefined) => string[] {

        /**
         * Callback funktion
         *
         * @param {string} financingMapIds financingMapIds
         * @param {string} realEstateIds realEstateIds
         * @param {string} householdIds householdIds
         * @param {string} debtorIds debtorIds
         * @param {DocumentType} types DocumentTypes
         * @returns {string[]} gefilterte Dokument-IDs
         */
        return (financingMapIds?: string[], realEstateIds?: string[], householdIds?: string[], debtorIds?: string[], types?: DocumentType[]) =>
            DocumentState.documentsByAnyKindOfIds(state)(financingMapIds, realEstateIds, householdIds, debtorIds, types).map(doc => doc.id);
    }

    /**
     * liefert die Dokumente, die mit einer bestimmten ID übereinstimmen
     *
     * @param {IDocumentStateModel} state aktueller State
     * @returns {() => IDocumentModel[]} callback
     */
    @Selector()
    public static documentsByAnyKindOfIds(state: IDocumentStateModel): (financingMapIds?: string[] | undefined, realEstateIds?: string[] | undefined, householdIds?: string[] | undefined, debtorIds?: string[] | undefined, types?: boolean | DocumentType[] | undefined) => IDocumentModel[] {
        /**
         * Callback funktion
         *
         * @param {string} financingMapIds financingMapIds
         * @param {string} realEstateIds realEstateIds
         * @param {string} householdIds householdIds
         * @param {string} debtorIds debtorIds
         * @param {DocumentType} types true = any Typ, false = type is undefiend, [] type filter
         * @returns {IDocumentModel[]} gefilterte Dokumente
         */
        return (financingMapIds?: string[], realEstateIds?: string[], householdIds?: string[], debtorIds?: string[], types?: DocumentType[] | boolean) => (state.data as IDocumentModel[]).filter(doc =>
            (
                (types === HelperService.hasValue(types)) || // wenn types ein boolean ist, wird nur geprüft ob ein typ vorhanden ist
                (Array.isArray(types) && types.includes(doc.type)) // wenn type ein array ist, muss der Typ enthalten sein
            ) &&
            (
                (!!financingMapIds && !!doc.financingMapId && financingMapIds.includes(doc.financingMapId)) ||
                (!!realEstateIds && !!doc.realEstateId && realEstateIds.includes(doc.realEstateId)) ||
                (!!debtorIds && !!doc.debtorId && debtorIds.includes(doc.debtorId)) ||
                (!!householdIds && !!doc.householdId && householdIds.includes(doc.householdId))
            ));
    }

    /**
     * return SampleCalculation filemodel of current FinancingMap
     *
     * @param {IDocumentStateModel} state aktueller State
     * @returns {IDocumentModel[]} documents SampleCalculation & SampleCalculationVariable
     */
    @Selector()
    public static sampleCalculationDocumentsByFinancing(
        state: IDocumentStateModel,
    ): IDocumentModel[] {
        /**
         * filterfunktion
         *
         * @param {IDocumentStateModel} state aktueller State
         * @returns {IDocumentModel[]} gefiltertes Dokumenten Array
         */
        return DocumentState.documentsByTypes(state)([
            DocumentType.SampleCalculation,
            DocumentType.SampleCalculationVariable,
        ]).sort((a, b) => ((a.type < b.type) ? -1 : (a.type > b.type) ? 1 : 0));

    }

    /**
     * Filtert die Dokumente nach Typ
     *
     * @param {IDocumentStateModel} state aktueller State
     * @returns {() => IDocumentModel[]} callback
     */
    @Selector()
    public static documentsByTypes(state: IDocumentStateModel): (types: DocumentType[]) => IDocumentModel[] {
        /**
         * filterfunktion
         *
         * @param {DocumentType} types Typen zum filtern
         * @returns {IDocumentModel[]} gefiltertes Dokumenten Array
         */
        return (types: DocumentType[]): IDocumentModel[] => state.data.filter(({ type }) => types.includes(type))
            .sort((a, b) => ((a.position ?? 0 < (b.position ?? 0)) ? -1 : (a.position ?? 0 > (b.position ?? 0)) ? 1 : 0));
    }


    /**
     * return SampleCalculation Information filemodel of current FinancingMap
     *
     * @param {DocumentState} state aktueller State
     * @param {FileState} fileState aktueller State
     * @returns {IDocumentModel} doc model
     */
    @Selector([FileState])
    public static sampleCalculationInformationDocumentsByFinancing(
        state: IDocumentStateModel,
        fileState: IFileStateModel,
    ): IDocumentModel[] {
        const documents = DocumentState.documentsByTypes(state)([
            DocumentType.EmployerApproval,
            DocumentType.BankAccountPrices,
        ]);

        // Alle Dokumente von FinProcess haben nicht das isSigned Flag
        return documents.filter(d => {
            const files = FileState.filesByDocumentIds(fileState)([d.id]);
            return files.some(({ isSigned }) => !isSigned);
        });
    }

    /**
     * return ESIS Document of givem FinancingMap
     *
     * @param {IDocumentStateModel} state aktueller State
     * @returns {IDocumentModel[]} documents ESIS
     */
    @Selector()
    public static currentEsisDocument(state: IDocumentStateModel): IDocumentModel[] {
        return DocumentState.documentsByTypes(state)([DocumentType.ESIS])
    }


    /**
     * return RejectionLetter Document of givem FinancingMap
     *
     * @param {IDocumentStateModel} state aktueller State
     * @returns {IDocumentModel[]} documents RejectionLetter
     */
    @Selector()
    public static currentRejectionLetterDocument(state: IDocumentStateModel): IDocumentModel[] {
        return DocumentState.documentsByTypes(state)([DocumentType.RejectionLetter])
    }


    /**
     * Gibt alle Files zurück welche in der Anwendung vom Nutzer hochgeladen wurden.
     * Gibt nicht die von FinProcess generierten zurück (GlobalSettings.documentTypesComeFromVpc)
     *
     * @param {IDocumentStateModel} state aktueller Dokumenten State
     * @param {IFileStateModel} fileState aktueller File State
     * @returns {IFileModel[]} alle Files eines Users
     */
    @Selector([FileState])
    public static allUserFiles(
        state: IDocumentStateModel,
        fileState: IFileStateModel,
    ): IFileModel[] {
        const documents = DocumentState.current(state);
        const finProcessDocuments = documents.filter(({ type }) => GlobalSettings.documentTypesComeFromVpc.includes(type)).map(({ id }) => id);
        const files = FileState.current(fileState);

        // Files von FinProcess haben isSigned = false
        // wenn diese von der Anwendung hochgeladen werden wird isSigned für das jeweile file = true
        return files.filter(({ documentId, isSigned }) => !finProcessDocuments.includes(documentId) || isSigned);
    }


    /**
     * Gibt alle Files zurück welche in der Anwendung vom Nutzer hochgeladen wurden.
     * Gibt nicht die von FinProcess generierten zurück (GlobalSettings.documentTypesComeFromVpc)
     *
     * @param {IDocumentStateModel} state aktueller Dokumenten State
     * @param {IFileStateModel} fileState aktueller File State
     * @returns {IFileModel[]} alle Files eines Users
     */
    @Selector([FileState])
    public static allHousholdDocumentFiles(
        state: IDocumentStateModel,
        fileState: IFileStateModel,
    ): IFileModel[] {
        const documents = DocumentState.current(state);
        const signDocumentIds = documents.filter(({ type }) => GlobalSettings.documentTypesHousehold.includes(type)).map(({ id }) => id);

        // Files von FinProcess haben isSigned = false
        // wenn diese von der Anwendung hochgeladen werden wird isSigned für das jeweile file = true
        return FileState.filesByDocumentIds(fileState)(signDocumentIds, true);
    }

    /**
     * Gibt alle Haushalt Dokumente zurück welche von FinProcess mit dem Rechenbeispiel gesendet wurden
     *
     * @param {IDocumentStateModel} state aktueller Dokumenten State
     * @param {IFileStateModel} fileState aktueller File State
     * @returns {IDocumentModel[]} alle von FinProcess gesendete Haushaltsdokumente
     */
    @Selector([FileState])
    public static allHousholdDocuments(
        state: IDocumentStateModel,
        fileState: IFileStateModel,
    ): IDocumentModel[] {
        const documents = DocumentState.current(state);
        const files = FileState.current(fileState);

        // Files von FinProcess haben isSigned = false
        // wenn diese von der Anwendung hochgeladen werden wird isSigned für das jeweile file = true
        return documents.filter(({ type, id }) => GlobalSettings.documentTypesHousehold.includes(type) &&
            files.some(({ documentId, isSigned }) => documentId === id && !isSigned));

    }


    /**
     * die fehlenden erforderlichen Dokumenttypen für der Haushaltsrechnung/Selbstauskunft zurückgeben
     *
     * @param {IDocumentStateModel} state aktueller Dokumenten State
     * @param {IFileStateModel} fileState aktueller File State
     * @returns {DocumentType[]} fehlende erforderliche Dokumenten
     */
    @Selector([FileState])
    public static missingHouseholdDocuments(
        state: IDocumentStateModel,
        fileState: IFileStateModel,
    ): DocumentType[] {
        const requiredDocuments = DocumentState.allHousholdDocuments(state, fileState);
        const documents = DocumentState.current(state);

        return requiredDocuments.reduce<DocumentType[]>((missing, document) => {
            const val = DOCUMENT_CARD_VALIDATION_MAP['householdDocuments'].find(({ type }) => type === document.type);

            if (val) {
                const existingDocuments = documents.filter(doc => doc[val.referenceProperty] === document[val.referenceProperty] && val.type === doc.type);
                if (existingDocuments.length > 0) {
                    const existingFiles = FileState.filesByDocumentIds(fileState)(existingDocuments.map(({ id }) => id), true);

                    if (existingFiles.length === 0) {
                        return [...missing, val.type];
                    }
                }
            }

            return missing;
        }, []);
    }

}
