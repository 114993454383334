import { IFinancingMapModel } from '../../models';
import { IStatusResponse } from '../../responses';

/**
 * Action welche alle Financing Maps updated
 */
 export class SdkPatchFinancingMap {
    public static readonly type = '[SDK FinancingMap] PATCH';

    /**
     * Standard Konstruktor
     * 
     * @param {IFinancingMapModel} payload zu patchende Financing Map
     */
    public constructor(public payload: IFinancingMapModel | null) { }
}

/**
 * Action welche alle Financing Maps updated
 */
 export class SdkUpdateStatus {
    public static readonly type = '[SDK FinancingMap] UpdateStatus';

    /**
     * Standard Konstruktor
     * 
     * @param {IFinancingMapModel} payload neue Statuswerte
     */
    public constructor(public payload: IStatusResponse) { }
}
