<waiter-component [isLoading]="isLoading" class="area-wrapper area-height">
    <h2 class="page-title">{{ 'components.financingTab.documents.pageTitle' | translate }}</h2>
    <cxad-read-only [isVisible]="closed"></cxad-read-only>
    <div fxLayout="row" fxLayoutAlign="flex-end">
        <mat-icon *ngIf="mustSendToVPC" class="resubmit-warning"
            matTooltip="{{ 'components.financingTab.documents.resubmitWarningTooltip' | translate }}"> report
        </mat-icon>
        <button mat-raised-button color="primary" (click)="sendDocumentsToFinProcess()" class="action-button fix-button"
            [disabled]='!mustSendToVPC' [class.invisible]='!readonly || closed'
            
            matTooltip="{{ 'components.financingTab.documents.resubmitTooltip' | translate }}">
            <mat-icon class="action-icon">upload_file</mat-icon>
            <span>{{ 'components.financingTab.documents.resubmit' | translate}}</span>
        </button>
    </div>
    <div class="scroll-wrapper" [class.readonly]="readonly" [class.open]="!readonly">
        <div fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px"
            class="max-height">

            <!-- Unterlagen -->
            <div fxFlex="100" fxflex.gt-md="48">
                <div class="header">
                    <h1>{{ 'components.financingTab.documents.documents' | translate }}</h1>
                    <p>{{ 'components.financingTab.documents.documentsNote' | translate }}</p>
                </div>
                <mat-accordion multi [togglePosition]="'before'">
                    <mat-expansion-panel *ngFor="let section of accordionListAreas" expanded=false>
                        <mat-expansion-panel-header class="documents-expansion-header disable-ripple">
                            <mat-panel-title>
                                {{ section.name }}
                                <span *ngIf="section.required">*</span>
                            </mat-panel-title>
                            <mat-panel-description fxFlex="20%" fxLayout="row" fxLayoutAlign="end center">
                                <span class="counter"> {{ section.items.length }} </span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-between stretch"
                            fxLayoutGap="10px">

                            <div fxFlex="33" [innerHTML]="section.description" class="margin-top word-break">
                            </div>

                            <div fxFlex="33">
                                <label class="upload" [for]="section.areaId" [class.disabled-icon]='closed'
                                    matTooltip="{{ ('components.financingTab.documents.multiUploadDocumentFor' | translate :{name: section.name}) }}">
                                    <mat-icon> folder </mat-icon>
                                    {{ 'components.financingTab.documents.selectDocument' | translate }}
                                </label>
                                <input type="file" [id]="section.areaId" [accept]="inputAcceptString" hidden multiple
                                    (change)="newDocuments($event, section.area, section.metaType)"
                                    [disabled]="closed" />
                                <div class="dropdiv" (dragenter)="toggleHoverZone(section.metaType, false)"
                                    (dragleave)="toggleHoverZone(section.metaType, true)"
                                    [class.drag-active]="dropzonesHovered[section.metaType]" fxHide fxShow.gt-md
                                    (dragover)="onDragOver($event)"
                                    (dragleave)="toggleHoverZone(section.metaType, true)"
                                    [class.drag-active]="dropzonesHovered[section.metaType]" fxHide fxShow.gt-md
                                    (dragover)="onDragOver($event)"
                                    (drop)="onDropSuccess($event, section.area, section.metaType)">
                                    <label class=" custom-file-upload" [class.disabled-icon]='closed'>
                                        <input hidden type="file" />
                                        {{ 'components.financingTab.documents.dropDocuments' | translate }}
                                    </label>
                                </div>
                            </div>

                            <div fxFlex="33" class="margin-top">
                                <h2>{{ 'components.financingTab.documents.uplodedDocuments' | translate }}</h2>
                                <span *ngIf="!section.hasContent">{{
                                    'components.financingTab.documents.uplodedDocumentsNote' | translate }}
                                </span>
                                <mat-list>
                                    <mat-list-item *ngFor="let item of section.items"
                                        (click)="showFile(item.fileId ?? '', item.name)">
                                        <span class="word-break" fxFlex="80" fxLayout="row"
                                            fxLayoutAlign="start center">
                                            {{ item.name }}
                                        </span>
                                        <span class="span-icon" fxFlex="20" fxLayout="row" fxLayoutAlign="end center"
                                            fxLayoutAlign.gt-md="none">
                                            <mat-icon matListIcon
                                                (click)="deleteFile($event, item.documentId, item.fileId)"
                                                [class.disabled-icon]="item.disableActionButtons"
                                                matTooltip="{{ 'components.financingTab.documents.toolTipDeleteDocument' | translate }}">
                                                delete
                                            </mat-icon>
                                        </span>
                                    </mat-list-item>
                                    <mat-divider></mat-divider>
                                </mat-list>
                            </div>

                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>

            <!-- Unterschriften -->
            <div fxFlex="100" fxflex.gt-md="48" class="signature-section">
                <h1>{{ 'components.financingTab.documents.signatures' | translate }}</h1>
                <mat-accordion multi [togglePosition]="'before'">
                    <mat-expansion-panel *ngFor="let signature of signatureListAreas"
                        (click)="onSignDocumentClicked(signature)" [hideToggle]="!signature.hasContent"
                        [disabled]="!signature.hasContent" [ngClass]="!signature.hasContent ? 'remove-margin': ''">
                        <mat-expansion-panel-header *ngIf="!signature.isHidden" [collapsedHeight]="'auto'"
                            [expandedHeight]="'auto'">
                            <mat-panel-title>
                                <mat-icon *ngIf="signature.isValid">check
                                </mat-icon>
                                <span class="panel-title"
                                    [ngClass]="!signature.hasContent && !signature.isValid ? 'padding-left': ''">
                                    {{ signature.name }}
                                </span>
                            </mat-panel-title>
                            <mat-panel-description fxFlex="20%" fxLayout="row" fxLayoutAlign="end center">
                                <span *ngIf="signature.hasContent || !signature.pageName" class="counter">
                                    {{ signature.items.length }}
                                </span>
                                <mat-icon *ngIf="!signature.hasContent && !!signature.pageName"
                                    [matTooltip]="'components.financingTab.documents.signatureHover' | translate"
                                    class="left-arrow">
                                    edit
                                </mat-icon>
                                <div class=" image-upload" (click)="preventNavigate($event)">
                                    <label [for]="signature.areaId">
                                        <mat-icon [class.disabled-icon]='closed'
                                            matTooltip="{{ ('components.financingTab.documents.multiUploadDocumentFor' | translate :{name: signature.name}) }}">
                                            file_upload
                                        </mat-icon>
                                    </label>
                                    <input [id]="signature.areaId" [accept]="inputAcceptString" type="file"
                                        multiple="multiple"
                                        (change)="newDocuments($event, signature.area, signature.documentType, signature.referenceId)"
                                        [disabled]="closed" />
                                </div>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <mat-card *ngFor="let item of signature.items" (click)="showFile(item.fileId ?? '', item.name)"
                            class="file-card">
                            <mat-card-actions class="mat-card-actions-margin" fxFlex="100%" fxLayout="row">
                                <span fxFlex="80%" fxLayout="row" fxLayoutAlign="start center">
                                    {{ item.name }}
                                </span>
                                <mat-icon fxFlex="20%" fxLayout="row" fxLayoutAlign="end center" matListIcon
                                    (click)="deleteFile($event, item.documentId, item.fileId)"
                                    [class.disabled-icon]="item.disableActionButtons"
                                    matTooltip="{{ 'components.financingTab.documents.toolTipDeleteDocument' | translate }}">
                                    delete
                                </mat-icon>
                            </mat-card-actions>
                        </mat-card>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>

        <div fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px"
            class="margin-top max-height">
            <!-- Nachzureichende Unterlagen -->
            <div fxFlex="100" fxflex.gt-md="48" *ngIf="isRequiredDocumentsVisible === true">
                <h1>{{ 'components.financingTab.documents.documentsToBeSubmittedLater' | translate }}</h1>
                <mat-accordion multi [togglePosition]="'before'">
                    <mat-expansion-panel *ngFor="let section of requiredDocumentListAreas; trackBy: identifyer"
                        expanded=false>
                        <mat-expansion-panel-header class="documents-expansion-header disable-ripple"
                            *ngIf="!section.isHidden">
                            <mat-panel-title>
                                {{ section.name }}
                                <span>*</span>
                            </mat-panel-title>
                            <mat-panel-description fxFlex="20%" fxLayout="row" fxLayoutAlign="end center">
                                <span class="counter"> {{ section.items.length }} </span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <ng-container>
                            <div fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-between stretch"
                                fxLayoutGap="10px">
                                <div fxFlex="33" [innerHTML]="section.description" class="margin-top word-break">
                                </div>
                                <div fxFlex="33">
                                    <label class="upload" [for]="section.areaId"
                                        matTooltip="{{ ('components.financingTab.documents.multiUploadDocumentFor' | translate :{name: section.name}) }}">
                                        <mat-icon> folder </mat-icon>
                                        {{ 'components.financingTab.documents.selectDocument' | translate }}
                                    </label>
                                    <input type="file" [id]="section.areaId" [accept]="inputAcceptString" hidden
                                        multiple
                                        (change)="newDocuments($event, section.area, section.documentType, section.referenceId)" />
                                    <div class="dropdiv"
                                        (dragenter)="toggleHoverZoneRequired(section.documentType, false)"
                                        (dragleave)="toggleHoverZoneRequired(section.documentType, true)"
                                        [class.drag-active]="dropzonesHoveredRequired[section.documentType]" fxHide
                                        fxShow.gt-md (dragover)="onDragOver($event)"
                                        (drop)="onDropSuccess($event, section.area, section.documentType, section.referenceId)">
                                        <label class=" custom-file-upload">
                                            <input hidden type="file" />
                                            {{ 'components.financingTab.documents.dropDocuments' | translate }}
                                        </label>
                                    </div>
                                </div>
                                <div fxFlex="33" class="margin-top">
                                    <h2>{{ 'components.financingTab.documents.uplodedDocuments' | translate }}</h2>
                                    <span *ngIf="!section.hasContent">{{
                                        'components.financingTab.documents.uplodedDocumentsNote' | translate }}
                                    </span>
                                    <mat-list *ngFor="let item of section.items">
                                        <mat-list-item (click)="showFile(item.fileId ?? '', item.name)">
                                            <span class="word-break" fxFlex="80" fxLayout="row"
                                                fxLayoutAlign="start center">
                                                {{ item.name }}
                                            </span>
                                            <span fxFlex="20" fxLayout="row" fxLayoutAlign="end center"
                                                fxLayoutAlign.gt-md="none">
                                                <mat-icon matListIcon
                                                    (click)="deleteFile($event, item.documentId, item.fileId)"
                                                    [class.disabled-icon]="item.disableActionButtons"
                                                    matTooltip="{{ 'components.financingTab.documents.toolTipDeleteDocument' | translate }}">
                                                    delete
                                                </mat-icon>
                                            </span>
                                        </mat-list-item>
                                        <mat-divider></mat-divider>
                                    </mat-list>
                                </div>
                            </div>
                        </ng-container>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
    <div class="action-button-group">
        <button mat-raised-button color="primary" (click)="createActivity()" class="action-button fix-button"
            *ngIf="!readonly && !notSign && !closed"
            matTooltip="{{ 'components.financingTab.documents.sendToFinadvisory' | translate }}">
            <mat-icon class="action-icon">upload_file</mat-icon>
            <span>{{ 'components.financingTab.documents.sendToFinadvisory' | translate}}</span>
        </button>

        <button mat-raised-button color="primary" (click)="sendHouseholdDocuments()" class="action-button fix-button"
            [class.invisible]="!housholdCalculationStatus" [disabled]="missingHouseholdCalculationFiles"
            matTooltip="{{ 'components.financingTab.documents.submitHousholdCalculationTooltip' | translate }}">
            <mat-icon class="action-icon">upload_file</mat-icon>
            <span>{{ 'components.financingTab.documents.submitHousholdCalculation' | translate}}</span>
        </button>
    </div>

</waiter-component>