import { SystemType } from '../enums';
import { EmailNotificationType } from '../enums/mail-notification-type.enum';
import { ISODate } from '../types';

import { IBackendBaseModel } from './backend-base.model';

export interface IActivityModel extends IBackendBaseModel {

    /** The description. */
    description: string;

    /** Thevalue indicating whether this ActivityModel is read. */
    isRead: boolean;

    /** The executive system. */
    executiveSystem: SystemType;

    /** The customer number. */
    customerNo: string;

    /** The financing number. */
    financingNo: string;

    /** The customer identifier. */
    customerId: string;

    /** The customers first name */
    customerFirstname: string;

    /** The customers last name */
    customerLastname: string;

    /** The consultant identifier. */
    consultantId: string;

    /** The branch identifier of customer. */
    customerBranchId: string;

    /** The consultants first name */
    consultantFirstname: string;

    /** The consultants last  name */
    consultantLastname: string;

    /** The Type of Email notification. */
    emailNotificationType: EmailNotificationType;

    /** The financingmap identifier. */
    financingMapId: string;
}

export interface IActivityCluster {
    customerId: string;
    financingMapId: string;
    customerName: string;
    lastModified: ISODate;
    financingNumber: string;
    customerNumber: string;
    branchName: string;
    consultantShortName: string;
    data: IActivityModel[];
}


export interface IActivityResultModel {
    data: IActivityCluster[];
    totalResultCount: number,
}
