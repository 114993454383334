<div fxLayout="column" *ngIf="form">
    <mat-label *ngIf="!!customLabel">{{ customLabel | translate }}</mat-label>
    <mat-label *ngIf="!customLabel">{{ ('enumLabels.' + translationKey | finprocessEnumTranslation)}} </mat-label>
    <mat-hint *ngIf="!!comment">{{ comment }}</mat-hint>

    <section [formGroup]="form" fxLayout="row wrap" fxLayout.lt-sm="column" class="mat-form-field-wrapper">
        <ng-container *ngFor="let entity of selectList">
            <mat-checkbox fxFlex="33.3%" fxFlex.lt-md="100" *ngIf="!!entity.value" [formControlName]="!!entity.value ? entity.value.toString() : ''">{{ entity.label }}
            </mat-checkbox>
        </ng-container>
    </section>
</div>