import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ISplittingRule } from '@ntag-ef/finprocess-calculations';
import { DocumentType } from '@ntag-ef/finprocess-enums/core';
import { Collateralization, ObjectPurposeType } from '@ntag-ef/finprocess-enums/finadvisory';
import { WaiterService } from '@ntag-ef/waiter';
import { sort } from 'fast-sort';
import { BehaviorSubject, Observable, combineLatest, forkJoin, iif, map, mergeMap, of, switchMap, take, tap } from 'rxjs';

import { AccountController, ActivityController, CustomerController, DebtorController, DocumentController, FileController, FinancingController, HouseholdController, LiabilityController, MasterDataController, MessageController, NewLiabilityController, RealEstateController, SignatureController, StatusEntryController, TaskController } from '../../controller';
import { EmailNotificationType, FinancingMapStatus, SystemType } from '../../enums';
import { IGlobalState, IImportFinancing, IListTuple } from '../../interfaces';
import { IActivityCluster, IActivityModel, ICustomerLightModel, ICustomerModel, IDebtorModel, IDocumentModel, IFileModel, IFinancingConfigurationModel, IFinancingMapLightModel, IFinancingMapModel, IHistoryModel, IHouseholdModel, ILiabilityModel, IMessageModel, INewLiabilityModel, IProductModel, IProductPackageModel, IProductPackageVM, IProductVM, IRealEstateModel, ISignatureModel, IStatusEntry, ITaskModel } from '../../models';
import { IPostDocumentFileRequest } from '../../requests';
import { IStatusResponse } from '../../responses';
import { SdkAddDebtor, SdkAddDocument, SdkAddFile, SdkAddHousehold, SdkAddLiability, SdkAddNewLiability, SdkAddRealEstate, SdkAddSignature, SdkAddTask, SdkDeleteDebtor, SdkDeleteDocument, SdkDeleteDocuments, SdkDeleteFile, SdkDeleteFiles, SdkDeleteHousehold, SdkDeleteLiability, SdkDeleteLiabilityByHousehold, SdkDeleteNewLiability, SdkDeleteNewLiabilityByHousehold, SdkDeleteRealEstate, SdkDeleteSignature, SdkDeleteSignatures, SdkDeleteTask, SdkPatchActivity, SdkPatchBankAccounts, SdkPatchBranches, SdkPatchCountries, SdkPatchCustomer, SdkPatchDebitRate, SdkPatchDebitRateAdaption, SdkPatchDebtor, SdkPatchDocument, SdkPatchFile, SdkPatchFinancingConfigurations, SdkPatchFinancingMap, SdkPatchHousehold, SdkPatchLegalisationFee, SdkPatchLiability, SdkPatchMorixRatingAssignments, SdkPatchNewLiability, SdkPatchRealEstate, SdkPatchSignature, SdkPatchTask, SdkPatchUser, SdkUpdateDebtor, SdkUpdateDocument, SdkUpdateFile, SdkUpdateFiles, SdkUpdateHousehold, SdkUpdateLiability, SdkUpdateNewLiability, SdkUpdateRealEstate, SdkUpdateSignature, SdkUpdateTask, UnLoadCustomer, UnLoadFinancing } from '../../statemanagement/actions';
import { IMasterDataStateModel } from '../../statemanagement/masterdata/masterdata.state';
import { CustomerState, DebtorState, DocumentState, FileState, FinancingMapState, HouseholdState, LiabilityState, MasterDataState, NewLiabilityState, RealEstateState, SignatureState, TaskState } from '../../statemanagement/states';
import { GlobalSettings } from '../../utils/global-settings';
import { FakeGenerator } from '../../utils/mock';
import { HelperService } from '../helper/helper.service';
import { ModelFactoryService } from '../model-factory/model-factory.service';
import { ValidationService } from '../validation/validation.service';

/**
 * handelt die komplette Verarbeitung von daten zwischen Backend (Hub, Controller) und Frontend (Store, temporär)
 */
@Injectable()
export class DataService {

    //#region Entity Ids

    public currentBranchIds$: BehaviorSubject<string[]>;
    public currentBranchesAsTuble$: Observable<IListTuple<string>[]>;

    public currentUserId: string | undefined;
    public currentCustomerId: string | undefined;
    public currentFinancingMapId: string | undefined;

    //#endregion

    //#region temporäre Datenlisten

    private currentCustomers: ICustomerLightModel[] | null;
    private customerSubject: BehaviorSubject<ICustomerLightModel[] | null>;
    private currentFinancings: IFinancingMapLightModel[] | null;
    private financingSubject: BehaviorSubject<IFinancingMapLightModel[] | null>;
    private currentMessages: IMessageModel[] | null;
    private messageSubject: BehaviorSubject<IMessageModel[] | null>;
    private currentHistory: IHistoryModel[] | null;
    private historySubject: BehaviorSubject<IHistoryModel[] | null>;
    private currentStatusEntries: IStatusEntry[] | null;
    private statusEntriesSubject: BehaviorSubject<IStatusEntry[] | null>;
    private currentProductPackages: IProductPackageModel[] | null;
    private productPackageSubject: BehaviorSubject<IProductPackageModel[] | null>;
    private currentProducts: IProductModel[] | null;
    private productSubject: BehaviorSubject<IProductModel[] | null>;

    //#endregion

    //#region Daten Observable

    /**
     * getter des Kunden Observable
     *
     * @returns {Observable<ICustomerLightModel[] | null>} Kunden Observable
     */
    public get customerObservable$(): Observable<ICustomerLightModel[] | null> {
        return this.customerSubject.asObservable(); // Wird dann im Template abhängig der Filter und Sortierung sortiert
    }

    /**
     * getter des Finanzierungs Observable
     *
     * @returns {Observable<IFinancingMapLightModel[] | null>} Finanzierungs Observable
     */
    public get financingObservable$(): Observable<IFinancingMapLightModel[] | null> {
        return this.financingSubject.asObservable()
            .pipe(
                map<IFinancingMapLightModel[] | null, IFinancingMapLightModel[] | null>(result => {
                    if (Array.isArray(result)) {
                        result = sort(result).desc(it => it.created);
                    }

                    return result;
                }),
            );
    }

    /**
     * getter des Nachrichten Observable
     *
     * @returns {Observable<IMessageModel[] | null>} Nachrichten Observable
     */
    public get messageObservable$(): Observable<IMessageModel[] | null> {
        return this.messageSubject.asObservable()
            .pipe(
                map<IMessageModel[] | null, IMessageModel[] | null>(result => {
                    if (Array.isArray(result)) {
                        result = sort(result).asc(it => it.created);
                    }

                    return result;
                }),
            );
    }

    /**
     * getter des History Observable
     *
     * @returns {Observable<IHistoryModel[] | null>} History Observable
     */
    public get historyObservable$(): Observable<IHistoryModel[] | null> {
        return this.historySubject.asObservable()
            .pipe(
                map<IHistoryModel[] | null, IHistoryModel[] | null>(result => {
                    if (Array.isArray(result)) {
                        result = sort(result).desc(it => it.created);
                    }

                    return result;
                }),
            );
    }

    /**
     * getter des StatusEntry Observable
     *
     * @returns {Observable<IStatusEntry[] | null>} StatusEntry Observable
     */
    public get statusEntryObservable$(): Observable<IStatusEntry[] | null> {
        return this.statusEntriesSubject.asObservable()
            .pipe(
                map<IStatusEntry[] | null, IStatusEntry[] | null>(result => {
                    if (Array.isArray(result)) {
                        result = sort(result).by([
                            { asc: s => s.financingMapId },
                            { asc: s => s.created },
                        ]);
                    }

                    return result;
                }),
            );
    }

    /**
     * Wandelt die Statuseinträge in ein Dictionary mit der FinanzierungsId als Key
     * 
     * @returns {Observable<Record<string, IStatusEntry[]> | null>} StatusEntry Observable als Dictionary
     */
    public get statusEntryAsDictionary$(): Observable<Record<string, IStatusEntry[]> | null> {
        return this.statusEntryObservable$
            .pipe(
                map<IStatusEntry[] | null, Record<string, IStatusEntry[]> | null>(datas => {
                    if (Array.isArray(datas)) {
                        const result: Record<string, IStatusEntry[]> = {};

                        const financingMapIds = HelperService.distinctSubValue<IStatusEntry, string>(datas, 'financingMapId');
                        for (const id of financingMapIds) {
                            result[id] = datas.filter(it => it.financingMapId === id);
                        }

                        return result;
                    }

                    return datas;
                }),
            );
    }

    /**
     * getter des ProduktPaket Observable
     *
     * @returns {Observable<IProductPackageModel[] | null>} IProductPackageModel Observable
     */
    public get productPackageObservable$(): Observable<IProductPackageModel[] | null> {
        return this.productPackageSubject
            .pipe(
                map(result => (Array.isArray(result) ? sort(result).asc(it => it.position) : result)),
            );
    }

    /**
     * getter des Produkt Observable
     *
     * @returns {Observable<IProductModel[] | null>} IProductModel Observable
     */
    public get productObservable$(): Observable<IProductModel[] | null> {
        return this.productSubject
            .pipe(
                map(result => (Array.isArray(result) ? sort(result).asc(it => it.position) : result)),
            );
    }

    /**
     * Getter des ProduktPaket ViewModel
     * 
     * @returns {Observable<IProductPackageVM>} IProductPackageVM Observable
     */
    public get productPackageVM$(): Observable<IProductPackageVM> {
        return combineLatest([
            this.productPackageObservable$,
            this.productObservable$,
            this.store.select(DocumentState.currentEsisDocument),
        ]).pipe(
            map(([productPackages, products, esisDocuments]) => {
                const productPackage = Array.isArray(productPackages) ? productPackages[0] : <IProductPackageModel>{ id: this.currentFinancingMapId, financingMapId: this.currentFinancingMapId };
                const productPackageProducts = Array.isArray(products) ? products.filter(product => product.productPackageId === productPackage.id) : [];
                const resultProducts: IProductVM[] = Array.isArray(productPackages) ?
                    productPackageProducts.map(product => ({ // neue Logik
                        ...product,
                        name: ` - ${product.name}`,
                        documents: esisDocuments.filter(({ productId }) => productId === product.id),
                    })) :
                    [{ // alte Logik
                        ...<IProductVM>{},
                        productPackageId: productPackage.id,
                        position: 0,
                        name: '- ESIS',
                        documents: esisDocuments,
                    }];

                return {
                    ...productPackage,
                    products: resultProducts,
                };
            }),
        )
    }

    //#endregion

    /**
     * Standard Konstruktor
     *
     * @param {Store} store Store Injector
     * @param {ValidationService} validation ValidationService Injector
     * @param {WaiterService} waiter WaiterService Injector
     * @param {AccountController} accountController AccountController injector
     * @param {CustomerController} customerController CustomerController injector
     * @param {FinancingController} financingController FinancingController injector
     * @param {RealEstateController} realEstateController RealEstateController injector
     * @param {HouseholdController} householdController HouseholdController injector
     * @param {DebtorController} debtorController DebtorController injector
     * @param {LiabilityController} liabilityController LiabilityController injector
     * @param {NewLiabilityController} newLiabilityController NewLiabilityController injector
     * @param {DocumentController} documentController DocumentController injector
     * @param {FileController} fileController FileController injector
     * @param {SignatureController} signatureController SignatureController injector
     * @param {MessageController} messageController MessageController injector
     * @param {ActivityController} activityController ActivityController injector
     * @param {TaskController} taskController TaskController injector
     * @param {StatusEntryController} statusEntryController StatusEntryController injector
     * @param {MasterDataController} masterDataController MasterDataController injector
     */
    public constructor(
        private store: Store,
        private validation: ValidationService,
        private waiter: WaiterService,
        private accountController: AccountController,
        private customerController: CustomerController,
        private financingController: FinancingController,
        private realEstateController: RealEstateController,
        private householdController: HouseholdController,
        private debtorController: DebtorController,
        private liabilityController: LiabilityController,
        private newLiabilityController: NewLiabilityController,
        private documentController: DocumentController,
        private fileController: FileController,
        private signatureController: SignatureController,
        private messageController: MessageController,
        private activityController: ActivityController,
        private taskController: TaskController,
        private statusEntryController: StatusEntryController,
        private masterDataController: MasterDataController,
    ) {
        this.currentCustomers = null;
        this.customerSubject = new BehaviorSubject<ICustomerLightModel[] | null>(this.currentCustomers);

        this.currentFinancings = null;
        this.financingSubject = new BehaviorSubject<IFinancingMapLightModel[] | null>(this.currentFinancings);

        this.currentMessages = null;
        this.messageSubject = new BehaviorSubject<IMessageModel[] | null>(this.currentMessages);

        this.currentHistory = null;
        this.historySubject = new BehaviorSubject<IHistoryModel[] | null>(this.currentHistory);

        this.currentStatusEntries = null;
        this.statusEntriesSubject = new BehaviorSubject<IStatusEntry[] | null>(this.currentStatusEntries);

        this.currentProductPackages = null;
        this.productPackageSubject = new BehaviorSubject<IProductPackageModel[] | null>(this.currentProductPackages);

        this.currentProducts = null;
        this.productSubject = new BehaviorSubject<IProductModel[] | null>(this.currentProducts);

        this.currentBranchIds$ = new BehaviorSubject<string[]>([]);

        this.currentBranchesAsTuble$ = combineLatest([
            this.currentBranchIds$,
            this.store.select(MasterDataState.branchesAsListTuple),
        ]).pipe(
            map(([branchIds, branches]) => branches.filter(({ value }) => !!value && branchIds.includes(value))),
            map(branches => sort(branches).asc(({ label }) => label)),
        );
    }

    //#region User

    /**
     * läd die komplette Kundenliste, sollte diese noch leer sein
     */
    public loadUser() {
        return new Promise<void>((resolve, reject) => {
            this.accountController.getEntity()
                .pipe(take(1))
                .subscribe({
                    next: user => {
                        this.store.dispatch(new SdkPatchUser(user));
                        resolve();
                    },
                    error: e => { reject(e); },
                })
        });
    }

    /**
     * entfernt alle geladenen Kunden aus der liste
     */
    public unloadUser() {
        this.store.dispatch(new SdkPatchUser(null));
    }

    //#endregion

    //#region Customer

    /**
     * läd die komplette Kundenliste unter berücksichtigung der Filter, Paging und sortiertung vom Backend
     */
    public refreshCustomerList() {
        if (GlobalSettings.userSettings.customerFilterConsultant === null) { // nur beim ersten mal laden
            GlobalSettings.userSettings.customerFilterConsultant = !!this.currentUserId ? [this.currentUserId] : [];
        }

        return new Promise<void>((resolve, reject) => {
            this.customerController.filter()
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        if (Array.isArray(result.data)) {
                            this.currentCustomers = result.data;
                            GlobalSettings.userSettings.customerPaging.totalResultCount = result.totalResultCount;
                            GlobalSettings.userSettings.customerFilterConsultMap = result.consultants;
                            this.customerSubject.next(this.currentCustomers);
                            resolve();
                        }
                    },
                    error: e => {
                        reject(e);
                    },
                });
        });
    }

    /**
     * entfernt alle geladenen Kunden aus der liste
     */
    public unloadCustomerList() {
        this.currentCustomers = null;
        this.customerSubject.next(this.currentCustomers);
    }

    /**
     * Löscht alle Daten des aktuellen Kunden aus dem Store
     */
    public unLoadCustomer() {
        this.store.dispatch(new UnLoadCustomer());
    }

    /**
     * Aktualisiert / Erstellt den Kunden im Store
     *
     * @param {ICustomerModel} data Kundendaten
     */
    public patchCustomer(data: ICustomerModel) {
        this.store.dispatch(new SdkPatchCustomer(data))
    }

    /**
     * Erstellt ein Kunden in der Kundenliste
     *
     * @param {ICustomerLightModel} data Kundendaten
     */
    public createCustomerLocal(data: ICustomerLightModel) {
        this.currentCustomers = Array.isArray(this.currentCustomers) ? [...this.currentCustomers, data] : [data];
        this.customerSubject.next(this.currentCustomers);
    }

    /**
     * Aktualisiert ein Kunden in der Kundenliste
     *
     * @param {ICustomerLightModel} data Kundendaten
     */
    public updateCustomerLocal(data: ICustomerLightModel | ICustomerModel) {
        if (Array.isArray(this.currentCustomers) && this.currentCustomers.some(({ id }) => id === data.id)) {

            if ('consultant' in data) {
                // ICustomerLightModel
                this.currentCustomers = this.currentCustomers.filter(({ id }) => id !== data.id).concat(data);
            }
            else {
                // ICustomerModel
                const localEntity = this.currentCustomers.find(({ id }) => id === data.id) as ICustomerLightModel;

                for (const prop in localEntity) {
                    if (prop in localEntity && prop in data) {
                        const property = prop as keyof ICustomerModel;
                        (localEntity as unknown as Record<string, unknown>)[prop] = data[property];
                    }
                }

                this.currentCustomers = this.currentCustomers.filter(({ id }) => id !== data.id).concat(localEntity);
            }

            this.customerSubject.next(this.currentCustomers);
        }
    }

    /**
     * Löscht einen Kunden im Backend und aktualisiert anschließend den Store
     *
     * @param {string} id id des Kunden
     */
    public deleteCustomer(id: string) {
        return new Promise<void>((resolve, reject) => {
            this.waiter.show();
            this.customerController.deleteEntity(id)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.deleteCustomerLocal(id);
                        this.unLoadCustomer();
                        this.waiter.hide();
                        resolve();
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    /**
     * Löscht ein Kunden in der Kundenliste
     *
     * @param {string} entityId id des Kunden
     */
    public deleteCustomerLocal(entityId: string) {
        if (Array.isArray(this.currentCustomers) && this.currentCustomers.some(({ id }) => id === entityId)) {
            this.currentCustomers = this.currentCustomers.filter(({ id }) => id !== entityId);
            this.customerSubject.next(this.currentCustomers);
        }
    }

    /**
     * prüft ob bereits ein Kunde mit der id geladen wurde
     * 
     * @param {string} customerId id des Kunden
     * @returns {boolean} es exisitert mind. ein Kunde
     */
    public existCustomer(customerId: string): boolean {
        return !!customerId && Array.isArray(this.currentCustomers) && this.currentCustomers.some(({ id }) => customerId === id);
    }

    /**
     * Prüft ob mindestens ein Kunde des übergebenen Nutzers vorhanden ist
     * 
     * @param {string} userId id des Nutzers
     * @returns {boolean} es exisitert mind. ein Kunde
     */
    public existCustomerForUser(userId: string): boolean {
        return !!userId && Array.isArray(this.currentCustomers) && this.currentCustomers.some(({ consultantId }) => consultantId === userId);
    }

    /**
     * Speichert einen Kunden im Backend und aktualisiert anschließend den Store
     *
     * @param {ICustomerModel} data Kundendaten
     * @returns {Promise} id der neuen Entität
     */
    public createCustomer(data: Partial<ICustomerModel>): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.waiter.show();
            this.customerController.createEntity(data)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        this.patchCustomer(result);
                        this.waiter.hide();
                        resolve(result.id);
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    /**
     * Updatedet einen Kunden im Backend und aktualisiert anschließend den Store
     *
     * @param {ICustomerModel} data Kundendaten
     */
    public updateCustomer(data: Partial<ICustomerModel>) {
        return new Promise<void>((resolve, reject) => {
            this.waiter.show();

            const local = this.store.selectSnapshot(CustomerState.current);
            const toSave = Object.assign({}, local ?? {}, data);

            this.customerController.updateEntity(toSave)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        this.patchCustomer(result);
                        this.waiter.hide();
                        resolve();
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    //#endregion

    //#region FinancingMap

    /**
     * läd alle dem Kunden zugewiesenen Finanzierungen
     *
     * @param {string} customerId Id des Kunden
     * @param {boolean} force erzwingen des neuladens der Liste
     * @returns {Promise} abschluss promise
     */
    public loadFinancingMapList(customerId: string, force?: boolean): Promise<void> {
        if (!!force || !Array.isArray(this.currentFinancings) || this.currentFinancings.length === 0) {
            return new Promise<void>((resolve, reject) => {
                this.financingController.getFinancingMapsByCustomer(customerId)
                    .pipe(take(1))
                    .subscribe({
                        next: financings => {
                            if (Array.isArray(financings)) {
                                if (financings.every(({ customerId: cId }) => cId?.toLowerCase() === this.currentCustomerId?.toLowerCase())) {
                                    this.currentFinancings = financings;
                                    this.financingSubject.next(this.currentFinancings);
                                }
                                resolve();
                            }
                            else {
                                reject(new Error('No financing loaded'));
                            }
                        },
                        error: e => {
                            reject(e);
                        },
                    });
            });
        }
        else {
            return Promise.resolve();
        }
    }


    /**
     * läd die aktuelle Finanzierungen (sofern geöffnet) aus der liste neu vom Backend
     *
     * @returns {Promise} abschluss promise
     */
    public refreshCurrentFinancingMapListItem(): Promise<void> {
        const financingMapId = this.store.selectSnapshot(FinancingMapState.currentId);
        return !!financingMapId ? this.refreshFinancingMapListItem(financingMapId) : Promise.resolve();
    }

    /**
     * läd eine Finanzierungen aus der liste neu vom Backend
     *
     * @param {string} financingMapId Id der Finanzierung
     * @returns {Promise} abschluss promise
     */
    public refreshFinancingMapListItem(financingMapId: string): Promise<void> {

        if (Array.isArray(this.currentFinancings) && this.currentFinancings.some(({ id }) => id === financingMapId)) {
            return new Promise<void>((resolve, reject) => {
                this.financingController.getEntity<IFinancingMapLightModel>(financingMapId, true)
                    .pipe(take(1))
                    .subscribe({
                        next: financing => {
                            this.updateFinancingLocal(financing);
                            resolve();
                        },
                        error: e => {
                            reject(e);
                        },
                    });
            });
        }
        else {
            return Promise.resolve();
        }
    }

    /**
     * entfernt alle geladenen Finanzierungen aus der Finanzierungsliste
     */
    public unloadFinancingMapList() {
        this.currentFinancings = null;
        this.financingSubject.next(this.currentFinancings);
    }

    /**
     * Löscht alle Daten der aktuellen Finanzierung aus dem Store
     */
    public unLoadFinancing() {
        this.store.dispatch(new UnLoadFinancing());
    }

    /**
     * Aktualisiert / Erstellt die Finanzierung im Store
     *
     * @param {IFinancingMapModel} data Finanzierungsdaten
     */
    public patchFinancing(data: IFinancingMapModel) {
        this.store.dispatch(new SdkPatchFinancingMap(data));
    }

    /**
     * Prüft ob mindestens eine Finanzierung des übergebenen Kunden vorhanden ist
     * 
     * @param {string} id id des Kunden
     * @returns {boolean} es exisitert mind. eine Finanzierung
     */
    public existFinancingForCustomer(id: string): boolean {
        return !!id && Array.isArray(this.currentFinancings) && this.currentFinancings.some(({ customerId }) => customerId === id);
    }

    /**
     * Erstellt eine Finanzierung in der Finanzierungsliste
     *
     * @param {IFinancingMapLightModel} data Finanzierungsdaten
     */
    public createFinancingLocal(data: IFinancingMapLightModel) {
        this.currentFinancings = Array.isArray(this.currentFinancings) ? [...this.currentFinancings, data] : [data];
        this.financingSubject.next(this.currentFinancings);
    }

    /**
     * Aktualisiert eine Finanzierung in der Finanzierungsliste
     *
     * @param {IFinancingMapLightModel} data Finanzierungsdaten
     */
    public updateFinancingLocal(data: IFinancingMapLightModel) {
        if (Array.isArray(this.currentFinancings) && this.currentFinancings.some(({ id }) => id === data.id)) {
            this.currentFinancings = this.currentFinancings.filter(({ id }) => id !== data.id).concat(data);
            this.financingSubject.next(this.currentFinancings);
        }
    }

    /**
     * Löscht eine Finanzierung in der Finanzierungsliste
     *
     * @param {string} entityId id der Finanzierung
     */
    public deleteFinancingLocal(entityId: string) {
        if (Array.isArray(this.currentFinancings) && this.currentFinancings.some(({ id }) => id === entityId)) {
            this.currentFinancings = this.currentFinancings.filter(({ id }) => id !== entityId);
            this.financingSubject.next(this.currentFinancings);
        }
    }

    /**
     * kopiert eine Finanzierung im Backend an und aktualisiert anschließend den Store
     *
     * @param {string} id id der Finanzierung
     */
    public copyFinancingMap(id: string) {
        return new Promise<void>((resolve, reject) => {
            this.waiter.show();
            this.financingController.copyFinancingMapFull(id)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        const customerId = this.store.selectSnapshot(CustomerState.currentId);
                        this.loadFinancingMapList(customerId, true) // Neu Laden der Liste
                            .then(() => {
                                this.waiter.hide();
                                resolve();
                            })
                            .catch(e => {
                                this.waiter.hide();
                                reject(e);
                            });
                    },
                    error: e => {
                        reject(e)
                    },
                });
        });
    }

    /**
     * legt eine komplette neue Fianzierung im Backend an und aktualisiert anschließend den Store
     */
    public createNewFinancingMap() {
        return new Promise<void>((resolve, reject) => {
            const customer = this.store.selectSnapshot(CustomerState.current);

            if (!!customer) {
                const financingMap = ModelFactoryService.createFinancingMapModel(customer.id);
                this.financingController.createEntity(financingMap)
                    .pipe(
                        mergeMap(financingMapResult => {
                            const realEstate = ModelFactoryService.createRealEstateModel(financingMapResult.id);
                            realEstate.objectPurpose = ObjectPurposeType.Finance;
                            realEstate.collateralization = Collateralization.NewFinancing;

                            const household = ModelFactoryService.createHouseholdModel(financingMapResult.id);

                            return forkJoin([
                                this.householdController.createEntity(household),
                                this.realEstateController.createEntity(realEstate),
                            ]);
                        }),
                        mergeMap(([household]) => {
                            const debtor = ModelFactoryService.createDebtorModel(household.id, customer);
                            return this.debtorController.createEntity(debtor);
                        }),
                    )
                    .subscribe({
                        next: () => {
                            this.loadFinancingMapList(customer.id, true) // Neu Laden der Liste
                                .then(resolve)
                                .catch(reject);
                        },
                        error: e => {
                            reject(e)
                        },
                    });
            }
            else {
                reject(new Error('no customer selected'));
            }
        });
    }

    /**
     * legt eine komplette neue Fianzierung mit dummydaten im Backend an und aktualisiert anschließend den Store
     */
    public createFakeFinancingMap() {
        return new Promise<void>((resolve, reject) => {
            const customer = this.store.selectSnapshot(CustomerState.current);
            const bankAccounts = this.store.selectSnapshot(MasterDataState.bankAccounts);

            if (!!customer) {
                const financingMap = FakeGenerator.generateFakeFinancingMap(customer.id);
                this.financingController.createEntity(financingMap)
                    .pipe(
                        mergeMap(financingMapResult => {
                            const realEstate = FakeGenerator.generateFakeRealEstate(financingMapResult.id);
                            realEstate.objectPurpose = ObjectPurposeType.Finance;
                            realEstate.collateralization = Collateralization.NewFinancing;

                            const household = FakeGenerator.generateFakeHousehold(financingMapResult.id);

                            const privacyStatement = FakeGenerator.generateFakeDocument('DSE.png', 'financingMapId', financingMapResult.id, DocumentType.PrivacyStatementSignature);
                            privacyStatement.file.isSigned = true;

                            return forkJoin([
                                this.householdController.createEntity(household),
                                this.realEstateController.createEntity(realEstate),
                                this.fileController.createEntity(privacyStatement),
                            ]);
                        }),
                        mergeMap(([household, realEstate]) => {
                            const debtor = FakeGenerator.generateFakeDebitor(household.id, customer, bankAccounts);
                            const floorPlan = FakeGenerator.generateFakeDocument('Wohnungsplan.png', 'realEstateId', realEstate.id, DocumentType.FloorPlan);

                            return forkJoin([
                                this.debtorController.createEntity(debtor),
                                this.realEstateController.updateEntity(realEstate, true), // Marktwert updaten
                                this.fileController.createEntity(floorPlan),
                            ]);
                        }),
                        mergeMap(([debtor]) => {
                            const photoId = FakeGenerator.generateFakeDocument('Ausweis.png', 'debtorId', debtor.id, DocumentType.PhotoId);
                            const salary = FakeGenerator.generateFakeDocument('Gehaltszettel.png', 'debtorId', debtor.id, DocumentType.SalaryStatement);

                            return forkJoin([
                                this.fileController.createEntity(photoId),
                                this.fileController.createEntity(salary),
                            ]);
                        }),
                    )
                    .subscribe({
                        next: () => {
                            this.loadFinancingMapList(customer.id, true) // Neu Laden der Liste
                                .then(resolve)
                                .catch(reject);
                        },
                        error: e => {
                            reject(e)
                        },
                    });
            }
            else {
                reject(new Error('no customer selected'));
            }
        });
    }

    /**
     * legt eine neue Fianzierung mit den übergebenen Daten an
     *
     * @param {IImportFinancing} importData daten der zu importierenden Finanzierung
     */
    public importFinancingMap(importData: IImportFinancing) {
        return new Promise<void>((resolve, reject) => {
            const customer = this.store.selectSnapshot(CustomerState.current);

            if (!!customer) {
                importData.financing.customerId = customer.id;


                this.financingController.createEntity(importData.financing)
                    .pipe(
                        mergeMap(() => {
                            const requests: Observable<unknown>[] = [];

                            for (const household of importData.households) {
                                requests.push(this.householdController.createEntity(household));
                            }

                            for (const realEstate of importData.realestates) {
                                requests.push(
                                    this.realEstateController.createEntity(realEstate)
                                        .pipe(mergeMap(re => this.realEstateController.updateEntity(re, true))),
                                );
                            }

                            return forkJoin(requests);
                        }),
                        mergeMap(() => {
                            const requests: Observable<unknown>[] = [];

                            for (const liability of importData.liabilities) {
                                requests.push(this.liabilityController.createEntity(liability));
                            }

                            for (const newliability of importData.newliabilities) {
                                requests.push(this.newLiabilityController.createEntity(newliability));
                            }

                            for (const debtor of importData.debtors) {
                                requests.push(this.debtorController.createEntity(debtor));
                            }

                            return forkJoin(requests);
                        }),
                    )
                    .subscribe({
                        next: () => {
                            this.loadFinancingMapList(customer.id, true) // Neu Laden der Liste
                                .then(resolve)
                                .catch(reject);
                        },
                        error: e => {
                            reject(e)
                        },
                    });
            }
            else {
                reject(new Error('no customer selected'));
            }
        });
    }

    /**
     * Updated eine Finanzierung im Backend und aktualisiert anschließend den Store
     *
     * @param {IFinancingMapModel} data Finanzierungsdaten
     */
    public updateFinancing(data: Partial<IFinancingMapModel>) {
        return new Promise<void>((resolve, reject) => {

            const local = this.store.selectSnapshot(FinancingMapState.current);
            const toSave = Object.assign({}, local ?? {}, data);

            this.financingController.updateEntity(toSave)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        this.patchFinancing(result);
                        this.refreshFinancingMapListItem(result.id).catch(e => { throw e; });
                        resolve();
                    },
                    error: e => {
                        reject(e);
                    },
                });
        });
    }

    /**
     * Löscht eine Finanzierung im Backend und aktualisiert anschließend den Store
     *
     * @param {string} id id der Finanzierung
     */
    public deleteFinancing(id: string) {
        return new Promise<void>((resolve, reject) => {
            this.waiter.show();
            this.financingController.deleteEntity(id)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.deleteFinancingLocal(id);
                        this.unLoadFinancing();
                        this.waiter.hide();
                        resolve();
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    /**
     * bereitet die Finanzierung für die Einreichung vor. Speichert sie noch einmal und löscht leere Objekte
     *
     * @param {IFinancingMapModel} financing Finanzierung welche evt noch gespeichert werden muss
     * @returns {Observable} anschluss Observable
     */
    public prepareFinancingForSubmit(financing?: Partial<IFinancingMapModel>): Observable<void> {

        const liabilityRequestArray: Array<{ id: string, obs: Observable<void> }> = [];
        const newLiabilityRequestArray: Array<{ id: string, obs: Observable<void> }> = [];

        const liabilities = this.store.selectSnapshot(LiabilityState.current);
        const newLiabilities = this.store.selectSnapshot(NewLiabilityState.current);

        for (const liability of liabilities) {
            if (!HelperService.isLiabilityDirty(liability)) {
                liabilityRequestArray.push({ id: liability.id, obs: this.liabilityController.deleteEntity(liability.id) });
            }
        }

        for (const newLiability of newLiabilities) {
            if (!HelperService.isNewLiabilityDirty(newLiability)) {
                newLiabilityRequestArray.push({ id: newLiability.id, obs: this.newLiabilityController.deleteEntity(newLiability.id) });
            }
        }

        const requests: Observable<IFinancingMapModel | void>[] = [];

        if (!!financing) {
            const local = this.store.selectSnapshot(FinancingMapState.current);
            const toSave = Object.assign({}, local ?? {}, financing);

            requests.push(this.financingController.updateEntity(toSave));
        }

        for (const r of liabilityRequestArray) {
            requests.push(r.obs);
        }

        for (const r of newLiabilityRequestArray) {
            requests.push(r.obs);
        }

        return forkJoin(requests).pipe(
            take(1),
            mergeMap(() => {
                for (const r of liabilityRequestArray) {
                    this.deleteLiabilityLocal(r.id);
                }

                for (const r of newLiabilityRequestArray) {
                    this.deleteNewLiabilityLocal(r.id);
                }

                return of(void 0);
            }),
        );
    }

    /**
     * verarbeitet den Status und läd evt Dokumente nach
     *
     * @param {IStatusResponse} response der neue Status
     */
    public handleStatusResponse(response: IStatusResponse): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const local = this.store.selectSnapshot(FinancingMapState.current);
            if (!!local && response.id === local.id && (response.status !== local.status || response.statusInfo !== local.statusInfo)) {
                this.financingController.getEntity<IFinancingMapModel>(response.id)
                    .pipe(take(1))
                    .subscribe({
                        next: data => {
                            if (response.id === data.id) {
                                if (HelperService.hasValue(data.status) && [
                                    FinancingMapStatus.HouseholdCalculationRequested, // Einreichung, um SendToVPC in den Files zu updaten
                                    FinancingMapStatus.HouseholdCalculationExist, // HRR abrufen
                                    FinancingMapStatus.SampleCalculationRequested, // HRR Akzeptieren, um SendToVPC in den nachzureichenden Files zu updaten
                                    FinancingMapStatus.SampleCalculationExists, // Rechenbeispiel abrufen
                                    FinancingMapStatus.EsisRequested, // RB Akzeptieren, um SendToVPC in den nachzureichenden Files zu updaten
                                    FinancingMapStatus.EsisExists, // Esis abrufen
                                    FinancingMapStatus.VpcRejected, // Anfrage beim RB abgelehnt, abruf Ablehnungsschreiben
                                ].includes(data.status)) {
                                    this.updateDocumentsAndFiles(data.id)
                                        .then(() => ((data.status === FinancingMapStatus.EsisExists) ? this.loadProductPackages(data.id) : Promise.resolve()))
                                        .then(() => this.refreshFinancingMapListItem(data.id))
                                        .then(() => this.patchFinancing(data))
                                        .then(resolve)
                                        .catch(e => { throw e; });
                                }
                                else {
                                    this.refreshFinancingMapListItem(data.id)
                                        .then(() => this.patchFinancing(data))
                                        .then(resolve)
                                        .catch(e => { throw e; });
                                }
                            }
                            else {
                                resolve();
                            }
                        },
                        error: e => { reject(e); },
                    });
            }
            else {
                resolve();
            }
        });
    }
    /**
     * ermittelt financingMap by ID
     *
     * @param {string} mapId financingMap id
     * @returns {IFinancingMapLightModel | undefined} Financing Map Light oder undefined
     */
    public getFinancing(mapId: string): IFinancingMapLightModel | undefined {
        return this.currentFinancings?.find(({ id }) => id === mapId);
    }
    //#endregion

    //#region RealEstate

    /**
     * Überschreibt die Objekte im Store
     *
     * @param {IRealEstateModel} data Objektdaten
     */
    public patchRealEstates(data: IRealEstateModel[]) {
        this.store.dispatch(new SdkPatchRealEstate(data));
    }

    /**
     * Erstellt ein Objekt im Store
     *
     * @param {IRealEstateModel} data Objektdaten
     */
    public createRealEstateLocal(data: IRealEstateModel) {
        if (this.store.selectSnapshot(FinancingMapState.currentId) === data.financingMapId) {
            this.store.dispatch(new SdkAddRealEstate(data));
        }
    }

    /**
     * Aktualisiert ein Objekt im Store
     *
     * @param {IRealEstateModel} data Objektdaten
     */
    public updateRealEstateLocal(data: IRealEstateModel) {
        this.store.dispatch(new SdkUpdateRealEstate(data));
    }

    /**
     * Löscht ein Objekt im Store
     *
     * @param {string} realEstateId id des Objektes
     */
    public deleteRealEstateLocal(realEstateId: string) {
        const localDocuments = this.store.selectSnapshot(DocumentState.documentsByAnyKindOfIds)(undefined, [realEstateId]);
        this.store.dispatch(new SdkDeleteDocuments(localDocuments.map(({ id }) => id)));

        this.store.dispatch(new SdkDeleteRealEstate(realEstateId));
    }

    /**
     * Speichert ein Objekt im Backend und aktualisiert anschließend den Store
     *
     * @param {IRealEstateModel} data Objektdaten
     * @returns {Promise} id der neuen Entität
     */
    public createRealEstate(data: Partial<IRealEstateModel>): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.waiter.show();
            this.realEstateController.createEntity(data)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        this.refreshFinancingMapListItem(result.financingMapId).catch(e => { throw e; });
                        this.createRealEstateLocal(result);
                        this.waiter.hide();
                        resolve(result.id);
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    /**
     * Updated ein Objekt im Backend und aktualisiert anschließend den Store
     *
     * @param {IRealEstateModel} data Objektdaten
     */
    public updateRealEstate(data: Partial<IRealEstateModel>) {
        return new Promise<void>((resolve, reject) => {

            const local = this.store.selectSnapshot(RealEstateState.byId)(data.id ?? '');
            const toSave = Object.assign({}, local ?? {}, data);
            const isRealEstateValid = this.validation.isPartialRealestateValid(toSave);

            this.realEstateController.updateEntity(toSave, isRealEstateValid)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        this.refreshFinancingMapListItem(result.financingMapId).catch(e => { throw e; });
                        this.updateRealEstateLocal(result);
                        resolve();
                    },
                    error: e => {
                        reject(e);
                    },
                });
        });
    }

    /**
     * Löscht ein Objekt im Backend und aktualisiert anschließend den Store
     *
     * @param {string} id id des Objekts
     */
    public deleteRealEstate(id: string) {
        return new Promise<void>((resolve, reject) => {
            this.waiter.show();
            this.realEstateController.deleteEntity(id)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.deleteRealEstateLocal(id);
                        this.waiter.hide();
                        resolve();
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    //#endregion

    //#region Household

    /**
     * Überschreibt die Haushalte im Store
     *
     * @param {IHouseholdModel} data Haushaltdaten
     */
    public patchHouseholds(data: IHouseholdModel[]) {
        this.store.dispatch(new SdkPatchHousehold(data));
    }

    /**
     * Erstellt ein Haushalt im Store
     *
     * @param {IHouseholdModel} data Haushaltdaten
     */
    public createHouseholdLocal(data: IHouseholdModel) {
        if (this.store.selectSnapshot(FinancingMapState.currentId) === data.financingMapId) {
            this.store.dispatch(new SdkAddHousehold(data));
        }
    }

    /**
     * Aktualisiert ein Haushalt im Store
     *
     * @param {IHouseholdModel} data Haushaltdaten
     */
    public updateHouseholdLocal(data: IHouseholdModel) {
        this.store.dispatch(new SdkUpdateHousehold(data));
    }

    /**
     * Löscht ein Haushalt im Store
     *
     * @param {string} housholdId id des Haushalts
     */
    public deleteHouseholdLocal(housholdId: string) {
        const localDebtors = this.store.selectSnapshot(DebtorState.currentByHousholdIds)([housholdId]);
        const localDocuments = this.store.selectSnapshot(DocumentState.documentsByAnyKindOfIds)(undefined, undefined, [housholdId], localDebtors.map(({ id }) => id));
        this.store.dispatch(new SdkDeleteDocuments(localDocuments.map(({ id }) => id)));
        const localSignatures = this.store.selectSnapshot(SignatureState.byDebtorIds)(localDebtors.map(({ id }) => id));
        this.store.dispatch(new SdkDeleteSignatures(localSignatures.map(({ id }) => id)));
        this.store.dispatch(new SdkDeleteLiabilityByHousehold(housholdId));
        this.store.dispatch(new SdkDeleteNewLiabilityByHousehold(housholdId));

        this.store.dispatch(new SdkDeleteHousehold(housholdId));
    }

    /**
     * Speichert ein Haushalt im Backend und aktualisiert anschließend den Store
     *
     * @param {IHouseholdModel} data Haushaltdaten
     * @returns {Promise} id der neuen Entität
     */
    public createHousehold(data: Partial<IHouseholdModel>): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.waiter.show();
            this.householdController.createEntity(data)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        this.refreshFinancingMapListItem(result.financingMapId).catch(e => { throw e; });
                        this.createHouseholdLocal(result);
                        this.waiter.hide();
                        resolve(result.id);
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    /**
     * Updated ein Haushalt im Backend und aktualisiert anschließend den Store
     *
     * @param {IHouseholdModel} data Haushaltdaten
     */
    public updateHousehold(data: Partial<IHouseholdModel>) {
        return new Promise<void>((resolve, reject) => {

            const local = this.store.selectSnapshot(HouseholdState.currentById)(data.id ?? '');
            const toSave = Object.assign({}, local ?? {}, data);

            this.householdController.updateEntity(toSave)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        this.refreshFinancingMapListItem(result.financingMapId).catch(e => { throw e; });
                        this.updateHouseholdLocal(result);
                        resolve();
                    },
                    error: e => {
                        reject(e);
                    },
                });
        });
    }

    /**
     * Löscht ein Haushalt im Backend und aktualisiert anschließend den Store
     *
     * @param {string} id id des Haushalts
     */
    public deleteHousehold(id: string) {
        return new Promise<void>((resolve, reject) => {
            this.waiter.show();
            this.householdController.deleteEntity(id)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.deleteHouseholdLocal(id);
                        this.waiter.hide();
                        resolve();
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    //#endregion

    //#region Debtor

    /**
     * Überschreibt die Kreditnehmer im Store
     *
     * @param {IHouseholdModel} data Kreditnehmerdaten
     */
    public patchDebtors(data: IDebtorModel[]) {
        this.store.dispatch(new SdkPatchDebtor(data));
    }

    /**
     * Erstellt ein Kreditnehmer im Store
     *
     * @param {IDebtorModel} data Kreditnehmerdaten
     */
    public createDebtorLocal(data: IDebtorModel) {
        if (!!this.store.selectSnapshot(HouseholdState.currentById)(data.householdId)) {
            this.store.dispatch(new SdkAddDebtor(data));
        }
    }

    /**
     * Aktualisiert ein Kreditnehmer im Store
     *
     * @param {IDebtorModel} data Kreditnehmerdaten
     */
    public updateDebtorLocal(data: IDebtorModel) {
        this.store.dispatch(new SdkUpdateDebtor(data));
    }

    /**
     * Löscht ein Kreditnehmer im Store
     *
     * @param {string} debtorId id des Kreditnehmers
     */
    public deleteDebtorLocal(debtorId: string) {
        const localDocuments = this.store.selectSnapshot(DocumentState.documentsByAnyKindOfIds)(undefined, undefined, undefined, [debtorId]);
        this.store.dispatch(new SdkDeleteDocuments(localDocuments.map(({ id }) => id)));
        const localSignatures = this.store.selectSnapshot(SignatureState.byDebtorIds)([debtorId]);
        this.store.dispatch(new SdkDeleteSignatures(localSignatures.map(({ id }) => id)));

        this.store.dispatch(new SdkDeleteDebtor(debtorId));
    }

    /**
     * Speichert ein Kreditnehmer im Backend und aktualisiert anschließend den Store
     *
     * @param {IDebtorModel} data Kreditnehmerdaten
     * @returns {Promise} id der neuen Entität
     */
    public createDebtor(data: Partial<IDebtorModel>): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.waiter.show();
            this.debtorController.createEntity(data)
                .pipe(
                    take(1),
                    tap(() => {
                        // löschen von Unterschriftsdokumenten mit allen Kreditnehmern
                        const dse = this.store.selectSnapshot(DocumentState.documentsByTypes)([DocumentType.PrivacyStatementSignature]);
                        if (dse.length > 0) {
                            const promises = dse.map(({ id }) => this.deleteDocument(id));
                            Promise.all(promises).catch(e => { throw e; });
                        }
                    }),
                )
                .subscribe({
                    next: result => {
                        this.refreshCurrentFinancingMapListItem().catch(e => { throw e; });
                        this.createDebtorLocal(result);
                        this.waiter.hide();
                        resolve(result.id);
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    /**
     * Updated ein Kreditnehmer im Backend und aktualisiert anschließend den Store
     *
     * @param {IDebtorModel} data Kreditnehmerdaten
     */
    public updateDebtor(data: Partial<IDebtorModel>) {
        return new Promise<void>((resolve, reject) => {

            const local = this.store.selectSnapshot(DebtorState.currentById)(data.id ?? '');
            const toSave = Object.assign({}, local ?? {}, data);

            this.debtorController.updateEntity(toSave)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        this.refreshCurrentFinancingMapListItem().catch(e => { throw e; });
                        this.updateDebtorLocal(result);
                        resolve();
                    },
                    error: e => {
                        reject(e);
                    },
                });
        });
    }

    /**
     * Löscht ein Kreditnehmer im Backend und aktualisiert anschließend den Store
     *
     * @param {string} id id des Kreditnehmers
     */
    public deleteDebtor(id: string) {
        return new Promise<void>((resolve, reject) => {
            this.waiter.show();
            this.debtorController.deleteEntity(id)
                .pipe(
                    take(1),
                    tap(() => {
                        // löschen von Unterschriftsdokumenten mit allen Kreditnehmern
                        const dse = this.store.selectSnapshot(DocumentState.documentsByTypes)([DocumentType.PrivacyStatementSignature]);
                        if (dse.length > 0) {
                            const promises = dse.map(({ id: documentId }) => this.deleteDocument(documentId));
                            Promise.all(promises).catch(e => { throw e; });
                        }
                    }),
                )
                .subscribe({
                    next: () => {
                        this.deleteDebtorLocal(id);
                        this.waiter.hide();
                        resolve();
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    //#endregion

    //#region Liability

    /**
     * Überschreibt die Bestandskredite im Store
     *
     * @param {ILiabilityModel} data Bestandskreditdaten
     */
    public patchLiabilites(data: ILiabilityModel[]) {
        this.store.dispatch(new SdkPatchLiability(data));
    }

    /**
     * Erstellt ein Bestandskredit im Store
     *
     * @param {ILiabilityModel} data Bestandskreditdaten
     */
    public createLiabilityLocal(data: ILiabilityModel) {
        if (!!this.store.selectSnapshot(HouseholdState.currentById)(data.householdId)) {
            this.store.dispatch(new SdkAddLiability(data));
        }
    }

    /**
     * Aktualisiert ein Bestandskredit im Store
     *
     * @param {ILiabilityModel} data Bestandskreditdaten
     */
    public updateLiabilityLocal(data: ILiabilityModel) {
        this.store.dispatch(new SdkUpdateLiability(data));
    }

    /**
     * Löscht ein Bestandskredit im Store
     *
     * @param {string} id id des Bestandskredits
     */
    public deleteLiabilityLocal(id: string) {
        this.store.dispatch(new SdkDeleteLiability(id));
    }

    /**
     * Speichert ein Bestandskredit im Backend und aktualisiert anschließend den Store
     *
     * @param {ILiabilityModel} data Bestandskreditdaten
     * @returns {Promise} id der neuen Entität
     */
    public createLiability(data: Partial<ILiabilityModel>): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            // Set a timeout to show the waiter after 1 second
            const showWaiterTimeout = setTimeout(() => {
                this.waiter.show();
            }, GlobalSettings.waiterDelayLong);
            this.liabilityController.createEntity(data)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        // Clear the timeout if the request completes before 1 second
                        if (showWaiterTimeout) {
                            clearTimeout(showWaiterTimeout);
                        }
                        this.refreshCurrentFinancingMapListItem().catch(e => { throw e; });
                        this.createLiabilityLocal(result);
                        this.waiter.hide();
                        resolve(result.id);
                    },
                    error: e => {
                        // Clear the timeout if there's an error
                        if (showWaiterTimeout) {
                            clearTimeout(showWaiterTimeout);
                        }
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    /**
     * Updated ein Bestandskredit im Backend und aktualisiert anschließend den Store
     *
     * @param {ILiabilityModel} data Bestandskreditdaten
     */
    public updateLiability(data: Partial<ILiabilityModel>) {
        return new Promise<void>((resolve, reject) => {

            const local = this.store.selectSnapshot(LiabilityState.currentById)(data.id ?? '');
            const toSave = Object.assign({}, local ?? {}, data);

            this.liabilityController.updateEntity(toSave)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        this.refreshCurrentFinancingMapListItem().catch(e => { throw e; });
                        this.updateLiabilityLocal(result);
                        resolve();
                    },
                    error: e => {
                        reject(e);
                    },
                });
        });
    }

    /**
     * Löscht ein Bestandskredit im Backend und aktualisiert anschließend den Store
     *
     * @param {string} id id des Bestandskredits
     */
    public deleteLiability(id: string) {
        return new Promise<void>((resolve, reject) => {
            // Set a timeout to show the waiter after 1 second
            const showWaiterTimeout = setTimeout(() => {
                this.waiter.show();
            }, GlobalSettings.waiterDelayLong);
            this.liabilityController.deleteEntity(id)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        // Clear the timeout if the request completes before 1 second
                        if (showWaiterTimeout) {
                            clearTimeout(showWaiterTimeout);
                        }
                        this.deleteLiabilityLocal(id);
                        this.waiter.hide();
                        resolve();
                    },
                    error: e => {
                        // Clear the timeout if there's an error
                        if (showWaiterTimeout) {
                            clearTimeout(showWaiterTimeout);
                        }
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    //#endregion

    //#region NewLiability

    /**
     * Überschreibt die neue Verpflichtung im Store
     *
     * @param {INewLiabilityModel} data neue Verpflichtungsdaten
     */
    public patchNewLiabilites(data: INewLiabilityModel[]) {
        this.store.dispatch(new SdkPatchNewLiability(data));
    }

    /**
     * Erstellt eine neue Verpflichtung im Store
     *
     * @param {INewLiabilityModel} data neue Verpflichtungsdaten
     */
    public createNewLiabilityLocal(data: INewLiabilityModel) {
        if (!!this.store.selectSnapshot(HouseholdState.currentById)(data.householdId)) {
            this.store.dispatch(new SdkAddNewLiability(data));
        }
    }

    /**
     * Aktualisiert eine neue Verpflichtung im Store
     *
     * @param {INewLiabilityModel} data neue Verpflichtungdaten
     */
    public updateNewLiabilityLocal(data: INewLiabilityModel) {
        this.store.dispatch(new SdkUpdateNewLiability(data));
    }

    /**
     * Löscht eine neue Verpflichtung im Store
     *
     * @param {string} id id des neue Verpflichtungdaten
     */
    public deleteNewLiabilityLocal(id: string) {
        this.store.dispatch(new SdkDeleteNewLiability(id));
    }

    /**
     * Speichert eine neue Verpflichtung im Backend und aktualisiert anschließend den Store
     *
     * @param {INewLiabilityModel} data neue Verpflichtungdaten
     * @returns {Promise} id der neuen Entität
     */
    public createNewLiability(data: Partial<INewLiabilityModel>): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            // Set a timeout to show the waiter after 1 second
            const showWaiterTimeout = setTimeout(() => {
                this.waiter.show();
            }, GlobalSettings.waiterDelayLong);
            this.newLiabilityController.createEntity(data)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        // Clear the timeout if the request completes before 1 second
                        if (showWaiterTimeout) {
                            clearTimeout(showWaiterTimeout);
                        }
                        this.refreshCurrentFinancingMapListItem().catch(e => { throw e; });
                        this.createNewLiabilityLocal(result);
                        this.waiter.hide();
                        resolve(result.id);
                    },
                    error: e => {
                        // Clear the timeout if there's an error
                        if (showWaiterTimeout) {
                            clearTimeout(showWaiterTimeout);
                        }
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    /**
     * Updated eine neue Verpflichtung im Backend und aktualisiert anschließend den Store
     *
     * @param {INewLiabilityModel} data neue Verpflichtungdaten
     */
    public updateNewLiability(data: Partial<INewLiabilityModel>) {
        return new Promise<void>((resolve, reject) => {

            const local = this.store.selectSnapshot(NewLiabilityState.currentById)(data.id ?? '');
            const toSave = Object.assign({}, local ?? {}, data);

            this.newLiabilityController.updateEntity(toSave)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        this.refreshCurrentFinancingMapListItem().catch(e => { throw e; });
                        this.updateNewLiabilityLocal(result);
                        resolve();
                    },
                    error: e => {
                        reject(e);
                    },
                });
        });
    }

    /**
     * Löscht eine neue Verpflichtung im Backend und aktualisiert anschließend den Store
     *
     * @param {string} id id der neue Verpflichtung
     */
    public deleteNewLiability(id: string) {
        return new Promise<void>((resolve, reject) => {
            // Set a timeout to show the waiter after 1 second
            const showWaiterTimeout = setTimeout(() => {
                this.waiter.show();
            }, GlobalSettings.waiterDelayLong);
            this.newLiabilityController.deleteEntity(id)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        // Clear the timeout if the request completes before 1 second
                        if (showWaiterTimeout) {
                            clearTimeout(showWaiterTimeout);
                        }
                        this.deleteNewLiabilityLocal(id);
                        this.waiter.hide();
                        resolve();
                    },
                    error: e => {
                        // Clear the timeout if there's an error
                        if (showWaiterTimeout) {
                            clearTimeout(showWaiterTimeout);
                        }
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    //#endregion

    //#region Document

    /**
     * Überschreibt die Dokumente im Store
     *
     * @param {IDocumentModel} data Dokumentsdaten
     */
    public patchDocuments(data: IDocumentModel[]) {
        this.store.dispatch(new SdkPatchDocument(data));
    }

    /**
     * Erstellt ein Dokument im Store
     *
     * @param {IDocumentModel} data Dokumentsdaten
     */
    public createDocumentLocal(data: IDocumentModel) {
        if (!!this.store.selectSnapshot(FinancingMapState.existDocumentParent)(data)) {
            this.store.dispatch(new SdkAddDocument(data));
        }
    }

    /**
     * Aktualisiert ein Dokument im Store
     *
     * @param {IDocumentModel} data Dokumentdaten
     */
    public updateDocumentLocal(data: IDocumentModel) {
        this.store.dispatch(new SdkUpdateDocument(data));
    }

    /**
     * Löscht ein Dokument im Store
     *
     * @param {string} documentId id des Dokuments
     */
    public deleteDocumentLocal(documentId: string) {
        const localFiles = this.store.selectSnapshot(FileState.filesByDocumentIds)([documentId]);
        this.store.dispatch(new SdkDeleteFiles(localFiles.map(({ id }) => id)));
        this.store.dispatch(new SdkDeleteDocument(documentId));
    }

    /**
     * Updated ein Dokument im Backend und aktualisiert anschließend den Store
     *
     * @param {IDocumentModel} data Dokumentdaten
     */
    public updateDocument(data: Partial<IDocumentModel>) {
        return new Promise<void>((resolve, reject) => {
            this.waiter.show();

            const local = this.store.selectSnapshot(DebtorState.currentById)(data.id ?? '');
            const toSave = Object.assign({}, local ?? {}, data);

            this.documentController.updateEntity(toSave)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        this.refreshCurrentFinancingMapListItem().catch(e => { throw e; });
                        this.updateDocumentLocal(result);
                        this.waiter.hide();
                        resolve();
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    /**
     * Löscht ein Dokument im Backend und aktualisiert anschließend den Store
     *
     * @param {string} id id des Dokuments
     */
    public deleteDocument(id: string) {
        return new Promise<void>((resolve, reject) => {
            this.waiter.show();
            this.documentController.deleteEntity(id)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.deleteDocumentLocal(id);
                        this.waiter.hide();
                        resolve();
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    //#endregion

    //#region File

    /**
     * Überschreibt die Dateien im Store
     *
     * @param {IFileModel} data Dateidaten
     */
    public patchFiles(data: IFileModel[]) {
        this.store.dispatch(new SdkPatchFile(data));
    }

    /**
     * Erstellt eine Datei im Store
     *
     * @param {IFileModel} data Dateidaten
     */
    public createFileLocal(data: IFileModel) {
        if (!!this.store.selectSnapshot(DocumentState.currentById)(data.documentId)) {
            this.store.dispatch(new SdkAddFile(data));
        }
    }

    /**
     * Aktualisiert eine Datei im Store
     *
     * @param {IFileModel} data Dateidaten
     */
    public updateFileLocal(data: IFileModel) {
        this.store.dispatch(new SdkUpdateFile(data));
    }

    /**
     * Aktualisiert merere Dateien im Store
     *
     * @param {IFileModel} data Dateidaten
     */
    public updateFilesLocal(data: IFileModel[]) {
        this.store.dispatch(new SdkUpdateFiles(data));
    }

    /**
     * Löscht eine Datei im Store
     *
     * @param {string} id id der Datei
     */
    public deleteFileLocal(id: string) {
        this.store.dispatch(new SdkDeleteFile(id));
    }

    /**
     * Eine Datei kann nur mit Dokument zusammen gespeichert werden
     *
     * @param {IPostDocumentFileRequest} documentFile requestdaten aus dokument, datei und content
     */
    public createFile(documentFile: IPostDocumentFileRequest) {
        return new Promise<void>((resolve, reject) => {
            this.waiter.show();
            this.fileController.createEntity(documentFile)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        if (!!result?.document?.id && !!result?.file?.id) {
                            if (this.store.selectSnapshot(DocumentState.currentById)(result.document.id)) {
                                this.updateDocumentLocal(result.document);
                            }
                            else {
                                this.createDocumentLocal(result.document);
                            }

                            this.createFileLocal(result.file);
                        }
                        this.waiter.hide();
                        resolve();
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    /**
     * es ist nur gestatted das isSigned Flag an einem File zu aktualisieren
     *
     * @param {string} fileId id des Files
     * @param {boolean} isSigned der Wert auf den es gesetzt werden soll
     */
    public updateFile(fileId: string, isSigned: boolean) {
        return new Promise<void>((resolve, reject) => {
            this.waiter.show();
            this.fileController.setIsSigned(fileId, isSigned)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        this.updateFileLocal(result);
                        this.waiter.hide();
                        resolve();
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    /**
     * Löscht eine Datei im Backend und aktualisiert anschließend den Store
     *
     * @param {string} id id der Datei
     */
    public deleteFile(id: string) {
        return new Promise<void>((resolve, reject) => {
            this.waiter.show();
            this.fileController.deleteEntity(id)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.deleteFileLocal(id);
                        this.waiter.hide();
                        resolve();
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    //#endregion

    //#region Signature

    /**
     * Überschreibt die Unterschriften im Store
     *
     * @param {ISignatureModel} data Unterschriftdaten
     */
    public patchSignatures(data: ISignatureModel[]) {
        this.store.dispatch(new SdkPatchSignature(data));
    }

    /**
     * Erstellt eine Unterschrift im Store
     *
     * @param {ISignatureModel} data Unterschriftdaten
     */
    public createSignatureLocal(data: ISignatureModel) {
        if (this.store.selectSnapshot(FinancingMapState.currentId) === data.financingMapId) {
            this.store.dispatch(new SdkAddSignature(data));
        }
    }

    /**
     * Aktualisiert eine Unterschrift im Store
     *
     * @param {ISignatureModel} data Unterschriftdaten
     */
    public updateSignatureLocal(data: ISignatureModel) {
        this.store.dispatch(new SdkUpdateSignature(data));
    }

    /**
     * Löscht eine Unterschrift im Store
     *
     * @param {string} id id der Unterschrift
     */
    public deleteSignatureLocal(id: string) {
        this.store.dispatch(new SdkDeleteSignature(id));
    }

    /**
     * Speichert eine Unterschrift im Backend und aktualisiert anschließend den Store
     *
     * @param {ISignatureModel} data Unterschriftdaten inkl. content
     * @returns {Promise} id der neuen Entität
     */
    public createSignature(data: Partial<ISignatureModel>): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.waiter.show();
            this.signatureController.createEntity(data)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        this.createSignatureLocal(result);
                        this.waiter.hide();
                        resolve(result.id);
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    /**
     * Löscht eine Unterschrift im Backend und aktualisiert anschließend den Store
     *
     * @param {string} id id der Unterschrift
     */
    public deleteSignature(id: string) {
        return new Promise<void>((resolve, reject) => {
            this.waiter.show();
            this.signatureController.deleteEntity(id)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.deleteSignatureLocal(id);
                        this.waiter.hide();
                        resolve();
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    //#endregion

    //#region Messages


    /**
     * läd alle Nachrichten der übergeordneten Finanzierungen
     *
     * @param {string} financingMapId Id der Nachricht
     */
    public loadMessages(financingMapId: string) {
        if (!Array.isArray(this.currentMessages) || this.currentMessages.length === 0) {
            this.messageController.getMessagesByFinancing(financingMapId)
                .pipe(take(1))
                .subscribe(messages => {
                    if (Array.isArray(messages)) {
                        this.currentMessages = messages;
                        this.messageSubject.next(this.currentMessages);
                    }
                });
        }
    }

    /**
     * entfernt alle geladenen Nachrichten aus der Nachrichtenliste
     */
    public unloadMessages() {
        this.currentMessages = null;
        this.messageSubject.next(this.currentMessages);
    }

    /**
     * Erstellt eine Nachricht in der Nachrichtenliste
     *
     * @param {IMessageModel} data Nachrichtendaten
     */
    public createMessageLocal(data: IMessageModel) {
        this.currentMessages = Array.isArray(this.currentMessages) ? [...this.currentMessages, data] : [data];
        this.messageSubject.next(this.currentMessages);
    }

    /**
     * Aktualisiert eine Nachricht in der Nachrichtenliste
     *
     * @param {IMessageModel} data Nachrichtendaten
     */
    public updateMessageLocal(data: IMessageModel) {
        if (Array.isArray(this.currentMessages) && this.currentMessages.some(({ id }) => id === data.id)) {
            this.currentMessages = this.currentMessages.filter(({ id }) => id !== data.id).concat(data);
            this.messageSubject.next(this.currentMessages);
        }
    }

    /**
     * Aktualisiert mehrere Nachrichten in der Nachrichtenliste
     *
     * @param {IMessageModel} data Nachrichtendaten
     */
    public updateMessagesLocal(data: IMessageModel[]) {
        const updateMessageIds = data.map(({ id }) => id);
        if (Array.isArray(this.currentMessages) && this.currentMessages.some(({ id }) => updateMessageIds.includes(id))) {
            this.currentMessages = this.currentMessages.filter(({ id }) => !updateMessageIds.includes(id)).concat(data);
            this.messageSubject.next(this.currentMessages);

            this.setUnreadMessagesCountToZero()
        }
    }

    /**
     * Setzt unreadMessageCount der Aktuellen Finanzierung auf 0
     *
     *
     */
    private setUnreadMessagesCountToZero() {

        if (this.currentMessages !== null) {

            const mapId = this.currentMessages[0].financingMapId ?? 0;
            const index = this.currentFinancings?.findIndex(i => i.id === mapId) ?? null;

            if (this.currentFinancings !== null && index !== null && index !== -1) {
                this.currentFinancings[index].unreadMessageCount = 0;
                this.financingSubject.next(this.currentFinancings);
            }
        }

    }

    /**
     * Löscht eine Nachricht in der Nachrichtenliste
     *
     * @param {string} entityId id der Nachricht
     */
    public deleteMessageLocal(entityId: string) {
        if (Array.isArray(this.currentMessages) && this.currentMessages.some(({ id }) => id === entityId)) {
            this.currentMessages = this.currentMessages.filter(({ id }) => id !== entityId);
            this.messageSubject.next(this.currentMessages);
        }
    }

    /**
     * Speichert eine Aufgabe im Backend und aktualisiert anschließend den Store
     *
     * @param {IActivityModel} data Aufgabendate
     * @returns {Promise} id der neuen Entität
     */
    public createMessage(data: Partial<IActivityModel>): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.messageController.createEntity(data)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        this.createMessageLocal(result);
                        resolve(result.id);
                    },
                    error: e => {
                        reject(e);
                    },
                });
        });
    }

    /**
     * Speichert eine Aufgabe im Backend und aktualisiert anschließend den Store
     *
     * @param {string[]} ids ids der Nachrichten
     * @returns {Promise} abschluss Promise
     */
    public setReaded(ids: string[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.waiter.show();
            this.messageController.setReaded(ids)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        this.updateMessagesLocal(result);
                        this.waiter.hide();
                        resolve();
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    /**
     * Markiert eine Nachricht im Backend als gelöscht und aktualisiert anschließend den Store
     *
     * @param {string} id id der Nachricht
     * @returns {Promise} abschluss Promise
     */
    public markMessageDeleted(id: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.messageController.markDeleted(id)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        this.updateMessageLocal(result);
                        resolve();
                    },
                    error: e => {
                        reject(e);
                    },
                });
        });
    }

    //#endregion

    //#region Activities

    /**
     * läd die komplette Kundenliste unter berücksichtigung der Filter, Paging und sortiertung vom Backend
     */
    public refreshActivityList() {
        return new Promise<void>((resolve, reject) => {
            this.activityController.filter()
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        if (Array.isArray(result.data)) {
                            GlobalSettings.userSettings.activityPaging.totalResultCount = result.totalResultCount;
                            this.patchActivities(result.data);
                            resolve();
                        }
                    },
                    error: e => {
                        reject(e);
                    },

                });
        });
    }

    /**
     * entfernt alle geladenen Aktivitäten aus der Anwendung
     */
    public unloadActivities() {
        this.patchActivities([]);
    }

    /**
     * Überschreibt die Aufgaben im Store
     *
     * @param {IActivityModel} data Aufgabendaten
     */
    public patchActivities(data: IActivityCluster[]) {
        this.store.dispatch(new SdkPatchActivity(data));
    }

    /**
     * Speichert eine Aufgabe im Backend und aktualisiert anschließend den Store
     *
     * @param {string} financingMapId Finanzierung-ID
     * @param {string} description "Änderung" text
     * @param {SystemType} executiveSystem "Änderung durch" text
     * @param {EmailNotificationType} emailNotificationType Emailbenachrichtigungstyp. Standardmäßig nicht gesetzt
     * @returns {Promise} id der neuen Entität
     */
    public createActivity(financingMapId: string, description: string, executiveSystem: SystemType, emailNotificationType?: EmailNotificationType): Promise<string> {
        const activity: Partial<IActivityModel> = {
            financingMapId,
            description,
            executiveSystem,
            emailNotificationType,
        };

        return new Promise<string>((resolve, reject) => {
            this.waiter.show();
            this.activityController.createEntity(activity)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        this.waiter.hide();
                        resolve(result.id);
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    /**
     * Updated eine Aufgabe im Backend und aktualisiert anschließend den Store
     *
     * @param {string} activityId id der Aktivität
     * @param {boolean} isRead der Lese status
     */
    public updateActivity(activityId: string, isRead: boolean) {
        return new Promise<void>((resolve, reject) => {
            this.waiter.show();
            this.activityController.updateEntity(activityId, isRead)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.refreshActivityList().catch(e => { throw e; });
                        this.waiter.hide();
                        resolve();
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    //#endregion

    //#region Tasks

    /**
     * läd alle für den Nutzer sichtbaren Aktivitäten und speichert sie in der Store
     */
    public loadTasks() {
        const localTasks = this.store.selectSnapshot(TaskState.current);
        if (!Array.isArray(localTasks) || localTasks.length === 0) {
            this.taskController.getEntities()
                .pipe(take(1))
                .subscribe(tasks => {
                    if (Array.isArray(tasks)) {
                        this.patchTasks(tasks);
                    }
                });
        }
    }

    /**
     * entfernt alle geladenen Task aus der Anwendung
     */
    public unloadTasks() {
        this.patchTasks([]);
    }

    /**
     * Überschreibt die Aufgaben im Store
     *
     * @param {ITaskModel} data Aufgabendaten
     */
    public patchTasks(data: ITaskModel[]) {
        this.store.dispatch(new SdkPatchTask(data));
    }

    /**
     * Erstellt ein Aufgaben im Store
     *
     * @param {ITaskModel} data Aufgabendate
     */
    public createTaskLocal(data: ITaskModel) {
        this.store.dispatch(new SdkAddTask(data));
    }

    /**
     * Aktualisiert eine Aufgabe im Store
     *
     * @param {ITaskModel} data Aufgabendate
     */
    public updateTaskLocal(data: ITaskModel) {
        this.store.dispatch(new SdkUpdateTask(data));
    }

    /**
     * Löscht eine Aufgabe im Store
     *
     * @param {string} id id des Bestandskredits
     */
    public deleteTaskLocal(id: string) {
        this.store.dispatch(new SdkDeleteTask(id));
    }

    /**
     * Speichert eine Aufgabe im Backend und aktualisiert anschließend den Store
     *
     * @param {ITaskModel} data Aufgabendate
     * @returns {Promise} id der neuen Entität
     */
    public createTask(data: Partial<ITaskModel>): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.waiter.show();
            this.taskController.createEntity(data)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        this.createTaskLocal(result);
                        this.waiter.hide();
                        resolve(result.id);
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    /**
     * Updated eine Aufgabe im Backend und aktualisiert anschließend den Store
     *
     * @param {ITaskModel} data Aufgabendate
     */
    public updateTask(data: Partial<ITaskModel>) {
        return new Promise<void>((resolve, reject) => {
            this.waiter.show();

            const local = this.store.selectSnapshot(TaskState.currentById)(data.id ?? '');
            const toSave = Object.assign({}, local ?? {}, data);

            this.taskController.updateEntity(toSave)
                .pipe(take(1))
                .subscribe({
                    next: result => {
                        this.updateTaskLocal(result);
                        this.waiter.hide();
                        resolve();
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    /**
     * Löscht eine Aufgabe im Backend und aktualisiert anschließend den Store
     *
     * @param {string} id id des Bestandskredits
     */
    public deleteTask(id: string) {
        return new Promise<void>((resolve, reject) => {
            this.waiter.show();
            this.taskController.deleteEntity(id)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.deleteTaskLocal(id);
                        this.waiter.hide();
                        resolve();
                    },
                    error: e => {
                        this.waiter.hide();
                        reject(e);
                    },
                });
        });
    }

    //#endregion

    //#region  Masterdata

    /**
     * prüft ob die Stammdaten noch aktuell sind und aktualisiert diese
     *
     * @param {boolean} force soll da nachladen erzwungen werden
     */
    public updateMasterdata(force?: boolean) {
        return new Promise<void>((resolve, reject) => {
            forkJoin([
                this.getMasterDataObservable('branches', this.masterDataController.getBranches(), !!force),
                this.getMasterDataObservable('debitRate', this.masterDataController.getDebitRates(), !!force),
                this.getMasterDataObservable('debitRateAdaption', this.masterDataController.getDebitRateAdaptions(), !!force),
                this.getMasterDataObservable('bankAccounts', this.masterDataController.getBankAccounts(), !!force),
                this.getMasterDataObservable('countries', this.masterDataController.getCountries(), !!force),
                this.getMasterDataObservable('legalisationFeeBases', this.masterDataController.getLegalisationFeeBases(), !!force),
                this.getMasterDataObservable('financingConfigurations', this.masterDataController.getFinancingConfigurations(), !!force),
                this.getMasterDataObservable('morixRatingAssignments', this.masterDataController.getMorixRatingAssignments(), !!force),
            ])
                .pipe(take(1))
                .subscribe({
                    next: ([branches, debitRates, debitRateAdaptions, bankAccounts, countries, legalisationFeeBases, financingConfigurations, morixRatingAssignments]) => {
                        if (branches.length > 0) { this.store.dispatch(new SdkPatchBranches(branches)); }
                        if (debitRates.length > 0) { this.store.dispatch(new SdkPatchDebitRate(debitRates)); }
                        if (debitRateAdaptions.length > 0) { this.store.dispatch(new SdkPatchDebitRateAdaption(debitRateAdaptions)); }
                        if (bankAccounts.length > 0) { this.store.dispatch(new SdkPatchBankAccounts(bankAccounts)); }
                        if (countries.length > 0) { this.store.dispatch(new SdkPatchCountries(countries)); }
                        if (legalisationFeeBases.length > 0) { this.store.dispatch(new SdkPatchLegalisationFee(legalisationFeeBases)); }
                        if (financingConfigurations.length > 0) { this.store.dispatch(new SdkPatchFinancingConfigurations(DataService.fixMappingfinancingConfigurations(financingConfigurations))); }
                        if (morixRatingAssignments.length > 0) { this.store.dispatch(new SdkPatchMorixRatingAssignments(morixRatingAssignments)); }

                        resolve();
                    },
                    error: e => {
                        reject(e);
                    },
                });
        });
    }

    //#endregion

    //#region History

    /**
     * läd die komplette History
     *
     * @param {string} financingMapId Id der Finanzierung
     */
    public loadHistoryList(financingMapId: string) {
        if (!Array.isArray(this.currentHistory) || this.currentHistory.length === 0) {
            this.financingController.getFinancingHistory(financingMapId)
                .pipe(take(1))
                .subscribe(history => {
                    if (Array.isArray(history)) {
                        this.currentHistory = history;
                        this.historySubject.next(this.currentHistory);
                    }
                });
        }
    }

    /**
     * entfernt alle geladenen HistoryEinträge
     */
    public unloadHistoryList() {
        this.currentHistory = null;
        this.historySubject.next(this.currentHistory);
    }


    //#endregion


    //#region Produkte

    /**
     * läd die alle Produkte und ProduktPakete der Finanzierung
     *
     * @param {string} financingMapId Id der Finanzierung
     */
    public loadProductPackages(financingMapId: string): void {
        if (!Array.isArray(this.currentProductPackages) || this.currentProductPackages.length === 0 ||
            !Array.isArray(this.currentProducts) || this.currentProducts.length === 0) {

            this.financingController.getProductPackages(financingMapId)
                .pipe(
                    switchMap<IProductPackageModel[], Observable<{ productPackages: IProductPackageModel[], products: IProductModel[] }>>(productPackages => iif(
                        () => Array.isArray(productPackages) && productPackages.length > 0,
                        forkJoin(productPackages.map(productPackage => this.financingController.getProducts(productPackage.id)))
                            .pipe(
                                map(products => products.reduce((acc, val) => acc.concat(val), [])),
                                map(products => ({ productPackages, products })),
                            ),
                        of(({ productPackages: [], products: [] })),
                    )),
                    take(1),
                )
                .subscribe(data => {
                    this.currentProductPackages = data.productPackages;
                    this.currentProducts = data.products;

                    this.productPackageSubject.next(this.currentProductPackages);
                    this.productSubject.next(this.currentProducts);
                });
        }
    }

    /**
     * entfernt alle geladenen Produkte und ProduktPakete der Finanzierung
     */
    public unloadProductPackages() {
        this.currentProductPackages = null;
        this.currentProducts = null;

        this.productPackageSubject.next(this.currentProductPackages);
        this.productSubject.next(this.currentProducts);
    }

    //#endregion


    //#region StatusEntries

    /**
     * läd die komplette StatusEntry Liste
     */
    public loadStatusEntryList() {
        if (!Array.isArray(this.currentStatusEntries) || this.currentStatusEntries.length === 0) {
            this.statusEntryController.getEntities()
                .pipe(take(1))
                .subscribe(statusEntries => {
                    if (Array.isArray(statusEntries)) {
                        this.currentStatusEntries = statusEntries;
                        this.statusEntriesSubject.next(this.currentStatusEntries);
                    }
                });
        }
    }

    /**
     * entfernt alle geladenen StatusEntry
     */
    public unloadStatusEntryList() {
        this.currentStatusEntries = null;
        this.statusEntriesSubject.next(this.currentStatusEntries);
    }


    //#endregion

    /**
     * Aktualisert die Dokumente und Files vom Server
     *
     * @param {string} financingMapId Id der Finanzierung
     * @returns {Promise} abschluss Promise
     */
    private updateDocumentsAndFiles(financingMapId: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            forkJoin([
                this.documentController.getDocumentsByFinancing(financingMapId),
                this.fileController.getFilesByFinancing(financingMapId),
            ])
                .pipe(take(1))
                .subscribe({
                    next: ([documents, files]) => {
                        if (Array.isArray(documents) && Array.isArray(files)) {
                            this.patchDocuments(documents);
                            this.patchFiles(files);
                        }

                        resolve();
                    },
                    error: e => { reject(e) },
                });
        });
    }

    /**
     * mappt eine Property um, damit sie von der Berechnungs Bibliothek korrekt erkannt wird
     *
     * @param {IFinancingConfigurationModel[]} oldValues alte werte
     * @returns {IFinancingConfigurationModel[]} gefixte Werte
     */
    // eslint-disable-next-line @typescript-eslint/member-ordering
    private static fixMappingfinancingConfigurations(oldValues: IFinancingConfigurationModel[]): IFinancingConfigurationModel[] {
        const debtorCountProp = 'debtorCount' as keyof ISplittingRule;

        for (const conf of oldValues) {
            // Mapping um falsche bennenung zu beheben
            conf.splittingRules = conf.splittingRules.map(rule => {
                if (typeof rule[debtorCountProp] !== 'undefined') {
                    rule.debitorCount = rule[debtorCountProp] as number;
                    delete rule[debtorCountProp];
                }

                return rule;
            });
        }

        return oldValues;
    }

    /**
     * prüft ob die Daten schon geladen sind (rückgabe leeres Array), sonst werden sie über den Controller geladen
     *
     * @param {string} name property name im state
     * @param {Observable} controllerData Observable welcher über die Cotroller Funktion kommen würde
     * @param {boolean} force erzwingen des nachladens
     * @returns {Observable} Observable mit geladenen daten
     */
    private getMasterDataObservable<T>(name: keyof IMasterDataStateModel, controllerData: Observable<T[]>, force: boolean): Observable<T[]> {

        if (force) {
            return controllerData;
        }

        const localState = this.store.selectSnapshot((state: IGlobalState) => state.masterdataState[name]);

        if (localState.length === 0) {
            return controllerData;
        }

        return of([]);
    }
}
