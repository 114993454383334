/** History action type */
export enum ActionType {
        CreateCustomer,
        EditCustomerMasterData,
        SendCIPLink,
        TransmitFinancintMap,
        ChangeFormData,
        CreateNewTask,
        EditTask,
        DeleteTask,
        MarkTaskAsDone,
        UploadDocuments,
        DeleteDocuments,
        WriteMessageToCustomer,
        WriteMessageToPF,
        WriteMessageToBank,
        ReceiveMessageFromPFToCustomer,
        ReceiveMessageFromPFToFiliale,
        CopyFinancing,
        ArchiveFinancing,
        DeleteFinancing,
        PreCheck,
        Submit,
        AutomaticallyRejected,
        SuccessfullySbmitted,
        FinancingOnHold,
        FinancingCancelled,
        CalculationExampleSent,
        AcceptCalaculationExample,
        ReapplyCalculationExample,
        ESISSent,
        AcceptESIS,
        ApplyForNewESIS,
        LoanAgreementSent,
        FinancingCompleted,
        DocumentsResubmitted,
        ActivityMarkReadUnread,
        FinancingStatusChanged,
        ExportFinancing,
        ActivityGenerated,
        CreateFile,
        DeleteFile,
        CreateFinancing,
        SendHouseholdDocuments,
}
