import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, combineLatest, map } from 'rxjs';

import { SubArea } from '../../enums';
import { IValidationDialogAccordion, IValidationDialogAccordionItem, IValidationDialogArea, IValidationDialogResult } from '../../interfaces';

/**
 * komponente um invalide Felder anzuzeigen
 */
@Component({
    selector: 'ucba-validation-dialog',
    templateUrl: './validation-dialog.component.html',
    styleUrls: ['./validation-dialog.component.scss'],
})
export class ValidationDialogComponent implements OnInit {

    public selectedIndex: number | null = null;
    public allEmpty$: Observable<boolean>;

    /**
     * Standard konstruktor
     *
     * @param {MatDialogRef} dialogRef referenz auf diesen Dialog
     * @param {IValidationDialogArea[]} areas Array aus Bereichen mit Fehler Observables
     */
    public constructor(
        public dialogRef: MatDialogRef<ValidationDialogComponent, IValidationDialogResult>,
        @Inject(MAT_DIALOG_DATA) public areas: IValidationDialogArea[],
    ) {

        const missingSectionsOservables = this.areas.map(area => area.sections$) as Observable<IValidationDialogAccordion[]>[];
        this.allEmpty$ = combineLatest(missingSectionsOservables)
            .pipe(
                map(results => results.reduce((sum, curr) => sum + curr.length, 0)),
                map(summ => summ === 0),
            )
    }

    /**
     * Angular Hook um Werte zu initialisieren
     */
    public ngOnInit(): void {
        const idx = this.areas.findIndex(({ isSelected }) => isSelected);

        if (idx !== -1) {
            this.selectedIndex = idx;
        }
    }

    /**
     * Schließt den Dialog
     */
    public dismiss(): void {
        this.dialogRef.close();
    }

    /**
     * sendet Control Name und Pfad zum Anrufer
     *
     * @param {string} path Haupt navigationspfad
     * @param {IValidationDialogAccordionItem} item ausgewähltes Item
     * @param {SubArea} subArea unterbereich für die navigation
     */
    public onGoTo(path: string, item: IValidationDialogAccordionItem, subArea?: SubArea): void {
        this.dialogRef.close({
            path,
            subArea,
            controlName: item.fieldName,
            modelId: item.itemId,
            model: item.subModel,
        });
    }
}
