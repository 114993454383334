import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentViewerComponent, PrivacyStatementComponent } from '@ucba/sdk/components';
import { RoutingParams } from '@ucba/sdk/enums';

import { BankDocumentsComponent } from './components/bank-documents/bank-documents.component';
import { ChatComponent } from './components/chat/chat.component';
import { DebtorComponent } from './components/debtor/debtor.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { EntryPointComponent } from './components/entry-point/entry-point.component';
import { FinancingplanComponent } from './components/financingplan/financingplan.component';
import { HouseholdComponent } from './components/household/household.component';
import { LogoutComponent } from './components/logout/logout.component';
import { MyDataComponent } from './components/my-data/my-data.component';
import { RealestateComponent } from './components/realestate/realestate.component';
import { SelfDisclosureComponent } from './components/self-disclosure/self-disclosure.component';
import { StatusComponent } from './components/status/status.component';
import { TabNavigationComponent } from './components/tab-navigation/tab-navigation.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { AuthGuard } from './guards/auth.guard';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { ImprintComponent } from './modules/shared/components/imprint/imprint.component';

const routes: Routes = [
    {
        path: '',
        component: TabNavigationComponent,
        children: [
            {
                path: 'welcome',
                component: WelcomeComponent,
                canActivate: [AuthGuard],
            },
            {
                path: `${RoutingParams.FINANCING_MODULE}/status`,
                component: StatusComponent,
                canActivate: [AuthGuard],
            },
            {
                path: `${RoutingParams.FINANCING_MODULE}`,
                component: SelfDisclosureComponent,
                canActivate: [AuthGuard],
            },
            {
                path: `${RoutingParams.FINANCING_MODULE}/:${RoutingParams.FINANCINGMAP_ID}/${RoutingParams.PRIVACY_ROUTE}`,
                component: PrivacyStatementComponent,
                canActivate: [AuthGuard],
            },
            {
                path: `${RoutingParams.FINANCING_MODULE}/:${RoutingParams.FINANCINGMAP_ID}`,
                canActivate: [AuthGuard],
                component: MyDataComponent,
                children: [
                    {
                        path: 'debtor',
                        component: DebtorComponent,
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'household',
                        component: HouseholdComponent,
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'realestate',
                        component: RealestateComponent,
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'financingplan',
                        component: FinancingplanComponent,
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'news',
                        component: ChatComponent,
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'documents',
                        component: DocumentsComponent,
                        canActivate: [AuthGuard],
                        canDeactivate: [CanDeactivateGuard],
                    },
                    {
                        path: 'bankdocuments',
                        component: BankDocumentsComponent,
                        canActivate: [AuthGuard],
                    },
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'financingplan',
                    },
                ],
            },
            {
                path: 'myData',
                component: MyDataComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'imprint',
                component: ImprintComponent,
                canActivate: [],
            },
            {
                path: 'logout',
                component: LogoutComponent,
                canActivate: [],
            },
        ],
    },
    {
        path: 'document-viewer',
        component: DocumentViewerComponent,
        canActivate: [AuthGuard],
    },
    {
        path: `:${RoutingParams.CUSTOMER_ID}`,
        component: EntryPointComponent,
    },
];

/**
 * Routing Modul für App Routen
 */
@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        CommonModule,
    ],
    exports: [
        RouterModule,
    ],
})
export class AppRoutingModule { }
