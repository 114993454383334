import { ILiabilityModel } from '../../models';

/**
 * Action welche alle Liability updated
 */
 export class SdkPatchLiability {
    public static readonly type = '[SDK Liability] PATCH';

    /**
     * Standard Konstruktor
     * 
     * @param {ILiabilityModel} payload zu patchende Liability
     */
    public constructor(public payload: ILiabilityModel[]) { }
}

/**
 * Action welche ein neues Liability hinzufügt, sollte es bereits existieren wird add ignoriert
 */
 export class SdkAddLiability {
    public static readonly type = '[SDK Liability] ADD';

    /**
     * Standard Konstruktor
     * 
     * @param {ILiabilityModel} payload neues Liability
     */
    public constructor(public payload: ILiabilityModel) { }
}

/**
 * Action welche ein spezifisches Liability updated
 */
export class SdkUpdateLiability {
    public static readonly type = '[SDK Liability] UPDATE';

    /**
     * Standard Konstruktor
     * 
     * @param {ILiabilityModel} payload zu patchendes Liability
     */
    public constructor(public payload: ILiabilityModel) { }
}

/**
 * Action welche ein Liability entfernt, sollte es nicht existieren wird delete ignoriert
 */
export class SdkDeleteLiability {
    public static readonly type = '[SDK Liability] DELETE';

    /**
     * Standard Konstruktor
     * 
     * @param {string} id id des zu löschenden Liabilitys
     */
    public constructor(public id: string) { }
}

/**
 * Action welche alle Liability entfernt, welche einem Haushalt zugeordnet wurden
 */
 export class SdkDeleteLiabilityByHousehold {
    public static readonly type = '[SDK Liability] DELETE_BY_HOUSEHOLD';

    /**
     * Standard Konstruktor
     * 
     * @param {string} id id des Haushaltes der zu löschenden Liabilitys
     */
    public constructor(public id: string) { }
}
