<div fxLayout="column" *ngIf="!!control">

        <mat-label >{{ labelText }}</mat-label>
        <mat-hint *ngIf="!!comment">({{ comment }})</mat-hint>


    <mat-form-field fxFlex="50" *ngIf="!!control" [hideRequiredMarker]="!isRequired" appearance="outline">
        <textarea class="settings" matInput [formControl]="formControl" [rows]="rows" [readonly]="isReadOnly"></textarea>
        <mat-error cxad-form-field-error *ngIf="control.invalid" [control]="control"></mat-error>
    </mat-form-field>
</div>
