import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IDebtorModel } from '../../models';
import { BaseApiController } from '../base/base-api.controller';

/**
 * Controller welche alle Funkionalitäten des Debtor Controller API bereithält
 */
@Injectable()
export class DebtorController extends BaseApiController<IDebtorModel> {
    protected override baseUrl = '/debtor';

    //#region --- Get ---

    /**
     * Gibt alle Kreditnehmer für die Finanzierung mit der übergebenen Id zurück.
     * 
     * @param {string} id id der Finanzierung
     * @returns {IDebtorModel[]} alle Kreditnehmer der Finanzierung
     */
    public getDebtorsByFinancing(id: string): Observable<IDebtorModel[]> {
        return this.get<IDebtorModel[]>(`${this.baseUrl}/GetDebtorsByFinancing/${id}`);
    }

    //#endregion

    //#region --- CRUD ---

    //#endregion
}
