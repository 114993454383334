export enum CurrencyInputType {

    /** #,## € */
    CURRENCY = 0,

    /** #,# % */
    PERCENT = 1,

    /** #,##m² */
    AREA = 2,

    /** #[SUFFIX] */
    SUFFIX = 4,
}
