import { RealEstateType } from '@ntag-ef/finprocess-enums/finadvisory';

import { FinancingMapStatus } from '../enums';
import { ISODate } from '../types';

import { IBackendBaseModel } from './backend-base.model';

/**
 * Finanzierungs anzeige Daten, wird ausschließlich vom Backend generiert
 */
export interface IFinancingMapLightModel extends IBackendBaseModel {

    /** Die Kunden Id */
    customerId: string | null;

    /** Der Objekt typ */
    type?: RealEstateType | null;

    /** Postleitzahl */
    zip?: string | null;

    /** Stadt */
    city?: string | null;

    /** Straße */
    street?: string | null;

    /** Hausnummer */
    streetNumber?: string | null;

    /** Der Kreditbetrag */
    creditAmount?: number | null;

    /** The status. */
    status?: FinancingMapStatus | null;

    /** Alternativer Translation Key für Status. */
    alternativeStatusText? :string | null; 

    /** The status information. */
    statusInfo?: string | null;

    /** The display name of the clerk. */
    clerkDisplayName?: string | null;

    /** wurde die Finanzierung automatisch zurückgestellt */
    isAutoExit?: boolean | null;

    /** wie viele Nachrichten bei dieser Finanzierung noch ungelesen sind */
    unreadMessageCount?: number | null;

    /** startzeit der vsl. Bearbeitungszeit */
    processingTimeStart?: ISODate | null;

    /** vsl. Bearbeitungszeit inkl. Buffer */
    processingTimeStartWithBuffer?: ISODate | null;

    /** Der Zeitpunkt wenn der Fall einem Bearbeiter in Finprocess zum ersten mal zugewiesen wurde */
    processingActuallyStart?: ISODate | null;
}
