<form *ngIf="!!phoneForm && !!control" [formGroup]="phoneForm" class="usba-phone-wrapper" fxLayout="column">
    <div>
        <div fxFlex="30" fxFlex.gt-md="20">
            <mat-form-field class="areacode" [class.mat-form-field-invalid]="control.invalid && numberControl.touched">
                <mat-select formControlName="areaCode">
                    <mat-option *ngFor="let entity of areaCodeList" [value]="entity.value">
                        {{entity.label}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="70" fxFlex.gt-md="80">
            <mat-form-field [hideRequiredMarker]="!isRequired" [attr.name]="controlName"
                [class.mat-form-field-invalid]="control.invalid && numberControl.touched" class="mobile-number">
                <mat-label class="padding-left">{{ labelText }}</mat-label>
                <input class="padding-left" matInput type="phone" formControlName="number" [required]="!!isRequired"
                    [errorStateMatcher]="phoneErrorStateMatcher">

                <mat-hint *ngIf="!!comment">{{ comment }}</mat-hint>
            </mat-form-field>
        </div>
        <mat-error *ngIf="control.invalid && numberControl.touched" ucba-form-field-error [control]="numberControl">
        </mat-error>
    </div>

</form>