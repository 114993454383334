import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IFileModel } from '../../models';
import { ICreateFileRequest, IPostDocumentFileRequest } from '../../requests'
import { IPostDocumentFileResponse } from '../../responses';
import { BaseController } from '../base/base.controller'

/**
 * Controller welcher alle Funkionalitäten des File Controllers API bereithält
 */
@Injectable()

export class FileController extends BaseController {

    private baseUrl = '/file';

    //#region --- Get ---

    /**
     * Gibt die Entität mit übergebener Id zurück
     * 
     * @param {string} id id der Entität
     * @returns {any} angeforderte Entität
     */
    public getEntity(id: string): Observable<IFileModel> {
        return this.get(`${this.baseUrl}/${id}`);
    }

    /**
     * Gibt alle Dateien (ohne Content) für die Finanzierung mit der übergebenen Id zurück.
     * 
     * @param {string} id id der Finanzierung
     * @returns {IFileModel[]} alle Dateien der Finanzierung
     */
    public getFilesByFinancing(id: string): Observable<IFileModel[]> {
        return this.get(`${this.baseUrl}/GetFilesByFinancing/${id}`);
    }

    /**
     * Abrufen des Contents der angegebenen Datei
     *
     * @param {string} fileId Id der Datei
     * @returns {Promise<Blob>} Datei als Blob
     */
    public downloadFile(fileId: string): Observable<Blob | null> {
        const url = `${this.baseUrl}/Download/${fileId}`;
        return this.download(url);
    }

    //#endregion

    //#region --- CRUD ---

    /**
     * neu anlegen eines Dokumentes mit Datei
     *
     * @param  {IPostDocumentFileRequest} documentFile zu speicherndes Dokument und Datei
     * @returns {Observable} http request observable
     */
    public createEntity(documentFile: IPostDocumentFileRequest): Observable<IPostDocumentFileResponse> {
        const formData: FormData = new FormData();
        formData.append('File', JSON.stringify({ ...documentFile.file, content: undefined }));
        formData.append('Document', JSON.stringify(documentFile.document));
        formData.append('Content', documentFile.content);

        return this.post(this.baseUrl, formData, {
            headers: new HttpHeaders(), // reset content-type to autodetect content-type
        });
    }

    /**
     * Löscht die Entität mit übergebener Id
     * 
     * @param {string} id id der Entität
     * @returns {any} angeforderte Entität
     */
    public deleteEntity(id: string): Observable<void> {
        return this.delete(`${this.baseUrl}/${id}`);
    }

    /**
     * setzt das isSigned flag eines Files
     * 
     * @param  {string} fileId id des Files
     * @param  {boolean} isSigned der Wert auf den es gesetzt werden soll 
     * @returns {Observable} aktualisiertes File Model
     */
    public setIsSigned(fileId: string, isSigned: boolean): Observable<IFileModel> {
        const url = `${this.baseUrl}/SetIsSigned/${fileId}?isSigned=${isSigned}`;
        return this.patch(url);
    }

    //#endregion

    //#region File Generations

    /**
     * Generiert im Backend eine Datei und gibt dieses als Blob zurück. Speziel für die Datenschutzerklärung
     *
     * @param {ICreateFileRequest} fileRequest Daten zur Erstellung einer Datei auf dem Server
     * @returns {Observable} http request observable
     */
    public createServerFile(fileRequest: ICreateFileRequest): Observable<Blob | null> {
        const url = `${this.baseUrl}/CreateServerFile/${fileRequest.financingMapId}?householdId=${fileRequest.householdId}&type=${fileRequest.type.toString()}`;
        return this.download(url);
    }

    /**
     * Generiert im Backend den Chatverlauf als PDF und gibt dieses als Blob zurück.
     *
     * @param  {string} financingMapId Finanzierungs Id
     * @param  {boolean} isCustomerChat ist der Kundenchat oder der Interne Chat aktiv
     * @returns {Observable<Blob>} die Chat PDF
     */
    public createCommunicationExport(financingMapId: string, isCustomerChat: boolean): Observable<Blob | null> {
        const url = `${this.baseUrl}/CreateCommunicationExport/${financingMapId}?isCustomer=${isCustomerChat}`;
        return this.download(url);
    }

    /**
     * Generiert im Backend die Finanzierungsdaten als PDF und gibt dieses als Blob zurück.
     *
     * @param {string} id id der Finanzierung
     * @returns {Observable} request observable
     */
    public createFinancingMapFile(id: string): Observable<Blob | null> {
        return this.download(`${this.baseUrl}/DownloadFinancingMapFile/${id}`);
    }

    /**
     * Funktion zum Download der DSGVO als PDF
     *
     * @returns {Observable<Blob>} DSGVO als PDF
     */
    public downloadDsgvo(): Observable<Blob | null> {
        const url = `${this.baseUrl}/DownloadDSGVOFile`;
        return this.download(url);
    }

    //#endregion
}
