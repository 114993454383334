<div class="alert-warning" *ngIf="isVisible">
    <div class="content">
        <span class="text">
            {{ 'global.readonly.text' | translate }} <br>
        </span>
        <div class="infos">
            <table>
                <tr *ngIf="status !== undefined && status !== null">
                    <td> {{ 'global.readonly.status' | translate }} </td>
                    <td> {{ status | finprocessEnumTranslation:'FinancingMapStatus' }} </td>
                </tr>
                <tr *ngIf="clerkDisplayName !== undefined && clerkDisplayName !== null">
                    <td> {{ 'global.readonly.clerkDisplayName' | translate }} </td>
                    <td> {{ clerkDisplayName }} </td>
                </tr>
            </table>
        </div>
    </div>
</div>