import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { INewLiabilityModel } from '../../models';
import { BaseApiController } from '../base/base-api.controller';

/**
 * Controller welche alle Funkionalitäten der NewLiability Controller API bereithält
 */
@Injectable()
export class NewLiabilityController extends BaseApiController<INewLiabilityModel> {

    protected override baseUrl = '/newliability';

    //#region --- Get ---

    /**
     * Gibt alle Neuen Verpflichtungen für die Finanzierung mit der übergebenen Id zurück.
     * 
     * @param {string} id id der Finanzierung
     * @returns {INewLiabilityModel[]} alle Neuen Verpflichtungen der Finanzierung
     */
    public getNewLiabilitiesByFinancing(id: string): Observable<INewLiabilityModel[]> {
        return this.get<INewLiabilityModel[]>(`${this.baseUrl}/GetNewLiabilitiesByFinancing/${id}`);
    }

    //#endregion

    //#region --- CRUD ---

    //#endregion
}
