/* eslint-disable @typescript-eslint/naming-convention */

export enum AddressComponentTypes {
    /** Straße */
    route = 'route',

    /** Hausnummer */
    street_number = 'street_number',

    /** PLZ */
    postal_code = 'postal_code',

    /** Ort */
    locality = 'locality',

    /** Ortsteil */
    sublocality = 'sublocality',

    /** Bundesland */
    administrative_area_level_1 = 'administrative_area_level_1',

    /** Gemeinde */
    administrative_area_level_2 = 'administrative_area_level_2',

    /** Land */
    country = 'country',
}
