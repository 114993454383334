import { IRealEstateModel } from '../../models';

/**
 * Action welche alle RealEstate updated
 */
export class SdkPatchRealEstate {
    public static readonly type = '[SDK RealEstate] PATCH';

    /**
     * Standard Konstruktor
     * 
     * @param {IRealEstateModel} payload zu patchende Real Estate
     */
    public constructor(public payload: IRealEstateModel[]) { }
}

/**
 * Action welche ein neues RealEstate hinzufügt, sollte es bereits existieren wird add ignoriert
 */
export class SdkAddRealEstate {
    public static readonly type = '[SDK RealEstate] ADD';

    /**
     * Standard Konstruktor
     * 
     * @param {IRealEstateModel} payload neues RealEstate
     */
    public constructor(public payload: IRealEstateModel) { }
}

/**
 * Action welche ein spezifisches RealEstate updated, sollte das RealEstate nicht exisiteren, wird update ignoriert
 */
export class SdkUpdateRealEstate {
    public static readonly type = '[SDK RealEstate] UPDATE';

    /**
     * Standard Konstruktor
     * 
     * @param {IRealEstateModel} payload zu patchendes RealEstate
     */
    public constructor(public payload: IRealEstateModel) { }
}

/**
 * Action welche ein RealEstate entfernt, sollte es nicht existieren wird delete ignoriert
 */
export class SdkDeleteRealEstate {
    public static readonly type = '[SDK RealEstate] DELETE';

    /**
     * Standard Konstruktor
     * 
     * @param {string} id id des zu löschenden RealEstates
     */
    public constructor(public id: string) { }
}
