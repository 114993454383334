<mat-form-field [attr.name]="controlName" *ngIf="!!control">
    <mat-label *ngIf="!!customLabel">{{ customLabel }}</mat-label>
    <mat-label *ngIf="!customLabel">{{ ('enumLabels.' + translationKey | finprocessEnumTranslation)}} </mat-label>
    <span *ngIf="!!iconName" matPrefix class="material-icons">{{ iconName }} &nbsp;</span>

    <mat-select [formControl]="formControl" [required]="!!isRequired" [multiple]="multiple" (selectionChange)="selectionChange.emit($event)">
        <mat-option *ngFor="let entity of selectList" [value]="entity.value">
            {{entity.label}}
        </mat-option>
    </mat-select>

    <mat-error ucba-form-field-error *ngIf="control.invalid" [control]="control"></mat-error>
    <mat-hint *ngIf="!!comment">
        {{ comment }}
        <ng-container *ngIf="!!comment2">
            <br> {{ comment2 }}
        </ng-container>
    </mat-hint>
</mat-form-field>
