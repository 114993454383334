/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable class-methods-use-this */

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { IListTuple } from '../../interfaces/list-tuple.interface';
import { IBranchModel, ICountryModel, IDebitRateAdaptionModel, IDebitRateModel, IFinancingConfigurationModel, ILegalisationFeeBaseModel, IMorixRatingAssignmentModel } from '../../models';
import { HelperService } from '../../services';
import { Dispose } from '../actions';

import { SdkPatchBankAccounts, SdkPatchBranches, SdkPatchCountries, SdkPatchDebitRate, SdkPatchDebitRateAdaption, SdkPatchFinancingConfigurations, SdkPatchLegalisationFee, SdkPatchMorixRatingAssignments } from './masterdata.actions';

export interface IMasterDataStateModel {
    branches: IBranchModel[];
    debitRate: IDebitRateModel[];
    debitRateAdaption: IDebitRateAdaptionModel[];
    bankAccounts: string[];
    countries: ICountryModel[];
    legalisationFeeBases: ILegalisationFeeBaseModel[];
    financingConfigurations: IFinancingConfigurationModel[];
    morixRatingAssignments: IMorixRatingAssignmentModel[];
}

/**
 * class for loading state
 */
@State<IMasterDataStateModel>({
    name: MasterDataState.stateName,
    defaults: MasterDataState.defaultData,
})
@Injectable()
export class MasterDataState {
    public static readonly stateName = 'masterdataState';
    private static readonly defaultData: IMasterDataStateModel = {
        branches: [],
        debitRate: [],
        debitRateAdaption: [],
        bankAccounts: [],
        countries: [],
        legalisationFeeBases: [],
        financingConfigurations: [],
        morixRatingAssignments: [],
    };

    //#region Actions

    /**
     * Aktualisiert die aktuellen Filialen
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchBranches} action SdkPatchBranches Action
     */
    @Action(SdkPatchBranches)
    public patchBranches(
        { patchState, getState }: StateContext<IMasterDataStateModel>,
        { payload }: SdkPatchBranches,
    ): void {

        const local = getState().branches ?? [];
        const patchIds = payload.map(({ id }) => id);

        patchState({
            branches: local.filter(({ id }) => !patchIds.includes(id)).concat(payload),
        });
    }

    /**
     * Aktualisiert die aktuellen DebitRates
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchDebitRate} action SdkPatchDebitRate Action
     */
    @Action(SdkPatchDebitRate)
    public patchDebitRate(
        { patchState, getState }: StateContext<IMasterDataStateModel>,
        { payload }: SdkPatchDebitRate,
    ): void {
        const local = getState().debitRate;
        const patchInterestMethod = payload.map(({ interestMethod }) => interestMethod);

        patchState({
            debitRate: local.filter(({ interestMethod }) => !patchInterestMethod
                .includes(interestMethod))
                .concat(payload),
        });
    }

    /**
     * Aktualisiert die aktuellen DebitRates Adaption
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchDebitRateAdaption} action SdkPatchDebitRateAdaption Action
     */
    @Action(SdkPatchDebitRateAdaption)
    public patchDebitRateAdaption(
        { patchState, getState }: StateContext<IMasterDataStateModel>,
        { payload }: SdkPatchDebitRateAdaption,
    ): void {
        const local = getState().debitRateAdaption;

        patchState({
            debitRateAdaption: local.filter(dra => !payload.some(l =>
                l.parameter === dra.parameter &&
                l.comparison === dra.comparison &&
                l.value === dra.value &&
                l.adaption === dra.adaption))
                .concat(payload),
        });
    }

    /**
     * Aktualisiert die aktuellen Länderlisten
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchCountries} action SdkPatchCountries Action
     */
    @Action(SdkPatchCountries)
    public sdkPatchCountries(
        { patchState, getState }: StateContext<IMasterDataStateModel>,
        { payload }: SdkPatchCountries,
    ): void {
        const local = getState().countries;
        const patchCodes = payload.map(({ code }) => code);

        patchState({
            countries: local.filter(({ code }) => !patchCodes.includes(code)).concat(payload),
        });
    }

    /**
     * Aktualisiert die aktuellen Legalisierungsgebühren
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchLegalisationFee} action SdkPatchLegalisationFee Action
     */
    @Action(SdkPatchLegalisationFee)
    public sdkPatchLegalisationFee(
        { patchState, getState }: StateContext<IMasterDataStateModel>,
        { payload }: SdkPatchLegalisationFee,
    ): void {
        const local = getState().legalisationFeeBases;
        const patchIds = payload.map(({ id }) => id);

        patchState({
            legalisationFeeBases: local.filter(({ id }) => !patchIds.includes(id)).concat(payload),
        });
    }

    /**
     * Aktualisiert die aktuellen FinancingConfiguration
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchFinancingConfigurations} action SdkPatchFinancingConfigurations Action
     */
    @Action(SdkPatchFinancingConfigurations)
    public patchFinancingConfigurationModels(
        { patchState, getState }: StateContext<IMasterDataStateModel>,
        { payload }: SdkPatchFinancingConfigurations,
    ): void {
        const local = getState().financingConfigurations;
        const patchIds = payload.map(({ id }) => id);

        patchState({
            financingConfigurations: local.filter(({ id }) => !patchIds.includes(id)).concat(payload),
        });
    }

    /**
     * Aktualisiert die aktuellen MorixRating
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchMorixRatingAssignments} action SdkPatchMorixRatingAssignments Action
     */
    @Action(SdkPatchMorixRatingAssignments)
    public patchMorixRatingAssignments(
        { patchState, getState }: StateContext<IMasterDataStateModel>,
        { payload }: SdkPatchMorixRatingAssignments,
    ): void {
        const local = getState().morixRatingAssignments;
        const patchIds = payload.map(({ id }) => id);
        patchState({
            morixRatingAssignments: local.filter(({ id }) => !patchIds.includes(id)).concat(payload),
        });
    }

    /**
     * Aktualisiert die aktuellen BankAccounts
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchBankAccounts} action SdkPatchBankAccounts Action
     */
    @Action(SdkPatchBankAccounts)
    public sdkPatchBankAccounts(
        { patchState }: StateContext<IMasterDataStateModel>,
        { payload }: SdkPatchBankAccounts,
    ): void {
        patchState({
            bankAccounts: payload,
        });
    }

    /**
     * zurücksetzen des States
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(Dispose)
    public dispose({ setState }: StateContext<IMasterDataStateModel>): void {
        setState({ ...MasterDataState.defaultData });
    }

    //#endregion

    //#region Selects

    /**
     * gibt alle Länder für Geburtsland zurück
     *
     * @param {IMasterDataStateModel} state IMasterDataStateModel
     * @returns {ICountryModel[]} liste der Länder
     */
    @Selector()
    public static birthCountries(state: IMasterDataStateModel): ICountryModel[] {
        return Array.isArray(state.countries) ? state.countries
            .filter(({ useForBirthCountry }) => useForBirthCountry)
            .sort(MasterDataState.sortCountries) : [];
    }

    /**
     * gibt alle Länder für Staatsbürgerschaft zurück
     *
     * @param {IMasterDataStateModel} state IMasterDataStateModel
     * @returns {ICountryModel[]} liste der Länder
     */
    @Selector()
    public static citizenshipCountries(state: IMasterDataStateModel): ICountryModel[] {
        return Array.isArray(state.countries) ? state.countries
            .filter(({ useForCiticenship }) => useForCiticenship)
            .sort(MasterDataState.sortCountries) : [];
    }

    /**
     * gibt alle Länder für Steuerdomizil/e zurück
     *
     * @param {IMasterDataStateModel} state IMasterDataStateModel
     * @returns {ICountryModel[]} liste der Länder
     */
    @Selector()
    public static taxResidenceCountries(state: IMasterDataStateModel): ICountryModel[] {
        return Array.isArray(state.countries) ? state.countries
            .filter(({ useForTaxResidence }) => useForTaxResidence)
            .sort(MasterDataState.sortCountries) : [];
    }

    /**
     * gibt alle Legalisierungsgebühren zurück
     *
     * @param {IMasterDataStateModel} state IMasterDataStateModel
     * @returns {ILegalisationFeeBaseModel[]} legalisierungsgebühren
     */
    @Selector()
    public static legalisationFeeBases(state: IMasterDataStateModel): ILegalisationFeeBaseModel[] {
        return Array.isArray(state.legalisationFeeBases) ?
            state.legalisationFeeBases.map(l => (l.creditAmount === 0 ? ({ ...l, creditAmount: undefined }) : l))
            : [];
    }

    /**
     * gibt alle auswählbaren Bank Accounts zurück
     *
     * @param {IMasterDataStateModel} state IMasterDataStateModel
     * @returns {string[]} bank Accounts
     */
    @Selector()
    public static bankAccounts(state: IMasterDataStateModel): string[] {
        return Array.isArray(state.bankAccounts) ? state.bankAccounts : [];
    }

    /**
     * gibt alle branches zurück
     *
     * @param {IMasterDataStateModel} state IMasterDataStateModel
     * @returns {IBranchModel[]} branches
     */
    @Selector()
    public static branches(state: IMasterDataStateModel): IBranchModel[] {
        return Array.isArray(state.branches) ? state.branches.filter(({ disabled }) => !disabled) : [];
    }

    /**
     * gibt alle branches als ListTuple zurück
     *
     * @param {IMasterDataStateModel} state IMasterDataStateModel
     * @returns {IListTuple<string>[]} branches
     */
    @Selector()
    public static branchesAsListTuple(state: IMasterDataStateModel): IListTuple<string>[] {
        return MasterDataState.branches(state).map<IListTuple<string>>(({ id, name }) => ({ value: id, label: name }));
    }

    /**
     * gibt alle debitRates zurück
     *
     * @param {IMasterDataStateModel} state IMasterDataStateModel
     * @returns {IDebitRateModel[]} debitRates
     */
    @Selector()
    public static debitRates(state: IMasterDataStateModel): IDebitRateModel[] {
        return Array.isArray(state.debitRate) ? state.debitRate : [];
    }

    /**
     * gibt alle debitRateAdaption zurück
     *
     * @param {IMasterDataStateModel} state IMasterDataStateModel
     * @returns {IDebitRateAdaptionModel[]} debitRateAdaption
     */
    @Selector()
    public static debitRateAdaptions(state: IMasterDataStateModel): IDebitRateAdaptionModel[] {
        return Array.isArray(state.debitRateAdaption) ? state.debitRateAdaption : [];
    }

    /**
     * ermittelt eine Finanzierungskonfiguration anhand der übergebenen Id. Ohne Id wird die aktuelle zurückgegeben.
     *
     * @param {IMasterDataStateModel} state IMasterDataStateModel
     * @returns {(configurationId?: string) => IFinancingConfigurationModel} callback für ermittlung
     */
    @Selector()
    public static determineFinancingConfiguration(state: IMasterDataStateModel):
        (configurationId?: string) => IFinancingConfigurationModel | undefined {

        /**
         * Callbackfunktion zum Ermittlen der Finanzierungskonfiguration
         *
         * @param {configurationId} configurationId id der konfiguration
         * @returns {IFinancingConfigurationModel} Finanzierungskonfiguration
         */
        return (configurationId?: string): IFinancingConfigurationModel | undefined =>
        (HelperService.hasValue(configurationId) ?
            state.financingConfigurations.find(({ id }) => id === configurationId) :
            state.financingConfigurations.find(({ validTo }) => !HelperService.hasValue(validTo)))
    }

    /**
     * gibt alle morixRatingAssignments zurück
     *
     * @param {IMasterDataStateModel} state IMasterDataStateModel
     * @returns {IMorixRatingAssignmentModel[]} morixRatingAssignments
     */
    @Selector()
    public static morixRatingAssignments(state: IMasterDataStateModel): IMorixRatingAssignmentModel[] {
        return Array.isArray(state.morixRatingAssignments) ? state.morixRatingAssignments : [];
    }

    //#endregion

    /**
     * sortierfunktion um Länderlisten zu sortieren
     *
     * @param {ICountryModel} a erster Wert
     * @param {ICountryModel} b zweiter Wert
     * @returns {number} zahl um sortierreihenfolge festzulegen
     */
    private static sortCountries(a: ICountryModel, b: ICountryModel): number {
        if (a.name.toLowerCase() === 'österreich') {
            return -1;
        }

        if (b.name.toLowerCase() === 'österreich') {
            return 1;
        }

        const fa = a.name.toLowerCase().replace('ä', 'a').replace('ö', 'o').replace('ü', 'u');
        const fb = b.name.toLowerCase().replace('ä', 'a').replace('ö', 'o').replace('ü', 'u');
        return fa < fb ? -1 : fa > fb ? 1 : 0;
    }
}
