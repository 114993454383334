export enum UploadFileResult {
    ALL_VALID = 'ALL_VALID',
    WRONG_FORMAT = 'WRONG_FORMAT',
    SIZE_QUOTA = 'SIZE_QUOTA',
    NO_SIZE = 'NO_SIZE',
    SIZE_QUOTA_ALL = 'SIZE_QUOTA_ALL',
    NOT_FOUND_ERR = 'NOT_FOUND_ERR',
    CORRUPTED_PDF = 'CORRUPTED_PDF',
    ENCRYPTED_PDF = 'ENCRYPTED_PDF',
    UPLOAD_CANCLED = 'UPLOAD_CANCLED',
}
