<mat-dialog-content>
    <h2>{{ 'modals.consent.content.title' | translate}}</h2>
    <p>{{ 'modals.consent.content.text1' | translate}}</p>
    <p>{{ 'modals.consent.content.text2' | translate}}</p>
    <ul>
        <li>{{ 'modals.consent.content.text2_1' | translate}}</li>
        <li>{{ 'modals.consent.content.text2_2' | translate}}</li>
        <li>{{ 'modals.consent.content.text2_3' | translate}}</li>
        <li>{{ 'modals.consent.content.text2_4' | translate}}</li>
    </ul>
    <p>{{ 'modals.consent.content.text3' | translate}}</p>
    <p>{{ 'modals.consent.content.text4' | translate}}</p>
    <p>{{ 'modals.consent.content.text5' | translate}}</p>

    <!-- <button *ngIf="isNotScrolled" mat-fab class="btn-scroll" (click)="onBtnDown()">
        <mat-icon>arrow_circle_down</mat-icon>
    </button> -->

    <p>
        <strong>
            {{ 'modals.consent.content.warning' | translate}}
        </strong>
        {{ 'modals.consent.content.text6' | translate}}
    </p>
</mat-dialog-content>

<mat-dialog-actions #actions class="action">
    <button class="btn" (click)="onConfirm()" color="primary" mat-raised-button [mat-dialog-close]
        matTooltip="{{ 'modals.consent.buttons.btnConfirm' | translate}}">
        {{ 'modals.consent.buttons.btnConfirm' | translate}}
    </button>
    <button class="btn" (click)="onDownloadDsgvo()" mat-raised-button
        matTooltip="{{ 'modals.consent.buttons.btnDSGVOinfoTitle' | translate}}">
        {{ 'modals.consent.buttons.btnDSGVOinfo' | translate}}
    </button>
</mat-dialog-actions>