import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { CalculationHelperService } from '@ntag-ef/finprocess-calculations';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';
import { distinctUntilChanged } from 'rxjs/operators';

interface IMultiInputTuple {
    value: number;
    isPercent: boolean;
}

/**
 * Komponente, welche eine currency Input Feld darstellt
 */
@Component({
    selector: 'cxad-multi-input',
    templateUrl: './multi-input.component.html',
    styleUrls: ['./multi-input.component.scss'],
})
export class MultiInputComponent implements OnInit {

    /*
        Idee der Komponente:
        von außen wird eine Formgroup mit zwei Controls reingegeben, diese Werden
        intern in zwei interne Controls überführt. Sobald die Internen Controls sich verändern,
        wird die FormGroup mit ggf. umgerechneten aktualisiert, dadurch werden 
        die externen Controls zeitgleich getriggert. Von außen muss auf das change reagiert werden
        um ein übergeordnete Formgroup zu triggern
    */

    /** Form welches externe Controls kombiniert */
    @Input() public externalForm!: FormGroup;
    public internalValueCtr: FormControl | undefined;
    public internalIsPercentCtr: FormControl | undefined;

    /** externes Kontrol zu pflichtfeldüberwachung */
    private control: AbstractControl | undefined;


    /** der Basiswert von dem der Prozentwert berechnet wird */
    @Input() public baseValue!: number;
    @Input() public labelText!: string;
    @Input() public comment: string | undefined;

    /** der defaut Wert der angezeigt wird, falls Prozent auf 0 fallen würde */
    @Input() public defaultPercent: number | undefined;
    /** der maximale Pozentwert als korrektur bei rundungsfehlern #1530 */
    @Input() public maxPercent!: number;

    /** default Einstellungen für ein currency input */
    public options: NgxCurrencyConfig = {
        align: 'right',
        allowNegative: false,
        decimal: ',',
        precision: 1,
        prefix: '',
        suffix: ' %',
        thousands: '.',
        allowZero: true,
        nullable: true,
        inputMode: NgxCurrencyInputMode.Natural,
    };

    /**
     * Standard Konstruktor
     * 
     * @param {UntypedFormBuilder} fb UntypedFormBuilder injector
     */
    public constructor(private fb: UntypedFormBuilder) { }

    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {
        if (!!this.externalForm) {
            const externValueCtr = this.externalForm.get('value');
            const externIsPercentCtr = this.externalForm.get('isPercent');
    
            if (!externValueCtr || !externIsPercentCtr) {
                // eslint-disable-next-line no-console
                console.warn('missing controls for multi input component');
                return;
            }
    
            this.control = externValueCtr;
    
            this.internalValueCtr = this.fb.control(this.control.value, {
                updateOn: 'blur',
            });
    
            this.internalIsPercentCtr = this.fb.control(externIsPercentCtr.value, {
                updateOn: 'change',
            });
    
            // wenn von außen über die Controls eine Änderung geschieht, 
            // werden die internen Controls geupdated
            this.externalForm.valueChanges
                .subscribe((form: IMultiInputTuple) => {
                    if (!!this.internalValueCtr && form.value !== this.internalValueCtr.value) {
                        this.internalValueCtr.patchValue(form.value, { onlySelf: true, emitEvent: false });
                    }
    
                    if (!!this.internalIsPercentCtr && form.isPercent !== this.internalIsPercentCtr.value) {
                        this.internalIsPercentCtr.patchValue(form.isPercent, { onlySelf: true, emitEvent: false });
                    }
                });
    
            this.internalValueCtr.valueChanges
                .pipe(distinctUntilChanged())
                .subscribe((internalValue: number) => {
    
                    if (!!this.externalForm) {
                        const formData: IMultiInputTuple = this.externalForm?.value;
                        formData.value = internalValue;
    
                        // löst ein gleichzeitiges patchen der controlls aus
                        this.externalForm.patchValue(formData);
                    }
                });
    
            this.internalIsPercentCtr.valueChanges
                .pipe(distinctUntilChanged())
                .subscribe((internalIsPercent: boolean) => {
    
                    if (!!this.externalForm) {
                        const formData: IMultiInputTuple = this.externalForm?.value;
                        formData.isPercent = internalIsPercent;
    
                        if (this.baseValue > 0) {
                            if (internalIsPercent) {
                                formData.value = Math.min(CalculationHelperService.round(formData.value / this.baseValue * 100, 3), this.maxPercent);
                            }
                            else {
                                const maxValue = this.maxPercent * this.baseValue / 100
                                formData.value = Math.min(CalculationHelperService.round(formData.value * this.baseValue / 100, 2), maxValue);
                            }
                        }
                        else {
                            formData.value = 0;
                        }
    
                        if (internalIsPercent && formData.value === 0 && this.defaultPercent !== undefined) {
                            formData.value = this.defaultPercent;
                        }
    
                        this.updateOptions(internalIsPercent);
    
                        // löst ein gleichzeitiges patchen der controlls aus
                        this.externalForm.patchValue(formData);
                    }
                });
    
            this.updateOptions(externIsPercentCtr.value);
        }
    }

    /**
     * aktualisiert die optionen beim wechsel zwischen € und %
     *
     * @param {boolean} isPercent ist gerade Prozent aktiv
     */
    private updateOptions(isPercent: boolean) {
        if (isPercent) {
            this.options.suffix = ' %';
            this.options.precision = 3;
        }
        else {
            this.options.suffix = ' €';
            this.options.precision = 2;
        }
    }
}

