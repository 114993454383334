<mat-card class="cardStyle mat-elevation-z2" *ngIf="financingCardModel">
    <mat-card-header>
        <mat-card-title *ngIf="financingCardModel.type !== undefined && financingCardModel.type !== null; else noType">
            {{ financingCardModel.type | finprocessEnumTranslation:'RealEstateType' }}
        </mat-card-title>
        <ng-template #noType>
            <mat-card-title>&nbsp;</mat-card-title>
        </ng-template>
        <a class="content-buttons content-r">
            <a *ngIf="buttons.length === 1 || forceExpand">
                <a *ngFor="let btn of buttons" mat-button (click)="btn.onClick(financingCardModel.id)"
                    [matTooltip]="btn.tooltip">
                    <span [matBadge]="btn.badge" matBadgeSize="small" class="material-icons">
                        {{ btn.icon }}
                    </span>
                </a>
            </a>
            <button *ngIf="buttons.length > 1 && !forceExpand" class="more-button" [matMenuTriggerFor]="menu"
                aria-label="Toggle menu" mat-button
                matTooltip="{{ 'modules.customer.components.customerDetail.openFinancingActions' | translate }}">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before" [class]="'customize'" style="margin-left: 8px;
                    margin-right: 8px;">
                <a *ngFor="let btn of buttons" mat-button (click)="btn.onClick(financingCardModel.id)"
                    style="margin-right: 0.2em;" [matTooltip]="btn.tooltip">
                    <span [matBadge]="btn.badge" matBadgeSize="small" class="material-icons">
                        {{ btn.icon }}
                    </span>
                </a>
            </mat-menu>
        </a>
    </mat-card-header>
    <hr class="financing-card-underline">
    <mat-card-content class="cursor-pointer" (click)="onClicked()" matTooltip="Finanzierung öffnen" matRipple
        fxLayout="column" fxLayoutAlign="space-between stretch">
        <div fxLayout="column">
            <div fxLayout="row">
                <div class="col">Adresse:</div>
                <div class="col">{{ formatAddress }}</div>
            </div>
            <div fxLayout="row">
                <div class="col">Kreditbetrag:</div>
                <div class="col">{{ financingCardModel.creditAmount | currency:'EUR':'symbol':'1.2-2' }}</div>
            </div>
            <div fxLayout="row">
                <div class="col">Erstellt:</div>
                <div class="col">{{ financingCardModel.created | date:'short'}}</div>
            </div>
            <div fxLayout="row">
                <div class="col">Letzte Änderung:</div>
                <div class="col">{{ financingCardModel.modified | date:'short'}}</div>
            </div>
            <div *ngIf="isDurationVisible" fxLayout="row">
                <div class="col">Vsl. Bearbeitungsbeginn:</div>
                <div class="col">{{ processingTimeText }}</div>
            </div>
            <div *ngIf="!!financingCardModel.processingActuallyStart" fxLayout="row">
                <div class="col">Bearbeitungsbeginn:</div>
                <div class="col">{{ financingCardModel.processingActuallyStart | date:'dd.MM.yyyy'}}</div>
            </div>
            <div fxLayout="row">
                <div class="col">Status:</div>
                <div *ngIf="!!financingCardModel.alternativeStatusText" class="col">
                    {{ financingCardModel.alternativeStatusText }}
                </div>
                <div *ngIf="financingCardModel.status !== null && financingCardModel.status !== undefined && !financingCardModel.alternativeStatusText"
                    class="col">
                    {{(financingCardModel.status |finprocessEnumTranslation:'FinancingMapStatus') }}
                </div>
                <div *ngIf="financingCardModel.isAutoExit" class="col">
                    &nbsp;(automatisch)
                </div>
            </div>
            <div *ngIf="!!financingCardModel.statusInfo" fxLayout="row">
                <div class="col">Info zum Status:</div>
                <div class="col"> {{ financingCardModel.htmlStatusInfoShort }}
                    <button *ngIf="financingCardModel.htmlStatusInfoLong.length > maxLength" class="more-button"
                        (mouseenter)="toggleVisibility(true)" (mouseleave)="toggleVisibility(false)"
                        (click)="onExpandClicked($event, 'Info zum Status', financingCardModel.htmlStatusInfoLong)"
                        matTooltip="Mehr anzeigen" mat-button>
                        <mat-icon>{{visible ? 'visibility' : 'visibility_off'}}</mat-icon>
                    </button>
                </div>
            </div>
            <div fxLayout="row">
                <div class="col">Sachbearbeiter:</div>
                <div class="col">{{ financingCardModel.clerkDisplayName }}</div>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="end end">
            <button mat-raised-button type="button" color="primary">
                Einstieg
            </button>
        </div>
    </mat-card-content>
</mat-card>