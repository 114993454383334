import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { GlobalSettings, clearInvalidFormValues } from '@ucba/sdk';
import { FinancingMapStatus } from '@ucba/sdk/enums';
import { ICalculationHouseHold } from '@ucba/sdk/interfaces';
import { IDebtorModel, IHouseholdModel, ILiabilityModel, INewLiabilityModel } from '@ucba/sdk/models';
import { DataService, HelperService, ModelFactoryService, ValidationService } from '@ucba/sdk/services';
import { DebtorState, FinancingMapState, HouseholdState, LiabilityState, NewLiabilityState } from '@ucba/sdk/statemanagement/states';
import { HOUSEHOLD_FORM_VALIDATION_MAP } from '@ucba/sdk/validations';
import { Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';

import { IDeleteHouseholdData, IDeleteHouseholdResult } from '../../interfaces';
import { DeleteHouseholdComponent } from '../delete-household/delete-household.component';

/**
 * Komponente zum darstellen des Household Formulares
 */
@Component({
    selector: 'cxad-household',
    templateUrl: './household.component.html',
    styleUrls: ['./household.component.scss'],
})

export class HouseholdComponent implements OnInit, OnDestroy, AfterViewInit {
    public readonly housholdKey = 'houshold';
    public readonly debtorKey = 'debtors';
    public readonly liabilityKey = 'liabilitys';
    public readonly newliabilityKey = 'newLiabilitys';

    public form: FormGroup | undefined;

    /** beinhaltet ein Formular für den Haushalt und FormArrays für die Kreditnehmer, Bestandskredite, ... */
    public householdCollections: FormArray | undefined;
    public selectedHouseholdIdx = 0;
    public houseHoldCalculations: Record<string, ICalculationHouseHold> = {};

    public debtorHouseholdLabels: string[][] = [];
    public debtorLabels: string[][] = [];

    public readonly maxLiabilities = GlobalSettings.maxLiabilities;
    public readonly maxNewLiabilities = GlobalSettings.maxNewLiabilities;
    public readonly maxHouseholds = GlobalSettings.maxHouseholds;

    @ViewChild('overflowWrapper') public overflowWrapper!: ElementRef;

    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();

    /**
     * Standard Konstruktor
     *
     * @param {UntypedFormBuilder} fb FormBulder injector
     * @param {Store} store Store injector
     * @param {DataService} dataService DataService injector
     * @param {NotificationService} notification NotificationService injector
     * @param {TranslateService} translate TranslateService injector
     * @param {MatDialog} dialog MatDialog injector
     * @param {ValidationService} validation ValidationService injector
     * @param { Router } router Router injector
     * @param { ActivatedRoute } route ActivatedRoute injector
     * @param { ElementRef } elementRef ElementRef injector
     */
    public constructor(
        private fb: UntypedFormBuilder,
        private store: Store,
        private dataService: DataService,
        private notification: NotificationService,
        private translate: TranslateService,
        private dialog: MatDialog,
        public validation: ValidationService,
        private router: Router,
        private route: ActivatedRoute,
        private elementRef: ElementRef<HTMLElement>,
    ) { }

    /**
     * castet ein Abstract Control in ein Formgroup um es im Template besser verwenden zu können
     *
     * @param {AbstractControl} ac das zu castende AbstractControl
     * @returns { FormGroup } gecastede Formgroup
     */
    // eslint-disable-next-line class-methods-use-this
    public asFormGroup(ac: AbstractControl | null): FormGroup {
        return ac as FormGroup;
    }

    /**
     * castet ein FormArray in ein array aus Formgroup um es im Template besser verwenden zu können
     *
     * @param {AbstractControl} control das zu castende FormArray
     * @returns { FormGroup } gecastede Formgroup Array
     */
    // eslint-disable-next-line class-methods-use-this
    public asFormGroups(control: AbstractControl | null): FormGroup[] {
        return !!control && (control instanceof FormArray) ? ((control as FormArray).controls as FormGroup[]) : [];
    }

    /**
     * Angular Hook zum initialisieren
     *
     */
    public ngOnInit(): void {

        const calcVersion = this.store.selectSnapshot(FinancingMapState.current)?.calculationVersion;

        combineLatest([
            this.store.select(DebtorState.current),
            this.store.select(HouseholdState.current),
            this.store.select(LiabilityState.current),
            this.store.select(NewLiabilityState.current),
        ])
            .pipe(takeUntil(this.viewLeft$))
            .subscribe(([debtors, households, liabilitys, newliabilitys]) => {
                if (!this.form && Array.isArray(debtors) && Array.isArray(households)) {
                    this.initFormular(debtors, households, liabilitys, newliabilitys);
                } else if (Array.isArray(debtors) && Array.isArray(households)) {
                    //prüft ob ausgewählte household noch existiert, es kann sein dass aus der Filiale entfernt ist
                    if (!households[this.selectedHouseholdIdx]) {
                        this.selectHousehold(0);
                        this.updateHouseholdFormular(households, debtors, liabilitys, newliabilitys);
                    } else {
                        this.updateHouseholdFormular(households, debtors, liabilitys, newliabilitys);
                    }
                }

                this.debtorHouseholdLabels = households.map((hh, hhIdx) => {
                    const debs = debtors.filter(({ householdId }) => householdId === hh.id)
                    return debs.map((deb, idx) => HelperService.calcHouseholdDebitorLabel(deb, hhIdx, idx, 'Kreditnehmer'));
                });

                this.debtorLabels = households.map(hh => {
                    const debs = debtors.filter(({ householdId }) => householdId === hh.id)
                    return debs.map((deb, idx) => HelperService.calcDebitorLabel(deb, idx, 'Kreditnehmer'));
                });
            });

        this.store.select(DebtorState.current)
            .pipe(takeUntil(this.viewLeft$))
            .subscribe(debtors => {
                this.updateDebtorFormular(debtors);
            });

        this.store.select(HouseholdState.calculations)
            .pipe(takeUntil(this.viewLeft$))
            .subscribe(calcFunktion => {
                const calculations = calcFunktion(HelperService.getValueOrUndefiend(calcVersion));

                for (const calc of calculations) {
                    this.houseHoldCalculations[calc.householdId] = calc;
                }
            });

        this.store.select(LiabilityState.current)
            .pipe(takeUntil(this.viewLeft$))
            .subscribe(liabilitys => {
                this.updateLiabilityFormular(liabilitys);
            });

        this.store.select(NewLiabilityState.current)
            .pipe(takeUntil(this.viewLeft$))
            .subscribe(newLiabilitys => {
                this.updateNewLiabilityFormular(newLiabilitys);
            });

    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
    }

    /**
     * Angular Hook nachdem die View geladen wurde
     */
    public ngAfterViewInit(): void {
        const p = this.elementRef.nativeElement.parentElement?.parentElement;

        if (!!p?.scrollTo) {
            p.scrollTo(0, 0);
        }
    }

    /**
     * wenn auf den Weiter Button gedrückt wird, wird zum nächsten Tab gewechselt
     * 
     * @returns {Promise} navigations Promise
     */
    public onNext(): Promise<boolean> {
        return this.router.navigate(['..', 'documents'], { relativeTo: this.route });
    }

    /**
     * Fügt einen Haushalt hinzu
     *
     * @returns {Promise<void>} Void Promise
     */
    public async addHousehold(): Promise<void> {
        const financingId = this.store.selectSnapshot(FinancingMapState.currentId);

        if (!!financingId) {
            const newHousehold = ModelFactoryService.createHouseholdModel(financingId);
            await this.dataService.createHousehold(newHousehold);
        }
    }


    /**
     *
     * Löscht einen Haushalt
     *
     * @param {number} index Index des zu löschenden Haushalts
     */
    public deleteHousehold(index: number): void {
        const household = this.householdCollections?.controls[index];
        const id: string = household?.get(this.housholdKey)?.get('id')?.value;
        const financingMapStatus = this.store.selectSnapshot(FinancingMapState.current)?.status;

        if (!id) {
            return;
        }

        const hasDebtors = this.store.selectSnapshot(DebtorState.currentByHousholdIds)([id]).length > 0;

        if (financingMapStatus === FinancingMapStatus.Open) {
            if (hasDebtors) {
                const dialogRef = this.dialog.open(DeleteHouseholdComponent, {
                    data: {
                        deletedHouseholdId: id,
                        debtors: this.store.select(DebtorState.current),
                        households: this.store.select(HouseholdState.current),
                    } as IDeleteHouseholdData,
                });

                dialogRef.afterClosed().subscribe(async (result: IDeleteHouseholdResult) => {
                    if (!!result) {
                        for (const debtor of result.debtors) {
                            if (debtor.household === 'delete') {
                                await this.dataService.deleteDebtor(debtor.id);
                            } else {
                                await this.dataService.updateDebtor({
                                    id: debtor.id,
                                    householdId: debtor.household,
                                });
                            }
                        }

                        await this.dataService.deleteHousehold(id);

                        if (this.selectedHouseholdIdx === index) {
                            this.selectedHouseholdIdx = 0;
                        }
                    }
                });
            } else {
                this.notification.confirmYesNo(
                    this.translate.instant('components.financingTab.household.deleteHousehold'),
                    this.translate.instant('components.financingTab.household.deleteEmptyHouseholdMessage'),
                )
                    .pipe(take(1))
                    .subscribe(async role => {
                        if (role === 'submit') {
                            await this.dataService.deleteHousehold(id);

                            if (this.selectedHouseholdIdx === index) {
                                this.selectedHouseholdIdx = 0;
                            }
                        }
                    });
            }
        }
    }

    /**
     * fügt dem Haushalt einen weiteren Bestandskredit hinzu
     */
    public addLiability(): void {
        const collection = this.householdCollections?.controls[this.selectedHouseholdIdx];

        if (!!collection) {
            const household = collection.get(this.housholdKey);
            const householdId = household?.get('id')?.value;
            const liabilities = collection.get(this.liabilityKey) as FormArray;

            if (!!householdId && !!liabilities && liabilities.length < this.maxLiabilities) {
                liabilities.push(this.createLiabilityForm({ householdId }))
            }
        }
    }

    /**
     * löscht einen Bestandskredit an übergenener Stelle
     *
     * @param {number} idx index des Bestandskredites
     */
    public async deleteLiability(idx: number): Promise<void> {
        const liabilities = this.householdCollections?.controls[this.selectedHouseholdIdx]?.get(this.liabilityKey) as FormArray;

        if (!!liabilities && !!liabilities.controls[idx]) {
            const id = liabilities.controls[idx].get('id')?.value;

            if (!!id) {
                // wenn schonmal gespeichert, löschen über backend
                await this.dataService.deleteLiability(id);
            }
            else {
                // sonst löschen nur im FormArray
                liabilities.controls.splice(idx, 1);
            }
        }
    }

    /**
     * fügt dem Haushalt eine weitere neue Verpflichtung hinzu
     */
    public addNewLiability(): void {
        const collection = this.householdCollections?.controls[this.selectedHouseholdIdx];

        if (!!collection) {
            const household = collection.get(this.housholdKey);
            const householdId = household?.get('id')?.value;
            const newLiabilities = collection.get(this.newliabilityKey) as FormArray;

            if (!!householdId && !!newLiabilities && newLiabilities.length < this.maxNewLiabilities) {
                newLiabilities.push(this.createNewLiabilityForm({ householdId }))
            }
        }
    }

    /**
     * löscht eine neue Verpflichtung an übergenener Stelle
     *
     * @param {number} idx index der neue Verpflichtung
     */
    public async deleteNewLiability(idx: number): Promise<void> {
        const newLiabilities = this.householdCollections?.controls[this.selectedHouseholdIdx]?.get(this.newliabilityKey) as FormArray;

        if (!!newLiabilities && !!newLiabilities.controls[idx]) {
            const id = newLiabilities.controls[idx].get('id')?.value;

            if (!!id) {
                // wenn schonmal gespeichert, löschen über backend
                await this.dataService.deleteNewLiability(id);
            }
            else {
                // sonst löschen nur im FormArray
                newLiabilities.controls.splice(idx, 1);
            }
        }
    }

    /**
     *
     * Wählt einen Haushalt aus
     *
     * @param {number} index Index des Haushalts
     */
    public selectHousehold(index: number): void {
        this.selectedHouseholdIdx = index;
        this.overflowWrapper.nativeElement.scrollTo({ top: 0 });
    }

    /**
     * initialisiert das Formular
     *
     * @param {IDebtorModel} debtors die Kreditnehmer zum initialisieren
     * @param {IHouseholdModel} households die Haushalte zum initialisieren
     * @param  {ILiabilityModel[]} liabilitys Verpflichtungen
     * @param  {INewLiabilityModel[]} newliabilitys neue Verpflichtungen
     */
    private initFormular(debtors: IDebtorModel[], households: IHouseholdModel[], liabilitys: ILiabilityModel[], newliabilitys: INewLiabilityModel[]): void {

        if (households.length > 0 && debtors.length > 0) {
            this.householdCollections = this.fb.array(
                households.map(household => this.createHouseholdCollection(household, debtors, liabilitys, newliabilitys)),
            );

            this.form = this.fb.group({
                households: this.householdCollections,
            });
        }
    }

    /**
     * aktualisiert die Kreditnehmer im Formular
     *
     * @param {IDebtorModel} debtors die Kreditnehmer zum updaten
     */
    private updateDebtorFormular(debtors: IDebtorModel[]) {
        if (!!this.householdCollections) {
            for (const ac of this.householdCollections.controls) {
                const asFormGroup = ac as FormGroup;
                const debtorFormArray = asFormGroup.controls[this.debtorKey] as FormArray;

                const debtorValidation = HOUSEHOLD_FORM_VALIDATION_MAP['debtorModel'];

                for (const debtorAC of debtorFormArray.controls) {
                    const debtorForm = debtorAC as FormGroup;

                    const id = debtorForm.get('id')?.value;
                    const debtor = debtors.find(d => d.id === id);

                    if (!!debtor) {
                        for (const fieldname in debtorValidation) {
                            if (fieldname in debtorValidation) {
                                const field = debtorForm.get(fieldname);
                                const value = (debtor as unknown as Record<string, unknown>)[fieldname] as number | string | boolean;

                                if (!!field && HelperService.getValueOrNull(field.value) !== HelperService.getValueOrNull(value) && (field.valid || HelperService.hasValue(value))) {
                                    field.patchValue(value, { onlySelf: true })
                                }
                            }
                        }
                        this.validation.setValidatorsForForm(debtorValidation, debtorForm, undefined, debtors);
                    }
                    else {
                        // TODO debtor Form entfernen
                    }
                }
            }
        }
    }

    /**
     * aktualisiert die Haushalte im Formular
     *
     * @param {IHouseholdModel} households die Haushalte zum updaten
     * @param {IDebtorModel[]} debtors die Kreditnehmer zum updaten
     * @param  {ILiabilityModel[]} liabilitys Verpflichtungen
     * @param  {INewLiabilityModel[]} newliabilitys neue Verpflichtungen
     */
    private updateHouseholdFormular(households: IHouseholdModel[], debtors: IDebtorModel[], liabilitys: ILiabilityModel[], newliabilitys: INewLiabilityModel[]) {

        if (!!this.householdCollections) {

            this.householdCollections.controls = this.householdCollections.controls.filter(control => {
                const id = control.get(this.housholdKey)?.get('id')?.value;
                return !!id && households.some(household => household.id === id);
            });

            const householdValidation = HOUSEHOLD_FORM_VALIDATION_MAP['householdModel'];

            for (const household of households) {
                const existing = this.householdCollections.controls.find(control => control.get(this.housholdKey)?.get('id')?.value === household.id)?.get(this.housholdKey);

                if (!!existing) {
                    for (const fieldname in householdValidation) {
                        if (fieldname in householdValidation) {
                            const field = existing.get(fieldname);
                            const value = (household as unknown as Record<string, unknown>)[fieldname] as number | string | boolean;

                            if (!!field && HelperService.getValueOrNull(field.value) !== HelperService.getValueOrNull(value) && (field.valid || HelperService.hasValue(value))) {
                                field.patchValue(value, { onlySelf: true })
                            }
                        }
                    }

                    this.validation.setValidatorsForForm(householdValidation, existing as FormGroup, undefined, household);
                }
                else {
                    this.householdCollections.push(this.createHouseholdCollection(household, debtors, liabilitys, newliabilitys));
                }
            }
        }
    }

    /**
     * Erstellt eine Household Collection und befüllt diese mit den übergegeben Daten
     *
     * @param  {IHouseholdModel} household Haushalt
     * @param  {IDebtorModel[]} debtors Kreditnehmer
     * @param  {ILiabilityModel[]} liabilitys Verpflichtungen
     * @param  {INewLiabilityModel[]} newliabilitys neue Verpflichtungen
     * @returns {FormGroup} HouseholdCollection
     */
    private createHouseholdCollection(household: IHouseholdModel, debtors: IDebtorModel[], liabilitys: ILiabilityModel[], newliabilitys: INewLiabilityModel[]): FormGroup {
        const houseHoldForm = this.createHouseholdForm(household);
        const debFormGroups = debtors.filter(debtor => debtor.householdId === household.id).map(debtor => this.createDebtorForm(debtor));
        const liabilityFormGroups = liabilitys.filter(liability => liability.householdId === household.id).map(liability => this.createLiabilityForm(liability));
        const newLiabilityFormGroups = newliabilitys.filter(newLiability => newLiability.householdId === household.id).map(newLiability => this.createNewLiabilityForm(newLiability));

        if (liabilityFormGroups.length === 0) {
            liabilityFormGroups.push(this.createLiabilityForm({ householdId: household.id }))
        }

        if (newLiabilityFormGroups.length === 0) {
            newLiabilityFormGroups.push(this.createNewLiabilityForm({ householdId: household.id }))
        }

        const housholdCollection = this.fb.group({});
        housholdCollection.addControl(this.housholdKey, houseHoldForm);
        housholdCollection.addControl(this.debtorKey, this.fb.array(debFormGroups));
        housholdCollection.addControl(this.liabilityKey, this.fb.array(liabilityFormGroups));
        housholdCollection.addControl(this.newliabilityKey, this.fb.array(newLiabilityFormGroups));

        return housholdCollection;
    }


    /**
     * erstellt eine Household Form und befüllt diese mit den übergebene Daten
     *
     * @param {IHouseholdModel} household der Haushalt zum initialisieren
     * @returns {FormGroup} generierte FormGroup
     */
    private createHouseholdForm(household: IHouseholdModel): FormGroup {

        const controlCfg: Record<string, unknown> = {
            id: [household.id], // only for identification
            financingMapId: [household.financingMapId],
        };

        const householdValidation = HOUSEHOLD_FORM_VALIDATION_MAP['householdModel'];

        for (const fieldname in householdValidation) {
            if (fieldname in householdValidation) {
                const value = (household as unknown as Record<string, unknown>)[fieldname] as number | string | boolean;
                controlCfg[fieldname] = this.fb.control(HelperService.getValueOrNull(value), { updateOn: householdValidation[fieldname].changeByChange ? 'change' : 'blur' });
            }
        }

        const form = this.fb.group(controlCfg);

        this.validation.setValidatorsForForm(householdValidation, form, undefined, household);

        form.valueChanges
            .pipe(
                takeUntil(this.viewLeft$),
                clearInvalidFormValues(form),
            )
            .subscribe((change: IHouseholdModel) => {
                if (!!change.financingMapId) {
                    // um fehlende defaultwerte zu ergänzen
                    const defaultHousehold = ModelFactoryService.createHouseholdModel(change.financingMapId);
                    const toSave = ModelFactoryService.updateWithDefaultData(change, defaultHousehold);
                    this.dataService.updateHousehold(toSave).catch(e => { throw e; });
                }
            });

        return form;
    }

    /**
     * erstellt eine Debtor Form und befüllt diese mit den übergebene Daten
     *
     * @param {IDebtorModel} debtor der Haushalt zum initialisieren
     * @returns {FormGroup} generierte FormGroup
     */
    private createDebtorForm(debtor: IDebtorModel): FormGroup {

        const controlCfg: Record<string, unknown> = {
            id: [debtor.id], // only for identification
            householdId: [debtor.householdId],
        };

        const debtorValidation = HOUSEHOLD_FORM_VALIDATION_MAP['debtorModel'];

        for (const fieldname in debtorValidation) {
            if (fieldname in debtorValidation) {
                const value = (debtor as unknown as Record<string, unknown>)[fieldname] as number | string | boolean;
                controlCfg[fieldname] = this.fb.control(HelperService.getValueOrNull(value), { updateOn: debtorValidation[fieldname].changeByChange ? 'change' : 'blur' });
            }
        }

        const form = this.fb.group(controlCfg);

        this.validation.setValidatorsForForm(debtorValidation, form, undefined, debtor);

        form.valueChanges
            .pipe(
                takeUntil(this.viewLeft$),
                clearInvalidFormValues(form),
            )
            .subscribe((change: IDebtorModel) => {
                this.dataService.updateDebtor(change).catch(e => { throw e; });
            });

        return form;
    }


    /**
     * erstellt eine Liability Form und befüllt diese mit den übergebene Daten
     *
     * @param {ILiabilityModel} liability initialdaten mit dem der Bestandskredit befüllt wird
     * @returns {FormGroup} generierte FormGroup
     */
    private createLiabilityForm(liability: Partial<ILiabilityModel>): FormGroup {

        const controlCfg: Record<string, unknown> = {
            id: [liability.id], // only for identification
            householdId: [liability.householdId],
        };

        const liabilityValidation = HOUSEHOLD_FORM_VALIDATION_MAP['liabilityModel'];

        for (const fieldname in liabilityValidation) {
            if (fieldname in liabilityValidation) {
                const value = (liability as unknown as Record<string, unknown>)[fieldname] as number | string | boolean;
                controlCfg[fieldname] = this.fb.control(HelperService.getValueOrNull(value), { updateOn: liabilityValidation[fieldname].changeByChange ? 'change' : 'blur' });
            }
        }

        const form = this.fb.group(controlCfg);

        this.validation.setValidatorsForForm(liabilityValidation, form, undefined, liability);

        form.valueChanges
            .pipe(
                takeUntil(this.viewLeft$),
                clearInvalidFormValues(form),
                distinctUntilChanged((x, y) => HelperService.compareObjects(x, y, true)),
            )
            .subscribe((change: ILiabilityModel) => {

                if (!!change.id) {
                    const local = this.store.selectSnapshot(LiabilityState.currentById)(change.id)

                    change.covered = !!change.securedByLandRegister && !local?.securedByLandRegister ? false : change.covered;
                    change.securedByLandRegister = !!change.covered && !local?.covered ? false : change.securedByLandRegister;
                    change.securedRealEstateId = !!change.securedByLandRegister ? change.securedRealEstateId : undefined;

                    this.dataService.updateLiability(change).catch(e => { throw e; });
                }
                else {
                    const toCreate: Partial<ILiabilityModel> = {
                        ...change,
                        id: undefined, // id wird explizit auf undefiend gesetzt, da es sonst beim anlegen im Backend zum Fehler kommt
                    };

                    this.dataService.createLiability(toCreate).catch(e => { throw e; });
                }
            });

        return form;
    }

    /**
     * aktualisiert die Liability im Formular
     *
     * @param {ILiabilityModel} liabilitys die liability zum updaten
     */
    private updateLiabilityFormular(liabilitys: ILiabilityModel[]) {
        if (!!this.householdCollections) {
            for (const ac of this.householdCollections.controls) {
                const asFormGroup = ac as FormGroup;
                const liabilityFormArray = asFormGroup.controls[this.liabilityKey] as FormArray;
                const householdForm = asFormGroup.controls[this.housholdKey] as FormGroup;
                const houseHoldId = householdForm.get('id')?.value;

                const liabilityValidation = HOUSEHOLD_FORM_VALIDATION_MAP['liabilityModel'];

                liabilityFormArray.controls = liabilityFormArray.controls.filter(control => {
                    const id = control.get('id')?.value;
                    return !id || liabilitys.some(liability => liability.id === id);
                });

                for (const liability of liabilitys) {

                    if (liability.householdId !== houseHoldId) {
                        continue;
                    }

                    const existing = liabilityFormArray.controls.find(control => control.get('id')?.value === liability.id) || liabilityFormArray.controls.find(control => control.get('id')?.value === null);

                    if (!!existing) {
                        for (const fieldname in liabilityValidation) {
                            if (fieldname in liabilityValidation) {
                                const field = existing.get(fieldname);
                                const value = (liability as unknown as Record<string, unknown>)[fieldname] as number | string | boolean;

                                if (!!field && HelperService.getValueOrNull(field.value) !== HelperService.getValueOrNull(value) && (field.valid || HelperService.hasValue(value))) {
                                    field.patchValue(value, { onlySelf: true })
                                }
                            }
                        }

                        this.validation.setValidatorsForForm(liabilityValidation, existing as FormGroup, undefined, liability);
                    }
                    else {
                        liabilityFormArray.push(this.createLiabilityForm(liability));
                    }
                }

                if (liabilityFormArray.controls.length === 0) {
                    const id = asFormGroup.controls['houshold'].get('id')?.value
                    liabilityFormArray.push(this.createLiabilityForm({ householdId: id }));
                }
            }
        }
    }

    /**
     * erstellt eine New Liability Form und befüllt diese mit den übergebene Daten
     *
     * @param {INewLiabilityModel} newLiability initialdaten mit dem die neue Verpflichtung befüllt wird
     * @returns {FormGroup} generierte FormGroup
     */
    private createNewLiabilityForm(newLiability: Partial<INewLiabilityModel>): FormGroup {

        const controlCfg: Record<string, unknown> = {
            id: [newLiability.id], // only for identification
            householdId: [newLiability.householdId],
        };

        const newLiabilityValidation = HOUSEHOLD_FORM_VALIDATION_MAP['newLiabilityModel'];

        for (const fieldname in newLiabilityValidation) {
            if (fieldname in newLiabilityValidation) {
                const value = (newLiability as unknown as Record<string, unknown>)[fieldname] as number | string | boolean;
                controlCfg[fieldname] = this.fb.control(HelperService.getValueOrNull(value), { updateOn: newLiabilityValidation[fieldname].changeByChange ? 'change' : 'blur' });
            }
        }

        const form = this.fb.group(controlCfg);

        this.validation.setValidatorsForForm(newLiabilityValidation, form, undefined, newLiability);

        form.valueChanges
            .pipe(
                takeUntil(this.viewLeft$),
                clearInvalidFormValues(form),
                distinctUntilChanged((x, y) => HelperService.compareObjects(x, y, true)),
            )
            .subscribe((change: INewLiabilityModel) => {
                change.securedRealEstateId = !!change.securedByLandRegister ? change.securedRealEstateId : undefined;

                if (!!change.id) {
                    this.dataService.updateNewLiability(change).catch(e => { throw e; });
                }
                else {
                    const toCreate: Partial<INewLiabilityModel> = {
                        ...change,
                        id: undefined, // id wird explizit auf undefiend gesetzt, da es sonst beim anlegen im Backend zum Fehler kommt
                    };

                    this.dataService.createNewLiability(toCreate).catch(e => { throw e; });

                }
            });

        return form;
    }

    /**
     * aktualisiert die NewLiability im Formular
     *
     * @param {INewLiabilityModel} newLiabilitys die liability zum updaten
     */
    private updateNewLiabilityFormular(newLiabilitys: INewLiabilityModel[]) {

        if (!!this.householdCollections) {
            for (const ac of this.householdCollections.controls) {
                const asFormGroup = ac as FormGroup;
                const newLiabilityFormArray = asFormGroup.controls[this.newliabilityKey] as FormArray;
                const householdForm = asFormGroup.controls[this.housholdKey] as FormGroup;
                const houseHoldId = householdForm.get('id')?.value;

                const newLiabilityValidation = HOUSEHOLD_FORM_VALIDATION_MAP['newLiabilityModel'];

                newLiabilityFormArray.controls = newLiabilityFormArray.controls.filter(control => {
                    const id = control.get('id')?.value;
                    return !id || newLiabilitys.some(newliability => newliability.id === id)
                });

                for (const newLiability of newLiabilitys) {

                    if (newLiability.householdId !== houseHoldId) {
                        continue;
                    }

                    const existing = newLiabilityFormArray.controls.find(control => control.get('id')?.value === newLiability.id) || newLiabilityFormArray.controls.find(control => control.get('id')?.value === null);

                    if (!!existing) {
                        for (const fieldname in newLiabilityValidation) {
                            if (fieldname in newLiabilityValidation) {
                                const field = existing.get(fieldname);
                                const value = (newLiability as unknown as Record<string, unknown>)[fieldname] as number | string | boolean;

                                if (!!field && HelperService.getValueOrNull(field.value) !== HelperService.getValueOrNull(value) && (field.valid || HelperService.hasValue(value))) {
                                    field.patchValue(value, { onlySelf: true })
                                }
                            }
                        }
                        this.validation.setValidatorsForForm(newLiabilityValidation, existing as FormGroup, undefined, newLiability);
                    }
                    else {
                        newLiabilityFormArray.push(this.createNewLiabilityForm(newLiability));
                    }
                }

                if (newLiabilityFormArray.controls.length === 0) {
                    const id = asFormGroup.controls['houshold'].get('id')?.value
                    newLiabilityFormArray.push(this.createNewLiabilityForm({ householdId: id }));
                }
            }
        }
    }
}
