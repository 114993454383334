import { DocumentType } from '@ntag-ef/finprocess-enums/finadvisory';

import { DocumentMetaType } from '../enums'

import { IBackendBaseModel } from './backend-base.model';

/**
 * Dokumenten Daten
 * Dokument, das mehrere Dateien enthält. Ein Dokument ist mit einem Debtor, einem Haushalt, einer Immobilie, einer Finanzierungsmappe, oder einem Produktpaket + Produkt verknüpft.
 * Es muss genau eine der Ids DebtorId, HouseholdId, RealEstateId, FinancingMapId oder der Ids productPackageId + productId angegeben werden.
 */
export interface IDocumentModel extends IBackendBaseModel {

    /** Typ des Dokuments. */
    type: DocumentType;

    /** MetaTyp des Dokuments. Relevant für CxAdisory */
    metaType: DocumentMetaType;

    /** Die Position. */
    position?: number;

    /** Der Name. Nur relevant bei DocumentType Sonstiges (Other). */
    name?: string;

    /** Id des Debtors, an den das Dokument angehängt ist. */
    debtorId?: string;

    /** Id der Finanzierungsmappe, an die das Dokument angehängt ist. */
    financingMapId?: string;

    /** Id der Immobilie, an die das Dokument angehängt ist. */
    realEstateId?: string;

    /** Id des Haushalts, an die das Dokument angehängt ist. */
    householdId?: string;
    
    /** Id des Produktpakets, an das das Dokument angehängt ist. */
    productPackageId?: string;

    /** Id des Produkts, an das das Dokument angehängt ist. */
    productId?: string;
}
