<div class="wrapper">
    <mat-toolbar class="header mat-elevation-z3 header-toolbar">
        <div fxFlex="33%" fxLayout="row" fxLayoutAlign="start center">
            <button mat-button type="button" (click)="back()" matTooltip="Zurück zum Unterlagenbereich">
                <span class="material-icons md-36">arrow_back</span>
            </button>
        </div>
        <div fxFlex="33%" fxLayout="row" fxLayoutAlign="center center">
            <span>{{ customerName }}</span>
        </div>
        <div fxFlex="33%" fxLayout="row" fxLayoutAlign="end center">
            <button mat-button type="button" (click)="download()" matTooltip="Dokument herunterladen">
                <span class="material-icons md-36">file_download</span>
            </button>
        </div>
    </mat-toolbar>
    <waiter-component [isLoading]="isLoading">
        <div class="document">
            <div style="width:740px; font-size:medium;" class="alert-warning">
                Bei nachträglicher Datenänderungen werden bereits gesetzte Unterschriften gelöscht.
            </div>
            <br>
            <table class=msonormalTable [style.width]="!!width ? width : '740px'">
                <tr>
                    <td>&nbsp;</td>
                </tr>
                <tr style='height:18.0pt'>
                    <td nowrap style='width:50%; padding:0cm 3.5pt 0cm 3.5pt;' *ngIf='!!realEstate'>
                        <p class=msonormal>Finanzierungsobjekt:</p>
                        <p class=msonormal>{{helperService.hasValue(realEstate.type) ? (realEstate.type |
                            finprocessEnumTranslation:'RealEstateType') : 'Es erfolgten noch keine Angaben zum
                            Objekttyp'}}
                        </p>
                        <p class=msonormal>{{helperService.formatAddress(realEstate.street, realEstate.streetNumber,
                            realEstate.zip, realEstate.city)}}
                        </p>
                    </td>
                </tr>
                <tr>
                    <td style="width:740px; padding:0cm 3.5pt 0cm 3.5pt">&nbsp;</td>
                </tr>
            </table>

            <table class=msonormalTable width=741>
                <tr style='height:18.0pt'>
                    <td nowrap colspan=5 [style.width]="!!width ? width : '740px'"
                        style='border:solid gray 1.0pt; background:#D2DFE7; padding:0cm 3.5pt 0cm 3.5pt; height:18.0pt'>
                        <p class=msotitle><span style='color:#E2001A'>Datenschutzerklärung</span></p>
                    </td>
                </tr>
                <tr style='height:18.0pt'>
                    <td nowrap colspan=4 [style.width]="!!width ? width : '740px'"
                        style='border-top:none; border-left:solid gray 1.0pt; border-bottom:solid white 1.0pt; border-right:solid gray 1.0pt; padding:0cm 3.5pt 0cm 3.5pt; height:18.0pt'>
                        <p class=msonormal>An die UniCredit Bank Austria AG</p>
                    </td>
                </tr>

                <ng-container *ngFor="let d of debSignMap; let i=index">
                    <tr style='height:18.35pt'>
                        <td nowrap valign=top
                            style='width:138.25pt; border-top:none; border-left:solid gray 1.0pt; border-bottom:solid white 1.0pt; border-right:solid white 1.0pt; padding:0cm 3.5pt 0cm 3.5pt; height:18.35pt'>
                            <h1 style='margin-top:0cm'>&nbsp;</h1>
                            <h1 style='margin-top:0cm'> Kreditnehmer {{ i + 1 }} :</h1>
                        </td>
                        <td colspan=3 valign=top
                            style='width:414.85pt; border-top:none; border-left:none; border-bottom:solid white 1.0pt; border-right:solid gray 1.0pt; padding:0cm 3.5pt 0cm 3.5pt; height:18.35pt'>
                            <p class=msonormal>&nbsp;</p>
                            <p class=msonormal>{{d.debtor.title}} {{d.debtor.firstName}} {{d.debtor.lastName}}</p>
                        </td>
                    </tr>
                    <tr style='height:18.35pt'>
                        <td nowrap valign=top
                            style='width:138.25pt;border-top:none; border-left:solid gray 1.0pt; border-bottom:solid white 1.0pt; border-right:solid white 1.0pt; padding:0cm 3.5pt 0cm 3.5pt; height:18.35pt'>
                            <h1 style='margin-top:0cm'>Adresse:</h1>
                        </td>
                        <td colspan=3 valign=top
                            style='width:414.85pt; border-top:none; border-left:none; border-bottom:solid white 1.0pt; border-right:solid gray 1.0pt; padding:0cm 3.5pt 0cm 3.5pt; height:18.35pt'>
                            <p class="msonormal">
                                <span>{{d.debtor.homeStreet}} {{d.debtor.homeStreetNumber}}</span>
                                <span *ngIf="!!d.debtor.homeStairway">/{{d.debtor.homeStairway}}</span>
                                <span *ngIf="!!d.debtor.homeTop">/{{d.debtor.homeTop}}</span>
                            </p>
                            <p class=msonormal>{{d.debtor.homeZip}} {{d.debtor.homeCity}}&nbsp;</p>
                            <p class=msonormal>{{d.debtor.homeCountry}}</p>
                        </td>
                    </tr>
                </ng-container>

                <tr style='height:18.35pt'>
                    <td nowrap colspan=4 [style.width]="!!width ? width : '740px'"
                        style='border:solid gray 1.0pt; border-top:none; padding:0cm 3.5pt 0cm 3.5pt; height:18.0pt'>
                        <p class=msonormal>&nbsp;</p>
                        <p class=msonormal>Ich (Wir) erkläre(n), dass ich (wir) voll geschäftsfähig bin (sind),
                            über mein (unser) Vermögen bisher das Ausgleichs- oder Konkursverfahren nicht beantragt
                            wurde,
                            ich (wir) keinen Offenbarungseid geleistet habe(n) und in mein (unser) Vermögen
                            während der letzten fünf Jahre nicht Exekution geführt wurde.</p>
                        <p class=msonormal>&nbsp;</p>
                        <p class=msonormal>Ich (Wir) erkläre(n) mich <span style='line-height:107%'>(uns)
                                unwiderruflich damit einverstanden, dass im Rahmen der Geschäftsanbahnung
                                bzw. der bestehenden Geschäftsverbindung und auch nach Beendigung der
                                Geschäftsverbindung bis zur völligen Abwicklung</span></p>
                        <p class=msonormal>&nbsp;</p>
                        <ul class="msonormal">
                            <li>
                                <span style='font-size:10.0pt; line-height:107%;font-family:"Arial",sans-serif'>
                                    anlässlich der
                                    Beantragung meine (unsere) Identitätsdaten
                                    (Namen, Adresse, Geburtsdatum) und die Kredit- / Darlehensdaten (Höhe der
                                    Verbindlichkeit, Rückführungsmodalitäten)
                                </span>
                            </li>
                            <li>
                                <span style='font-size:10.0pt; line-height:107%; font-family:"Arial",sans-serif'>
                                    anlässlich der
                                    Gewährung oder Ablehnung des Kredites /
                                    Darlehens dieser Umstand allfällige später vereinbarte Änderungen der Kredit-
                                    /Darlehensabwicklung wie etwa vorzeitiger Rückzahlung oder
                                    Laufzeitverlängerung
                                </span>
                            </li>
                            <li>
                                <span style='font-size:10.0pt;line-height:107%; font-family:"Arial",sans-serif'>
                                    ein allfälliges vertragswidriges Kundenverhalten
                                </span>
                            </li>
                            <li>
                                <span style='font-size:10.0pt; line-height:107%; font-family:"Arial",sans-serif'>
                                    allfällige Schritte im Zusammenhang mit der Fälligstellung bzw. Rechtsverfolgung
                                </span>
                            </li>
                        </ul>
                        <p class=msonormal>&nbsp;</p>
                        <p class=msonormal>an die Konsumentenkreditevidenz beim Kreditschutzverband von
                            1870 mit Sitz in Wien gemeldet werden. Bei der Kleinkreditevidenz handelt es
                            sich um ein zu Zwecken des Gläubigerschutzes und der Risikominimierung
                            geführtes Informationsverbundsystem von Kreditinstituten, kreditgewährenden
                            Versicherungsunternehmen sowie Leasingunternehmen, dessen Betreiber der
                            Kreditschutzverband von 1870 ist. Die in der Kleinkreditevidenz gespeicherten
                            Daten werden ausschließlich an Kreditinstitute, kreditgewährende
                            Versicherungsunternehmen und Leasinggesellschaften mit Sitz in einem
                            Mitgliedstaat des europäischen Wirtschaftsraums (EWR) auf Anfrage
                            weitergegeben, soweit diese eine</p>
                        <p class=msonormal>Rechtspflicht zur korrekten Beurteilung des Kreditrisikos,
                            das ein/e Kreditwerber darstellt, trifft.</p>
                        <p class=msonormal>&nbsp;</p>
                        <p class=msonormal>Mir (Uns) ist bekannt, dass ich (wir) mich (uns) bei
                            diesbezüglichen Unklarheiten an meinen (unseren) Kundenbetreuer oder an den
                            Kreditschutzverband
                            von 1870 wenden kann (können), insbesondere auch, wenn ich (wir) meine
                            (unsere) Auskunfts-, Richtigstellungs-, Löschungs- und Widerspruchsrechte
                            Rechte als Betroffene/r geltend machen will (wollen).</p>
                        <p class=msonormal>&nbsp;</p>
                        <p class=msonormal>Es ist mir (uns) bewusst, dass die in dieser Kreditanfrage gemachten Angaben
                            von wesentlicher Bedeutung für die Einräumung eines Kredites sind,
                            und ich (wir) erkläre(n), dass diese vollständig und wahrheitsgetreu erfolgten.
                            Weiters willige(n) ich (wir) hiermit ausdrücklich ein, dass die von mir (uns)
                            vorgelegte/n Gehaltsbestätigung/en (Gehaltszettel) durch Rückfragen
                            bei meinem (unserem) Arbeitgeber überprüft und auch Details
                            der beabsichtigten Kreditaufnahme offengelegt werden dürfen.</p>
                        <p class=msonormal>&nbsp;</p>
                        <p class=msonormal><strong><span style='font-family:"Arial",sans-serif'>WARNUNG
                                </span></strong>gem. § 10 Abs 3 HIKrG: Ich (Wir) nehme(n) zur Kenntnis, dass
                            der Kredit nicht gewährt werden kann, wenn die Unicredit Bank Austria AG
                            nicht imstande ist eine Kreditwürdigkeitsprüfung vorzunehmen, weil ich (wir)
                            mich (uns) weigere(weigern), die für die Prüfung meiner (unserer)
                            Kreditwürdigkeit erforderlichen Informationen oder Nachweise vorzulegen.</p>
                        <p class=msonormal><b>&nbsp;</b></p>
                        <p class=msonormal style='line-height:normal'><strong><span
                                    style='font-family:"Arial",sans-serif'>Ich
                                    (Wir) nehme(n) zur Kenntnis, dass die
                                    Entscheidung über die Kreditgewährung ausschließlich bei der UniCredit Bank
                                    Austria AG liegt, und bis zum Vorliegen der Entscheidung der UniCredit Bank
                                    Austria AG niemand berechtigt ist, diesbezügliche Erklärungen abzugeben oder
                                    Zusagen zu tätigen.</span></strong></p>
                        <p class=msonormal style='line-height:normal'>&nbsp;</p>
                    </td>
                </tr>
            </table>

            <table class=msonormalTable style='width:741px;'>
                <tr style='height:70.5pt'>
                    <td nowrap colspan=4
                        style='border:solid gray 1.0pt; border-top:none; background:white; padding:0cm 3.5pt 0cm 3.5pt; height:70.5pt'>
                        <br>
                        <div *ngFor="let d of debSignMap; let i = index;">
                            <h1>
                                Datum:
                                <ng-container *ngIf="!!d.signature">
                                    {{d.signature.created | date:'dd.MM.yyyy, HH:mm'}} Uhr
                                </ng-container>
                            </h1>
                            <h1>Unterschrift Kreditnehmer: {{d.debtor.title}} {{d.debtor.firstName}}
                                {{d.debtor.lastName}}
                            </h1>
                            <br><br>
                            <div class="signature-container" float-left (click)="openSignatureModal($event, d)"
                                matTooltip="Unterschreiben">
                                <mat-icon id="signature" *ngIf="!d.signature">gesture</mat-icon>
                                <img *ngIf="!!d.signature" [src]="d.signature.content" />
                            </div>
                            <p class=msonormal>&nbsp;</p>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </waiter-component>
</div>