import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

import { FileController } from '../../controller/file/file.controller';
import { IImageOptions, IPdfOptions, IVideoOptions } from '../../interfaces';
import { FileHelper, HelperService } from '../../services';
import { GlobalSettings } from '../../utils';

/**
 * Komponente um Dokumente anzuzeigen und herunterladen
 */
@Component({
    selector: 'ucba-document-viewer',
    templateUrl: './document-viewer.component.html',
    styleUrls: ['./document-viewer.component.scss'],
})
export class DocumentViewerComponent {
    public title: string | undefined;
    public fileName: string | undefined;
    private mimeType: string | undefined;
    public imgOptions: IImageOptions | undefined;
    public pdfOptions: IPdfOptions;
    public videoOptions: IVideoOptions | undefined;
    private downloadInProgress: boolean | undefined;

    /** Aus nicht bekanntem Grund verliert pdfOptions den buffer. Hiermit wird der Wert für den Download zwischengespeichert */
    private downloadBlob: Blob | undefined;

    /**
     * Standard Konstruktor
     * 
     * @param { ActivatedRoute } route ActivatedRoute injector
     * @param { FileController } fileController fileController injector
     * @param { DomSanitizer } sanitizer sanitizer injector
     * @param { Location } location Location injector
     */
    public constructor(
        private route: ActivatedRoute,
        private fileController: FileController,
        private sanitizer: DomSanitizer,
        private location: Location,
    ) {
        const params = this.route.snapshot.queryParams;

        if (!!params) {
            this.title = params['title'];
        }

        if (!!params['fileId']) {
            this.fileName = params['fileName'];
            this.mimeType = params['mimeType'];
            this.fileController.downloadFile(params['fileId']).pipe(take(1)).subscribe(blob => {
                this.initDocumentViewer(blob).catch(e => { throw e; });
            });
        }
        else if (!!params['financingMapId']) {
            this.fileController.createFinancingMapFile(params['financingMapId']).pipe(take(1)).subscribe(blob => {
                if (!!blob) {
                    const now = HelperService.formatDate('YYYY-MM-DD');
                    const name = this.title?.replace(' ', '_');
                    this.fileName = `${now}_${name}_Export`;
                    this.mimeType = blob.type;
                    this.initDocumentViewer(blob).catch(e => { throw e; });
                }
            });
        }

        this.pdfOptions = {
            viewerFolder: 'assets/pdfjs',
            viewBookmark: false,
            openFile: false,
            download: false,
            print: true,
            page: 1,
        };
    }

    /**
     * Laden der Datei aus dem lokalen Zustand in den Viewer
     * 
     * @param {Blob} blob Datei als Blob
     */
    private async initDocumentViewer(blob?: Blob | null): Promise<void> {
        if (!!this.mimeType && !!blob) {
            this.downloadBlob = blob;

            if (HelperService.isValidImage(this.mimeType)) {
                const imgSrc = await FileHelper.blobToDataURL(blob);
                this.imgOptions = {
                    src: imgSrc,
                };
            }
            else if (HelperService.isPDF(this.mimeType)) {
                const buffer = await FileHelper.blobToArrayBuffer(blob);
                this.setPDFOptions(buffer);
            }
            else if (this.mimeType === GlobalSettings.MIME_MP4) {

                // @see https://laptrinhx.com/angular-javascript-open-blob-url-view-pdf-and-images-in-new-tab-2899316580/
                this.videoOptions = {
                    src: this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(blob)),
                };
            }
        }

    }

    /**
     * Setzen der pdf-Optionen mit gegebenem Buffer
     * 
     * @param {ArrayBuffer} buffer buffer
     */
    private setPDFOptions(buffer: ArrayBuffer) {
        this.pdfOptions.pdfSrc = new Uint8Array(buffer);
    }

    /**
     * navigiert zur vorherigen Seite
     */
    public back(): void {
        this.location.back();
    }

    /**
     * Herunterladen der geöffneten Datei
     * 
     */
    public download(): void {
        if (!this.downloadInProgress) {
            this.downloadInProgress = true;
            if (!!this.mimeType && !!this.fileName) {
                if (HelperService.isPDF(this.mimeType) && !!this.pdfOptions.pdfSrc && !!this.downloadBlob) {
                    FileHelper.downloadFileFromBlob(this.downloadBlob, this.fileName).then(() => {
                        this.downloadInProgress = false;
                    }).catch((e: unknown) => {
                        this.downloadInProgress = false;
                        throw e;
                    });
                }
                else if (HelperService.isValidImage(this.mimeType) && !!this.imgOptions) {
                    const data = this.imgOptions.src;
                    FileHelper.downloadFileFromDataUri(data, this.fileName).then(() => {
                        this.downloadInProgress = false;
                    }).catch((e: unknown) => {
                        this.downloadInProgress = false;
                        throw e;
                    });
                }
            }
        }
    }
}
