import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { IListTuple } from '@ucba/sdk/interfaces';

/**
 * Komponete, welche eine Selekt Dokument auswahl darstellt
 */
@Component({
  selector: 'cxad-select-document',
  templateUrl: './select-document.component.html',
  styleUrls: ['./select-document.component.scss'],
})
export class SelectDocumentComponent {

    @Input() public control!: AbstractControl;
    @Input() public labelText!: string;
    @Input() public array!: Array<IListTuple<string> & { iconName?: string }>;
    @Input() public comment?: string;

    @Output() public buttonClicked = new EventEmitter<string>();
    @Output() public selectedChanged = new EventEmitter<string | null>();

    /**
     * gibt den aktuellen AbstractControl als FormControl zurück
     *
     * @returns {FormControl} control
     */
    public get formControl(): FormControl {
        return this.control as FormControl;
    }

    /**
     * ween icon button geklickt wurde
     *
     * @param {string} id value id
     */
    public onButtonClicked(id?: string | null): void {
        if (!!id) {
            this.buttonClicked.emit(id)
        }
    }

    /**
     * gibt selected value zurück
     *
     * @param { string | null} id selected value
     */
    public onSelectedChanged(id?: string | null): void {
        this.selectedChanged.emit(id)
    }
}

