import { IFileModel } from '../../models';

/**
 * Action welche alle Files updated
 */
export class SdkPatchFile {
    public static readonly type = '[SDK File] PATCH';

    /**
     * Standard Konstruktor
     * 
     * @param {IFileModel} payload zu patchende Files
     */
    public constructor(public payload: IFileModel[]) { }
}

/**
 * Action welche ein neues File hinzufügt, sollten es bereits existieren wird add ignoriert
 */
export class SdkAddFile {
    public static readonly type = '[SDK File] ADD';

    /**
     * Standard Konstruktor
     * 
     * @param {IFileModel} payload neues File
     */
    public constructor(public payload: IFileModel) { }
}

/**
 * Action welche ein File Content updated
 */
export class UpdateFileContent {
    public static readonly type = '[SDK File] UPDATE_CONTENT';

    /**
     * Standard Konstruktor
     * 
     * @param {string} fileId Id des Files
     * @param {string} fileContent Content des Files
     */
    public constructor(public fileId: string, public fileContent: string) { }
}

/**
 * Action welche ein spezifisches File updated
 */
export class SdkUpdateFile {
    public static readonly type = '[SDK File] UPDATE';

    /**
     * Standard Konstruktor
     * 
     * @param {IFileModel} payload zu patchendes File
     */
    public constructor(public payload: IFileModel) { }
}

/**
 * Action welche mehere File updated
 */
export class SdkUpdateFiles {
    public static readonly type = '[SDK File] UPDATEMULTIPLE';

    /**
     * Standard Konstruktor
     * 
     * @param {IFileModel[]} payload zu patchende Files
     */
    public constructor(public payload: IFileModel[]) { }
}


/**
 * Action welche ein File entfernt, sollte es nicht existieren wird delete ignoriert
 */
export class SdkDeleteFile {
    public static readonly type = '[SDK File] DELETE';

    /**
     * Standard Konstruktor
     * 
     * @param {string} id id des zu löschenden File
     */
    public constructor(public id: string) { }
}

/**
 * Action welche mehrere Files entfernt, sollten keine existieren wird delete ignoriert
 */
export class SdkDeleteFiles {
    public static readonly type = '[SDK File] DELETE_MULTIPLE';

    /**
     * Standard Konstruktor
     * 
     * @param {string[]} ids ids der zu löschenden Files
     */
    public constructor(public ids: string[]) { }
}
