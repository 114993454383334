import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IStatusEntry } from '../../models';
import { BaseController } from '../base/base.controller';

/**
 * Controller welche alle Funkionalitäten der Financing Controller API bereithält
 */
@Injectable()
export class StatusEntryController extends BaseController {

    protected baseUrl = '/statusentry';

    /**
     * Standard Konstruktor
     *
     * @param {HttpClient} httpClient HttpClient injector
     */
    public constructor(
        protected override httpClient: HttpClient,
    ) {
        super(httpClient);
    }

    //#region --- Get ---

    /**
     * Gibt ein Array aus allen für den Nutzer sichbaren Entitäten
     * 
     * @returns {IStatusEntry[]} angeforderte Entitäten
     */
    public getEntities(): Observable<IStatusEntry[]> {
        return this.get(this.baseUrl);
    }

    /**
     * Gibt alle Entität zur übergebener FinanzierungsId zurück
     * 
     * @param {string} id id der Finanzierung
     * @returns {IStatusEntry[]} angeforderte Entitäten
     */
    public getEntitiesByFinancing(id: string): Observable<IStatusEntry[]> {
        return this.get(`${this.baseUrl}/${id}`);
    }

    //#endregion

}
