import { Component, Input } from '@angular/core';

/**
 * Komponente, welche eine Output Currency Feld darstellt
 */
@Component({
  selector: 'ucba-output-currency',
  templateUrl: './output-currency.component.html',
  styleUrls: ['./output-currency.component.scss'],
})

export class OutputCurrencyComponent {
    @Input() public labelText!: string;
    @Input() public comment: string | undefined;
    @Input() public value: number | undefined;

}
