import {
    AdditionalFacilities, AdditionalHeatingSystem, AnnexType, BasementConstruction, BasementUsage,
    BathroomFacilities, BuildingMaterialType, Collateralization, CreditPurpose, FlatOrientation, GarageFacilities, GarageLocation, HeatingSystem, HeatingSystemType,
    MarketValueType, ObjectPurposeType, Orientation, OutsideFacilities, ParkingSpace, RealEstateType, RoofConstruction,
    Terrace,
    WintergardenType,
} from '@ntag-ef/finprocess-enums/finadvisory';

import { YesNo } from '../enums';
import { ISODate } from '../types';

import { IBackendBaseModel } from './backend-base.model';


/**
 * Stores real estate data.
 */
export interface IRealEstateModel extends IBackendBaseModel {

    /** The financing map identifier. */
    financingMapId: string;

    /** The name of this Realestate */
    name?: string | null;

    /** The credit purpose. */
    creditPurpose?: CreditPurpose;

    /** The type of the object. */
    objectPurpose: ObjectPurposeType;

    /** The type. */
    type?: RealEstateType | null;

    /** The collateralization. */
    collateralization: Collateralization;

    /** If the object will be rented out. */
    willBeRentedOut?: boolean | null;


    /** The floor. */
    floor?: number | null;

    /** Whether still under construction. */
    underConstruction?: boolean | null;

    /** The year of construction. */
    yearOfConstruction?: number | null;

    /** The end of construction. */
    endOfConstruction?: ISODate | null;

    /** The start of construction. */
    startOfConstruction?: ISODate | null;

    /** The size of the lot. */
    lotSize?: number | null;

    /** The purchase price. */
    purchasePrice?: number;

    /** Whether the purchase price is relevant for additional costs. */
    purchasePriceRelevantForAdditionalCosts?: boolean | null;

    /** The lot price. */
    lotPrice?: number | null;

    /** Whether the lot price is relevant for additional costs. */
    lotPriceRelevantForAdditionalCosts?: boolean | null;

    /** The refurbishment costs. */
    refurbishmentCosts?: number | null;

    /** The construction costs. */
    constructionCosts?: number | null;

    /** Whether construction costs are relevant for additional costs. */
    constructionCostsRelevantForAdditionalCosts?: boolean | null;

    /** The development costs. */
    developmentCosts?: number | null;

    /** Whether development costs are relevant for additional costs. */
    developmentCostsRelevantForAdditionalCosts?: boolean | null;

    /** The construction costs overrun. */
    constructionCostsOverrun?: number | null;

    /** The other costs. */
    otherCosts?: number | null;

    /** The land register number. */
    landRegisterNumber?: string | null;

    /** The property register number. */
    propertyRegisterNumber?: string | null;

    /** The living room orientation. */
    livingRoomOrientation?: Orientation | null;

    /** The flat orientation. */
    flatOrientation?: FlatOrientation | null;

    /** The index of the energy. */
    energyIndex?: number | null;

    /** The net room area. */
    netRoomArea?: number | null;

    /** The area of the basement. */
    areaBasement?: number | null;

    /** The area ground floor. */
    areaGroundFloor?: number | null;

    /** The area of the upper floor. */
    areaUpperFloor?: number | null;

    /** The area of the additional upper floor. */
    areaAdditionalUpperFloor?: number | null;

    /** The area of the top floor. */
    areaTopFloor?: number | null;

    /** The area of the loggia. */
    areaLoggia?: number | null;

    /** The area of the roof terrace. */
    areaRoofTerrace?: number | null;

    /** The area of the balcony. */
    areaBalcony?: number | null;

    /** The area of the garden. */
    areaGarden?: number | null;

    /**  The area of the winter garden. */
    areaWinterGarden?: number | null;

    /** The area of the terrace. */
    areaTerrace?: number | null;

    /** The area of the garage. */
    areaGarage?: number | null;

    /** The area of the outbuildings. */
    areaOutbuildings?: number | null;

    /** The basement usage. Will only be used as single selection in Finservice  */
    basementUsage?: BasementUsage | null;

    /** The basement construction. */
    basementConstruction?: BasementConstruction | null;

    /** The roof construction. Will only be used as sinlge selection in Finservice */
    roofConstruction?: RoofConstruction | null;

    /** The terrace. Will only be used as sinlge selection in Finservice */
    terrace?: Terrace | null;

    /** The type of the wintergarden. */
    wintergardenType?: WintergardenType | null;

    /** The type of the annex. Will only be used as single selection in Finservice */
    annexType?: AnnexType | null;

    /** The refurbishment year of the inner buildung structure. */
    refurbishmentInnerBuildungStructure?: number | null;

    /** The refurbishment year of the roof. */
    refurbishmentRoof?: number | null;

    /** The refurbishment year of the outer building structure. */
    refurbishmentOuterBuildingStructure?: number | null;

    /** The refurbishment year of the common area. */
    refurbishmentCommonArea?: number | null;

    /** The refurbishment year of the windows. */
    refurbishmentWindow?: number | null;

    /** The refurbishment year of the heating. */
    refurbishmentHeating?: number | null;

    /** The refurbishment year of the electric sanitary. */
    refurbishmentElectricSanitary?: number | null;

    /** The refurbishment year of the wallpapering. */
    refurbishmentWallpapering?: number | null;

    /** The refurbishment year of the inner doors, stairs and floor covering. */
    refurbishmentInnerDoorsStairsFloorCovering?: number | null;

    /* The bathroom count. */
    bathroomCount?: number | null;

    /** The main bathroom facilities. */
    mainBathroomFacilities?: BathroomFacilities | null;

    /** The heating system. */
    heatingSystem?: HeatingSystem | null;

    /** The type of the heating system. */
    heatingSystemType?: HeatingSystemType | null;

    /** The additional heating system. */
    additionalHeatingSystem?: AdditionalHeatingSystem | null;

    /** The additional facilities. */
    additionalFacilities?: AdditionalFacilities | null;

    /** The parking space. */
    parkingSpace?: ParkingSpace | null;

    /** The garage location. */
    garageLocation?: GarageLocation | null;

    /** The outside facilities. */
    outsideFacilities?: OutsideFacilities | null;

    /** The longitude. */
    longitude?: number | null;

    /** The latitude. */
    latitude?: number | null;

    /** The zip. */
    zip?: string | null;

    /** The city. */
    city?: string | null;

    /** The street. */
    street?: string | null;

    /** The street number. */
    streetNumber?: string | null;

    /** The stairway. */
    stairway?: string | null;

    /** The top. */
    top?: string | null;

    /** The zip. */
    validatedZip?: string | null;

    /** The city. */
    validatedCity?: string | null;

    /** The street. */
    validatedStreet?: string | null;

    /** The street number. */
    validatedStreetNumber?: string | null;

    /** The Morix-Rating */
    morixRating?: number;

    /** The lot number. */
    lotNumber?: string | null;

    /** The market value. */
    marketValue?: number;

    /** The MarketValueType */
    marketValueType?: MarketValueType;

    /** The liebe valuation identifier. */
    liebeValuationId?: string | null;

    /** Whether the market value is discount calculation. */
    isMarketValueDiscountCalculation?: boolean | null;

    /** The facility of garage. */
    garageFacilities?: GarageFacilities | null;

    /** is the realestate prefabrivated construction */
    prefabricatedConstruction?: boolean | null;

    /** The building material type. */
    buildingMaterialType?: BuildingMaterialType | null;

    /* #region Trustee */

    /** The name of the trustee. */
    trusteeName?: string | null;

    /** The trustee phone number. */
    trusteePhoneNumber?: string | null;

    /** The trustee fax number. */
    trusteeFaxNumber?: string | null;

    /** The trustee zip. */
    trusteeZip?: string | null;

    /** The trustee city. */
    trusteeCity?: string | null;

    /** The trustee street. */
    trusteeStreet?: string | null;

    /** The trustee street number. */
    trusteeStreetNumber?: string | null;

    /* #endregion */
}

/**
 * Stores real estate data.
 */
export interface IRealEstateFormModel extends IBackendBaseModel {

    /** The financing map identifier. */
    financingMapId: string;

    /** The credit purpose. */
    creditPurpose?: CreditPurpose;

    /** The type of the object. */
    objectPurpose: ObjectPurposeType;

    /** The type. */
    type?: RealEstateType | null;

    /** The collateralization. */
    collateralization: Collateralization;

    /** If the object will be rented out. */
    willBeRentedOut?: YesNo | null;

    /** The floor. */
    floor?: number | null;

    /** Whether still under construction. */
    underConstruction?: YesNo | null;

    /** The year of construction. */
    yearOfConstruction?: number | null;

    /** The end of construction. */
    endOfConstruction?: ISODate | null;

    /** The start of construction. */
    startOfConstruction?: ISODate | null;

    /** The size of the lot. */
    lotSize?: number | null;

    /** The purchase price. */
    purchasePrice?: number;

    /** Whether the purchase price is relevant for additional costs. */
    purchasePriceRelevantForAdditionalCosts?: boolean | null;

    /** The lot price. */
    lotPrice?: number | null;

    /** Whether the lot price is relevant for additional costs. */
    lotPriceRelevantForAdditionalCosts?: boolean | null;

    /** The refurbishment costs. */
    refurbishmentCosts?: number | null;

    /** The construction costs. */
    constructionCosts?: number | null;

    /** Whether construction costs are relevant for additional costs. */
    constructionCostsRelevantForAdditionalCosts?: boolean | null;

    /** The development costs. */
    developmentCosts?: number | null;

    /** Whether development costs are relevant for additional costs. */
    developmentCostsRelevantForAdditionalCosts?: boolean | null;

    /** The construction costs overrun. */
    constructionCostsOverrun?: number | null;

    /** The other costs. */
    otherCosts?: number | null;

    /** The land register number. */
    landRegisterNumber?: string | null;

    /** The property register number. */
    propertyRegisterNumber?: string | null;

    /** The living room orientation. */
    livingRoomOrientation?: Orientation | null;

    /** The flat orientation. */
    flatOrientation?: FlatOrientation | null;

    /** The index of the energy. */
    energyIndex?: number | null;

    /** The net room area. */
    netRoomArea?: number | null;

    /** The area of the basement. */
    areaBasement?: number | null;

    /** The area ground floor. */
    areaGroundFloor?: number | null;

    /** The area of the upper floor. */
    areaUpperFloor?: number | null;

    /** The area of the additional upper floor. */
    areaAdditionalUpperFloor?: number | null;

    /** The area of the top floor. */
    areaTopFloor?: number | null;

    /** The area of the loggia. */
    areaLoggia?: number | null;

    /** The area of the roof terrace. */
    areaRoofTerrace?: number | null;

    /** The area of the balcony. */
    areaBalcony?: number | null;

    /** The area of the garden. */
    areaGarden?: number | null;

    /**  The area of the winter garden. */
    areaWinterGarden?: number | null;

    /** The area of the terrace. */
    areaTerrace?: number | null;

    /** The area of the garage. */
    areaGarage?: number | null;

    /** The area of the outbuildings. */
    areaOutbuildings?: number | null;

    /** The basement usage. Will only be used as single selection in Finservice  */
    basementUsage?: BasementUsage | null;

    /** The basement construction. */
    basementConstruction?: BasementConstruction[] | null;

    /** The roof construction. Will only be used as sinlge selection in Finservice */
    roofConstruction?: RoofConstruction | null;

    /** The terrace. Will only be used as sinlge selection in Finservice */
    terrace?: Terrace | null;

    /** The type of the wintergarden. */
    wintergardenType?: WintergardenType | null;

    /** The type of the annex. Will only be used as single selection in Finservice */
    annexType?: AnnexType | null;

    /** The refurbishment year of the inner buildung structure. */
    refurbishmentInnerBuildungStructure?: number | null;

    /** The refurbishment year of the roof. */
    refurbishmentRoof?: number | null;

    /** The refurbishment year of the outer building structure. */
    refurbishmentOuterBuildingStructure?: number | null;

    /** The refurbishment year of the common area. */
    refurbishmentCommonArea?: number | null;

    /** The refurbishment year of the windows. */
    refurbishmentWindow?: number | null;

    /** The refurbishment year of the heating. */
    refurbishmentHeating?: number | null;

    /** The refurbishment year of the electric sanitary. */
    refurbishmentElectricSanitary?: number | null;

    /** The refurbishment year of the wallpapering. */
    refurbishmentWallpapering?: number | null;

    /** The refurbishment year of the inner doors, stairs and floor covering. */
    refurbishmentInnerDoorsStairsFloorCovering?: number | null;

    /* The bathroom count. */
    bathroomCount?: number | null;

    /** The main bathroom facilities. */
    mainBathroomFacilities?: BathroomFacilities[] | null;

    /** The heating system. */
    heatingSystem?: HeatingSystem | null;

    /** The type of the heating system. */
    heatingSystemType?: HeatingSystemType | null;

    /** The additional heating system. */
    additionalHeatingSystem?: AdditionalHeatingSystem[] | null;

    /** The additional facilities. */
    additionalFacilities?: AdditionalFacilities[] | null;

    /** The parking space. */
    parkingSpace?: ParkingSpace[] | null;

    /** The garage location. */
    garageLocation?: GarageLocation | null;

    /** The outside facilities. */
    outsideFacilities?: OutsideFacilities[] | null;

    /** The longitude. */
    longitude?: number | null;

    /** The latitude. */
    latitude?: number | null;

    /** The zip. */
    zip?: string | null;

    /** The city. */
    city?: string | null;

    /** The street. */
    street?: string | null;

    /** The street number. */
    streetNumber?: string | null;

    /** The stairway. */
    stairway?: string | null;

    /** The top. */
    top?: string | null;

    /** The zip. */
    validatedZip?: string | null;

    /** The city. */
    validatedCity?: string | null;

    /** The street. */
    validatedStreet?: string | null;

    /** The street number. */
    validatedStreetNumber?: string | null;

    /** The lot number. */
    lotNumber?: string | null;

    /** The market value. */
    marketValue?: number;

    /** The MarketValueType */
    marketValueType?: MarketValueType;

    /** The liebe valuation identifier. */
    liebeValuationId?: string | null;

    /** Whether the market value is discount calculation. */
    isMarketValueDiscountCalculation?: boolean | null;

    /** The facility of garage. */
    garageFacilities?: GarageFacilities[] | null;

    /** is the realestate prefabrivated construction */
    prefabricatedConstruction?: YesNo | null;

    /** The building material type. */
    buildingMaterialType?: BuildingMaterialType | null;

    /* #region Trustee */

    /** The name of the trustee. */
    trusteeName?: string | null;

    /** The trustee phone number. */
    trusteePhoneNumber?: string | null;

    /** The trustee fax number. */
    trusteeFaxNumber?: string | null;

    /** The trustee zip. */
    trusteeZip?: string | null;

    /** The trustee city. */
    trusteeCity?: string | null;

    /** The trustee street. */
    trusteeStreet?: string | null;

    /** The trustee street number. */
    trusteeStreetNumber?: string | null;

    /* #endregion */
}
