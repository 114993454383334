<div fxLayout="column" *ngIf="!!control" >

    <mat-label >{{ labelText }}</mat-label>
    <mat-hint *ngIf="!!comment">({{ comment }})</mat-hint>

    <mat-form-field  [hideRequiredMarker]="!isRequired" appearance="outline">
        <ng-container [ngSwitch]="type">
            <!-- bug with type number https://stackoverflow.com/a/59027629/4758334 -->
            <input *ngSwitchCase="'number'" [min]="min" matInput type="number" [readonly]="readonly" [formControl]="formControl"
                [required]="!!isRequired" (keydown)="onlyNumbers($event)" (blur)="onBlur()" (keydown.enter)="onEnter()" #inputElement>

            <input *ngSwitchCase="'year'" matInput min="1900" max="2099" type="number" [readonly]="readonly"
                [formControl]="formControl" [required]="!!isRequired" (blur)="onBlur()" (keydown.enter)="onEnter()" #inputElement>

            <input *ngSwitchDefault matInput [type]="type" [readonly]="readonly" [formControl]="formControl"
                [required]="!!isRequired" (blur)="onBlur()" (keydown.enter)="onEnter()" #inputElement>
        </ng-container>

        <mat-error cxad-form-field-error *ngIf="control.invalid" [control]="control"></mat-error>
    </mat-form-field>
</div>
