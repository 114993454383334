import { IDocumentModel } from '../../models';

/**
 * Action welche alle Dokumente updated
 */
export class SdkPatchDocument {
    public static readonly type = '[SDK Document] PATCH';

    /**
     * Standard Konstruktor
     * 
     * @param {IDocumentModel} payload zu patchende Dokumente
     */
    public constructor(public payload: IDocumentModel[]) { }
}

/**
 * Action welche ein neues Dokumente hinzufügt, sollten es bereits existieren wird add ignoriert
 */
export class SdkAddDocument {
    public static readonly type = '[SDK Document] ADD';

    /**
     * Standard Konstruktor
     * 
     * @param {IDocumentModel} payload neues Dokument
     */
    public constructor(public payload: IDocumentModel) { }
}

/**
 * Action welche ein spezifisches Dokument updated
 */
export class SdkUpdateDocument {
    public static readonly type = '[SDK Document] UPDATE';

    /**
     * Standard Konstruktor
     * 
     * @param {IDocumentModel} payload zu patchendes Dokument
     */
    public constructor(public payload: IDocumentModel) { }
}

/**
 * Action welche ein Dokument entfernt, sollte es nicht existieren wird delete ignoriert
 */
export class SdkDeleteDocument {
    public static readonly type = '[SDK Document] DELETE';

    /**
     * Standard Konstruktor
     * 
     * @param {string} id id des zu löschenden Dokuments
     */
    public constructor(public id: string) { }
}

/**
 * Action welche mehrere Dokument entfernt, sollten sie nicht existieren wird delete ignoriert
 */
export class SdkDeleteDocuments {
    public static readonly type = '[SDK Document] DELETE_MULTIPLE';

    /**
     * Standard Konstruktor
     * 
     * @param {string[]} ids ids der zu löschenden Dokumente
     */
    public constructor(public ids: string[]) { }
}

/**
 * Action welche ein Dokument umbennent
 */
export class SdkRenameDocument {
    public static readonly type = '[SDK Document] RENAMEDOUCMENT';
    /**
     * Standard Konstruktor
     * 
     * @param {string} documentId id des zu löschenden Dokuments
     * @param {string} newName neuer Name des Dokuments
     */
    public constructor(public documentId: string, public newName: string) { }
}

/**
 * Action welche ein Dokumente aus der Auswahlliste entfernt, sollte es nicht existieren wird delete ignoriert
 */
export class SdkDeleteSelectedDocument {
    public static readonly type = '[SDK Document] DELETESELECTED';

    /**
     * Standard Konstruktor
     * 
     * @param {string} id neues Dokument
     */
    public constructor(public id: string) { }
}

/**
 * Action welche ein Dokumente aus der Auswahlliste hinzufügt oder entfernt
 */
export class SdkAddAndDeleteSelectedDocument {
    public static readonly type = '[SDK Document] ADDANDDELETESELECTED';

    /**
     * Standard Konstruktor
     * 
     * @param {string} id Id des hinzufügenden bzw. löschenden Dokuments
     */
    public constructor(public id: string) { }
}

/**
 * Action welche die Auswahlliste leert
 */
export class SdkClearSelectedDocuments {
    public static readonly type = '[SDK Document] CLEARSELECTEDDOCUMENTS';
}
