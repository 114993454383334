import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { ISignatureModel } from '../../models';
import { FileHelper } from '../../services/file/file.helper';
import { BaseController } from '../base/base.controller'

/**
 * Controller welche alle Funkionalitäten der Unterschriften Controller API bereithält
 */
@Injectable()

export class SignatureController extends BaseController {

    private baseUrl = '/signature';

    //#region --- Get ---

    /**
     * Gibt die Entität mit übergebener Id zurück
     * 
     * @param {string} id id der Entität
     * @returns {any} angeforderte Entität
     */
    public getEntity(id: string): Observable<ISignatureModel> {
        return this.get(`${this.baseUrl}/${id}`);
    }

    /**
     * Gibt alle Unterschriften (ohne Content) für die Finanzierung mit der übergebenen Id zurück.
     * 
     * @param {string} id id der Finanzierung
     * @returns {ISignatureModel[]} alle Unterschriften der Finanzierung
     */
    public getSignaturesByFinancing(id: string): Observable<ISignatureModel[]> {
        return this.get(`${this.baseUrl}/GetSignaturesByFinancing/${id}`);
    }

    /**
     * Abrufen des Contents der angegebenen Unterschrift
     * 
     * @param {string} signatureId Id der Unterschrift
     * @returns {Promise<Blob>} Unterschrift als Blob
     */
    public downloadSignature(signatureId: string): Observable<Blob | null> {
        const url = `${this.baseUrl}/Download/${signatureId}`;
        return this.download(url);
    }

    //#endregion

    //#region --- CRUD ---

    /**
     * neu anlegen einer Unterschrift
     * 
     * @param  {ISignatureModel} signature zu speichernde Unterschirft
     * @returns {Observable} http request observable
     */
    public createEntity(signature: Partial<ISignatureModel>): Observable<ISignatureModel> {
        if (!!signature.content) {
            const asBlob = !(signature.content instanceof Blob) ? FileHelper.dataURItoBlob(signature.content) : signature.content;

            const formData: FormData = new FormData();
            formData.append('Content', asBlob ?? '');
            formData.append('Signature', JSON.stringify({ ...signature, content: undefined }));

            return this.post(this.baseUrl, formData, {
                headers: new HttpHeaders(), // reset content-type to autodetect content-type
            });
        }
        else {
            return throwError(() => new Error('content is undefinded'));
        }
    }

    /**
     * Löscht die Entität mit übergebener Id
     * 
     * @param {string} id id der Entität
     * @returns {any} angeforderte Entität
     */
    public deleteEntity(id: string): Observable<void> {
        return this.delete(`${this.baseUrl}/${id}`);
    }

    //#endregion
}
