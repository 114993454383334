import {
    BankAustriaStatus,
    BathroomFacilities,
    CreditPurpose,
    DocumentType,
    EmployeeStatus,
    FlatOrientation,
    Gender,
    HeatingSystem,
    HeatingSystemType,
    HousingType,
    InterestMethod,
    LevelOfTraining,
    MaritalStatus,
    Orientation,
    RealEstateType,
    RoofConstruction,
} from '@ntag-ef/finprocess-enums/finadvisory';
import * as faker from 'faker';
import { v4 as uuid } from 'uuid';

import { FinancingMapStatus, LineType } from '../enums';
import { ICustomerModel, IDebtorModel, IDocumentModel, IFileModel, IFinancingMapModel, IHouseholdModel, IRealEstateModel } from '../models';
import { IPostDocumentFileRequest } from '../requests';
import { FileHelper, ModelFactoryService } from '../services';
import { PropertyTyp } from '../types';

/**
 * Klasse um Dummy Daten zu generieren
 */
export class FakeGenerator {

    /**
     * eine Funktion um dummy Daten für Kunden zu erstellen
     * 
     * @param  {number} count Anzahl der Kunden
     * @returns {Array<ICustomerModel>} generierte Kunden
     */
    public static createFakeCustomers(count: number): ICustomerModel[] {
        faker.setLocale('de');
        const countryNumbers = ['+43', '+49']

        const result: ICustomerModel[] = [];

        for (let i = 0; i < count; i++) {

            const firstName = faker.name.firstName(i % 2);
            const lastName = faker.name.lastName(i % 2);

            result.push({
                id: uuid(),
                consultantId: uuid(),
                gender: i % 2,
                title: faker.random.arrayElement(['Dr.', 'Prof.', null]),
                firstName,
                lastName,
                phoneNumber: faker.phone.phoneNumber(`${faker.random.arrayElement(countryNumbers)} ### ######`),
                mobileNumber: faker.phone.phoneNumber(`${faker.random.arrayElement(countryNumbers)} ### ######`),
                email: faker.internet.email(firstName, lastName),
                zip: faker.address.zipCode(),
                city: faker.address.city(),
                street: faker.address.streetName(),
                streetNumber: faker.datatype.number({ min: 1, max: 30 }).toString(),
                birthdate: faker.date.between('01/01/1990', '01/01/2000').toISOString(),
                created: new Date().toISOString(),
            });
        }

        return result;
    }

    /**
     * generiert eine Finanzierung mit dummy daten
     * 
     * @param  {string} customerId id des übergeordneten Kunden
     * @returns {IFinancingMapModel} Finanzierung mit dummydaten
     */
    public static generateFakeFinancingMap(customerId: string): IFinancingMapModel {

        const financingMap = ModelFactoryService.createFinancingMapModel(customerId);
        financingMap.cash = faker.datatype.number({ min: 10000, max: 20000, precision: 1000 });
        financingMap.prefinancingSales = faker.datatype.boolean();
        financingMap.redemptionInsurance = faker.datatype.number({ min: 1000, max: 20000, precision: 1000 });
        financingMap.prefinancingInsurance = faker.datatype.boolean();
        financingMap.bausparCreditBalance = faker.datatype.number({ min: 1000, max: 20000, precision: 1000 });
        financingMap.prefinancingBausparCreditBalance = faker.datatype.boolean();
        financingMap.funding = faker.datatype.number({ min: 1000, max: 20000, precision: 1000 });
        financingMap.prefinancingFunding = faker.datatype.boolean();
        financingMap.otherOwnCapital = faker.datatype.number({ min: 1000, max: 20000, precision: 1000 });
        financingMap.prefinancingOtherOwnCapital = faker.datatype.boolean();
        financingMap.financingFee = faker.datatype.number({ min: 100, max: 500, precision: 50 });
        financingMap.status = FinancingMapStatus.Open;
        financingMap.guarantees = false;
        financingMap.assumedDuration = faker.datatype.number({ min: 240, max: 360, precision: 12 });
        financingMap.interestMethod = faker.random.arrayElement(FakeGenerator.enumToArray(InterestMethod, [InterestMethod.FixFiveYears]));
        financingMap.requestedDebitRate = faker.datatype.number({ min: 0.5, max: 2, precision: 0.01 });
        financingMap.calculationVersion = null;
        financingMap.creditApplicationStatement = 'Generierte Test Finanzierung';

        return financingMap as IFinancingMapModel;
    }

    /**
     * generiert eine Liegenschaft mit dummy daten
     * 
     * @param  {string} financingMapId id des übergeordneten Finanzierung
     * @returns {IRealEstateModel} Liegenschaft mit dummydaten
     */
    public static generateFakeRealEstate(financingMapId: string): IRealEstateModel {
        const realEstate = ModelFactoryService.createRealEstateModel(financingMapId);
        realEstate.type = RealEstateType.Flat;
        realEstate.creditPurpose = CreditPurpose.Purchase;
        realEstate.stairway = faker.datatype.number({ min: 1, max: 10 }).toString();
        realEstate.top = faker.datatype.number({ min: 1, max: 10 }).toString();
        realEstate.purchasePrice = faker.datatype.number({ min: 200000, max: 300000, precision: 10000 });
        realEstate.purchasePriceRelevantForAdditionalCosts = true;
        realEstate.street = realEstate.validatedStreet = 'Schottenring';
        realEstate.streetNumber = realEstate.validatedStreetNumber = '17';
        realEstate.zip = realEstate.validatedZip = '1010';
        realEstate.city = realEstate.validatedCity = 'Wien';
        realEstate.landRegisterNumber = faker.datatype.number({ min: 1, max: 99999 }).toString().padStart(5, '0');
        realEstate.propertyRegisterNumber = faker.datatype.number({ min: 1, max: 30 }).toString();
        realEstate.underConstruction = false;
        realEstate.yearOfConstruction = faker.datatype.number({ min: 1900, max: 2018 });
        realEstate.netRoomArea = faker.datatype.number({ min: 50, max: 200, precision: 0.1 });
        realEstate.roofConstruction = RoofConstruction.NotAvailable;
        realEstate.flatOrientation = faker.random.arrayElement(FakeGenerator.enumToArray(FlatOrientation));
        realEstate.livingRoomOrientation = faker.random.arrayElement(FakeGenerator.enumToArray(Orientation));
        realEstate.bathroomCount = faker.datatype.number({ min: 1, max: 5 });
        realEstate.mainBathroomFacilities = BathroomFacilities.Toilet | BathroomFacilities.Shower;
        realEstate.heatingSystem = faker.random.arrayElement(FakeGenerator.enumToArray(HeatingSystem));
        realEstate.heatingSystemType = faker.random.arrayElement(FakeGenerator.enumToArray(HeatingSystemType));
        realEstate.latitude = 48.2160804;
        realEstate.longitude = 16.36685280000006;
        realEstate.willBeRentedOut = false;

        return realEstate as IRealEstateModel;
    }

    /**
     * generiert ein Haushalt mit dummy daten
     * 
     * @param  {string} financingMapId id des übergeordneten Finanzierung
     * @returns {IHouseholdModel} Haushalt mit dummydaten
     */
    public static generateFakeHousehold(financingMapId: string): IHouseholdModel {

        const household = ModelFactoryService.createHouseholdModel(financingMapId);
        household.operationalCosts = faker.datatype.number({ min: 500, max: 1000, precision: 50 });
        household.electricityCosts = faker.datatype.number({ min: 20, max: 100, precision: 10 });
        household.phoneCosts = faker.datatype.number({ min: 20, max: 50, precision: 10 });
        household.broadcastCosts = faker.datatype.number({ min: 10, max: 50, precision: 10 });
        household.carCosts = 0;
        household.insuranceCosts = 0;
        household.livingCosts = 0;
        household.otherCosts = 0;

        return household as IHouseholdModel;
    }

    /**
     * generiert einen Kreditnehmer mit dummy daten
     * 
     * @param  {string} householdId id des übergeordneten Haushaltes
     * @param  {ICustomerModel} customer Kunde mit adressdaten
     * @param  {string[]} bankaccounts liste aller Möglichen Kontoverbindungen (aus Stammdaten)
     * @returns {IDebtorModel} Kreditnehmer mit dummydaten
     */
    // eslint-disable-next-line complexity
    public static generateFakeDebitor(householdId: string, customer?: ICustomerModel | null, bankaccounts?: string[]): IDebtorModel {
        const debtor = ModelFactoryService.createDebtorModel(householdId, customer);
        const gender = faker.random.arrayElement([0, 1]);
        const firstname = !!customer && !!customer.firstName ? customer.firstName : faker.name.firstName(gender);
        const lastName = !!customer && !!customer.lastName ? customer.lastName : faker.name.lastName(gender);

        debtor.gender = [Gender.Male, Gender.Female][gender];
        debtor.customerNumber = faker.datatype.number({ min: 1, max: 99999999 }).toString().padStart(8, '0');
        debtor.firstName = firstname;
        debtor.lastName = lastName;
        debtor.homeZip = !!customer && !!customer.zip ? customer.zip : faker.datatype.number({ min: 1000, max: 9999 }).toString();
        debtor.homeCity = !!customer && !!customer.city ? customer.city : faker.address.city();
        debtor.homeStreet = !!customer && !!customer.street ? customer.street : faker.address.streetName();
        debtor.homeStreetNumber = !!customer && !!customer.streetNumber ? customer.streetNumber : faker.datatype.number({ min: 1, max: 30 }).toString();
        debtor.homeStairway = faker.datatype.number({ min: 1, max: 10 }).toString();
        debtor.homeTop = faker.datatype.number({ min: 1, max: 10 }).toString();
        debtor.homeCountry = faker.address.country();
        debtor.mobileNumber = !!customer && !!customer.mobileNumber ? customer.mobileNumber : '+43123456789';
        debtor.email = !!customer && !!customer.email ? customer.email : faker.internet.email(firstname, lastName);
        debtor.birthday = faker.date.between('01/01/1990', '01/01/2000').toISOString();
        debtor.birthcountry = faker.address.country();
        debtor.birthPlace = faker.address.city();
        debtor.netIncome = faker.datatype.number({ min: 2000, max: 4000, precision: 100 });
        debtor.fourteenSalariesPerYear = faker.datatype.boolean();
        debtor.citizenship = faker.random.arrayElement(['Österreich', 'Deutschland', 'Schweiz']);
        debtor.housingType = faker.random.arrayElement(FakeGenerator.enumToArray(HousingType));
        debtor.livingAtHomeAddressSince = faker.date.past().toISOString();
        debtor.taxResidence = faker.random.arrayElement(['Österreich', 'Deutschland', 'Schweiz']);
        debtor.maritalStatus = faker.random.arrayElement(FakeGenerator.enumToArray(MaritalStatus));
        debtor.childrenCount = faker.datatype.number({ min: 1, max: 3 });
        debtor.dependentPersonsCount = faker.datatype.number({ min: 2, max: 4 });
        debtor.levelOfTraining = faker.random.arrayElement(FakeGenerator.enumToArray(LevelOfTraining));
        debtor.employeeStatus = EmployeeStatus.Employed;
        debtor.employerName = 'NT Neue Technologie AG';
        debtor.profession = 'Tester';
        debtor.employedSince = faker.date.past(10, '01/01/2017').toISOString();
        debtor.bankAccount = faker.random.arrayElement(bankaccounts ?? 'BA-Neu,Erste Bank,BAWAG P.S.K.,Raiffeisen,Sparkasse,Volksbank,easybank'.split(','));
        debtor.bankAustriaStatus = faker.random.arrayElement(FakeGenerator.enumToArray(BankAustriaStatus));
        debtor.vehicleCount = 1;
        debtor.lineType = LineType.TwoLine;

        return debtor as IDebtorModel;
    }

    /**
     * Erstellt einen fertiges Requestobjekt zum Dokumentenupload. File hat leeren content
     * 
     * @param  {string} name name inkl. endung
     * @param  {PropertyTyp} referenceProperty Referenztyp des elternobjektes
     * @param  {string} referenceId referenz id
     * @param  {DocumentType} type Dokumenten Typ
     * @returns {IPostDocumentFileRequest} request zum Dokument erstellen
     */
    public static generateFakeDocument(name: string, referenceProperty: PropertyTyp, referenceId: string, type?: DocumentType): IPostDocumentFileRequest {

        const parts = FileHelper.getNameParts(name);
        const blob = FileHelper.dataURItoBlob('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAANSURBVBhXY2BgYPgPAAEEAQBwIGULAAAAAElFTkSuQmCC');
        const file = new File([blob], name, { type: blob.type });

        const newDocument: Partial<IDocumentModel> = { type, name };
        newDocument[referenceProperty] = referenceId;

        const newFile: Partial<IFileModel> = {
            name: parts.name,
            mimeType: file.type,
            length: file.size,
            extension: parts.extension,
        };

        return {
            document: newDocument,
            file: newFile,
            content: file,
        };
    }

    /**
     * Wandelt ein Enum in ein number Array um
     * 
     * @param {any} enumObject zu wandelndes Enum
     * @param {any} exclude Werte die Ignoriert werden sollen
     * @returns {number[]} gewandenltes number Array
     */
    private static enumToArray<T extends Record<string | number, unknown>>(enumObject: T, exclude?: unknown[]): number[] {
        return Object.entries(enumObject).reduce<number[]>(((acc, curr) => (typeof curr[1] === 'number' && (!exclude || !exclude.includes(curr[1])) ? [...acc, curr[1]] : acc)), []);
    }
}
