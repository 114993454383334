import { Component, Input } from '@angular/core';

/**
 * Komponente, welche eine Output Feld darstellt
 */
@Component({
  selector: 'cxad-output',
  templateUrl: './output.component.html',
  styleUrls: ['./output.component.scss'],
})
export class OutputComponent {
    @Input() public labelText!: string;
    @Input() public comment: string | undefined;
    @Input() public value: string | undefined;
    @Input() public funds: number | undefined;
}
