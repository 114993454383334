import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FileController } from '@ucba/sdk/controller';
import { FileHelper } from '@ucba/sdk/services';
import { take } from 'rxjs';

/**
 * Komponente an den Kunden Einwilligungserklärung bestätigen
 */
@Component({
    selector: 'cxad-consent',
    templateUrl: './consent.component.html',
    styleUrls: ['./consent.component.scss'],
})
export class ConsentComponent implements OnInit, OnDestroy {
    @ViewChild('actions') private actions: ElementRef | undefined;
    public isNotScrolled = true;

    /**
     * Standard Konstruktor
     *
     * @param {MatDialogRef} dialogRef dialog ref injector
     * @param {FileController} fileController FileController injector
     * @param {TranslateService} translate TranslateService injector
     */
    public constructor(
        public dialogRef: MatDialogRef<ConsentComponent>,
        private fileController: FileController,
        private translate: TranslateService,

    ) { }

    /**
     * Angular Hook zum initialisieren
     *
     */
    public ngOnInit(): void {
        window.addEventListener('scroll', event => {
            this.scrollEvent(event);
        }, true);
        this.isNotScrolled = true;
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        window.removeEventListener('scroll', event => {
            this.scrollEvent(event)
        }, true);
    }

    /**
     * Beim scrolling ausblenden oder anzeigen BtnDown
     *
     * @param {Event} event event
     */
    public scrollEvent(event: Event): void {
        if (!!event && !!event.target) {
            const target = event.target as HTMLDivElement;
            if (target.offsetHeight + target.scrollTop + 70 >= target.scrollHeight) {
                this.isNotScrolled = false;
            } else {
                this.isNotScrolled = true;
            }
        }
    }

    /**
     * Funktion, die bis zum Ende scrollt
     */
    public onBtnDown(): void {
        if (!!this.actions) {
            this.actions.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }
    }

    /**
     *download DSGVO
     */
    public onDownloadDsgvo(): void {
        this.fileController.downloadDsgvo()
            .pipe(take(1))
            .subscribe(blob => {
                if (!!blob) {
                    FileHelper.downloadFileFromBlob(blob, 'DSGVO').catch(e => { throw e; });
                }
                else {
                    throw new Error(this.translate.instant('modals.consent.content.warning'));
                }
            });
    }

    /**
     * prüfung, dass der Benutzer auf Bestätigung klickt
     */
    public onConfirm(): void {
        this.dialogRef.close(true);
    }
}
