import { DocumentType, EmployeeStatus, MaritalStatus, OrganisationalUnitResponsibilityType } from '@ntag-ef/finprocess-enums/finadvisory';

import { DocumentMetaType } from '../enums';
import { IFieldCardVisibilityRealEstate } from '../interfaces';
import { IDebtorModel, IDocumentModel, IFinancingMapModel, ILiabilityModel } from '../models';
import { HelperService } from '../services/helper/helper.service';
import { PropertyTyp } from '../types';

export interface IDocumentValidation {
    /** the document type */
    type: DocumentType;

    /** der meta document type um das Dokment korrekt in der Kundeninfo anzuzeigen*/
    metaType?: DocumentMetaType;

    /** propertyname with this document is connected  */
    referenceProperty: PropertyTyp;

    /** show this document for any debtor */
    useForAnyDebtor?: boolean;

    /** responsibility to specific vpc */
    responsibility?: OrganisationalUnitResponsibilityType;

    /** the page name to navigate. Only for Signature Documents */
    pageName?(model?: unknown): string;


    /** is the document required for submit financingmap */
    required(model?: unknown, ...params: unknown[]): boolean;

    /** is the document visible in tag list */
    visible(model?: unknown, ...params: unknown[]): boolean;
}

/**
 * map to display document selection
 */

export const DOCUMENT_CARD_VALIDATION_MAP: { [key: string]: IDocumentValidation[] } = {
    debtor: [
        {
            type: DocumentType.RegisterConfirmation,
            metaType: DocumentMetaType.Identity,
            referenceProperty: 'debtorId',
            required: () => false,
            visible: () => true,
        },
        {
            type: DocumentType.PhotoId,
            metaType: DocumentMetaType.Identity,
            referenceProperty: 'debtorId',
            required: () => true,
            visible: () => true,
        },
    ],
    household: [
        {
            type: DocumentType.Lease,
            metaType: DocumentMetaType.Income,
            referenceProperty: 'debtorId',
            useForAnyDebtor: true,
            required: () => false,
            visible: () => true,
        },
        {
            type: DocumentType.AlimonyIncome,
            metaType: DocumentMetaType.Income,
            referenceProperty: 'debtorId',
            useForAnyDebtor: true,
            required: () => false,
            visible: () => true,
        },
        {
            type: DocumentType.AlimonyToBePaid,
            metaType: DocumentMetaType.Income,
            referenceProperty: 'debtorId',
            useForAnyDebtor: true,
            required: () => false,
            visible: () => true,
        },
        {
            type: DocumentType.SalaryStatement,
            metaType: DocumentMetaType.Income,
            referenceProperty: 'debtorId',
            useForAnyDebtor: true,
            required: (debtor: IDebtorModel) => debtor.employeeStatus === EmployeeStatus.UnpaidLeave ||
                (HelperService.getValue(debtor.netIncome, 0) > 0 && debtor.employeeStatus !== EmployeeStatus.Retiree),
            visible: () => true,
        },
        {
            type: DocumentType.PensionNotice,
            metaType: DocumentMetaType.Income,
            referenceProperty: 'debtorId',
            useForAnyDebtor: true,
            required: (debtor: IDebtorModel) => debtor.employeeStatus === EmployeeStatus.Retiree,
            visible: () => true,
        },
        {
            type: DocumentType.ParentalLeaveNotice,
            metaType: DocumentMetaType.Income,
            referenceProperty: 'debtorId',
            useForAnyDebtor: true,
            required: (debtor: IDebtorModel) => debtor.employeeStatus === EmployeeStatus.UnpaidLeave,
            visible: () => true,
        },
        {
            type: DocumentType.DivorceSettlementAgreement,
            metaType: DocumentMetaType.Income,
            referenceProperty: 'debtorId',
            useForAnyDebtor: true,
            required: () => false,
            visible: (debtor: IDebtorModel) => debtor.maritalStatus === MaritalStatus.Divorced,
        },
        {
            type: DocumentType.EmploymentContract,
            metaType: DocumentMetaType.Income,
            referenceProperty: 'debtorId',
            useForAnyDebtor: true,
            required: () => false,
            visible: () => true,
        },
        {
            type: DocumentType.InsuranceApplications,
            metaType: DocumentMetaType.Income,
            referenceProperty: 'householdId',
            required: () => false,
            visible: () => true,
        },
        {
            type: DocumentType.ChildcareAllowanceNotice,
            metaType: DocumentMetaType.Income,
            referenceProperty: 'householdId',
            required: () => false,
            visible: () => true,
        },
        {
            type: DocumentType.RegionalHealthInsuranceStatement,
            metaType: DocumentMetaType.Income,
            referenceProperty: 'householdId',
            required: () => false,
            visible: () => true,
        },
        {
            type: DocumentType.BookingNoteSocialInsurance,
            metaType: DocumentMetaType.Income,
            referenceProperty: 'debtorId',
            useForAnyDebtor: true,
            responsibility: OrganisationalUnitResponsibilityType.SelfEmployed,
            required: (debtor: IDebtorModel) => debtor.employeeStatus === EmployeeStatus.SelfEmployed,
            visible: () => true,
        },
        {
            type: DocumentType.DebitNoteTaxAuthority,
            metaType: DocumentMetaType.Income,
            referenceProperty: 'debtorId',
            useForAnyDebtor: true,
            responsibility: OrganisationalUnitResponsibilityType.SelfEmployed,
            required: (debtor: IDebtorModel) => debtor.employeeStatus === EmployeeStatus.SelfEmployed,
            visible: () => true,
        },
        {
            type: DocumentType.BookingNoteHealthInsurance,
            metaType: DocumentMetaType.Income,
            referenceProperty: 'debtorId',
            useForAnyDebtor: true,
            responsibility: OrganisationalUnitResponsibilityType.SelfEmployed,
            required: () => false,
            visible: () => true,
        },
        {
            type: DocumentType.IncomeTaxReturn,
            metaType: DocumentMetaType.Income,
            referenceProperty: 'debtorId',
            useForAnyDebtor: true,
            responsibility: OrganisationalUnitResponsibilityType.SelfEmployed,
            required: (debtor: IDebtorModel) => debtor.employeeStatus === EmployeeStatus.SelfEmployed,
            visible: (debtor: IDebtorModel) => HelperService.getValue(debtor.otherIncome, 0) > 0 || debtor.employeeStatus === EmployeeStatus.SelfEmployed,
        },
        {
            type: DocumentType.IncomeTaxAssessment,
            metaType: DocumentMetaType.Income,
            referenceProperty: 'debtorId',
            useForAnyDebtor: true,
            responsibility: OrganisationalUnitResponsibilityType.SelfEmployed,
            required: (debtor: IDebtorModel) => debtor.employeeStatus === EmployeeStatus.SelfEmployed,
            visible: () => true,
        },
        {
            type: DocumentType.BalanceList,
            metaType: DocumentMetaType.Income,
            referenceProperty: 'debtorId',
            useForAnyDebtor: true,
            responsibility: OrganisationalUnitResponsibilityType.SelfEmployed,
            required: (debtor: IDebtorModel) => debtor.employeeStatus === EmployeeStatus.SelfEmployed,
            visible: () => true,
        },
        {
            type: DocumentType.BalanceSheet,
            metaType: DocumentMetaType.Income,
            referenceProperty: 'debtorId',
            useForAnyDebtor: true,
            responsibility: OrganisationalUnitResponsibilityType.SelfEmployed,
            required: (debtor: IDebtorModel, documents: IDocumentModel[]) => (
                debtor.employeeStatus === EmployeeStatus.SelfEmployed &&
                (!Array.isArray(documents) || !documents.some(({ type }) => type === DocumentType.AccountingOnCashBasis))
            ),
            visible: () => true,
        },
        {
            type: DocumentType.AccountingOnCashBasis,
            metaType: DocumentMetaType.Income,
            referenceProperty: 'debtorId',
            useForAnyDebtor: true,
            responsibility: OrganisationalUnitResponsibilityType.SelfEmployed,
            required: (debtor: IDebtorModel, documents: IDocumentModel[]) => (
                debtor.employeeStatus === EmployeeStatus.SelfEmployed &&
                (!Array.isArray(documents) || !documents.some(({ type }) => type === DocumentType.BalanceSheet))
            ),
            visible: () => true,
        },
    ],
    financingplan: [
        {
            type: DocumentType.OwnCapitalDocument,
            metaType: DocumentMetaType.Other,
            referenceProperty: 'financingMapId',
            required: () => false,
            visible: () => true,
        },
        {
            type: DocumentType.AccountStatement,
            metaType: DocumentMetaType.Other,
            referenceProperty: 'debtorId',
            useForAnyDebtor: true,
            required: () => false,
            visible: () => true,
        },
        {
            type: DocumentType.ExistingFinancingDocument,
            metaType: DocumentMetaType.Income,
            referenceProperty: 'householdId',
            required: () => false,
            visible: (liabilities: ILiabilityModel[]) => liabilities.some(HelperService.isLiabilityDirty),
        },
        {
            type: DocumentType.PersistingFinancingDocument,
            metaType: DocumentMetaType.Income,
            referenceProperty: 'householdId',
            required: () => false,
            visible: (liabilities: ILiabilityModel[]) => liabilities.some(HelperService.isLiabilityDirty),
        },
        {
            type: DocumentType.ReclaimAssociation,
            metaType: DocumentMetaType.Other,
            referenceProperty: 'debtorId',
            useForAnyDebtor: true,
            required: () => false,
            visible: () => true,
        },
    ],
    realestate: [
        {
            type: DocumentType.EnergyCertificate,
            metaType: DocumentMetaType.Object,
            referenceProperty: 'realEstateId',
            required: () => false,
            visible: () => true,
        },
        {
            type: DocumentType.LandRegisterExcerptContracts,
            metaType: DocumentMetaType.Object,
            referenceProperty: 'realEstateId',
            required: () => false,
            visible: () => true,
        },
        {
            type: DocumentType.PurchaseAgreement,
            metaType: DocumentMetaType.Object,
            referenceProperty: 'realEstateId',
            required: () => false,
            visible: (conditions: IFieldCardVisibilityRealEstate) => conditions.isCreditPurposePuReTeO,
        },
        {
            type: DocumentType.Map,
            metaType: DocumentMetaType.Object,
            referenceProperty: 'realEstateId',
            required: () => false,
            visible: (conditions: IFieldCardVisibilityRealEstate) => conditions.isCreditPurposePuReTeO && conditions.isTypeHouse,
        },
        {
            type: DocumentType.ConstructionPermit,
            metaType: DocumentMetaType.Object,
            referenceProperty: 'realEstateId',
            required: () => false,
            visible: (conditions: IFieldCardVisibilityRealEstate) => conditions.isCreditPurposeCoEx && conditions.isTypeHouse,
        },
        {
            type: DocumentType.ConstructionCostBreakdown,
            metaType: DocumentMetaType.Object,
            referenceProperty: 'realEstateId',
            required: () => false,
            visible: (conditions: IFieldCardVisibilityRealEstate) => conditions.isCreditPurposeCoEx && conditions.isTypeHouse,
        },
        {
            type: DocumentType.FloorPlan,
            metaType: DocumentMetaType.Object,
            referenceProperty: 'realEstateId',
            required: () => true,
            visible: () => true,
        },
        {
            type: DocumentType.BrokerReport,
            metaType: DocumentMetaType.Object,
            referenceProperty: 'realEstateId',
            required: () => false,
            visible: () => true,
        },
        {
            type: DocumentType.FireInsurance,
            metaType: DocumentMetaType.Object,
            referenceProperty: 'realEstateId',
            required: () => false,
            visible: () => true,
        },
        {
            type: DocumentType.Photos,
            metaType: DocumentMetaType.Object,
            referenceProperty: 'realEstateId',
            required: () => false,
            visible: () => true,
        },
        {
            type: DocumentType.LandRegisterExcerpt,
            metaType: DocumentMetaType.Object,
            referenceProperty: 'realEstateId',
            required: () => false,
            visible: () => true,
        },
        {
            type: DocumentType.BenefitAnalysis,
            metaType: DocumentMetaType.Object,
            referenceProperty: 'realEstateId',
            required: () => false,
            visible: (conditions: IFieldCardVisibilityRealEstate) => conditions.isTypeFlInRoSe,
        },
    ],
    other: [
        {
            type: DocumentType.Other,
            metaType: DocumentMetaType.Other,
            referenceProperty: 'financingMapId', // alle Ids möglich, fallback FinancingMap
            required: () => false,
            visible: () => true,
        },
    ],
    signature: [
        {
            type: DocumentType.PrivacyStatementSignature,
            referenceProperty: 'financingMapId',
            pageName: () => 'template-privacy-statement',
            required: () => true,
            visible: () => true,
        },
    ],
    householdDocuments: [
        {
            type: DocumentType.HouseholdBalanceSignature,
            referenceProperty: 'householdId',
            required: () => true,
            visible: () => true,
        },
        {
            type: DocumentType.CompositionOfOtherIncomeSignature,
            referenceProperty: 'householdId',
            required: () => true,
            visible: () => true,
        },
        {
            type: DocumentType.SelfDisclosure,
            referenceProperty: 'debtorId',
            required: () => true,
            visible: () => true,
        },
        // {
        //     type: DocumentType.PensionHouseholdBalanceSignature,
        //     referenceProperty: 'householdId',
        //     required: () => true,
        //     visible: () => true,
        // },
        {
            type: DocumentType.AdditionalSheetSignature,
            referenceProperty: 'householdId',
            required: () => true,
            visible: () => true,
        },
    ],
    requiredDocuments: [
        {
            type: DocumentType.EmployerApproval,
            referenceProperty: 'financingMapId',
            required: () => true,
            visible: (financingMap: IFinancingMapModel) =>
                !!financingMap.requiredDocuments && financingMap.requiredDocuments.includes(DocumentType.EmployerApproval),
        },
        {
            type: DocumentType.OfferRequiredInsurance,
            referenceProperty: 'financingMapId',
            required: () => true,
            visible: (financingMap: IFinancingMapModel) =>
                !!financingMap.requiredDocuments && financingMap.requiredDocuments.includes(DocumentType.OfferRequiredInsurance),
        },
        {
            type: DocumentType.PurchaseAgreement,
            referenceProperty: 'realEstateId',
            required: () => true,
            visible: (financingMap: IFinancingMapModel) =>
                !!financingMap.requiredDocuments && financingMap.requiredDocuments.includes(DocumentType.PurchaseAgreement),
        },
    ],
};

/** validations for all regular documents */
export const DOCUMENT_CARD_VALIDATION_MAP_REGULAR = [
    ...DOCUMENT_CARD_VALIDATION_MAP['debtor'],
    ...DOCUMENT_CARD_VALIDATION_MAP['household'],
    ...DOCUMENT_CARD_VALIDATION_MAP['financingplan'],
    ...DOCUMENT_CARD_VALIDATION_MAP['realestate'],
    ...DOCUMENT_CARD_VALIDATION_MAP['other'],
];
