import { AbstractControl, ValidationErrors } from '@angular/forms';
import { electronicFormatIBAN, isValidIBAN } from 'ibantools';

/**
 * validator class for iban validation
 * https://simplify.github.io/ibantools/
 */
export class IbanValidation {

    /**
     * validate IBAN format
     *
     * @param {AbstractControl} control das zu castende AbstractControl
     * @returns { ValidationErrors } Invalid IBAN
     */
    public static valid(control: AbstractControl): ValidationErrors | null {
        if (!!control.value && control.value.length > 0) {
            const testIBAN = electronicFormatIBAN(control.value);
            if (!testIBAN || !isValidIBAN(testIBAN)) {
                return { invalidIBAN: true };
            }
        }
        return null;
    }
}
