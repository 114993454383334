import { Inject, Injectable } from '@angular/core';
import { HubConnection, HubConnectionState, IHttpConnectionOptions, ILogger, LogLevel } from '@microsoft/signalr';
import { NGXLogger } from 'ngx-logger';
import { forkJoin, take } from 'rxjs';

import { CustomerController, DocumentController, FileController, FinancingController, LiabilityController, MessageController, NewLiabilityController, RealEstateController, SignatureController } from '../../controller';
import { DebtorController } from '../../controller/debtor/debtor.controller';
import { HouseholdController } from '../../controller/household/household.controller';
import { ApplicationType, EntityType, SystemType } from '../../enums';
import { ICustomerModel, IFinancingMapLightModel, IFinancingMapModel, IUserModel } from '../../models';
import { DataService, ThirdPartyFactoryService } from '../../services';
import { APPLICATION_TYPE, GlobalSettings } from '../../utils';
import { HelperService } from '../helper/helper.service';

interface IFunctionItem {
    onCreateFn?: (entityId: string, additional?: unknown) => void,
    onUpdateFn?: (entityId: string, additional?: unknown) => void,
    onDeleteFn?: (entityId: string) => void,
}

/**
 * Eigener Logger für SignalR Connection
 */
class CustomSignalRLogger implements ILogger {

    /**
     * Standard Konstruktor
     * 
     * @param {NGXLogger} logger NGXLogger injector
     */
    public constructor(private logger: NGXLogger) { }

    /**
     * überladene Logging Funktion
     * 
     * @override
     * @param {LogLevel} logLevel das log level
     * @param {string} message die zu loggende Nachricht
     */
    public log(logLevel: LogLevel, message: string) {
        if ([LogLevel.Error, LogLevel.Critical].includes(logLevel)) {
            this.logger.error(new Error(message));
            GlobalSettings.hubError = true;
        }
        else if (logLevel === LogLevel.Warning) {
            this.logger.warn(new Error(message));
        }
        else if ([LogLevel.Trace, LogLevel.Debug].includes(logLevel)) {
            this.logger.debug(message);
        }
        else if (logLevel === LogLevel.Information) {
            this.logger.info(message);
        }
    }
}

/**
 * Verwaltet den Anwendungshub um die anfragen entgegenzunehmen
 */
@Injectable()
export class ApplicationHub {

    //#region Hub Variablen

    private hubConnection: HubConnection | undefined;
    private functionMap: Record<number, IFunctionItem> = {};

    /**
     * getter welcher prüft ob es keine Verbindung gibt (unabhängig ob offen oder geschlossen)
     * 
     * @returns {boolean} ist Verbindung geschlossen
     */
    public get isClosed(): boolean {
        return this.hubConnection === undefined;
    }

    //#endregion

    /**
     * StandardKonstruktor
     * 
     * @param {CustomerController} customerController CustomerController injector
     * @param {FinancingController} financingController FinancingController injector
     * @param {RealEstateController} realEstateController RealEstateController injector
     * @param {HouseholdController} householdController HouseholdController injector
     * @param {DebtorController} debtorController DebtorController injector
     * @param {LiabilityController} liabilityController LiabilityController injector
     * @param {NewLiabilityController} newLiabilityController NewLiabilityController injector
     * @param {DocumentController} documentController DocumentController injector
     * @param {FileController} fileController FileController injector
     * @param {SignatureController} signatureController SignatureController injector
     * @param {MessageController} messageController MessageController injector
     * @param {ThirdPartyFactoryService} thirdPartyFactoryService ThirdPartyFactoryService injector
     * @param {NGXLogger} logger NGXLogger injector
     * @param {DataService} dataService DataService injector
     * @param {APPLICATION_TYPE} applicationType APPLICATION_TYPE injector
     */
    public constructor(
        private customerController: CustomerController,
        private financingController: FinancingController,
        private realEstateController: RealEstateController,
        private householdController: HouseholdController,
        private debtorController: DebtorController,
        private liabilityController: LiabilityController,
        private newLiabilityController: NewLiabilityController,
        private documentController: DocumentController,
        private fileController: FileController,
        private signatureController: SignatureController,
        private messageController: MessageController,
        private thirdPartyFactoryService: ThirdPartyFactoryService,
        private logger: NGXLogger,
        private dataService: DataService,
        @Inject(APPLICATION_TYPE) private applicationType: ApplicationType,
    ) {
        this.initFunctionMap();
    }

    //#region direktes Hub Handling

    /**
     * fügt die Connection einer Hub Gruppe hinzu (BranchId, UserId, CustomerId, FinancingMapId)
     * 
     * @param {string} groupName Gruppen Name
     */
    public async addToGroup(groupName: string | string[]): Promise<void> {
        if (!!this.hubConnection && this.hubConnection.state === HubConnectionState.Connected && !!groupName) {
            if (typeof groupName === 'string') {
                groupName = [groupName];
            }

            for (const name of groupName) {
                await this.hubConnection.invoke('addtogroup', name);
            }
        }
    }

    /**
     * entfernt die Connection aus eine Hub Gruppe
     * 
     * @param {string} groupName Gruppen Name
     */
    public async removeFromGroup(groupName: string | string[]): Promise<void> {
        if (!!this.hubConnection && this.hubConnection.state === HubConnectionState.Connected && !!groupName) {

            if (typeof groupName === 'string') {
                groupName = [groupName];
            }

            for (const name of groupName) {
                await this.hubConnection.invoke('removefromgroup', name);
            }
        }
    }

    /**
     * erstellt eine Verbindung zum Backend Hub und konfiguriert alle Listener
     * 
     * @param {string} hubURL url des hubs
     * @param {string} token Authentifizierungstoken
     */
    public createConnection(hubURL: string, token?: string | null) {
        const httpOptions: IHttpConnectionOptions = !!token ? {
            accessTokenFactory: () => token,
        } : {};

        if (this.isClosed) {
            this.hubConnection = this.thirdPartyFactoryService.hubConnectionBuilder
                .withUrl(hubURL, httpOptions)
                .configureLogging(new CustomSignalRLogger(this.logger))
                .withAutomaticReconnect()
                .build();

            this.registerListener();
        }
    }

    /**
     * startet eine Verbindung zum Backend Hub
     *
     * @returns {Promise} abschluss Promise
     */
    public async startConnection(): Promise<void> {
        if (!!this.hubConnection && this.hubConnection.state === HubConnectionState.Disconnected) {
            await this.hubConnection.start();
            GlobalSettings.connectionId = await this.hubConnection.invoke('getconnectionid');
        }
    }

    /**
     * stoppt die aktuelle hubConnection. Löscht alle temporären Daten aus dem Hub
     *
     * @returns {Promise} abschluss Promise
     */
    public async stopConnection(): Promise<void> {
        if (!!this.hubConnection && this.hubConnection.state === HubConnectionState.Connected) {

            if (!!this.dataService.currentFinancingMapId) {
                await this.removeFromGroup(this.dataService.currentFinancingMapId);
                this.dataService.currentFinancingMapId = undefined;
                this.dataService.unLoadFinancing();
            }

            if (!!this.dataService.currentCustomerId) {
                await this.removeFromGroup(this.dataService.currentCustomerId);
                this.dataService.currentCustomerId = undefined;
            }

            if (!!this.dataService.currentUserId) {
                await this.removeFromGroup(this.dataService.currentUserId);
                this.dataService.currentUserId = undefined;
            }

            if (this.dataService.currentBranchIds$.value.length > 0) {
                await this.removeFromGroup(this.dataService.currentBranchIds$.value);
                this.dataService.currentBranchIds$.next([]);
            }

            await this.hubConnection.stop();
            GlobalSettings.connectionId = undefined;
            this.hubConnection = undefined;
        }
    }

    //#endregion

    //#region Handling der Temporären Daten

    /**
     * hub registriert sich in der Gruppe des Nutzers. Hört auf Änderungen im Branch und im User
     * 
     * @param {IUserModel} user der aktuell angemeldete Nutzer
     */
    public async setCurrentUser(user?: IUserModel | null): Promise<void> {
        if (!!this.hubConnection && this.hubConnection.state === HubConnectionState.Connected && this.dataService.currentUserId !== user?.id) {

            if (user?.id !== null && !this.dataService.currentUserId) {
                if (user?.id) {
                    await this.addToGroup(user.id);
                    this.dataService.currentUserId = user.id;
                }

                const branchIds = user?.branches ? [...user.branches] : [];

                if (user?.branchId) {
                    branchIds.push(user.branchId);
                }

                if (branchIds.length > 0) {
                    await this.addToGroup(branchIds);
                    this.dataService.currentBranchIds$.next(branchIds);
                }
            }
            else if (user?.id === null && !!this.dataService.currentUserId) {
                await this.removeFromGroup(this.dataService.currentUserId);
                await this.removeFromGroup(this.dataService.currentBranchIds$.value);
            }
        }
    }

    /**
     * hub registriert sich in der Gruppe des Kunden. Hört auf Änderungen im Kunden
     * 
     * @param {string} customerId Id des Kunden oder null
     */
    public async setCurrentCustomer(customerId: string | null): Promise<void> {
        if (this.dataService.currentCustomerId !== customerId) {
            if (customerId !== null && !this.dataService.currentCustomerId) {
                await this.addToGroup(customerId);
                this.dataService.currentCustomerId = customerId;

                this.customerController.getEntity<ICustomerModel>(customerId)
                    .pipe(take(1))
                    .subscribe(customer => {
                        if (!!customer) {
                            this.dataService.patchCustomer(customer);
                        }
                    });
            }
            else if (customerId === null && !!this.dataService.currentCustomerId) {
                await this.removeFromGroup(this.dataService.currentCustomerId);
                this.dataService.currentCustomerId = undefined;
                this.dataService.unLoadCustomer();
            }
        }
    }

    /**
     * hub registriert sich in der Gruppe der Finanzeirung. Hört auf Änderungen in der Finanzierungen und allen Unterobjekten/Nachrichten ...
     * 
     * @param {string} finacingMapId Id der Finanzierung oder null
     */
    public async setCurrentFinancingMap(finacingMapId: string | null): Promise<void> {
        if (this.dataService.currentFinancingMapId !== finacingMapId) {
            if (finacingMapId !== null && !this.dataService.currentFinancingMapId) {

                this.dataService.currentFinancingMapId = finacingMapId;

                forkJoin([
                    this.financingController.getEntity<IFinancingMapModel>(this.dataService.currentFinancingMapId),
                    this.realEstateController.getRealEstatesByFinancing(this.dataService.currentFinancingMapId),
                    this.householdController.getHouseholdsByFinancing(this.dataService.currentFinancingMapId),
                    this.debtorController.getDebtorsByFinancing(this.dataService.currentFinancingMapId),
                    this.liabilityController.getLiabilitiesByFinancing(this.dataService.currentFinancingMapId),
                    this.newLiabilityController.getNewLiabilitiesByFinancing(this.dataService.currentFinancingMapId),
                    this.documentController.getDocumentsByFinancing(this.dataService.currentFinancingMapId),
                    this.fileController.getFilesByFinancing(this.dataService.currentFinancingMapId),
                    this.signatureController.getSignaturesByFinancing(this.dataService.currentFinancingMapId),
                ])
                    .pipe(take(1))
                    .subscribe(([financingMap, realEstates, households, debtors, liabilites, newLiabilities, documents, files, signatures]) => {

                        // Nur patchen wenn es immernoch die richtige Finanzierung ist
                        if (financingMap.id === this.dataService.currentFinancingMapId) {
                            this.dataService.patchFinancing(financingMap);
                            this.dataService.patchRealEstates(realEstates);
                            this.dataService.patchHouseholds(households);
                            this.dataService.patchDebtors(debtors);
                            this.dataService.patchLiabilites(liabilites);
                            this.dataService.patchNewLiabilites(newLiabilities);
                            this.dataService.patchDocuments(documents);
                            this.dataService.patchFiles(files);
                            this.dataService.patchSignatures(signatures);

                            this.dataService.loadHistoryList(this.dataService.currentFinancingMapId);
                            this.dataService.loadProductPackages(this.dataService.currentFinancingMapId);
                        }
                    });

                await this.addToGroup(this.dataService.currentFinancingMapId);
            }
            else if (finacingMapId === null && !!this.dataService.currentFinancingMapId) {
                await this.removeFromGroup(this.dataService.currentFinancingMapId);
                this.dataService.unloadHistoryList();
                this.dataService.unloadProductPackages();
                this.dataService.currentFinancingMapId = undefined;
                this.dataService.unLoadFinancing();
            }
        }
    }

    //#endregion

    //#region Funktions Maps

    /**
     * initialisiert die Map mit den listenern
     */
    private initFunctionMap() {

        // Task

        this.functionMap[EntityType.Customer] = this.createCustomerFunctionMap();
        this.functionMap[EntityType.FinancingMap] = this.createFinancingFunctionMap();
        this.functionMap[EntityType.FinancingMapLight] = this.createFinancingLightFunctionMap();
        this.functionMap[EntityType.FinancingMapStatus] = this.createFinancingStatusFunctionMap();
        this.functionMap[EntityType.RealEstate] = this.createRealEstateFunctionMap();
        this.functionMap[EntityType.Household] = this.createHouseholdFunctionMap();
        this.functionMap[EntityType.Debtor] = this.createDebtorFunctionMap();
        this.functionMap[EntityType.Liability] = this.createLiabilityFunctionMap();
        this.functionMap[EntityType.NewLiability] = this.createNewLiabilityFunctionMap();
        this.functionMap[EntityType.Document] = this.createDocumentFunctionMap();
        this.functionMap[EntityType.File] = this.createFileFunctionMap();
        this.functionMap[EntityType.Files] = this.createFilesFunctionMap();
        this.functionMap[EntityType.Signature] = this.createSignatureFunctionMap();
        this.functionMap[EntityType.Message] = this.createMessageFunctionMap();
        this.functionMap[EntityType.Activity] = this.createActivityFunctionMap();
    }

    /**
     * erstellt die Funktionsmap für die Kundendaten
     * 
     * @returns {IFunctionItem} Funktionsmap der Kundendaten
     */
    private createCustomerFunctionMap(): IFunctionItem {
        return {
            onCreateFn: entityId => {
                if (!this.dataService.existCustomer(entityId)) {
                    this.dataService.refreshCustomerList().catch(e => { throw e; });
                }
            },
            onUpdateFn: entityId => {
                this.customerController.getEntity<ICustomerModel>(entityId)
                    .pipe(take(1))
                    .subscribe(data => {
                        if (this.dataService.existCustomer(entityId)) {
                            this.dataService.updateCustomerLocal(data);

                            if (!!this.dataService.currentCustomerId && data.id === this.dataService.currentCustomerId) {
                                this.dataService.patchCustomer(data);
                            }
                        }
                    });
            },
            onDeleteFn: entityId => {
                if (this.dataService.existCustomer(entityId)) {
                    this.dataService.refreshCustomerList().catch(e => { throw e; });
                }
            },
        };
    }

    /**
     * erstellt die Funktionsmap für die Finanzierungsdaten
     * 
     * @returns {IFunctionItem} Funktionsmap der Finanzierungsdaten
     */
    private createFinancingFunctionMap(): IFunctionItem {
        return {
            onUpdateFn: entityId => {
                this.financingController.getEntity<IFinancingMapModel>(entityId)
                    .pipe(take(1))
                    .subscribe(data => {
                        if (!!this.dataService.currentFinancingMapId && data.id === this.dataService.currentFinancingMapId) {
                            this.dataService.patchFinancing(data);
                        }
                    });
            },
            onDeleteFn: entityId => {
                if (!!this.dataService.currentFinancingMapId && entityId === this.dataService.currentFinancingMapId) {
                    this.dataService.unLoadFinancing();
                    this.dataService.currentFinancingMapId = undefined;
                }
            },
        };
    }

    /**
     * erstellt die Funktionsmap für die Finanzierungsdaten Light
     * 
     * @returns {IFunctionItem} Funktionsmap der Finanzierungsdaten Light
     */
    private createFinancingLightFunctionMap(): IFunctionItem {
        return {
            onCreateFn: entityId => {
                this.financingController.getEntity<IFinancingMapLightModel>(entityId, true)
                    .pipe(take(1))
                    .subscribe(data => {
                        if (this.dataService.currentCustomerId === data.customerId) {
                            this.dataService.createFinancingLocal(data);
                        }
                    });
            },
            onUpdateFn: entityId => {
                this.financingController.getEntity<IFinancingMapLightModel>(entityId, true)
                    .pipe(take(1))
                    .subscribe(data => {
                        this.dataService.updateFinancingLocal(data);
                    });
            },
            onDeleteFn: entityId => {
                this.dataService.deleteFinancingLocal(entityId);
            },
        };
    }

    /**
     * erstellt die Funktionsmap für die Finanzierungsstatus
     * 
     * @returns {IFunctionItem} Funktionsmap der Finanzierungsstatus
     */
    private createFinancingStatusFunctionMap(): IFunctionItem {
        return {
            onUpdateFn: entityId => {
                if (this.dataService.currentFinancingMapId === entityId) {
                    this.financingController.getFinancingMapStatus(entityId)
                        .pipe(take(1))
                        .subscribe(status => {
                            this.dataService.handleStatusResponse(status).catch(e => { throw e; });
                        });
                }
            },
        };
    }

    /**
     * erstellt die Funktionsmap für die Objekte
     * 
     * @returns {IFunctionItem} Funktionsmap der Objekte
     */
    private createRealEstateFunctionMap(): IFunctionItem {
        return {
            onCreateFn: entityId => {
                if (!!this.dataService.currentFinancingMapId) {
                    this.realEstateController.getEntity(entityId)
                        .pipe(take(1))
                        .subscribe(data => {
                            if (data.financingMapId === this.dataService.currentFinancingMapId) {
                                this.dataService.createRealEstateLocal(data);
                            }
                        });
                }
            },
            onUpdateFn: entityId => {
                if (!!this.dataService.currentFinancingMapId) {
                    this.realEstateController.getEntity(entityId)
                        .pipe(take(1))
                        .subscribe(data => {
                            if (data.financingMapId === this.dataService.currentFinancingMapId) {
                                this.dataService.updateRealEstateLocal(data);
                            }
                        });
                }
            },
            onDeleteFn: entityId => {
                if (!!this.dataService.currentFinancingMapId) {
                    this.dataService.deleteRealEstateLocal(entityId);
                }
            },
        };
    }

    /**
     * erstellt die Funktionsmap für die Haushalte
     * 
     * @returns {IFunctionItem} Funktionsmap der Haushalte
     */
    private createHouseholdFunctionMap(): IFunctionItem {
        return {
            onCreateFn: entityId => {
                if (!!this.dataService.currentFinancingMapId) {
                    this.householdController.getEntity(entityId)
                        .pipe(take(1))
                        .subscribe(data => {
                            if (data.financingMapId === this.dataService.currentFinancingMapId) {
                                this.dataService.createHouseholdLocal(data);
                            }
                        });
                }
            },
            onUpdateFn: entityId => {
                if (!!this.dataService.currentFinancingMapId) {
                    this.householdController.getEntity(entityId)
                        .pipe(take(1))
                        .subscribe(data => {
                            if (data.financingMapId === this.dataService.currentFinancingMapId) {
                                this.dataService.updateHouseholdLocal(data);
                            }
                        });
                }
            },
            onDeleteFn: entityId => {
                if (!!this.dataService.currentFinancingMapId) {
                    this.dataService.deleteHouseholdLocal(entityId);
                }
            },
        };
    }

    /**
     * erstellt die Funktionsmap für die Kreditnehmer
     * 
     * @returns {IFunctionItem} Funktionsmap der Kreditnehmer
     */
    private createDebtorFunctionMap(): IFunctionItem {
        return {
            onCreateFn: entityId => {
                if (!!this.dataService.currentFinancingMapId) {
                    this.debtorController.getEntity(entityId)
                        .pipe(take(1))
                        .subscribe(data => {
                            this.dataService.createDebtorLocal(data);
                        });
                }
            },
            onUpdateFn: entityId => {
                if (!!this.dataService.currentFinancingMapId) {
                    this.debtorController.getEntity(entityId)
                        .pipe(take(1))
                        .subscribe(data => {
                            this.dataService.updateDebtorLocal(data);
                        });
                }
            },
            onDeleteFn: entityId => {
                if (!!this.dataService.currentFinancingMapId) {
                    this.dataService.deleteDebtorLocal(entityId);
                }
            },
        };
    }

    /**
     * erstellt die Funktionsmap für die Bestandskredite
     * 
     * @returns {IFunctionItem} Funktionsmap der Bestandskredite
     */
    private createLiabilityFunctionMap(): IFunctionItem {
        return {
            onCreateFn: entityId => {
                if (!!this.dataService.currentFinancingMapId) {
                    this.liabilityController.getEntity(entityId)
                        .pipe(take(1))
                        .subscribe(data => {
                            this.dataService.createLiabilityLocal(data);
                        });
                }
            },
            onUpdateFn: entityId => {
                if (!!this.dataService.currentFinancingMapId) {
                    this.liabilityController.getEntity(entityId)
                        .pipe(take(1))
                        .subscribe(data => {
                            this.dataService.updateLiabilityLocal(data);
                        });
                }
            },
            onDeleteFn: entityId => {
                if (!!this.dataService.currentFinancingMapId) {
                    this.dataService.deleteLiabilityLocal(entityId);
                }
            },
        };
    }

    /**
     * erstellt die Funktionsmap für die neuen Verpflichtungen
     * 
     * @returns {IFunctionItem} Funktionsmap der neuen Verpflichtungen
     */
    private createNewLiabilityFunctionMap(): IFunctionItem {
        return {
            onCreateFn: entityId => {
                if (!!this.dataService.currentFinancingMapId) {
                    this.newLiabilityController.getEntity(entityId)
                        .pipe(take(1))
                        .subscribe(data => {
                            this.dataService.createNewLiabilityLocal(data);
                        });
                }
            },
            onUpdateFn: entityId => {
                if (!!this.dataService.currentFinancingMapId) {
                    this.newLiabilityController.getEntity(entityId)
                        .pipe(take(1))
                        .subscribe(data => {
                            this.dataService.updateNewLiabilityLocal(data);
                        });
                }
            },
            onDeleteFn: entityId => {
                if (!!this.dataService.currentFinancingMapId) {
                    this.dataService.deleteNewLiabilityLocal(entityId);
                }
            },
        };
    }

    /**
     * erstellt die Funktionsmap für die Dokumente
     * 
     * @returns {IFunctionItem} Funktionsmap der Dokumente
     */
    private createDocumentFunctionMap(): IFunctionItem {
        return {
            onCreateFn: (entityId, additional) => {
                if (!!this.dataService.currentFinancingMapId) {
                    const type = HelperService.hasValue(additional) ? additional as SystemType : undefined;

                    if (type !== SystemType.Finadvisory || this.applicationType === ApplicationType.FINADVISORY) {
                        this.documentController.getEntity(entityId)
                            .pipe(take(1))
                            .subscribe(data => {
                                this.dataService.createDocumentLocal(data);
                            });
                    }
                }
            },
            onUpdateFn: entityId => {
                if (!!this.dataService.currentFinancingMapId) {
                    this.documentController.getEntity(entityId)
                        .pipe(take(1))
                        .subscribe(data => {
                            this.dataService.updateDocumentLocal(data);
                        });
                }
            },
            onDeleteFn: entityId => {
                if (!!this.dataService.currentFinancingMapId) {
                    this.dataService.deleteDocumentLocal(entityId);
                }
            },
        };
    }

    /**
     * erstellt die Funktionsmap für die Dateien
     * 
     * @returns {IFunctionItem} Funktionsmap der Dateien
     */
    private createFileFunctionMap(): IFunctionItem {
        return {
            onCreateFn: (entityId, additional) => {
                if (!!this.dataService.currentFinancingMapId) {
                    const type = HelperService.hasValue(additional) ? additional as SystemType : undefined;

                    if (type !== SystemType.Finadvisory || this.applicationType === ApplicationType.FINADVISORY) {
                        this.fileController.getEntity(entityId)
                            .pipe(take(1))
                            .subscribe(data => {
                                this.dataService.createFileLocal(data);
                            });
                    }
                }
            },
            onUpdateFn: (entityId, additional) => {
                if (!!this.dataService.currentFinancingMapId) {
                    const type = HelperService.hasValue(additional) ? additional as SystemType : undefined;

                    if (type !== SystemType.Finadvisory || this.applicationType === ApplicationType.FINADVISORY) {
                        this.fileController.getEntity(entityId)
                            .pipe(take(1))
                            .subscribe(data => {
                                this.dataService.updateFileLocal(data);
                            });
                    }
                }
            },
            onDeleteFn: entityId => {
                if (!!this.dataService.currentFinancingMapId) {
                    this.dataService.deleteFileLocal(entityId);
                }
            },
        };
    }

    /**
     * erstellt die Funktionsmap für die mehrere Dateien gleichzeitig
     * 
     * @returns {IFunctionItem} Funktionsmap der Dateien
     */
    private createFilesFunctionMap(): IFunctionItem {
        return {
            onUpdateFn: entityId => { // Aktualisierung aller Files aus der Finanzierung
                if (!!this.dataService.currentFinancingMapId && this.dataService.currentFinancingMapId === entityId) {
                    this.fileController.getFilesByFinancing(entityId)
                        .pipe(take(1))
                        .subscribe(data => {
                            this.dataService.patchFiles(data);
                        });
                }
            },
        };
    }

    /**
     * erstellt die Funktionsmap für die Unterschriften Dateien
     * 
     * @returns {IFunctionItem} Funktionsmap der Dateien
     */
    private createSignatureFunctionMap(): IFunctionItem {
        return {
            onCreateFn: entityId => {
                if (!!this.dataService.currentFinancingMapId) {
                    this.signatureController.getEntity(entityId)
                        .pipe(take(1))
                        .subscribe(data => {
                            this.dataService.createSignatureLocal(data);
                        });
                }
            },
            onUpdateFn: entityId => {
                if (!!this.dataService.currentFinancingMapId) {
                    this.signatureController.getEntity(entityId)
                        .pipe(take(1))
                        .subscribe(data => {
                            this.dataService.updateSignatureLocal(data);
                        });
                }
            },
            onDeleteFn: entityId => {
                if (!!this.dataService.currentFinancingMapId) {
                    this.dataService.deleteSignatureLocal(entityId);
                }
            },
        };
    }

    /**
     * erstellt die Funktionsmap für die Finanzierungsdaten Light
     * 
     * @returns {IFunctionItem} Funktionsmap der Finanzierungsdaten Light
     */
    private createMessageFunctionMap(): IFunctionItem {
        return {
            onCreateFn: entityId => {
                this.messageController.getEntity(entityId)
                    .pipe(take(1))
                    .subscribe(data => {
                        if (this.dataService.currentFinancingMapId === data.financingMapId) {
                            this.dataService.createMessageLocal(data);
                        }
                    });
            },
            onUpdateFn: entityId => {
                this.messageController.getEntity(entityId)
                    .pipe(take(1))
                    .subscribe(data => {
                        this.dataService.updateMessageLocal(data);
                    });
            },
            onDeleteFn: entityId => {
                this.dataService.deleteMessageLocal(entityId);
            },
        };
    }

    /**
     * erstellt die Funktionsmap für die Finanzierungsdaten Light
     * 
     * @returns {IFunctionItem} Funktionsmap der Finanzierungsdaten Light
     */
    private createActivityFunctionMap(): IFunctionItem {
        return {
            onCreateFn: () => {
                this.dataService.refreshActivityList().catch(e => { throw e; });
            },
            onUpdateFn: () => {
                this.dataService.refreshActivityList().catch(e => { throw e; });
            },
        };
    }

    //#endregion

    /**
     * registriert alle Listener
     */
    private registerListener() {
        if (!!this.hubConnection) {
            this.hubConnection.on('createEntity', (data: { entityId: string, type: EntityType, additional?: unknown }) => {
                const fnMap = this.functionMap[data.type];
                if (!!fnMap.onCreateFn) { fnMap.onCreateFn(data.entityId, data.additional) }
                else { this.logger.error(new Error(`no create function defined for ${data.type}`)) }
            });

            this.hubConnection.on('updateEntity', (data: { entityId: string, type: EntityType, additional?: unknown }) => {
                const fnMap = this.functionMap[data.type];
                if (!!fnMap?.onUpdateFn) { fnMap.onUpdateFn(data.entityId, data.additional) }
                else { this.logger.error(new Error(`no update function defined for ${data.type}`)) }
            });

            this.hubConnection.on('deleteEntity', (data: { entityId: string, type: EntityType }) => {
                const fnMap = this.functionMap[data.type];
                if (!!fnMap?.onDeleteFn) { fnMap.onDeleteFn(data.entityId) }
                else { this.logger.error(new Error(`no delete function defined for ${data.type}`)) }
            });

            this.hubConnection.onreconnected(() => {
                GlobalSettings.hubError = false;
            });
        }
    }

}
