import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpError } from '@curveball/http-errors';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { GlobalSettings } from '@ucba/sdk';
import { CustomerController } from '@ucba/sdk/controller';
import { RoutingParams } from '@ucba/sdk/enums';
import { HelperService } from '@ucba/sdk/services';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { ConsentComponent } from '../../modals/consent/consent.component';
import { PasscodeComponent } from '../../modals/passcode/passcode.component';
import { SetConfirmed, SetCustomerId, SetHash } from '../../statemanagement/actions';
import { ApplicationState } from '../../statemanagement/states';


/**
 * Komponente an den Kunden nach logging landet
 */
@Component({
    selector: 'cxad-entry-point',
    templateUrl: './entry-point.component.html',
    styleUrls: ['./entry-point.component.scss'],
})

export class EntryPointComponent implements OnInit, OnDestroy {

    private dialogRef: MatDialogRef<PasscodeComponent> | undefined;
    private dialogConsentRef: MatDialogRef<ConsentComponent> | undefined;
    private requestCodeSubscription: Subscription | undefined;
    private codeEnteredSubscription: Subscription | undefined;
    private isPrivacyStatementConfirmed: boolean | undefined;

    /**
     *Standard Konstuktor
     *
     * @param  {MatDialog} dialog MatDialog injector
     * @param  {ActivatedRoute} route Route injector
     * @param  {Router} router Router injector
     * @param  {CustomerController} customerController CustomerController injector
     * @param  {TranslateService} translate  TranslateService injector
     * @param  {NGXLogger} logger  NGXLogger injector
     * @param  {Store} store  Store injector
     */
    public constructor(
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private customerController: CustomerController,
        private translate: TranslateService,
        private logger: NGXLogger,
        private store: Store,
    ) { }

    /**
     * Angular Hook um Werte zu initialisieren
     */
    public ngOnInit(): void {
        const customerId = this.route.snapshot.paramMap.get(RoutingParams.CUSTOMER_ID) as string;
        if (!!customerId && HelperService.isValidGUID(customerId)) {
            this.customerController.getCustomersNumber(customerId)
                .pipe(take(1))
                .subscribe(result => {
                    if (!!result) {
                        result.mobileNumber = result.mobileNumber ?? '';

                        this.openDialog(result.mobileNumber.padStart(12, '*'), customerId);
                        this.store.dispatch(new SetCustomerId(customerId))
                        this.initValidateCode();
                        this.initRequestNewCode();
                    }
                });
        }
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        if (this.dialogConsentRef !== undefined) {
            this.dialogConsentRef.close();
            this.dialogConsentRef = undefined;
        }

        if (this.dialogRef !== undefined) {
            this.dialogRef.close();
            this.dialogRef = undefined;
        }
    }

    /**
     * öffnet den Consent dialog
     *
     * @param {string} customerId die Kunnden id
     */
    private openConsentDialog(customerId: string): void {

        this.dialogConsentRef = this.dialog.open(ConsentComponent, {
            backdropClass: 'backdrop-background',
            disableClose: true,
            autoFocus: false, //IMPORTANT! disable auto focus when modal-dialog is opened, it automatically focuses first button or input feld!
        });

        this.dialogConsentRef.afterClosed()
            .pipe(take(1))
            .subscribe(result => {
                if (result) {
                    this.customerController.confirmprivacystatement(customerId)
                        .pipe(
                            take(1),
                        )
                        .subscribe({
                            next: () => {
                                this.store.dispatch(new SetConfirmed(true));
                                this.router.navigate(['welcome']).catch(e => { throw e; });
                            },
                            error: (error: Error) => {
                                if (error instanceof HttpErrorResponse) {
                                    this.logger.error(error);
                                    throw new Error(error.error.message);
                                }
                                else {
                                    throw error;
                                }
                            },
                        });
                }
            });
    }
    /**
     * öffnet den Passcode dialog
     *
     * @param {string} phoneNumber die angezeigte mobilnummer
     * @param {string} customerId KundenId
     */
    private openDialog(phoneNumber: string, customerId: string): void {
        this.dialogRef = this.dialog.open(PasscodeComponent, {
            data: {
                phoneNumber,
            },
            hasBackdrop: false,
        });

        this.dialogRef.afterClosed().pipe(take(1)).subscribe(success => {
            if (!!this.requestCodeSubscription) {
                this.requestCodeSubscription.unsubscribe();
            }

            if (!!this.codeEnteredSubscription) {
                this.codeEnteredSubscription.unsubscribe();
            }

            if (success) {
                if (!this.isPrivacyStatementConfirmed) {
                    this.openConsentDialog(customerId);
                }
                else {
                    this.store.dispatch(new SetConfirmed(true));
                    this.router.navigate(['welcome']).catch(e => { throw e; });
                }
            }
        });
    }

    /**
     * initialisieren des event listerner sobald der Anfordern (od. erneut Anfordern) Button gedrückt wurde
     */
    private initRequestNewCode(): void {
        if (!!this.dialogRef) {
            this.requestCodeSubscription = this.dialogRef.componentInstance.requestCode.subscribe(skipPasscode => {
                const customerId = this.store.selectSnapshot(ApplicationState.customerId);

                if (!!this.dialogRef && !!customerId) {
                    this.customerController.sendpasscode(customerId, skipPasscode).pipe(
                        take(1),
                    ).subscribe({
                        next: result => {
                            if (!!this.dialogRef) {
                                if (result.success) {
                                    // Umschalten auf anderen Dialog Inhalt
                                    this.dialogRef.componentInstance.errorMessage = undefined;
                                    this.dialogRef.componentInstance.isPassCodeSended = true;
                                }
                                else {
                                    result.message = !!result.message ? result.message : this.translate.instant('components.entry-point.activities.sendPasscodeFailed')
                                    const postText = this.translate.instant('components.entry-point.activities.sendPasscodeFailed_Post');
                                    this.dialogRef.componentInstance.errorMessage = `${result.message} ${postText}`;
                                }
                            }
                        },
                        error: (error: HttpError) => {
                            if (error.httpStatus === 401) {
                                if (!!this.dialogRef) {
                                    this.dialogRef.componentInstance.errorMessage = this.translate.instant('modals.passcode.content.passcodeError');
                                    this.logger.error(error, customerId);
                                }
                            }
                            else {
                                throw error;
                            }
                        },
                    });
                }
            });
        }
    }

    /**
     * initialisieren des event listeners sobald der Betätigen Button gedrückt wurde
     *
     */
    private initValidateCode(): void {
        if (!!this.dialogRef) {
            this.codeEnteredSubscription = this.dialogRef.componentInstance.codeEntered.subscribe(inputedCode => {
                const customerId = this.store.selectSnapshot(ApplicationState.customerId);

                if (!!customerId) {
                    this.customerController.validatepasscode(customerId, inputedCode)
                        .pipe(
                            take(1),
                        )
                        .subscribe({
                            next: result => {
                                GlobalSettings.token = result.token;
                                this.store.dispatch(new SetHash(result.token));

                                if (!!this.dialogRef) {
                                    this.dialogRef.close(true);
                                    this.isPrivacyStatementConfirmed = result.isPrivacyStatementConfirmed;
                                }
                            },
                            error: (error: HttpError) => {
                                if (error.httpStatus === 401) {
                                    if (!!this.dialogRef) {
                                        this.dialogRef.componentInstance.errorMessage = this.translate.instant('modals.passcode.content.wrongCode');
                                        this.logger.error(error, customerId);
                                    }
                                }
                                else {
                                    throw error;
                                }
                            },
                        })
                }
                else {
                    this.logger.error(new Error('no customerId for validate passcode'))
                }
            });
        }
    }
}
