import { Gender } from '@ntag-ef/finprocess-enums/finadvisory';

import { ISODate } from '../types';

import { IBackendBaseModel } from './backend-base.model';

/**
 * Kunden Daten
 */
export interface ICustomerModel extends IBackendBaseModel {

    /** Id des Filialmitarbeiters */
    consultantId: string | null;

    /** Geschlecht */
    gender?: Gender | null;

    /** Titel */
    title?: string | null;

    /** Vorname */
    firstName: string | null;

    /** Nachname */
    lastName: string | null;

    /** Telefonnummer */
    phoneNumber?: string | null;

    /** Mobiltelefonnummer */
    mobileNumber: string | null;

    /** E-Mail */
    email?: string | null;

    /** Postleitzahl */
    zip?: string | null;

    /** Stadt */
    city?: string | null;

    /** Straße */
    street?: string | null;

    /** Hausnummer */
    streetNumber?: string | null;

    /** Geburtstag */
    birthdate?: ISODate | null;

    /** Id der Filiale */
    branchId?: string;
}
