import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FilterType } from '../../enums/filter-type.enum';
import { IActivityModel, IActivityResultModel } from '../../models';
import { IActivityFilterRequest } from '../../requests/activity-filter.request';
import { GlobalSettings } from '../../utils/global-settings';
import { BaseController } from '../base/base.controller';

/**
 * Controller welche das Mailtemplate abruft und Mails sendet
 */
@Injectable()
export class ActivityController extends BaseController {

    private baseUrl = '/activity';

    //#region --- Get ---

    /**
     * Gibt die Entität mit übergebener Id zurück
     * 
     * @param {string} id id der Entität
     * @returns {any} angeforderte Entität
     */
    public getEntity(id: string): Observable<IActivityModel> {
        return this.get(`${this.baseUrl}/${id}`);
    }

    /**
     * Gibt ein Array aus allen für den Nutzer sichbaren Entitäten
     * 
     * @returns {any} angeforderte Entität
     */
    public getEntities(): Observable<IActivityModel[]> {
        return this.get(this.baseUrl);
    }

    /**
     * Gibt ein Array aus allen für den Nutzer sichbaren Entitäten gefiltert
     * 
     * @returns {any} angeforderte Entität
     */
    public filter(): Observable<IActivityResultModel> {
        const request: IActivityFilterRequest = {
            currentPage: GlobalSettings.userSettings.activityPaging.currentPage,
            entityCountPerPage: GlobalSettings.userSettings.activityPaging.entityCountPerPage,
            myActivities: GlobalSettings.userSettings.activityFilterConsult === FilterType.OWN,
            textSearch: GlobalSettings.userSettings.activitySearch,
            branchIds: GlobalSettings.userSettings.activityFilterBranch,
            sorting: GlobalSettings.userSettings.activitySort,
        };

        const asRecord = request as unknown as Record<string, number | string | boolean | Array<number | string | boolean>>;
        return this.get(`${this.baseUrl}/filter`, { params: asRecord });
    }

    //#endregion

    //#region --- CRUD ---

    /**
     * erstellt eine Entität im Backend
     * 
     * @param {any} model zu speichernde Entität
     * @returns {any} im backend gespeicherte Entität
     */
    public createEntity(model: Partial<IActivityModel>): Observable<IActivityModel> {
        return this.post(this.baseUrl, model);
    }

    /**
     * setzt das isRead flag einer Aktivität
     * 
     * @param  {string} activityId id der Aktivität
     * @param  {boolean} isRead der Wert auf den es gesetzt werden soll 
     * @returns {Observable} aktualisiertes ActivityModel
     */
    public updateEntity(activityId: string, isRead: boolean): Observable<IActivityModel> {
        const url = `${this.baseUrl}/SetIsRead/${activityId}?isRead=${isRead}`;
        return this.patch(url);
    }

    //#endregion
}
