import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateModule } from '@angular/material-moment-adapter';
import { TranslateModule } from '@ngx-translate/core';
import { EnumTranslationModule } from '@ntag-ef/finprocess-enums';
import { NgxCurrencyDirective } from 'ngx-currency';
import { FlexLayoutModule } from 'ngx-flexible-layout';

import { BooleanComponent } from './components/boolean/boolean.component';
import { DateComponent } from './components/date/date.component';
import { FinancingCardComponent } from './components/financing-card/financing-card.component';
import { FloatingButtonComponent } from './components/floating-button/floating-button.component';
import { FormFieldErrorComponent } from './components/form-field-error/form-field-error.component';
import { InputComponent } from './components/input/input.component';
import { InputCurrencyComponent } from './components/input-currency/input-currency.component';
import { MultiInputComponent } from './components/multi-input/multi-input.component';
import { OutputComponent } from './components/output/output.component';
import { OutputCurrencyComponent } from './components/output-currency/output-currency.component';
import { OutputPercentComponent } from './components/output-percent/output-percent.component';
import { PhoneComponent } from './components/phone/phone.component';
import { RadioComponent } from './components/radio/radio.component';
import { SampleCalculationCardComponent } from './components/sample-calculation-card/sample-calculation-card.component';
import { SelectComponent } from './components/select/select.component';
import { SelectDocumentComponent } from './components/select-document/select-document.component';
import { SkeletonCardComponent } from './components/skeleton-card/skeleton-card.component';
import { TextareaComponent } from './components/textarea/textarea.component';

// see https://www.concretepage.com/angular-material/angular-material-datepicker-format
export const MY_FORMATS = {
    parse: {
        dateInput: 'DD-MM-YYYY',
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

/**
 * Module welches alle Komponenten bereitsstellt
 */
@NgModule({
    declarations: [
        DateComponent,
        FormFieldErrorComponent,
        InputComponent,
        PhoneComponent,
        SelectComponent,
        RadioComponent,
        BooleanComponent,
        TextareaComponent,
        OutputComponent,
        InputCurrencyComponent,
        OutputCurrencyComponent,
        FinancingCardComponent,
        MultiInputComponent,
        SkeletonCardComponent,
        OutputPercentComponent,
        SelectDocumentComponent,
        FloatingButtonComponent,
        SampleCalculationCardComponent,
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        EnumTranslationModule.forChild(),
        MatBadgeModule,
        MatButtonModule,
        MatInputModule,
        MatCardModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatRadioModule,
        MatSelectModule,
        MatTableModule,
        MatIconModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatRippleModule,
        MatGridListModule,
        MatMenuModule,
        MomentDateModule,
        NgxCurrencyDirective,
    ],
    providers: [
        DatePipe,
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: false },
        },
    ],
    exports: [
        DateComponent,
        FormFieldErrorComponent,
        InputComponent,
        PhoneComponent,
        SelectComponent,
        RadioComponent,
        BooleanComponent,
        TextareaComponent,
        OutputComponent,
        InputCurrencyComponent,
        OutputCurrencyComponent,
        FinancingCardComponent,
        MultiInputComponent,
        SkeletonCardComponent,
        OutputPercentComponent,
        SelectDocumentComponent,
        FloatingButtonComponent,
        SampleCalculationCardComponent,
    ],
})
export class ComponentsModule { }
