<div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between stretch">

    <div fxLayout="column" fxLayoutAlign="start start">
        <mat-label>{{ labelText }}</mat-label>
        <mat-hint *ngIf="!!comment">({{ comment }})</mat-hint>
    </div>

    <mat-form-field fxLayout="row" fxLayoutAlign="space-between center" appearance="outline">
        <input matInput class="right" [value]="value !== undefined ? (value | currency:'EUR':'symbol':'1.2-2') : ''"
            readonly>
    </mat-form-field>
</div>
<hr fxHide fxShow.gt-sm>