/**
 * Triggert das Aufräumen der States
 */
export class Dispose {
    public static readonly type = '[Global] Dispose';
}

/**
 * Triggert das eine Finanzierung aus dem State entladen wird
 */
export class UnLoadFinancing {
    public static readonly type = '[Global] UnLoadFinancing';
}

/**
 * Triggert das eine Kunde und seine Finanzierung aus dem State entladen wird
 */
export class UnLoadCustomer {
    public static readonly type = '[Global] UnLoadCustomer';
}
