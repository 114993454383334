import { IFinancingConfiguration } from '@ntag-ef/finprocess-calculations';

import { ISODate } from '../types';

import { IBaseIdentifierModel } from './base-identifier.model';

/**
 * fictional rate configurations from server.
 */
export interface IFinancingConfigurationModel extends IBaseIdentifierModel, IFinancingConfiguration {

    /** the valid from date. */
    validFrom: ISODate;

    /** the valid to date. Is null if the configuration is still valid. */
    validTo?: ISODate;
}
