/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { IActivityCluster } from '../../models';
import { Dispose } from '../actions';

import { SdkPatchActivity } from './activity.actions';


export interface IActivityStateModel {
    data: IActivityCluster[];
}


/**
 * Klasse des Aktivitäten States
 */
@State<IActivityStateModel>({
    name: ActivityState.stateName,
    defaults: ActivityState.defaultData,
})
@Injectable()
export class ActivityState {
    public static readonly stateName = 'activityState';

    private static readonly defaultData: IActivityStateModel = {
        data: [],
    };

    /**
     * Aktualisiert die aktuellen Aktivitäten
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchActivity} action SdkPatchActivity Action
     */
    @Action(SdkPatchActivity)
    public patchActivity(
        { patchState }: StateContext<IActivityStateModel>,
        { payload }: SdkPatchActivity,
    ): void {
        patchState({
            data: payload,
        });
    }

    /**
     * zurücksetzen des States
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action([Dispose])
    public dispose({ setState }: StateContext<IActivityStateModel>): void {
        setState({ ...ActivityState.defaultData });
    }

    /**
     * gibt alle Aktivitäten zurück
     *
     * @param {IActivityStateModel} state aktueller State
     * @returns {IActivityCluster[]} liste von Aktivitäten
     */
    @Selector()
    public static current(state: IActivityStateModel): IActivityCluster[] {
        return state?.data ?? [];
    }
}
