import { BankAustriaStatus, EmployeeStatus, Gender, HousingType, LevelOfTraining, MaritalStatus } from '@ntag-ef/finprocess-enums/finadvisory';

import { LineType } from '../enums/line-type.enum';
import { ISODate } from '../types';

import { IBackendBaseModel } from './backend-base.model';

/**
 * Stores personal details for debtors.
 */
export interface IDebtorModel extends IBackendBaseModel {

    /** The household identifier. */
    householdId: string;

    /** The position. */
    position?: number | null;

    /** The title. */
    title?: string | null;

    /** The first name. */
    firstName?: string | null;

    /** The last name. */
    lastName?: string | null;

    /** The customer bank system number. */
    customerNumber?: string | null;

    /** The gender. */
    gender?: Gender | null;

    /** The phone number. */
    phoneNumber?: string | null;

    /** The mobile number. */
    mobileNumber?: string | null;

    /** The email. */
    email?: string | null;

    /** The birthday. */
    birthday?: ISODate | null;

    /** The birthcountry. */
    birthcountry?: string | null;

    birthPlace?: string | null;

    /** The notes about birth and assumed duration. */
    creditDurationInRetirement?: string | null;

    /** The marital status. */
    maritalStatus?: MaritalStatus | null;

    /** The citizenship */
    citizenship?: string | null;

    /** The tax residence */
    taxResidence?: string | null;

    /** The additional citizenship. */
    additionalCitizenship?: string | null;

    /** The tin number */
    tinNumber?: string | null;

    /** The children count. */
    childrenCount?: number | null;

    /** The dependent persons count. */
    dependentPersonsCount?: number | null;

    /** The date since when the person was living at his home address. */
    livingAtHomeAddressSince?: ISODate | null;

    /** The type of the housing. */
    housingType?: HousingType | null;

    /** The level of training. */
    levelOfTraining?: LevelOfTraining | null;

    /** The employee status. */
    employeeStatus?: EmployeeStatus | null;

    /** The profession. */
    profession?: string | null;

    /** The employed since date. */
    employedSince?: ISODate | null;

    /** optinal field to disable careers if employeeStatus is SelfEmployed. */
    noCareers?: boolean | null;

    /** The name of the employer. */
    employerName?: string | null;

    /** the count of vehicles */
    vehicleCount?: number | null;

    /** the line type of the vehicles */
    lineType?: LineType | null;

    /** The bank account. one of BankAccountList.bankAccounts */
    bankAccount?: string | null;

    /** he bank austria status. */
    bankAustriaStatus?: BankAustriaStatus | null;

    /** The notes about the person. */
    notesPerson?: string | null;

    /** The net income per month. */
    netIncome?: number | null;

    /** true if fourteen salaries per year; otherwise, false. */
    fourteenSalariesPerYear?: boolean | null;

    /** The other income per month. */
    otherIncome?: number | null;

    /** The notes about the income. */
    notesIncome?: string | null;

    /** The notes about other income. */
    notesOtherIncome?: string | null;

    /** Whether the other income contains alimony. */
    otherIncomeContainsAlimony?: boolean | null;

    /** The zip. */
    homeZip?: string | null;

    /** The city. */
    homeCity?: string | null;

    /** The street. */
    homeStreet?: string | null;

    /** The street number. */
    homeStreetNumber?: string | null;

    /** The stairway */
    homeStairway?: string | null;

    /** The top */
    homeTop?: string | null;

    /** The country */
    homeCountry?: string | null;
}


export interface IDebtorFormModel extends IBackendBaseModel {

    /** The household identifier. */
    householdId: string;

    /** The position. */
    position?: number | null;

    /** The title. */
    title?: string | null;

    /** The first name. */
    firstName?: string | null;

    /** The last name. */
    lastName?: string | null;

    /** The customer bank system number. */
    customerNumber?: string | null;

    /** The gender. */
    gender?: Gender | null;

    /** The phone number. */
    phoneNumber?: string | null;

    /** The mobile number. */
    mobileNumber?: string | null;

    /** The email. */
    email?: string | null;

    /** The birthday. */
    birthday?: ISODate | null;

    /** The birthcountry. CountryListModel.birthCountries or other string */
    birthcountry?: string | null;

    /** is other birthcountry string only revalte during the term not for db */
    birthcountryOther?: string | null;

    birthPlace?: string | null;

    /** The notes about birth and assumed duration. */
    creditDurationInRetirement?: string | null;

    /** The marital status. */
    maritalStatus?: MaritalStatus | null;

    /** The citizenship. CountryListModel.citizenshipCountries or other string */
    citizenship?: string | null;

    /** is other citizenship string only revalte during the term not for db */
    citizenshipOther?: string | null;

    /** The tax residence. CountryListModel.taxResidenceCountries or other string */
    taxResidence?: string | null;

    /** is other taxResidence string only revalte during the term not for db */
    taxResidenceOther?: string | null;

    /** The additional citizenship. */
    additionalCitizenship?: string | null;

    /** is other additional citizenship string only revalte during the term not for db */
    additionalCitizenshipOther?: string | null;

    /** The tin number */
    tinNumber?: string | null;

    /** The children count. */
    childrenCount?: number | null;

    /** The dependent persons count. */
    dependentPersonsCount?: number | null;

    /** The date since when the person was living at his home address. */
    livingAtHomeAddressSince?: ISODate | null;

    /** The type of the housing. */
    housingType?: HousingType | null;

    /** The level of training. */
    levelOfTraining?: LevelOfTraining | null;

    /** The employee status. */
    employeeStatus?: EmployeeStatus | null;

    /** The profession. */
    profession?: string | null;

    /** The employed since date. */
    employedSince?: ISODate | null;

    /** optinal field to disable careers if employeeStatus is SelfEmployed. */
    noCareers?: boolean | null;

    /** The name of the employer. */
    employerName?: string | null;

    /** the count of vehicles */
    vehicleCount?: number | null;

    /** the line type of the vehicles */
    lineType?: LineType | null;

    /** The bank account. one of BankAccountList.bankAccounts */
    bankAccount?: string | null;

    /** is other bankAccount string only revalte during the term not for db  */
    bankAccountOther?: string | null;

    /** he bank austria status. */
    bankAustriaStatus?: BankAustriaStatus | null;

    /** The notes about the person. */
    notesPerson?: string | null;

    /** The net income per month. */
    netIncome?: number | null;

    /** true if fourteen salaries per year; otherwise, false. */
    fourteenSalariesPerYear?: boolean | null;

    /** The other income per month. */
    otherIncome?: number | null;

    /** The notes about the income. */
    notesIncome?: string | null;

    /** The notes about other income. */
    notesOtherIncome?: string | null;

    /** Whether the other income contains alimony. */
    otherIncomeContainsAlimony?: boolean | null;

    /** The zip. */
    homeZip?: string | null;

    /** The city. */
    homeCity?: string | null;

    /** The street. */
    homeStreet?: string | null;

    /** The street number. */
    homeStreetNumber?: string | null;

    /** The stairway */
    homeStairway?: string | null;

    /** The top */
    homeTop?: string | null;

    /** The country */
    homeCountry?: string | null;
}

