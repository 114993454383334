import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import SignaturePad from 'signature_pad';

import { IDebtorSignatureMap, ISignatureDialogResult } from '../../interfaces';
import { GlobalSettings } from '../../utils';

/**
 * Komponente für den Dialog zum Unterzeichnen eines Dokuments
 */
@Component({
    selector: 'ucba-signatur',
    templateUrl: './signatur.component.html',
    styleUrls: ['./signatur.component.scss'],
})
export class SignatureDialogComponent implements AfterViewInit {

    /**
     * SignaturePad
     */
    @ViewChild('signatureCanvas', { static: true })
    public signatureCanvas!: ElementRef<HTMLCanvasElement>;

    private signaturePad?: SignaturePad;
    public signaturePadOptions = { width: 550, height: 300 };

    public signatureImage: string | undefined;
    private wasCleared = false;
    public isEmpty = true;

    /**
     * Standard Konstruktor
     * 
     * @param {MatDialogRef<SignatureDialogComponent>} dialogRef Referenz auf den Dialog
     * @param {ISignatureDialogResult} data beinhaltet die Daten die beim verlassen des Dialogs übergeben werden
     */
    public constructor(
        public dialogRef: MatDialogRef<SignatureDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDebtorSignatureMap,
    ) { }

    /**
     * Angular Hook zum initialisieren
     *
     */
    public ngAfterViewInit(): void {
        this.isEmpty = !this.data.signature;
        this.signatureImage = this.data.signature?.content as string;
        this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement, {
            backgroundColor: 'rgb(255,255,255)',
        });
        if (!!this.signatureImage && this.signatureImage.startsWith('data:') && !!this.signaturePad) {
            this.signaturePad.fromDataURL(this.signatureImage, this.signaturePadOptions).catch(e => { throw e; });
        }
    }

    /**
     * schließen des Dialogs
     */
    public drawCancel(): void {
        this.dialogRef.close();
        this.signaturePad?.off();
    }

    /**
     * Dialog schließen und Daten übertragen
     */
    public drawComplete(): void {
        if (!!this.signaturePad) {
            const result: ISignatureDialogResult = {
                isEmpty: !!this.signaturePad && this.signaturePad.isEmpty(),
                wasCleared: this.wasCleared,
                base64Data: this.signaturePad.toDataURL(GlobalSettings.MIME_JPG, 1),
            };
            this.dialogRef.close(result);
            this.signaturePad?.off();
        }
    }

    /**
     * Unterschriften Feld leeren
     */
    public drawClear(): void {
        if (!!this.signaturePad) {
            this.isEmpty = true;
            this.signaturePad.clear();
            this.wasCleared = true;
        }
    }
}
