import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationService } from '@ntag-ef/notifications';
import { FinancingMapStatus, HelperService, IFinancingMapLightModel } from '@ucba/sdk';

import { IFinancingCardButton } from '../../interfaces';

type FinancingCardModel = IFinancingMapLightModel & { htmlStatusInfoLong: string, htmlStatusInfoShort: string }

/**
 * Komponente welche Aktive Finanzierung anzeigt
 */
@Component({
    selector: 'ucba-financing-card',
    templateUrl: './financing-card.component.html',
    styleUrls: ['./financing-card.component.scss'],
})
export class FinancingCardComponent {

    @Input() public forceExpand = false;
    @Input() public buttons: IFinancingCardButton[] = [];
    /**
     * Setter um Enum bei jeder Änderung zu aktualisieren
     *
     * @param  {IFinancingMapLightModel | undefined} fmModel Finanzierungsmodell
     */
    @Input() public set financingModel(fmModel: IFinancingMapLightModel | undefined) {
        this.financingCardModel = fmModel ? {
            ...fmModel,
            htmlStatusInfoLong: HelperService.toHTMLBreakes(fmModel?.statusInfo ?? ''),
            htmlStatusInfoShort: HelperService.abbreviateString(HelperService.removeHtml(fmModel?.statusInfo ?? ''), this.maxLength),
        } : undefined;
    }
    @Output() public clicked = new EventEmitter<string>();

    public financingCardModel: FinancingCardModel | undefined;
    public maxLength = 30;
    public visible = false;

    public transgressionText = 'Aufgrund eines ungewöhnlich hohen Aufgebots an Finanzierungsanfragen, konnte die geschätzte Bearbeitungszeit nicht eingehalten werden. Wir bitten um Ihr Verständnis und melden uns in Kürze bei Ihnen.';

    /**
     * Standard construktor
     * 
     * @param {DatePipe} datePipe  DatePipe injector
     * @param {NotificationService} notification NotificationService injector
     */
    public constructor(
        private datePipe: DatePipe,
        private notification: NotificationService,
    ) { }

    /**
     * gibt die adresse formatiert zurück
     * 
     * @returns {string} formatierte Adresse
     */
    public get formatAddress(): string {
        return HelperService.formatAddress(
            this.financingCardModel?.street,
            this.financingCardModel?.streetNumber,
            this.financingCardModel?.zip,
            this.financingCardModel?.city,
        )
    }


    /**
     * check ob die vorrauslichtliche Bearbeitungszeit angezeigt werden soll
     * 
     * @returns {boolean} soll die Bearbeitungszeit angezeigt werden
     */
    public get isDurationVisible(): boolean {
        return !!this.financingCardModel && HelperService.hasValue(this.financingCardModel.status) &&
            [FinancingMapStatus.HouseholdCalculationRequested, FinancingMapStatus.SampleCalculationRequested].includes(this.financingCardModel.status) && // Im status Eingereicht
            HelperService.hasValue(this.financingCardModel.processingTimeStart) && // Start datum gesetzt
            HelperService.hasValue(this.financingCardModel.processingTimeStartWithBuffer) && // End datum gesetzt
            HelperService.isNullOrWhitespaces(this.financingCardModel.clerkDisplayName); // noch kein Bearbeiter gesetzt
    }

    /**
     * gibt den angezeigten Text für den Bearbeitungszeitraum zurück, unter berücksichtigung falls dieser Überschritten wurde
     * 
     * @returns {string} soll die Bearbeitungszeit angezeigt werden
     */
    public get processingTimeText(): string {
        if (!!this.financingCardModel &&
            HelperService.hasValue(this.financingCardModel.processingTimeStart) &&
            HelperService.hasValue(this.financingCardModel.processingTimeStartWithBuffer) &&
            HelperService.today() <= new Date(this.financingCardModel.processingTimeStartWithBuffer)) {
            return `${this.datePipe.transform(this.financingCardModel.processingTimeStart, 'dd.MM.yyyy')} - ${this.datePipe.transform(this.financingCardModel.processingTimeStartWithBuffer, 'dd.MM.yyyy')}`;
        }
        else {
            return this.transgressionText;
        }
    }

    /**
     * wenn die Karte geklickt wurde
     */
    public onClicked(): void {
        this.clicked.emit(this.financingCardModel?.id);
    }

    /**
     * wenn Icon geklickt wird
     * 
     * @param {Event} event some stupid event
     * @param {string} title some stupid title
     * @param {string} displayText some stupid text to display
     */
    public onExpandClicked(event: Event, title: string, displayText: string): void {
        event.stopPropagation();
        this.notification.alert(title, displayText);
    }

    /**
     * Um die Visibility des Icons zu ändern
     * 
     * @param {boolean} flipTo z
     */
    public toggleVisibility(flipTo: boolean): void {
        this.visible = flipTo;
    }
}
