import { Language } from '@ntag-ef/finprocess-enums';
import { DocumentType, InterestMethod, OriginalToType } from '@ntag-ef/finprocess-enums/finadvisory';

import { FinancingMapStatus } from '../enums';
import { ISODate } from '../types';

import { IBackendBaseModel } from './backend-base.model';

/**
 * Finanzierungs Daten
 */
export interface IFinancingMapModel extends IBackendBaseModel {

    /** The customer identifier. */
    customerId: string | null;

    /** The branch of choice identifier. */
    branchOfChoiceId?: string | null;

    /** The sales partner center identifier. */
    salesPartnerCenterId?: string | null;

    /** the financing configuration identifier. Is set by VPC when submitted. Changes of frontend are ignored.     */
    financingConfigurationId?: string | null;

    /** The duration assumed. */
    assumedDuration?: number | null;

    /** The communityCustomerNumber in bank system. */
    communityCustomerNumber?: string | null;

    /* #region Own Capital */

    /** The cash. */
    cash?: number | null;
    /** The sales revenue. */

    salesRevenue?: number | null;
    /** The prefinancing sales. */

    prefinancingSales?: boolean | null;
    /** The redemption insurance. */

    redemptionInsurance?: number | null;
    /** The prefinancing insurance. */

    prefinancingInsurance?: boolean | null;
    /** The bauspar credit balance. */

    bausparCreditBalance?: number | null;
    /** Whether prefinancing of the bauspar credit balance is required. */

    prefinancingBausparCreditBalance?: boolean | null;

    /** The funding. */
    funding?: number | null;

    /**  Whether prefinancing of the funding is required. */
    prefinancingFunding?: boolean | null;

    /** The other own capital. */
    otherOwnCapital?: number | null;

    /** Whether prefinancing of the other own capital is required. */
    prefinancingOtherOwnCapital?: boolean | null;

    /* #endregion */

    /* #region Fees */
    /** The notary fee input value. */
    notaryFeeInput?: number | null;

    /** true if FinancingMapModel.notaryFeeInput is percent; otherwise, false. */
    notaryFeeIsPercent?: boolean | null;

    /** The brokerage fee input value. */
    brokerageFeeInput?: number | null;

    /** true if FinancingMapModel.brokerageFeeInput is percent; otherwise, false. */
    brokerageFeeIsPercent?: boolean | null;

    /** The handling fee. */
    handlingFeeInput?: number | null;

    /** true if FinancingMapModel.handlingFee is percent; otherwise, false. */
    handlingFeeIsPercent?: boolean | null;

    /** The valuation fee. */
    valuationFeeInput?: number | null;

    /** true if FinancingMapModel.valuationFee is percent; otherwise, false. */
    valuationFeeIsPercent?: boolean | null;

    /** The registration charges in percent (0 or 1.44). */
    registrationChargesPercent?: number | null;

    /* #endregion */

    /** The financing notes. */
    financingNotes?: string | null;

    /** Whether there are guarantees. */
    guarantees?: boolean | null;

    /** The notes regarding guarantees. */
    notesGuarantees?: string | null;

    /** the DocumentType of documents there are required to submit financing */
    requiredDocuments?: DocumentType[];

    /** The additional sample calculation information. */
    additionalSampleCalculationInformation?: string | null;

    /** The accepted sample calculation file identifier. */
    acceptedSampleCalculationFileId?: string | null;
    
    /** the requested consultation language */
    requestedLanguages?: Language | null;

    /* #region Product */

    /** The grace period. */
    gracePeriod?: number | null;

    /** The requested debit rate. */
    requestedDebitRate?: number | null;

    /** The requested debit rate notes. */
    requestedDebitRateNotes?: string | null;

    /** The calculation version. */
    calculationVersion?: number | null;

    /* #endregion */

    /* #region Guarantee for company */

    /** The duration of the guarantee. */
    guaranteeAmount?: number | null;

    /** The guarantee date. */
    guaranteeDate?: ISODate | null;

    /** The duration of the guarantee. */
    guaranteeDuration?: number | null;

    /** The guarantee original to type. */
    guaranteeOriginalTo?: OriginalToType | null;

    /** The guarantee original to other notes. */
    guaranteeOriginalToOtherNotes?: string | null;

    /* #endregion */

    /* #region Wohnbaukonto */

    /** The construction credit amount. */
    constructionCreditAmount?: number | null;

    /** The construction credit processing charges. */
    constructionCreditProcessingCharges?: number | null;

    /** The construction credit debit rate. */
    constructionCreditDebitRate?: number | null;

    /** The construction credit duration. */
    constructionCreditDuration?: number | null;

    /** The construction credit covered by notes. */
    constructionCreditCoveredNotes?: string | null;

    /** The construction credit guarantee notes. */
    constructionCreditGuaranteeNotes?: string | null;

    /* #endregion */

    /* #region MVP */

    /** The differing debit rate. */

    differingDebitRate?: number | null;
    /** The reason for differing debit rate. */

    sampleCalculationResponse?: string | null;
    /** The status information. */

    statusInfo?: string | null;
    /** If there was a consulting service. */

    consultingService?: boolean | null;

    /* #endregion */

    /* #region Credit */

    /** The planned financing start. */
    plannedFinancingStart?: ISODate | null;

    /** The planned financing end. */
    plannedFinancingEnd?: ISODate | null;

    /** The financing fee. */
    financingFee?: number | null;

    /** The interest method. */
    interestMethod?: InterestMethod | null;

    /** The status. */
    status?: FinancingMapStatus;

    /* #endregion */

    /** The display name of the clerk. */
    clerkDisplayName?: string | null;

    /** The reference interest rate. */
    referenceInterestRate?: number | null;

    /** The esis message. */
    esisMessage?: string | null;

    /** The credit application statement. */
    creditApplicationStatement?: string | null;

    /** The flag for can use extended export */
    canUseExtendedExport?: boolean | null;

    /** flag if map canBeArchived */
    canBeArchived?: boolean | null;

    /** the differing fictional rate. Is only set by backend */
    differingFictionalRate?: number | null;

    /** wurde die Finanzierung automatisch zurückgestellt */
    isAutoExit?: boolean | null;

    /** startzeit der vsl. Bearbeitungszeit */
    processingTimeStart?: ISODate | null;

    /** vsl. Bearbeitungszeit inkl. Buffer */
    processingTimeStartWithBuffer?: ISODate | null;

    /** Der Zeitpunkt wenn der Fall einem Bearbeiter in Finprocess zum ersten mal zugewiesen wurde */
    processingActuallyStart?: ISODate | null;
}
