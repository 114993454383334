import { Component } from '@angular/core';

/**
 * Komponente an den Kunden nach richtige Codeeingabe landet
 */
@Component({
    selector: 'cxad-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent {

}
