import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { GlobalSettings } from '@ucba/sdk';
import { Dispose } from '@ucba/sdk/statemanagement/actions';
import { take } from 'rxjs';

import { ApplicationState } from '../statemanagement/states';

/**
 * Guard zum prüfen ob ein gültiger Passcode eingegeben wurde,
 * - use canActivate: [AuthGuard] in routing module
 * 
 * @see https://codeburst.io/using-angular-route-guard-for-securing-routes-eabf5b86b4d1
 */
@Injectable({
    providedIn: 'root',
})
export class AuthGuard {

    /**
     * Standard Konstruktor
     * 
     * @param {Router} router Router injector
     * @param {Store} store Store injector
     * @param {NotificationService} notificationService NotificationService injector
     * @param {TranslateService} translate TranslateService injector
     */
    public constructor(
        private router: Router,
        private store: Store,
        private notificationService: NotificationService,
        private translate: TranslateService,
    ) { }

    /**
     * @override
     * @returns {Promise<boolean>} kann route aktiviert werden
     */
    public canActivate(): Promise<boolean> {
        if (GlobalSettings.assertAccessToken() && this.store.selectSnapshot(ApplicationState.hasConfirmed)) {
            return Promise.resolve(true);
        }
        else if (!GlobalSettings.token) { // Token wurde noch nie gesetzt
            const customerId = this.store.selectSnapshot(ApplicationState.customerId);
            this.router.navigate([`/${!!customerId ? customerId : ''}`]).catch(e => { throw e; });
            return Promise.resolve(false);
        }
        else {
            return new Promise<boolean>((resolve, reject) => {
                this.notificationService.alert(
                    this.translate.instant('global.invalidTokenTitle'),
                    this.translate.instant('global.invalidTokenText'),
                    false,
                )
                    .pipe(take(1))
                    .subscribe(() => {
                        const customerId = this.store.selectSnapshot(ApplicationState.customerId);
                        this.store.dispatch(new Dispose());
                        // navigate to root page
                        this.router.navigate([`/${!!customerId ? customerId : ''}`]).catch(reject);

                        resolve(false);
                    })
            });
        }
    }
}
