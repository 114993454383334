import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { DocumentType } from '@ntag-ef/finprocess-enums';
import { ObjectPurposeType } from '@ntag-ef/finprocess-enums/finadvisory';
import { sort } from 'fast-sort';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, filter, map, take, tap } from 'rxjs/operators';

import { ValidationDialogComponent } from '../../components/validation-dialog/validation-dialog.component';
import { ApplicationArea, FinancingMapStatus, ModelType, RoutingParams, SubArea } from '../../enums';
import { IFieldCardVisibilityRealEstate, IValidationDialogAccordion, IValidationDialogAccordionItem, IValidationDialogArea, IValidationDialogResult, IValidationDocumentField, IValidationField, IValidationMapClass, IValidationMapField, IValidationSection } from '../../interfaces';
import { IDebtorModel, IDocumentModel, IFileModel, IFinancingMapModel, IHouseholdModel, ILiabilityModel, INewLiabilityModel, IRealEstateModel, ISignatureModel } from '../../models';
import { IBackendBaseModel } from '../../models/backend-base.model';
import { CustomerState, DebtorState, DocumentState, FileState, FinancingMapState, HouseholdState, LiabilityState, NewLiabilityState, RealEstateState, SignatureState } from '../../statemanagement/states';
import { CALCULATOR_PLAN_FORM_VALIDATION_MAP, DEBTOR_FORM_VALIDATION_MAP, DOCUMENT_CARD_VALIDATION_MAP, FINANCING_PLAN_FORM_VALIDATION_MAP, HOUSEHOLD_FORM_VALIDATION_MAP, IDocumentValidation, REALESTATE_FORM_VALIDATION_MAP, isLivingAreaValid } from '../../validations';
import { HelperService } from '../helper/helper.service';

/**
 * Service, welcher sich um die pflichfeldvalidierung kümmert
 */
@Injectable()
export class ValidationService {
    private static validatorsChanged$ = new Subject<AbstractControl>();

    //#region data observable

    private currentDebtors$: Observable<IDebtorModel[]>;
    private currentFinancingMap$: Observable<IFinancingMapModel | null>;
    private currentFinancingObject$: Observable<IRealEstateModel | null>;
    private currentRealEstates$: Observable<IRealEstateModel[]>;
    private currentHouseholds$: Observable<IHouseholdModel[]>;
    private currentLiabilities$: Observable<ILiabilityModel[]>;
    private currentNewLiabilities$: Observable<INewLiabilityModel[]>;
    private currentDocuments$: Observable<IDocumentModel[]>;
    private currentFiles$: Observable<IFileModel[]>;
    private currentSignatures$: Observable<ISignatureModel[]>;

    //#endregion

    //#region validierungen

    public invalidFieldsInDebtor$: Observable<IValidationSection<IValidationField>[]>;
    public invalidFieldsInHouseHold$: Observable<IValidationSection<IValidationField>[]>;
    public invalidFieldsInFinancingPlan$: Observable<IValidationSection<IValidationField>[]>;
    public invalidFieldsInRealEstate$: Observable<IValidationSection<IValidationField>[]>;
    public invalidFieldsInNote$: Observable<IValidationSection<IValidationField>[]>;
    public invalidFieldsInSubmissionsDocuments$: Observable<IValidationSection<IValidationDocumentField>[]>;
    public invalidFieldsInBankDocuments$: Observable<IValidationSection<IValidationDocumentField>[]>;
    public invalidFieldsInCalculation$: Observable<IValidationSection<IValidationField>[]>;
    public financingMapValid$: Observable<{ valid: boolean, preCheck: boolean }>;
    public financingMapDocumentsValid$: Observable<boolean>;
    public financingMapCalculationValid$: Observable<boolean>;
    public financingValid$: Observable<boolean>;
    public isDebtorValid: boolean | undefined;
    public isHouseHoldValid: boolean | undefined;
    public isFinancingPlanValid: boolean | undefined;
    public isRealEstateValid: boolean | undefined;
    public isGuarenteesValid: boolean | undefined;
    public isNotesValid: boolean | undefined;
    public isSubmissionDocumentValid: boolean | undefined;
    public isBankDocumentsValid: boolean | undefined;
    public isCalculationValid: boolean | undefined;
    public isPrecheckValid?: boolean;
    public isValidForSubmit?: boolean;

    public isReadOnly: boolean | undefined;

    //#endregion

    /**
     * Observable für das aktuelle Feld, welches hervorgehoben werden soll
     * 
     * @returns {Observable} Observable des Feldes
     */
    public get highlightField$(): Observable<IValidationDialogResult | null> {
        return this.highlightFieldSubject.asObservable();
    }

    private highlightFieldSubject = new BehaviorSubject<IValidationDialogResult | null>(null);

    /**
     * Observable welcher immer triggert, sobald sich die Validierung eines Controls ändert
     *
     * @returns {Observable} observable mit control
     */
    public static get validatorsChangedObservable$(): Observable<AbstractControl> {
        return ValidationService.validatorsChanged$.asObservable();
    }

    /**
     * Konstruktor initialisiert alle Observable
     *
     * @param {Store} store Store injector
     * @param {NGXLogger} logger NGXLogger injector
     * @param {TranslateService} translate Translation injector
     * @param {MatDialog} dialog MatDialog injector
     * @param {Router} router Router injector
     */
    public constructor(
        private store: Store,
        private logger: NGXLogger,
        private translate: TranslateService,
        private dialog: MatDialog,
        private router: Router,
    ) {
        this.highlightFieldSubject.next(null);

        this.currentDebtors$ = this.store.select(DebtorState.current);
        this.currentFinancingMap$ = this.store.select(FinancingMapState.current);
        this.currentFinancingObject$ = this.store.select(RealEstateState.currentFinancingObject);
        this.currentRealEstates$ = this.store.select(RealEstateState.current);
        this.currentHouseholds$ = this.store.select(HouseholdState.current);
        this.currentLiabilities$ = this.store.select(LiabilityState.current);
        this.currentNewLiabilities$ = this.store.select(NewLiabilityState.current);
        this.currentDocuments$ = this.store.select(DocumentState.current);
        this.currentFiles$ = this.store.select(FileState.current);
        this.currentSignatures$ = this.store.select(SignatureState.current);

        this.store.select(FinancingMapState.current).subscribe(financing => {
            this.isReadOnly = !!financing && !FinancingMapState.staticIsFinancingMapEditable(financing.status);
        });

        this.invalidFieldsInDebtor$ = combineLatest([
            this.currentDebtors$,
            this.currentFinancingMap$,
        ]).pipe(
            filter(([debtors, financingMap]) => Array.isArray(debtors) && debtors.length > 0 && !!financingMap),
            map(([debtors, financingMap]) => ValidationService.getValidationSectionForDebtorForm(debtors, financingMap as IFinancingMapModel)),
            tap(fields => { this.isDebtorValid = fields.length === 0 }),
        );

        this.invalidFieldsInHouseHold$ = combineLatest([
            this.currentHouseholds$,
            this.currentDebtors$,
            this.currentLiabilities$,
            this.currentNewLiabilities$,
        ]).pipe(
            filter(([households, debtors, liabilities, newLiabilities]) =>
                Array.isArray(households) && households.length > 0 &&
                Array.isArray(debtors) && debtors.length > 0 &&
                Array.isArray(liabilities) &&
                Array.isArray(newLiabilities)),
            map(([households, debtors, liabilities, newLiabilities]) =>
                ValidationService.getValidationSectionForHouseholdForm(households, debtors, liabilities, newLiabilities)),
            tap(fields => { this.isHouseHoldValid = fields.length === 0 }),
        );

        this.invalidFieldsInFinancingPlan$ = combineLatest([
            this.currentFinancingMap$,
            this.currentFinancingObject$,
        ]).pipe(
            filter(([financingMap, realEstate]) => !!financingMap && !!realEstate),
            map(([financingMap, realEstate]) => ValidationService.getValidationSectionForFinancingPlanForm(financingMap as IFinancingMapModel, realEstate as IRealEstateModel)),
            tap(fields => { this.isFinancingPlanValid = fields.length === 0 }),
        );

        this.invalidFieldsInRealEstate$ = combineLatest([
            this.currentRealEstates$,
        ]).pipe(
            filter(([realEstates]) => Array.isArray(realEstates) && realEstates.length > 0),
            map(([realEstates]) => this.getValidationSectionForRealEstateForm(realEstates)),
            tap(fields => { this.isRealEstateValid = fields.length === 0 }),
        );

        this.invalidFieldsInNote$ = combineLatest([
            this.currentFinancingMap$,
        ]).pipe(
            filter(([financingMap]) => !!financingMap),
            map(([financingMap]) => ValidationService.getValidationSectionForNoteForm(financingMap as IFinancingMapModel)),
            tap(fields => { this.isNotesValid = fields.length === 0 }),
        );

        this.invalidFieldsInSubmissionsDocuments$ = combineLatest([
            this.currentFinancingMap$,
            this.currentDebtors$,
            this.currentRealEstates$,
            this.currentHouseholds$,
            this.currentLiabilities$,
            this.currentDocuments$,
            this.currentSignatures$,
            this.currentFiles$,
        ]).pipe(
            filter(([financingMap, debtors, realEstates, household, liabilities, documents, signatures, files]) =>
                !!financingMap &&
                Array.isArray(debtors) && debtors.length > 0 &&
                Array.isArray(realEstates) && realEstates.length > 0 &&
                Array.isArray(household) && household.length > 0 &&
                Array.isArray(liabilities) &&
                Array.isArray(documents) &&
                Array.isArray(signatures) &&
                Array.isArray(files)),
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            map(([financingMap, debtors, realEstates, household, liabilities, documents, signatures]) => this.getValidationSectionForSubmissionDocuments(financingMap as IFinancingMapModel, debtors, realEstates, household, liabilities, documents, signatures)),
            tap(fields => { this.isSubmissionDocumentValid = fields.length === 0 }),
        );

        this.invalidFieldsInBankDocuments$ = combineLatest([
            this.currentFinancingMap$,
        ]).pipe(
            filter(([financingMap]) => !!financingMap),
            map(([financingMap]) => financingMap as IFinancingMapModel),
            map(financingMap => ValidationService.getValidationSectionForBankDocuments(financingMap)),
            tap(fields => { this.isBankDocumentsValid = fields.length === 0 }),
        );

        this.invalidFieldsInCalculation$ = combineLatest([
            this.currentFinancingMap$,
        ]).pipe(
            filter(([financingMap]) => !!financingMap),
            map(([financingMap]) => ValidationService.getValidationFieldsForCalculator(financingMap as IFinancingMapModel)),
            tap(fields => { this.isCalculationValid = fields.length === 0 }),
        );

        this.financingMapValid$ = combineLatest([
            this.invalidFieldsInDebtor$,
            this.invalidFieldsInHouseHold$,
            this.invalidFieldsInFinancingPlan$,
            this.invalidFieldsInRealEstate$,
            this.invalidFieldsInNote$,
            this.invalidFieldsInSubmissionsDocuments$,
            this.invalidFieldsInBankDocuments$,
            this.invalidFieldsInCalculation$,
        ]).pipe(debounceTime(200),
            map(validationSections => ({
                valid: validationSections.every(sections => sections.length === 0),
                preCheck: !validationSections.some(sections => (sections as IValidationSection<IValidationField | IValidationDocumentField>[])
                    .some(section => section.fields.some(field => field.validatorsPrecheck !== undefined))),
            })),
            tap(validation => {
                this.isPrecheckValid = validation.preCheck;
                this.isValidForSubmit = validation.valid;
            }),
        );

        this.financingValid$ = combineLatest([
            this.invalidFieldsInDebtor$,
            this.invalidFieldsInHouseHold$,
            this.invalidFieldsInFinancingPlan$,
            this.invalidFieldsInRealEstate$,
            this.invalidFieldsInNote$,
        ]).pipe(map(validationSections => validationSections.every(section => section.length === 0)));

        this.financingMapDocumentsValid$ = combineLatest([
            this.invalidFieldsInSubmissionsDocuments$,
            this.invalidFieldsInBankDocuments$,
        ]).pipe(map(validationSections => validationSections.every(section => section.length === 0)));

        this.financingMapCalculationValid$ = combineLatest([
            this.invalidFieldsInCalculation$,
        ]).pipe(map(validationSections => validationSections.every(section => section.length === 0)));
    }

    /**
     * gibt die Validierungsbereiche des Personen Formulars zurück, in denen es invalide Felder gibt
     *
     * @param {IDebtorModel[]} debtors die Daten der Kreditnehmer
     * @param {IFinancingMapModel} financingMap die Daten der Finanzierung
     * @returns {IValidationSection[]} array aus Validierungsbereiche
     */
    private static getValidationSectionForDebtorForm(debtors: IDebtorModel[], financingMap: IFinancingMapModel): IValidationSection<IValidationField>[] {
        return debtors.reduce<IValidationSection<IValidationField>[]>((sections, debtor, idx) => {
            const fields = ValidationService.validateSingleModel(
                DEBTOR_FORM_VALIDATION_MAP['debtorModel'],
                debtor,
                ModelType.Debtor,
                financingMap,
            );

            return fields.length > 0 ? [...sections, ({
                label: HelperService.calcDebitorLabel(debtor, idx, 'Kreditnehmer'), // TODO label in de.json
                fields,
            } as IValidationSection<IValidationField>)] : sections;
        }, []);
    }

    /**
     * gibt die Validierungsbereiche des Haushalts Formulars zurück, in denen es invalide Felder gibt
     *
     * @param {IHouseholdModel[]} households die Daten der Haushalte
     * @param {IDebtorModel[]} debtors die Daten der Kreditnehmer
     * @param {ILiabilityModel[]} liabilities die Daten der Bestandskredite
     * @param {INewLiabilityModel[]} newLiabilities die Daten der neuen Verpflichtungen
     * @returns {IValidationSection[]} array aus Validierungsbereiche
     */
    private static getValidationSectionForHouseholdForm(
        households: IHouseholdModel[],
        debtors: IDebtorModel[],
        liabilities: ILiabilityModel[],
        newLiabilities: INewLiabilityModel[],
    ): IValidationSection<IValidationField>[] {
        return households.reduce<IValidationSection<IValidationField>[]>((sections, household, householdIdx) => {

            // Validierung Haushaltsangaben
            const invalidInHoushold = ValidationService.validateSingleModel(
                HOUSEHOLD_FORM_VALIDATION_MAP['householdModel'],
                household,
                ModelType.Household,
            );

            // Validierung Haushalt hat Kreditnehmer
            if (!debtors.some(({ householdId }) => householdId === household.id)) {
                invalidInHoushold.push({
                    fieldName: 'title',
                    modelId: household.id,
                    validators: { missingDebtor: true },
                    subModel: ModelType.Household,
                });
            }

            if (invalidInHoushold.length > 0) {
                sections.push({
                    label: `${householdIdx + 1}. Haushalt`, // TODO label in de.json
                    fields: invalidInHoushold,
                });
            }

            // Validierung Kreditnehmerangaben im Haushalt
            debtors.forEach((debtor, debtorIdx) => {
                if (debtor.householdId === household.id) {
                    const invalidInDebtor = ValidationService.validateSingleModel(
                        HOUSEHOLD_FORM_VALIDATION_MAP['debtorModel'],
                        debtor,
                        ModelType.Debtor,
                    );

                    if (invalidInDebtor.length > 0) {
                        sections.push({
                            label: HelperService.calcHouseholdDebitorLabel(debtor, householdIdx, debtorIdx, 'Kreditnehmer'), // TODO label in de.json
                            fields: invalidInDebtor,
                        });
                    }
                }
            });

            // Validierung Bestandskredite
            liabilities.forEach((liability, liabilityIdx) => {
                if (liability.householdId === household.id) {
                    const invalidInLiability = ValidationService.validateSingleModel(
                        HOUSEHOLD_FORM_VALIDATION_MAP['liabilityModel'],
                        liability,
                        ModelType.Liability,
                    );

                    if (invalidInLiability.length > 0) {
                        sections.push({
                            label: `${householdIdx + 1}. Haushalt, ${liabilityIdx + 1}. Bestandskredit`,
                            customTranslationKey: 'modules.customer.components.financingTab.liability.',
                            fields: invalidInLiability,
                        });
                    }
                }
            });

            // Validierung neue Verpflichtungen
            newLiabilities.forEach((newLiability, newLiabilityIdx) => {
                if (newLiability.householdId === household.id) {
                    const invalidInNewLiability = ValidationService.validateSingleModel(
                        HOUSEHOLD_FORM_VALIDATION_MAP['newLiabilityModel'],
                        newLiability,
                        ModelType.NewLiability,
                    );

                    if (invalidInNewLiability.length > 0) {
                        sections.push({
                            label: `${householdIdx + 1}. Haushalt, ${newLiabilityIdx + 1}. Neue Verpflichtung`,
                            customTranslationKey: 'modules.customer.components.financingTab.newLiability.',
                            fields: invalidInNewLiability,
                        });
                    }
                }
            });

            return sections;
        }, []);
    }

    /**
     * gibt die Validierungsbereiche des Finanzierungsplan Formulars zurück, in denen es invalide Felder gibt
     *
     * @param {IFinancingMapModel} financingMap die Daten der Finanzierung
     * @param {IRealEstateModel} realEstate die Daten des Liegenschaftsobjekts
     * @returns {IValidationSection[]} array aus Validierungsbereiche
     */
    private static getValidationSectionForFinancingPlanForm(financingMap: IFinancingMapModel, realEstate: IRealEstateModel): IValidationSection<IValidationField>[] {
        const invalidInRealEstate = ValidationService.validateSingleModel(
            FINANCING_PLAN_FORM_VALIDATION_MAP['realEstateModel'],
            realEstate,
            ModelType.RealEstate,
            financingMap,
        );

        const invalidInFinancing = ValidationService.validateSingleModel(
            FINANCING_PLAN_FORM_VALIDATION_MAP['financingMapModel'],
            financingMap,
            ModelType.Financing,
            realEstate,
        );

        const fields = [...invalidInRealEstate, ...invalidInFinancing];

        return fields.length > 0 ? [({
            label: 'Finanzierungsplan', // TODO label in de.json
            fields,
        } as IValidationSection<IValidationField>)] : [];
    }

    /**
     * gibt die Validierungsbereiche der Bankunterlagen zurück, in denen es invalide Felder gibt
     *
     * @param {IFinancingMapModel} financingMap die Daten der Finanzierung
     * @returns {IValidationSection} array aus Validierungsbereiche
     */
    private static getValidationSectionForBankDocuments(financingMap: IFinancingMapModel): IValidationSection<IValidationDocumentField>[] {
        const fields: IValidationDocumentField[] = [];

        if (financingMap.status === FinancingMapStatus.SampleCalculationExists && !financingMap.acceptedSampleCalculationFileId) {
            fields.push({
                type: DocumentType.SampleCalculation,
                referenceId: financingMap.id,
            });
        }

        if (financingMap.status === FinancingMapStatus.EsisExists) {
            fields.push({
                type: DocumentType.ESIS,
                referenceId: financingMap.id,
            });
        }

        return fields.length > 0 ? [{
            label: '',
            fields,
        }] : [];
    }

    /**
     * gibt die Validierungsbereiche des "Anmerkung zur Finanzierung" Formulars zurück, in denen es invalide Felder gibt
     *
     * @param {IFinancingMapModel} financingMap die Daten der Finanzierung
     * @returns {IValidationSection[]} array aus Validierungsbereiche
     */
    private static getValidationSectionForNoteForm(financingMap: IFinancingMapModel): IValidationSection<IValidationField>[] {
        const fields = ValidationService.validateSingleModel(
            FINANCING_PLAN_FORM_VALIDATION_MAP['statement'],
            financingMap,
            ModelType.Financing,
        );

        return fields.length > 0 ? [({
            label: 'Anmerkungen', // TODO label in de.json
            fields,
        } as IValidationSection<IValidationField>)] : [];
    }

    /**
     * gibt die Validierungsbereiche des "Produktrechners" Formulars zurück, in denen es invalide Felder gibt
     *
     * @param {IFinancingMapModel} financingMap die Daten der Finanzierung
     * @returns {IValidationSection[]} array aus Validierungsbereiche
     */
    private static getValidationFieldsForCalculator(financingMap: IFinancingMapModel): IValidationSection<IValidationField>[] {
        const fields = ValidationService.validateSingleModel(
            CALCULATOR_PLAN_FORM_VALIDATION_MAP['financingMapModel'],
            financingMap,
            ModelType.Financing,
        );

        return fields.length > 0 ? [({
            label: 'Produktrechner', // TODO label in de.json
            fields,
        } as IValidationSection<IValidationField>)] : [];
    }

    /**
     * Validiert ein Datenmodel gegen eine validationMap
     *
     * @param {IValidationMapClass} validation validation Map
     * @param {IBackendBaseModel} model das zu prüfende Datenmodell
     * @param {ModelType} modelType type der Entität
     * @param {IBackendBaseModel[]} args zusätzliche Parameter
     * @returns {string[]} array aus feldnamen, welche nicht valide sind
     */
    private static validateSingleModel(validation: IValidationMapClass | IDocumentValidation[], model: IBackendBaseModel, modelType: ModelType, ...args: unknown[]): IValidationField[] {
        return Object.entries(validation).reduce<IValidationField[]>((fields, [fieldName, validationField]: [string, IValidationMapField]) => {
            const asRecord = model as unknown as Record<string, unknown>
            const excludeFromPrecheck = !!validationField.excludeFromPrecheck ? validationField.excludeFromPrecheck(model, ...args) : false;

            if (!validationField.ignoreRequireInCheck && !!validationField.required) {
                const isRequired = validationField.required(model, ...args);
                const isSet = !!validationField.customIsSet ? validationField.customIsSet(model, ...args) : HelperService.hasValue(asRecord[fieldName]);

                if (isRequired && !isSet) {
                    return [...fields, ({
                        fieldName,
                        modelId: model.id,
                        validators: { 'required': true },
                        validatorsPrecheck: excludeFromPrecheck ? undefined : { 'required': true },
                        subModel: modelType,
                    } as IValidationField)];
                }
            }

            const baseValidators: ValidatorFn[] = !!validationField.baseValidator ? validationField.baseValidator(model, ...args) : [];

            if (baseValidators.length > 0) {
                const controlValue = HelperService.isISODate(asRecord[fieldName]) ?
                    HelperService.parseISODateToGermanDateString(asRecord[fieldName] as string) :
                    asRecord[fieldName];

                const control = new FormControl(controlValue, baseValidators);
                const validators = !!control.validator ? control.validator(control) : null;

                if (!!validators) {
                    return [...fields, ({
                        fieldName,
                        modelId: model.id,
                        validators,
                        validatorsPrecheck: excludeFromPrecheck ? undefined : validators,
                    } as IValidationField)];
                }
            }

            return fields;
        }, []);
    }

    /**
     * Validiert ein Datenmodel gegen eine validationMap
     *
     * @param {IDocumentValidation} validation validation Map
     * @param {IDocumentModel[]} documents aktuelle Dokumente
     * @param {string} referenceId entitäten Id auf den das Dokument verweisen muss
     * @param {IBackendBaseModel[]} args zusätzliche Parameter
     * @returns {string[]} array aus feldnamen, welche nicht valide sind
     */
    private static validateDocumentModel(validation: IDocumentValidation[], documents: IDocumentModel[], referenceId: string, ...args: Array<IFieldCardVisibilityRealEstate | IBackendBaseModel | IBackendBaseModel[]>): IValidationDocumentField[] {
        return validation.reduce<IValidationDocumentField[]>((fields, validationField) => {

            const isRequired = validationField.required(...args) && validationField.visible(...args);
            const isSet = !isRequired || documents.some(doc => doc.type === validationField.type &&
                (!referenceId || doc[validationField.referenceProperty] === referenceId));

            if (isRequired && !isSet) {
                return [...fields, ({
                    referenceId,
                    type: validationField.type,
                    validators: { 'required': true },
                } as IValidationDocumentField)];
            }

            return fields;
        }, []);
    }

    /**
     * gibt die Validierungsbereiche des Liegenschaft Formulars zurück, in denen es invalide Felder gibt
     *
     * @param {IRealEstateModel[]} realEstates die Daten des Liegenschaftsobjekts und der Besicherungsobjekte
     * @param {string[]} excludeFields Bereiche, welche bei der Validierung nicht berücksichtig werden sollen
     * @returns {IValidationSection[]} array aus Validierungsbereiche
     */
    public getValidationSectionForRealEstateForm(realEstates: IRealEstateModel[], excludeFields?: string[]): IValidationSection<IValidationField>[] {
        return realEstates.map((realEstate, idx) => {
            let fields = ValidationService.validateSingleModel(
                REALESTATE_FORM_VALIDATION_MAP['realEstateModel'],
                realEstate,
                ModelType.RealEstate,
                this.isRealEstateValid,
            );

            if (Array.isArray(excludeFields) && excludeFields.length > 0) {
                fields = fields.filter(field => !excludeFields.includes(field.fieldName));
            }

            // reasonability
            const validateResult = isLivingAreaValid(realEstate);

            if (!validateResult.valid) {
                fields.push({
                    errorLabel: this.translate.instant('global.ErrorMessages.areaRange', { min: validateResult.min, max: validateResult.max }),
                    fieldName: 'livingArea',
                    modelId: realEstate.id,
                    subModel: ModelType.RealEstate,
                });
            }

            if (!realEstate.latitude || !realEstate.longitude) {
                fields.push({
                    fieldName: 'titlePropertyAddress',
                    modelId: realEstate.id,
                    validators: { address: true },
                    subModel: ModelType.RealEstate,
                });
            }

            return fields.length > 0 ? ({
                label: realEstate.objectPurpose === ObjectPurposeType.Finance
                    ? this.translate.instant('modules.customer.components.financingTab.realestate.titleFinancingObject')
                    : realEstate.name ? `Objekt ${idx + 1} (${realEstate.name})` : `Objekt ${idx + 1}`,
                fields,
            } as IValidationSection<IValidationField>) : null;
        }).filter(section => section !== null) as IValidationSection<IValidationField>[];
    }

    /**
     * setzt bei den FormControls die Validatoren je nach konfigurierten Werten der validationMap
     *
     * @param {IValidationMapClass} validationMap die validierungskonfiguration
     * @param {FormGroup} form das Formular mit den FormControls
     * @param {boolean} highlightInvalidFields sollen invalide Felder sofort hervorgehoben werden
     * @param {any[]} args Paramter welche für das ermitteln gebraucht werden
     */
    public setValidatorsForForm(validationMap: IValidationMapClass, form: FormGroup, highlightInvalidFields?: boolean, ...args: unknown[]): void {
        for (const [controlName, control] of Object.entries(form.controls)) {
            if (!this.isReadOnly) {
                if (control.disabled) { control.enable({ onlySelf: true }); }

                const validationField = validationMap[controlName];

                if (validationField === undefined) {
                    this.logger.error(new Error(`ValidationMap don't contain ${controlName}, please check the property name or given validationMap`));
                    continue;
                }

                const validators: ValidatorFn[] = validationField.baseValidator(...args);

                if (!!validationField.required && validationField.required(...args)) {
                    validators.push(Validators.required);
                }

                control.setValidators(validators);
                control.updateValueAndValidity({ emitEvent: false, onlySelf: true });
                ValidationService.validatorsChanged$.next(control);

                if (control.invalid && highlightInvalidFields) {
                    control.markAsDirty();
                    control.markAsTouched();
                }
            }
            else {
                if (control.enabled) {
                    control.disable({ emitEvent: true, onlySelf: true });
                    control.updateValueAndValidity({ emitEvent: false, onlySelf: true });
                }
            }
        }
    }

    /**
     * läd das lokale Objekt, aktualisiert es mit den übergebenen Werten und prüft ob das neue Objekt valide ist
     *
     * @param {Partial<IRealEstateModel>} realestate akutalisierte Daten
     * @returns {boolean} ist Objekt valide
     */
    public isPartialRealestateValid(realestate: Partial<IRealEstateModel>): boolean {
        const excludedFields = ['marketValue'];

        if (!!realestate.id) {
            const local = this.store.selectSnapshot(RealEstateState.byId)(realestate.id);
            const toCheck = Object.assign({}, local, realestate);
            return this.getValidationSectionForRealEstateForm([toCheck], excludedFields).length === 0;
        }

        return false;
    }

    /**
     * gibt die Validierungsbereiche der Einreichunterlagen zurück, in denen es invalide Felder gibt
     *
     * @param {IFinancingMapModel} financingMap die Daten der Finanzierung
     * @param {IDebtorModel[]} debtors die Daten der Kreditnehmer
     * @param {IRealEstateModel[]} realEstates die anzeigelogik des Objektes
     * @param {IHouseholdModel[]} households die Daten der Haushalte
     * @param {ILiabilityModel[]} liabilities die Daten der Bestandskredite
     * @param {IDocumentModel[]} currentDocuments die Daten der Dokumente
     * @param {ISignatureModel[]} signatures die Daten der Unterschriften
     * @returns {IValidationSection[]} array aus Validierungsbereiche
     */
    // eslint-disable-next-line complexity
    private getValidationSectionForSubmissionDocuments(financingMap: IFinancingMapModel, debtors: IDebtorModel[], realEstates: IRealEstateModel[], households: IHouseholdModel[], liabilities: ILiabilityModel[], currentDocuments: IDocumentModel[], signatures: ISignatureModel[]): IValidationSection<IValidationDocumentField>[] {
        // Dokumente Bereich Unsortiert
        const unsortedSection: IValidationSection<IValidationDocumentField> | undefined = currentDocuments.some(({ type }) => !HelperService.hasValue(type)) ? {
            label: 'Es existieren noch unsortierte Dokumente', // TODO label in de.json
            fields: [],
        } : undefined;

        // Dokumente Bereich Person
        const debtorDocuments = debtors.reduce<IValidationSection<IValidationDocumentField>[]>((sections, debtor, idx) => {
            const debtorFields = ValidationService.validateDocumentModel(
                DOCUMENT_CARD_VALIDATION_MAP['debtor'],
                currentDocuments,
                debtor.id,
            );

            return debtorFields.length > 0 ? [...sections, ({
                label: HelperService.calcDebitorLabel(debtor, idx, 'Kreditnehmer'), // TODO label in de.json
                fields: debtorFields,
            } as IValidationSection<IValidationDocumentField>)] : sections;
        }, []);


        // Dokumente Bereich Haushalt
        const householdSections = households.reduce<IValidationSection<IValidationDocumentField>[]>((sections, household, hhIdx) => {

            const onlyHousehold = DOCUMENT_CARD_VALIDATION_MAP['household'].filter(val => !val.useForAnyDebtor);
            const useForAnyDebtor = DOCUMENT_CARD_VALIDATION_MAP['household'].filter(val => val.useForAnyDebtor);

            const householdFields = ValidationService.validateDocumentModel(
                onlyHousehold,
                currentDocuments,
                household.id,
            );

            if (householdFields.length > 0) {
                sections.push({
                    label: `${hhIdx + 1}. Haushalt`, // TODO label in de.json
                    fields: householdFields,
                });
            }

            const debtorsOfHousehold = debtors.filter(({ householdId }) => householdId === household.id);

            debtorsOfHousehold.forEach((deb, idx) => {
                const debtorFields = ValidationService.validateDocumentModel(
                    useForAnyDebtor,
                    currentDocuments,
                    deb.id,
                    deb,
                );

                if (debtorFields.length > 0) {
                    sections.push({
                        label: HelperService.calcHouseholdDebitorLabel(deb, hhIdx, idx, 'Kreditnehmer'),
                        fields: debtorFields,
                    });
                }
            });

            return sections;
        }, []);

        // Dokumente Bereich Finazierungsplan

        const financingPlanSections: IValidationSection<IValidationDocumentField>[] = [];

        const onlyFinancingPlan = DOCUMENT_CARD_VALIDATION_MAP['financingplan'].filter(val => !val.useForAnyDebtor);
        const useForAnyDebtor = DOCUMENT_CARD_VALIDATION_MAP['financingplan'].filter(val => val.useForAnyDebtor);

        const financingplanFields = ValidationService.validateDocumentModel(
            onlyFinancingPlan,
            currentDocuments,
            financingMap.id,
            liabilities,
        );

        if (financingplanFields.length > 0) {
            financingPlanSections.push({
                label: 'Finanzierungsplan', // TODO label in de.json
                fields: financingplanFields,
            });
        }

        debtors.forEach((deb, idx) => {
            const debtorFields = ValidationService.validateDocumentModel(
                useForAnyDebtor,
                currentDocuments,
                deb.id,
            );

            if (debtorFields.length > 0) {
                financingPlanSections.push({
                    label: HelperService.calcDebitorLabel(deb, idx, 'Kreditnehmer'),
                    fields: debtorFields,
                });
            }
        });

        // Dokumente Bereich Objekt

        const realEstateSections = realEstates.reduce<IValidationSection<IValidationDocumentField>[]>((sections, realEstate, idx) => {
            const missing = ValidationService.validateDocumentModel(
                DOCUMENT_CARD_VALIDATION_MAP['realestate'],
                currentDocuments,
                realEstate.id,
                RealEstateState.visibleMap(realEstate),
            );

            if (missing.length > 0) {
                const realEstateSection: IValidationSection<IValidationDocumentField> = {
                    label: realEstate.objectPurpose === ObjectPurposeType.Finance ?
                        this.translate.instant('modules.customer.components.financingTab.realestate.titleFinancingObject') :
                        realEstate.name ? `Objekt ${idx + 1} (${realEstate.name})` : `Objekt ${idx + 1}`,
                    fields: missing,
                };

                return [...sections, realEstateSection];
            }
            else {
                return sections;
            }
        }, []);

        // Unterschriften

        const signatureFields = DOCUMENT_CARD_VALIDATION_MAP['signature'].reduce<IValidationDocumentField[]>((fields, val) => {
            const isRequired = val.required() && val.visible();

            if (isRequired) {
                // Prüfung ob ein Unterschriftsdokument hochgeladen wurde
                const hasDocument = currentDocuments.some(({ type }) => type === val.type);

                // Falls nicht, prüfung ob alle Unterschriften gesetzt wurden
                if (!hasDocument) {
                    const allSign = debtors.every(({ id }) => signatures.some(({ debtorId, type }) => type === val.type && debtorId === id));

                    if (!allSign) {
                        return [...fields, {
                            referenceId: financingMap.id,
                            type: val.type,
                            validators: { 'required': true },
                        }];
                    }
                }
            }

            return fields;
        }, []);

        const signatureSection: IValidationSection<IValidationDocumentField> | undefined = signatureFields.length > 0 ? {
            label: 'Unterschriften',
            fields: signatureFields,
        } : undefined;


        // Dokumente Bereich Nachzureichende Unterlagen

        const requiredDocumentFields = DOCUMENT_CARD_VALIDATION_MAP['requiredDocuments'].reduce<IValidationDocumentField[]>((fields, validation) => {
            let referenceId: string | undefined;

            if (validation.referenceProperty === 'financingMapId') {
                referenceId = financingMap.id;
            }
            else if (validation.referenceProperty === 'realEstateId') {
                // Nachzureichende Unterlagen immer am zu finanzierenden Objekt
                referenceId = realEstates.find(({ objectPurpose }) => objectPurpose === ObjectPurposeType.Finance)?.id;
            }

            const isRequired = validation.required(financingMap) && validation.visible(financingMap);
            const isSet = !isRequired || (!!referenceId && currentDocuments.some(doc => {
                if (doc.type === validation.type && doc[validation.referenceProperty] === referenceId) {
                    const files = this.store.selectSnapshot(FileState.filesByDocumentIds)([doc.id]);
                    return files.some(({ isSigned }) => isSigned);
                }
                else {
                    return false;
                }
            }));

            if (isRequired && !isSet) {
                return [...fields, ({
                    referenceId,
                    type: validation.type,
                    validators: { 'required': true },
                } as IValidationDocumentField)];
            }
            else {
                return fields;
            }

        }, []);

        const requiredDocumentSection: IValidationSection<IValidationDocumentField> | undefined = requiredDocumentFields.length > 0 ? {
            label: 'Nachzureichende Unterlagen',
            fields: requiredDocumentFields,
        } : undefined;

        // Unterschriftendokumete Haushaltsrechnung/Selbstauskunft
        const signDocuments = this.store.selectSnapshot(DocumentState.allHousholdDocuments);

        const householdDocumentSections = households.reduce<IValidationSection<IValidationDocumentField>[]>((sections, household, index) => {
            const signDocumentsForHousehold = signDocuments.filter(({ householdId: docHouseholdId, debtorId }) =>
                (docHouseholdId === household.id) || (debtors.some(({ id, householdId }) => householdId === household.id && id === debtorId)));

            const householdDocumentFields = signDocumentsForHousehold.reduce<IValidationDocumentField[]>((fields, document) => {
                const validation = DOCUMENT_CARD_VALIDATION_MAP['householdDocuments'].find(({ type }) => type === document.type);

                if (!!validation) {
                    const isRequired = validation.required() && validation.visible();
                    const referenceId = document[validation.referenceProperty];

                    const isSet = !isRequired || (currentDocuments.some(doc => {
                        if (doc.type === validation.type && doc[validation.referenceProperty] === referenceId) {
                            const files = this.store.selectSnapshot(FileState.filesByDocumentIds)([doc.id], true);
                            return files.length > 0;
                        }
                        else {
                            return false;
                        }
                    }));

                    if (isRequired && !isSet) {
                        let prefix = '';

                        if (validation.referenceProperty === 'debtorId') {
                            const debtorIdx = debtors.findIndex(({ id }) => id === referenceId);
                            if (debtorIdx !== -1) {
                                prefix = `${HelperService.calcDebitorLabel(debtors[debtorIdx], debtorIdx, 'Kreditnehmer')}: `;
                            }
                        }

                        return [...fields, ({
                            referenceId,
                            type: validation.type,
                            labelPrefix: prefix,
                            validators: { 'required': true },
                        } as IValidationDocumentField)];
                    }
                }

                return fields;
            }, []);

            if (householdDocumentFields.length > 0) {
                sections.push({
                    label: `Unterschriftsdokumete für ${index + 1}. Haushalt`,
                    fields: sort(householdDocumentFields).asc(({ type }) => type),
                });
            }

            return sections;
        }, []);

        let result = [
            ...debtorDocuments,
            ...householdSections,
            ...financingPlanSections,
            ...realEstateSections,
        ];

        if (!!unsortedSection) {
            // an erste stelle
            result = [unsortedSection, ...result];
        }

        if (!!signatureSection) {
            result.push(signatureSection);
        }

        if (!!requiredDocumentSection) {
            result.push(requiredDocumentSection);
        }

        return [...result, ...householdDocumentSections];
    }

    /**
     * Validiert einen Bereich der kompletten Finanzierung
     * 
     * @param {ApplicationArea} applicationArea aus welchem Bereich wurde der Check ausgelöst
     * @param {boolean} preCheck handelt es sich um eine validierung für den Precheck
     */
    public validateFinancingArea(applicationArea: ApplicationArea, preCheck?: boolean): void {
        const customerId = this.store.selectSnapshot(CustomerState.currentId);
        const financingMap = this.store.selectSnapshot(FinancingMapState.current);
        let dialogAreas: IValidationDialogArea[] | undefined;

        if (!!customerId && !!financingMap) {

            switch (applicationArea) {
                case ApplicationArea.FINANCING_MAP: {
                    const translationKey = 'modules.customer.components.financingTab';
                    dialogAreas = [
                        this.createDialogArea(applicationArea, translationKey, 'menu.debtorLabel', this.invalidFieldsInDebtor$, preCheck, SubArea.DEBTOR),
                        this.createDialogArea(applicationArea, translationKey, 'menu.householdLabel', this.invalidFieldsInHouseHold$, preCheck, SubArea.HOUSEHOLD),
                        this.createDialogArea(applicationArea, translationKey, 'menu.financingPlanLabel', this.invalidFieldsInFinancingPlan$, preCheck, SubArea.FINANCINGPLAN),
                        this.createDialogArea(applicationArea, translationKey, 'menu.realEstateLabel', this.invalidFieldsInRealEstate$, preCheck, SubArea.REALESTATE),
                        this.createDialogArea(applicationArea, translationKey, 'menu.notesLabel', this.invalidFieldsInNote$, preCheck, SubArea.NOTES),
                    ];

                    break;
                }

                case ApplicationArea.DOCUMENTS: {
                    const translationKey = 'modules.customer.components.paperTab';
                    const isEditable = FinancingMapState.staticIsFinancingMapEditable(financingMap.status)

                    dialogAreas = [
                        this.createDocumentDialogArea(applicationArea, translationKey, 'menu.submissionLabel', this.invalidFieldsInSubmissionsDocuments$, preCheck, SubArea.SUBMISSION),
                    ];

                    if (!isEditable) {
                        // Bank bereich wird nun validiert, wenn die Finanzierung einrereicht ist
                        dialogAreas.push(
                            this.createDocumentDialogArea(applicationArea, translationKey, 'menu.bankLabel', this.invalidFieldsInBankDocuments$, preCheck, SubArea.BANK),
                        )
                    }

                    break;
                }

                case ApplicationArea.CALCULATOR: {
                    const translationKey = 'modules.customer.components.financingTab.calculator';
                    const dialogArea = this.createDialogArea(applicationArea, translationKey, 'title', this.invalidFieldsInCalculation$, preCheck);
                    dialogAreas = [dialogArea];
                    break;
                }

                default: break;
            }

            if (Array.isArray(dialogAreas) && dialogAreas.length > 0) {
                this.handleValidationDialog(dialogAreas, customerId, financingMap.id);
            }
        }
    }

    /**
     * erzeugt eine AreaData für den Bereich FinancingMap
     * 
     * @param  {string} path Navigationspfad zu diesem Bereich
     * @param  {string} translationKey Anfang des Übersetzungskeys
     * @param  {string} menuTranslateSuffix Suffix des Übersetzungskeys für die Menü einträge
     * @param  {Observable<IValidationSection<IValidationField>[]>} fieldObservable$ Observable der fehlenden Pflichtfelder
     * @param  {boolean} preCheck ist dieser Check ein PreCheck
     * @param  {SubArea} subArea pfad zum unterbereich für navigation und übersetzung
     * @returns {IValidationDialogArea} die erzeugten area Daten
     */
    private createDialogArea(
        path: string,
        translationKey: string,
        menuTranslateSuffix: string,
        fieldObservable$: Observable<IValidationSection<IValidationField>[]>,
        preCheck?: boolean,
        subArea?: SubArea,
    ): IValidationDialogArea {
        const validators: keyof IValidationField = preCheck ? 'validatorsPrecheck' : 'validators';

        return {
            label: this.translate.instant(`${translationKey}.${menuTranslateSuffix}`),
            path,
            isSelected: HelperService.hasValue(subArea) && this.router.url.endsWith(subArea),
            sections$: fieldObservable$.pipe(
                map(sections => sections.map<IValidationDialogAccordion>(section => ({
                    label: section.label ?? '',
                    fields: (preCheck ? section.fields.filter(field => !!field.validatorsPrecheck) : section.fields).map<IValidationDialogAccordionItem>(field => ({
                        fieldName: field.fieldName,
                        label: this.translate.instant((!!section.customTranslationKey ? section.customTranslationKey :
                            ((HelperService.hasValue(subArea) ? `${translationKey}.${subArea}.` : `${translationKey}.`))) + field.fieldName),
                        errorLabel: this.getErrorLabel(field, validators, field.errorLabel),
                        itemId: field.modelId,
                        subModel: field.subModel,
                    })),
                    subArea,
                }))),
            ),
            missingFields$: fieldObservable$.pipe(
                map(sections => sections.reduce((count, section) => count + (preCheck ? section.fields.filter(field => !!field.validatorsPrecheck) : section.fields).length, 0)),
                filter(count => count > 0),
            ),
        };
    }

    /**
     * ermittelt den angezeigten Fehlertext im dialog
     * 
     * @param {IValidationField} field zu prüfendes feld
     * @param {string} validators propertyname im feld
     * @param {string} forcedErrorLabel forcierter Fehlertext
     * @returns {string} angezeigter Fehlertext
     */
    private getErrorLabel(field: IValidationField, validators: 'validators' | 'validatorsPrecheck', forcedErrorLabel?: string) {
        if (!!forcedErrorLabel) {
            return forcedErrorLabel;
        }

        const errors = field[validators];

        if (!!errors) {
            const key = HelperService.getFirstValidationError(errors);
            return this.translate.instant(`global.ErrorMessages.${key}`, errors[key]);
        }
        else {
            return '';
        }
    }

    /**
     * erzeugt eine AreaData für den Bereich FinancingMap
     * 
     * @param  {string} path Navigationspfad zu diesem Bereich
     * @param  {string} translationKey Anfang des Übersetzungskeys
     * @param  {string} menuTranslateSuffix Suffix des Übersetzungskeys für die Menü einträge
     * @param  {Observable<IValidationSection<IValidationField>[]>} fieldObservable$ Observable der fehlenden Pflichtfelder
     * @param  {boolean} preCheck ist dieser Check ein PreCheck
     * @param  {SubArea} subArea pfad zum unterbereich für navigation und übersetzung
     * @returns {IValidationDialogArea} die erzeugten area Daten
     */
    private createDocumentDialogArea(
        path: string,
        translationKey: string,
        menuTranslateSuffix: string,
        fieldObservable$: Observable<IValidationSection<IValidationDocumentField>[]>,
        preCheck?: boolean,
        subArea?: SubArea,
    ): IValidationDialogArea {
        const validators: keyof IValidationDocumentField = preCheck ? 'validatorsPrecheck' : 'validators';

        return {
            label: this.translate.instant(`${translationKey}.${menuTranslateSuffix}`),
            path,
            sections$: fieldObservable$.pipe(
                map(sections => sections.map<IValidationDialogAccordion>(section => ({
                    label: section.label ?? '',
                    fields: (preCheck ? section.fields.filter(field => !!field.validatorsPrecheck) : section.fields).map<IValidationDialogAccordionItem>(field => ({
                        fieldName: field.type.toString(),
                        label: `${field.labelPrefix ?? ''}${DocumentType.translate(field.type)}`,
                        errorLabel: field.errorLabel ?? (!!field[validators] ?
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            this.translate.instant(`global.ErrorMessages.${HelperService.getFirstValidationError(field[validators]!)}`, field[validators]!) :
                            ''),
                        itemId: field.referenceId,
                        subModel: ModelType.Document,
                    })),
                    subArea,
                }))),
            ),
            missingFields$: fieldObservable$.pipe(
                map(sections => sections.reduce((count, section) => count + (preCheck ? section.fields.filter(field => !!field.validatorsPrecheck) : section.fields).length, 0)),
                filter(count => count > 0),
            ),
        };
    }

    /** setzt das hervorgehobene Feld zurück */
    public resetHighlightField(): void {
        this.highlightFieldSubject.next(null);
    }

    /**
     * öffnet den validierungsdialog und verarbeitet die Auswahl
     * 
     * @param  {IValidationDialogArea[]} dialogAreas die anzuzeigenden Bereiche
     * @param  {string} customerId aktuelle KundenId
     * @param  {string} financingMapId aktuelle FinanzierungsId
     */
    private handleValidationDialog(dialogAreas: IValidationDialogArea[], customerId: string, financingMapId: string) {
        const dialogRef = this.dialog.open<ValidationDialogComponent, IValidationDialogArea[], IValidationDialogResult>(ValidationDialogComponent, {
            data: dialogAreas,
        });

        dialogRef.afterClosed()
            .pipe(take(1))
            .subscribe(result => {

                if (!!result) {
                    const path = [RoutingParams.CUSTOMER_MODULE, customerId, financingMapId, result.path];

                    if (HelperService.hasValue(result.subArea)) {
                        path.push(result.subArea);
                    }

                    if (this.router.url !== `/${path.join('/')}`) {
                        // ziel ist in einem anderen bereich
                        this.router.navigate(['/', ...path], { replaceUrl: true })
                            .then(() => {
                                this.highlightFieldSubject.next(result);
                            })
                            .catch(e => { throw e; });
                    }
                    else {
                        // ziel ist im gleichen Bereich.
                        this.highlightFieldSubject.next(result);
                    }
                }
            });
    }
}
