import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IDocumentModel } from '../../models';
import { BaseApiController } from '../base/base-api.controller';

/**
 * Controller welche alle Funkionalitäten der Document Controller API bereithält
 */
@Injectable()
export class DocumentController extends BaseApiController<IDocumentModel> {

    protected override baseUrl = '/document';

    //#region --- Get ---

    /**
     * Gibt alle Haushalte für die Finanzierung mit der übergebenen Id zurück.
     * 
     * @param {string} id id der Finanzierung
     * @returns {IDocumentModel[]} alle Haushalte der Finanzierung
     */
    public getDocumentsByFinancing(id: string): Observable<IDocumentModel[]> {
        return this.get<IDocumentModel[]>(`${this.baseUrl}/GetDocumentsByFinancing/${id}`);
    }

    //#endregion

    //#region --- CRUD ---

    //#endregion
}
