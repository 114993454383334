<!-- source: https://loading.io/css/ -->
<div  class="lds-spinner">
    <div [style.scale]="scaleValue"></div>
    <div [style.scale]="scaleValue"></div>
    <div [style.scale]="scaleValue"></div>
    <div [style.scale]="scaleValue"></div>
    <div [style.scale]="scaleValue"></div>
    <div [style.scale]="scaleValue"></div>
    <div [style.scale]="scaleValue"></div>
    <div [style.scale]="scaleValue"></div>
    <div [style.scale]="scaleValue"></div>
    <div [style.scale]="scaleValue"></div>
    <div [style.scale]="scaleValue"></div>
    <div [style.scale]="scaleValue"></div>
</div>