export enum FinancingMapStatus {
    Open = 0,
    SampleCalculationRequested = 1,
    VpcRejected = 2,
    VpcChangeRequest = 3,
    SampleCalculationExists = 4,
    EsisRequested = 5,
    EsisExists = 6,
    LoanAgreementOrdered = 7,
    VpcCompleted = 8,
    VpcCanceled = 9,
    VpcRejectedGkFb = 10,
    VpcRejectedPrivate = 11,
    HouseholdCalculationRequested = 12,
    HouseholdCalculationExist = 13,
    HouseholdCalculationUpdateAvailable = 14,

}
