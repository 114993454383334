
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timeout } from 'rxjs';

import { IUserModel } from '../../models';
import { ILoginRequest, IRegisterRequest } from '../../requests';
import { ILoginResponse } from '../../responses';
import { BaseController } from '../base/base.controller';

/**
 * Controller welcher alle Funktionalitäten der AccountControllers API bereitsstellt
 */
@Injectable()
export class AccountController extends BaseController {

    private baseUrl = '/user';

    /**
     * Öffnet die Nutzerverwaltung in einem neuen Fenster
     * (Redirection zum Backend)
     * 
     */
        public static openUserManagement(): void {
            window.open(`${BaseController.backendUrl}/UserManagement/index`, '_blank');
        }

    /**
     * Gibt den angemeldeten User zurück
     * 
     * @returns {IUserModel} angeforderte Entität
     */
    public getEntity(): Observable<IUserModel> {
        return this.get(this.baseUrl);
    }

    /**
     * Führt einen Login aus
     * 
     * @param {ILoginRequest} payload request mit login daten
     * @returns {Observable} antwort Observable
     */
    public login(payload: ILoginRequest): Observable<ILoginResponse> {
        return this.httpClient.post<ILoginResponse>(`${BaseController.backendUrl}/authorization/login`, payload);
    }

    /**
     * Führt eine Registrierung aus
     * 
     * @param {IRegisterRequest} payload request mit registrierungsdaten
     * @returns {Observable} antwort Observable
     */
    public register(payload: IRegisterRequest): Observable<void> {
        return this.httpClient.post<void>(`${BaseController.backendUrl}/authorization/register`, payload);
    }

    /**
     * Updated die BranchId eines Users
     * 
     * @param {string} payload BranchId
     * @returns {Observable} antwort Observable
     */
    public updateBranchId(payload: string): Observable<void> {
        return this.patch(`${this.baseUrl}/updatebranchid/${payload}`);
    }

    /**
     * Pingt Backend ob es verfügbar ist
     * 
     * @returns {Observable} antwort Observable
     */
    public ping(): Observable<HttpResponse<void>> {
        return this.httpClient.get<void>(`${BaseController.backendUrl}/api/ping`, {observe: 'response'})
        .pipe(
            timeout(3000),
        );
    }
}
