import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { CurrencyInputType } from '@ucba/components';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';

/**
 * Komponente, welche eine currency Input Feld mit Checkbox darstellt
 */
@Component({
  selector: 'cxad-checkbox-input-h',
  templateUrl: './checkbox-input-horizontal.component.html',
  styleUrls: ['./checkbox-input-horizontal.component.scss'],
})
export class CheckboxInputHorizontalComponent implements OnInit {

    @Input() public control!: AbstractControl;
    @Input() public checkBoxControl!: AbstractControl;
    @Input() public labelText!: string;
    @Input() public checkBoxText!: string;
    @Input() public comment: string | undefined;
    @Input() public type = CurrencyInputType.CURRENCY;
    @Input() public readonly?: boolean;

    /** ein optionales Ergebnis welches mit angezeigt wird */
    // @Input() public calculation?: string | null;

    /**
     * Frei wählbarer suffix, nur mit typ CurrencyInputType.SUFFIX
     */
    @Input() public suffix?: string;

    public isRequired: boolean | undefined;

    /** default Einstellungen für ein currency input */
    public options: NgxCurrencyConfig = {
        align: 'right',
        allowNegative: false,
        decimal: ',',
        precision: 2,
        prefix: '',
        suffix: ' €',
        thousands: '.',
        allowZero: true,
        nullable: true,
        inputMode: NgxCurrencyInputMode.Natural,
    };

    /**
     * gibt den aktuellen AbstractControl als FormControl zurück
     *
     * @returns {FormControl} control
     */
    public get formControl(): FormControl {
        return this.control as FormControl;
    }


    /**
     * gibt den aktuellen AbstractControl als FormControl zurück
     *
     * @returns {FormControl} control
     */
     public get checkBoxFormControl(): FormControl {
        return this.checkBoxControl as FormControl;
    }

    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {
        switch (this.type) {
            case CurrencyInputType.PERCENT:
                this.options.suffix = ' %';
                this.options.precision = 3;
                break;

            case CurrencyInputType.AREA:
                this.options.suffix = ' m²';
                break;

            case CurrencyInputType.SUFFIX:
                this.options.precision = 0;
                this.options.suffix = this.suffix ?? '';
                break;

            default: break;
        }

        // if (!!this.calculation) {
        //     this.options.align = 'left';
        // }

        // @see https://stackoverflow.com/a/43904237
        const validators = !!this.control && !!this.control.validator ? this.control.validator(new FormControl()) : {};
        this.isRequired = !!validators && ('required' in validators);
    }

}
