import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CodeInputComponent } from 'angular-code-input';
import { environment } from 'apps/cxadvisory/src/environments/environment';

/**
 * Komponente Zwei-Faktor-Authentisierung
 */
@Component({
    selector: 'cxad-passcode',
    templateUrl: './passcode.component.html',
    styleUrls: ['./passcode.component.scss'],
})

export class PasscodeComponent implements OnInit {
    public phoneNumber: string | undefined;
    public errorMessage: string | undefined;
    public isPassCodeSended = false;
    public skipPasscodeCheckboxVisible = !!environment.allowSkipSMSPasscode;
    public skipPasscode = this.skipPasscodeCheckboxVisible;
    public passcode: string | undefined;

    public requestPasscodeTimer = 0;

    @ViewChild('codeInput') private codeInput !: CodeInputComponent;

    @Output() public codeEntered = new EventEmitter<string>();
    @Output() public requestCode = new EventEmitter<boolean>();

    /**
     * Standard Konstuktor
     *
     * @param  { MAT_DIALOG_DATA } data Dialog Data
     */
    public constructor(
        @Inject(MAT_DIALOG_DATA) public data: PasscodeComponent,
    ) {
    }

    /**
     * Angular Hook um Werte zu initialisieren
     */
    public ngOnInit(): void {
        this.phoneNumber = this.data.phoneNumber
    }

    /**
     * Die angezeigte Handynummer ist von Benutzer bestätigt
     */
    public onConfirmPhoneNr(): void {
        if (this.requestPasscodeTimer === 0) {
            this.requestCode.emit(this.skipPasscode);

            this.requestPasscodeTimer = 60;
            const itv = setInterval(() => {
                this.requestPasscodeTimer --;
                if (this.requestPasscodeTimer <= 0) {
                    clearInterval(itv);
                }
            }, 1000);
        }
    }

    /**
     * Kod wird an entry-point abgegeben
     */
    public onCodeCompleted(): void {
        this.codeEntered.emit(this.passcode);
    }
    /**
     * Workaround, mit jedem Eingabewert der Cod ist an passcode variable gespeihert
     *
     * @param  {string} changedCode  Eingabewert
     */
    public onCodeEntered(changedCode: string): void {
        this.passcode = changedCode;
    }


    /**
     * Emit Event an entry-point component, dass neuer Code muss gesendet werden,
     * passcode zurücksetzen,
     * Eingabefelder zurücksetzen
     */
    public onRequestNewCode(): void {
        if (this.requestPasscodeTimer === 0) {
            this.requestCode.emit();
            this.passcode = '';
            this.codeInput.reset();

            this.requestPasscodeTimer = 60;
            const itv = setInterval(() => {
                this.requestPasscodeTimer--;
                if (this.requestPasscodeTimer <= 0) {
                    clearInterval(itv);
                }
            }, 1000);
        }
    }

    /**
     * Prüfung ob der Kunde 6-stelligen Cod eingegeben hat
     *
     * @returns { boolean } ob passcode ok ist
     */
    public isBtnNotDisabled(): boolean {
        return (!this.passcode || this.passcode.length < 6)
    }

}
