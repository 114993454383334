/**
 * Enum welches einen bestimmten Bereich in der Anwendung beschreibt.
 * wird hauptsächlich vom Validierungsdialog genutzt.
 * Wert entspricht der navigationsroute
 */
export enum ApplicationArea {
    FINANCING_MAP = 'map',
    DOCUMENTS = 'doc',
    CALCULATOR = 'calc',
}

/** 
 * Enum welches einen bestimmten unterbereich Bereich beschreibt.
 * Wert entspricht der navigationsroute und der übersetzungsroute
 */
export enum SubArea {
    DEBTOR = 'debtor',
    HOUSEHOLD = 'household',
    FINANCINGPLAN = 'financingplan',
    REALESTATE = 'realestate',
    NOTES = 'notes',
    SUBMISSION = 'submission',
    BANK = 'bank',
}

/** Mögliche Modeltypen, welche in einem Formular annavigiert werden können */
export enum ModelType {
    Financing,
    RealEstate,
    Household,
    Debtor,
    Liability,
    NewLiability,
    Document,
}
