import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { EnumTranslationService } from '@ntag-ef/finprocess-enums';
import { HelperService, IListTuple, ValidationService } from '@ucba/sdk';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { PhoneAreaCode } from '../../enums/phone-area-code.enum';
import { PhoneErrorStateMatcher } from '../../utils/phone-error-state-matcher';

interface IPhoneTuple {
    areaCode: string;
    number: string;
}

/** @see https://angular.io/api/forms/FormControlStatus */
type FormControlStatus = 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED';

/**
 * Komponete, welche eine Telefonnummer Feld darstellt
 */
@Component({
    selector: 'ucba-phone',
    templateUrl: './phone.component.html',
    styleUrls: ['./phone.component.scss'],
})

export class PhoneComponent implements OnInit, OnDestroy {
    /**
     * setzt das interne control und updated das interne phoneForm
     */
    @Input() public set control(ac: AbstractControl | undefined) {
        this.externalControl = ac;

        // Wenn es bereits eine Form gibt, wird diese mit den neuen Werten initialisiert
        if (!!this.phoneForm && !!ac) {
            const initValues = this.parseInitValues(ac.value);
            this.phoneForm.patchValue({
                areaCode: initValues.areaCode,
                number: initValues.number,
            }, { onlySelf: true, emitEvent: false });

            ac.markAllAsTouched = () => {
                this.phoneForm?.markAllAsTouched();
            };
            ac.markAsDirty = () => {
                this.phoneForm?.markAsDirty();
            };
        }
    }

    /**
     * getter für internes Control
     * 
     * @returns {AbstractControl} interes control
     */
    public get control(): AbstractControl | undefined {
        return this.externalControl;
    }

    private externalControl: AbstractControl | undefined;
    @Input() public labelText!: string;
    @Input() public comment: string | undefined;

    public areaCodeList: IListTuple<string>[];
    public isRequired: boolean | undefined;

    public phoneForm: FormGroup | undefined;
    public phoneErrorStateMatcher!: PhoneErrorStateMatcher;

    private defaultSelection: string;
    public controlName: string | undefined;

    /** Notifier wenn View verlassen wird */
    private destroyed$ = new Subject<void>();

    /**
     * Standard Konstruktor
     *
     * @param  {EnumTranslationService} enumTranslate EnumTranslationService injector
     * @param  {UntypedFormBuilder} fb UntypedFormBuilder injector
     */
    public constructor(
        private enumTranslate: EnumTranslationService,
        private fb: UntypedFormBuilder,
    ) {
        this.areaCodeList = Object.values(PhoneAreaCode).map<IListTuple<string>>(t => ({
            value: this.enumTranslate.instant({ type: 'AreaCode', value: t }) as string,
            label: this.enumTranslate.instant({ type: 'AreaCode', value: t }) as string,
        }));

        this.defaultSelection = this.enumTranslate.instant({ type: 'AreaCode', value: PhoneAreaCode.AT }) as string;
    }

    /**
     * gibt den aktuellen AbstractControl als FormControl zurück
     *
     * @returns {FormControl} control
     */
    public get formControl(): FormControl {
        return this.control as FormControl;
    }

    /**
     * gibt den aktuellen AbstractControl des number felds als FormControl zurück
     *
     * @returns {FormControl} control
     */
    public get numberControl(): FormControl {
        return this.phoneForm?.get('number') as FormControl;
    }

    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {

        this.controlName = HelperService.getControlName(this.formControl);

        ValidationService.validatorsChangedObservable$.pipe(
            filter(it => it === this.externalControl),
            takeUntil(this.destroyed$),
        ).subscribe(() => {
            this.updateRequired();
        });

        this.updateRequired();

        const currentValue = !!this.externalControl ? this.externalControl.value : '';
        const initValues = this.parseInitValues(currentValue);

        this.phoneForm = this.fb.group({
            areaCode: [initValues.areaCode, Validators.required],
            number: [initValues.number, Validators.required],
        }, {
            updateOn: 'blur',
        });

        this.phoneForm.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe((value: IPhoneTuple) => {
                if (!!this.phoneForm && !!this.externalControl) {
                    if (!!value.number) {
                        this.externalControl.patchValue(`${value.areaCode}${value.number}`);
                    }
                    else if (!this.externalControl.value) {
                        this.phoneForm?.get('areaCode')?.patchValue(value.areaCode, { onlySelf: true, emitEvent: false });
                    }
                    else {
                        this.externalControl.patchValue(null);
                    }
                    this.externalControl.markAsDirty();
                }
            });


        if (this.externalControl?.disabled) {
            this.phoneForm.disable({ emitEvent: false });
        }

        if (!!this.externalControl) {
            this.phoneErrorStateMatcher = new PhoneErrorStateMatcher(this.externalControl);

            // Update der internen form wenn der wert sich von außen ändert
            this.externalControl.valueChanges
                .pipe(takeUntil(this.destroyed$))
                .subscribe((phoneNr: string) => {
                    const values = this.parseInitValues(phoneNr);
                    const current = this.phoneForm?.value as IPhoneTuple;

                    if (values.areaCode !== current.areaCode || values.number !== current.number) {
                        this.phoneForm?.patchValue(values, { onlySelf: true, emitEvent: false });
                    }
                });

            this.externalControl.statusChanges
                .pipe(takeUntil(this.destroyed$))
                .subscribe((status: FormControlStatus) => {
                    if (status === 'DISABLED' && this.phoneForm?.enabled) {
                        this.phoneForm?.disable({ emitEvent: false, onlySelf: true });
                    }
                    else if (this.phoneForm?.disabled) {
                        this.phoneForm?.enable({ emitEvent: false, onlySelf: true });
                    }
                });

            this.externalControl.markAllAsTouched = () => {
                this.phoneForm?.markAllAsTouched();
            };
            this.externalControl.markAsDirty = () => {
                this.phoneForm?.markAsDirty();
            };
        }
    }

    /**
     * update das isRequired feld anhand der im control rinterlegten validatoren
     */
    private updateRequired() {
        // @see https://stackoverflow.com/a/43904237
        const validators = !!this.externalControl && !!this.externalControl.validator ? this.externalControl.validator(new FormControl()) : {};
        this.isRequired = !!validators && ('required' in validators);
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.destroyed$.next();
    }

    /**
     * Uses glibphone to try and find the country code of the given number
     *
     * @param {string} phoneNumber The phone number
     * @returns {string} der country Code
     */
    private parseInitValues(phoneNumber: string): IPhoneTuple {
        if (!phoneNumber || !phoneNumber.startsWith('+')) {
            return { areaCode: this.defaultSelection, number: phoneNumber };
        }
        else {
            const code = phoneNumber.substr(0, 3);
            if (this.areaCodeList.map(({ value }) => value).some(c => c === code)) {
                return { areaCode: code, number: phoneNumber.substr(3) };
            }
            else {
                return { areaCode: '+', number: phoneNumber.substr(1) };
            }
        }
    }
}
