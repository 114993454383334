import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

/**
 * Komponente, welche eine currency Output Feld mit Checkbox darstellt
 */
@Component({
  selector: 'cxad-checkbox-output',
  templateUrl: './checkbox-output.component.html',
  styleUrls: ['./checkbox-output.component.scss'],
})
export class CheckboxOutputComponent {

    @Input() public value?: number;
    @Input() public checkBoxControl!: AbstractControl;
    @Input() public labelText!: string;
    @Input() public checkBoxText!: string;
    @Input() public comment: string | undefined;

    /**
     * gibt den aktuellen AbstractControl als FormControl zurück
     *
     * @returns {FormControl} control
     */
     public get checkBoxFormControl(): FormControl {
        return this.checkBoxControl as FormControl;
    }
}
