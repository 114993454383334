<mat-dialog-content class="mat-typography">
    <div class="rate-calculation-container">
        <button mat-icon-button class="close-button" [mat-dialog-close]="true" type="button">
            <mat-icon class="close-icon" color="primary">close</mat-icon>
        </button>
        <h1 class="rate-calculation-title">{{'components.calculationDialog.rateCalculator' | translate}}</h1>

        <form *ngIf="form" [formGroup]="form">
            <div class="rate-calculation-slide">
                <div class="label-container">
                    <label class="slider-label">
                        {{'components.calculationDialog.constructionCreditAmount' | translate}}</label>
                </div>
                <ucba-input-currency class="slider-input" [ignoreRequireStar]="true" [control]="form.controls['amount']"
                    labelText="">
                </ucba-input-currency>
                <div *ngIf="asFormControl('amount') as amountCtl">
                    <mat-slider [step]="stepAmount" [min]="minAmount" [max]="maxAmount" [discrete]="true"
                        aria-label="units" #ngSlider>
                        <input matSliderThumb (input)="onChangeCredit(ngSliderThumb.value)"
                            #ngSliderThumb="matSliderThumb" />
                    </mat-slider>
                    <div class="slider-values">
                        <label class="slider-min">{{minAmount | currency:'EUR':'symbol':'1.2-2'}}</label>
                        <label class="slider-max">{{maxAmount | currency:'EUR':'symbol':'1.2-2'}}</label>
                    </div>
                </div>
            </div>
            <div class="rate-calculation-slide">
                <div class="label-container">
                    <label class="slider-label">
                        {{'components.calculationDialog.constructionCreditDebitRate' | translate}}</label>
                </div>
                <ucba-input-currency class="slider-input" [ignoreRequireStar]="true" [type]="currencyInputType.PERCENT"
                    [control]="form.controls['debitRate']" labelText="">
                </ucba-input-currency>
                <div *ngIf="asFormControl('debitRate') as debitCtl">
                    <mat-slider [step]="stepDebitRate" [min]="minDebitRate" [max]="maxDebitRate" [discrete]="true"
                        aria-label="units" #ngSlider>
                        <input matSliderThumb (input)="onChangeDebitRate(ngSliderThumb.value)"
                            #ngSliderThumb="matSliderThumb" />
                    </mat-slider>
                    <div class="slider-values">
                        <label class="slider-min">{{minDebitRate}}%</label>
                        <label class="slider-max">{{maxDebitRate}}%</label>
                    </div>
                </div>
            </div>
            <div class="rate-calculation-slide">
                <div class="label-container">
                    <label class="slider-label">
                        {{'components.calculationDialog.constructionCreditDuration' | translate}}</label>
                </div>
                <ucba-input-currency class="slider-input" [ignoreRequireStar]="true" [type]="currencyInputType.SUFFIX"
                    suffix=" Jahre" [control]="form.controls['duration']" labelText="">
                </ucba-input-currency>
                <div *ngIf="asFormControl('duration') as durationCtl">
                    <mat-slider [step]="stepDuration" [min]="minDuration" [max]="maxDuration" [discrete]="true"
                        aria-label="units" #ngSlider>
                        <input matSliderThumb (input)="onChangeCreditDuration(ngSliderThumb.value)"
                            #ngSliderThumb="matSliderThumb" />
                    </mat-slider>
                    <div class="slider-values">
                        <label class="slider-min">{{minDuration}} {{'global.years' | translate}}</label>
                        <label class="slider-max">{{maxDuration}} {{'global.years' | translate}}</label>
                    </div>
                </div>
            </div>
            <div class="monthly-rate-container">
                <label class="monthly-rate-left">{{'components.calculationDialog.monthlyRate' |
                    translate}}</label>
                <label class="monthly-rate-right">{{monthlyRate | currency:'EUR':'symbol':'1.2-2'}}</label>
            </div>
        </form>
    </div>
</mat-dialog-content>