import { Component, Inject, OnDestroy } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GlobalSettings } from '@ucba/sdk';
import { IListTuple } from '@ucba/sdk/interfaces';
import { IHouseholdModel } from '@ucba/sdk/models';
import { Observable, Subject, takeUntil } from 'rxjs';

/**
 * Dialog zum generieren eines neuen Kreditnehmers
 */
@Component({
    selector: 'cxad-add-debtor',
    templateUrl: './add-debtor.component.html',
    styleUrls: ['./add-debtor.component.scss'],
})
export class AddDebtorComponent implements OnDestroy {

    public form: FormGroup | undefined;
    public households?: IListTuple<string>[];

    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();

    /**
     * standard Konstruktor
     *
     * @param {UntypedFormBuilder} fb UntypedFormBuilder injector
     * @param {TranslateService} translate TranslateService injector
     * @param {MatDialogRef} dialogRef MatDialogRef injector
     * @param {Observable<IHouseholdModel[]>} data MatDialogData injector
     */
    public constructor(
        private fb: UntypedFormBuilder,
        private translate: TranslateService,
        public dialogRef: MatDialogRef<AddDebtorComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Observable<IHouseholdModel[]>,
    ) {
        this.form = this.fb.group({
            firstName: [null, Validators.required],
            lastName: [null, Validators.required],
            household: [null, Validators.required],
            copyAddress: [true],
        });

        if (this.data) {
            this.data.pipe(takeUntil(this.viewLeft$)).subscribe(households => {
                this.households = households.map((household, index) => ({
                    value: household.id,
                    label: `${index + 1}. Haushalt`,
                }));

                if (this.households.length < GlobalSettings.maxHouseholds) {
                    this.households.push({
                        value: 'createhousehold',
                        label: this.translate.instant('components.financingTab.household.addNewHouseholdButton'),
                    });
                }
            });
        }
    }

    /**
     * Angular Hook zum initialisieren
     */
     public ngOnDestroy(): void {
        this.viewLeft$.next();
    }

    /**
     * Dialog abbrechen
     */
    public dismiss(): void {
        this.dialogRef.close();
    }

    /**
     * Formular speichern
     */
    public save(): void {
        if (!!this.form && this.form.valid) {
            this.dialogRef.close(this.form.value);
        }
    }
}
