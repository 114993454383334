import { ComparisonType, DebitRateAdaptionParameter } from '@ntag-ef/finprocess-enums/finadvisory';

import { IModifiedBackend } from '../interfaces';

/**
 * Handles debit rate adaptions.
 */
 export interface IDebitRateAdaptionModel extends IModifiedBackend {

    /** The parameter. */
    parameter: DebitRateAdaptionParameter;

    /** The comparison type. */
    comparison: ComparisonType;

    /** The value. */
    value: number;

    /** The adaption. */
    adaption: number;
}
