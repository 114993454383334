import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { EnumTranslationModule } from '@ntag-ef/finprocess-enums';
import { NgxCurrencyDirective } from 'ngx-currency';
import { FlexLayoutModule } from 'ngx-flexible-layout';

import { BooleanComponent } from './components/boolean/boolean.component';
import { CheckboxInputComponent } from './components/checkbox-input/checkbox-input.component';
import { CheckboxInputHorizontalComponent } from './components/checkbox-input-horizontal/checkbox-input-horizontal.component';
import { CheckboxOutputComponent } from './components/checkbox-output/checkbox-output.component';
import { CheckboxOutputHorizontalComponent } from './components/checkbox-output-horizontal/checkbox-output-horizontal.component';
import { DateComponent } from './components/date/date.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormFieldErrorComponent } from './components/form-field-error/form-field-error.component';
import { InputComponent } from './components/input/input.component';
import { InputCurrencyComponent } from './components/input-currency/input-currency.component';
import { InputCurrencyHorizontalComponent } from './components/input-currency-horizontal/input-currency-horizontal.component';
import { MultiInputComponent } from './components/multi-input/multi-input.component';
import { MultiInputHorizontalComponent } from './components/multi-input-horizontal/multi-input-horizontal.component';
import { OutputComponent } from './components/output/output.component';
import { OutputCurrencyComponent } from './components/output-currency/output-currency.component';
import { OutputCurrencyHorizontalComponent } from './components/output-currency-horizontal/output-currency-horizontal.component';
import { PhoneComponent } from './components/phone/phone.component';
import { RadioComponent } from './components/radio/radio.component';
import { ReadOnlyComponent } from './components/read-only/read-only.component';
import { SelectComponent } from './components/select/select.component';
import { SelectDocumentComponent } from './components/select-document/select-document.component';
import { SelectFlatComponent } from './components/select-flat/select-flat.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TextareaComponent } from './components/textarea/textarea.component';

/**
 * Shared Select- Komponente
 */
@NgModule({
    declarations: [
        FooterComponent,
        InputComponent,
        InputCurrencyComponent,
        FormFieldErrorComponent,
        TextareaComponent,
        BooleanComponent,
        RadioComponent,
        SelectComponent,
        DateComponent,
        PhoneComponent,
        OutputComponent,
        OutputCurrencyComponent,
        MultiInputComponent,
        CheckboxInputComponent,
        CheckboxOutputComponent,
        InputCurrencyHorizontalComponent,
        OutputCurrencyHorizontalComponent,
        MultiInputHorizontalComponent,
        CheckboxOutputHorizontalComponent,
        CheckboxInputHorizontalComponent,
        SelectDocumentComponent,
        ReadOnlyComponent,
        SelectFlatComponent,
        SpinnerComponent,
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatSidenavModule,
        MatListModule,
        MatTooltipModule,
        TranslateModule,
        MatInputModule,
        MatFormFieldModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        MatTooltipModule,
        NgxCurrencyDirective,
        MatRadioModule,
        MatSelectModule,
        MatCheckboxModule,
        MatDatepickerModule,
        EnumTranslationModule.forChild(),
    ],
    exports: [
        FooterComponent,
        InputComponent,
        InputCurrencyComponent,
        FormFieldErrorComponent,
        TextareaComponent,
        BooleanComponent,
        RadioComponent,
        SelectComponent,
        DateComponent,
        PhoneComponent,
        OutputComponent,
        OutputCurrencyComponent,
        MultiInputComponent,
        CheckboxInputComponent,
        CheckboxOutputComponent,
        InputCurrencyHorizontalComponent,
        OutputCurrencyHorizontalComponent,
        MultiInputHorizontalComponent,
        CheckboxOutputHorizontalComponent,
        CheckboxInputHorizontalComponent,
        SelectDocumentComponent,
        ReadOnlyComponent,
        SelectFlatComponent,
        SpinnerComponent,
    ],
})
export class SharedModule { }
