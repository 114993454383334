<waiter-component [isLoading]="(chatMessagesGroup$ | async) === null" class="area-wrapper area-height">
    <!-- header -->
    <div class="header-container align-center" fxLayoutAlign="space-between start">
        <div class="header-left">
            <h2 class="page-title">{{ 'components.chat.chatWith' | translate }}</h2>
            <p>{{ 'components.chat.answer' | translate }}</p>
        </div>
        <div class="header-right">
            <button *ngIf="!isDownloadLoading" mat-button (click)="downloadChatHistory()"
                [disabled]="!isDownloadBtnActive">
                <mat-icon class="download-icon">file_download</mat-icon>
            </button>
            <cxad-spinner *ngIf="isDownloadLoading" class="download-spinner" scaleValue="0.6"></cxad-spinner>
        </div>
    </div>

    <!-- body -->
    <div class="msg-container align-center" #scrollContrainer>

        <div *ngFor="let msg of (chatMessagesGroup$ | async); let last = last" class="msg"
            [ngClass]="msg.from === systemType.Cxadvisory ? 'sent-msg' : 'recived-msg'">
            <div class="msg-header" *ngIf="!!msg.from">
                <span>{{msg.displayName}}</span>
                <button matTooltip="{{ 'components.chat.deleteMsg' | translate }}" mat-icon-button
                    *ngIf="isFinancingMapOpen && !msg.markedDeleted && msg.from === systemType.Cxadvisory"
                    (click)="markDeleted(msg.id!)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
            <span [class.msg-deleted]="msg.markedDeleted" [innerHTML]="msg.text"></span>
            <span class="msg-date">{{msg.created | date:'dd.MM.yyyy, HH:mm'}}</span>
        </div>

    </div>

    <!-- footer -->
    <div class="align-center">
        <form *ngIf="!!chatForm && !isChatOnlyReadable" [formGroup]="chatForm" #form="ngForm">
            <mat-form-field appearance="fill">
                <textarea placeholder="{{ 'components.chat.placeholder' | translate }}" class="input-set scroll"
                    rows="3" matInput formControlName="txArea" maxlength="2500"></textarea>
                <button *ngIf="!isSendLoading" matSuffix mat-button class="send-btn" (click)="onSend()"
                    [disabled]="!chatForm.controls['txArea'].value?.trim()">
                    <mat-icon class="send-icon">send</mat-icon>
                </button>
                <cxad-spinner *ngIf="isSendLoading" class="send-spinner" scaleValue="0.6"></cxad-spinner>
                <span class="remaining-chars">
                    {{ 'components.chat.remainingChars' | translate:{'count' :
                    2500-chatForm.controls['txArea'].value?.length} }}
                </span>
            </mat-form-field>
        </form>
    </div>
</waiter-component>