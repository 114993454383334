import { ISODate } from '../types';

import { IBackendBaseModel } from './backend-base.model';

/**
 * Handles liabilities of a household.
 */
export interface INewLiabilityModel extends IBackendBaseModel {

    /** The household identifier. */
    householdId: string;

    /** The name of the creditor. */
    creditorName?: string;

    /** The amount. */
    amount: number;

    /** The loan period in months. */
    loanPeriodInMonths?: number;

    /** The start date. */
    starts?: ISODate;

    /** The monthly rate. */
    monthlyRate?: number;

    /** Whether the new liability is secured by land register. */
    securedByLandRegister?: boolean;
    /** Id of the RealEstate wich secured this new liability */
    securedRealEstateId?: string;
}
