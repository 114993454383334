export enum ClaimType {
    Actor = 'http://schemas.xmlsoap.org/ws/2009/09/identity/claims/actor',
    PostalCode = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/postalcode',
    PrimaryGroupSid = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/primarygroupsid',
    PrimarySid = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/primarysid',
    Role = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
    Rsa = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/rsa',
    SerialNumber = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/serialnumber',
    Sid = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid',
    Spn = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/spn',
    StateOrProvince = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/stateorprovince',
    StreetAddress = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/streetaddress',
    Surname = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname',
    System = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/system',
    Thumbprint = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/thumbprint',
    Upn = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/upn',
    Uri = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/uri',
    UserData = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata',
    Version = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/version',
    Webpage = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/webpage',
    WindowsAccountName = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/windowsaccountname',
    WindowsDeviceClaim = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/windowsdeviceclaim',
    WindowsDeviceGroup = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/windowsdevicegroup',
    WindowsFqbnVersion = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/windowsfqbnversion',
    WindowsSubAuthority = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/windowssubauthority',
    OtherPhone = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/otherphone',
    NameIdentifier = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
    Name = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
    MobilePhone = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/mobilephone',
    Anonymous = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/anonymous',
    Authentication = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication',
    AuthenticationInstant = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/authenticationinstant',
    AuthenticationMethod = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/authenticationmethod',
    AuthorizationDecision = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authorizationdecision',
    CookiePath = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/cookiepath',
    Country = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country',
    DateOfBirth = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/dateofbirth',
    DenyOnlyPrimaryGroupSid = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/denyonlyprimarygroupsid',
    DenyOnlyPrimarySid = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/denyonlyprimarysid',
    DenyOnlySid = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/denyonlysid',
    WindowsUserClaim = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/windowsuserclaim',
    DenyOnlyWindowsDeviceGroup = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/denyonlywindowsdevicegroup',
    Dsa = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/dsa',
    Email = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
    Expiration = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/expiration',
    Expired = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/expired',
    Gender = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/gender',
    GivenName = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
    GroupSid = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/groupsid',
    Hash = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/hash',
    HomePhone = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/homephone',
    IsPersistent = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/ispersistent',
    Locality = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/locality',
    Dns = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/dns',
    X500DistinguishedName = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/x500distinguishedname',
}
