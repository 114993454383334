import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { RateCalculationDialogComponent } from '@ucba/sdk/components';
import { FinancingMapStatus, RoutingParams } from '@ucba/sdk/enums';
import { IRateCalculationData } from '@ucba/sdk/interfaces';
import { IFinancingMapLightModel } from '@ucba/sdk/models';
import { DataService, FileService, HelperService } from '@ucba/sdk/services';
import { CustomerState, FinancingMapState, MasterDataState } from '@ucba/sdk/statemanagement/states';
import { sort } from 'fast-sort';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

import { CardModel, FinancingMapLightModelWithMessageCount } from '../../types';

/**
 * Komponente für die Selbstauskunft
 */
@Component({
    selector: 'cxad-self-disclosure',
    templateUrl: './self-disclosure.component.html',
    styleUrls: ['./self-disclosure.component.scss'],
})
export class SelfDisclosureComponent implements OnInit {

    public financings$: Observable<CardModel[]> | null = null;

    /**
     * Standard Konstruktor
     *
     * @param {Router} router Router injector
     * @param {TranslateService} translate TranslateService injector
     * @param {Store} store Store injector
     * @param {DataService} dataService DataService injector
     * @param {FileService} fileService FileService injector
     * @param {MatDialog} dialog Dialog injector
     */
    public constructor(
        private router: Router,
        private translate: TranslateService,
        private store: Store,
        private dataService: DataService,
        private fileService: FileService,
        private dialog: MatDialog,
    ) { }

    /**
     * Angular Hook um Werte zu initialisieren
     */
    public ngOnInit(): void {
        this.financings$ = this.dataService.financingObservable$
            .pipe(
                filter(financings => Array.isArray(financings)),
                map(financings => financings as IFinancingMapLightModel[]),// durch den vorangegangen filter sollte dies gehen
                map(financings => financings.map<FinancingMapLightModelWithMessageCount>(fm => ({ ...fm, messageCount: fm.unreadMessageCount ?? 0 }))),
                map(financings => sort(financings).desc(({ created }) => created)),
                map(financings => financings.map<CardModel>(financing => ({
                    financing,
                    buttons: [
                        {
                            icon: 'file_download',
                            tooltip: this.translate.instant('components.welcome.buttons.download'),
                            onClick: (financingMapId: string) => {
                                this.fileService.showFinancingMapFile(
                                    financingMapId,
                                    this.store.selectSnapshot(CustomerState.currentName),
                                ).catch(e => { throw e; });
                            },
                        },
                        {
                            icon: 'email',
                            tooltip: this.translate.instant('components.welcome.buttons.messages'),
                            badge: financing.messageCount,
                            onClick: async (id: string) => {
                                await this.router.navigateByUrl(`${RoutingParams.FINANCING_MODULE}/${id}/news`)
                            },
                        },
                    ],
                }))),
                shareReplay({ bufferSize: 1, refCount: true }),
            );
    }

    /**
     * Öffnet die Finanzierungsmappe
     *
     * @param {string} id Finanzierungs Id
     * @returns {Promise} routing result Promise
     */
    public openFinancing(id: string): Promise<boolean> {
        const fm = this.dataService.getFinancing(id);

        if (!!fm && HelperService.hasValue(fm.status) && fm.status > FinancingMapStatus.Open) {
            return this.router.navigateByUrl(`${RoutingParams.FINANCING_MODULE}/${id}/bankdocuments`)
        }
        else {
            return this.router.navigateByUrl(`${RoutingParams.FINANCING_MODULE}/${id}`)
        }
    }

    /**
     * open the rate Calculator
     */
    public openCalculator(): void {
        const brutto = this.store.selectSnapshot(FinancingMapState.longFinancingRequirementBrutto);
        const fm = this.store.selectSnapshot(FinancingMapState.current);
        const referenceInterest = this.store.selectSnapshot(FinancingMapState.referenceInterest);
        const cfg = this.store.selectSnapshot(MasterDataState.determineFinancingConfiguration)();

        const dialogConfig: MatDialogConfig = {
            autoFocus: false,
            data: {
                amount: brutto > 10000 ? brutto : undefined,
                debitRate: HelperService.hasValue(fm?.requestedDebitRate) ? fm?.requestedDebitRate : referenceInterest > 0 ? referenceInterest : undefined,
                duration: !!fm && HelperService.hasValue(fm?.assumedDuration) ? fm.assumedDuration / 12 : undefined,
                calculationVersion: fm?.calculationVersion,
                bankAccountFee: cfg?.bankAccountFee,
            } as IRateCalculationData,
        }

        this.dialog.open(RateCalculationDialogComponent, dialogConfig);
    }
}
