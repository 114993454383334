<div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between stretch">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <mat-label>{{ labelText }}</mat-label>
            <mat-hint *ngIf="!!comment">{{ comment }}</mat-hint>
        </div>
        <ng-content></ng-content>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between">

        <mat-checkbox fxLayout="row" fxLayoutAlign="space-between center"  *ngIf="!!checkBoxControl" [formControl]="checkBoxFormControl">
            {{ checkBoxText }}
        </mat-checkbox>

        <mat-form-field appearance="outline">
            <input class="calculation" matInput [value]="value !== undefined ? (value | currency:'EUR':'symbol':'1.2-2') : ''" readonly/>
        </mat-form-field>
    </div>
</div>
<hr fxHide fxShow.gt-sm>
