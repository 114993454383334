import { IBackendBaseModel } from './backend-base.model';

/**
 * Daten des UserModels
 */
export interface IUserModel extends IBackendBaseModel {

    enabled?: boolean;

    /** Vorname */
    firstName?: string;

    /** Nachname */
    lastName?: string;

    /**
     * Der Anzeige Name. wird vom Backend gesetzt "{FirstName} {LastName}"
     *
     * @readonly
     */
    displayName?: string;

    /** Email Adresse */
    email?: string;

    /** Telefonnummer */
    phoneNumber?: string;

    /** Id der Filiale */
    branchId?: string;

    /** Ids der Filiale welche vom Nutzer vertreten werden */
    branches?: string[];

    /**letzte gelesene Release Version */
    lastReadReleaseVersion?: string
}
