import { ISODate } from '../types';

import { IBackendBaseModel } from './backend-base.model';

/**
 * File Daten
 */
export interface IFileModel extends IBackendBaseModel {
      
    /** Der Document Identifikator. */
    documentId: string;
  
    /** Der Name. */
    name: string;

    /** Die Erweiterung mit führendem Punkt. (Beispiel: ".jpg")  */
    extension?: string;

    /** Der MIME-Type des Files. */
    mimeType: string;

    /** Die Größe der Datei, in Bytes. */
    length: number;

    /** Das Sortiergewicht. */
    sortWeight: number;

    /** true, wenn diese Instanz signiert ist; andernfalls false. */
    isSigned?: boolean;

    /** Das Datum beim Senden an VPC. */
    sentToVPC?: ISODate;
}
