/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable class-methods-use-this */

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DocumentType } from '@ntag-ef/finprocess-enums/finadvisory';

import { ISignatureModel } from '../../models';
import { Dispose, UnLoadCustomer, UnLoadFinancing } from '../actions';

import { SdkAddSignature, SdkDeleteSignature, SdkDeleteSignatures, SdkDeleteSignaturesByFinancingMap, SdkPatchSignature, SdkUpdateSignature } from './signature.actions';

export interface ISignatureStateModel {
    data: ISignatureModel[];
}


/**
 * Klasse des Signature States
 */
@State<ISignatureStateModel>({
    name: SignatureState.stateName,
    defaults: SignatureState.defaultData,
})
@Injectable()
export class SignatureState {
    public static readonly stateName = 'signatureState';
    private static readonly defaultData: ISignatureStateModel = {
        data: [],
    };

    /**
     * Aktualisiert die aktuelle Signature
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchSignature} action SdkPatchSignature Action
     */
    @Action(SdkPatchSignature)
    public patchSignature(
        { patchState }: StateContext<ISignatureStateModel>,
        { payload }: SdkPatchSignature,
    ): void {
        patchState({
            data: payload,
        });
    }

    /**
     * Aktualisiert ein spezifisches Signature
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkUpdateSignature} action SdkUpdateSignature Action
     */
    @Action(SdkUpdateSignature)
    public updateSignature(
        { patchState, getState }: StateContext<ISignatureStateModel>,
        { payload }: SdkUpdateSignature,
    ): void {
        const current = getState().data;

        if (current.some(({ id }) => id === payload.id)) {
            patchState({
                data: current.filter(({ id }) => id !== payload.id).concat(payload),
            });
        }
    }

    /**
     * fügt eine neue Signature hinzu
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkAddSignature} action SdkAddSignature Action
     */
    @Action(SdkAddSignature)
    public addSignature(
        { patchState, getState }: StateContext<ISignatureStateModel>,
        { payload }: SdkAddSignature,
    ): void {
        const current = getState().data;

        if (!current.some(({ id }) => id === payload.id)) {
            patchState({
                data: current.concat(payload),
            });
        }
    }

    /**
     * entfernt ein spezifisches Signature
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkDeleteSignature} action SdkDeleteSignature Action
     */
    @Action(SdkDeleteSignature)
    public deleteSignature(
        { patchState, getState }: StateContext<ISignatureStateModel>,
        { id: signatureId }: SdkDeleteSignature,
    ): void {
        const current = getState().data;

        if (current.some(({ id }) => id === signatureId)) {
            patchState({
                data: current.filter(({ id }) => id !== signatureId),
            });
        }
    }

    /**
     * entfernt ein spezifisches Signature
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkDeleteSignaturesByFinancingMap} action SdkDeleteSignaturesByFinancingMap Action
     */
    @Action(SdkDeleteSignaturesByFinancingMap)
    public deleteSignaturesByFinancingMap(
        { patchState, getState }: StateContext<ISignatureStateModel>,
        { financingMapId: fmId, type: signType }: SdkDeleteSignaturesByFinancingMap,
    ): void {
        const current = getState().data;

        if (current.some(({ financingMapId }) => financingMapId === fmId)) {
            patchState({
                data: current.filter(({ financingMapId, type }) => financingMapId !== fmId || (signType !== undefined && type !== signType)),
            });
        }
    }

    /**
     * entfernt ein spezifisches Signature
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkDeleteSignatures} action SdkDeleteSignatures Action
     */
    @Action(SdkDeleteSignatures)
    public deleteSignatures(
        { patchState, getState }: StateContext<ISignatureStateModel>,
        { ids }: SdkDeleteSignatures,
    ): void {
        const current = getState().data;

        if (current.some(({ id }) => ids.includes(id))) {
            patchState({
                data: current.filter(({ id }) => !ids.includes(id)),
            });
        }
    }

    /**
     * zurücksetzen des States
     * 
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action([Dispose, UnLoadFinancing, UnLoadCustomer])
    public dispose({ setState }: StateContext<ISignatureStateModel>): void {
        setState({ ...SignatureState.defaultData });
    }

    /**
     * gibt alle aktuell selectierten Signature zurück
     * 
     * @param {ISignatureStateModel} state aktueller State
     * @returns {ISignatureModel} die aktuell selektierte Signature
     */
    @Selector()
    public static current(state: ISignatureStateModel): ISignatureModel[] {
        return state?.data ?? [];
    }

    /**
     * gibt alle Unterschriften für einen bestimmten Finanzierungsplan zurück
     * 
     * @param {ISignatureStateModel} state aktueller State
     * @returns {ISignatureModel[]} callback
     */
    @Selector()
    public static byFinancingMapId(state: ISignatureStateModel): (financingMapId: string) => ISignatureModel[] {
        /**
         * Callback funktion
         * 
         * @param {string} financingMapId id des Finanzierungsplan
         * @returns {ISignatureModel[]} gefilterte Signature
         */
        return (financingMapId: string) => (state.data as ISignatureModel[]).filter(s => s.financingMapId === financingMapId);
    }

    /**
     * gibt alle Unterschriften für bestimmte Kreditnehmer zurück
     * 
     * @param {ISignatureStateModel} state aktueller State
     * @returns {ISignatureModel[]} callback
     */
    @Selector()
    public static byDebtorIds(state: ISignatureStateModel): (debtorIds: string[]) => ISignatureModel[] {
        /**
         * Callback funktion
         * 
         * @param {string} debtorIds ids der Kreditnehmers
         * @returns {ISignatureModel[]} gefilterte Signature
         */
        return (debtorIds: string[]): ISignatureModel[] => (state.data as ISignatureModel[]).filter(s => !!s.debtorId && debtorIds.includes(s.debtorId));
    }

    /**
     * gibt alle alle Unterschriften für eine bestimmte Finanzierungsplan und einen bestimmten Typ
     * 
     * @param {ISignatureStateModel} state aktueller State
     * @returns {ISignatureModel[]} callback
     */
    @Selector()
    public static financingSignaturesByType(state: ISignatureStateModel): (financingMapId: string, types: DocumentType[]) => ISignatureModel[] {
        /**
         * Callback funktion
         * 
         * @param {string} financingMapId id des Finanzierungsplan
         * @param {DocumentType} types DocumentType
         * @returns {ISignatureModel[]} gefilterte Signature
         */
        return (financingMapId: string, types: DocumentType[]) => (state.data as ISignatureModel[]).filter(s =>
            s.financingMapId === financingMapId && !!s.type && types.includes(s.type));
    }
}
