import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ITaskModel } from '../../models';
import { BaseApiController } from '../base/base-api.controller';

/**
 * Controller welche alle Funkionalitäten der Task Controller API bereithält
 */
@Injectable()
export class TaskController extends BaseApiController<ITaskModel> {

    protected override baseUrl = '/task';

    //#region --- Get ---

    /**
     * Gibt ein Array aus allen für den Nutzer sichbaren Entitäten
     * 
     * @returns {any} angeforderte Entität
     */
    public getEntities(): Observable<ITaskModel[]> {
        return this.get(this.baseUrl);
    }

    //#endregion

    //#region --- CRUD ---

    //#endregion
}
