/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable class-methods-use-this */

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ICustomerModel } from '../../models';
import { Dispose, UnLoadCustomer } from '../actions';

import { SdkPatchCustomer } from './customer.actions';

export interface ICustomerStateModel {
    data: ICustomerModel | null;
}

/**
 * Klasse des Kunden States
 */
@State<ICustomerStateModel>({
    name: CustomerState.stateName,
    defaults: CustomerState.defaultData,
})
@Injectable()
export class CustomerState {
    public static readonly stateName = 'customerState';
    private static readonly defaultData: ICustomerStateModel = {
        data: null,
    };

    /**
     * Aktualisiert den aktuellen Kunden
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchCustomer} action SdkPatchCustomer Action
     */
    @Action(SdkPatchCustomer)
    public patchCustomer(
        { patchState }: StateContext<ICustomerStateModel>,
        { payload }: SdkPatchCustomer,
    ): void {
        patchState({
            data: payload,
        });
    }

    /**
     * zurücksetzen des States
     * 
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action([Dispose, UnLoadCustomer])
    public dispose({ setState }: StateContext<ICustomerStateModel>): void {
        setState({ ...CustomerState.defaultData });
    }

    /**
     * gibt aktuell selectierten Kunden zurück
     * 
     * @param {ICustomerStateModel} state aktueller State
     * @returns {ICustomerModel} der aktuell selektierte Kunde
     */
    @Selector()
    public static current(state: ICustomerStateModel): ICustomerModel | null {
        return state?.data ?? null;
    }

    
    /**
     * gibt den Namen selectierten Kunden zurück
     * 
     * @param {ICustomerStateModel} state aktueller State
     * @returns {string} der Name des aktuell selektierten Kunde
     */
     @Selector()
     public static currentName(state: ICustomerStateModel): string {
         const customer = CustomerState.current(state);
         return !!customer ? `${customer.firstName} ${customer.lastName}` : '';
     }

    /**
     * gibt die Id selectierten Kunden zurück
     * 
     * @param {ICustomerStateModel} state aktueller State
     * @returns {ICustomerModel} der aktuell selektierte Kunde
     */
    @Selector()
    public static currentId(state: ICustomerStateModel): string {
        const customer = CustomerState.current(state);
        return !!customer ? customer.id : '';
    }
}
