import {
    // BANKACCOUNT_FEE,
    COMFORTCREDIT_PLUS_ASSUMEDDURATION,
    // LAND_REGISTER_EXTRACT,
    // LAND_REGISTER_REQUEST,
} from '@ntag-ef/finprocess-calculations';
import { Collateralization, Language, ObjectPurposeType } from '@ntag-ef/finprocess-enums';

import { FinancingMapStatus } from '../../enums';
import { ICustomerModel, IDebtorModel, IFinancingMapModel, IHouseholdModel, IRealEstateModel } from '../../models';
import { IBackendBaseModel } from '../../models/backend-base.model';
import { HelperService } from '../helper/helper.service';

/**
 * Factory um alle Backend Models mit default werten zu erstellen
 */
export class ModelFactoryService {

    // public static readonly landRegistryRequest = 47 //LAND_REGISTER_REQUEST; // default 47
    // public static readonly landRegistryExcerpt = 15 //LAND_REGISTER_EXTRACT; // default 15;
    public static readonly registrationFeeLienValue = 1.44;
    // public static readonly accountManagementFee = 5.65//BANKACCOUNT_FEE; // default 5.65
    public static readonly globalCurrencyDefault = 0;
    public static readonly globalBooleanDefault = false;
    public static readonly globalRelevantCostsBooleanDefault = true;
    public static readonly notaryFeeDefault = 3;
    public static readonly brokerageFeeDefault = 3.6;
    public static readonly notaryFeeMaximum = 10;
    public static readonly brokerageFeeMaximum = 3.6;
    public static readonly handlingFeeDefault = 2;
    public static readonly valuationFeeDefault = 0;
    public static readonly realEstateTransferTaxFeeDefault = 3.5;
    public static readonly rightOfOwnershipFeeDefault = 1.1;
    public static readonly legalisationFeeMax = 188.06;
    public static readonly creditPlusAmountDuration = COMFORTCREDIT_PLUS_ASSUMEDDURATION; // always 120 @see BAFAPP-1512

    /**
     * erstellt ein FinancingMap Model mit allen default Werten
     *
     * @param {string} customerId Id des Kunden
     * @returns {IFinancingMapModel} FinancingMapModel
     */
    public static createFinancingMapModel(customerId: string): Partial<IFinancingMapModel> {
        return {
            customerId,
            status: FinancingMapStatus.Open,
            notaryFeeInput: ModelFactoryService.notaryFeeDefault,
            notaryFeeIsPercent: true,
            brokerageFeeInput: ModelFactoryService.brokerageFeeDefault,
            brokerageFeeIsPercent: true,
            handlingFeeInput: ModelFactoryService.handlingFeeDefault,
            handlingFeeIsPercent: true,
            valuationFeeInput: ModelFactoryService.valuationFeeDefault,
            valuationFeeIsPercent: true,
            prefinancingBausparCreditBalance: ModelFactoryService.globalBooleanDefault,
            prefinancingFunding: ModelFactoryService.globalBooleanDefault,
            prefinancingInsurance: ModelFactoryService.globalBooleanDefault,
            prefinancingOtherOwnCapital: ModelFactoryService.globalBooleanDefault,
            prefinancingSales: ModelFactoryService.globalBooleanDefault,
            registrationChargesPercent: ModelFactoryService.registrationFeeLienValue,
            calculationVersion: null,
            requestedLanguages: Language.German,
            guarantees: false,
        };
    }

    /**
     * erstellt ein RealEstate Model mit allen default Werten
     *
     * @param {string} financingMapId Id der Finanzierung
     * @returns {IRealEstateModel} RealEstateModel
     */
    public static createRealEstateModel(financingMapId: string): Partial<IRealEstateModel> {
        return {
            financingMapId,
            constructionCostsRelevantForAdditionalCosts: ModelFactoryService.globalBooleanDefault,
            developmentCostsRelevantForAdditionalCosts: ModelFactoryService.globalBooleanDefault,
            lotPriceRelevantForAdditionalCosts: ModelFactoryService.globalBooleanDefault,
            purchasePriceRelevantForAdditionalCosts: ModelFactoryService.globalRelevantCostsBooleanDefault,
            collateralization: Collateralization.NewFinancing,
            objectPurpose: ObjectPurposeType.Owned,
        };
    }

    /**
     * erstellt ein Household Model mit allen default Werten
     *
     * @param {string} financingMapId Id der Finanzierung
     * @returns {IHouseholdModel} HouseholdModel
     */
    public static createHouseholdModel(financingMapId: string): Partial<IHouseholdModel> {
        return {
            financingMapId,
            otherCosts: ModelFactoryService.globalCurrencyDefault,
            insuranceCosts: ModelFactoryService.globalCurrencyDefault,
            livingCosts: ModelFactoryService.globalCurrencyDefault,
            carCosts: ModelFactoryService.globalCurrencyDefault,
        };
    }

    /**
     * erstellt ein Debitor Model mit allen default Werten
     *
     * @param {string} householdId Id des haushaltes
     * @param {ICustomerModel} customer optionaler Kunde aus dem Daten übernommen werden
     * @returns {IDebtorModel} DebtorModel
     */
    public static createDebtorModel(householdId: string, customer?: ICustomerModel | null): Partial<IDebtorModel> {

        return {
            householdId,
            gender: customer?.gender,
            title: customer?.title,
            firstName: customer?.firstName,
            lastName: customer?.lastName,
            phoneNumber: customer?.phoneNumber,
            mobileNumber: customer?.mobileNumber,
            email: customer?.email,
            homeZip: customer?.zip,
            homeCity: customer?.city,
            homeStreet: customer?.street,
            homeStreetNumber: customer?.streetNumber,
            birthday: customer?.birthdate,
            fourteenSalariesPerYear: true,
        };
    }

    /**
     * Füllt fehlende Werte mit den übergebenen defaultwerten auf
     *
     * @param {any} newEntity zu befüllende Entität
     * @param {any} defaultEntity Entität mit defaultwerten
     * @returns {any} aufgefüllte Entität
     */
    public static updateWithDefaultData<T extends IBackendBaseModel>(newEntity: Partial<T>, defaultEntity: Partial<T>): Partial<T> {
        for (const prop in defaultEntity) {
            if (prop in defaultEntity && !HelperService.hasValue(newEntity[prop])) {
                newEntity[prop] = defaultEntity[prop];
            }
        }

        return newEntity;
    }
}
