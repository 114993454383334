import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

/**
 * Komponente zum Anzeigen einer Error Nachricht eines Eingabefeldes
 */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[ucba-form-field-error]',
    templateUrl: './form-field-error.component.html',
    styleUrls: ['./form-field-error.component.scss'],
})
export class FormFieldErrorComponent {
    @Input() public control!: AbstractControl;

    /**
     * standard konstruktor
     * 
     * @param {TranslateService} translate TranslateService injector
     */
    public constructor(private translate: TranslateService) { }

    /**
     * Getter für den ersten error im Control
     * 
     * @returns {string} fehler
     */
    public get error(): string {

        if (!!this.control && !!this.control.errors) {
            const error = Object.keys(this.control.errors)[0];
            return this.translate.instant(`global.ErrorMessages.${error}`, this.control.errors[error]);
        }
        else {
            return '';
        }
    }
}
