<div fxLayout="column" fxLayout.gt-sm="row"  fxLayoutAlign="space-between stretch"  *ngIf="!!control">

    <div fxLayout="column" fxLayoutAlign="start start">
        <mat-label >{{ labelText }}</mat-label>
        <mat-icon *ngIf="!!toolTip" [matTooltip]="toolTip">help</mat-icon>
        <mat-hint *ngIf="!!comment">({{ comment }})</mat-hint>
    </div>

    <div fxLayout="row">
        <mat-form-field [fxFlex]="!!calculation ? 45 : 100" [hideRequiredMarker]="!isRequired" appearance="outline">
            <input *ngIf="isRequired" [options]="options" currencyMask matInput [formControl]="formControl" required />
            <input *ngIf="!isRequired" [options]="options" currencyMask matInput [formControl]="formControl" />
            <mat-error cxad-form-field-error *ngIf="control.invalid" [control]="control"></mat-error>
        </mat-form-field>
        <ng-container *ngIf="!!calculation">
            <div fxFlex="10" fxLayout="row" fxLayoutAlign="center center" class="equal-sign">
                =
            </div>
            <mat-form-field fxFlex="45" appearance="outline">
                <input matInput [value]="calculation" readonly class="calculation">
            </mat-form-field>
        </ng-container>
    </div>
</div>
<hr fxHide fxShow.gt-sm class="hr-set" [class.valid]="control.valid">



