import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface ICanComponentDeactivate {
  canDeactivate: () => boolean | Observable<boolean> | Promise<boolean>;
}

/**
 * Guard zum prüfen, ob eine bestimmte Bedingung in einer Komponente mit canDeactivate erfüllt ist,
 * damit die Route verlassen werden kann
 */
@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuard {

  /**
   * @override
   * @returns {boolean | Observable<boolean> | Promise<boolean>} kann route deaktiviert werden
   */
  // eslint-disable-next-line class-methods-use-this
  public canDeactivate(
    component: ICanComponentDeactivate,
  ): boolean | Observable<boolean> | Promise<boolean> {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
