import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IRealEstateModel } from '../../models';
import { BaseApiController } from '../base/base-api.controller';

/**
 * Controller welche alle Funkionalitäten der RealEstate Controller API bereithält
 */
@Injectable()
export class RealEstateController extends BaseApiController<IRealEstateModel> {

    protected override baseUrl = '/realestate';

    //#region --- Get ---

    /**
     * Gibt alle Objekte für die Finanzierung mit der übergebenen Id zurück.
     * 
     * @param {string} id id der Finanzierung
     * @returns {IRealEstateModel[]} alle Objekte der Finanzierung
     */
    public getRealEstatesByFinancing(id: string): Observable<IRealEstateModel[]> {
        return this.get<IRealEstateModel[]>(`${this.baseUrl}/GetRealEstatesByFinancing/${id}`);
    }

    //#endregion

    //#region --- CRUD ---

    /**
     * updated eine Entität im Backend
     * 
     * @param {any} model zu speichernde Entität
     * @param {boolean} getMarketValue soll der Marktwert mit berechnet werden
     * @returns {any} im backend gespeicherte Entität
     */
    public override updateEntity(model: Partial<IRealEstateModel>, getMarketValue = false): Observable<IRealEstateModel> {
        return this.put<IRealEstateModel>(`${this.baseUrl}/${model.id}?getMarketValue=${getMarketValue}`, model)
    }

    //#endregion
}
