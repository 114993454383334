import { DocumentType } from '@ntag-ef/finprocess-enums/finadvisory';

import { ISignatureModel } from '../../models';

/**
 * Action welche alle Signature updated
 */
export class SdkPatchSignature {
    public static readonly type = '[SDK Signature] PATCH';

    /**
     * Standard Konstruktor
     * 
     * @param {ISignatureModel} payload zu patchende Signature
     */
    public constructor(public payload: ISignatureModel[]) { }
}

/**
 * Action welche ein neues Signature hinzufügt, sollten es bereits existieren wird add ignoriert
 */
export class SdkAddSignature {
    public static readonly type = '[SDK Signature] ADD';

    /**
     * Standard Konstruktor
     * 
     * @param {ISignatureModel} payload neues Signature
     */
    public constructor(public payload: ISignatureModel) { }
}

/**
 * Action welche ein spezifisches Signature updated
 */
export class SdkUpdateSignature {
    public static readonly type = '[SDK Signature] UPDATE';

    /**
     * Standard Konstruktor
     * 
     * @param {ISignatureModel} payload zu patchendes Signature
     */
    public constructor(public payload: ISignatureModel) { }
}

/**
 * Action welche ein Signature entfernt, sollte es nicht existieren wird delete ignoriert
 */
export class SdkDeleteSignature {
    public static readonly type = '[SDK Signature] DELETE';

    /**
     * Standard Konstruktor
     * 
     * @param {string} id id des zu löschenden Signature
     */
    public constructor(public id: string) { }
}

/**
 * Action welche ein Signature entfernt, sollte es nicht existieren wird delete ignoriert
 */
export class SdkDeleteSignatures {
    public static readonly type = '[SDK Signature] DELETE_MULTIPLE';

    /**
     * Standard Konstruktor
     * 
     * @param {string} ids ids der zu löschenden Signaturen
     */
    public constructor(public ids: string[]) { }
}

/**
 * Delete all Signature for financingmap action or specified by type
 */
export class SdkDeleteSignaturesByFinancingMap {
    public static readonly type = '[SDK Signature] DELETE_BY_FINANCING';
    /**
     * Standard Konstruktor
     * 
     * @param {string} financingMapId id der Finanzierung
     * @param {DocumentType} type DocumentType
     */
    public constructor(public financingMapId: string, public type?: DocumentType) { }
}
