import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { GoogleService } from '../../services/google/google.service';
import { HelperService } from '../../services/helper/helper.service';

/**
 * Komponente, mit der ein Login durchgeführt wird
 */
@Component({
    selector: 'ucba-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnDestroy, AfterViewInit {

    /**
     * ViewChild for google map
     */
    @ViewChild('dialogGoogleMap', { static: false })
    public dialogGoogleMap: ElementRef | undefined;
    @Input() public geometry: { lat: number; lng: number; zoom: number; } | undefined;

    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();

    /**
     * Standard Konstruktor
     * 
     * @param  {GoogleService} googleService GoogleService injector
     */
    public constructor(private googleService: GoogleService) { }

    /**
     * Angular Hoock nachdem die View initialisiert wurde.
     */
    public ngAfterViewInit(): void {
        HelperService.isBrowserOnlineObservable$.pipe(
            takeUntil(this.viewLeft$),
        ).subscribe(online => {
            if (online && this.googleService.isLoaded) {
                this.googleService.setMap(this.dialogGoogleMap?.nativeElement);
                this.googleService.initializeMarker(this.geometry);
            }
        })
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.googleService.unsetMap();
        this.viewLeft$.next();
    }
}

