import { CommonModule, CurrencyPipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { EnumTranslationModule } from '@ntag-ef/finprocess-enums';
import { WaiterModule } from '@ntag-ef/waiter';
import { NgxCurrencyDirective } from 'ngx-currency';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { of } from 'rxjs';

import { DocumentViewerComponent, PrivacyStatementComponent, SignatureDialogComponent, TagListDialogComponent, ValidationDialogComponent } from './components';
import { FormFieldErrorComponent } from './components/form-field-error/form-field-error.component';
import { GoogleMapsComponent } from './components/google-maps/google-maps.component';
import { InputCurrencyComponent } from './components/input-currency/input-currency.component';
import { MapComponent } from './components/map/map.component';
import { RateCalculationDialogComponent } from './components/rate-calculation-dialog/rate-calculation-dialog.component';
import { AccountController, ActivityController, CustomerController, DebtorController, DocumentController, FileController, FinancingController, LiabilityController, MailingController, MasterDataController, MessageController, NewLiabilityController, RealEstateController, SignatureController, StatusEntryController, TaskController } from './controller';
import { HouseholdController } from './controller/household/household.controller';
import { ReleaseNotesController } from './controller/release-notes/release-notes.controller';
import { ApplicationType } from './enums';
import { IDataType, ISdkModuleConfig } from './interfaces';
import { ApplicationHub, DataService, FileService, GoogleService, HelperService, HubService, ThirdPartyFactoryService, ValidationService } from './services';
import { APPLICATION_TYPE, BACKEND_URL, DATA_OBSERVABLE } from './utils';

/**
 * Modul, welches alle Funkionalität bereitstellt um Backend Entitäten zu bearbeiten
 */
@NgModule({
    imports: [
        CommonModule,
        NgxsModule.forFeature(),
        RouterModule.forChild([]),
        FlexLayoutModule,
        MatTabsModule,
        MatListModule,
        MatExpansionModule,
        MatBadgeModule,
        MatToolbarModule,
        MatTooltipModule,
        MatIconModule,
        NgxExtendedPdfViewerModule,
        WaiterModule.forRoot(),
        EnumTranslationModule.forRoot(),
        MatButtonModule,
        MatDialogModule,
        TranslateModule.forChild(),
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSliderModule,
        NgxCurrencyDirective,
    ],
    declarations: [
        MapComponent,
        GoogleMapsComponent,
        RateCalculationDialogComponent,
        ValidationDialogComponent,
        TagListDialogComponent,
        DocumentViewerComponent,
        PrivacyStatementComponent,
        SignatureDialogComponent,
        InputCurrencyComponent,
        FormFieldErrorComponent,
    ],
    providers: [
        CurrencyPipe,
        // Services
        FileService,
        GoogleService,
        HelperService,
        ThirdPartyFactoryService,
        HubService,
        ValidationService,
        DataService,
        // Controller
        AccountController,
        ActivityController,
        CustomerController,
        DebtorController,
        DocumentController,
        FileController,
        FinancingController,
        HouseholdController,
        LiabilityController,
        MailingController,
        MasterDataController,
        MessageController,
        NewLiabilityController,
        RealEstateController,
        SignatureController,
        TaskController,
        ReleaseNotesController,
        StatusEntryController,
        // Hubs
        ApplicationHub,
    ],
})
export class SdkModule {

    private static defaultData: IDataType = {
        isLoggendIn: false,
        userId: undefined,
        token: undefined,
    };

    private static initialConfig: ISdkModuleConfig = {
        backendUrl: 'localhost:4200',
        applicationType: ApplicationType.UNKNOWN,
        data$: of(SdkModule.defaultData),
    };

    /**
     * nutze diese Methode in deinem Root-Modul, um den SdkService konfigurieren zu können
     * 
     * @param {ISdkModuleConfig} config optionales Konfigurationsobjekt
     * @returns {ModuleWithProviders<SdkModule>} ModuleWithProviders für root module
     */
    public static forRoot(config: ISdkModuleConfig = { applicationType: ApplicationType.UNKNOWN }): ModuleWithProviders<SdkModule> {
        return {
            ngModule: SdkModule,
            providers: [
                {
                    provide: BACKEND_URL,
                    useValue: config.backendUrl !== undefined ? config.backendUrl : SdkModule.initialConfig.backendUrl,
                },
                {
                    provide: APPLICATION_TYPE,
                    useValue: config.applicationType !== undefined ? config.applicationType : SdkModule.initialConfig.applicationType,
                },
                {
                    provide: DATA_OBSERVABLE,
                    useValue: config.data$ !== undefined ? config.data$ : SdkModule.initialConfig.data$,
                },
            ],
        };
    }


    /**
     * nutze diese Methode in deinen anderen Modulen (nicht Root), um den SdkService konfigurieren zu können
     * 
     * @param {ISdkModuleConfig} config optionales Konfigurationsobjekt
     * @returns {ModuleWithProviders<SdkModule>} ModuleWithProviders für child module
     */
    public static forChild(config?: ISdkModuleConfig | null): ModuleWithProviders<SdkModule> {

        const result: ModuleWithProviders<SdkModule> = {
            ngModule: SdkModule,
            providers: [],
        };

        if (config === undefined || config === null) {
            return result;
        }

        if (config.backendUrl !== undefined) {
            result.providers?.push({ provide: BACKEND_URL, useValue: config.backendUrl });
        }

        if (config.applicationType !== undefined) {
            result.providers?.push({ provide: APPLICATION_TYPE, useValue: config.applicationType });
        }

        if (config.data$ !== undefined) {
            result.providers?.push({ provide: DATA_OBSERVABLE, useValue: config.data$ });
        }

        return result;
    }
}
