import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { FinancingMapStatus } from '@ucba/sdk/enums';
import { IFinancingMapLightModel } from '@ucba/sdk/models';
import { DataService, HelperService } from '@ucba/sdk/services';
import { FinancingMapState } from '@ucba/sdk/statemanagement/states';
import { Subject, combineLatest, filter, map, takeUntil } from 'rxjs';

/**
 * Container Komponente für die Selbstauskunft unter meine Daten
 */
@Component({
    selector: 'cxad-my-data',
    templateUrl: './my-data.component.html',
    styleUrls: ['./my-data.component.scss'],
})
export class MyDataComponent implements OnInit, OnDestroy {

    /**gibt an wie viele Nachrichten bei dieser Finanzierung noch ungelesen sind */
    public unreadMessageCount: number | undefined;

    public financingMapStatus: FinancingMapStatus | undefined;
    public currentFinancingId: FinancingMapStatus | undefined;

    /** is there any file wich must send to VPC */
    public mustSendToVPC = false;

    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();

    /**
     * Standard Konstruktor
     * 
     * @param {DataService} dataService Data service
     * @param {Store} store Store injector
     */
    public constructor(
        private dataService: DataService,
        private store: Store,
    ) { }

    /**
     * Angular Hook um Werte zu initialisieren
     */
    public ngOnInit(): void {
        combineLatest([
            this.store.select(FinancingMapState.currentId),
            this.dataService.financingObservable$,
            this.store.select(FinancingMapState.hasSentFileToVPC),
        ])
            .pipe(
                takeUntil(this.viewLeft$),
                filter(([id, financings]) => !!id && Array.isArray(financings)),
                map<[string | undefined, IFinancingMapLightModel[] | null, boolean], [IFinancingMapLightModel | undefined, boolean]>(
                    ([currentId, financings, hasSentFile]) => [financings?.find(({ id }) => id === currentId), hasSentFile]),
            )
            .subscribe(([financing, hasSentFile]) => {
                if (!!financing) {
                    this.financingMapStatus = financing.status ?? undefined;
                    this.unreadMessageCount = HelperService.hasValue(financing.unreadMessageCount) && financing.unreadMessageCount > 0 ? financing.unreadMessageCount : undefined;
                }
                this.mustSendToVPC = hasSentFile;
            });
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
    }
}
