<mat-dialog-content>
    <form *ngIf="!!form && !!households" [formGroup]="form">
        <h2>{{'components.financingTab.debtor.addDebtorTooltip' | translate}}</h2>
        <ucba-input [control]="form.controls['firstName']"
            [labelText]="'components.financingTab.debtor.firstName' | translate">
        </ucba-input>
        <ucba-input [control]="form.controls['lastName']"
            [labelText]="'components.financingTab.debtor.lastName' | translate">
        </ucba-input>
        <ucba-select [allowNoSelection]="false" [array]="households" [control]="form.controls['household']"
            [customLabel]="'components.financingTab.household.title' | translate">
        </ucba-select>
        <ucba-boolean [control]="form.controls['copyAddress']"
            [labelText]="'components.financingTab.debtor.copyAddress' | translate">
        </ucba-boolean>
    </form>

    <div class="buttons" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-around none">
        <button fxFlex="100" fxFlex.gt-sm="40" class="dialog-button" mat-raised-button
            (click)="dismiss()">Abbrechen</button>
        <button [disabled]="!form || form.invalid" fxFlex="100" fxFlex.gt-sm="40" class="dialog-button"
            mat-raised-button color="primary" (click)="save()">Speichern</button>
    </div>
</mat-dialog-content>