/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable class-methods-use-this */

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Collateralization, CreditPurpose, MarketValueType, ObjectPurposeType, RealEstateType } from '@ntag-ef/finprocess-enums';
import { IRealEstateInput } from '@ntag-ef/finprocess-validation';
import { sort } from 'fast-sort';

import { IFieldCardVisibilityRealEstate } from '../../interfaces';
import { IRealEstateModel } from '../../models';
import { CalculationFactoryService, HelperService, ModelFactoryService } from '../../services';
import { Dispose, UnLoadCustomer, UnLoadFinancing } from '../actions';
import { IMasterDataStateModel, MasterDataState } from '../masterdata/masterdata.state';

import { SdkAddRealEstate, SdkDeleteRealEstate, SdkPatchRealEstate, SdkUpdateRealEstate } from './realestate.actions';

export interface IRealEstateStateModel {
    data: IRealEstateModel[];
}

/**
 * Klasse des Objekt States
 */
@State<IRealEstateStateModel>({
    name: RealEstateState.stateName,
    defaults: RealEstateState.defaultData,
})
@Injectable()
export class RealEstateState {
    public static readonly stateName = 'realEstateState';
    private static readonly defaultData: IRealEstateStateModel = {
        data: [],
    };

    /**
     * Aktualisiert die aktuellen Objekte
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchRealEstate} action SdkPatchRealEstate Action
     */
    @Action(SdkPatchRealEstate)
    public patchRealEstate(
        { patchState }: StateContext<IRealEstateStateModel>,
        { payload }: SdkPatchRealEstate,
    ): void {
        patchState({
            data: payload,
        });
    }

    /**
     * Aktualisiert ein spezifisches Objekte
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkUpdateRealEstate} action SdkUpdateRealEstate Action
     */
    @Action(SdkUpdateRealEstate)
    public updateRealEstate(
        { patchState, getState }: StateContext<IRealEstateStateModel>,
        { payload }: SdkUpdateRealEstate,
    ): void {
        const current = getState().data;

        if (current.some(({ id }) => id === payload.id)) {
            patchState({
                data: current.filter(({ id }) => id !== payload.id).concat(payload),
            });
        }
    }

    /**
     * fügt ein neues Objekte hinzu
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkUpdateRealEstate} action SdkUpdateRealEstate Action
     */
    @Action(SdkAddRealEstate)
    public addRealEstate(
        { patchState, getState }: StateContext<IRealEstateStateModel>,
        { payload }: SdkUpdateRealEstate,
    ): void {
        const current = getState().data;

        if (!current.some(({ id }) => id === payload.id)) {
            patchState({
                data: current.concat(payload),
            });
        }
    }

    /**
     * entfernt ein spezifisches Objekte
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkDeleteRealEstate} action SdkDeleteRealEstate Action
     */
    @Action(SdkDeleteRealEstate)
    public deleteRealEstate(
        { patchState, getState }: StateContext<IRealEstateStateModel>,
        { id: realEstateId }: SdkDeleteRealEstate,
    ): void {
        const current = getState().data;

        if (current.some(({ id }) => id === realEstateId)) {
            patchState({
                data: current.filter(({ id }) => id !== realEstateId),
            });
        }
    }

    /**
     * zurücksetzen des States
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action([Dispose, UnLoadFinancing, UnLoadCustomer])
    public dispose({ setState }: StateContext<IRealEstateStateModel>): void {
        setState({ ...RealEstateState.defaultData });
    }

    /**
     * gibt alle Objekte zurück, welche der aktuellen Finanzierung zugeordnet sind
     *
     * @param {IRealEstateStateModel} state aktueller State
     * @returns {IRealEstateModel} der aktuell selektierte RealEstate
     */
    @Selector()
    public static current(state: IRealEstateStateModel): IRealEstateModel[] {
        // umwandlung in date, da es sonst im ms bereich zu fehlern kommt (Problem beim Kopieren)
        // 2022-03-23T10:33:36.94403Z > 2022-03-23T10:33:36.9440395Z was aber falsch ist
        return sort(state.data).asc(realestate => HelperService.toMicroSeconds(realestate.created));
    }

    /**
     * gibt die Anzahl der Objekte zurück, welche der aktuellen Finanzierung zugeordnet sind
     *
     * @param {IRealEstateStateModel} state aktueller State
     * @returns {number} Anzahl der selektierten RealEstates
     */
    @Selector()
    public static currentObjectsLength(state: IRealEstateStateModel): number {
        return state.data.length;
    }


    /**
     * gibt die Ids aller Objekte zurück, welche der aktuellen Finanzierung zugeordnet sind
     *
     * @param {IRealEstateStateModel} state aktueller State
     * @returns {string[]} Ids aller selektierten RealEstates
     */
    @Selector()
    public static currentIds(state: IRealEstateStateModel): string[] {
        return RealEstateState.current(state).map(({ id }) => id);
    }

    /**
     * gibt ein Objekt mit übergebener Id zurück
     *
     * @param {IRealEstateStateModel} state aktueller State
     * @returns {IRealEstateModel} callbackfunktion
     */
    @Selector()
    public static byId(state: IRealEstateStateModel): (realEstateId: string) => IRealEstateModel | null {

        /**
         * callback funktion um Objekt zu filtern
         * 
         * @param {string} realEstateId id des Objektes
         * @returns {IRealEstateModel} gesuchtes Objekt
         */
        return (realEstateId: string): IRealEstateModel | null => state.data.find(({ id }) => id === realEstateId) ?? null;
    }

    /**
     * gibt das FinancingObject zurück, welche der aktuellen Finanzierung zugeordnet sind
     *
     * @param {IRealEstateStateModel} state aktueller State
     * @returns {IRealEstateModel} der aktuell selektierte RealEstate
     */
    @Selector()
    public static currentFinancingObject(state: IRealEstateStateModel): IRealEstateModel | null {
        return RealEstateState.current(state).find(re => re.objectPurpose === ObjectPurposeType.Finance) ?? null;
    }

    /**
     * gibt die Id des FinancingObject zurück, welche der aktuellen Finanzierung zugeordnet sind
     *
     * @param {IRealEstateStateModel} state aktueller State
     * @returns {string} die aktuelle Id selektierte RealEstates
     */
    @Selector()
    public static currentFinancingObjectId(state: IRealEstateStateModel): string | null {
        return RealEstateState.currentFinancingObject(state)?.id ?? null;
    }

    /**
     * gibt alle GuarantyObjects zurück, welche der aktuellen Finanzierung zugeordnet sind
     *
     * @param {IRealEstateStateModel} state aktueller State
     * @returns {IRealEstateModel[]} der aktuell selektierte RealEstate
     */
    @Selector()
    public static currentGuarantyObjects(state: IRealEstateStateModel): IRealEstateModel[] {
        const guaranteeObjects = state.data.filter(re => re.objectPurpose !== ObjectPurposeType.Finance);
        return sort(guaranteeObjects).asc(realestate => realestate.created);
    }

    /**
     * gibt alle Liegenschaften die zum Verkauf stehen zurück, welche der aktuellen Finanzierung zugeordnet sind
     *
     * @param {IRealEstateStateModel} state aktueller State
     * @returns {{ realEstate: IRealEstateModel, index: number }[]} zum Verkauf stehende Liegenschaften mit ihren ursprünglichen Indizes
     */
    @Selector()
    public static currentForSaleObjects(state: IRealEstateStateModel): { realEstate: IRealEstateModel, index: number }[] {
        return RealEstateState.current(state)
            .map((re, index) => ({ realEstate: re, index }))
            .filter(item => item.realEstate.objectPurpose === ObjectPurposeType.ForSale);
    }

    /**
     * berechnet die Summer der Marktwerte aller Liegenschaften die zum Verkauf stehen
     *
     * @param {IRealEstateStateModel} state aktueller State
     * @returns {number} summe der Marktwerte
     */
    @Selector()
    public static currentSumSalesValue(state: IRealEstateStateModel): number | undefined {
        const forSaleObjects = state.data.filter(re => re.objectPurpose === ObjectPurposeType.ForSale);
        return forSaleObjects.length > 0 ?
            forSaleObjects.reduce((sum, current) => sum + HelperService.getValue(current?.marketValue, 0), 0) :
            undefined;
    }

    /**
     * gibt alle Objekte zurück, welche als Sicherheitsobjekte ausgewählt werden können
     *
     * @param {IRealEstateStateModel} state aktueller State
     * @returns {() => IRealEstateModel} callback funktion
     */
    @Selector()
    public static currentForSecuredObjects(
        state: IRealEstateStateModel,
    ): (forceId?: string) => IRealEstateModel[] {
        // gibt alle Objekte zurück mit Zweck "im Besitz"
        return () => state.data.filter(({ objectPurpose }) => objectPurpose === ObjectPurposeType.Owned);
    }

    /**
     * Gibt das übergebende Finanzierungsobjekt im Format für den Precheck zurück
     *
     * @param  {IRealEstateModel} realestate zu mappendes Model
     * @returns {IRealEstateInput} Finanzierungsobjekt
     */
    // eslint-disable-next-line complexity
    public static asInputModel(realestate: IRealEstateModel): IRealEstateInput {
        return {
            id: realestate.id,
            purchasePrice: realestate.purchasePrice ?? undefined,
            lotPrice: realestate.lotPrice ?? undefined,
            developmentCosts: realestate.developmentCosts ?? undefined,
            constructionCosts: realestate.constructionCosts ?? undefined,
            refurbishmentCosts: realestate.refurbishmentCosts ?? undefined,
            constructionCostsOverrun: realestate.constructionCostsOverrun ?? undefined,
            otherCosts: realestate.otherCosts ?? undefined,
            purchasePriceRelevantForAdditionalCosts: realestate.purchasePriceRelevantForAdditionalCosts ?? undefined,
            lotPriceRelevantForAdditionalCosts: realestate.lotPriceRelevantForAdditionalCosts ?? undefined,
            developmentCostsRelevantForAdditionalCosts: realestate.developmentCostsRelevantForAdditionalCosts ?? undefined,
            constructionCostsRelevantForAdditionalCosts: realestate.constructionCostsRelevantForAdditionalCosts ?? undefined,
            marketValue: realestate.marketValue ?? undefined,
            marketValueType: realestate.marketValueType ?? undefined,
            type: realestate.type ?? undefined,
            creditPurpose: realestate.creditPurpose,
            objectPurpose: realestate.objectPurpose,
            collateralization: realestate.collateralization,
        }
    }

    // /**
    //  * gibt die visibility Map für die aktuelle Liegenschaft zurück
    //  *
    //  * @param {IRealEstateStateModel} state aktueller State
    //  * @returns {IFieldCardVisibilityRealEstate} aktuelle visibility Map
    //  */
    // @Selector()
    // public static currentVisibilityMap(state: IRealEstateStateModel): IFieldCardVisibilityRealEstate {
    //     const re = RealEstateState.currentFinancingObject(state);
    //     return RealEstateState.visibleMap(re);
    // }

    //#region Berechnungen

    /**
     * berechnet direkt die Summe der für die Kaufnebenkosten relevanten Kosten für die übergebene Liegenschaft
     *
     * @param {IRealEstateModel} realEstate die Liegenschaft für die Berechnung
     * @param {number} calculationVersion calculationVersion version der Berechungslogik
     * @returns {number} die Kaufnebenkosten relevanten Kosten
     */
    public static staticPrePurchasingAdditionalCostsSum(realEstate: IRealEstateModel, calculationVersion?: number): number {
        const realEstateCalculationService = CalculationFactoryService.realEstateCalculationService(calculationVersion);

        return realEstateCalculationService.sumPricesRelevantForAdditionalCosts({
            purchasePriceRelevantForAdditionalCosts: HelperService.getValueOrUndefiend(realEstate.purchasePriceRelevantForAdditionalCosts),
            purchasePrice: HelperService.getValueOrUndefiend(realEstate.purchasePrice),
            lotPriceRelevantForAdditionalCosts: HelperService.getValueOrUndefiend(realEstate.lotPriceRelevantForAdditionalCosts),
            lotPrice: HelperService.getValueOrUndefiend(realEstate.lotPrice),
            developmentCostsRelevantForAdditionalCosts: HelperService.getValueOrUndefiend(realEstate.developmentCostsRelevantForAdditionalCosts),
            developmentCosts: HelperService.getValueOrUndefiend(realEstate.developmentCosts),
            constructionCostsRelevantForAdditionalCosts: HelperService.getValueOrUndefiend(realEstate.constructionCostsRelevantForAdditionalCosts),
            constructionCosts: HelperService.getValueOrUndefiend(realEstate.constructionCosts),
        });
    }

    /**
     * berechnet Summe der für die Kaufnebenkosten relevanten Kosten
     *
     * @param {IRealEstateStateModel} state aktueller State
     * @returns {(calculationVersion?: number) => number} callback für berechnung
     */
    @Selector()
    public static prePurchasingAdditionalCostsSum(state: IRealEstateStateModel):
        (calculationVersion?: number) => number {
        /**
         * Callback funktion zum berechnen der Kaufnebenkosten relevanten Kosten
         *
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {number} die Kaufnebenkosten relevanten Kosten
         */
        return (calculationVersion?: number): number => {
            const realEstate = RealEstateState.currentFinancingObject(state);
            return !!realEstate ? RealEstateState.staticPrePurchasingAdditionalCostsSum(realEstate, calculationVersion) : 0;
        };
    }

    /**
     * berechnet das MorixRating übergebenes Objekt
     * 
     * @param {IRealEstateStateModel} state aktueller State 
     * @param {IMasterDataStateModel} masterDataState MasterData State 
     * @returns {() => number} Funktion zum ermitteln des Morix Ratings
     */
    @Selector([MasterDataState])
    public static morixRating(
        state: IRealEstateStateModel,
        masterDataState: IMasterDataStateModel,
    ): (realEstateId: string, calculationVersion?: number) => number | undefined {
        /**
         * Callback funktion zum ermitteln des Morix Ratings
         * 
         * @param {string} realEstateId Id des Objetes
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {number} ermitteltes Morix Rating
         */
        return (realEstateId: string, calculationVersion?: number): number | undefined => {
            const realEstate = RealEstateState.byId(state)(realEstateId);
            const morixRatingAssignments = MasterDataState.morixRatingAssignments(masterDataState);
            const realEstateCalculationService = CalculationFactoryService.realEstateCalculationService(calculationVersion);
            return !!realEstate?.zip ? realEstateCalculationService.getMorixRating({ morixRatingAssignments, zip: realEstate.zip }) : undefined;
        };
    }

    /**
     * gibt alle Objekte mit bei Bedarf ermittelten Morix-Rating
     * 
     * @param {IRealEstateStateModel} state aktueller State 
     * @param {IMasterDataStateModel} masterDataState MasterData State 
     * @returns {() => number} Funktion zum ermitteln des Morix Ratings
     */
    @Selector([MasterDataState])
    public static currentWithMorixRating(
        state: IRealEstateStateModel,
        masterDataState: IMasterDataStateModel,
    ): (calculationVersion?: number) => IRealEstateModel[] {
        /**
         * Callback funktion zum ermitteln des Morix Ratings
         * 
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {number} ermitteltes Morix Rating
         */
        return (calculationVersion?: number): IRealEstateModel[] => RealEstateState.current(state).map(re => {
            if (!!re.zip && !HelperService.hasValue(re.morixRating)) {
                // bei offnen Finanzierungen ist das morixRating nicht gesetzt
                const morixRating = RealEstateState.morixRating(state, masterDataState)(re.id, calculationVersion);
                return {
                    ...re,
                    morixRating,
                };
            }
            else {
                return re;
            }
        });
    }

    /**
     * berechnet Grunderwerbssteuer
     *
     * @param {IRealEstateStateModel} state aktueller State
     * @returns {(calculationVersion?: number) => number} callback für berechnung
     */
    @Selector()
    public static realEstateTransferTax(state: IRealEstateStateModel):
        (calculationVersion?: number) => number {

        /**
         * Callback funktion zum berechnen der Grunderwerbssteuer
         *
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {number} die Grunderwerbssteuer
         */
        return (calculationVersion?: number): number => {
            const prePurchasingAdditionalCostsSum = RealEstateState.prePurchasingAdditionalCostsSum(state)(calculationVersion);
            const realEstateCalculationService = CalculationFactoryService.realEstateCalculationService(calculationVersion);
            return realEstateCalculationService.realEstateTaxes({
                sumPricesRelevantForAdditionalCosts: prePurchasingAdditionalCostsSum,
                realEstateTaxesFee: ModelFactoryService.realEstateTransferTaxFeeDefault,
            });
        }
    }

    /**
     * berechnet Kosten für Eintragung Eigentumsrecht
     *
     * @param {IRealEstateStateModel} state aktueller State
     * @returns {(calculationVersion?: number) => number} callback für berechnung
     */
    @Selector()
    public static rightOfOwnership(state: IRealEstateStateModel):
        (calculationVersion?: number) => number {

        /**
         * Callback funktion zum berechnen der Kosten für Eintragung Eigentumsrecht
         *
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {number} Kosten für Eintragung Eigentumsrecht
         */
        return (calculationVersion?: number): number => {
            const prePurchasingAdditionalCostsSum = RealEstateState.prePurchasingAdditionalCostsSum(state)(calculationVersion);
            const realEstateCalculationService = CalculationFactoryService.realEstateCalculationService(calculationVersion);

            return realEstateCalculationService.landregisterEntry({
                sumPricesRelevantForAdditionalCosts: prePurchasingAdditionalCostsSum,
                landregisterEntryFee: ModelFactoryService.rightOfOwnershipFeeDefault,
            });
        };
    }

    /**
     * ermittelt die Summe der Marktwerte, welche für die LTV Berechnung relevant sind
     *
     * @param {IRealEstateStateModel} state aktueller State
     * @returns {(realEstateId: string, calculationVersion?: number) => number} callback für berechnung
     */
    @Selector()
    public static marketValueSumForLTV(state: IRealEstateStateModel): (calculationVersion?: number) => number {

        /**
         * Callback funktion zum berechnen der Summe der Marktwerte
         *
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {number} Kosten für Eintragung Eigentumsrecht
         */
        return (calculationVersion?: number): number => {
            const filteredRealEstates = state.data.filter(it => it.collateralization === Collateralization.NewFinancing && it.objectPurpose !== ObjectPurposeType.ForSale);
            const realEstateCalculationService = CalculationFactoryService.realEstateCalculationService(calculationVersion);

            return filteredRealEstates.reduce((pV, cR) => {
                const currentMarketValue = (cR.objectPurpose !== ObjectPurposeType.Finance) ?
                    cR.marketValue :
                    realEstateCalculationService.calculatedMarketValue({
                        marketValue: cR.marketValue,
                        purchasePrice: cR.purchasePrice,
                        marketValueType: cR.marketValueType,
                        creditPurpose: cR.creditPurpose,
                        realEstateType: cR.type ?? undefined,
                    });

                const result = pV += (currentMarketValue ?? 0);
                return result;
            }, 0);
        };
    }

    /**
     * ermittelt den Marktwert unter berücksichtigung eventueller Aufschläge
     *
     * @param {IRealEstateStateModel} state aktueller State
     * @returns {(realEstateId: string, calculationVersion?: number) => number} callback für berechnung
     */
    @Selector()
    public static marketValueById(state: IRealEstateStateModel): (realEstateId: string, calculationVersion?: number) => number | undefined {

        /**
         * Callback funktion zum berechnen des Marktwertes
         *
         * @param {string} realEstateId id des Objekts
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {number} Kosten für Eintragung Eigentumsrecht
         */
        return (realEstateId: string, calculationVersion?: number): number | undefined => {
            const realEstate = RealEstateState.current(state).find(({ id }) => id === realEstateId);
            const realEstateCalculationService = CalculationFactoryService.realEstateCalculationService(calculationVersion);

            return !!realEstate ? realEstateCalculationService.calculatedMarketValue({
                marketValue: HelperService.getValueOrUndefiend(realEstate.marketValue),
                purchasePrice: HelperService.getValueOrUndefiend(realEstate.purchasePrice),
                marketValueType: HelperService.getValueOrUndefiend(realEstate.marketValueType),
                creditPurpose: HelperService.getValueOrUndefiend(realEstate.creditPurpose),
                realEstateType: HelperService.getValueOrUndefiend(realEstate.type),
            }) : undefined;
        };
    }

    //#endregion

    /**
     * ein visibility objekt welches bestimmt, welche Felder und Karten angezeigt werden
     *
     * @param {IRealEstateModel} realEstate zu prüfendes Objekt
     * @returns {IFieldCardVisibilityRealEstate} die visibility Map
     */
    // eslint-disable-next-line complexity
    public static visibleMap(realEstate?: IRealEstateModel | null): IFieldCardVisibilityRealEstate {

        if (!realEstate) {
            return {} as IFieldCardVisibilityRealEstate;
        }

        const typeIsDhOrRhOrSdhOrAhOrO = RealEstateState.typeIsDhOrRhOrSdhOrAhOrO(realEstate);
        const anyTypeSelected = realEstate.type !== null && realEstate.type !== undefined;
        const typeIsFlat = RealEstateState.typeIsFlat(realEstate);
        const areaTerraceGreaterThanZero = HelperService.isGreaterThenZero(realEstate.areaTerrace);
        const areaWinterGardenGreaterThanZero = HelperService.isGreaterThenZero(realEstate.areaWinterGarden);
        const areaOutBuildingsGreaterThanZero = HelperService.isGreaterThenZero(realEstate.areaOutbuildings);
        const typeIsNotLot = RealEstateState.typeIsNotLot(realEstate);
        const typeIsLot = RealEstateState.typeIsLot(realEstate);
        const isUnderConstructionSet = realEstate.underConstruction !== undefined && realEstate.underConstruction !== null;
        const isObjectUnderConstruction = realEstate.underConstruction === true;
        const hasPosition = HelperService.hasValue(realEstate.latitude) && HelperService.hasValue(realEstate.longitude);

        return {
            realEstateId: realEstate.id,
            hasType: HelperService.hasValue(realEstate.type),
            isTypeDhOrRhOrSdhOrAhOrO: typeIsDhOrRhOrSdhOrAhOrO,
            isTypeHouse: RealEstateState.typeIsHouse(realEstate),
            isTypeFlInRoSe: RealEstateState.typeIsFlInRoSe(realEstate),
            isTypeFlat: typeIsFlat,
            isTypeNotLot: typeIsNotLot,
            isTypeLot: typeIsLot,
            isCreditPurposePuReTeO: RealEstateState.creditPurposeIsPuReTeO(realEstate),
            isCreditPurposeCoEx: RealEstateState.creditPurposeIsCoEx(realEstate),
            fieldManuelMarketValue: realEstate.marketValueType === MarketValueType.Input || (realEstate.marketValueType === MarketValueType.DiscountCalculation && realEstate.marketValue === 0),
            fieldObjectType: realEstate.objectPurpose !== ObjectPurposeType.Finance,
            fieldCollateralization: realEstate.objectPurpose !== ObjectPurposeType.Finance,
            fieldWillBeRentedOut: HelperService.hasValue(realEstate.objectPurpose) && realEstate.objectPurpose === ObjectPurposeType.Finance,
            addressHasPosition: hasPosition,
            cardAdditionalAddress: typeIsFlat,
            cardOtherObjectSpecifications: anyTypeSelected,
            fieldLotSize: typeIsDhOrRhOrSdhOrAhOrO || typeIsLot,
            fieldEergyIndex: typeIsNotLot,
            fieldFloor: typeIsFlat,
            cardYearOfConstruction: typeIsNotLot,
            fieldYearOfConstruction: isUnderConstructionSet && !realEstate.underConstruction,
            fieldStartOfConstruction: isUnderConstructionSet && !!realEstate.underConstruction,
            fieldEndOfConstruction: isUnderConstructionSet && !!realEstate.underConstruction,
            isUnderConstruction: isObjectUnderConstruction,
            cardAdditionalInformation: RealEstateState.typeIsHouse(realEstate),
            cardTrustee: HelperService.isTrusteeDirty(realEstate),
            cardTrusteeAddress: HelperService.isTrusteeAddressDirty(realEstate),
            cardAreas: typeIsNotLot,
            fieldNetRoomArea: typeIsFlat,
            fieldAreaGroundFloor: typeIsDhOrRhOrSdhOrAhOrO,
            cardOtherFloors: typeIsDhOrRhOrSdhOrAhOrO,
            cardOtherAreas1: typeIsNotLot,
            fieldAreaGarden: typeIsFlat,
            fieldAreaRoofTerrace: typeIsFlat,
            cardOtherAreas2: typeIsDhOrRhOrSdhOrAhOrO,
            cardBasement: typeIsDhOrRhOrSdhOrAhOrO && HelperService.isGreaterThenZero(realEstate.areaBasement),
            cardRoofConstruction: typeIsFlat || (typeIsDhOrRhOrSdhOrAhOrO && HelperService.isGreaterThenZero(realEstate.areaTopFloor)),
            cardUsabilityLocation: typeIsDhOrRhOrSdhOrAhOrO && (
                areaTerraceGreaterThanZero ||
                areaWinterGardenGreaterThanZero ||
                areaOutBuildingsGreaterThanZero
            ),
            fieldTerrace: typeIsDhOrRhOrSdhOrAhOrO && areaTerraceGreaterThanZero,
            fieldWintergardenType: areaWinterGardenGreaterThanZero,
            fieldAnnexType: areaOutBuildingsGreaterThanZero,
            cardGarage: typeIsFlat || (typeIsDhOrRhOrSdhOrAhOrO && HelperService.isGreaterThenZero(realEstate.areaGarage)),
            fieldParkingSpace: typeIsFlat,
            fieldGarageLocation: typeIsDhOrRhOrSdhOrAhOrO && HelperService.isGreaterThenZero(realEstate.areaGarage),
            cardOuterBuildingRefurbishment: typeIsNotLot,
            fieldRefurbishmentCommonArea: typeIsFlat,
            cardInnerBuildingRefurbishment: typeIsNotLot,
            cardOrientation: typeIsNotLot,
            fieldLivingRoomOrientation: typeIsNotLot,
            fieldFlatOrientation: typeIsFlat,
            cardMainBathroomFacilities: typeIsNotLot,
            cardHeater: typeIsNotLot,
            cardOutsideFacilities: typeIsNotLot,
            cardAdditionalFacilities: typeIsNotLot,
        };
    }

    /**
     * Prüft Objektart
     *
     * @param { IRealEstateModel } realEstate stores real estate data
     * @returns { boolean } return true wenn ?
     */
    private static typeIsDhOrRhOrSdhOrAhOrO(realEstate: Partial<IRealEstateModel>): boolean {
        return (
            !!realEstate && realEstate.type !== undefined && realEstate.type !== null &&
            (realEstate.type === RealEstateType.DetachedHouse ||
                realEstate.type === RealEstateType.RowHouse ||
                realEstate.type === RealEstateType.SemiDetachedHouse ||
                realEstate.type === RealEstateType.ApartmentHouse ||
                realEstate.type === RealEstateType.Other)
        );
    }

    /**
     * Prüft um welchen Haustyp es sich handelt
     *
     * @param { IRealEstateModel } realEstate stores real estate data
     * @returns { boolean } ist House
     */
    private static typeIsHouse(realEstate: Partial<IRealEstateModel>): boolean {
        return (
            !!realEstate && realEstate.type !== undefined && realEstate.type !== null &&
            (realEstate.type === RealEstateType.DetachedHouse ||
                realEstate.type === RealEstateType.SemiDetachedHouse ||
                realEstate.type === RealEstateType.RowHouse ||
                realEstate.type === RealEstateType.ApartmentHouse)
        );
    }

    /**
     * Prüft ob Doppelhaushälfte/Vorsorgewohnung ist
     *
     * @param { IRealEstateModel } realEstate stores real estate data
     * @returns { boolean } true wenn Doppelhaushälfte/Vorsorgewohnung
     */
    private static typeIsFlInRoSe(realEstate: Partial<IRealEstateModel>): boolean {
        return (
            !!realEstate && realEstate.type !== undefined && realEstate.type !== null &&
            [
                RealEstateType.Flat, RealEstateType.InvestmentFlat,
                RealEstateType.RowHouse, RealEstateType.SemiDetachedHouse,
            ].includes(realEstate.type)
        );
    }

    /**
     * Prüft ob Vorsorgewohnung/Eigentumswohnung ist
     *
     * @param { IRealEstateModel } realEstate stores real estate data
     * @returns { boolean } true wenn Vorsorgewohnung/Eigentumswohnung ist
     */
    private static typeIsFlat(realEstate: Partial<IRealEstateModel>): boolean {
        return (
            realEstate.type !== undefined &&
            realEstate.type !== null &&
            (
                realEstate.type === RealEstateType.Flat ||
                realEstate.type === RealEstateType.InvestmentFlat
            )
        );
    }

    /**
     *  Prüft ob Grundstück ist
     *
     * @param { IRealEstateModel } realEstate stores real estate data
     * @returns { boolean } false wenn Grundstück
     */
    private static typeIsNotLot(realEstate: Partial<IRealEstateModel>): boolean {
        return (
            realEstate.type !== undefined &&
            realEstate.type !== null &&
            (
                realEstate.type !== RealEstateType.Lot
            )
        );
    }

    /**
     * Prüft ob Grundstück ist
     *
     * @param { IRealEstateModel } realEstate stores real estate data
     * @returns { boolean } true wenn Grundstück
     */
    private static typeIsLot(realEstate: Partial<IRealEstateModel>): boolean {
        return (
            realEstate.type !== undefined &&
            realEstate.type !== null &&
            (
                realEstate.type === RealEstateType.Lot
            )
        );
    }

    /**
     * Prüft ob Kredit für Kauf/Renovierung/Technische Ausstattung/Sonstiges ist
     *
     * @param { IRealEstateModel } realEstate stores real estate data
     * @returns { boolean } true wenn Kredit für Kauf/Renovierung/Technische Ausstattung/Sonstiges ist
     */
    private static creditPurposeIsPuReTeO(realEstate: Partial<IRealEstateModel>): boolean {
        return (
            !!realEstate && realEstate.creditPurpose !== undefined && realEstate.creditPurpose !== null &&
            [
                CreditPurpose.Purchase, CreditPurpose.Refurbishment,
                CreditPurpose.TechEquipment, CreditPurpose.Other,
            ].includes(realEstate.creditPurpose)
        );
    }

    /**
     *  Prüft ob Kredit für Renovierung/Erweiterung (Ausbau)
     *
     * @param { IRealEstateModel } realEstate stores real estate data
     * @returns { boolean } true wennKredit für Renovierung/Erweiterung (Ausbau)
     */
    private static creditPurposeIsCoEx(realEstate: Partial<IRealEstateModel>): boolean {
        return (
            !!realEstate && realEstate.creditPurpose !== undefined && realEstate.creditPurpose !== null &&
            [CreditPurpose.Construction, CreditPurpose.Expansion].includes(realEstate.creditPurpose)
        );
    }
}
