<div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between stretch" *ngIf="!!internalValueCtr">
    <div fxLayout="column" fxLayoutAlign="start start">
        <mat-label>{{ labelText }}</mat-label>
        <mat-hint *ngIf="!!comment">({{ comment }})</mat-hint>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between">
        <mat-radio-group fxLayout="row" fxLayoutAlign="start center" *ngIf="!!internalIsPercentCtr"
            [formControl]="internalIsPercentCtr">
            <mat-radio-button [value]="false" color="primary" class="cxda-radio">€</mat-radio-button>
            <mat-radio-button [value]="true" color="primary">%</mat-radio-button>
        </mat-radio-group>

        <mat-form-field fxLayout="row" fxLayoutAlign="space-between center" appearance="outline">
            <input [options]="options" currencyMask matInput [formControl]="internalValueCtr" />
            <mat-error cxad-form-field-error *ngIf="internalValueCtr.invalid" [control]="internalValueCtr"></mat-error>
        </mat-form-field>
    </div>
</div>
<hr>