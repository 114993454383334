import { Injectable } from '@angular/core';
import { IReleaseContainer } from '@ntag-ef/release-notes';
import { Observable } from 'rxjs';

import { BaseController } from '../base/base.controller';

/**
 * Controller welche alle Funkionalitäten der RealEstate Controller API bereithält
 */
@Injectable()
export class ReleaseNotesController extends BaseController {

    protected baseUrl = '/ReleaseNote';

    //#region --- Get ---

    /**
     * schaut anhand der userid tabelle nach welche release notes der user zu letzt gesehen hat
     * wenn nicht aktuell ist, wird die aktuellste zurück gegeben
     *
     * @param {string} releaseVersionNumber releaseVersionNumber
     * @returns {Observable<IReleaseContainer | boolean>} return
     */
    // eslint-disable-next-line class-methods-use-this
    public getUserNewestReleaseNotes(releaseVersionNumber?: string): Observable<IReleaseContainer | boolean> {
        return this.get(`${this.baseUrl}/CheckLatestReleaseNote`, {
            params: {
                releaseVersion: releaseVersionNumber ?? '',
            },
        })
    }

    /**
     * setzt beim user die zu letzte gelesen release note version
     *
     * @param  {string} userId userId
     * @param  {string} releaseVersionNumber releaseVersionNumber
     * @returns {Observable<boolean>} return
     */
    public setUserReadLatestReleaseNote(userId: string, releaseVersionNumber: string): Observable<boolean> {
        return this.put(`${this.baseUrl}/UserReadLatestReleaseNote`, null, {
            params: {
                userId: userId,
                releaseVersion: releaseVersionNumber,
            },
        })
    }

    /**
     * holt alle realse notes im container aus dem backend
     *
     * @returns {Observable<IReleaseContainer>} return
     */
    public getAllReleaseNotes(): Observable<IReleaseContainer> {
        return this.get(`${this.baseUrl}/AllReleaseNotes`);
    }

    //#endregion
}
