export enum EntityType {
    User,
    Customer,
    FinancingMap,
    FinancingMapLight,
    FinancingMapStatus,
    RealEstate,
    Household,
    Debtor,
    Liability,
    NewLiability,
    Document,
    File,
    Files,
    Signature,
    Message,
    Activity,
    Task
}
