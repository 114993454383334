import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { FinancingMapStatus } from '@ucba/sdk/enums';
import { IFinancingMapModel } from '@ucba/sdk/models';
import { FinancingMapState } from '@ucba/sdk/statemanagement/states';
import { Subject, filter, map, takeUntil } from 'rxjs';

/**
 * Komponente welches den Lesemodus Banner anzeigt
 */
@Component({
    selector: 'cxad-read-only',
    templateUrl: './read-only.component.html',
    styleUrls: ['./read-only.component.scss'],
})
export class ReadOnlyComponent implements OnInit, OnDestroy {

    @Input() public isVisible: boolean | undefined;
    public status: FinancingMapStatus | undefined;
    public clerkDisplayName: string | undefined;

    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();

    /**
     * Standard Konstruktor
     *
     * @param {Store} store Store injector
     */
    public constructor(
        private store: Store,
    ) { }

    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {
        this.store.select(FinancingMapState.current)
            .pipe(
                takeUntil(this.viewLeft$),
                filter(financing => !!financing),
                map(financing => financing as IFinancingMapModel),
            )
            .subscribe(({ status, clerkDisplayName }) => {
                this.status = status;
                this.clerkDisplayName = clerkDisplayName ?? undefined;
            });
    }


    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
    }

}
