<div class="background">
    <div class="my-data-container">
        <div class="my-data-inner" fxLayout="column">
            <div class="my-data-header">
                <h1>{{ 'components.myData.title' | translate}}</h1>
                <span>{{ 'components.myData.subTitle' | translate}}</span>
            </div>
            <div fxLayout="row" class="navigation-bar" fxLayoutGap="15px" fxLayoutGap.gt-sm="30px"
                fxLayoutAlign="center center">
                <mat-icon [svgIcon]="'cxad-finance-plan' + (!financingPlan.isActive ? '-grey' : '')" color="primary"
                    [routerLink]="'financingplan'" routerLinkActive #financingPlan="routerLinkActive"
                    [matTooltip]="'components.icon.financingplan' | translate"></mat-icon>
                <mat-icon [svgIcon]="'cxad-object' + (!realestate.isActive ? '-grey' : '')" color="primary"
                    [routerLink]="'realestate'" routerLinkActive #realestate="routerLinkActive"
                    [matTooltip]="'components.icon.object' | translate"></mat-icon>
                <mat-icon [svgIcon]="'cxad-person' + (!debtor.isActive ? '-grey' : '')" color="primary"
                    [routerLink]="'debtor'" routerLinkActive #debtor="routerLinkActive"
                    [matTooltip]="'components.icon.person' | translate"></mat-icon>
                <mat-icon [svgIcon]="'cxad-household' + (!household.isActive ? '-grey' : '')" color="primary"
                    [routerLink]="'household'" routerLinkActive #household="routerLinkActive"
                    [matTooltip]="'components.icon.household' | translate"></mat-icon>
                <mat-icon [svgIcon]="'cxad-upload' + (!documents.isActive ? '-grey' : '')" color="primary"
                    [routerLink]="'documents'" routerLinkActive #documents="routerLinkActive" matBadge="!"
                    matBadgePosition="below after" [matBadgeHidden]="!mustSendToVPC" class="doc-icon"
                    [matTooltip]=" (!mustSendToVPC ? 'components.icon.upload' : 'components.financingTab.documents.resubmitWarningTooltip') | translate"></mat-icon>
                <mat-icon *ngIf="financingMapStatus === 0" [svgIcon]="'cxad-download' + '-grey'" color="primary"
                    #bankdocuments class="cursor" [matTooltip]="'components.icon.download' | translate"></mat-icon>
                <mat-icon *ngIf="financingMapStatus !== 0"
                    [svgIcon]="'cxad-download' + (!bankdocuments .isActive ? '-grey' : '')" color="primary"
                    [routerLink]="'bankdocuments'" routerLinkActive #bankdocuments="routerLinkActive"
                    [matTooltip]="'components.icon.download' | translate"></mat-icon>
                <div class="message" fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon [matBadge]="unreadMessageCount" matBadgeSize="small"
                        [svgIcon]="'cxad-message' + (!message.isActive ? '-grey' : '')" color="primary"
                        [routerLink]="'news'" routerLinkActive #message="routerLinkActive"
                        [matTooltip]="'components.icon.message' | translate"></mat-icon>
                </div>
            </div>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>