export * from './base/base.controller';
export * from './account/account.controller';
export * from './mailing/mailing.controller';
export * from './masterdata/masterdata.controller';

export * from './customer/customer.controller';
export * from './financing/financing.controller';
export * from './real-estate/real-estate.controller';
export * from './household/household.controller';
export * from './debtor/debtor.controller';
export * from './liability/liability.controller';
export * from './new-liability/new-liability.controller';
export * from './signature/signature.controller';
export * from './document/document.controller';
export * from './file/file.controller';

export * from './activity/activity.controller';
export * from './message/message.controller';
export * from './task/task.controller';
export * from './release-notes/release-notes.controller';
export * from './statusentry/status-entry.controller';
