import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IBranchModel, ICountryModel, IDebitRateAdaptionModel, IDebitRateModel, IFinancingConfigurationModel, ILegalisationFeeBaseModel, IMorixRatingAssignmentModel } from '../../models';
import { BaseController } from '../base/base.controller';

/**
 * Controller welche alle Funkionalitäten der Masterdata Controller API bereithält
 */
@Injectable()
export class MasterDataController extends BaseController {

    /**
     * abrufen aller Branches
     * 
     * @param {string} lastModified Datum des letzten Eintrags
     * @returns {Observable} Observable mit dem Branches
     */
    public getBranches(lastModified?: string): Observable<IBranchModel[]> {
        const url = !!lastModified ? `/Branches?lastModified=${lastModified}` : '/Branches';
        return this.get(url);
    }

    /**
     * abrufen aller DebitRates
     * 
     * @param {string} lastModified Datum des letzten Eintrags
     * @returns {Observable} Observable mit dem DebitRates
     */
    public getDebitRates(lastModified?: string): Observable<IDebitRateModel[]> {
        const url = !!lastModified ? `/DebitRates?lastModified=${lastModified}` : '/DebitRates';
        return this.get(url);
    }

    /**
     * abrufen aller DebitRateAdaptions
     * 
     * @param {string} lastModified Datum des letzten Eintrags
     * @returns {Observable} Observable mit dem DebitRateAdaptions
     */
    public getDebitRateAdaptions(lastModified?: string): Observable<IDebitRateAdaptionModel[]> {
        const url = !!lastModified ? `/DebitRateAdaptions?lastModified=${lastModified}` : '/DebitRateAdaptions';
        return this.get(url);
    }

    /**
     * abrufen aller BankAccounts
     * 
     * @param {string} lastModified Datum des letzten Eintrags
     * @returns {Observable} Observable mit dem BankAccounts
     */
    public getBankAccounts(lastModified?: string): Observable<string[]> {
        const url = !!lastModified ? `/BankAccounts?lastModified=${lastModified}` : '/BankAccounts';
        return this.get(url);
    }

    /**
     * abrufen aller Countries
     * 
     * @param {string} lastModified Datum des letzten Eintrags
     * @returns {Observable} Observable mit dem Countries
     */
    public getCountries(lastModified?: string): Observable<ICountryModel[]> {
        const url = !!lastModified ? `/Countries?lastModified=${lastModified}` : '/Countries';
        return this.get(url);
    }

    /**
     * abrufen aller LegalisationFeeBases
     * 
     * @param {string} lastModified Datum des letzten Eintrags
     * @returns {Observable} Observable mit dem LegalisationFeeBases
     */
    public getLegalisationFeeBases(lastModified?: string): Observable<ILegalisationFeeBaseModel[]> {
        const url = !!lastModified ? `/LegalisationFeeBases?lastModified=${lastModified}` : '/LegalisationFeeBases';
        return this.get(url);
    }

    /**
     * abrufen aller FinancingConfigurations
     * 
     * @param {string} lastModified Datum des letzten Eintrags
     * @returns {Observable} Observable mit dem FinancingConfigurations
     */
    public getFinancingConfigurations(lastModified?: string): Observable<IFinancingConfigurationModel[]> {
        const url = !!lastModified ? `/FinancingConfigurations?lastModified=${lastModified}` : '/FinancingConfigurations';
        return this.get(url);
    }

    /**
     * abrufen aller MorixRatingAssignments
     * 
     * @param {string} lastModified Datum des letzten Eintrags
     * @returns {Observable} Observable mit dem MorixRatingAssignments
     */
    public getMorixRatingAssignments(lastModified?: string): Observable<IMorixRatingAssignmentModel[]> {
        const url = !!lastModified ? `/MorixRatingAssignments?lastModified=${lastModified}` : '/MorixRatingAssignments';
        return this.get(url);
    }

}
