import { INewLiabilityModel } from '../../models';

/**
 * Action welche alle NewLiability updated
 */
 export class SdkPatchNewLiability {
    public static readonly type = '[SDK NewLiability] PATCH';

    /**
     * Standard Konstruktor
     * 
     * @param {INewLiabilityModel} payload zu patchende NewLiability
     */
    public constructor(public payload: INewLiabilityModel[]) { }
}

/**
 * Action welche ein neues NewLiability hinzufügt, sollte es bereits existieren wird add ignoriert
 */
 export class SdkAddNewLiability {
    public static readonly type = '[SDK NewLiability] ADD';

    /**
     * Standard Konstruktor
     * 
     * @param {INewLiabilityModel} payload neues NewLiability
     */
    public constructor(public payload: INewLiabilityModel) { }
}

/**
 * Action welche ein spezifisches NewLiability updated
 */
export class SdkUpdateNewLiability {
    public static readonly type = '[SDK NewLiability] UPDATE';

    /**
     * Standard Konstruktor
     * 
     * @param {INewLiabilityModel} payload zu patchendes NewLiability
     */
    public constructor(public payload: INewLiabilityModel) { }
}

/**
 * Action welche ein NewLiability entfernt, sollte es nicht existieren wird delete ignoriert
 */
export class SdkDeleteNewLiability {
    public static readonly type = '[SDK NewLiability] DELETE';

    /**
     * Standard Konstruktor
     * 
     * @param {string} id id des zu löschenden NewLiabilitys
     */
    public constructor(public id: string) { }
}

/**
 * Action welche alle NewLiability entfernt, welche einem Haushalt zugeordnet wurden
 */
 export class SdkDeleteNewLiabilityByHousehold {
    public static readonly type = '[SDK NewLiability] DELETE_BY_HOUSEHOLD';

    /**
     * Standard Konstruktor
     * 
     * @param {string} id id des Haushaltes der zu löschenden NewLiability
     */
    public constructor(public id: string) { }
}
