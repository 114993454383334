import { Validators } from '@angular/forms';
import { DebitorCalculationService } from '@ntag-ef/finprocess-calculations';

import { IValidationMap } from '../interfaces';
import { IDebtorModel, IFinancingMapModel, IRealEstateModel } from '../models';
import { HelperService, ModelFactoryService } from '../services';
import { RealEstateState } from '../statemanagement/states';
import { GlobalSettings } from '../utils';

import { DateValidation } from './date.validation';
import { NumberValidation } from './number.validation';

/** Calculation instanz */
const debCalculation = new DebitorCalculationService();

export const FINANCING_PLAN_FORM_VALIDATION_MAP: IValidationMap = {
    realEstateModel: {
        id: {
            required: () => true,
            baseValidator: () => [],
        },
        financingMapId: {
            required: () => true,
            baseValidator: () => [],
        },
        creditPurpose: {
            baseValidator: () => [],
            required: () => true,
        },
        type: {
            baseValidator: () => [],
            required: () => true,
        },
        purchasePrice: {
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
            required: () => false,
        },
        purchasePriceRelevantForAdditionalCosts: {
            changeByChange: true,
            baseValidator: () => [],
            required: () => false,
        },
        lotPrice: {
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
            required: () => false,
        },
        lotPriceRelevantForAdditionalCosts: {
            changeByChange: true,
            baseValidator: () => [],
            required: () => false,
        },
        developmentCosts: {
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
            required: () => false,
        },
        developmentCostsRelevantForAdditionalCosts: {
            changeByChange: true,
            baseValidator: () => [],
            required: () => false,
        },
        constructionCosts: {
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
            required: () => false,
        },
        constructionCostsRelevantForAdditionalCosts: {
            changeByChange: true,
            baseValidator: () => [],
            required: () => false,
        },
        refurbishmentCosts: {
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
            required: () => false,
        },
        constructionCostsOverrun: {
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
            required: () => false,
        },
        otherCosts: {
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
            required: () => false,
        },
    },
    financingMapModel: {
        id: {
            required: () => true,
            baseValidator: () => [],
        },
        customerId: {
            required: () => true,
            baseValidator: () => [],
        },
        assumedDuration: {
            required: () => true,
            baseValidator: () => [Validators.min(GlobalSettings.minDuration), Validators.max(GlobalSettings.maxDuration), NumberValidation.isDivisibleByTwelve],
        },
        communityCustomerNumber: {
            required: () => false,
            baseValidator: () => [Validators.maxLength(GlobalSettings.maxCommunityCustomerNumberLength), Validators.pattern(/^\d+$/)],
        },
        cash: {
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
            required: () => false,
        },
        salesRevenue: {
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
            required: () => false,
        },
        prefinancingSales: {
            changeByChange: true,
            baseValidator: () => [],
            required: () => false,
        },
        redemptionInsurance: {
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
            required: () => false,
        },
        prefinancingInsurance: {
            changeByChange: true,
            baseValidator: () => [],
            required: () => false,
        },
        bausparCreditBalance: {
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
            required: () => false,
        },
        prefinancingBausparCreditBalance: {
            changeByChange: true,
            baseValidator: () => [],
            required: () => false,
        },
        funding: {
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
            required: () => false,
        },
        prefinancingFunding: {
            changeByChange: true,
            baseValidator: () => [],
            required: () => false,
        },
        otherOwnCapital: {
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
            required: () => false,
        },
        prefinancingOtherOwnCapital: {
            changeByChange: true,
            baseValidator: () => [],
            required: () => false,
        },
        notaryFeeInput: {
            baseValidator: (financingMap: IFinancingMapModel, realEstate: IRealEstateModel) => {
                if (financingMap.notaryFeeIsPercent) {
                    return [Validators.min(0), Validators.max(ModelFactoryService.notaryFeeMaximum)];
                }
                else {
                    const result = [Validators.min(0)];
                    const prePurchasingAdditionalCostsSum = RealEstateState.staticPrePurchasingAdditionalCostsSum(
                        realEstate,
                        HelperService.getValueOrUndefiend(financingMap.calculationVersion),
                    );

                    if (prePurchasingAdditionalCostsSum > 0) {
                        const maxValue = Math.round(prePurchasingAdditionalCostsSum * ModelFactoryService.notaryFeeMaximum) / 100;
                        result.push(Validators.max(maxValue));
                    }
                    return result;
                }
            },
            required: () => true,
        },
        notaryFeeIsPercent: {
            changeByChange: true,
            baseValidator: () => [],
            required: () => false,
        },
        brokerageFeeInput: {
            baseValidator: (financingMap: IFinancingMapModel, realEstate: IRealEstateModel) => {
                if (financingMap.brokerageFeeIsPercent) {
                    return [Validators.min(0), Validators.max(ModelFactoryService.brokerageFeeMaximum)];
                }
                else {
                    const result = [Validators.min(0)];
                    const prePurchasingAdditionalCostsSum = RealEstateState.staticPrePurchasingAdditionalCostsSum(
                        realEstate,
                        HelperService.getValueOrUndefiend(financingMap.calculationVersion),
                    );

                    if (prePurchasingAdditionalCostsSum > 0) {
                        const maxValue = Math.round(prePurchasingAdditionalCostsSum * ModelFactoryService.brokerageFeeMaximum) / 100;
                        result.push(Validators.max(maxValue));
                    }
                    return result;
                }
            },
            required: () => true,
        },
        brokerageFeeIsPercent: {
            changeByChange: true,
            baseValidator: () => [],
            required: () => false,
        },
        handlingFeeInput: {
            baseValidator: () => [],
            required: () => true,
        },
        valuationFeeInput: {
            baseValidator: () => [],
            required: () => true,
        },
        registrationChargesPercent: {
            baseValidator: () => [],
            required: () => false,
        },
        financingFee: {
            baseValidator: () => [Validators.maxLength(3)],
            required: () => false,
        },
        financingNotes: {
            baseValidator: () => [Validators.maxLength(4000)],
            required: () => false,
        },
        constructionCreditAmount: {
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
            required: () => false,
        },
        constructionCreditDebitRate: {
            baseValidator: () => [Validators.max(100)],
            required: () => false,
        },
        constructionCreditDuration: {
            baseValidator: () => [Validators.max(GlobalSettings.maxDuration), Validators.pattern(/^\d+$/)],
            required: () => false,
        },
        constructionCreditProcessingCharges: {
            baseValidator: () => [],
            required: () => false,
        },
        constructionCreditCoveredNotes: {
            baseValidator: () => [Validators.maxLength(256)],
            required: () => false,
        },
        guaranteeAmount: {
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
            required: () => false,
        },
        guaranteeDate: {
            required: () => false,
            baseValidator: () => [DateValidation.format],
        },
        guaranteeDuration: {
            baseValidator: () => [Validators.min(0), Validators.max(999)],
            required: () => false,
        },
        guaranteeOriginalTo: {
            baseValidator: () => [],
            required: () => false,
        },
        guaranteeOriginalToOtherNotes: {
            baseValidator: () => [Validators.maxLength(256)],
            required: () => false,
        },
        constructionCreditGuaranteeNotes: {
            baseValidator: () => [Validators.maxLength(256)],
            required: () => false,
        },
        registrationChargesIsFree: {
            baseValidator: () => [],
            required: () => false,
        },
        requestedLanguages: {
            changeByChange: true,
            baseValidator: () => [],
            required: () => false,
        },
    },
    debtorModel: {
        creditDurationInRetirement: {
            required: (debtor: IDebtorModel, financingMap: IFinancingMapModel) => {
                const birthDate = !!debtor.birthday ? new Date(debtor.birthday) : new Date();
                const age = debCalculation.ageDurationEnd({
                    birthday: birthDate,
                    assumedDuration: HelperService.getValueOrUndefiend(financingMap.assumedDuration),
                });
                return age !== undefined && age >= GlobalSettings.retirementAge;
            },
            excludeFromPrecheck: () => true,
            baseValidator: () => [Validators.minLength(1), Validators.maxLength(4000)],
        },
    },
    statement: {
        id: {
            required: () => true,
            baseValidator: () => [],
        },
        creditApplicationStatement: {
            baseValidator: () => [Validators.maxLength(4000)],
            required: () => true,
        },
        requestedLanguages: {
            changeByChange: true,
            baseValidator: () => [],
            required: () => false,
        },
    },
};
