<waiter-component [isLoading]="!form" class="area-wrapper area-height">
    <h2 class="page-title">{{ 'components.financingTab.debtor.pageTitle' | translate }}</h2>
    <cxad-read-only [isVisible]="validation.isReadOnly"></cxad-read-only>

    <mat-chip-listbox>
        <mat-chip-option *ngFor="let entity of debtors; let i = index" removable [selected]="selectedEntityIdx === i"
            (removed)="removeEntity(entity.id)" (click)="onSelectEntity(i)">
            {{ debtorLabels[i] }}
            <mat-icon *ngIf="!validation.isReadOnly" matChipRemove>cancel</mat-icon>
        </mat-chip-option>
        <button *ngIf="debtors.length < maxDebtor" mat-stroked-button (click)="addEntity()"
            [disabled]="validation.isReadOnly" type="button" color="primary">
            {{ 'components.financingTab.debtor.addNewDebtorButton' | translate }}
        </button>
    </mat-chip-listbox>

    <form *ngIf="form" [formGroup]="form" class="scroll-wrapper" #overflowWrapper>
        <div fxLayout="column">
            <div fxLayout="row wrap" fxLayoutAlign="flex-start">
                <!-- <mat-card-subtitle>{{ calcDebitorLabel(debtor.value, 0) }}</mat-card-subtitle> -->
                <cxad-select fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['gender']"
                    translationKey='Gender' [enum]="gender">
                </cxad-select>
                <cxad-input fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['title']"
                    [labelText]="'components.financingTab.debtor.title' | translate">
                </cxad-input>
                <cxad-input fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['firstName']"
                    [labelText]="'components.financingTab.debtor.firstName' | translate">
                </cxad-input>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="flex-start">
                <cxad-input fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['lastName']"
                    [labelText]="'components.financingTab.debtor.lastName' | translate">
                </cxad-input>

                <cxad-input fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['homeStreet']"
                    [labelText]="'components.financingTab.debtor.homeStreet' | translate">
                </cxad-input>

                <cxad-input fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['homeStreetNumber']"
                    [labelText]="'components.financingTab.debtor.homeStreetNumber' | translate">
                </cxad-input>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="flex-start">
                <cxad-input fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['homeStairway']"
                    [labelText]="'components.financingTab.debtor.homeStairway' | translate">
                </cxad-input>
                <cxad-input fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['homeTop']"
                    [labelText]="'components.financingTab.debtor.homeTop' | translate">
                </cxad-input>
                <cxad-input fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['homeZip']"
                    [labelText]="'components.financingTab.debtor.homeZip' | translate">
                </cxad-input>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="flex-start">
                <cxad-input fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['homeCity']"
                    [labelText]="'components.financingTab.debtor.homeCity' | translate">
                </cxad-input>
                <cxad-select fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['homeCountry']"
                    [array]="(listsWithOther['homeCountry'].listObservable$ | async)"
                    [customLabel]="'components.financingTab.debtor.homeCountry' | translate">
                </cxad-select>
                <cxad-phone fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['mobileNumber']"
                    [labelText]="'components.financingTab.debtor.mobileNumber' | translate">
                </cxad-phone>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="flex-start">
                <cxad-input fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['email']" type="email"
                    [labelText]="'components.financingTab.debtor.email' | translate">
                </cxad-input>
                <cxad-date fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['birthday']"
                    [labelText]="'components.financingTab.debtor.birthday' | translate">
                </cxad-date>
                <cxad-select fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['birthcountry']"
                    [array]="(listsWithOther['birthcountry'].listObservable$ | async)"
                    [customLabel]="'components.financingTab.debtor.birthcountry' | translate">
                </cxad-select>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="flex-start">
                <cxad-input *ngIf="form.controls['birthcountry'].value === otherLabel" fxFlex="100"
                    fxFlex.gt-sm="0 1 32%" [control]="form.controls['birthcountryOther']"
                    [labelText]="'components.financingTab.debtor.birthcountryOther' | translate">
                </cxad-input>
                <cxad-input fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['birthPlace']"
                    [labelText]="'components.financingTab.debtor.birthPlace' | translate">
                </cxad-input>
                <cxad-select fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['citizenship']"
                    [array]="(listsWithOther['citizenship'].listObservable$ | async)"
                    [customLabel]="'components.financingTab.debtor.citizenship' | translate">
                </cxad-select>
                <cxad-input *ngIf="form.controls['citizenship'].value === otherLabel" fxFlex="100"
                    fxFlex.gt-sm="0 1 32%" [control]="form.controls['citizenshipOther']"
                    [labelText]="'components.financingTab.debtor.citizenshipOther' | translate">
                </cxad-input>
                <cxad-select fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['additionalCitizenship']"
                    [array]="(listsWithOther['additionalCitizenship'].listObservable$ | async)"
                    [customLabel]="'components.financingTab.debtor.additionalCitizenship' | translate">
                </cxad-select>
                <cxad-input *ngIf="form.controls['additionalCitizenship'].value === otherLabel" fxFlex="100"
                    fxFlex.gt-sm="0 1 32%" [control]="form.controls['additionalCitizenshipOther']"
                    [labelText]="'components.financingTab.debtor.additionalCitizenshipOther' | translate">
                </cxad-input>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="flex-start flex-end">
                <cxad-input fxFlex="100" fxFlex.gt-sm="0 1 32%" *ngIf="form.controls['additionalCitizenship'].value"
                    [control]="form.controls['tinNumber']"
                    [labelText]="'components.financingTab.debtor.tinNumber' | translate"
                    [comment]="'components.financingTab.debtor.tinNumberComment' | translate">
                </cxad-input>
                <cxad-select fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['taxResidence']"
                    [array]="(listsWithOther['taxResidence'].listObservable$ | async)"
                    [customLabel]="'components.financingTab.debtor.taxResidence' | translate">
                </cxad-select>

                <cxad-input *ngIf="form.controls['taxResidence'].value === otherLabel" fxFlex="100"
                    fxFlex.gt-sm="0 1 32%" [control]="form.controls['taxResidenceOther']"
                    [labelText]="'components.financingTab.debtor.taxResidenceOther' | translate">
                </cxad-input>

            </div>

            <div fxLayout="row wrap" fxLayoutAlign="flex-start">
                <cxad-select fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['housingType']"
                    translationKey='HousingType' [enum]="housingType"
                    [customLabel]="'components.financingTab.debtor.housingType' | translate">
                </cxad-select>
                <cxad-date fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['livingAtHomeAddressSince']"
                    [labelText]="'components.financingTab.debtor.livingAtHomeAddressSince' | translate">
                </cxad-date>
                <span fxFlex="0" fxFlex.gt-sm="0 1 32%"></span>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="flex-start">
                <cxad-select fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['maritalStatus']"
                    translationKey='MaritalStatus' [enum]="maritalStatus">
                </cxad-select>
                <cxad-input fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['childrenCount']" type="number"
                    [labelText]="'components.financingTab.debtor.childrenCount' | translate">
                </cxad-input>
                <cxad-input fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['dependentPersonsCount']"
                    type="number" [labelText]="'components.financingTab.debtor.dependentPersonsCount' | translate">
                </cxad-input>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="flex-start" *ngIf="form.controls['id'].value as debitorId">
                <cxad-input fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['vehicleCount']" type="number"
                    [labelText]="'components.financingTab.debtor.vehicleCount' | translate">
                </cxad-input>
                <span fxFlex="0" fxFlex.gt-sm="0 1 32%"></span>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="flex-start">
                <cxad-select t fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['levelOfTraining']"
                    translationKey='LevelOfTraining' [enum]="levelOfTraining">
                </cxad-select>
                <cxad-select fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['employeeStatus']"
                    translationKey='EmployeeStatus' [enum]="employeeStatus">
                </cxad-select>
                <span fxFlex="0" fxFlex.gt-sm="0 1 32%"></span>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="flex-start"
                *ngIf="visibilityMap[form.controls['id'].value]?.cardProfession">
                <cxad-input fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['profession']"
                    [labelText]="'components.financingTab.debtor.profession' | translate">
                </cxad-input>
                <cxad-input fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['employerName']"
                    [labelText]="'components.financingTab.debtor.employerName' | translate">
                </cxad-input>
                <cxad-date fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['employedSince']"
                    [labelText]="'components.financingTab.debtor.employedSince' | translate">
                </cxad-date>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="flex-start">
                <cxad-input fxFlex="100" fxFlex.gt-sm="0 1 32%" [control]="form.controls['iban']"
                    [labelText]="'components.financingTab.debtor.iban' | translate">
                </cxad-input>
                <cxad-select fxFlex="100" fxFlex.gt-sm="0 1 32%" *ngIf="!!households"
                    [control]="form.controls['householdId']" [allowNoSelection]="false" [array]="households"
                    [customLabel]="'components.financingTab.debtor.householdAssignment' | translate">
                </cxad-select>
                <span fxFlex="0" fxFlex.gt-sm="0 1 32%"></span>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="flex-start">
                <cxad-textarea fxFlex [control]="form.controls['notesPerson']" [rows]="10"
                    [labelText]="'components.financingTab.debtor.notesPerson' | translate">
                </cxad-textarea>
            </div>
        </div>
    </form>
    <div fxLayout="row" fxLayoutAlign="end center" class="next-btn">
        <button mat-raised-button (click)="onNext()" type="button" color="primary">
            {{ 'global.buttons.next' | translate }}
        </button>
    </div>
</waiter-component>