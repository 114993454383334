import { Component, Input } from '@angular/core';

/**
 * Komponente, welche eine Output Prozent Feld darstellt
 */
@Component({
  selector: 'ucba-output-percent',
  templateUrl: './output-percent.component.html',
  styleUrls: ['./output-percent.component.scss'],
})
export class OutputPercentComponent {
    @Input() public labelText!: string;
    @Input() public comment: string | undefined;
    @Input() public value: number | undefined;


}
