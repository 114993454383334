<waiter-component [isLoading]="!form" class="area-wrapper area-height">
    <h2 class="page-title">{{ 'components.financingTab.bankdocuments.pageTitle' | translate }}</h2>
    <div *ngIf="!!form" class="scroll-wrapper">
        <div fxLayout="column">
            <div [fxLayout]="productPackagesExists ? 'row wrapr' : 'row' " fxFlex="100" fxLayout.sm="column"
                fxLayoutGap="25px">
                <h2 *ngIf="showDocumentsNotes">{{ 'components.financingTab.bankdocuments.titleNotes' | translate }}</h2>

                <!-- Zusatzinformation -->
                <div fxFlex="100" fxFlex.gt-sm="60" *ngIf="showAdditionalInfo()">
                    <div fxLayout="column">
                        <h2>{{ 'components.financingTab.bankdocuments.titleInformation' | translate }}</h2>
                        <h3>{{ 'components.financingTab.bankdocuments.titleAdditionalInformation' | translate }}</h3>
                        <hr>
                        <div class="add-info" [innerHTML]="additionalInformation"></div>
                    </div>
                    <hr>
                    <p class="margin-t">{{'components.financingTab.bankdocuments.hintUpoladI' | translate }}
                        <a>
                            <mat-icon [svgIcon]="'cxad-upload'" color="primary" [routerLink]="'../documents'">
                            </mat-icon>
                        </a>
                        {{'components.financingTab.bankdocuments.hintUpoladII' | translate }}
                    </p>
                </div>

                <!-- Neue Logik: Rasterformat Anordnung wenn Produktpakete gesendet werden-->
                <div class="margin-t" *ngIf="productPackagesExists && showSelectSampleCaluclationCard"
                    fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="2%">

                    <!-- Documents from VPC -->
                    <ng-template *ngTemplateOutlet="additionalInfoDocumentsCard"></ng-template>

                    <!-- Haushalt -->
                    <ng-template *ngTemplateOutlet="householdDocumentsCard"></ng-template>

                    <!-- Produktpakete -->
                    <ng-container *ngFor="let productPackage of productPackages">
                        <div class="border item margin-b" fxFlex="31" fxFlex.lt-md="48">
                            <ucba-sample-calculation-card [isCxadvisory]="true" [cardTitle]="productPackage.name"
                                [cardSubTitle]="'components.financingTab.bankdocuments.subTitleCalculationExample' | translate"
                                [dataSource]="productPackage.productPackagesfiles"
                                [isProposeRequest]="productPackage.isProposeRequest"
                                [userAcceptedSampleCalculationFileId]="userAcceptedSampleCalculationFileId$"
                                [isProductPackage]="true"
                                [sampleCalculationResponse]="form.controls['sampleCalculationResponse'].value"
                                (selectedValueChanged)="onSelectedValueChanged($event)"
                                (openFileClicked)="onOpenFile($event)"
                                (sendCreditOfferDecisionClicked)="sendCreditOfferDecision(!productPackage.isProposeRequest, $event.sampleCalculationResponse)">
                            </ucba-sample-calculation-card>
                        </div>
                    </ng-container>
                </div>

                <div [ngClass]="showAdditionalInfo() ? 'additional-exist': 'additional-notexist'"
                    *ngIf="!productPackagesExists || (!showSelectSampleCaluclationCard && productPackagesExists)"
                    fxLayout="row wrap" fxLayout.lt-sm="column">
                    <!--  Alte Logik: RB Fix und Variable (lediglich Rechenbeispiele) -->
                    <ng-container *ngIf="!!array && showSelectSampleCaluclationCard">
                        <div class="border item margin-b" [fxFlex]="productPackagesExists ? 31 : '1 1 auto'"
                            [fxFlex.lt-md]="productPackagesExists ? 48 :'1 1 auto'">
                            <ucba-sample-calculation-card [isCxadvisory]="true"
                                [cardTitle]="'components.financingTab.bankdocuments.titleCalculationExample' | translate"
                                [cardSubTitle]="'components.financingTab.bankdocuments.subTitleCalculationExample' | translate"
                                [dataSource]="array" [isProposeRequest]="!userAcceptedSampleCalculationFileId"
                                [userAcceptedSampleCalculationFileId]="userAcceptedSampleCalculationFileId$"
                                [sampleCalculationResponse]="form.controls['sampleCalculationResponse'].value"
                                (selectedValueChanged)="onSelectedValueChanged($event)"
                                (openFileClicked)="onOpenFile($event)"
                                (sendCreditOfferDecisionClicked)="sendCreditOfferDecision($event.accepted, $event.sampleCalculationResponse)">
                            </ucba-sample-calculation-card>
                        </div>
                    </ng-container>

                    <!-- RB Fix und Variable ReadOnly -->
                    <ng-container *ngIf="!!acceptedSampleCalculationFile && showSampleCaluclationChoiceCard">
                        <div class="border item margin-b ">
                            <h2>{{ 'components.financingTab.bankdocuments.titleCalculationExample' | translate }}</h2>
                            <hr>
                            <a class="docs" (click)="onOpenFile(acceptedSampleCalculationFile.id)" fxLayout="row"
                                fxLayoutAlign="space-between center"
                                matTooltip="{{ 'components.financingTab.bankdocuments.showDocumentsTooltip' | translate }}">
                                <span> {{ acceptedSampleCalculationFile.name }} &nbsp;&nbsp;
                                    {{acceptedSampleCalculationFile.created | date:'short' }}</span>
                                <span class="material-icons">east</span>
                            </a>
                            <hr>
                            <cxad-textarea [isReadOnly]="true" [control]="form.controls['sampleCalculationResponse']"
                                [labelText]="'components.financingTab.bankdocuments.sampleCalculationResponse' | translate">
                            </cxad-textarea>
                        </div>
                    </ng-container>

                    <!-- RB neu beantragt -->
                    <ng-container *ngIf="showSampleCaluclationDeniedCard">
                        <div class="border item margin-b ">
                            <h2>{{ 'components.financingTab.bankdocuments.titleCalculationExample' | translate }}</h2>
                            <hr>
                            <h3>{{ 'components.financingTab.bankdocuments.titleCalculationExampleDenied' | translate }}
                            </h3>
                            <hr>
                            <cxad-textarea [isReadOnly]="true" [control]="form.controls['sampleCalculationResponse']"
                                [labelText]="'components.financingTab.bankdocuments.sampleCalculationResponse' | translate">
                            </cxad-textarea>
                        </div>
                    </ng-container>

                    <!-- Documents from VPC -->
                    <ng-template *ngTemplateOutlet="additionalInfoDocumentsCard"></ng-template>

                    <!-- Haushalt -->
                    <ng-template *ngTemplateOutlet="householdDocumentsCard"></ng-template>

                    <!-- ESIS -->
                    <ng-container *ngIf="showEsisCard">
                        <div class="border item margin-b" *ngFor="let product of esisProductPackages?.products">
                            <h2>{{ 'components.financingTab.bankdocuments.titleESIS' | translate }} {{product.name}}
                            </h2>
                            <hr>
                            <div *ngFor="let esisDocument of product.documents">
                                <a class="docs" fxLayout="row" (click)="openDocument(esisDocument.id)"
                                    fxLayoutAlign="space-between center"
                                    matTooltip="{{ 'components.financingTab.bankdocuments.showDocumentsTooltip' | translate }}">
                                    <span fxLayoutAlign="center">
                                        <span>{{esisDocument.name}}</span>
                                        <span>&nbsp;&nbsp; {{esisDocument.modified | date:'short'}}</span>
                                    </span>
                                    <span class="material-icons">east</span>
                                </a>
                                <hr>
                            </div>
                        </div>
                        <!-- Bestätigung ESIS -->
                        <div *ngIf="currentStatus === financingMapStatus.EsisExists" class="border item margin-b">
                            <h2>{{ 'components.financingTab.bankdocuments.decisionEsis' | translate }}</h2>
                            <hr>
                            <mat-radio-group name="esisDecision" class="esis-radio" [(ngModel)]="esisConfrimed">
                                <mat-radio-button *ngFor="let entity of esisDecisionOptions" [value]="entity.value">
                                    <mat-option>
                                        {{entity.label}}
                                    </mat-option>
                                </mat-radio-button>
                            </mat-radio-group>
                            <cxad-textarea *ngIf="!esisConfrimed" [control]="form.controls['deniedEsisResponse']"
                                [labelText]="'components.financingTab.bankdocuments.deniedEsisResponse' | translate">
                            </cxad-textarea>
                            <button class="btn" mat-button (click)="sendEsisDecision(esisConfrimed)">
                                {{ (esisConfrimed ? 'components.financingTab.bankdocuments.submitEsis' :
                                'components.financingTab.bankdocuments.deniedEsis') | translate }}
                            </button>
                        </div>
                    </ng-container>

                    <!-- Ablehnungsschreiben -->
                    <div class="border item margin-b" *ngIf="!!rejectDocuments && showRejectionLetterCard">
                        <h2>{{ 'components.financingTab.bankdocuments.titleRejectionLetter' | translate }}</h2>
                        <hr>
                        <div *ngFor="let file of rejectDocuments">
                            <a class="docs" fxLayout="row" (click)="onOpenFile(file.id)"
                                fxLayoutAlign="space-between center"
                                matTooltip="{{ 'components.financingTab.bankdocuments.showDocumentsTooltip' | translate }}">
                                <span fxLayoutAlign="center">
                                    <span>{{file.name}}</span>
                                    <span>&nbsp;&nbsp; {{file.modified | date:'short'}}</span>
                                </span>
                                <span class="material-icons">east</span>
                            </a>
                            <hr>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</waiter-component>

<ng-template #additionalInfoDocumentsCard>
    <ng-container *ngIf="showAdditionalInfoDocumentsCard">
        <div *ngIf="currentSampleCalculationInformationFiles as documents" class="border item margin-b"
            [fxFlex]="productPackagesExists ? 31 : '1 1 auto'" [fxFlex.lt-md]="productPackagesExists ? 48 :'1 1 auto'">
            <h2>{{ 'components.financingTab.bankdocuments.titleVPCDocuments' | translate }}</h2>
            <hr>
            <div *ngFor="let doc of documents">
                <a class="docs" (click)="onOpenFile(doc.fileId, doc.name)" fxLayout="row"
                    fxLayoutAlign="space-between center"
                    matTooltip="{{ 'components.financingTab.bankdocuments.showDocumentsTooltip' | translate }}">
                    <span> {{doc.name}}</span>
                    <span class="material-icons">east</span>
                </a>
                <hr>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #householdDocumentsCard>
    <ng-container *ngIf="!!householdDocuments  && showHouseholdCard">
        <div *ngFor=" let doc of householdDocuments; let i=index" class="border item margin-b"
            [fxFlex]="productPackagesExists ? 31 : '1 1 auto'" [fxFlex.lt-md]="productPackagesExists ? 48 :'1 1 auto'">
            <h2>{{ 'components.financingTab.bankdocuments.titleHousehold' | translate:{index: (i+1)} }}
            </h2>
            <h5>{{ 'components.financingTab.bankdocuments.subTitleHousehold' | translate }}</h5>
            <hr>
            <div *ngFor="let file of doc.files">
                <a class="docs" (click)="onOpenFile(file.fileId, file.name)" fxLayout="row"
                    fxLayoutAlign="space-between center"
                    [matTooltip]="'components.financingTab.bankdocuments.showDocumentsTooltip' | translate">
                    <span> {{file.name}}</span>
                    <span class="material-icons">east</span>
                </a>
                <hr>
            </div>
        </div>
    </ng-container>
</ng-template>