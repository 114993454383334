/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable class-methods-use-this */

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CreditPurpose } from '@ntag-ef/finprocess-enums/finadvisory';
import { ILiabilityInput } from '@ntag-ef/finprocess-validation';

import { ILiabilityModel } from '../../models';
import { CalculationFactoryService, HelperService } from '../../services';
import { Dispose, UnLoadCustomer, UnLoadFinancing } from '../actions';

import { SdkAddLiability, SdkDeleteLiability, SdkDeleteLiabilityByHousehold, SdkPatchLiability, SdkUpdateLiability } from './liability.actions';

export interface ILiabilityStateModel {
    data: ILiabilityModel[];
}

/**
 * Klasse des Liability States
 */
@State<ILiabilityStateModel>({
    name: LiabilityState.stateName,
    defaults: LiabilityState.defaultData,
})
@Injectable()
export class LiabilityState {

    public static readonly stateName = 'liabilityState';

    private static readonly defaultData: ILiabilityStateModel = {
        data: [],
    };

    /* #region  Actions */

    /**
     * Aktualisiert die aktuellen Bestandskredite
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchLiability} action SdkPatchLiability Action
     */
    @Action(SdkPatchLiability)
    public patchLiability(
        { patchState }: StateContext<ILiabilityStateModel>,
        { payload }: SdkPatchLiability,
    ): void {
        patchState({
            data: payload,
        });
    }

    /**
     * Aktualisiert ein spezifischen Bestandskredit
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkUpdateLiability} action SdkUpdateLiability Action
     */
    @Action(SdkUpdateLiability)
    public updateLiability(
        { patchState, getState }: StateContext<ILiabilityStateModel>,
        { payload }: SdkUpdateLiability,
    ): void {
        const current = getState().data;

        if (current.some(({ id }) => id === payload.id)) {
            patchState({
                data: current.filter(({ id }) => id !== payload.id).concat(payload),
            });
        }
    }

    /**
     * fügt ein neuen Bestandskredit hinzu
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkAddLiability} action SdkAddLiability Action
     */
    @Action(SdkAddLiability)
    public addLiability(
        { patchState, getState }: StateContext<ILiabilityStateModel>,
        { payload }: SdkAddLiability,
    ): void {
        const current = getState().data;

        if (!current.some(({ id }) => id === payload.id)) {
            patchState({
                data: current.concat(payload),
            });
        }
    }

    /**
     * entfernt ein spezifischen Bestandskredit
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkDeleteLiability} action SdkDeleteLiability Action
     */
    @Action(SdkDeleteLiability)
    public deleteLiability(
        { patchState, getState }: StateContext<ILiabilityStateModel>,
        { id: liabilityId }: SdkDeleteLiability,
    ): void {
        const current = getState().data;

        if (current.some(({ id }) => id === liabilityId)) {
            patchState({
                data: current.filter(({ id }) => id !== liabilityId),
            });
        }
    }

    /**
     * entfernt ein alle Bestandskredit eines Haushaltes
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkDeleteLiabilityByHousehold} action SdkDeleteLiabilityByHousehold Action
     */
    @Action(SdkDeleteLiabilityByHousehold)
    public deleteNewLiabilityByHousehold(
        { patchState, getState }: StateContext<ILiabilityStateModel>,
        { id }: SdkDeleteLiabilityByHousehold,
    ): void {
        const current = getState().data;

        if (current.some(({ householdId }) => householdId === id)) {
            patchState({
                data: current.filter(({ householdId }) => householdId !== id),
            });
        }
    }

    /**
     * zurücksetzen des States
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action([Dispose, UnLoadFinancing, UnLoadCustomer])
    public dispose({ setState }: StateContext<ILiabilityStateModel>): void {
        setState({ ...LiabilityState.defaultData });
    }

    /* #endregion */

    /* #region  Selectors */

    /**
     * gibt alle Bestandskredite zurück, welche der aktuellen Finanzierung zugeordnet sind
     *
     * @param {ILiabilityStateModel} state aktueller State
     * @returns {ILiabilityModel} die aktuell selektierten Bestandskredite
     */
    @Selector()
    public static current(state: ILiabilityStateModel): ILiabilityModel[] {
        return state?.data ?? [];
    }

    /**
     * gibt den Bestandskredit mit übergebener Id zurück
     *
     * @param {ILiabilityStateModel} state aktueller State
     * @returns {(liabilityId: string) => ILiabilityStateModel} funktion zum filtern
     */
    @Selector()
    public static currentById(state: ILiabilityStateModel):
        (liabilityId: string) => ILiabilityModel | undefined {

        /**
         * callback funktion zum herraussuchen eines Bestandskredites
         *
         * @param {string} liabilityId id des Bestandskredites
         * @returns {ILiabilityModel} gesuchter Bestandskredit
         */
        return (liabilityId: string) => state.data.find(({ id }) => id === liabilityId);
    }

    /**
     * gibt alle aktuellen Bestandskredite eines Haushaltes zurück
     *
     * @param {ILiabilityStateModel} state aktueller State
     * @returns {(householdIds: string[]) => ILiabilityModel[]} funktion zum filtern
     */
    @Selector()
    public static currentByHousholdIds(state: ILiabilityStateModel):
        (householdIds: string[]) => ILiabilityModel[] {
        /**
         * callback funktion zum filtern
         *
         * @param {string[]} householdIds ids der Haushalte
         * @returns {ILiabilityModel[]} gefilterte Bestandskredite
         */
        return (householdIds: string[]): ILiabilityModel[] => state.data.filter(({ householdId }) => householdIds.includes(householdId));
    }

    /**
     * gibt alle aktuellen Bestandskredite im Format für den Precheck zurück
     *
     * @param {ILiabilityStateModel} state aktueller State
     * @returns {ILiabilityInput} Bestandskredite
     */
    @Selector()
    public static asInputModel(state: ILiabilityStateModel): ILiabilityInput[] {
        return LiabilityState.current(state).filter(l => HelperService.isLiabilityDirty(l)) as ILiabilityInput[];
    }

    /* #endregion */

    /* #region calculations */

    /**
     * berechnet monatliche Rate
     *
     * @param {ILiabilityStateModel} state aktueller State
     * @returns {(householdId: string, calculationVersion?: number) => number} funktion zum berechnen
     */
    @Selector()
    public static liabilitiesSumByHouseHold(state: ILiabilityStateModel):
        (householdId: string, calculationVersion?: number) => number {

        /**
         * Callback funktion zum berechnen der montl. Rate
         *
         * @param {string} householdId Id des Haushaltes
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {number} monatliche Rate
         */
        return (householdId: string, calculationVersion?: number): number => {
            const liabilities = LiabilityState.currentByHousholdIds(state)([householdId]);
            const liabilityCalculationService = CalculationFactoryService.liabilityCalculationService(calculationVersion);

            return liabilities.reduce<number>((acc, { monthlyRate, covered }) =>
                acc + liabilityCalculationService.monthlyRateNotCovered({
                    monthlyRate,
                    covered,
                }), 0);
        }
    }

    /**
     * berechnet Dzt. Aushaftung gem. Nachweis
     *
     * @param {ILiabilityStateModel} state aktueller State
     * @returns {(householdIds: string[], creditPurpose: CreditPurpose, calculationVersion?: number) => number} funktion zum berechnen
     */
    @Selector()
    public static coveragePriorCreditByHouseHolds(state: ILiabilityStateModel):
        (householdIds: string[], creditPurpose: CreditPurpose, calculationVersion?: number) => number {

        /**
         * Callback funktion zum berechnen der Dzt. Aushaftung
         *
         * @param {string[]} householdIds Ids der Haushalte
         * @param {CreditPurpose} creditPurpose Finanzierungszweck
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {number} Dzt. Aushaftung
         */
        return (householdIds: string[], creditPurpose: CreditPurpose, calculationVersion?: number): number => {
            const liabilities = LiabilityState.currentByHousholdIds(state)(householdIds);
            const liabilityCalculationService = CalculationFactoryService.liabilityCalculationService(calculationVersion);

            return creditPurpose === CreditPurpose.Rescheduling ?
                liabilities.reduce<number>((acc, { currentAmount, covered }) =>
                    acc + liabilityCalculationService.currentAmountCovered({
                        currentAmount,
                        covered,
                    }), 0) :
                0;
        };
    }

    /**
     * berechnet Dzt. Aushaftung gem. Nachweis, wenn nicht abgedeckt und grundbücherlich besichert
     *
     * @param {ILiabilityStateModel} state aktueller State
     * @returns {(calculationVersion?: number) => number} funktion zum berechnen
     */
    @Selector()
    public static liabilityAmountOfLandRegister(state: ILiabilityStateModel):
        (calculationVersion?: number) => number {

        /**
         * Callback funktion zum berechnen der Dzt. Aushaftung
         *
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {number} Dzt. Aushaftung
         */
        return (calculationVersion?: number): number => {
            const liabilityCalculationService = CalculationFactoryService.liabilityCalculationService(calculationVersion);
            const liabilities = LiabilityState.current(state);

            return liabilities.reduce<number>((acc, { securedByLandRegister, covered, currentAmount }) =>
                acc + liabilityCalculationService.currentAmountSecuredByLandRegisterNotCovered({
                    currentAmount,
                    covered,
                    securedByLandRegister,
                }), 0);
        };
    }

    /* #endregion */
}
