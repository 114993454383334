/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable class-methods-use-this */

import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { GlobalSettings } from '@ucba/sdk';
import { Dispose } from '@ucba/sdk/statemanagement/actions';

import { SetConfirmed, SetCustomerId, SetHash } from './application.actions';

/**
 * Datenmodel für ApplicationState
 */
export interface IApplicationStateModel {
    hash: string | null;
    customerId: string | null;
    hasConfirmed: boolean;
}

/**
 * Klasse des Anwendungs States
 */
@State<IApplicationStateModel>({
    name: ApplicationState.stateName,
    defaults: ApplicationState.defaultData,
})
@Injectable()
export class ApplicationState implements NgxsOnInit {
    public static readonly stateName = 'applicationState';

    private static readonly defaultData: IApplicationStateModel = {
        hash: null,
        customerId: null,
        hasConfirmed: false,
    };

    /**
     * initialized state
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @see https://ngxs.gitbook.io/ngxs/advanced/life-cycle#ngxsoninit
     */
    public ngxsOnInit({ getState }: StateContext<IApplicationStateModel>): void {
        const state = getState();
        GlobalSettings.token = state.hash;
    }

    /**
     * Aktualisiert den Hash
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SetHash} action setHash Action
     */
    @Action(SetHash)
    public setHash(
        { patchState }: StateContext<IApplicationStateModel>,
        { hash }: SetHash,
    ): void {
        patchState({ hash });
    }

    /**
     * Aktualisiert den Hash
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SetConfirmed} action SetConfirmed Action
     */
    @Action(SetConfirmed)
    public setConfirmed(
        { patchState }: StateContext<IApplicationStateModel>,
        { confirmed }: SetConfirmed,
    ): void {
        patchState({ hasConfirmed: confirmed });
    }

    /**
     * Aktualisiert die Kunden Id
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SetCustomerId} action SetCustomerId Action
     */
    @Action(SetCustomerId)
    public setCustomerId(
        { patchState }: StateContext<IApplicationStateModel>,
        { customerId }: SetCustomerId,
    ): void {
        patchState({ customerId });
    }

    /**
     * zurücksetzen des States
     * 
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(Dispose)
    public dispose({ setState }: StateContext<IApplicationStateModel>): void {
        setState({ ...ApplicationState.defaultData });
    }

    /**
     * prüft ob ein authentifizierungstoken vorliegt
     * 
     * @param {IApplicationStateModel} state aktueller State
     * @returns {boolean} ist ein Token gesetzt
     */
    @Selector()
    public static isLoggedIn(state: IApplicationStateModel): boolean {
        return !!state.hash && !!state.customerId && state.hasConfirmed;
    }

    /**
     * gibt die Id des Kunden zurück
     * 
     * @param {IApplicationStateModel} state aktueller State
     * @returns {string} die aktuelle Kunden Id
     */
    @Selector()
    public static customerId(state: IApplicationStateModel): string | null {
        return state.customerId;
    }

    /**
     * gibt hash des Kunden zurück
     * 
     * @param {IApplicationStateModel} state aktueller State
     * @returns {string} die aktuelle Kunden Id
     */
    @Selector()
    public static hash(state: IApplicationStateModel): string | null {
        return state.hash;
    }

    /**
     * gibt den aktuellen confirmed status zurück
     * 
     * @param {IApplicationStateModel} state aktueller State
     * @returns {boolean} confirmed status
     */
    @Selector()
    public static hasConfirmed(state: IApplicationStateModel): boolean | null {
        return state.hasConfirmed;
    }
}
