<mat-drawer-container>
    <mat-drawer #drawer position="end">
        <div fxLayout="column" *ngIf="isLoggedIn$ | async">
            <span routerLink="/financing" class="mobile-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
                matTooltip="{{ 'components.navigation.myFinancing' | translate }}">
                {{ 'components.navigation.myFinancing' | translate}}
            </span>
            <span routerLink="/financing/status" class="mobile-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}" matTooltip="{{ 'components.navigation.status' | translate }}">
                {{ 'components.navigation.status' | translate}}
            </span>
        </div>
    </mat-drawer>
    <mat-drawer-content fxLayout="column" fxLayoutAlign="space-between" *ngIf="!hideHeader; else privacy_statement">
        <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayoutAlign="start center" matTooltip="{{ 'components.navigation.home' | translate }}">
                <mat-icon routerLink="/welcome" fxHide.gt-sm svgIcon="bank-austria-mobile"></mat-icon>
                <mat-icon routerLink="/welcome" fxHide fxHide.gt-sm="false" svgIcon="bank-austria"></mat-icon>
            </div>
            <div fxLayoutAlign="end center">
                <mat-icon *ngIf="globalSettings.hubError" class="no-hub-icon"
                    (click)="onHubWarningClicked()">warning</mat-icon>
                <ng-container *ngIf="isLoggedIn$ | async">
                    <nav fxHide fxHide.gt-sm="false" mat-tab-nav-bar mat-stretch-tabs mat-align-tabs="end"
                        [tabPanel]="tabPanel">
                        <a mat-tab-link *ngFor="let link of navLinks" routerLink="{{ link.location }}" routerLinkActive
                            #rla="routerLinkActive" [active]="rla.isActive" [routerLinkActiveOptions]="{exact: true}">
                            <div>
                                <h2 mat-tab-label-h2 class="nav-item" [matTooltip]="link.label | translate">
                                    {{ link.label | translate}}
                                </h2>
                            </div>
                        </a>
                    </nav>
                    <span fxHide.gt-sm class="hover-pointer material-icons md-36" (click)="toggleDrawer()">menu</span>
                    <span (click)="onLogout()" class="hover-pointer logout material-icons md-36"
                        matTooltip="{{ 'logout.tooltip' | translate }}">logout</span>
                </ng-container>
            </div>
        </div>

        <mat-tab-nav-panel #tabPanel>
            <router-outlet></router-outlet>
        </mat-tab-nav-panel>

        <cxad-footer></cxad-footer>
    </mat-drawer-content>
    <ng-template #privacy_statement>
        <router-outlet>
        </router-outlet>
    </ng-template>
</mat-drawer-container>