/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, Input, OnInit } from '@angular/core';
/**
 * Komponete, welche als Skeleton die ladesequenz darstellt
 */
@Component({
    selector: 'ucba-skeleton-card',
    templateUrl: 'skeleton-card.component.html',
    styleUrls: ['skeleton-card.component.scss'],
})
export class SkeletonCardComponent implements OnInit {
    @Input() public items = 0;
    @Input() public rows = 0;
    @Input() public buttons: number | undefined;
    @Input() public column = false;
    @Input() public inputs = 0;
    @Input() public marginRightPx: number | undefined;
    @Input() public cardFxFlexMd = 50;
    @Input() public cardFxFlex = 32;
    public randomNumbersArray: number[] = [];
    /**
     * Beim Erstellen von Skeleton Cards
     * werden anhand der Anzahl der Zeile pro Card zufällige zahlen zwischen 30 und 90 erstellt
     * die die länge der Zeile wiederspiegelt um einen nätürlichen text fluss wiederzuspiegeln 🤟
     *( @returns) void
     */
    ngOnInit(): void {
        if (this.rows > 0) {
            for (let i1 = 0; i1 < this.items * 2; i1++) {
                for (let i2 = 0; i2 < this.rows; i2++) {
                    this.randomNumbersArray.push(Math.floor(Math.random() * (90 - 30 + 1) + 30));
                }
            }

        }
        if (this.inputs > 0) {
            for (let i1 = 0; i1 < this.items; i1++) {
                for (let i = 0; i < this.inputs; i++) {
                    this.randomNumbersArray.push(Math.floor(Math.random() * (80 - 10 + 1) + 10));
                }
            }
        }
    }
}
