<mat-dialog-content>
    <waiter-component [isLoading]="(allEmpty$ | async) === null">
        <ng-container *ngIf="(allEmpty$ | async) === false">
            <mat-tab-group *ngIf="!!areas" [selectedIndex]="selectedIndex" mat-stretch-tabs mat-align-tabs="center">
                <mat-tab *ngFor="let area of areas">
                    <ng-template mat-tab-label>
                        <span [matBadge]="(area.missingFields$ | async)" matBadgeSize="small"
                            matBadgeOverlap="false">{{area.label}}
                        </span>
                    </ng-template>
                    <div *ngIf="(area.sections$ | async) as sections">
                        <ng-container *ngIf="sections.length === 0">
                            {{'components.validationDialog.note' | translate}}
                        </ng-container>
                        <mat-accordion multi *ngIf="sections.length > 0">
                            <mat-expansion-panel *ngFor="let section of sections" [expanded]="section.fields.length > 0"
                                [hideToggle]="section.fields.length === 0" [disabled]="section.fields.length === 0">
                                <mat-expansion-panel-header>
                                    {{ section.label }}
                                </mat-expansion-panel-header>
                                <mat-list role="list">
                                    <mat-list-item *ngFor="let field of section.fields" role="listitem"
                                        (click)="onGoTo(area.path, field, section.subArea)">
                                        <div fxFlex="50" class="field-label">{{ !!field.label ? field.label :
                                            field.fieldName }}
                                        </div>
                                        <div fxFlex="50" class="error-label" color="primary">{{ field.errorLabel }}
                                        </div>
                                    </mat-list-item>
                                </mat-list>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </ng-container>
        <ng-container *ngIf="(allEmpty$ | async) === true">
            {{'components.validationDialog.note' | translate}}
        </ng-container>
    </waiter-component>
    <button class="button-close" mat-raised-button color="primary" (click)="dismiss()">Ok</button>
</mat-dialog-content>