import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IMailContentRequest, ISendMailRequest } from '../../requests';
import { IMailContentResponse } from '../../responses';
import { BaseController } from '../base/base.controller';

/**
 * Controller welche das Mailtemplate abruft und Mails sendet
 */
@Injectable()
export class MailingController extends BaseController {

    private baseUrl = '/mailing';

    /**
     * Generiert im Backend das Mail Template um einen kunden Link zu versenden
     * 
     * @param {IMailContentRequest} mailContentRequest The mail content request
     * @returns {Observable<any>} Observable mit dem Mail Content
     */
    public getMailTemplate(mailContentRequest: IMailContentRequest): Observable<IMailContentResponse> {
        return this.get(`${this.baseUrl}/mailtemplate/${mailContentRequest.id}?type=${mailContentRequest.type}`) as Observable<IMailContentResponse>;
    }

    /**
     * Schickt über das Backend übergebene Mail an den in den Kundenstammdaten hinterlegte EMail
     * 
     * @param {ISendMailRequest} mailContent der mail Content
     * @returns {Observable<void>} Abschluss Observable
     */
    public sendMail(mailContent: ISendMailRequest): Observable<void> {
        return this.post(`${this.baseUrl}/sendmail/${mailContent.customerId}?type=${mailContent.type}`, mailContent);
    }

}
