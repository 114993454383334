import { IBackendBaseModel } from './backend-base.model';

/**
 * Household data.
 */
export interface IHouseholdModel extends IBackendBaseModel {

    /** The financing map identifier. */
    financingMapId: string;

    /** The position. */
    position?: number | null;

    /** The operational costs. */
    operationalCosts?: number | null;

    /** The electricity costs. */
    electricityCosts?: number | null;

    /** The phone costs. */
    phoneCosts?: number | null;

    /** The broadcast costs. */
    broadcastCosts?: number | null;

    /** The car costs. */
    carCosts?: number | null;

    /** The insurance costs. */
    insuranceCosts?: number | null;

    /** The living costs. */
    livingCosts?: number | null;

    /** The other costs. */
    otherCosts?: number | null;

    /** The notes about the household. */
    notesHousehold?: string | null;
}
