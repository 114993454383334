import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { EnumTranslationService } from '@ntag-ef/finprocess-enums';
import { PhoneAreaCode, PhoneErrorStateMatcher } from '@ucba/components';
import { IListTuple } from '@ucba/sdk/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface IPhoneTuple {
    areaCode: string;
    number: string;
}

/**
 * Komponete, welche eine Telefonnummer Feld darstellt
 */
@Component({
    selector: 'cxad-phone',
    templateUrl: './phone.component.html',
    styleUrls: ['./phone.component.scss'],
})

export class PhoneComponent implements OnInit, OnDestroy {
    /**
     * setzt das interne control und updated das interne phoneForm
     */
     @Input() public set control(ac: AbstractControl | undefined) {
        this.controlInternal = ac;

        if (!!this.phoneForm && !!ac) {
            const initValues = this.parseInitValues(ac.value);
            this.phoneForm.patchValue({
                areaCode: initValues.areaCode,
                number: initValues.number,
            }, { onlySelf: true, emitEvent: false });
        }
    }

    /**
     * getter für internes Control
     * 
     * @returns {AbstractControl} interes control
     */
     public get control(): AbstractControl | undefined {
        return this.controlInternal;
    }

    private controlInternal: AbstractControl | undefined;

    @Input() public labelText!: string;
    @Input() public comment: string | undefined;

    public areaCodeList: IListTuple<string>[];
    public isRequired: boolean | undefined;

    public phoneForm: FormGroup | undefined;
    public phoneErrorStateMatcher!: PhoneErrorStateMatcher;

    private defaultSelection: string;

    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();

    /**
     * Standard Konstruktor
     *
     * @param  {EnumTranslationService} enumTranslate EnumTranslationService injector
     * @param  {UntypedFormBuilder} fb UntypedFormBuilder injector
     */
    public constructor(
        private enumTranslate: EnumTranslationService,
        private fb: UntypedFormBuilder,
    ) {
        this.areaCodeList = Object.values(PhoneAreaCode).map<IListTuple<string>>(t => ({
            value: this.enumTranslate.instant({ type: 'AreaCode', value: t }) as string,
            label: this.enumTranslate.instant({ type: 'AreaCode', value: t }) as string,
        }));

        this.defaultSelection = this.enumTranslate.instant({ type: 'AreaCode', value: PhoneAreaCode.AT }) as string;
    }

    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {
        // @see https://stackoverflow.com/a/43904237
        const validators = !!this.controlInternal && !!this.controlInternal.validator ? this.controlInternal.validator(new FormControl()) : {};
        this.isRequired = !!validators && ('required' in validators);

        const currentValue = !!this.controlInternal ? this.controlInternal.value : '';
        const initValues = this.parseInitValues(currentValue);

        if (this.controlInternal) {
            this.phoneErrorStateMatcher = new PhoneErrorStateMatcher(this.controlInternal);

            // Update der internen form wenn der wert sich von außen ändert
            this.controlInternal.valueChanges
                .pipe(takeUntil(this.viewLeft$))
                .subscribe((phoneNr: string) => {
                    const values = this.parseInitValues(phoneNr);
                    const current = this.phoneForm?.value as IPhoneTuple;

                    if (values.areaCode !== current.areaCode || values.number !== current.number) {
                        this.phoneForm?.patchValue(values, { onlySelf: true, emitEvent: false });
                    }
                });
            this.controlInternal.statusChanges
                .pipe(takeUntil(this.viewLeft$))
                .subscribe(status => {
                    if (status === 'DISABLED' && this.phoneForm?.enabled) {
                        this.phoneForm?.disable();
                    }
                    else if (this.phoneForm?.disabled) {
                        this.phoneForm?.enable();
                    }
                })
        }

        this.phoneForm = this.fb.group({
            areaCode: [initValues.areaCode, Validators.required],
            number: [initValues.number, Validators.required],
        }, {
            updateOn: 'blur',
        });

        if (this.controlInternal?.disabled) {
            this.phoneForm.disable({ emitEvent: false });
        }

        this.phoneForm.valueChanges
            .pipe(takeUntil(this.viewLeft$))
            .subscribe((value: IPhoneTuple) => {
                if (!!this.phoneForm && this.controlInternal) {
                    if (!!value.number) {
                        this.controlInternal.patchValue(`${value.areaCode}${value.number}`);
                    }
                    else if (!this.controlInternal.value) {
                        this.phoneForm?.get('areaCode')?.patchValue(value.areaCode, { onlySelf: true, emitEvent: false });
                    }
                    else {
                        this.controlInternal.patchValue(null);
                    }
                    this.controlInternal.markAsDirty();
                }
            });
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
    }

    /**
     * Uses glibphone to try and find the country code of the given number
     *
     * @param {string} phoneNumber The phone number
     * @returns {string} der country Code
     */
    private parseInitValues(phoneNumber: string): IPhoneTuple {
        if (!phoneNumber || !phoneNumber.startsWith('+')) {
            return { areaCode: this.defaultSelection, number: phoneNumber };
        }
        else {
            const code = phoneNumber.substr(0, 3);
            if (this.areaCodeList.map(({ value }) => value).some(c => c === code)) {
                return { areaCode: code, number: phoneNumber.substr(3) };
            }
            else {
                return { areaCode: '+', number: phoneNumber.substr(1) };
            }
        }
    }
}
