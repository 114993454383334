<mat-form-field *ngIf="!!control" [hideRequiredMarker]="!isRequired" [attr.name]="controlName">
    <mat-label>{{ labelText }}</mat-label>
    <textarea class="settings" matInput [formControl]="formControl" [rows]="rows" [required]="!!isRequired"
        [readonly]="isReadOnly"></textarea>

    <mat-error ucba-form-field-error *ngIf="control.invalid" [control]="control"></mat-error>
    <mat-hint *ngIf="!!comment">
        {{ comment }}
        <ng-container *ngIf="!!comment2">
            <br> {{ comment2 }}
        </ng-container>
    </mat-hint>
</mat-form-field>