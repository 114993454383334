import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Language } from '@ntag-ef/finprocess-enums';
import { CreditPurpose, RealEstateType } from '@ntag-ef/finprocess-enums/finadvisory';
import { CurrencyInputType } from '@ucba/components';
import { clearInvalidFormValues } from '@ucba/sdk';
import { ICalculationFinancingPlan, IValidationMapClass } from '@ucba/sdk/interfaces';
import { IFinancingMapModel, IRealEstateModel } from '@ucba/sdk/models';
import { DataService, HelperService, ModelFactoryService, ValidationService } from '@ucba/sdk/services';
import { FinancingMapState, MasterDataState, RealEstateState } from '@ucba/sdk/statemanagement/states';
import { FINANCING_PLAN_FORM_VALIDATION_MAP } from '@ucba/sdk/validations';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

/**
 *  Financingplan Komponente
 */
@Component({
    selector: 'cxad-financingplan',
    templateUrl: './financingplan.component.html',
    styleUrls: ['./financingplan.component.scss'],
})
export class FinancingplanComponent implements OnInit, OnDestroy, AfterViewInit {

    @Select(FinancingMapState.calculations)
    public financingPlanCalculations$: Observable<ICalculationFinancingPlan> | undefined;

    public form: FormGroup | undefined;
    public realEstateForm: FormGroup | undefined;
    public financingMapForm: FormGroup | undefined;

    public creditPurpose = CreditPurpose;
    public realEstateType = RealEstateType;
    public landRegistryRequest = 0;
    public landRegistryExcerpt = 0;
    public notaryFeeDefault = ModelFactoryService.notaryFeeDefault;
    public notaryFeeMaximum = ModelFactoryService.notaryFeeMaximum;
    public brokerageFeeDefault = ModelFactoryService.brokerageFeeDefault;
    public brokerageFeeMaximum = ModelFactoryService.brokerageFeeMaximum;

    public currencyInputType = CurrencyInputType;

    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();

    public notaryFeeForm: FormGroup | undefined;
    public brokerageFeeForm: FormGroup | undefined;

    /**
     * Standard Konstruktor
     *
     * @param { UntypedFormBuilder } fb UntypedFormBuilder injector
     * @param { Store } store Store injector
     * @param {DataService} dataService DataService injector
     * @param { ValidationService } validation ValidationService injector
     * @param { Router } router Router injector
     * @param { ActivatedRoute } route ActivatedRoute injector
     * @param { ElementRef } elementRef ElementRef injector
     */
    public constructor(
        private fb: UntypedFormBuilder,
        private store: Store,
        private dataService: DataService,
        public validation: ValidationService,
        private router: Router,
        private route: ActivatedRoute,
        private elementRef: ElementRef<HTMLElement>,
    ) { }

    /**
     * parst ein AbstractControl in ein FormGroup
     *
     * @param {AbstractControl} ac zu parsendes AbstractControl
     * @returns {FormGroup} geparste FormGroup
     */
    // eslint-disable-next-line class-methods-use-this
    public asFormGroup(ac: AbstractControl | undefined): FormGroup {
        return ac as FormGroup;
    }

    /**
     * Angular Hook um Werte zu initialisieren
     */
    public ngOnInit(): void {

        combineLatest([
            this.store.select(FinancingMapState.current),
            this.store.select(RealEstateState.currentFinancingObject),
            this.store.select(FinancingMapState.registrationChargesAmountIsFree),
        ])
            .pipe(takeUntil(this.viewLeft$))
            .subscribe(([financingMap, realEstate, registrationChargesIsFree]) => {
                if (!!financingMap && !!realEstate) {

                    const financingConfigurationId = HelperService.getValueOrUndefiend(financingMap?.financingConfigurationId);
                    const cfg = this.store.selectSnapshot(MasterDataState.determineFinancingConfiguration)(financingConfigurationId);

                    if (cfg) {
                        this.landRegistryRequest = cfg.landRegisterRequest;
                        this.landRegistryExcerpt = cfg.landRegisterExtract;
                    }

                    if (!this.form) {
                        this.initFormular(financingMap, realEstate, registrationChargesIsFree);
                    }
                    else {
                        this.updateForm(
                            financingMap as unknown as Record<string, unknown>,
                            this.financingMapForm,
                            FINANCING_PLAN_FORM_VALIDATION_MAP['financingMapModel'],
                            financingMap, realEstate,
                        );

                        this.updateForm(
                            realEstate as unknown as Record<string, unknown>,
                            this.realEstateForm,
                            FINANCING_PLAN_FORM_VALIDATION_MAP['realEstateModel'],
                            financingMap, realEstate,
                        );
                    }
                }
            });
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
    }

    /**
     * Angular Hook nachdem die View geladen wurde
     */
    public ngAfterViewInit(): void {
        const p = this.elementRef.nativeElement.parentElement?.parentElement;

        if (!!p?.scrollTo) {
            p.scrollTo(0, 0);
        }
    }

    /**
     * wenn auf den Weiter Button gedrückt wird, wird zum nächsten Tab gewechselt
     * 
     * @returns {Promise} navigations Promise
     */
    public onNext(): Promise<boolean> {
        return this.router.navigate(['..', 'realestate'], { relativeTo: this.route });
    }

    /**
     * initialisiert das Formular
     *
     * @param {IFinancingMapModel} financingMap die Finanzierungsmappe zum initialisieren
     * @param {IRealEstateModel} realEstate die Liegenschaft zum initialisieren
     * @param {boolean} registrationChargesIsFree ist Gebührenbefreiung Pfandrecht aktiv
     */
    private initFormular(financingMap: IFinancingMapModel, realEstate: IRealEstateModel, registrationChargesIsFree: boolean): void {

        this.financingMapForm = this.createFinancingMapForm(financingMap, realEstate);
        this.realEstateForm = this.createRealEstateForm(financingMap, realEstate);

        const registrationChargesIsFreeCtr = this.fb.control(registrationChargesIsFree, { updateOn: 'change' });
        registrationChargesIsFreeCtr.valueChanges
            .pipe(takeUntil(this.viewLeft$))
            .subscribe(isFree => {
                if (!!this.financingMapForm) {
                    const ctr = this.financingMapForm.get('registrationChargesPercent');

                    if (ctr) {
                        ctr.patchValue(isFree ? 0 : ModelFactoryService.registrationFeeLienValue)
                    }
                }
            });

        this.form = this.fb.group({
            registrationChargesIsFree: registrationChargesIsFreeCtr,
            financingMap: this.financingMapForm,
            realEstate: this.realEstateForm,
        });
    }

    /**
     * erstellt eine Finanzierungs Form und befüllt diese mit den übergebene Daten
     *
     * @param {IFinancingMapModel} financingMap die Finanzierung zum initialisieren
     * @param {IRealEstateModel} realEstate das Liegenschaftsobjekt zum validieren
     * @returns {FormGroup} generierte FormGroup
     */
    private createFinancingMapForm(financingMap: IFinancingMapModel, realEstate: IRealEstateModel): FormGroup {
        const controlCfg: Record<string, unknown> = {
            id: [financingMap.id], // only for identification
            customerId: [financingMap.customerId],
        };

        const financingValidation = FINANCING_PLAN_FORM_VALIDATION_MAP['financingMapModel'];

        for (const fieldname in financingValidation) {
            if (fieldname in financingValidation) {
                let value = (financingMap as unknown as Record<string, unknown>)[fieldname] as number | string | boolean;

                if (fieldname === 'requestedLanguages') {
                    value = FinancingplanComponent.languageToBoolean(value as Language);
                }

                controlCfg[fieldname] = this.fb.control(HelperService.getValueOrNull(value), { updateOn: financingValidation[fieldname].changeByChange ? 'change' : 'blur' });
            }
        }

        const form = this.fb.group(controlCfg);

        this.notaryFeeForm = this.fb.group({
            value: controlCfg['notaryFeeInput'],
            isPercent: controlCfg['notaryFeeIsPercent'],
        });

        this.notaryFeeForm.valueChanges.subscribe(notaryFeeData => {
            form.patchValue({
                notaryFeeInput: notaryFeeData.value,
                notaryFeeIsPercent: notaryFeeData.isPercent,
            });
        });

        this.brokerageFeeForm = this.fb.group({
            value: controlCfg['brokerageFeeInput'],
            isPercent: controlCfg['brokerageFeeIsPercent'],
        });

        this.brokerageFeeForm.valueChanges.subscribe(brokerageFeeData => {
            form.patchValue({
                brokerageFeeInput: brokerageFeeData.value,
                brokerageFeeIsPercent: brokerageFeeData.isPercent,
            });
        });

        this.validation.setValidatorsForForm(financingValidation, form, undefined, financingMap, realEstate);

        form.valueChanges
            .pipe(
                takeUntil(this.viewLeft$),
                map(changes => {
                    // Es müssen die Validatoren mit den neuen Werten gesetzt werden, damit diese bei clearInvalidFormValues
                    // korrekt augewertet werden können
                    const newData = Object.assign({}, financingMap, changes);
                    this.validation.setValidatorsForForm(financingValidation, form, undefined, newData, realEstate);
                    return changes;
                }),
                clearInvalidFormValues(form),
            )
            .subscribe((change: IFinancingMapModel) => {

                if (HelperService.hasValue(change.requestedLanguages) && typeof (change.requestedLanguages) === 'boolean') {
                    change.requestedLanguages = FinancingplanComponent.booleanToLanguage(change.requestedLanguages);
                }

                this.dataService.updateFinancing(change).catch(e => { throw e; });
            });

        return form;
    }

    /**
     * erstellt eine Liegenschafts Form und befüllt diese mit den übergebene Daten
     *
     * @param {IFinancingMapModel} financingMap die Finanzierung zum validieren
     * @param {IFinancingMapModel} realEstate die Liegenschaft zum initialisieren
     * @returns {FormGroup} generierte FormGroup
     */
    private createRealEstateForm(financingMap: IFinancingMapModel, realEstate: IRealEstateModel): FormGroup {
        const controlCfg: Record<string, unknown> = {
            id: [realEstate.id], // only for identification
            financingMapId: [realEstate.financingMapId],
        };

        const realEstateValidation = FINANCING_PLAN_FORM_VALIDATION_MAP['realEstateModel'];

        for (const fieldname in realEstateValidation) {
            if (fieldname in realEstateValidation) {
                const value = (realEstate as unknown as Record<string, unknown>)[fieldname] as number | string | boolean;
                controlCfg[fieldname] = this.fb.control(HelperService.getValueOrNull(value), { updateOn: realEstateValidation[fieldname].changeByChange ? 'change' : 'blur' });
            }
        }

        const form = this.fb.group(controlCfg);

        this.validation.setValidatorsForForm(realEstateValidation, form, undefined, financingMap, realEstate);

        form.valueChanges
            .pipe(
                takeUntil(this.viewLeft$),
                clearInvalidFormValues(form),
            )
            .subscribe((change: IRealEstateModel) => {
                this.dataService.updateRealEstate(change).catch(e => { throw e; });
            });

        return form;
    }
    /**
     * aktualisiert ein Feld in einem spezifischen Formularteil
     *
     * @param {Record} model das Datenmodell
     * @param {FormGroup} form das formular des Feldes
     * @param {IValidationMapClass} validation die Feld Validierung
     * @param {any[]} args optionale Parameter für die Validierung
     */
    private updateForm(model?: Record<string, unknown>, form?: FormGroup, validation?: IValidationMapClass, ...args: unknown[]): void {
        if (!!model && !!form && !!validation) {
            for (const fieldname in validation) {
                if (fieldname in validation) {
                    const field = form.get(fieldname);
                    let value = model[fieldname] as number | string | boolean;

                    if (fieldname === 'requestedLanguages') {
                        value = FinancingplanComponent.languageToBoolean(value as Language);
                    }

                    if (!!field && HelperService.getValueOrNull(field.value) !== HelperService.getValueOrNull(value) && (field.valid || HelperService.hasValue(value))) {
                        field.patchValue(value, { onlySelf: true })
                    }
                }
            }

            this.validation.setValidatorsForForm(validation, form, undefined, ...args);
        }
    }

    /**
     * wandelt das forumalar boolean in das Language Enum um
     * 
     * @param {boolean} value der Wert aus der Formular Checkbox
     * @returns {Language} das gewandelte Language Enum
     */
    // eslint-disable-next-line @typescript-eslint/member-ordering
    private static booleanToLanguage(value?: boolean | null): Language {
        return !!value ? Language.English : Language.German;
    }

    /**
     * wandelt das forumalar boolean in das Language Enum um
     * 
     * @param {Language} value der Wert aus der Formular Checkbox
     * @returns {boolean} das gewandelte Language Enum
     */
    // eslint-disable-next-line @typescript-eslint/member-ordering
    private static languageToBoolean(value?: Language | null): boolean {
        return value === Language.English;
    }
}
