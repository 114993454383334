import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { EnumTranslationService } from '@ntag-ef/finprocess-enums';
import { HelperService, HubService } from '@ucba/sdk/services';
import { Observable } from 'rxjs';

import { environment } from '../environments/environment';

import { ApplicationState } from './statemanagement/states';

/**
 * AppComponent Klasse
 */
@Component({
    selector: 'cxad-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {

    @Select(ApplicationState.isLoggedIn)
    public isLoggedIn$: Observable<boolean> | undefined;

    /**
     * standard Konstruktor, initialisiert TranslateService
     * 
     * @param {TranslateService} translateService TranslateService injector
     * @param {EnumTranslationService} enumTranslationService EnumTranslationService injector
     * @param {HubService} hubService HubService injector
     */
    public constructor(
        private translateService: TranslateService,
        private enumTranslationService: EnumTranslationService,
        private hubService: HubService,
    ) {
        this.initTranslate();
        this.hubService.initialize();
    }

    /**
     * inititialisiert den Translation Service
     *
     * @returns {void}
     */
    private initTranslate(): void {
        const browserLang = this.translateService.getBrowserLang();
        const defaultLang = environment.defaultLang;

        this.translateService.setDefaultLang(defaultLang);
        this.enumTranslationService.setDefaultLang(defaultLang);

        // probieren die Browsersprache zu ermitteln und zu setzen
        if (HelperService.hasValue(browserLang) && browserLang !== '') {
            this.translateService.use(browserLang);
            this.enumTranslationService.use(browserLang);
        }
        else {
            // sonst festen Wert setzen
            this.translateService.use(defaultLang);
            this.enumTranslationService.use(defaultLang);
        }
    }

}
