import { SystemType } from '../enums';
import { ISODate } from '../types';

import { IBaseIdentifierModel } from './base-identifier.model';


/**
 * Daten die Nachricht
 */
export interface IMessageModel extends IBaseIdentifierModel {
    financingMapId: string,
    userId: string,
    created: ISODate,
    displayName: string,
    text: string,
    from: SystemType,
    to: SystemType,
    readFrom: SystemType,
    markedDeleted?: boolean;
}
