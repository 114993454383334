import { ITaskModel } from '../../models';

/**
 * Action welche alle Tasks updated
 */
export class SdkPatchTask {
    public static readonly type = '[SDK Task] PATCH';

    /**
     * Standard Konstruktor
     * 
     * @param {ITaskModel} payload zu patchende Task
     */
    public constructor(public payload: ITaskModel[]) { }
}

/**
 * Action welche eine neue Task hinzufügt, sollte sie bereits existieren wird add ignoriert
 */
 export class SdkAddTask {
    public static readonly type = '[SDK Task] ADD';

    /**
     * Standard Konstruktor
     * 
     * @param {ITaskModel} payload neue Task
     */
    public constructor(public payload: ITaskModel) { }
}

/**
 * Action welche eine spezifische Task updated
 */
export class SdkUpdateTask {
    public static readonly type = '[SDK Task] UPDATE';

    /**
     * Standard Konstruktor
     * 
     * @param {ITaskModel} payload zu patchende Task
     */
    public constructor(public payload: ITaskModel) { }
}

/**
 * Action welche eine Task entfernt, sollte sie nicht existieren wird delete ignoriert
 */
export class SdkDeleteTask {
    public static readonly type = '[SDK Task] DELETE';

    /**
     * Standard Konstruktor
     * 
     * @param {string} id id der zu löschenden Task
     */
    public constructor(public id: string) { }
}

