import { Clipboard } from '@angular/cdk/clipboard';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { GlobalSettings, HelperService } from '@ucba/sdk';
import { CustomerState } from '@ucba/sdk/statemanagement/states';
import { DeviceDetectorService } from 'ngx-device-detector';

import { APPINFO } from '../../../../../environments/app-info';
import { environment } from '../../../../../environments/environment';

/**
 * Komponente zum Darstellen des Impressums
 */
@Component({
    selector: 'cxad-imprint',
    templateUrl: './imprint.component.html',
    styleUrls: ['./imprint.component.scss'],

})
export class ImprintComponent implements OnInit {

    public version: string | undefined;
    public buildDate: string | undefined;
    public backendUrl: string | undefined;
    public environment = environment;
    public showMetaData = !environment.production || environment.backendUrl.includes('test');
    public browser = GlobalSettings.browser;


    /**
     * Standard Konstuktor
     *
     * @param { Clipboard } clipboard Clipboard injector
     * @param { DeviceDetectorService } deviceService DeviceDetectorService injector
     * @param { Location } location Location injector
     * @param { NotificationService } notification NotificationService injector
     * @param { Store } store Store injector
     * @param { TranslateService } translate TranslateService injector
     */
    public constructor(
        private clipboard: Clipboard,
        private deviceService: DeviceDetectorService,
        private location: Location,
        private notification: NotificationService,
        private store: Store,
        private translate: TranslateService,
    ) { }

    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {
        this.version = APPINFO.version;
        this.buildDate = APPINFO.buildDate;
        this.backendUrl = environment.backendUrl;
    }

    /**
     * navigiert zur vorherigen seite
     */
    public back(): void {
        this.location.back();
    }

    /**
     * fügt die Versionsinformationen zum Clipboard hinzu
     */
    public copySystemInfo() {
        const customerEmail = this.store.selectSnapshot(CustomerState.current)?.email;
        const infos = [
            'Anwendung: KundenInfo',
            `Version: ${this.version} (${this.buildDate})`,
            `User: ${customerEmail ?? 'nicht angemeldet'}`,
            `Gerätetyp: ${this.deviceService.deviceType}`, // 'desktop', 'mobile', 'tablet', 'unknown'
            `UserAgent: ${navigator.userAgent}`,
            `Browser: ${this.deviceService.browser} (${this.deviceService.browser_version})`,
            `Betriebssystem: ${this.deviceService.os} (${this.deviceService.os_version})`,
            `Gerätemodel: ${this.deviceService.device}`,
            `PlatformVersion: ${GlobalSettings.platformVersion ?? ''}`,
            `Backend: ${this.backendUrl}`,
            `Netzwerk: ${navigator.onLine ? 'verbunden' : 'offline'}`,
            `Zeitpunkt: ${new Date()}`,
            `Zeitpunkt nach ISO 8601: ${new Date().toISOString()}`,
        ];

        const copyText = infos.filter(x => !HelperService.isNullOrEmpty(x)).join('\n');

        this.clipboard.copy(copyText);
        this.notification.toast(this.translate.instant('shared.components.supportCard.copyMsg'));
    }
}
