import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { RoutingParams } from '@ucba/sdk/enums';
import { ICustomerModel } from '@ucba/sdk/models';
import { CustomerState } from '@ucba/sdk/statemanagement/states';
import { Observable } from 'rxjs';

/**
 * Komponente an den Kunden nach richtige Codeeingabe landet
 */
@Component({
    selector: 'cxad-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent {
    @Select(CustomerState.current)
    public customer$: Observable<ICustomerModel | null> | undefined;
    public routingParams = RoutingParams;

    /**
     * Standard Konstruktor
     *
     * @param {Router} router Router injector
     * @param {ActivatedRoute} route ActivatedRoute injector
     */
    public constructor(
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    /**
     * Navigiert zu einer URL
     *
     * @param {string} url URL zu der navigiert werden soll
     */
    public async navigate(url: string): Promise<void> {
        await this.router.navigate([url], { relativeTo: this.route });
    }
}
