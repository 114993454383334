export enum ErrorCode {

    /** The user is not found */
    UserNotFound = '100',

    /** The user is disabled */
    UserDisabled = '101',

    /** The company of the user is disabled */
    CompanyDisabled = '102',

    /** The email is not confirmed */
    EmailNotConfirmed = '103',

    /** The email or vp consultant number is already taken by another user */
    EmailOrVPConsultantNumberAlreadyTaken = '199',

    /** Put: Id is distinct from Id of the object */
    DistinctIds = '200',

    /** Sign Esis: debtor without signature object */
    MissingSignature = '500',

    /** No template defined */
    NoTemplateDefined = '505',

    /** if the posted entity already exist in database */
    EntityExist = '550',

    /** No changes allowed for the financing map */
    NoChangesAllowed = '800',

    /** Service call resulted in no success response */
    NoSuccessOnServiceCall = '900',
}
