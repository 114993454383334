import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ILiabilityModel } from '../../models';
import { BaseApiController } from '../base/base-api.controller';

/**
 * Controller welche alle Funkionalitäten der Liability Controller API bereithält
 */
@Injectable()
export class LiabilityController extends BaseApiController<ILiabilityModel> {

    protected override baseUrl = '/liability';

    //#region --- Get ---

    /**
     * Gibt alle Bestandskredite für die Finanzierung mit der übergebenen Id zurück.
     * 
     * @param {string} id id der Finanzierung
     * @returns {ILiabilityModel[]} alle Bestandskredite der Finanzierung
     */
    public getLiabilitiesByFinancing(id: string): Observable<ILiabilityModel[]> {
        return this.get<ILiabilityModel[]>(`${this.baseUrl}/GetLiabilitiesByFinancing/${id}`);
    }

    //#endregion

    //#region --- CRUD ---

    //#endregion
}
