import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { DocumentType } from '@ntag-ef/finprocess-enums/finadvisory';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { GlobalSettings } from '@ucba/sdk';
import { FinancingController } from '@ucba/sdk/controller';
import { DocumentArea, DocumentMetaType, EmailNotificationType, RoutingParams, SystemType } from '@ucba/sdk/enums';
import { IDocumentFileItem, IDocumentSectionArea } from '@ucba/sdk/interfaces';
import { IFinancingMapModel } from '@ucba/sdk/models';
import { DataService, FileService, HelperService, ValidationService } from '@ucba/sdk/services';
import { DebtorState, DocumentState, FileState, FinancingMapState, HouseholdState, RealEstateState, SignatureState } from '@ucba/sdk/statemanagement/states';
import { DOCUMENT_CARD_VALIDATION_MAP, DOCUMENT_CARD_VALIDATION_MAP_REGULAR, IDocumentValidation } from '@ucba/sdk/validations';
import { Subject, combineLatest, distinctUntilChanged, filter, firstValueFrom, map, take, takeUntil } from 'rxjs';

import { ICanComponentDeactivate } from '../../guards/can-deactivate.guard';

type IRegularDocumentSectionArea = IDocumentSectionArea & {
    required: boolean;
    description: string;
    metaType: DocumentMetaType
}

type ISignatureSectionArea = IDocumentSectionArea & {
    isValid: boolean;
    documentType: DocumentType,
    referenceId: string,
    pageName?: string,
}

type IRequiredSectionArea = IDocumentSectionArea & {
    documentType: DocumentType,
    referenceId: string,
    description: string;
}

/**
 * Komponente zum hochladen von Dokumenten
 */
@Component({
    selector: 'cxad-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
})

export class DocumentsComponent implements OnInit, OnDestroy, AfterViewInit, ICanComponentDeactivate {

    public accordionListAreas: IRegularDocumentSectionArea[] = [];
    public signatureListAreas: ISignatureSectionArea[] = [];
    public requiredDocumentListAreas: IRequiredSectionArea[] = [];

    public inputAcceptString = GlobalSettings.validUploadMimeTypes.join(',');

    /**
     * Getter ob noch ein Bereich geladen werden muss 
     * 
     * @returns {boolean} muss noch ein bereich geladen werden
     */
    public get isLoading(): boolean {
        return this.isDocumentLoading || this.isSignatureLoading || this.isRequiredDocumentsLoading;
    }

    public isDocumentLoading = true;
    public isSignatureLoading = true;
    public isRequiredDocumentsLoading = true;

    public dropzoneHovered = false;

    public dropzonesHovered: Record<DocumentMetaType, boolean> = {
        [DocumentMetaType.Identity]: false,
        [DocumentMetaType.Income]: false,
        [DocumentMetaType.Object]: false,
        [DocumentMetaType.Other]: false,
    }

    public dropzonesHoveredRequired: Record<DocumentType | number, boolean> = {
        [DocumentType.PurchaseAgreement]: false,
        [DocumentType.OfferRequiredInsurance]: false,
        [DocumentType.EmployerApproval]: false,
    }

    /** is the section required Document visible */
    public isRequiredDocumentsVisible: boolean | undefined;

    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();

    /** is financing in any closed status */
    public closed: boolean | undefined;

    /** is financing in status Open */
    public open: boolean | undefined;

    /** is financing readonly */
    public readonly: boolean | undefined;

    /** wurde die DSE noch nicht unterschrieben oder hochgeladen */
    public notSign: boolean | undefined;

    /** is there any file wich must send to VPC */
    public mustSendToVPC = false;

    private excludeRoute = false;

    /** is there any file wich must send to VPC */
    public missingHouseholdCalculationFiles: boolean | undefined;

    /** is financing in status househouldExists */
    public housholdCalculationStatus: boolean | undefined;

    // Dient dazu, dass das Arrcoridon sich nicht einklappt wenn ein Element gelöscht wird
    // eslint-disable-next-line class-methods-use-this
    public identifyer = (index: number, item: IDocumentSectionArea): string => item.areaId;

    /**
     * Standard Konstruktor
     *
     * @param { Store } store Store injector
     * @param { TranslateService } translate TranslateService injector
     * @param { NotificationService } notificationService NotificationService injector
     * @param {DataService} dataService DataService injector
     * @param { FileService } fileService FileService injector
     * @param { FinancingController } financingController FinancingController injector
     * @param { DomSanitizer } sanitizer sanitizer injector
     * @param { WaiterService } waiter WaiterService injector
     * @param { ValidationService } validation ValidationService injector
     * @param { Router } router Router injector
     * @param { ActivatedRoute } route ActivatedRoute injector
     * @param { ElementRef } elementRef ElementRef injector
     */
    public constructor(
        private store: Store,
        private translate: TranslateService,
        private notificationService: NotificationService,
        private dataService: DataService,
        private fileService: FileService,
        private financingController: FinancingController,
        private sanitizer: DomSanitizer,
        private waiter: WaiterService,
        public validation: ValidationService,
        private router: Router,
        private route: ActivatedRoute,
        private elementRef: ElementRef<HTMLElement>,
    ) { }

    /**
     * Zeigt ein Bestätigungsdialog an, wenn noch Dokumente nachgereicht werden müssen, bevor die Route gewechselt wird
     * -Route-Ausnahme: 'document-viewer'
     *
     * @returns {Promise<boolean>} ob die Route verlassen werden kann
     */
    public async canDeactivate(): Promise<boolean> {
        if (this.mustSendToVPC && !this.excludeRoute) {
            const role = await firstValueFrom(this.notificationService.confirm(
                this.translate.instant('components.financingTab.documents.resubmit'),
                this.translate.instant('components.financingTab.documents.resubmitWarningTooltip'),
                this.translate.instant('global.buttons.next'), this.translate.instant('global.buttons.cancel')));
            return role === 'submit';
        }
        return true;
    }

    /**
     * toggle dropStyling on dragleave or enter
     * 
     * @param {DocumentMetaType} sectionMetaType type of section
     * @param {boolean} isLeaving whether or not its dragleave or dragexit
     */
    public toggleHoverZone(sectionMetaType: DocumentMetaType, isLeaving: boolean): void {
        this.dropzonesHovered[sectionMetaType] = !isLeaving;
    }

    /**
     * toggle dropStyling on dragleave or enter
     * 
     * @param {DocumentType} sectionMetaType type of section
     * @param {boolean} isLeaving whether or not its dragleave or dragexit
     */
    public toggleHoverZoneRequired(sectionMetaType: DocumentType, isLeaving: boolean): void {
        this.dropzonesHoveredRequired[sectionMetaType] = !isLeaving;
    }

    /**
     * Angular Hook zum initialisieren
     *
     */
    public ngOnInit(): void {

        this.store.select(FinancingMapState.hasSentFileToVPC)
            .pipe(
                takeUntil(this.viewLeft$),
                distinctUntilChanged(),
            )
            .subscribe(hasSentFiles => {
                this.mustSendToVPC = hasSentFiles;
            });

        this.store.select(FinancingMapState.current)
            .pipe(takeUntil(this.viewLeft$))
            .subscribe(financingMap => {
                if (!!financingMap) {
                    this.open = FinancingMapState.staticIsFinancingMapEditable(financingMap.status);
                    this.readonly = !FinancingMapState.staticIsFinancingMapEditable(financingMap.status);
                    this.closed = FinancingMapState.staticIsFinancingMapClosed(financingMap.status);
                    this.housholdCalculationStatus = FinancingMapState.staticIsFinancingMapInStatusHouseholdCalculationExist(financingMap.status);
                }
            });

        this.initDocumentObservable();
        this.initSignatureObservable();
        this.initRequiredObservable();

        this.store.select(DocumentState.missingHouseholdDocuments)
            .pipe(
                takeUntil(this.viewLeft$),
                distinctUntilChanged(),
            )
            .subscribe(missingFiles => {
                this.missingHouseholdCalculationFiles = missingFiles.length > 0;
            });

        this.router.events.pipe(
            takeUntil(this.viewLeft$),
            filter(ev => ev instanceof NavigationStart),
            map(ev => (ev as NavigationStart).url),
        ).subscribe(url => {
            this.excludeRoute = url.startsWith('/document-viewer');
        });
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
    }

    /**
     * Angular Hook nachdem die View geladen wurde
     */
    public ngAfterViewInit(): void {
        const p = this.elementRef.nativeElement.parentElement?.parentElement;

        if (!!p?.scrollTo) {
            p.scrollTo(0, 0);
        }
    }

    /**
     * verhindert das Navigieren, wenn auf den Upload Button geklickt wird
     * 
     * @param {Event} event event des Buttons
     */
    // eslint-disable-next-line class-methods-use-this
    public preventNavigate(event: Event): void {
        event.stopPropagation();
    }

    /**
     * Prüft ob das Dokumente Dateien enthält oder wechselt auf die Unterschirftenseite
     * 
     * @param {ISignatureSectionArea} section Unterschriften
     */
    public async onSignDocumentClicked(section: ISignatureSectionArea): Promise<void> {
        if (section.hasContent) {
            section.isOpen = !section.isOpen;
        }
        else if (section.items.length <= 0 && section.pageName) {
            await this.router.navigate([`../${RoutingParams.PRIVACY_ROUTE}`], { relativeTo: this.route });
        }
    }

    /**
     * Sendet Dokumente nachträglich an FinProcess
     * 
     *  @returns {Promise<boolean>} true wenn nachrichten gesendet werden
     */
    public sendDocumentsToFinProcess(): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this.notificationService.confirmOkCancel(
                this.translate.instant('components.financingTab.documents.sendToVPCTitle'),
                this.translate.instant('components.financingTab.documents.sendToVPCText'))
                .pipe(take(1)).subscribe(role => {
                    if (role === 'submit') {
                        const financingMapId = this.store.selectSnapshot(FinancingMapState.currentId);

                        if (!!financingMapId) {
                            this.waiter.show();

                            this.financingController.sendDocumentsToFinProcess(financingMapId)
                                .pipe(take(1))
                                .subscribe({
                                    next: files => {
                                        this.dataService.updateFilesLocal(files);
                                        this.waiter.hide();
                                        resolve(true);
                                    },
                                    error: e => {
                                        this.waiter.hide();
                                        resolve(false);
                                        throw e;
                                    },
                                })

                        }
                    }
                    else {
                        resolve(false);
                    }
                });
        });
    }

    /**
     * Hochladen von Dokumenten
     * 
     * @param {Event} event event
     * @param {DocumentArea} area Aus welchem Bereich kam der drop
     * @param {DocumentMetaType} type DocumentMetaType des bereichs
     * @param {DocumentMetaType} referenceId die Id der Reference Entität
     * @returns {Promise} void Promise
     */
    public async newDocuments(event: Event, area: DocumentArea, type: DocumentMetaType | DocumentType, referenceId?: string): Promise<void> {
        event.stopImmediatePropagation();
        const target = ((event as InputEvent).target as HTMLInputElement);
        const fileList = target.files;

        if (!!fileList && fileList.length > 0) {
            if (await this.checkForSignatures(area)) {
                await this.handleUpload(fileList, area, type, referenceId);
                target.value = '';
            }
        }

        return Promise.resolve();
    }

    /**
     * Wenn Dokumente Über den Drag & Drop Bereich sind
     * 
     * @param {Event} event event
     */
    // eslint-disable-next-line class-methods-use-this
    public onDragOver(event: Event): void {
        event.preventDefault();
    }

    /**
     * Hochladen von Dokumenten mittels Drag and Drop
     * 
     * @param {DragEvent} event DragEvent
     * @param {DocumentArea} area Aus welchem Bereich kam der drop
     * @param {DocumentMetaType} type DocumentMetaType des bereichs
     * @param {DocumentMetaType} referenceId die Id der Reference Entität
     */
    public async onDropSuccess(event: DragEvent, area: DocumentArea, type: DocumentMetaType | DocumentType, referenceId?: string): Promise<void> {
        event.preventDefault();
        if (event.dataTransfer !== null) {
            const fileList = event.dataTransfer.files;
            if (!!fileList && fileList.length > 0) {
                if (Array.from(fileList).every(f => GlobalSettings.validUploadMimeTypes.includes(f.type))) {
                    if (await this.checkForSignatures(area)) {
                        await this.handleUpload(fileList, area, type, referenceId);
                    }
                }
                else {
                    this.notificationService.alert(
                        this.translate.instant('components.financingTab.documents.wrongFormatTitle'),
                        this.translate.instant('components.financingTab.documents.wrongFormatMsg'),
                    );
                }
            }
        }
        if (area === DocumentArea.RequiredDocuments) {
            this.dropzonesHoveredRequired[type as DocumentType] = false;
        }
        else {
            this.dropzonesHovered[type as DocumentMetaType] = false;
        }
    }

    /**
     * wenn die Schaltfläche "Löschen" angeklickt wurde
     * 
     * @param {Event} event event des Buttons
     * @param {string} documentId id des zu löschenden Dokuments
     * @param {string} fileId id des zu löschenden Files
     */
    public deleteFile(event: Event, documentId?: string, fileId?: string): void {
        event.stopImmediatePropagation();

        if (!!documentId && !!fileId) {
            this.notificationService.confirmOkCancel(
                this.translate.instant('components.financingTab.documents.titleDeleteDocument'),
                this.translate.instant('components.financingTab.documents.confirmDeleteDocument'),
            ).pipe(take(1)).subscribe(role => {
                if (role === 'submit') {
                    this.dataService.deleteDocument(documentId).catch(e => { throw e; });
                }
            });
        }
    }

    /**
     * öffnet den DocumentViewer mit der entsprechenden Datei
     * 
     * @param {string} fileId id der Datei welche geöffnet werden soll
     * @param {string } title Name der Datei
     * @returns {Promise<void>} Void Promise
     */
    public showFile(fileId: string, title: string): Promise<void> {
        const file = this.store.selectSnapshot(FileState.currentById)(fileId)
        return !!file ?
            this.fileService.showFile(file.id, file.name, file.mimeType, title) :
            Promise.reject(new Error('file not found'));
    }

    /**
     * initialisiert die Observable für den Unterlagen bereich
     */
    private initDocumentObservable() {
        const allValidatinos = DOCUMENT_CARD_VALIDATION_MAP_REGULAR;

        const metaTypes: Array<{ type: DocumentMetaType, label: string, description: string, validationsOfType: IDocumentValidation[] }> = [
            {
                type: DocumentMetaType.Identity,
                label: this.translate.instant('components.financingTab.documents.documentIdentyHeader'),
                description: this.sanitizer.sanitize(SecurityContext.HTML,
                    (this.translate.instant(this.translate.instant('components.financingTab.documents.documentIdentyDescription')))) ?? '',
                validationsOfType: allValidatinos.filter(({ metaType }) => metaType === DocumentMetaType.Identity),
            },
            {
                type: DocumentMetaType.Income,
                label: this.translate.instant('components.financingTab.documents.documentIncomeHeader'),
                description: this.sanitizer.sanitize(SecurityContext.HTML,
                    (this.translate.instant(this.translate.instant('components.financingTab.documents.documentIncomeDescription')))) ?? '',
                validationsOfType: allValidatinos.filter(({ metaType }) => metaType === DocumentMetaType.Income),
            },
            {
                type: DocumentMetaType.Object,
                label: this.translate.instant('components.financingTab.documents.documentObjectHeader'),
                description: this.sanitizer.sanitize(SecurityContext.HTML,
                    (this.translate.instant(this.translate.instant('components.financingTab.documents.documentObjectDescription')))) ?? '',
                validationsOfType: allValidatinos.filter(({ metaType }) => metaType === DocumentMetaType.Object),
            },
            {
                type: DocumentMetaType.Other,
                label: this.translate.instant('components.financingTab.documents.documentOtherHeader'),
                description: this.sanitizer.sanitize(SecurityContext.HTML,
                    (this.translate.instant(this.translate.instant('components.financingTab.documents.documentOtherDescription')))) ?? '',
                validationsOfType: allValidatinos.filter(({ metaType }) => metaType === DocumentMetaType.Other),
            },
        ]

        combineLatest([
            this.store.select(FinancingMapState.currentId),
            this.store.select(DocumentState.current),
            this.store.select(FileState.current),
        ])
            .pipe(
                filter(([currentId]) => !!currentId),
                map(([currentId, documents, files]) => {
                    const areas = metaTypes.map<IRegularDocumentSectionArea>(data => {
                        const documentsOfType = documents.filter(({ metaType, type }) =>
                            metaType === data.type || (!HelperService.hasValue(metaType) && data.validationsOfType.some(val => val.type === type)));

                        const items = documentsOfType.reduce<IDocumentFileItem[]>((acc, curr) => {

                            const documentFiles = files
                                .filter(({ documentId, isSigned }) => documentId === curr.id && !isSigned)
                                .map<IDocumentFileItem>(file => ({
                                    name: `${file.name}${file.extension}`,
                                    documentType: curr.type,
                                    documentName: curr.name ?? '',
                                    documentId: curr.id,
                                    fileId: file.id,
                                    disableActionButtons: !!file.sentToVPC,
                                }));

                            return acc.concat(documentFiles);
                        }, []);

                        return {
                            name: data.label,
                            items,
                            hasContent: items.length > 0,
                            fileCount: items.length,
                            areaId: `${HelperService.toHTMLid(currentId)}meta${data.type}`, // Zur Unterscheidung zwischen DokuemtenType und DokumentenMetaType 
                            isHidden: items.length === 0,
                            area: DocumentArea.Unsorted,
                            referenceObject: {},
                            required: data.type !== DocumentMetaType.Other,
                            description: data.description,
                            metaType: data.type,
                        }
                    });

                    return areas;
                }),
            )
            .subscribe(documentList => {
                if (Array.isArray(this.accordionListAreas) && this.accordionListAreas.length > 0) {
                    if (this.accordionListAreas.length !== documentList.length) {
                        // Wenn es neue Bereiche gibt, wird der komplette bereich neu gesetzt
                        this.accordionListAreas = documentList;
                    }
                    else {
                        DocumentsComponent.checkForChanges(this.accordionListAreas, documentList);
                    }
                }
                else {
                    this.accordionListAreas = documentList;
                    this.isDocumentLoading = false;
                }
            });
    }

    /**
     * initialisiert die Observable für den Unterschriften bereich
     */
    private initSignatureObservable() {
        combineLatest([
            this.store.select(FinancingMapState.currentId),
            this.store.select(DebtorState.current),
            this.store.select(SignatureState.current),
            this.store.select(DocumentState.current),
            this.store.select(FileState.filesByDocumentIds),
        ])
            .pipe(
                takeUntil(this.viewLeft$),
                filter(([currentId, debtors]) => !!currentId && debtors.length > 0),
                map(([currentId, debtors, signatures, documents, filterFn]) => {
                    const signatureValidationMap = DOCUMENT_CARD_VALIDATION_MAP['signature'];
                    const householdDocumentsValidationMap = DOCUMENT_CARD_VALIDATION_MAP['householdDocuments'];

                    // reguläre Unterschriftsdokumente welche digital unterschrieben werden können
                    const regularSignatures = signatureValidationMap.reduce<ISignatureSectionArea[]>((resultAreas, validation) => {

                        if (!!currentId) {
                            const documentsOfType = documents.filter(({ financingMapId, type }) => financingMapId === currentId && type === validation.type);
                            const filesOfFinancing = filterFn(documentsOfType.map(({ id }) => id));

                            const fileItemsPerDocument = documentsOfType.reduce<IDocumentFileItem[]>((resultFiles, document) => {
                                const filesOfDocument = filesOfFinancing.filter(({ documentId }) => documentId === document.id);

                                const fileItems = filesOfDocument.map<IDocumentFileItem>(file => ({
                                    name: `${file.name}${file.extension}`,
                                    documentType: document.type,
                                    documentName: document.name ?? '',
                                    documentId: document.id,
                                    fileId: file.id,
                                    isRequired: validation.required(),
                                    disableActionButtons: !!file.sentToVPC,
                                }));

                                return resultFiles.concat(fileItems);
                            }, []);

                            const signatureOfType = signatures.filter(({ financingMapId, type }) => !!financingMapId && type === validation.type && financingMapId === currentId);
                            const isValid = (fileItemsPerDocument.length > 0 || signatureOfType.length >= debtors.length);
                            this.notSign = !isValid;

                            const resultArea: ISignatureSectionArea = {
                                name: DocumentType.translate(validation.type) ?? '',
                                items: fileItemsPerDocument,
                                hasContent: fileItemsPerDocument.length > 0, // inkl Platzhalter
                                fileCount: fileItemsPerDocument.filter(({ fileId }) => !!fileId).length, // exklusive Platzhalter
                                hasRequired: fileItemsPerDocument.some(({ isRequired }) => isRequired),
                                areaId: HelperService.toHTMLid(currentId) + validation.type,
                                area: DocumentArea.Signatures,
                                referenceObject: {
                                    'financingMapId': currentId,
                                },
                                isValid,
                                documentType: validation.type,
                                referenceId: currentId,
                                pageName: !!validation.pageName ? validation.pageName() : undefined,
                            };

                            return [...resultAreas, resultArea];
                        }
                        else {
                            return resultAreas;
                        }

                    }, []);

                    // Unterschriftsdokumente der Haushaltsrechnung welche mit dem Rechenbeispiel unterschrieben werden
                    let householdSignatures: ISignatureSectionArea[] = [];

                    if (!this.open) {
                        const signDocuments = documents.filter(({ type }) => GlobalSettings.documentTypesHousehold.includes(type));

                        // Für jeden Haushalt, Kreditnehmer und Typ wird eine Area angelegt
                        for (const doc of signDocuments) {
                            const validation = householdDocumentsValidationMap.find(({ type }) => type === doc.type);
                            const documentRefId = !!validation ? doc[validation.referenceProperty] : undefined;

                            if (!!validation && !!documentRefId) {
                                const filesOfDocument = filterFn([doc.id], true);

                                const fileItemsPerDocument = filesOfDocument.map<IDocumentFileItem>(file => ({
                                    name: `${file.name}${file.extension}`,
                                    documentType: doc.type,
                                    documentName: doc.name ?? '',
                                    documentId: doc.id,
                                    fileId: file.id,
                                    isRequired: validation.required(),
                                    disableActionButtons: !!file.sentToVPC,
                                }));

                                const existingArea = householdSignatures
                                    .find(({ referenceId: refId, documentType }) => refId === documentRefId && documentType === doc.type);

                                if (!existingArea) {
                                    const referenceObject: Record<string, string> = {};
                                    referenceObject[validation.referenceProperty] = documentRefId;

                                    const resultArea: ISignatureSectionArea = {
                                        name: DocumentType.translate(validation.type) ?? '',
                                        items: fileItemsPerDocument,
                                        hasContent: fileItemsPerDocument.length > 0, // inkl Platzhalter
                                        fileCount: fileItemsPerDocument.filter(({ fileId }) => !!fileId).length, // exklusive Platzhalter
                                        hasRequired: fileItemsPerDocument.some(({ isRequired }) => isRequired),
                                        areaId: HelperService.toHTMLid(documentRefId) + validation.type,
                                        area: DocumentArea.HouseholdDocuments,
                                        referenceObject,
                                        isValid: fileItemsPerDocument.length > 0,
                                        documentType: validation.type,
                                        referenceId: documentRefId,
                                    };

                                    householdSignatures.push(resultArea);
                                }
                                else {
                                    existingArea.items = existingArea.items.concat(fileItemsPerDocument);
                                    existingArea.hasContent = existingArea.items.length > 0; // inkl Platzhalter
                                    existingArea.fileCount = existingArea.items.filter(({ fileId }) => !!fileId).length; // exklusive Platzhalter
                                    existingArea.hasRequired = existingArea.items.some(({ isRequired }) => isRequired);
                                    existingArea.isValid = existingArea.items.length > 0;
                                }
                            }
                        }

                        // sortierung der Haushalte wie in der Finanzierung
                        const householdIds = this.store.selectSnapshot(HouseholdState.currentIds);
                        const result: ISignatureSectionArea[] = [];

                        for (let i = 0; i < householdIds.length; i++) {
                            const houseHoldItems = householdSignatures.filter(({ referenceId }) => referenceId === householdIds[i]);

                            const balanceSection = houseHoldItems.find(({ documentType }) => documentType === DocumentType.HouseholdBalanceSignature);
                            if (!!balanceSection) {
                                balanceSection.name = `${i + 1}. Haushalt: ${balanceSection.name}`
                                result.push(balanceSection);
                            }

                            const alimenteSection = houseHoldItems.find(({ documentType }) => documentType === DocumentType.CompositionOfOtherIncomeSignature);
                            if (!!alimenteSection) {
                                alimenteSection.name = `${i + 1}. Haushalt: ${alimenteSection.name}`
                                result.push(alimenteSection);
                            }

                            const selfDisclosureSection = houseHoldItems.find(({ documentType }) => documentType === DocumentType.SelfDisclosure);
                            if (!!selfDisclosureSection) {
                                selfDisclosureSection.name = `${i + 1}. Haushalt: ${selfDisclosureSection.name}`
                                result.push(selfDisclosureSection);
                            }

                            const additionalSheetSection = houseHoldItems.find(({ documentType }) => documentType === DocumentType.AdditionalSheetSignature);
                            if (!!additionalSheetSection) {
                                additionalSheetSection.name = `${i + 1}. Haushalt: ${additionalSheetSection.name}`
                                result.push(additionalSheetSection);
                            }

                            const debtorsOfHousehold = debtors.filter(({ householdId }) => householdId === householdIds[i]);
                            const debtorItems = householdSignatures.filter(({ referenceId, documentType }) =>
                                documentType === DocumentType.SelfDisclosure && debtorsOfHousehold.some(({ id }) => id === referenceId));

                            for (const debtorItem of debtorItems) {
                                const debIdx = debtors.findIndex(({ id }) => id === debtorItem.referenceId);
                                const debtorLabel = HelperService.calcDebitorLabel(debtors[debIdx], debIdx, this.translate.instant('modules.customer.components.paperTab.submission.document.titlePerson'));
                                debtorItem.name = `${debtorLabel}: ${debtorItem.name}`;
                                result.push(debtorItem);
                            }
                        }

                        householdSignatures = result;
                    }

                    return [
                        ...regularSignatures,
                        ...householdSignatures,
                    ];
                }),
            )
            .subscribe(signatureList => {
                if (Array.isArray(this.signatureListAreas) && this.signatureListAreas.length > 0) {
                    if (this.signatureListAreas.length !== signatureList.length) {
                        // Wenn es neue Bereiche gibt, wird der komplette bereich neu gesetzt
                        this.signatureListAreas = signatureList;
                    }
                    else {
                        DocumentsComponent.checkForChanges(this.signatureListAreas, signatureList);
                    }
                }
                else {
                    this.signatureListAreas = signatureList;
                    this.isSignatureLoading = false;
                }
            });
    }

    /**
     * initialisiert die Observable für den Unterschriften bereich
     */
    private initRequiredObservable() {
        combineLatest([
            this.store.select(FinancingMapState.current),
            this.store.select(RealEstateState.currentFinancingObject),
            this.store.select(DocumentState.current),
            this.store.select(FileState.filesByDocumentIds),
        ])
            .pipe(
                takeUntil(this.viewLeft$),
                filter(([financingMap, realEstate]) => !!financingMap && !!realEstate),
                map(([financingMap, realEstate, documents, filterFn]) => {
                    const validationMap = DOCUMENT_CARD_VALIDATION_MAP['requiredDocuments'];

                    this.isRequiredDocumentsVisible = !!financingMap?.requiredDocuments && financingMap.requiredDocuments.length > 0;

                    return validationMap.reduce<IRequiredSectionArea[]>((resultAreas, validation) => {

                        if (!!financingMap && !!realEstate && !!financingMap.requiredDocuments && financingMap.requiredDocuments.includes(validation.type)) {
                            const referenceObject: Record<string, string> = {
                                'financingMapId': financingMap.id,
                                'realEstateId': realEstate.id,
                            };

                            const documentsOfType = documents.filter(({ financingMapId, realEstateId, type }) => type === validation.type && (financingMapId === financingMap.id || realEstateId === realEstate.id));
                            const filesOfFinancing = filterFn(documentsOfType.map(({ id }) => id));

                            const fileItemsPerDocument = documentsOfType.reduce<IDocumentFileItem[]>((resultFiles, document) => {
                                // nur Unterschriebene Files werden im nachzureichenden Bereich dargestellt
                                const filesOfDocument = filesOfFinancing.filter(({ documentId, isSigned }) => documentId === document.id && isSigned);

                                const fileItems = filesOfDocument.map<IDocumentFileItem>(file => ({
                                    name: `${file.name}${file.extension}`,
                                    documentType: document.type,
                                    documentName: document.name ?? '',
                                    documentId: document.id,
                                    fileId: file.id,
                                    isRequired: validation.required(financingMap),
                                    disableActionButtons: !!file.sentToVPC,
                                }));

                                return resultFiles.concat(fileItems);
                            }, []);

                            // TODO die description nicht abhängig der Übersetzung machen

                            const resultArea: IRequiredSectionArea = {
                                name: DocumentType.translate(validation.type) ?? '',
                                items: fileItemsPerDocument,
                                hasContent: fileItemsPerDocument.length > 0, // inkl Platzhalter
                                fileCount: fileItemsPerDocument.filter(({ fileId }) => !!fileId).length, // exklusive Platzhalter
                                hasRequired: fileItemsPerDocument.some(({ isRequired }) => isRequired),
                                areaId: HelperService.toHTMLid(financingMap.id) + validation.type,
                                area: DocumentArea.RequiredDocuments,
                                referenceObject,
                                documentType: validation.type,
                                referenceId: referenceObject[validation.referenceProperty],
                                description: this.sanitizer.sanitize(SecurityContext.HTML, this.translate.instant(`components.financingTab.documents.documentDescriptions.${validation.type}`)) ?? '',
                            };

                            return [...resultAreas, resultArea];
                        }
                        else {
                            return resultAreas;
                        }

                    }, []);
                }),
            )
            .subscribe(requiredList => {
                if (Array.isArray(this.requiredDocumentListAreas) && this.requiredDocumentListAreas.length > 0) {
                    if (this.requiredDocumentListAreas.length !== requiredList.length) {
                        // Wenn es neue Bereiche gibt, wird der komplette bereich neu gesetzt
                        this.requiredDocumentListAreas = requiredList;
                    }
                    else {
                        DocumentsComponent.checkForChanges(this.requiredDocumentListAreas, requiredList);
                    }
                }
                else {
                    this.requiredDocumentListAreas = requiredList;
                    this.isRequiredDocumentsLoading = false;
                }
            });
    }

    /**
     * vergleicht zwei listen und setzt bei änderung die werte
     * 
     * @param {IDocumentSectionArea[]} localList aktuelle zu ändernde Liste
     * @param {IDocumentSectionArea[]} newList neue Liste
     */
    // eslint-disable-next-line @typescript-eslint/member-ordering
    private static checkForChanges(localList: IDocumentSectionArea[], newList: IDocumentSectionArea[]) {
        // update der aktuellen Liste
        // wird nicht direkt gesetzt, da sonst alles wieder zugeklappt wird

        if (localList.length !== newList.length) {
            // Wenn es neue Bereiche gibt (z.B. neuer KN), wird der komplette bereich neu gesetzt
            localList = newList;
        }
        else {
            localList.forEach((element, idx) => {
                const updateArea = newList.find(({ areaId }) => areaId === element.areaId);
                if (!!updateArea) {

                    // Nur das Updaten was sich wirklich geändert hat, um unnötige Rendertrigger zu vermeiden
                    if (localList[idx].isHidden !== updateArea.isHidden) {
                        localList[idx].isHidden = updateArea.isHidden;
                    }

                    if (localList[idx].fileCount !== updateArea.fileCount ||
                        localList[idx].items.length !== updateArea.items.length) {
                        localList[idx].items = updateArea.items;
                    }
                    else {
                        updateArea.items.forEach((item, itemIdx) => {
                            if (item.name !== localList[idx].items[itemIdx].name) {
                                localList[idx].items[itemIdx].name = item.name;
                                localList[idx].items[itemIdx].documentName = item.documentName;
                            }

                            if (localList[idx].items[itemIdx].disableActionButtons !== item.disableActionButtons) {
                                localList[idx].items[itemIdx].disableActionButtons = item.disableActionButtons;
                            }
                        });
                    }

                    if (localList[idx].name !== updateArea.name) {
                        localList[idx].name = updateArea.name;
                    }

                    if (localList[idx].hasContent !== updateArea.hasContent) {
                        localList[idx].hasContent = updateArea.hasContent;
                    }

                    if (localList[idx].fileCount !== updateArea.fileCount) {
                        localList[idx].fileCount = updateArea.fileCount;
                    }

                    if (localList[idx].hasRequired !== updateArea.hasRequired) {
                        localList[idx].hasRequired = updateArea.hasRequired;
                    }

                    if ([DocumentArea.Signatures, DocumentArea.HouseholdDocuments].includes(updateArea.area)) {
                        const localAsSign = localList as ISignatureSectionArea[];
                        const updateAsSign = updateArea as ISignatureSectionArea;

                        if (localAsSign[idx].isValid !== updateAsSign.isValid) {
                            localAsSign[idx].isValid = updateAsSign.isValid;
                        }
                    }
                }
            });
        }
    }

    /**
     * bereitet die Daten für den Uplaod auf und gibt sie an den FileService
     * 
     * @param  {FileList} fileList zu uploadende Files
     * @param {DocumentArea} area Aus welchem Bereich kam der drop
     * @param {DocumentMetaType} type DocumentMetaType des bereichs
     * @param {DocumentMetaType} referenceId die Id der Reference Entität
     * @returns {Promise} void Promise
     */
    private handleUpload(fileList: FileList, area: DocumentArea, type: DocumentMetaType | DocumentType, referenceId?: string): Promise<void> {

        const financingMapId = this.store.selectSnapshot(FinancingMapState.currentId);

        if (!!financingMapId) {

            let documentType: DocumentType | undefined;
            let metaType: DocumentMetaType | undefined;

            if ([
                DocumentArea.Signatures,
                DocumentArea.RequiredDocuments,
                DocumentArea.HouseholdDocuments,
            ].includes(area)) {
                // Direkter Upload zu einem typ
                documentType = type as DocumentType;
            }
            else {
                // Allgemeiner Upload in eins der Bereiche
                metaType = type as DocumentMetaType;

                if (!this.open && !this.closed) {
                    // Wenn die Finanzierung schon eingereicht ist
                    // wird es direkt Sonstiges zugeordnet um es direkt an FinProcess zu übermitteln
                    documentType = DocumentType.Other;
                }
            }

            return this.fileService.handleDirectUpload({
                fileList,
                area,
                referenceId: referenceId ?? financingMapId,
                metaType,
                documentType,
            });
        }
        else {
            return Promise.resolve();
        }
    }

    /**
     * öffnet für Unterschriftsdokumente ein Confirm um abzufragen ob alle Unterschriften gesetzt wurden
     * 
     * @param  {DocumentArea} area Upload Bereich
     * @returns {Promise<boolean>} true wenn nicht unterschriftenbereich oder Confirm bestätigt
     */
    private checkForSignatures(area: DocumentArea): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            if ([DocumentArea.Signatures, DocumentArea.HouseholdDocuments].includes(area)) {
                this.notificationService.confirm(
                    this.translate.instant('components.financingTab.documents.allSignaturesPresentTitle'),
                    this.translate.instant('components.financingTab.documents.allSignaturesPresentMsg'),
                    'Ok', 'Abbrechen').pipe(take(1)).subscribe(role => {
                        if ((role as unknown as string) === 'submit') {
                            resolve(true);
                        }
                        else {
                            resolve(false);
                        }
                    });
            }
            else {
                resolve(true);
            }
        });
    }

    /**
     * zeigt ein Dialog an, dass Daten an die Finadvisory geschickt wurden und erstellt eine Aktivität
     *
     */
    public async createActivity(): Promise<void> {
        const financingMap = this.store.selectSnapshot(FinancingMapState.current);
        if (!!financingMap) {
            if (this.readonly && !this.closed) {
                if (await this.sendDocumentsToFinProcess()) {
                    this.dataService.createActivity(financingMap?.id, this.translate.instant('components.financingTab.documents.activityDescription'), SystemType.Cxadvisory, EmailNotificationType.CustomerSubmit).then(() => {
                        this.notificationService.toast(this.translate.instant('components.financingTab.documents.sendToFinadvisorySuccess'));
                    }).catch(e => { throw e; })
                }
            }
            else if (!this.closed) {
                this.dataService.createActivity(financingMap?.id, this.translate.instant('components.financingTab.documents.activityDescription'), SystemType.Cxadvisory, EmailNotificationType.CustomerSubmit).then(() => {
                    this.notificationService.toast(this.translate.instant('components.financingTab.documents.sendToFinadvisorySuccess'));
                }).catch(e => { throw e; })
            }
        }
    }

    /**
     * Sendet die Haushaltsdokuemnte und nachträglich hochgeladenen Dokumente an FinProcess
     * 
     *  @returns {Promise<boolean>} true wenn nachrichten gesendet werden
     */
    public sendHouseholdDocuments(): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this.notificationService.confirmOkCancel(
                this.translate.instant('components.financingTab.documents.sendToVPCTitle'),
                this.translate.instant('components.financingTab.documents.sendToVPCText'))
                .pipe(take(1)).subscribe(role => {
                    if (role === 'submit') {
                        const financingMapId = this.store.selectSnapshot(FinancingMapState.currentId);

                        if (!!financingMapId) {
                            this.waiter.show();

                            this.financingController.sendHouseholdDocuments(financingMapId)
                                .pipe(take(1))
                                .subscribe({
                                    next: status => {
                                        this.dataService.handleStatusResponse(status)
                                            .then(() => {
                                                this.waiter.hide();
                                                this.notificationService.toast(this.translate.instant('modules.customer.components.paperTab.submission.document.submitSuccess'));
                                            })
                                            .catch(e => {
                                                this.waiter.hide();
                                                throw e;
                                            });
                                    },
                                    error: (error: HttpErrorResponse) => {
                                        if (error.status === 412) {
                                            // Abruf möglicher statuswechsel
                                            this.financingController.getEntity<IFinancingMapModel>(financingMapId)
                                                .pipe(take(1))
                                                .subscribe(fin => {
                                                    this.dataService.patchFinancing(fin);
                                                    this.waiter.hide();
                                                });
                                        }
                                        else {
                                            this.waiter.hide();
                                            throw error;
                                        }
                                    },
                                });
                        }
                        else {
                            resolve(false);
                        }
                        this.waiter.hide();
                    }
                });
        });
    }
}
