<mat-radio-group *ngIf="!!control && array" [formControl]="formControl">
    <div fxLayout="column" *ngFor="let entity of array">
        <div fxLayout="row" fxLayoutAlign="start start">
            <mat-radio-button [value]="entity.value" (change)="onSelectedChanged(entity.value)">
                <mat-option>
                    {{entity.label}}
                </mat-option>
            </mat-radio-button>
            <span class="button-span">
                <button *ngIf="!!entity.iconName" mat-icon-button (click)="onButtonClicked(entity.value)"
                    matTooltip="Rechenbeispiel anzeigen" type="button">
                    <mat-icon>{{entity.iconName}}</mat-icon>
                </button>
            </span>
        </div>

        <hr>
    </div>
</mat-radio-group>