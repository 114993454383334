<div fxLayout="column">
  <mat-label *ngIf="!!customLabel">{{ customLabel | translate }}</mat-label>
  <mat-label *ngIf="!customLabel">{{ ('enumLabels.' + translationKey | finprocessEnumTranslation)}} </mat-label>
  <mat-hint *ngIf="!!comment">{{ comment }}</mat-hint>
  <mat-form-field *ngIf="!!control" [hideRequiredMarker]="!isRequired" appearance="outline">
    <span *ngIf="!!iconName" matPrefix class="material-icons">{{ iconName }} &nbsp;</span>

    <mat-select [formControl]="formControl" [required]="!!isRequired" [multiple]="multiple" (selectionChange)="selectionChange.emit($event)">
        <mat-option *ngFor="let entity of selectList" [value]="entity.value">
            {{entity.label}}
        </mat-option>
    </mat-select>

    <mat-error cxad-form-field-error *ngIf="control.invalid" [control]="control"></mat-error>
  </mat-form-field>
</div>
