/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable class-methods-use-this */

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { IFileModel } from '../../models';
import { Dispose, UnLoadCustomer, UnLoadFinancing } from '../actions';

import { SdkAddFile, SdkDeleteFile, SdkDeleteFiles, SdkPatchFile, SdkUpdateFile, SdkUpdateFiles } from './file.actions';

export interface IFileStateModel {
    data: IFileModel[];
}

/**
 * Klasse des File States
 */
@State<IFileStateModel>({
    name: FileState.stateName,
    defaults: FileState.defaultData,
})
@Injectable()
export class FileState {
    public static readonly stateName = 'fileState';
    private static readonly defaultData: IFileStateModel = {
        data: [],
    };

    /**
     * Aktualisiert das aktuelle File
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchFile} action SdkPatchFile Action
     */
    @Action(SdkPatchFile)
    public patchFile(
        { patchState }: StateContext<IFileStateModel>,
        { payload }: SdkPatchFile,
    ): void {
        patchState({
            data: payload,
        });
    }

    /**
     * Aktualisiert ein spezifisches File
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkUpdateFile} action SdkUpdateFile Action
     */
    @Action(SdkUpdateFile)
    public updateFile(
        { patchState, getState }: StateContext<IFileStateModel>,
        { payload }: SdkUpdateFile,
    ): void {
        const current = getState().data;

        if (current.some(({ id }) => id === payload.id)) {
            patchState({
                data: current.filter(({ id }) => id !== payload.id).concat(payload),
            });
        }
    }


    /**
     * Aktualisiert mehere Files
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkUpdateFiles} action SdkUpdateFiles Action
     */
    @Action(SdkUpdateFiles)
    public updateFiles(
        { patchState, getState }: StateContext<IFileStateModel>,
        { payload }: SdkUpdateFiles,
    ): void {
        const current = getState().data;
        const toPatchIds = payload.map(({ id }) => id);

        patchState({
            data: current.filter(
                ({ id }) => !toPatchIds.includes(id)).concat(payload),
        });
    }

    /**
     * fügt ein neues File hinzu
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkAddFile} action SdkAddFile Action
     */
    @Action(SdkAddFile)
    public addFile(
        { patchState, getState }: StateContext<IFileStateModel>,
        { payload }: SdkAddFile,
    ): void {
        const current = getState().data;

        if (!current.some(({ id }) => id === payload.id)) {
            patchState({
                data: current.concat(payload),
            });
        }
    }

    /**
     * entfernt ein spezifisches File
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkDeleteFile} action SdkDeleteFile Action
     */
    @Action(SdkDeleteFile)
    public deleteFile(
        { dispatch }: StateContext<IFileStateModel>,
        { id }: SdkDeleteFile,
    ): void {
        dispatch(new SdkDeleteFiles([id]));
    }

    /**
     * entfernt mehrere spezifisches Files
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkDeleteFiles} action SdkDeleteFiles Action
     */
    @Action(SdkDeleteFiles)
    public deleteFiles(
        { patchState, getState }: StateContext<IFileStateModel>,
        { ids }: SdkDeleteFiles,
    ): void {
        const current = getState().data;

        if (current.some(({ id }) => ids.includes(id))) {
            patchState({
                data: current.filter(({ id }) => !ids.includes(id)),
            });
        }
    }

    /**
     * zurücksetzen des States
     * 
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action([Dispose, UnLoadFinancing, UnLoadCustomer])
    public dispose({ setState }: StateContext<IFileStateModel>): void {
        setState({ ...FileState.defaultData });
    }

    /**
     * gibt alle aktuell selectierten Files zurück
     * 
     * @param {IFileStateModel} state aktueller State
     * @returns {IFileModel} das aktuell selektierte File
     */
    @Selector()
    public static current(state: IFileStateModel): IFileModel[] {
        return state?.data ?? [];
    }

    /**
     * gibt ein File mit übergebender Id zurück
     * 
     * @param {IFileStateModel} state aktueller State
     * @returns {IFileModel} callback
     */
    @Selector()
    public static currentById(state: IFileStateModel): (fileId: string) => IFileModel | undefined {

        /**
         * Callback funktion
         * 
         * @param {string} fileId Id des Files
         * @returns {IFileModel} gefilterte File
         */
        return (fileId: string) => (state.data as IFileModel[]).find(({ id }) => id === fileId);
    }

    /**
     * liefert alle Dateien mit der angegebenen Dokument Id
     * 
     * @param {IFileStateModel} state aktueller State
     * @returns {IFileModel[]} callback
     */
    @Selector()
    public static filesByDocumentIds(state: IFileStateModel): (ids: string[], signState?: boolean) => IFileModel[] {

        /**
         * Callback funktion
         * 
         * @param {string} ids ids of documents
         * @param {boolean} signState value of property isSigned (undefined for both)
         * @returns {IFileModel[]} gefilterte Files
         */
        return (ids: string[], signState?: boolean) => (state.data as IFileModel[])
            .filter(({ documentId, isSigned }) => ids.includes(documentId) && (signState === undefined || signState === !!isSigned))
            .sort((a, b) => ((a.sortWeight < b.sortWeight) ? -1 : (a.sortWeight > b.sortWeight) ? 1 : 0));
    }

    /**
     * Ermittlung der Größe aller Dateien innerhalb der aktuellen Finanzierung
     * 
     * @param {IFileStateModel} state aktueller State
     * @returns {number} callback
     */
    @Selector()
    public static filesSizeOfAll(state: IFileStateModel): number {
        return (state.data as IFileModel[]).reduce<number>((acc, cur) => acc + cur.length, 0);
    }
}
