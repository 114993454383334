<mat-form-field *ngIf="!!control" [attr.name]="controlName" [hideRequiredMarker]="!isRequired">
    <mat-label>{{ labelText }}</mat-label>
    <input matInput [matDatepicker]="picker" [formControl]="formControl" [required]="!!isRequired"
        [min]="minDateForDatepicker" [max]="maxDateForDatepicker">
    <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>

    <!-- Meldungen für matDatepickerMin und matDatepickerMax werden seperat angezeigt, 
        ansonsten würde die das das Gesamte Datum als UTC ausgegeben  -->
    <mat-error ucba-form-field-error *ngIf="control.invalid && 
        !control.hasError('matDatepickerMin') && 
        !control.hasError('matDatepickerMin')" [control]="control">
    </mat-error>
    <mat-error *ngIf="control.hasError('matDatepickerMin')">
        {{'global.ErrorMessages.matDatepickerMin' | translate:{'min' : minDateForDatepicker | date: 'yyyy'} }}
    </mat-error>
    <mat-error *ngIf="control.hasError('matDatepickerMax')">
        {{'global.ErrorMessages.matDatepickerMax' | translate:{'max' : maxDateForDatepicker | date: 'yyyy'} }}
    </mat-error>
    <mat-hint *ngIf="!!comment">{{ comment }}</mat-hint>
</mat-form-field>